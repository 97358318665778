import { getType } from 'typesafe-actions'
import * as api from 'api'
import {
  fetchContactFeedback,
  IActions,
} from 'page/knowledge-base/ContactFeedback/actions'

type ContactFeedbackState = {
  readonly data: {
    readonly status: ContactFeedbackStatus
    readonly items: api.ContactFeedbackShapeType[]
    readonly totalCount: number
  }
}

export enum ContactFeedbackStatus {
  initial = 'initial',
  loading = 'loading',
  error = 'error',
  ok = 'ok',
}

const initialContactFeedbackState: ContactFeedbackState = {
  data: {
    status: ContactFeedbackStatus.initial,
    items: [],
    totalCount: 0,
  },
}

const contactFeedback = (
  state: ContactFeedbackState = initialContactFeedbackState,
  action: IActions
): ContactFeedbackState => {
  switch (action.type) {
    case getType(fetchContactFeedback.request):
      return {
        ...state,
        data: {
          ...state.data,
          status: ContactFeedbackStatus.loading,
        },
      }
    case getType(fetchContactFeedback.success):
      return {
        ...state,
        data: {
          items: action.payload.contact_feedback,
          status: ContactFeedbackStatus.ok,
          totalCount: action.payload.total_count,
        },
      }
    case getType(fetchContactFeedback.failure):
      return {
        ...state,
        data: {
          ...state.data,
          status: ContactFeedbackStatus.error,
        },
      }
    default:
      return state
  }
}

export default contactFeedback
