import { CustomInput, FormGroup } from 'reactstrap'
import { MobileIcon } from 'components/Icons/MobileIcon/MobileIcon'
import { LaptopIcon } from 'components/Icons/LaptopIcon/LaptopIcon'
import { FacebookMessengerIcon } from 'components/Icons/FacebookMessengerIcon/FacebookMessengerIcon'
import { assertNever } from 'util/exhaustiveness'
import { TransportId } from 'store/transport'
import { SlackIcon } from 'components/Icons/SlackIcon/SlackIcon'
import { capitalize } from 'lodash'
import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'
import { humanizedFilterLabels } from 'components/ContactList/SortableContactList'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { CheckableInput } from 'components/CheckableInput/CheckableInput'

const FILL_COLOR = '#95A7B0'

const iconAndText = (type: TransportId, customFillColor?: string) => {
  switch (type) {
    case 'twilio':
      return {
        icon: (
          <MobileIcon
            width="20"
            height="20"
            fill={customFillColor ?? FILL_COLOR}
          />
        ),
        label: 'SMS / Text Message only',
      }
    case 'facebook':
      return {
        icon: (
          <FacebookMessengerIcon
            width="20"
            height="20"
            fill={customFillColor ?? FILL_COLOR}
          />
        ),
        label: 'Facebook Messenger only',
      }
    case 'web':
      return {
        icon: (
          <LaptopIcon
            width="20"
            height="20"
            fillOffline={customFillColor ?? FILL_COLOR}
          />
        ),
        label: 'Web Chat only',
      }
    case 'slack':
      return {
        icon: <SlackIcon />,
        label: 'Slack only',
      }
    default:
      return assertNever(type)
  }
}

export function CheckboxChannelFilter({
  type,
  checked,
  inputType = 'checkbox',
  onChange,
  eventLocation,
  eventAction,
  eventObject,
  className,
  checkBoxSize = 'md',
}: {
  readonly type: TransportId
  readonly checked: boolean
  readonly inputType?: 'checkbox' | 'radio'
  readonly onChange: () => void
  readonly eventLocation?: string
  readonly eventAction?: EventAction
  readonly eventObject?: string
  readonly className?: string
  readonly checkBoxSize?: 'sm' | 'md'
}) {
  const { icon, label } = iconAndText(type, '#5F779A')

  if (inputType === 'checkbox') {
    return (
      <FormGroup className="pl-1" check>
        <Checkbox
          type="check"
          location={eventLocation}
          action={eventAction}
          object={eventObject}
          titleComponent={
            <div
              className="d-flex ml-2 mb-1 text-mainstay-dark-blue"
              onClick={onChange}>
              <div className="d-flex align-items-center mainstay-dark-blue">
                {icon}
                <span className="ml-2 fs-16px">{label}</span>
              </div>
            </div>
          }
          className={className}
          checkBoxSize={checkBoxSize}
          checked={checked}
          onClick={onChange}
        />
      </FormGroup>
    )
  }

  return (
    <FormGroup className="pl-1 pb-2 pt-2" check>
      <EventTrackerAttrAdder
        eventLocation={eventLocation}
        eventAction={eventAction}
        eventObject={eventObject}>
        <CustomInput
          id={type}
          className="pointer d-flex mr-1"
          type={inputType}
          onChange={onChange}
          checked={checked}>
          <div className="d-flex" onClick={onChange}>
            <EventTrackerAttrAdder
              eventLocation={eventLocation}
              eventAction={eventAction}
              eventObject={eventObject}>
              <div className="d-flex align-items-center">
                {icon}
                <span className="ml-2 fs-16px text-muted-v2">{label}</span>
              </div>
            </EventTrackerAttrAdder>
          </div>
        </CustomInput>
      </EventTrackerAttrAdder>
    </FormGroup>
  )
}
export function RadioFilter({
  valuesEnum,
  type,
  checked,
  onChange,
  eventAction,
  eventLocation,
  eventObject,
}: {
  readonly valuesEnum: { [key: string]: string }
  readonly type: 'active' | 'archived' | 'test' | 'nonTest'
  readonly checked: boolean
  readonly onChange: () => void
  readonly eventLocation?: string
  readonly eventAction?: EventAction
  readonly eventObject?: string
}) {
  const label =
    humanizedFilterLabels[valuesEnum[type]] ||
    valuesEnum[type]
      .split(' ')
      .map(elem => capitalize(elem))
      .join(' ')

  return (
    <FormGroup className="pl-1" check>
      <EventTrackerAttrAdder
        eventLocation={eventLocation}
        eventAction={eventAction}
        eventObject={eventObject}>
        <CheckableInput
          id={label}
          className="pointer d-flex align-items-center"
          type="radio"
          onChange={onChange}
          onClick={onChange}
          checked={checked}>
          <EventTrackerAttrAdder
            eventLocation={eventLocation}
            eventAction={eventAction}
            eventObject={eventObject}>
            <div className="d-flex" onClick={onChange}>
              <span className="fs-16px text-muted-v2">{label}</span>
            </div>
          </EventTrackerAttrAdder>
        </CheckableInput>
      </EventTrackerAttrAdder>
    </FormGroup>
  )
}
