import {
  Filter,
  FilterEnum,
  RelativeDateRange,
  StatusEnum,
  TestUserEnum,
} from 'components/FilterContactsModal/FilterContactsModal'
import { subDays } from 'date-fns'
import { cloneDeep } from 'lodash'
import { Channel, ChannelEnum } from 'store/transport'
import { ActionType, createStandardAction, getType } from 'typesafe-actions'
import { enumKeyFromString } from 'util/string'

// default date range goes back 90 days before the start of today's date to the end of today's date
// (00:00:00 today - 90 days) - 23:59:59 today
export const TODAY = new Date()
export const DEFAULT_DATE_RANGE_DAYS = 90
export const DEFAULT_DATE_QUERY_START = subDays(
  TODAY.setHours(0, 0, 0, 0),
  DEFAULT_DATE_RANGE_DAYS
)
export const DEFAULT_DATE_QUERY_END = new Date(TODAY.setHours(23, 59, 59, 0))
type Status = typeof StatusEnum[keyof typeof StatusEnum]
type TestUser = typeof TestUserEnum[keyof typeof TestUserEnum]

interface IFilterObject {
  [key: string]:
    | boolean
    | Date
    | number
    | undefined
    | RelativeDateRange
    | IFilterObject
}

const removeKeyFromNestedObject = (obj: IFilterObject, keyToDelete: string) => {
  delete obj[keyToDelete]
  Object.values(obj).forEach(elem => {
    if (typeof elem === 'object' && !(elem instanceof Date)) {
      removeKeyFromNestedObject(elem, keyToDelete)
    }
  })
}

export interface IFilterStates {
  channelFiltersObj: {
    [key in Channel]: boolean
  }
  statusFilterObj: { [key in Status]: boolean }
  testUserFilterObj: { [key in TestUser]: boolean }
  audienceFilter: number | undefined | null
  relativeDateRangeObj: {
    relativeDateRangeType: RelativeDateRange
    daysAgo: number
    relStartDate: Date
    relEndDate: Date
  }
}

export const setChannelFilters = createStandardAction(
  '@@mascot/FilterContacts/setChannelFilters'
)<IFilterStates['channelFiltersObj']>()

export const setStatusFilters = createStandardAction(
  '@@mascot/FilterContacts/setStatusFilters'
)<IFilterStates['statusFilterObj']>()

export const setTestUserFilter = createStandardAction(
  '@@mascot/FilterContacts/setTestUserFilter'
)<IFilterStates['testUserFilterObj']>()

export const setRelativeDateRange = createStandardAction(
  '@@mascot/FilterContacts/setRelativeDateRange'
)<IFilterStates['relativeDateRangeObj']>()

export const setAudienceFilterAction = createStandardAction(
  '@@mascot/FilterContacts/setAudienceFilterAction'
)<number | undefined>()

export const setFilters = createStandardAction(
  '@@mascot/FilterContacts/setFilters'
)<IFilterStates>()

export const clearFilterWithKey = createStandardAction(
  '@@mascot/FilterContacts/clearFilterWithKey'
)<{ key: Filter; val: string | undefined }>()

export type FilterContactsActions =
  | ActionType<typeof setChannelFilters>
  | ActionType<typeof setTestUserFilter>
  | ActionType<typeof setRelativeDateRange>
  | ActionType<typeof setStatusFilters>
  | ActionType<typeof setAudienceFilterAction>
  | ActionType<typeof setFilters>
  | ActionType<typeof clearFilterWithKey>

export const noneSelectedChannelFilters = {
  [ChannelEnum.web_bot]: false,
  [ChannelEnum.sms]: false,
  [ChannelEnum.facebook]: false,
  [ChannelEnum.slack]: false,
}

export const DEFAULT_FILTERS_INITIAL_STATE: IFilterStates = {
  channelFiltersObj: noneSelectedChannelFilters,
  statusFilterObj: {
    active: false,
    archived: false,
  },
  testUserFilterObj: {
    test: false,
    'non-test': false,
  },
  relativeDateRangeObj: {
    relativeDateRangeType: 'last',
    daysAgo: DEFAULT_DATE_RANGE_DAYS,
    relStartDate: DEFAULT_DATE_QUERY_START,
    relEndDate: TODAY,
  },
  audienceFilter: undefined,
}

export function filterContactsReducer(
  state: IFilterStates = DEFAULT_FILTERS_INITIAL_STATE,
  action: FilterContactsActions
): IFilterStates {
  switch (action.type) {
    case getType(setChannelFilters):
      return {
        ...state,
        channelFiltersObj: action.payload,
      }
    case getType(setStatusFilters):
      return {
        ...state,
        statusFilterObj: action.payload,
      }
    case getType(setTestUserFilter):
      return {
        ...state,
        testUserFilterObj: action.payload,
      }
    case getType(setRelativeDateRange):
      return {
        ...state,
        relativeDateRangeObj: action.payload,
      }
    case getType(setAudienceFilterAction):
      return {
        ...state,
        audienceFilter: action.payload,
      }
    case getType(setFilters):
      return action.payload
    case getType(clearFilterWithKey):
      const newState = cloneDeep(state)
      if (action.payload.key === FilterEnum.channel) {
        if (!action.payload.val) {
          return state
        }
        newState['channelFiltersObj'][
          enumKeyFromString(action.payload.val, ChannelEnum)
        ] = false
      }
      if (action.payload.key === FilterEnum.audience) {
        newState['audienceFilter'] = undefined
      }
      return newState
    default:
      return state
  }
}
