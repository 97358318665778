import {
  ActionType,
  createAsyncAction,
  createStandardAction,
} from 'typesafe-actions'
import * as api from 'api'

type KnowledgeBaseReviewItemsResponse = {
  readonly review_items: api.KnowledgeBaseReviewItemShapeType[]
  readonly total_count: number
}
export type SelectInputValue = { value: string; label: string }

export type KnowledgeBaseListFiltersType = {
  readonly audiences?: SelectInputValue[]
}

export type FilterType = { label: string; id: string; key: string }

export type KnowledgeBaseReviewItemsCountResponse = {
  readonly bot_could_not_answer_count: number
  readonly needs_personalized_answer_count: number
  readonly needs_interactive_answer_count: number
  readonly answer_incorrect_count: number
  readonly contact_feedback_count: number
}

export const fetchKnowledgeBaseReviewItems = createAsyncAction(
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_ITEMS_FETCH_START',
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_ITEMS_FETCH_SUCCESS',
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_ITEMS_FETCH_ERROR'
)<void, KnowledgeBaseReviewItemsResponse, void>()

export const fetchKnowledgeBaseDropdownItems = createAsyncAction(
  '@@MASCOT/KNOWLEDGE_BASE_DROPDOWN_ITEMS_START',
  '@@MASCOT/KNOWLEDGE_BASE_DROPDOWN_ITEMS_SUCCESS',
  '@@MASCOT/KNOWLEDGE_BASE_DROPDOWN_ITEMS_ERROR'
)<void, KnowledgeBaseListFiltersType, void>()

export const knowledgeBaseRemoveDropdownFilter = createStandardAction(
  '@@MASCOT/KNOWLEDGE_BASE_DROPDOWN_REMOVE_ITEMS'
)<FilterType>()

export const knowledgeBaseClearDropdownFilter = createStandardAction(
  '@@MASCOT/KNOWLEDGE_BASE_DROPDOWN_CLEAR_ITEMS'
)()
export const markKnowledgeBaseItemAsReviewed = createAsyncAction(
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_MARK_ITEM_AS_REVIEWED_START',
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_MARK_ITEM_AS_REVIEWED_SUCCESS',
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_MARK_ITEM_AS_REVIEWED_ERROR'
)<void, void, void>()
export const showKBReviewItemsFilterModal = createStandardAction(
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_SHOW_FILTER_MODAL'
)<boolean>()
export const addQuestionToInstitution = createAsyncAction(
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_ADD_QUESTION_START',
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_ADD_QUESTION_SUCCESS',
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_ADD_QUESTION_ERROR'
)<void, void, void>()
export const deleteQuestionForInstitution = createAsyncAction(
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_DELETE_QUESTION_START',
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_DELETE_QUESTION_SUCCESS',
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_DELETE_QUESTION_ERROR'
)<void, void, void>()
export const setKnowledgeReviewItemsCount = createStandardAction(
  '@@MASCOT/SET_KNOWLEDGE_REVIEW_ITEMS_COUNT'
)<KnowledgeBaseReviewItemsCountResponse>()
export const addToCheckboxList = createStandardAction(
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_ADD_TO_CHECKBOX_LIST'
)<string[]>()
export const removeFromCheckboxList = createStandardAction(
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_REMOVE_FROM_CHECKBOX_LIST'
)<string>()
export const selectAllFromCheckboxList = createStandardAction(
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_SELECT_ALL_FROM_CHECKBOX_LIST'
)<{ all: boolean; selectedIds: string[] }>()
export const resetCheckboxList = createStandardAction(
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_RESET_CHECKBOX_LIST_ITEMS'
)()
export const setCheckboxActionLoading = createStandardAction(
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_CHECKBOX_LIST_ACTION_LOADING'
)<boolean>()
export const updateSelectedItems = createAsyncAction(
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_UPDATE_SELECTED_ITEMS',
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_UPDATE_SELECTED_ITEMS',
  '@@MASCOT/KNOWLEDGE_BASE_REVIEW_UPDATE_SELECTED_ITEMS'
)<void, void, void>()

export type IActions =
  | ActionType<typeof addToCheckboxList>
  | ActionType<typeof removeFromCheckboxList>
  | ActionType<typeof selectAllFromCheckboxList>
  | ActionType<typeof resetCheckboxList>
  | ActionType<typeof fetchKnowledgeBaseReviewItems>
  | ActionType<typeof fetchKnowledgeBaseDropdownItems>
  | ActionType<typeof knowledgeBaseRemoveDropdownFilter>
  | ActionType<typeof knowledgeBaseClearDropdownFilter>
  | ActionType<typeof markKnowledgeBaseItemAsReviewed>
  | ActionType<typeof showKBReviewItemsFilterModal>
  | ActionType<typeof addQuestionToInstitution>
  | ActionType<typeof deleteQuestionForInstitution>
  | ActionType<typeof setKnowledgeReviewItemsCount>
  | ActionType<typeof setCheckboxActionLoading>
  | ActionType<typeof updateSelectedItems>
