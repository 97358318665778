export function CloseButtonIcon({
  className,
  fill = '#FAFBFB',
  width = 14,
  height = 14,
}: {
  readonly className?: string
  readonly fill?: string
  readonly width?: number
  readonly height?: number
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.984 1.42211L8.40599 7.00011L13.984 12.5781L12.578 13.9841L6.99999 8.40611L1.42199 13.9841L0.0159912 12.5781L5.59399 7.00011L0.0159912 1.42211L1.42199 0.0161133L6.99999 5.59411L12.578 0.0161133L13.984 1.42211Z"
        fill={fill}
      />
    </svg>
  )
}
