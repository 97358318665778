import { IMessagesActionTypes } from 'store/triage/chat/messagesActions'
import {
  IExchangesActions,
  pollInbox,
  getInbox,
} from 'store/triage/chat/exchangesActions'
import * as _ from 'lodash'
import {
  IMessageState,
  getResponseMessageMapper,
} from 'store/triage/chat/messagesReducer'
import { getType } from 'typesafe-actions'
import { IExchangeSetResponse } from 'api/response'

export interface IExchangeState {
  incoming: IMessageState
  outgoing: IMessageState
}

const INITIAL_EXCHANGE_STATE: IExchangesState = {
  byContactId: {},
  contactIds: [],
  loading: false,
  polling: false,
  loadingActiveContacts: false,
  total: 0,
}

interface IExchangesState {
  byContactId: {
    [key: string]: IExchangeState[]
  }
  contactIds: string[]
  total: number
  loading: boolean
  polling: boolean
  loadingActiveContacts: boolean
}

const mapExchangeSets = (exchangeSets: IExchangeSetResponse[]) => {
  const mapResponseMessage = getResponseMessageMapper()
  return _.chain(exchangeSets)
    .keyBy(x => x.contactId)
    .mapValues(x =>
      x.exchanges.map(y => ({
        incoming: mapResponseMessage(y.incoming),
        outgoing: mapResponseMessage(y.outgoing),
      }))
    )
    .value()
}

const reducer = (
  state: IExchangesState = INITIAL_EXCHANGE_STATE,
  action: IMessagesActionTypes | IExchangesActions
): IExchangesState => {
  switch (action.type) {
    case getType(getInbox.request):
      return {
        ...state,
        loading: true,
        byContactId: {},
        contactIds: [],
      }
    case getType(getInbox.failure):
      return {
        ...state,
        loading: false,
      }
    case getType(pollInbox.request): {
      return {
        ...state,
        polling: true,
      }
    }
    case getType(pollInbox.failure): {
      return {
        ...state,
        polling: false,
      }
    }
    case getType(pollInbox.success):
    case getType(getInbox.success): {
      return {
        ...state,
        loading: false,
        polling: false,
        total: action.payload.count,
        byContactId: mapExchangeSets(action.payload.results.exchangeSets),
        contactIds: action.payload.results.exchangeSets.map(x => x.contactId),
      }
    }
    default:
      return state
  }
}

export default reducer
