import React from 'react'
import { Button } from 'components/Button/Button'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { DEFAULT_PREPENDED_ESCALATED_MESSAGE_RESPONSE } from 'const/settings'
import * as api from 'api'
import {
  prependedEscalatedMesageResponseFormReducer,
  cancelChanges,
  updatePrependCounselorResponse,
  setRadioButton,
  setTextField,
  IPrependedEscalatedMesageResponseFormReducerState,
} from 'components/SettingsPrependedEscalatedMessageResponseForm/SettingsPrependedEscalatedMessageResponseFormReducer'
import { TextArea } from 'components/TextArea/TextArea'
import { Radio } from 'components/MaterialComponents/MaterialComponents'

export interface IPrependedEscalatedMesageResponseFormProps {
  readonly prependedMessageResponse: string | null
  readonly initialState?: IPrependedEscalatedMesageResponseFormReducerState
}

export function PrependedEscalatedMesageResponseForm({
  prependedMessageResponse,
  initialState = {
    prev: prependedMessageResponse,
    cur: prependedMessageResponse,
    updateStatus: 'initial',
  },
}: IPrependedEscalatedMesageResponseFormProps) {
  const [state, dispatch] = React.useReducer(
    prependedEscalatedMesageResponseFormReducer,
    initialState
  )

  const isDefault = state.cur == null
  const contentChanged = state.cur !== state.prev
  const handleCancel = () => dispatch(cancelChanges())

  const handleSave = () => {
    dispatch(updatePrependCounselorResponse.request())
    api
      .updateInstitutionData('me', { prependCounselorResponse: state.cur })
      .then(res => {
        dispatch(
          updatePrependCounselorResponse.success(
            res.data.prependCounselorResponse
          )
        )
        toast.success('Successfully updated prepended message.')
      })
      .catch(() => {
        toast.error('Failure updating prepended message.')
        dispatch(updatePrependCounselorResponse.failure())
      })
  }

  const handleRadioClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent> | { target: { value: string } }
  ) => {
    if ('value' in e.target) {
      dispatch(setRadioButton(e.target['value']))
    }
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    dispatch(setTextField(e.target.value))

  return (
    <form className="d-flex flex-column">
      <label className="d-flex">
        <Radio
          color="primary"
          name="escalated_message_response"
          value="default"
          className="flex-shrink-0"
          checked={isDefault}
          onClick={handleRadioClick}
        />
        <div className="d-flex flex-column ml-2">
          <div>Default Message</div>
          <small>{DEFAULT_PREPENDED_ESCALATED_MESSAGE_RESPONSE}</small>
        </div>
      </label>
      <label className="d-flex mb-2">
        <Radio
          color="primary"
          id="foo"
          name="escalated_message_response"
          value="custom"
          className="flex-shrink-0"
          checked={!isDefault}
          onClick={handleRadioClick}
        />

        <div className="d-flex flex-column ml-2 w-100">
          <div>Custom</div>
          <TextArea
            value={state.cur || ''}
            placeholder="Your custom message here"
            onChange={handleTextChange}
          />
        </div>
      </label>
      {contentChanged ? (
        <div className="d-flex justify-content-end">
          <Button height="small" className="mr-2" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            height="small"
            color="primary"
            disabled={!isDefault && !state.cur}
            onClick={handleSave}
            loading={state.updateStatus === 'loading'}>
            Save Changes
          </Button>
        </div>
      ) : null}
    </form>
  )
}
