import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css'
import 'components/ReadOnlyDatePicker/datePicker.scss'
import * as React from 'react'
import {
  DayPickerProps,
  NavbarElementProps,
} from 'react-day-picker/types/props'
import CustomInput from 'reactstrap/lib/CustomInput'
import { Button } from 'components/Button/Button'
import Tooltip from 'components/Tooltip/Tooltip'
import moment from 'moment'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import {
  getDynamicDate,
  serializeDynamicDate,
  getDisplayValue,
  shouldIgnoreYear,
  RelativeDateConsts,
} from 'components/ReadOnlyDatePicker/dynamicDateUtils'
import { Input } from 'components/Input/Input'

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'

export const DynamicDatePicker = ({
  value,
  onDayChange,
  classNames,
  dayPickerProps,
  disabled,
}: {
  readonly value?: string | Date | undefined
  readonly onDayChange: (date: string) => void
  readonly classNames?: Record<string, string>
  readonly dayPickerProps?: DayPickerProps
  readonly disabled?: boolean
}) => {
  const [ignoreYear, setIgnoreYear] = React.useState(shouldIgnoreYear(value))

  const dynamicValue = value ? getDynamicDate(value, ignoreYear) : undefined
  const inputRef: React.MutableRefObject<null | HTMLInputElement> = React.useRef(
    null
  )
  const [shouldFocusOffsetInput, setShouldFocusOffsetInput] = React.useState(
    false
  )

  return (
    <DayPickerInput
      format="LL"
      inputProps={{
        readOnly: true,
        ref: inputRef,
        disabled,
        className: `form-control form-control-md ${
          disabled
            ? 'day-picker-input--disabled'
            : 'daypicker-contact-panel-input'
        }`,
      }}
      /* eslint-disable @typescript-eslint/consistent-type-assertions */
      /* tslint:disable:no-any no-unsafe-any */
      classNames={
        { ...classNames, container: `w-100 ${classNames?.container}` } as any
      }
      /* eslint-enable @typescript-eslint/consistent-type-assertions */
      /* tslint:enable:no-any no-unsafe-any */
      parseDate={parseDate}
      // override the date picker's default format
      formatDate={(date, _, locale) =>
        formatDate(date, DEFAULT_DATE_FORMAT, locale)
      }
      placeholder="Select a date"
      value={dynamicValue ? getDisplayValue(dynamicValue) : value}
      onDayChange={(day: Date) =>
        onDayChange(
          serializeDynamicDate(getDynamicDate(day, ignoreYear), ignoreYear)
        )
      }
      dayPickerProps={{
        ...dayPickerProps,
        canChangeMonth: true,
        captionElement: () => null,
        navbarElement: props => (
          <div
            onMouseLeave={() => {
              // move the focus back to the parent to allow closing the popover.
              inputRef.current?.focus()
              setShouldFocusOffsetInput(false)
            }}>
            <DynamicDateBar
              ignoreYear={ignoreYear}
              dayOffset={dynamicValue?.offset}
              onDayChange={date => {
                setShouldFocusOffsetInput(true)
                onDayChange(date)
              }}
              shouldFocusInput={shouldFocusOffsetInput}
              onIgnoreYear={ignoreYear => {
                inputRef.current?.focus()
                setIgnoreYear(ignoreYear)
                if (!!dynamicValue) {
                  onDayChange(serializeDynamicDate(dynamicValue, ignoreYear))
                }
              }}
            />
            <DateBarCaption navProps={props} ignoreYear={ignoreYear} />
          </div>
        ),
      }}
    />
  )
}

interface IDateBarCaptionProps {
  navProps: React.PropsWithChildren<NavbarElementProps>
  ignoreYear?: boolean
}

const DateBarCaption = ({ navProps, ignoreYear }: IDateBarCaptionProps) => {
  const momentDate = moment(navProps.month)
  return (
    <div className="d-flex w-100 px-3 justify-content-between">
      <div className={navProps.classNames.caption} role="heading">
        <div className="d-flex flex-row justify-content-between">
          <div>
            {momentDate.format('MMMM')}{' '}
            {ignoreYear ? '' : momentDate.format('YYYY')}
          </div>
        </div>
      </div>
      <div className={navProps.classNames.navBar}>
        <span
          role="button"
          style={{ position: 'inherit', marginRight: 0 }}
          className={navProps.classNames.navButtonPrev}
          onClick={() => navProps.onPreviousClick()}
        />
        <span
          role="button"
          style={{ position: 'inherit' }}
          className={navProps.classNames.navButtonNext}
          onClick={() => navProps.onNextClick()}
        />
      </div>
    </div>
  )
}

interface IDynamicDateBarProps {
  ignoreYear: boolean
  dayOffset: number | undefined
  shouldFocusInput: boolean
  onIgnoreYear: (ignoreYear: boolean) => void
  onDayChange: (date: string) => void
}

const VALID_OFFSET_REGEX = new RegExp(/^[-+]{1}\d+/)

function offsetDisplayValue(value: string) {
  const parsedInt = parseInt(value, 10)
  // add a preceding + sign if not exists for zero or positive integers
  if (!isNaN(parsedInt) && parsedInt >= 0) {
    return `+${parsedInt}`
  }
  return value
}

const DynamicDateBar = ({
  ignoreYear,
  dayOffset,
  shouldFocusInput,
  onIgnoreYear,
  onDayChange,
}: IDynamicDateBarProps) => {
  const [offsetString, setOffsetString] = React.useState(
    offsetDisplayValue(String(dayOffset ?? 0))
  )
  const hasError = !VALID_OFFSET_REGEX.test(offsetString)

  React.useEffect(() => {
    if (shouldFocusInput && offsetString) {
      inputRef.current?.focus()
    }
  }, [offsetString, shouldFocusInput])

  const inputRef: React.MutableRefObject<null | HTMLInputElement> = React.useRef(
    null
  )

  return (
    <div>
      <div className="mt-3 mx-3">
        <div className="d-flex">
          <CustomInput
            id="ignoreYear"
            type="checkbox"
            checked={ignoreYear}
            onClick={() => onIgnoreYear(!ignoreYear)}
            label="Match to Any Year"
          />
          <Tooltip content="If checked, only the month and day of the Date-type field will be evaluated (e.g. finding contacts with a particular birthday). Otherwise, the Audience will evaluate the full date (e.g. finding contacts with an exact orientation date).">
            <div className="d-flex align-items-center">
              <AHIcon name="info_outline" />
            </div>
          </Tooltip>
        </div>
        <hr />
        <div className="d-flex align-items-center">
          <Button
            className="btn btn-outline-primary"
            active
            onClick={e => {
              onDayChange(
                serializeDynamicDate(
                  {
                    offset: 0,
                    month: RelativeDateConsts.MONTH,
                    day: RelativeDateConsts.DAY,
                  },
                  ignoreYear
                )
              )
              e.stopPropagation()
            }}>
            Today
          </Button>
          <div>
            <Input
              ref={inputRef}
              type="text"
              name="confirm"
              className="day-picker-day-offset-input"
              onChange={e => {
                const parsedOffset = parseInt(e.target.value.trim(), 10)
                if (!isNaN(parsedOffset)) {
                  onDayChange(
                    serializeDynamicDate(
                      {
                        offset: parsedOffset,
                        month: RelativeDateConsts.MONTH,
                        day: RelativeDateConsts.DAY,
                      },
                      ignoreYear
                    )
                  )
                } else {
                  setOffsetString(offsetDisplayValue(e.target.value.trim()))
                }
              }}
              onKeyDown={e => e.stopPropagation()}
              value={offsetString}
              error={hasError}
            />
            {hasError && (
              <div className="text-danger small day-picker-day-offset-input--error-msg">
                Please enter a valid number.
              </div>
            )}
          </div>
          <span>Days</span>
        </div>
        <hr />
      </div>
    </div>
  )
}
