import * as React from 'react'
import { ButtonIconRound } from 'components/ButtonIconRound/ButtonIconRound'

interface IDeleteNodeButtonProps {
  onClick?: (e: React.MouseEvent) => void
}

export default function DeleteNodeButton({ onClick }: IDeleteNodeButtonProps) {
  const noop = () => {
    // Noop
  }
  return (
    <div className="script-delete-node">
      <ButtonIconRound
        icon="delete"
        className="hover-text-new-ui-danger"
        onClick={onClick || noop}
        tooltipPlacement="right"
        tooltipText="Delete"
      />
    </div>
  )
}
