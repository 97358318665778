import React, { useContext } from 'react'
import { usePermissionsContext } from 'util/permissions/PermissionsContext'
import Tooltip from 'components/Tooltip/Tooltip'
import { Placement } from 'popper.js'
import classNames from 'classnames'
import { GuestPageContext } from 'components/TrendsV2/sharedUtils'

export default function PermissionsGuard({
  permission,
  placement = 'left',
  content = 'Your assigned permission level doesn’t allow you to perform this action. Please contact an admin for more information.',
  children,
  className,
  renderNothing,
  tabbable,
}: {
  readonly placement?: Placement
  readonly content?: string
  readonly tabbable?: boolean
  readonly permission?: string
  readonly className?: string
  readonly children: React.ReactNode
  readonly renderNothing?: boolean
}) {
  const { hasPermission } = usePermissionsContext()
  const { isGuest } = useContext(GuestPageContext)
  if (isGuest) {
    return <>{children}</>
  }
  if (!permission) {
    return <>{children}</>
  }
  const canAccess = hasPermission(permission)

  if (!canAccess && renderNothing) {
    return <></>
  }

  if (canAccess && renderNothing) {
    return <>{children}</>
  }

  return (
    <Tooltip
      boundary="viewport"
      maxWidth={200}
      content={<p className="text-mainstay-dark-blue-65 m-0">{content}</p>}
      placement={placement}
      isEnabled={!canAccess}
      className="border-color-bluegrey-20">
      <span className={className} tabIndex={tabbable ? 0 : -1}>
        <section
          className={classNames({
            'pe-none opacity-80': !canAccess,
          })}>
          {children}
        </section>
      </span>
    </Tooltip>
  )
}
