import React from 'react'
import cls from 'classnames'
import { MainstaySidebarLinkType } from 'mainstay-ui-kit/MainstaySidebar/MainstaySidebarSectionLink/MainstaySidebarSectionLink'
import MainstaySidebarSection from 'mainstay-ui-kit/MainstaySidebar/MainstaySidebarSection/MainstaySidebarSection'
import { Location, LocationDescriptor } from 'history'
import { match } from 'react-router'
import 'mainstay-ui-kit/MainstaySidebar/MainstaySidebar.scss'

export interface IMainstaySidebarProps {
  readonly header: string | JSX.Element
  readonly children?: React.ReactElement
  readonly links?: MainstaySidebarLinkType[]
  readonly footer?: React.ReactNode
  readonly className?: string
}

export default function MainstaySidebar({
  header,
  children,
  links,
  footer,
  className,
}: IMainstaySidebarProps) {
  return (
    <div
      className={cls('mainstay-sidebar d-flex flex-column h-100', className)}>
      <div className="mainstay-sidebar-sections-container">
        <div className="d-flex flex-column">
          <h4>{header}</h4>
          {children !== undefined ? (
            children
          ) : links !== undefined ? (
            <MainstaySidebarSection links={links} />
          ) : null}
        </div>
        {!!footer && <div className="margin-top-auto">{footer}</div>}
      </div>
    </div>
  )
}

function getParameterByName(name: string, url: string) {
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) {
    return null
  }
  if (!results[2]) {
    return ''
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const matchPathnameAndQueryParamsByQueryParam = (queryParam: string) => (
  to: LocationDescriptor
) => (match: match<{}>, location: Location) => {
  const toLabel = getParameterByName(queryParam, String(to))
  const pathLabel = getParameterByName(queryParam, location.search)
  return Boolean(match || toLabel === pathLabel)
}
