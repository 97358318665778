import { createSelector } from 'reselect'
import { RootState as IState } from 'store/store'
import * as _ from 'lodash'
import * as queryString from 'query-string'
import { IConversationPageRouteProps } from 'components/IRouteProps'
import { IContactState } from 'store/triage/chat/contactsReducer'
import { ITriagableMessageOwnProps } from 'page/triage/TriagableMessage/TriagableMessage'
import { notUndefined } from 'util/typeguards'

export const getMessages = (state: IState) => {
  return state.triage.application.messages.byId
}

export const getInboxFetchStatus = (state: IState) =>
  state.triage.application.exchanges.loading

export const getExchanges = (state: IState) => {
  return state.triage.application.exchanges.byContactId
}

export const getPendingExchangeGroups = createSelector(
  getExchanges,
  exchangeGroups => {
    return exchangeGroups
  }
)

// CONTACT SELECTORS
// ------------------------

export const getContactsByIdCollection = (state: IState) =>
  state.triage.application.contacts.byId

// TODO(sbdchd): rename as there is not default
const getContactOrDefaultById = (
  contacts: ReturnType<typeof getContactsByIdCollection>,
  id: string
): IContactState | undefined => {
  return contacts[id]
}

const getContactIdFromProps = (
  _state: IState,
  ownProps: { contactId: string }
) => ownProps.contactId

export const getConversationIdFromUrl = (
  _state: IState,
  ownProps: IConversationPageRouteProps
): string => {
  return ownProps.match.params.conversationId
}

export const getContactByIdFromUrl = createSelector(
  getContactsByIdCollection,
  getConversationIdFromUrl,
  getContactOrDefaultById
)

export const getContactById = createSelector(
  getContactsByIdCollection,
  getContactIdFromProps,
  getContactOrDefaultById
)

export const getConversationAuthor = createSelector(
  getContactsByIdCollection,
  getConversationIdFromUrl,
  getContactOrDefaultById
)
const getContactSearchResultsCollection = (state: IState) =>
  state.triage.application.contacts.bySearchQuery

const getContactSearchQuery = (state: IState) =>
  state.triage.application.contacts.query

export const getContactSearchResultIds = createSelector(
  getContactSearchResultsCollection,
  getContactSearchQuery,
  (collection, query) => collection[query]
)

export const getContactSearchResults = createSelector(
  getContactSearchResultIds,
  getContactsByIdCollection,
  (ids, collection) => {
    if (!ids) {
      return []
    }
    return ids.map(x => collection[x]).filter(notUndefined)
  }
)

export const getContactSearchStatus = (state: IState) =>
  state.triage.application.contacts.searching

// MESSAGE SELECTORS
// -----------------

export const getConversationTouched = (
  state: IState,
  ownProps: IConversationPageRouteProps
) => {
  return state.triage.application.messages.touched[
    ownProps.match.params.conversationId
  ]
}

export const getMessageIdFromQueryString = (
  _state: IState,
  ownProps: IConversationPageRouteProps
) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const qs = queryString.parse(ownProps.location.search) as {
    messageId?: string
  }
  return qs.messageId
}

export const getIncomingMessageForQuestionSearch = createSelector(
  getMessageIdFromQueryString,
  getMessages,
  (id, messages) => {
    if (id && messages[id]) {
      const message = messages[id]
      if (message.inReplyTo) {
        return messages[message.inReplyTo]
      }
      return message
    }
    return undefined
  }
)

export const getOutgoingMessageForQuestionSearch = createSelector(
  getMessageIdFromQueryString,
  getMessages,
  (id, messages) => {
    return id ? messages[id] : undefined
  }
)

export const getLoadingStatusOfTriageActionById = (
  state: IState,
  ownProps: ITriagableMessageOwnProps
) => state.triage.application.messages.loading[ownProps.message.id]

export const getConversationStatus = createSelector(
  (state: IState) => state.triage.ui.conversation.conversationStatusById,
  getConversationIdFromUrl,
  (statusById, id) => statusById[id]
)

export const getConversationMessages = createSelector(
  getMessages,
  getConversationIdFromUrl,
  (messages, authorId) => {
    return _.chain(messages)
      .pickBy(
        message => message.author === authorId || message.recipient === authorId
      )
      .orderBy(['created'], ['asc'])
      .value()
  }
)

export const getSelectedMessageIndex = createSelector(
  getConversationMessages,
  getMessageIdFromQueryString,
  (messages, id) => {
    const idx = _.findIndex(messages, x => x.id === id)
    if (idx > -1) {
      return idx
    }
    return undefined
  }
)

const getMessageSenders = (state: IState) =>
  state.triage.ui.conversation.message_senders

export const getMessageSendingStatus = createSelector(
  getConversationIdFromUrl,
  getMessageSenders,
  (conversationId, messageSenders) => {
    return (
      messageSenders &&
      messageSenders[conversationId] &&
      messageSenders[conversationId].sendingMessage
    )
  }
)
