export function ArrowDropDownIcon({
  collapsed = true,
  className,
}: {
  readonly collapsed?: boolean
  readonly className?: string
}) {
  if (collapsed) {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        className={className}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.48804 12.762L7.48804 5.23874L11.25 9.00074L7.48804 12.762Z"
          fill="#5E6B72"
        />
      </svg>
    )
  }
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.23804 7.48804L12.7613 7.48804L8.99929 11.25L5.23804 7.48804Z"
        fill="#5E6B72"
      />
    </svg>
  )
}
