import { ChangeEvent } from 'react'
import { LabeledTextInput } from 'components/LabeledInput/LabeledInput'
import { getFlagEmoji } from 'components/PurchasePhoneNumbersForm/PurchasePhoneNumbersForm'
import { FormikProps } from 'formik'
import { IContactFormData } from 'components/ContactPanel/EditableContactPanel'
import { ValueType } from 'react-select/lib/types'
import { FloatingLabelSelect } from 'components/SettingsEscalation/reactSelectUtils'

const countryCallingCodes = [
  {
    name: `${getFlagEmoji('US')} US (+1)`,
    value: '1',
  },
  {
    name: `${getFlagEmoji('AR')} Argentina (+54)`,
    value: '54',
    hidden: true,
  },
  {
    name: `${getFlagEmoji('BE')} Belgium (+32)`,
    value: '32',
  },
  {
    name: `${getFlagEmoji('BR')} Brazil (+55)`,
    value: '55',
    hidden: true,
  },
  {
    name: `${getFlagEmoji('CA')} Canada (+1)`,
    value: '1',
  },
  {
    name: `${getFlagEmoji('CN')} China (+86)`,
    value: '86',
    hidden: true,
  },
  {
    name: `${getFlagEmoji('FR')} France (+33)`,
    value: '33',
    hidden: true,
  },
  {
    name: `${getFlagEmoji('DE')} Germany (+49)`,
    value: '49',
  },
  {
    name: `${getFlagEmoji('HU')} Hungary (+36)`,
    value: '36',
  },
  {
    name: `${getFlagEmoji('IL')} Israel (+972)`,
    value: '972',
  },
  {
    name: `${getFlagEmoji('IE')} Ireland (+353)`,
    value: '353',
  },
  {
    name: `${getFlagEmoji('IT')} Italy (+39)`,
    value: '39',
  },
  {
    name: `${getFlagEmoji('MX')} Mexico (+52)`,
    value: '52',
  },
  {
    name: `${getFlagEmoji('NL')} Netherlands (+31)`,
    value: '31',
  },
  {
    name: `${getFlagEmoji('PL')} Poland (+48)`,
    value: '48',
  },
  {
    name: `${getFlagEmoji('MX')} Portugal (+351)`,
    value: '351',
  },
  {
    name: `${getFlagEmoji('RU')} Russia (+7)`,
    value: '7',
    hidden: true,
  },
  {
    name: `${getFlagEmoji('SA')} Saudi Arabia (+966)`,
    value: '966',
    hidden: true,
  },
  {
    name: `${getFlagEmoji('SG')} Singapore (+65)`,
    value: '65',
  },
  {
    name: `${getFlagEmoji('ES')} Spain (+34)`,
    value: '34',
    hidden: true,
  },
  {
    name: `${getFlagEmoji('SE')} Sweden (+46)`,
    value: '46',
  },
  {
    name: `${getFlagEmoji('TR')} Turkey (+90)`,
    value: '90',
  },
  {
    name: `${getFlagEmoji('GB')} United Kingdom (+44)`,
    value: '44',
  },
]

interface IOption {
  label: string
  value: string
}

interface IE164PhoneNumberProps {
  form: FormikProps<IContactFormData>
  onChangeCountryCallingCode: (value: ValueType<IOption>) => void
  onChangePhone: (e: ChangeEvent<HTMLInputElement>) => void
}

export function E164PhoneNumber({
  form,
  onChangeCountryCallingCode,
  onChangePhone,
}: IE164PhoneNumberProps) {
  return (
    <>
      <div className="d-flex pr-3">
        <FloatingLabelSelect<IOption>
          floatingLabel="Calling Code"
          eventAction="change"
          eventObject="Country Calling Code"
          onChange={onChangeCountryCallingCode}
          value={{
            label:
              countryCallingCodes.find(
                c => c.value === form.values.countryCallingCode
              )?.name ?? form.values.countryCallingCode,
            value: form.values.countryCallingCode,
          }}
          id="countryCallingCode"
          placeholder="Country Calling Code:"
          classNamePrefix="react-select"
          className="country-calling-code-dropdown mr-1"
          options={countryCallingCodes
            .filter(o => !o.hidden)
            .map(o => ({
              label: o.name,
              value: o.value,
            }))}
        />

        <LabeledTextInput
          eventAction="change"
          eventObject="phone"
          onChange={onChangePhone}
          label="Phone"
          id="phone"
          value={form.values.phone}
          className="intl-phone-container mb-2"
        />
      </div>
      {form.errors.phone && form.touched.phone && (
        <div className="mr-3 alert alert-danger">{form.errors.phone}</div>
      )}
    </>
  )
}
