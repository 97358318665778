import * as React from 'react'
import { AHIcon, AHIconType } from 'components/Icons/AHIcon/AHIcon'
import Tooltip from 'components/Tooltip/Tooltip'
import classnames from 'classnames'
import Popper from 'popper.js'

export interface IButtonIconRoundProps {
  readonly className?: string
  readonly icon: AHIconType
  readonly onClick: (e: React.MouseEvent) => void
  readonly tooltipText: string
  readonly tooltipPlacement?: Popper.Placement
  readonly disabled?: boolean
}
export function ButtonIconRound({
  className,
  icon,
  onClick,
  tooltipText,
  tooltipPlacement,
  disabled,
}: IButtonIconRoundProps) {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onClick(e)
  }
  const content = (
    <div
      onClick={handleClick}
      className={classnames(
        'hover-bg-white border-radius-50 hover-shadow-md py-2 px-2 ml-1 pointer line-height-0 hover-out-opacity-5 hover-opacity-100',
        { 'pointer-events-none not-allowed': disabled },
        className
      )}>
      <AHIcon name={icon} />
    </div>
  )
  if (!!tooltipText) {
    return (
      <Tooltip content={tooltipText} placement={tooltipPlacement ?? 'bottom'}>
        <div>{content}</div>
      </Tooltip>
    )
  }
  return content
}
