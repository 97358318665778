import {
  IKnowledgeActions,
  initKnowledgeReducer,
  getUnderstandingTopics,
  suggestedQuestionSearch,
  searchUnderstandings,
} from 'store/triage/knowledge/actions'
import * as _ from 'lodash'
import { IQuestionSearchResponseData } from 'api/response'
import { getType } from 'typesafe-actions'

export interface ISuggestedQuestionMatch {
  sampleQuestion: string
  matchingQuestion: string
  answer: string
  id: string
}

export const INITIAL_STATE = {
  understandings: {
    byId: {},
    allIds: [],
    byQuery: {},
    loading: false,
    deleting: false,
    query: '',
  },
  suggestions: {
    byUnderstandingId: {},
    allUnderstandingIds: [],
    loading: 'initial' as const,
  },
  topics: {
    loading: false,
    allTopics: [],
  },
}

// The search endpoint returns a tuple of size 4, where the first element
// is the topic, 2nd element is the matching question, the 3rd element is
// the answer, and the 4th is the sample question. Additionally, a hashmap of
// topic string => understandingId is returned. Here we're constructing a list
// of consolidated objects.
const mapSuggestedAnswersFromResponseData = (
  responseData: IQuestionSearchResponseData
): Array<ISuggestedQuestionMatch> => {
  return responseData.results.map(curr => {
    return {
      id: responseData.topicToUnderstandingId[curr[0]],
      matchingQuestion: curr[1],
      answer: curr[2],
      sampleQuestion: curr[3],
    }
  })
}

interface IKnowledgeState {
  readonly understandings: {
    readonly byId: {
      readonly [id: string]:
        | {
            readonly id: string
            readonly topic: string
            readonly questionGroups: ReadonlyArray<{
              readonly fuzzyQuestions: ReadonlyArray<string>
            }>
            readonly answerGroups: ReadonlyArray<{
              readonly answers: ReadonlyArray<string>
            }>
          }
        | undefined
    }
    readonly byQuery: {
      readonly [query: string]: ReadonlyArray<string> | undefined
    }
    readonly allIds: ReadonlyArray<string>
    readonly loading: boolean
    readonly deleting: boolean
    readonly query: string
  }
  readonly suggestions: {
    readonly allUnderstandingIds: ReadonlyArray<string>
    readonly byUnderstandingId: {
      readonly [id: string]: ISuggestedQuestionMatch | undefined
    }
    readonly loading: 'initial' | 'loading' | 'success' | 'failure'
  }
  readonly topics: {
    readonly loading: boolean
    readonly allTopics: ReadonlyArray<string>
  }
}

const reducer = (
  state: IKnowledgeState = INITIAL_STATE,
  action: IKnowledgeActions
): IKnowledgeState => {
  switch (action.type) {
    case getType(searchUnderstandings.failure):
      return {
        ...state,
        understandings: {
          ...state.understandings,
          loading: false,
          query: '',
        },
      }
    case getType(searchUnderstandings.request):
      return {
        ...state,
        understandings: {
          ...state.understandings,
          loading: true,
        },
      }
    case getType(searchUnderstandings.success): {
      const understandings = action.payload.understandings
      const newMap = _.keyBy(understandings, u => u.id)
      const newIds = action.payload.understandings.map(x => x.id)
      return {
        ...state,
        understandings: {
          ...state.understandings,
          byId: {
            ...state.understandings.byId,
            ...newMap,
          },
          byQuery: {
            ...state.understandings.byQuery,
            [action.payload.query]: newIds,
          },
          allIds: [...state.understandings.allIds, ...newIds],
          query: action.payload.query,
          loading: false,
        },
      }
    }
    case getType(suggestedQuestionSearch.request):
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          loading: 'loading',
        },
      }
    case getType(suggestedQuestionSearch.failure):
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          loading: 'failure',
        },
      }
    case getType(suggestedQuestionSearch.success):
      const mappedCollection = mapSuggestedAnswersFromResponseData(
        action.payload.matches
      )
      const ids = Object.values(action.payload.matches.topicToUnderstandingId)
      const dictionary = _.keyBy(mappedCollection, 'id')
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          allUnderstandingIds: [...ids],
          byUnderstandingId: {
            ...dictionary,
          },
          loading: 'success',
        },
      }
    case getType(getUnderstandingTopics.success): {
      return {
        ...state,
        topics: {
          ...state.topics,
          allTopics: action.payload,
          loading: false,
        },
      }
    }
    case getType(getUnderstandingTopics.request): {
      return {
        ...state,
        topics: {
          ...state.topics,
          loading: true,
        },
      }
    }
    case getType(getUnderstandingTopics.failure): {
      return {
        ...state,
        topics: {
          ...state.topics,
          loading: false,
        },
      }
    }
    case getType(initKnowledgeReducer):
    default:
      return state
  }
}

export default reducer
