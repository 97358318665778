import {
  ActionType,
  createAsyncAction,
  createStandardAction,
} from 'typesafe-actions'
import {
  ConversationSettingsShapeType,
  InstitutionGenerativeAISettingsShapeType,
  GenerativeTextPromptShapeType,
  GenerativeAISettingsShapeType,
} from 'api'
import { GenerativeTextPromptSettings } from 'page/settings/ConversationSettings/reducer'

export const fetchConversationSettings = createAsyncAction(
  '@@MASCOT/SETTINGS_CONVERSATION_START',
  '@@MASCOT/SETTINGS_CONVERSATION_SUCCESS',
  '@@MASCOT/SETTINGS_CONVERSATION_ERROR'
)<void, ConversationSettingsShapeType, void>()

export const updateConversationSettings = createAsyncAction(
  '@@MASCOT/SETTINGS_UPDATE_CONVERSATION_START',
  '@@MASCOT/SETTINGS_UPDATE_CONVERSATION_SUCCESS',
  '@@MASCOT/SETTINGS_UPDATE_CONVERSATION_ERROR'
)<void, ConversationSettingsShapeType, void>()

export const fetchGenerativeTextPromptSettings = createAsyncAction(
  '@@MASCOT/SETTINGS_GENERATIVE_TEXT_PROMPT_START',
  '@@MASCOT/SETTINGS_GENERATIVE_TEXT_PROMPT_SUCCESS',
  '@@MASCOT/SETTINGS_GENERATIVE_TEXT_PROMPT_ERROR'
)<void, InstitutionGenerativeAISettingsShapeType, void>()

export const updateGenerativeTextPromptSetting = createAsyncAction(
  '@@MASCOT/SETTINGS_UPDATE_GENERATIVE_TEXT_PROMPT_START',
  '@@MASCOT/SETTINGS_UPDATE_GENERATIVE_TEXT_PROMPT_SUCCESS',
  '@@MASCOT/SETTINGS_UPDATE_GENERATIVE_TEXT_PROMPT_ERROR'
)<
  keyof GenerativeTextPromptSettings['updateStatus'],
  {
    categoryKey: keyof GenerativeTextPromptSettings['updateStatus']
    defaultValue: GenerativeTextPromptShapeType
  },
  keyof GenerativeTextPromptSettings['updateStatus']
>()

export const updateGenerativeAISettings = createStandardAction(
  '@@MASCOT/SETTINGS_UPDATE_GENERATIVE_AI_SETTINGS_SUCCESS'
)<GenerativeAISettingsShapeType>()

export type IActions =
  | ActionType<typeof fetchConversationSettings>
  | ActionType<typeof updateConversationSettings>
  | ActionType<typeof fetchGenerativeTextPromptSettings>
  | ActionType<typeof updateGenerativeTextPromptSetting>
  | ActionType<typeof updateGenerativeAISettings>
