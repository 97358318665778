import { SectionTitle } from 'page/SettingsGeneral'
import Select from 'components/Select/SelectV2'
import classnames from 'classnames'
import * as Api from 'api'
import { isRight } from 'fp-ts/lib/Either'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { useSelector, useDispatch } from 'util/hooks'
import { getInstitutionDateFormatSettings } from 'store/triage/institution/selectors'
import { updateDateFormat } from 'store/triage/institution/actions'

interface IOption {
  value: string
  label: string
}
export const VALID_DATE_FORMATS: IOption[] = [
  { value: '%b %-d, %Y', label: 'Feb 1, 2001' },
  { value: '%B %-d, %Y', label: 'February 1, 2001' },
  { value: '%-d %b, %Y', label: '1 Feb, 2001' },
  { value: '%-d %B, %Y', label: '1 February, 2001' },
  { value: '%m/%d/%y', label: '02/01/01' },
  { value: '%m/%d/%Y', label: '02/01/2001' },
  { value: '%d/%m/%y', label: '01/02/01' },
  { value: '%d/%m/%Y', label: '01/02/2001' },
  { value: '%Y-%m-%d', label: '2001-02-01' },
]

export const DateFormatSettings = () => {
  return (
    <>
      <SectionTitle>Date Format</SectionTitle>
      <div className="mb-3">
        Optionally, set a custom date format for your institution. This will set
        the formatting for all date-type Organization Attributes and Contact
        Fields in the platform as well as in messages sent to contacts.
      </div>
      <hr className="w-100" />
      <DateFormatSelect />
    </>
  )
}
const DateFormatSelect = () => {
  const instDateFormat = useSelector(getInstitutionDateFormatSettings)
  const dispatch = useDispatch()

  const selectedDateFormat: IOption | undefined = VALID_DATE_FORMATS.find(
    elem => elem.value === instDateFormat
  )

  const onUpdate = (dateFormat: IOption | null | undefined) => {
    if (
      dateFormat === undefined ||
      (dateFormat &&
        VALID_DATE_FORMATS.map(elem => elem.value).includes(dateFormat.value))
    ) {
      Api.updateDateFormat({ dateFormat: dateFormat?.value }).then(res => {
        if (isRight(res)) {
          dispatch(updateDateFormat.success(res.right))
          toast.success('Successfully updated setting.')
        } else {
          toast.error('Failure updating setting.')
        }
      })
    }
  }

  return (
    <Select
      className={classnames(
        'form-control',
        'p-0',
        'border-0',
        'min-width-200px',
        'w-25',
        'mb-5',
        'mt-3'
      )}
      classNamePrefix="react-select"
      name="dateFormat"
      placeholder="Select date format..."
      value={selectedDateFormat}
      onChange={option => {
        if (Array.isArray(option)) {
          return
        }
        onUpdate(option)
      }}
      options={VALID_DATE_FORMATS}
    />
  )
}
