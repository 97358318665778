import classNames from 'classnames'
import { Button } from 'components/Button/Button'
import {
  CheckboxChannelFilter,
  RadioFilter,
} from 'components/ChannelsCheckboxList/CheckboxChannelFilter/CheckboxChannelFilter'
import { Chip } from 'components/Chip/Chip'

import { ReadOnlyContactSegmentSelect } from 'components/ContactSegmentSelect/ContactSegmentSelect'
import { EventTrackerAttrAdder } from 'components/EventTracker/EventTracker'
import 'components/FilterContactsModal/FilterContactsModal.scss'
import {
  FilterChipsDataType,
  getAudienceIdFromParam,
  getChannelFiltersObj,
  getRelativeDateRangeFilterObj,
  getStatusFiltersObj,
  getTestUserFilterObj,
  handleUpdateQueryParams,
  useDataPointFiltersFromQueryParams,
  useParseChipsData,
} from 'components/FilterContactsModal/filterContactsModalUtils'
import {
  DEFAULT_DATE_QUERY_END,
  DEFAULT_DATE_QUERY_START,
  DEFAULT_DATE_RANGE_DAYS,
  DEFAULT_FILTERS_INITIAL_STATE,
  IFilterStates,
  clearFilterWithKey,
  setFilters,
  setAudienceFilterAction,
  setChannelFilters,
  setRelativeDateRange,
  setStatusFilters,
  setTestUserFilter,
  FilterContactsActions,
  filterContactsReducer,
} from 'components/FilterContactsModal/filterContactsReducer'
import { FilterIcon } from 'components/Icons/FilterIcon/FilterIcon'
import { Input } from 'components/Input/Input'
import { ReadOnlyDatePicker } from 'components/ReadOnlyDatePicker/ReadOnlyDatePicker'
import Select from 'components/Select/SelectV2'
import { SimpleModalHeader } from 'components/SimpleModalHeader/SimpleModalHeader'
import { capitalize, isUndefined } from 'lodash'
import React, { useReducer } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { ChannelEnum, channelToTransport } from 'store/transport'
import {
  getInstitutionChannelsWithDefaults,
  getInstitutionTimeZone,
} from 'store/triage/profile/selectors'
import { useSelector } from 'util/hooks'
import { removeQueryFilter } from 'util/queryFilters'
import { enumKeyFromString } from 'util/string'

export const QUERY_PARAM_DATE_FORMAT = 'yyyy-MM-dd'

export const FilterEnum = {
  channel: 'channel',
  filter: 'filter',
  audience: 'audience',
  testUser: 'testUser',
  relativeDate: 'relativeDate',
} as const

export type Filter = typeof FilterEnum[keyof typeof FilterEnum]

export const StatusEnum = {
  active: 'active',
  archived: 'archived',
} as const

export const TestUserEnum = {
  test: 'test',
  nonTest: 'non-test',
} as const

export const RelativeDateRangeEnum = {
  last: 'last',
  startingOn: 'starting on',
  dateRange: 'date range',
} as const

export type RelativeDateRange = typeof RelativeDateRangeEnum[keyof typeof RelativeDateRangeEnum]

export interface IContactsListFilterModalProps {
  onClose: () => void
  onSubmit?: (localState: IFilterStates) => void
  initialState?: IFilterStates
  dispatch: React.Dispatch<FilterContactsActions>
  show: boolean
  enabledFilters: {
    [key in Filter]?: {
      multi?: boolean
    }
  }
  shouldResetFilters?: boolean
  eventLocation?: string
  shouldUseQueryParams?: boolean
}

const mapChannelFiltersToEventTracker: { [key: string]: string } = {
  [ChannelEnum.web_bot]: 'web chat',
  [ChannelEnum.sms]: 'sms',
  [ChannelEnum.facebook]: 'facebook messenger',
  [ChannelEnum.slack]: 'slack',
}

interface IChips {
  enabledFilters: {
    [key in Filter]?: {
      multi?: boolean
      actionType?: 'clear' | 'change'
    }
  }
  shouldUseQueryParams?: boolean
  onChange?: () => void
  eventLocation?: string
}
interface IChipsComponent extends Omit<IChips, 'shouldUseQueryParams'> {
  handleRemoveFilters: (
    filterKey: string,
    filterValue: string | undefined
  ) => void
  filterData: FilterChipsDataType
}

export const ChipsComponent = ({
  enabledFilters,
  eventLocation,
  filterData,
  onChange,
  handleRemoveFilters,
}: IChipsComponent) => {
  if (!filterData) {
    return null
  }
  return (
    <div className="d-flex w-100 flex-wrap">
      {Object.keys(filterData).map(elem => {
        const typedElem = enumKeyFromString(elem, FilterEnum)
        const elemFilterData = filterData[typedElem]
        const v = elemFilterData.value
        if (!(typedElem in enabledFilters)) {
          return null
        }
        const actionType = enabledFilters[typedElem]?.actionType ?? 'change'
        const restActionProps = (valueToRemove?: string) =>
          actionType === 'clear'
            ? {
                onClear: () =>
                  elemFilterData.params.forEach(elem => {
                    handleRemoveFilters(elem, valueToRemove)
                  }),
              }
            : { onChange: () => onChange?.() }

        if (Array.isArray(v)) {
          const areMultipleFiltersAllowed = !!enabledFilters[typedElem]?.multi
          // if multiple filters are not allowed, only select the first value
          return (areMultipleFiltersAllowed ? v : v.slice(0, 1)).map(k => {
            return k ? (
              <Chip
                className="mr-2"
                key={`${k}-${elemFilterData.humanize(k)}`}
                label={elemFilterData.humanize(k)}
                type={actionType}
                eventLocation={eventLocation}
                eventAction="click"
                eventObject={typedElem}
                {...restActionProps(k)}
              />
            ) : null
          })
        } else {
          return v ? (
            <Chip
              className="mr-2"
              key={`${v}-${elemFilterData.humanize(v)}`}
              label={elemFilterData.humanize(v)}
              type={actionType}
              eventLocation={eventLocation}
              eventAction="click"
              eventObject={typedElem}
              {...restActionProps()}
            />
          ) : null
        }
      })}
    </div>
  )
}

export const ChipsRenderFromQueryParams = (props: IChips) => {
  const history = useHistory()
  const filterData = useDataPointFiltersFromQueryParams()
  const handleRemoveFilters = (
    filterKey: string,
    filterValue: string | undefined
  ) => history.push(removeQueryFilter(window.location, filterKey, filterValue))
  return (
    <ChipsComponent
      filterData={filterData}
      handleRemoveFilters={handleRemoveFilters}
      {...props}
    />
  )
}

export const Chips = ChipsRenderFromQueryParams

const noneSelectedChannelFilters = {
  [ChannelEnum.web_bot]: false,
  [ChannelEnum.sms]: false,
  [ChannelEnum.facebook]: false,
  [ChannelEnum.slack]: false,
}

function getFiltersStateFromQueryParams({
  allowMultipleChannels,
  locationSearch,
  timeZone,
}: {
  allowMultipleChannels: boolean
  locationSearch: string
  timeZone: string
}) {
  const relativeDateRange = getRelativeDateRangeFilterObj({
    locationSearch,
    timeZone,
  })
  return {
    channelFiltersObj: getChannelFiltersObj(allowMultipleChannels),
    statusFilterObj: getStatusFiltersObj(),
    testUserFilterObj: getTestUserFilterObj(),
    relativeDateRangeObj: relativeDateRange,
    audienceFilter: getAudienceIdFromParam(),
  }
}

/* Filter by channel or archived/active or audience or date ranges. */
export function ContactsListFilterModal({
  onClose,
  onSubmit,
  initialState,
  dispatch,
  enabledFilters,
  show,
  shouldResetFilters,
  shouldUseQueryParams = true,
  eventLocation,
}: IContactsListFilterModalProps) {
  const history = useHistory()
  const location = useLocation()
  const timeZone = useSelector(getInstitutionTimeZone)

  const allowMultipleChannels = !!enabledFilters[FilterEnum.channel]?.multi

  // we track changes locally, and update the actual state on after the user clicks `Apply Filter`
  const [localState, localDispatch] = useReducer(
    filterContactsReducer,
    initialState ?? DEFAULT_FILTERS_INITIAL_STATE
  )

  const institutionChannels = useSelector(getInstitutionChannelsWithDefaults)

  const refetchFilters = React.useCallback(
    (locationSearch: string) => {
      if (shouldUseQueryParams) {
        dispatch(
          setFilters(
            getFiltersStateFromQueryParams({
              allowMultipleChannels,
              locationSearch,
              timeZone,
            })
          )
        )
      }
    },
    [dispatch, allowMultipleChannels, timeZone, shouldUseQueryParams]
  )

  React.useEffect(() => {
    if (initialState) {
      localDispatch(setFilters(initialState))
    }
  }, [initialState, localDispatch])

  React.useEffect(() => {
    if (shouldResetFilters) {
      dispatch(setFilters(DEFAULT_FILTERS_INITIAL_STATE))
    }
  }, [shouldResetFilters, dispatch])

  React.useEffect(() => {
    refetchFilters(location.search)
  }, [location.search, refetchFilters])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    onSubmit?.(localState)
    dispatch(setFilters(localState))
    if (shouldUseQueryParams) {
      handleUpdateQueryParams({ enabledFilters, history, ...localState })
    }
    onClose()
  }

  const isClearFiltersDisabled = () =>
    Object.values(localState.channelFiltersObj).every(elem => elem === false) &&
    Object.values(localState.statusFilterObj).every(elem => elem === false) &&
    Object.values(localState.testUserFilterObj).every(elem => elem === false) &&
    isUndefined(localState.audienceFilter) &&
    localState.relativeDateRangeObj.relativeDateRangeType ===
      RelativeDateRangeEnum.last &&
    localState.relativeDateRangeObj.daysAgo === DEFAULT_DATE_RANGE_DAYS

  interface IOption {
    value: string
    label: string
  }

  const relativeDateFilterOptions: IOption[] = [
    {
      label: 'Last...',
      value: RelativeDateRangeEnum.last,
    },
    {
      label: 'Starting on...',
      value: RelativeDateRangeEnum.startingOn,
    },
    {
      label: 'Date Range...',
      value: RelativeDateRangeEnum.dateRange,
    },
  ]

  return (
    <Modal isOpen={show} size="lg" className="filter-modal">
      <div className="text-mainstay-dark-blue-80 h-100">
        <SimpleModalHeader
          cancelTrackingEvent={{
            location: eventLocation,
            action: 'click',
            object: 'close filter',
          }}
          onClose={onClose}
          text="Filters"
        />
        <ModalBody>
          {FilterEnum.channel in enabledFilters && (
            <div className="px-4">
              <div className="pt-2 pb-2 filter-modal-sub-header">Channels</div>
              <div className="d-flex flex-column">
                {Object.keys(ChannelEnum).map(elem => {
                  const typedElem = enumKeyFromString(elem, ChannelEnum)
                  if (!institutionChannels.includes(typedElem)) {
                    return null
                  }
                  return (
                    <CheckboxChannelFilter
                      key={elem}
                      type={channelToTransport(typedElem)}
                      checked={localState.channelFiltersObj[typedElem]}
                      onChange={() => {
                        localDispatch(
                          setChannelFilters({
                            ...(enabledFilters[FilterEnum.channel]?.multi
                              ? localState.channelFiltersObj
                              : noneSelectedChannelFilters),
                            [typedElem]: !localState.channelFiltersObj[
                              typedElem
                            ],
                          })
                        )
                      }}
                      inputType={
                        enabledFilters[FilterEnum.channel]?.multi
                          ? 'checkbox'
                          : 'radio'
                      }
                      checkBoxSize="sm"
                      eventLocation={eventLocation}
                      eventAction="click"
                      eventObject={`${mapChannelFiltersToEventTracker[typedElem]} filter checkbox`}
                    />
                  )
                })}
              </div>
            </div>
          )}

          {FilterEnum.filter in enabledFilters && (
            <>
              <hr />
              <div className="px-4">
                <div className="pt-2 pb-2 filter-modal-sub-header">Status</div>
                <div className="d-flex flex-column">
                  {Object.keys(StatusEnum).map(elem => {
                    const typedElem = enumKeyFromString(elem, StatusEnum)
                    const otherStatus =
                      typedElem === 'active' ? 'archived' : 'active'
                    return (
                      <RadioFilter
                        key={elem}
                        valuesEnum={StatusEnum}
                        type={StatusEnum[typedElem]}
                        checked={localState.statusFilterObj[typedElem]}
                        onChange={() =>
                          localDispatch(
                            setStatusFilters({
                              ...localState.statusFilterObj,
                              [typedElem]: !localState.statusFilterObj[
                                typedElem
                              ],
                              [otherStatus]: !!localState.statusFilterObj[
                                otherStatus
                              ]
                                ? false
                                : localState.statusFilterObj[otherStatus],
                            })
                          )
                        }
                        eventLocation={eventLocation}
                        eventAction="click"
                        eventObject={`${elem} filter option`}
                      />
                    )
                  })}
                </div>
              </div>
            </>
          )}

          {FilterEnum.testUser in enabledFilters && (
            <>
              <hr />
              <div className="px-4 pb-3">
                <div className="pt-2 pb-2 filter-modal-sub-header">
                  Test User
                </div>
                <div className="d-flex flex-column">
                  {Object.keys(TestUserEnum).map(elem => {
                    const typedElem = enumKeyFromString(elem, TestUserEnum)
                    const otherStatus =
                      typedElem === 'test' ? 'nonTest' : 'test'
                    const statusKey = TestUserEnum[typedElem]
                    const otherStatusKey = TestUserEnum[otherStatus]
                    return (
                      <RadioFilter
                        key={elem}
                        valuesEnum={TestUserEnum}
                        type={typedElem}
                        checked={localState.testUserFilterObj[statusKey]}
                        onChange={() => {
                          localDispatch(
                            setTestUserFilter({
                              ...localState.testUserFilterObj,
                              [statusKey]: !localState.testUserFilterObj[
                                statusKey
                              ],
                              [otherStatusKey]: !!localState.testUserFilterObj[
                                otherStatusKey
                              ]
                                ? false
                                : localState.testUserFilterObj[otherStatusKey],
                            })
                          )
                        }}
                        eventLocation={eventLocation}
                        eventAction="click"
                        eventObject={`${elem} filter option`}
                      />
                    )
                  })}
                </div>
              </div>
            </>
          )}

          {FilterEnum.relativeDate in enabledFilters && (
            <>
              <hr />
              <div className="px-4 pb-2">
                <div className="pt-2 pb-2 filter-modal-sub-header">
                  Time range
                </div>
                <div className="d-inline-flex align-items-center w-100">
                  <Select
                    value={{
                      label: `${capitalize(
                        localState.relativeDateRangeObj.relativeDateRangeType
                      )}...`,
                      value:
                        localState.relativeDateRangeObj.relativeDateRangeType,
                    }}
                    placeholder="Select..."
                    options={relativeDateFilterOptions}
                    className={classNames(
                      'form-control',
                      'w-25',
                      'p-0',
                      'border-0',
                      'mr-3',
                      'text-mainstay-dark-blue'
                    )}
                    classNamePrefix="react-select"
                    components={{
                      IndicatorSeparator: null,
                    }}
                    onChange={option => {
                      if (!Array.isArray(option) && option) {
                        const daysAgo = DEFAULT_DATE_RANGE_DAYS
                        const relStartDate = DEFAULT_DATE_QUERY_START
                        const relEndDate = DEFAULT_DATE_QUERY_END
                        const newRelativeDateRangeObj = {
                          daysAgo,
                          relStartDate,
                          relEndDate,
                        }
                        localDispatch(
                          setRelativeDateRange({
                            ...newRelativeDateRangeObj,
                            /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
                            relativeDateRangeType: option.value as RelativeDateRange,
                          })
                        )
                      }
                    }}
                  />
                  {localState.relativeDateRangeObj.relativeDateRangeType ===
                  RelativeDateRangeEnum.dateRange ? (
                    <>
                      <span className="mr-2">From</span>
                      <ReadOnlyDatePicker
                        value={localState.relativeDateRangeObj.relStartDate}
                        onDayChange={(date: Date) => {
                          localDispatch(
                            setRelativeDateRange({
                              ...localState.relativeDateRangeObj,
                              relStartDate: date,
                            })
                          )
                        }}
                        dayPickerProps={{
                          disabledDays: {
                            after: localState.relativeDateRangeObj.relEndDate,
                          },
                        }}
                        classNames={{
                          container: 'DayPickerInput-Container',
                          overlayWrapper: 'DayPickerInput-OverlayWrapper',
                          overlay: 'DayPickerInput-Overlay',
                          maxWidth: '9.75em',
                        }}
                        eventLocation={eventLocation}
                        eventObject="from date"
                      />
                      <span className="filter-modal-to-field">to</span>
                      <ReadOnlyDatePicker
                        value={localState.relativeDateRangeObj.relEndDate}
                        onDayChange={(date: Date) => {
                          localDispatch(
                            setRelativeDateRange({
                              ...localState.relativeDateRangeObj,
                              relEndDate: new Date(
                                date.setHours(23, 59, 59, 0)
                              ),
                            })
                          )
                        }}
                        dayPickerProps={{
                          disabledDays: {
                            before:
                              localState.relativeDateRangeObj.relStartDate,
                            after: new Date(),
                          },
                        }}
                        classNames={{
                          container: 'DayPickerInput-Container',
                          overlayWrapper: 'DayPickerInput-OverlayWrapper',
                          overlay: 'DayPickerInput-Overlay',
                          maxWidth: '9.75em',
                        }}
                        eventLocation={eventLocation}
                        eventObject="to date"
                      />
                    </>
                  ) : localState.relativeDateRangeObj.relativeDateRangeType ===
                    RelativeDateRangeEnum.last ? (
                    <>
                      <Input
                        value={localState.relativeDateRangeObj.daysAgo.toString()}
                        type="number"
                        step="1"
                        min={1}
                        max={999}
                        placeholder=""
                        className="filter-modal-num-input"
                        onChange={e => {
                          localDispatch(
                            setRelativeDateRange({
                              ...localState.relativeDateRangeObj,
                              daysAgo: Number(e.target.value),
                            })
                          )
                        }}
                      />
                      <span className="ml-2 text-muted">
                        Days (including today)
                      </span>
                    </>
                  ) : localState.relativeDateRangeObj.relativeDateRangeType ===
                    RelativeDateRangeEnum.startingOn ? (
                    <>
                      <ReadOnlyDatePicker
                        value={localState.relativeDateRangeObj.relStartDate}
                        onDayChange={(date: Date) => {
                          localDispatch(
                            setRelativeDateRange({
                              ...localState.relativeDateRangeObj,
                              relStartDate: new Date(
                                date.setHours(23, 59, 59, 0)
                              ),
                            })
                          )
                        }}
                        dayPickerProps={{
                          disabledDays: {
                            after: new Date(),
                          },
                        }}
                        classNames={{
                          container: 'DayPickerInput-Container',
                          overlayWrapper: 'DayPickerInput-OverlayWrapper',
                          overlay: 'DayPickerInput-Overlay',
                          maxWidth: '136px',
                        }}
                        eventLocation={eventLocation}
                        eventObject="to date"
                      />
                      <span className="ml-2 text-muted">including today</span>
                    </>
                  ) : null}
                </div>
              </div>
            </>
          )}

          {FilterEnum.audience in enabledFilters && (
            <>
              <hr />
              <div className="px-4 pb-3">
                <div className="pt-2 pb-2 filter-modal-sub-header">
                  Audience
                </div>
                <ReadOnlyContactSegmentSelect
                  selectedFilterId={localState.audienceFilter}
                  onChange={selected => {
                    if (!Array.isArray(selected)) {
                      localDispatch(setAudienceFilterAction(selected?.value))
                    }
                  }}
                  includeAllContactsOption={true}
                  defaultToAllContacts={true}
                  onNewSegment={() => {
                    const newWindow = window.open('/audiences', '_blank')
                    newWindow?.focus()
                  }}
                  className="w-360"
                  customClassName="mb-2"
                  eventLocation={eventLocation}
                  isClearable={false}
                />
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button
            type="button"
            disabled={isClearFiltersDisabled()}
            onClick={() =>
              localDispatch(setFilters(DEFAULT_FILTERS_INITIAL_STATE))
            }
            eventAction="click"
            eventLocation={eventLocation}
            className="text-mainstay-dark-blue-65 bg-white"
            eventObject="clear filter">
            Reset Filter
          </Button>
          <div>
            <Button
              className="btn btn-secondary-teal px-3 py-2"
              onClick={handleSubmit}
              eventAction="click"
              eventLocation={eventLocation}
              eventObject="apply filter">
              Apply Filter
            </Button>
            <Button
              className="ml-2 btn text-mainstay-dark-blue-65 border-color-mainstay-dark-blue-65 border-1px bg-white px-3 py-2"
              eventAction="click"
              onClick={onClose}
              eventLocation={eventLocation}
              eventObject="close filter">
              Close
            </Button>
          </div>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export interface IFilterModalState {
  channel: string[]
  filter: string[]
  testUser: string[]
  audience?: string
  date?: string
}

interface IFilterButton {
  onClick: () => void
  className?: string
  eventLocation?: string
}

export const FilterButton = ({
  eventLocation,
  className,
  onClick,
}: IFilterButton) => {
  return (
    <EventTrackerAttrAdder
      eventLocation={eventLocation}
      eventAction="click"
      eventObject="filter">
      <Button
        className={classNames(
          'btn action-btn unset-box-shadow d-flex bg-transparent p-0',
          className
        )}
        onClick={onClick}>
        <FilterIcon />
      </Button>
    </EventTrackerAttrAdder>
  )
}

interface IDataPointFilters {
  initialFilterValues: IFilterStates
  eventLocation?: string
  className?: string
}

export const DataPointFilters = ({
  initialFilterValues,
  className,
  dispatch,
  eventLocation,
}: IDataPointFilters & {
  dispatch: React.Dispatch<FilterContactsActions>
}) => {
  const [showFiltersModal, setShowFiltersModal] = React.useState(false)
  const filterChipsData = useParseChipsData(initialFilterValues)
  const handleRemoveFilters = (
    filterKey: string,
    filterValue: string | undefined
  ) =>
    dispatch(
      clearFilterWithKey({
        key: enumKeyFromString(filterKey, FilterEnum),
        val: filterValue,
      })
    )

  return (
    <>
      <ContactsListFilterModal
        eventLocation={eventLocation}
        show={showFiltersModal}
        initialState={initialFilterValues}
        dispatch={dispatch}
        enabledFilters={{
          [FilterEnum.channel]: { multi: true },
          [FilterEnum.relativeDate]: {},
          [FilterEnum.audience]: {},
        }}
        onClose={() => setShowFiltersModal(false)}
        shouldUseQueryParams={false}
      />
      <div className={classNames('d-flex align-items-center', className)}>
        <FilterButton
          eventLocation={eventLocation}
          className="mr-3"
          onClick={() => setShowFiltersModal(true)}
        />
        <ChipsComponent
          filterData={filterChipsData}
          handleRemoveFilters={handleRemoveFilters}
          eventLocation={eventLocation}
          onChange={() => setShowFiltersModal(true)}
          enabledFilters={{
            [FilterEnum.channel]: { multi: true, actionType: 'clear' },
            [FilterEnum.relativeDate]: { actionType: 'change' },
            [FilterEnum.audience]: { actionType: 'clear' },
          }}
        />
      </div>
    </>
  )
}
