import { ActionType, createAsyncAction } from 'typesafe-actions'

import { AudienceChoice } from 'page/conversations-v2/ConversationFilters'

export const fetchAudiencesChoices = createAsyncAction(
  '@@MASCOT/CONVERSATION_FILTERS_FETCH_AUDIENCES_CHOICES_START',
  '@@MASCOT/CONVERSATION_FILTERS_FETCH_AUDIENCES_CHOICES_SUCCESS',
  '@@MASCOT/CONVERSATION_FILTERS_FETCH_AUDIENCES_CHOICES_FAILURE'
)<void, AudienceChoice[], void>()

export const fetchAudiencesPinned = createAsyncAction(
  '@@MASCOT/CONVERSATION_FILTERS_FETCH_AUDIENCES_PINNED_START',
  '@@MASCOT/CONVERSATION_FILTERS_FETCH_AUDIENCES_PINNED_SUCCESS',
  '@@MASCOT/CONVERSATION_FILTERS_FETCH_AUDIENCES_PINNED_FAILURE'
)<void, AudienceChoice[], void>()

export type SelectAudiencePayload = AudienceChoice & {
  readonly selected: boolean
}
export const selectAudience = createAsyncAction(
  '@@MASCOT/CONVERSATION_FILTERS_SELECT_AUDIENCE_START',
  '@@MASCOT/CONVERSATION_FILTERS_SELECT_AUDIENCE_SUCCESS',
  '@@MASCOT/CONVERSATION_FILTERS_SELECT_AUDIENCE_FAILURE'
)<SelectAudiencePayload, SelectAudiencePayload, SelectAudiencePayload>()

export type IActions =
  | ActionType<typeof fetchAudiencesChoices>
  | ActionType<typeof fetchAudiencesPinned>
  | ActionType<typeof selectAudience>
