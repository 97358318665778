import * as api from 'api'
import cls from 'classnames'
import {
  MultipleLinksViewer,
  ReferenceContentCard,
} from 'components/ContentCard/ContentCard'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { ILinkWithMiddleEllipsisProps } from 'components/LinkWithMiddleEllipsis/LinkWithMiddleEllipsis'
import { CenteredLoader } from 'components/Loader/Loader'
import { CONVERSATION_ROUTES } from 'const/routes'
import { useBotMessageExpanded } from 'page/conversations-v2/ConversationsDetail/BotReply/hooks'
import {
  MessageWithAttributes,
  templateHasAttributes,
} from 'page/conversations-v2/ConversationsDetail/BotReply/MessageWithAttributes'
import {
  RagtimeReply,
  ReplyDescription,
} from 'page/conversations-v2/ConversationsDetail/BotReply/ReplyDescription'
import { ReplyFailure } from 'page/conversations-v2/ConversationsDetail/BotReply/ReplyFailure'
import { ReplyHeader } from 'page/conversations-v2/ConversationsDetail/BotReply/ReplyHeader'
import 'page/conversations-v2/ConversationsDetail/ConversationsDetail.scss'
import {
  FALLBACK_URL_PARAM,
  MessageOrigin,
  UNDERSTANDING_URL_PARAM,
} from 'page/conversations-v2/ConversationsDetail/index'
import React from 'react'
import {
  isFailure,
  isSuccessOrRefetching,
  isUnresolved,
  WebData,
} from 'store/webdata'
import { assertNever } from 'util/exhaustiveness'
import { updateQueryString } from 'util/string'

function linkFromOrigin(origin: api.UnderstandingOriginType) {
  return origin.fallback
    ? // fallback responses open in a new tab.
      {
        pathname: location.pathname,
        search: updateQueryString(
          {
            [FALLBACK_URL_PARAM]: origin.id,
            [UNDERSTANDING_URL_PARAM]: undefined,
          },
          location.search
        ),
      }
    : // normal understandings open in the current page based on a query parameter.
      {
        pathname: location.pathname,
        search: updateQueryString(
          {
            [UNDERSTANDING_URL_PARAM]: origin.id,
            [FALLBACK_URL_PARAM]: undefined,
          },
          location.search
        ),
      }
}

export function generateLinksFromEmbeddedSources(
  sources: api.EmbeddedDataSourceType[]
): ILinkWithMiddleEllipsisProps[] {
  return sources.map(
    ({ understanding_mongo_id, source_url, answer_text, source_title }) => {
      if (understanding_mongo_id && answer_text) {
        return {
          target: `${CONVERSATION_ROUTES.KNOWLEDGE}/?understanding_id=${understanding_mongo_id}`,
          isInternal: true,
          displayText: answer_text,
        }
      }
      if (source_url) {
        return {
          target: source_url,
          isInternal: false,
          displayText: source_title || source_url,
        }
      }
      return { target: null }
    }
  )
}

function AIGeneratedMessage({
  message,
}: {
  readonly message: WebData<api.ExpandedMessageType>
}) {
  if (isUnresolved(message)) {
    return <CenteredLoader />
  }
  if (isFailure(message)) {
    return <ReplyFailure />
  }
  if (message.data.origin.kind === 'ai_assisted_live_chat') {
    return <div className="px-3 py-2">{message.data.message}</div>
  }
  return <></>
}

function formatScriptName(origin: api.ScriptOriginType): string {
  if (origin.internal) {
    return '(Mainstay Default Script)'
  }
  return origin.name ?? '(Unnamed Script)'
}

export function BotReply({
  messageId,
  origin,
  children,
}: {
  readonly messageId: string
  readonly children: React.ReactNode
  readonly origin: MessageOrigin
}) {
  const [autoReplyExpanded, setAutoReplyExpanded] = React.useState(false)
  const clickHeader = React.useCallback(() => {
    setAutoReplyExpanded(s => !s)
  }, [])
  const [view, setView] = React.useState<
    'message' | 'attributes' | 'ai_assisted_live_chat'
  >('message')
  const genAiTransactionId =
    origin.kind === 'ai_assisted_live_chat' || origin.kind === 'ai_generated'
      ? origin.generativeTransactionId
      : undefined
  const message = useBotMessageExpanded(
    messageId,
    genAiTransactionId,
    autoReplyExpanded
  )
  return (
    <>
      <ReplyHeader
        messageId={messageId}
        origin={origin}
        onClick={clickHeader}
        expanded={autoReplyExpanded}
      />
      {autoReplyExpanded ? (
        <div
          className={cls(
            'bg-blue-grey-015',
            'border-radius-md',
            'border-top-left-radius-0',
            'border-top-right-radius-0',
            'pt-1',
            'd-flex',
            'flex-column'
          )}>
          {isUnresolved(message) ? (
            <CenteredLoader className="content-card-height" />
          ) : isFailure(message) ? (
            <ReplyFailure />
          ) : (
            <>
              {message.data.origin.kind === 'script' && (
                <ReferenceContentCard
                  dataId={message.data.origin.id}
                  type="Script"
                  icon={<AHIcon name="comment" />}
                  link={
                    message.data.origin.name != null &&
                    !message.data.origin.internal
                      ? `/campaign-scripts/${message.data.origin.id}/view`
                      : undefined
                  }>
                  {formatScriptName(message.data.origin)}
                </ReferenceContentCard>
              )}
              {message.data.origin.kind === 'script' &&
                message.data.ragtimeInfo !== null && (
                  <>
                    <RagtimeReply message={message.data} />
                    {message.data.ragtimeInfo.embeddedSourcesData.length > 0 ? (
                      <ReferenceContentCard type="Source">
                        <MultipleLinksViewer
                          linkProps={generateLinksFromEmbeddedSources(
                            message.data.ragtimeInfo.embeddedSourcesData
                          )}
                        />
                      </ReferenceContentCard>
                    ) : (
                      <span className="px-3 fs-13px">No sources used</span>
                    )}
                  </>
                )}
              {message.data.origin.kind === 'understanding' && (
                <ReferenceContentCard
                  dataId={message.data.origin.id}
                  type="Understanding"
                  icon={<AHIcon name="knowledge-base" />}
                  openInNewTab={false}
                  link={linkFromOrigin(message.data.origin)}>
                  {message.data.origin.sampleQuestion}
                </ReferenceContentCard>
              )}
              {message.data.origin.kind === 'understanding_deleted' && (
                <div className="small w-100 d-flex justify-content-center">
                  {' '}
                  Understanding Deleted{' '}
                </div>
              )}
              {message.data.origin.kind === 'understanding_not_found' && (
                <div className="small w-100 d-flex justify-content-center">
                  {' '}
                  Understanding Not Found{' '}
                </div>
              )}
              {(message.data.origin.kind === 'ai_assisted_live_chat' ||
                message.data.origin.kind === 'ai_generated') &&
                (message.data.origin.embeddedSourcesData.length > 0 ? (
                  <ReferenceContentCard type="Source">
                    <MultipleLinksViewer
                      linkProps={generateLinksFromEmbeddedSources(
                        message.data.origin.embeddedSourcesData
                      )}
                    />
                  </ReferenceContentCard>
                ) : (
                  <span className="px-3 fs-13px">No sources used</span>
                ))}

              <ReplyDescription
                generated={
                  origin.kind === 'fallback' &&
                  origin.generativeTransactionId !== undefined &&
                  origin.generativeTransactionId !== null
                }
                message={message.data}
              />
            </>
          )}
          <div className="mt-3">
            <div className="d-flex">
              <div className="border-bottom d-flex pl-3 fs-13px">
                <div
                  className={cls('pointer', {
                    'conv-v2-und-expand-tab-border': view === 'message',
                  })}
                  onClick={() => setView('message')}>
                  Response
                </div>
              </div>
              {isSuccessOrRefetching(message) &&
              templateHasAttributes(message.data.messageTemplate) ? (
                <div className="border-bottom d-flex px-3 fs-13px">
                  <div
                    className={cls('pointer', {
                      'conv-v2-und-expand-tab-border': view === 'attributes',
                    })}
                    onClick={() => setView('attributes')}>
                    View Attributes
                  </div>
                </div>
              ) : null}
              {isSuccessOrRefetching(message) &&
              origin.kind === 'ai_assisted_live_chat' &&
              genAiTransactionId ? (
                <div className="border-bottom d-flex px-3 fs-13px">
                  <div
                    className={cls('pointer', {
                      'conv-v2-und-expand-tab-border':
                        view === 'ai_assisted_live_chat',
                    })}
                    onClick={() => setView('ai_assisted_live_chat')}>
                    AI Suggested Response
                  </div>
                </div>
              ) : null}
            </div>
            <div className="bg-blue-grey-050 border-radius-md border-top-left-radius-0 border-top-right-radius-0">
              {view === 'message' ? (
                children
              ) : view === 'attributes' ? (
                <MessageWithAttributes message={message} />
              ) : view === 'ai_assisted_live_chat' ? (
                <AIGeneratedMessage message={message} />
              ) : (
                assertNever(view)
              )}
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  )
}
