import { createAsyncAction, ActionType } from 'typesafe-actions'
import { IUpdateUserLocationResponseData } from 'api/response'

export const fetchUserByLocation = createAsyncAction(
  '@@MASCOT/VIEWER_LIST/FETCH_VIEWER_LIST_START',
  '@@MASCOT/VIEWER_LIST/FETCH_VIEWER_LIST_SUCCESS',
  '@@MASCOT/VIEWER_LIST/FETCH_VIEWER_LIST_FAIL'
)<
  { path: string },
  {
    path: string
    data: Array<{ lastName: string; firstName: string; isActive: boolean }>
  },
  { path: string; err: { message: string } }
>()

export const updateUserLocation = createAsyncAction(
  '@@MASCOT/VIEWER_LIST/UPDATE_USER_LOCATION_START',
  '@@MASCOT/VIEWER_LIST/UPDATE_USER_LOCATION_SUCCESS',
  '@@MASCOT/VIEWER_LIST/UPDATE_USER_LOCATION_FAIL'
)<void, IUpdateUserLocationResponseData, { message: string }>()

export type IUserLocationActions =
  | ActionType<typeof fetchUserByLocation>
  | ActionType<typeof updateUserLocation>
