import ClassNames from 'classnames'

import { Button, IButtonProps } from 'components/Button/Button'

export function ActionButton(props: IButtonProps) {
  const passProps = { ...props }
  delete passProps.className

  return (
    <Button
      className={ClassNames(
        'btn bg-status-dropdown-item-non-selected text-mainstay-dark-blue-70 text-left list-group-item',
        props.className
      )}
      {...passProps}
    />
  )
}
