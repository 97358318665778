import * as React from 'react'
import { OutsideClickHandler } from 'components/OutsideClickHandler/OutsideClickHandler'

export default class EditDisplayComponent extends React.PureComponent<{
  editor: React.ReactNode
  display: React.ReactNode
  editing: boolean
  onClick: (event?: React.MouseEvent) => void
  onClickOutside: () => void
  children?: never
}> {
  handleClick = (e: React.MouseEvent) => {
    this.props.onClick(e)
  }

  handleClickOutside = () => {
    this.props.onClickOutside()
  }

  render() {
    return (
      <OutsideClickHandler
        containerClassName="edit-display-component"
        excludeClassname="modal exclude-delete-button"
        onClickOutside={this.handleClickOutside}>
        <div
          className="w-100 edit-display-component"
          onClick={this.handleClick}
          tabIndex={-1}>
          {this.props.editing ? this.props.editor : this.props.display}
        </div>
      </OutsideClickHandler>
    )
  }
}
