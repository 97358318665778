import React from 'react'
import { Drawer } from 'components/MaterialComponents/MaterialComponents'
import classNames from 'classnames'

interface IPanelDrawerProps {
  readonly children: React.ReactNode
  readonly onClose: () => void
  readonly header: React.ReactNode
  readonly open?: boolean
  readonly width?: string
  readonly showHr?: boolean
  readonly panelContentRef?: React.RefObject<HTMLElement>
  readonly panelContentClassName?: string
  readonly panelContainerClassName?: string
  readonly onScroll?: () => void
  readonly className?: string
}

export function PanelDrawer({
  children,
  onClose,
  header,
  open = true,
  showHr = true,
  width = '600px',
  panelContentRef,
  panelContentClassName,
  panelContainerClassName,
  className,
  onScroll,
}: IPanelDrawerProps) {
  return (
    <Drawer
      open={open}
      anchor="right"
      transitionDuration={500}
      disableEnforceFocus={true}
      className={className}
      onBackdropClick={onClose}>
      <div style={{ width }} className="h-100">
        <Panel
          onScroll={onScroll}
          header={header}
          showHr={showHr}
          panelContentClassName={panelContentClassName}
          panelContainerClassName={panelContainerClassName}
          panelContentRef={panelContentRef}>
          {children}
        </Panel>
      </div>
    </Drawer>
  )
}

function Panel({
  header,
  children,
  showHr = true,
  panelContentRef,
  panelContentClassName,
  panelContainerClassName,
  onScroll,
}: Pick<
  IPanelDrawerProps,
  | 'children'
  | 'header'
  | 'showHr'
  | 'panelContentClassName'
  | 'panelContainerClassName'
  | 'onScroll'
  | 'panelContentRef'
>) {
  return (
    <aside className="panel h-100">
      {header}
      {showHr && <hr className="text-blue-grey-200 m-0" />}
      <div className={classNames('overflow-y-auto', panelContainerClassName)}>
        <section
          onScroll={onScroll}
          ref={panelContentRef}
          className={classNames(
            'panel-content h-100 px-0 pt-3 align-items-center',
            panelContentClassName
          )}>
          {children}
        </section>
      </div>
    </aside>
  )
}
