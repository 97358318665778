import { Dispatch } from 'store/store'
import * as Api from 'api'
import { AxiosError } from 'axios'
import { toastOnHttpError500or400 } from 'api/http'
import {
  fetchCurrentInstitution,
  updateDefaultCounselor,
} from 'store/triage/institution/actions'

export const fetchCurrentInstitutionAsync = (dispatch: Dispatch) => {
  return () => {
    dispatch(fetchCurrentInstitution.request())
    return Api.getCurrentInstitution()
      .then(response => {
        dispatch(fetchCurrentInstitution.success(response.data))
      })
      .catch((err: AxiosError) => {
        toastOnHttpError500or400(err)
        dispatch(fetchCurrentInstitution.failure(err))
      })
  }
}

export const updateDefaultCounselorAsync = (dispatch: Dispatch) => (
  defaultCounselorId: number
) => {
  dispatch(updateDefaultCounselor.request())
  return Api.updateInstitutionData('me', { defaultCounselorId })
    .then(response => {
      dispatch(updateDefaultCounselor.success(response.data))
    })
    .catch((err: AxiosError) => {
      toastOnHttpError500or400(err)
      dispatch(updateDefaultCounselor.failure(err))
    })
}
