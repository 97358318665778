import React from 'react'
import cls from 'classnames'
import 'components/MainstayPageContainer/MainstayPageContainer.scss'
import MainstaySidebar, {
  IMainstaySidebarProps,
} from 'mainstay-ui-kit/MainstaySidebar/MainstaySidebar'
import { useDebounce, useLocalStorage } from 'util/hooks'
import { CSSTransition } from 'components/CSSTransition/CSSTransition'
import { isUndefined } from 'lodash'
import { EventTrackerAttrAdder } from 'components/EventTracker/EventTracker'
import scssVariables from 'scss/_variables.scss'

export const ChevronIcon = ({
  transform = undefined,
  width = '7',
  height = '10',
  fill = scssVariables.mainstayDarkBlue80,
}: {
  transform?: string
  fill?: string
  width?: string
  height?: string
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 7 10"
    fill="none"
    transform={transform}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.7 0.200196L6.49999 5.0002L1.69999 9.8002L0.575194 8.6754L4.25039 5.0002L0.575195 1.325L1.7 0.200196Z"
      fill={fill}
    />
  </svg>
)

const MainstayPageContent = ({
  showSidebar,
  className,
  children,
  passStyleToChild,
}: {
  showSidebar?: boolean
  className?: string
  children?: React.ReactElement
  passStyleToChild?: boolean
}) => {
  const classNames = cls('ms-page-content', className, {
    'ms-page-content--expanded': showSidebar,
  })

  if (children && passStyleToChild) {
    return React.cloneElement(children, {
      // tslint:disable-next-line:no-unsafe-any
      className: cls(classNames, children.props.className),
    })
  }

  return <div className={classNames}>{children}</div>
}

export const MainstayPage = ({
  className,
  children,
  pageContentClassName,
  sidebarContent,
  passStyleToChild,
  hideSidebar,
  ...restProps
}: IMainstaySidebarProps & {
  pageContentClassName?: string
  passStyleToChild?: boolean
  children?: React.ReactElement
  sidebarContent?: React.ReactElement
  hideSidebar?: boolean
}) => {
  const [showSidebar, setShowSidebar] = useLocalStorage(
    'ms-sidebar-visible',
    true
  )
  const toggleSidebar = () => setShowSidebar(() => !showSidebar)

  const animationDuration = 300
  const expandingBtnAnimationDelay = 40
  const moveBtnToExpanded = useDebounce(showSidebar, expandingBtnAnimationDelay)
  const isCollapsing = !showSidebar

  const [startExpanded, setStartExpanded] = React.useState<
    boolean | undefined
  >()
  React.useEffect(() => {
    if (isUndefined(startExpanded)) {
      setStartExpanded(showSidebar)
    } else if (startExpanded && !showSidebar) {
      setStartExpanded(false)
    }
  }, [showSidebar, startExpanded])

  const sideBarClassName = cls(className, {
    'mainstay-sidebar--expanded': !!startExpanded,
  })
  const btnClassName = cls('sidebar-btn', {
    'sidebar-btn--expanded': !!startExpanded,
  })

  const content = (
    <MainstayPageContent
      showSidebar={showSidebar}
      passStyleToChild={passStyleToChild}
      className={pageContentClassName}>
      {children}
    </MainstayPageContent>
  )

  if (hideSidebar) {
    return content
  }
  return (
    <>
      <CSSTransition
        in={isCollapsing ? false : moveBtnToExpanded}
        timeout={{
          enter: animationDuration - expandingBtnAnimationDelay,
          exit: animationDuration,
        }}
        classNames="sidebar-btn-transition-container">
        <EventTrackerAttrAdder
          eventLocation="sidebar"
          eventObject={showSidebar ? 'collapse' : 'expand'}
          eventAction="click">
          <button
            tabIndex={0}
            className={btnClassName}
            onClick={() => toggleSidebar()}>
            <ChevronIcon />
            <ChevronIcon />
          </button>
        </EventTrackerAttrAdder>
      </CSSTransition>
      <CSSTransition
        in={showSidebar}
        timeout={animationDuration}
        classNames="sidebar-transition-container"
        unmountOnExit>
        <div className="d-flex flex-column h-100 flex-shrink-none">
          <MainstaySidebar className={sideBarClassName} {...restProps}>
            {sidebarContent}
          </MainstaySidebar>
        </div>
      </CSSTransition>
      {content}
    </>
  )
}
