interface ICheckboxIconProps {
  readonly height?: number | string
  readonly width?: number | string
  readonly fill?: string
  readonly className?: string
}

export function CommentIcon({
  height = '24',
  width = '24',
  fill = '#FFFEFC',
  className,
}: ICheckboxIconProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.4305 10.8606C21.1802 10.8605 19.9614 10.4683 18.9458 9.73911C18.889 9.89116 18.7873 10.0224 18.6543 10.1154C18.5212 10.2083 18.3631 10.2587 18.2008 10.2598H7.43412C7.22166 10.2598 7.0179 10.1754 6.86766 10.0252C6.71743 9.87495 6.63303 9.67119 6.63303 9.45872C6.63303 9.24626 6.71743 9.0425 6.86766 8.89227C7.0179 8.74204 7.22166 8.65763 7.43412 8.65763H17.7842C17.1293 7.85062 16.6962 6.88677 16.5276 5.86121C16.359 4.83566 16.4608 3.78387 16.8229 2.80968C15.5207 2.43551 14.1723 2.24673 12.8174 2.24891C5.75183 2.24891 0 7.27976 0 13.4642C0 19.6486 5.75183 24.6794 12.8174 24.6794C14.1394 24.6779 15.4548 24.4946 16.7268 24.1347L21.1568 26.9545C21.2777 27.0321 21.4173 27.0758 21.5609 27.0809C21.7045 27.086 21.8468 27.0524 21.973 26.9836C22.0991 26.9148 22.2044 26.8133 22.2779 26.6898C22.3514 26.5663 22.3902 26.4253 22.3905 26.2816V20.8903C23.3928 19.9287 24.1927 18.7763 24.743 17.501C25.2933 16.2257 25.5829 14.8531 25.5948 13.4642C25.5913 12.3733 25.4072 11.2906 25.0501 10.2598C24.2338 10.6564 23.338 10.8618 22.4305 10.8606ZM15.8215 18.2707H9.81336C9.60089 18.2707 9.39713 18.1863 9.2469 18.0361C9.09667 17.8858 9.01227 17.6821 9.01227 17.4696C9.01227 17.2572 9.09667 17.0534 9.2469 16.9032C9.39713 16.7529 9.60089 16.6685 9.81336 16.6685H15.8215C16.034 16.6685 16.2378 16.7529 16.388 16.9032C16.5382 17.0534 16.6226 17.2572 16.6226 17.4696C16.6226 17.6821 16.5382 17.8858 16.388 18.0361C16.2378 18.1863 16.034 18.2707 15.8215 18.2707ZM18.4251 14.2653H7.20981C6.99735 14.2653 6.79359 14.1809 6.64336 14.0306C6.49312 13.8804 6.40872 13.6766 6.40872 13.4642C6.40872 13.2517 6.49312 13.048 6.64336 12.8977C6.79359 12.7475 6.99735 12.6631 7.20981 12.6631H18.4251C18.6375 12.6631 18.8413 12.7475 18.9915 12.8977C19.1418 13.048 19.2262 13.2517 19.2262 13.4642C19.2262 13.6766 19.1418 13.8804 18.9915 14.0306C18.8413 14.1809 18.6375 14.2653 18.4251 14.2653Z"
        fill={fill}
      />
      <path
        d="M22.4306 8.85789C24.6427 8.85789 26.436 7.06459 26.436 4.85244C26.436 2.64029 24.6427 0.846985 22.4306 0.846985C20.2184 0.846985 18.4251 2.64029 18.4251 4.85244C18.4251 7.06459 20.2184 8.85789 22.4306 8.85789Z"
        fill={fill}
      />
    </svg>
  )
}
