import * as React from 'react'
import { SectionTitle } from 'page/SettingsGeneral'
import TimezoneSelect, {
  SelectInputValue,
} from 'components/TimezoneSelect/TimezoneSelect'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import * as api from 'api'

export default function({ timezone }: { timezone: string }) {
  const [timezoneOption, setTimeZoneOption] = React.useState<SelectInputValue>({
    value: timezone,
    label: timezone,
  })

  const handleSelect = (value: SelectInputValue | null) => {
    const prev = timezoneOption
    if (value) {
      api
        .updateInstitutionData('me', { timeZone: value.value })
        .then(() => {
          setTimeZoneOption(value)
          toast.success('Successfully updated setting.')
        })
        .catch(() => {
          setTimeZoneOption(prev)
          toast.error('Failure updating setting.')
        })
    }
  }

  return (
    <div id="timezone">
      <SectionTitle>Time Zone</SectionTitle>
      <div className="mb-3">
        Choose a Time Zone for your organization. You will see this when
        scheduling a campaign, but can always change a campaign's timezone
        before sending. This is also used for evaluating Audiences and time
        ranges in reporting.
      </div>
      <hr className="w-100" />
      <TimezoneSelect
        className="mb-5 mr-2 w-25"
        selectedOption={timezoneOption}
        onSelect={handleSelect}
        placeholder="select"
      />
    </div>
  )
}
