import * as React from 'react'
import Loader from 'components/Loader/Loader'

interface IRefetchingOverlayProps {
  readonly children: React.ReactNode
  readonly enabled: boolean
  readonly centeredSpinner?: boolean
}

export const RefetchingOverlay = ({
  children,
  enabled,
  centeredSpinner,
}: IRefetchingOverlayProps) => {
  return (
    <div className="position-relative">
      {enabled && (
        <div className="d-flex position-absolute w-100 h-100 justify-content-center z-index-100 bg-opacity-50 p-4">
          <Loader size="md" centeredSpinner={centeredSpinner} />
        </div>
      )}
      {children}
    </div>
  )
}
