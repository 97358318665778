import axios from 'axios'
import { IMessageResponseData } from 'api/response'
import { store } from 'store/store'
import { PromptType } from 'embed/embedReducer'
import * as settings from 'const/settings'
import { IQuickActions } from 'page/webchat/SettingsWebChatForm/SettingsWebChatForm'
import { http2 } from 'api/http'
import * as t from 'io-ts'
interface IQuestionSearchRequestData {
  readonly question: string
  readonly webBotId?: string
  readonly messagingService?: string
  readonly contexts: ReadonlyArray<string>
  readonly userId?: string
  readonly promptType?: string
  readonly dialogOptionText?: string
  readonly isTestUser?: boolean
}

const http = axios.create()

http.interceptors.request.use(config => {
  // tslint:disable no-unsafe-any
  if (store.getState().auth.orgSlug) {
    config.headers[
      settings.CURRENT_INSTITUTION_HEADER
    ] = store.getState().auth.orgSlug
  }
  return config
})

export interface IWebChatResponse {
  messageId: string
}

export interface IWebBotPulseResponse {
  messages: IMessageResponseData[]
  lastPromptType?: PromptType
  multipleChoices: string[]
  isLiveChatLocked: boolean
  loggedIn: boolean
}

export interface IGetBotResponse {
  color: string
  textColor: string
  messagingService: string
  image: string | null
  name: string
  introMessages: Array<IMessageResponseData>
  userId: string
  webBotId: string
  language: string
  buttonLabelEnabled: boolean
  buttonLabelText: string
  popUpData: {
    shouldPopUp: boolean
    message: string
    popUpTimeSeconds: number
    image: string | null
  }
  quickMenu?: IQuickActions[]
  quickActionsEnabled: boolean
  expiresIn: number | null
}

export interface IWebBotToken {
  readonly botToken?: string
  readonly collegeId?: string
}

export type IGetBotRequest = {
  readonly botToken?: string
  readonly userId?: string
  readonly messagingService?: string
  readonly isTestUser?: boolean
}

export interface IPulseRequest {
  userId: string
  token: string
  lastMessageId?: string
}

export const sendMessage = ({
  isTestUser,
  ...request
}: IQuestionSearchRequestData) =>
  http.post<IWebChatResponse>('/api/v1/webchat/send/', request, {
    params: { isTestUser },
  })

export const getBot = (request: IGetBotRequest) =>
  http.get<IGetBotResponse>(`/api/v1/webchat/get_bot/`, {
    params: request,
  })

export const sendPulse = (request: IPulseRequest) =>
  http.post<IWebBotPulseResponse>('/api/v1/webchat/pulse/', request)

interface IGetLanguageResponse {
  data?: {
    languages: {
      language: string
      name: string
    }[]
  }
}

export const getLanguages = (target?: string) =>
  http.get<IGetLanguageResponse>(`/api/v1/webchat/get_languages/`, {
    params: { target },
  })

interface IWebChatLanguageSetResponse {
  detail: string
  language: string
}

export const updateLanguage = ({
  language,
  contactId,
  webBotId,
  messagingService,
}: {
  language: string
  contactId: string
  webBotId?: string
  messagingService?: string
}) =>
  http.post<IWebChatLanguageSetResponse>('/api/v1/webchat/set_language/', {
    language,
    contact_id: contactId,
    webBotId,
    messagingService,
  })

interface IResetMessagesRequest {
  userId: string
  webBotId: string
  messagingService?: string
}

export const resetMessages = (data: IResetMessagesRequest) =>
  http.post('/api/v1/webchat/reset/', data)

export interface ICreateWebbotContactRequest {
  botToken: string
  userId?: string
}

export interface ICreateWebbotContactResponse {
  id: string
  language: string
}

export const createWebBotContact = (
  contact: ICreateWebbotContactRequest,
  isTestUser?: boolean
) => {
  const params = isTestUser ? { isTestUser } : undefined
  return http.post<ICreateWebbotContactResponse>(
    '/api/v1/webchat/contact/',
    contact,
    {
      params,
    }
  )
}

export interface ISetWebchatLastActiveRequest {
  contactId: string
  webBotToken: string
}

export const setWebchatLastActive = (data: ISetWebchatLastActiveRequest) =>
  http.post<ICreateWebbotContactResponse>(
    '/api/v1/webchat/set_last_active/',
    data
  )

export interface ISetWebchatLastActiveRequest {
  contactId: string
  webBotToken: string
}

interface IGetLanguageResponse {
  data?: {
    languages: {
      language: string
      name: string
    }[]
  }
}

export interface IShowFeedbackControlRequest {
  userId: string
  token: string
  messageId: string
}

export const showFeedbackControl = (request: IShowFeedbackControlRequest) =>
  http2({
    method: 'POST',
    url: '/api/v1/webchat/show_feedback_control/',
    shape: t.boolean,
    data: request,
  })

export interface IAddFeedbackRequest {
  userId: string
  messageId: string
  rating: string
  comment?: string
}

export const addFeedback = (request: IAddFeedbackRequest) =>
  http2({
    method: 'POST',
    url: '/api/v1/webchat/add_feedback/',
    shape: t.unknown,
    data: request,
  })
