import classNames from 'classnames'

import 'components/Icons/ProfileIcon/ProfileIcon.scss'

export function ProfileIcon({
  fill,
  className,
}: {
  readonly fill?: string
  readonly className?: string
}) {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('profile-icon', className)}>
      <path
        d="M26.5357 24.5878C28.0755 22.7352 29.1465
        20.539 29.658 18.1849C30.1695 15.8308 30.1065 13.3882 29.4743 11.0636C28.8421
        8.73906 27.6593 6.60095 26.0261 4.83016C24.3928 3.05938 22.3571 1.70802 20.0911
        0.890395C17.8251 0.0727713 15.3955 -0.187059 13.0078 0.132884C10.6202 0.452826
        8.3447 1.34313 6.37389 2.72848C4.40309 4.11383 2.79494 5.95347 1.68548 8.09177C0.576023
        10.2301 -0.00210428 12.6041 5.75516e-06 15.0131C0.000815448 18.5151 1.2349 21.905 3.48571
        24.5878L3.46427 24.6061C3.53932 24.6961 3.6251 24.7733 3.70229 24.8623C3.79879 24.9728 3.90279
        25.0768 4.00251 25.184C4.30272 25.5099 4.61151 25.823 4.93532 26.1168C5.03396 26.2069
        5.13582 26.2905 5.23553 26.3763C5.57863 26.6722 5.93139 26.9531 6.297 27.2147C6.34418
        27.2469 6.38707 27.2887 6.43424 27.3219V27.3091C8.94543 29.0762 11.9411 30.0246 15.0118
        30.0246C18.0825 30.0246 21.0782 29.0762 23.5894 27.3091V27.3219C23.6365 27.2887 23.6783
        27.2469 23.7266 27.2147C24.0911 26.952 24.445 26.6722 24.7881 26.3763C24.8878 26.2905
        24.9896 26.2058 25.0883 26.1168C25.4121 25.8219 25.7209 25.5099 26.0211 25.184C26.1208
        25.0768 26.2237 24.9728 26.3213 24.8623C26.3974 24.7733 26.4843 24.6961 26.5593 24.605L26.5357
        24.5878ZM15.0107 6.43559C15.965 6.43559 16.8978 6.71856 17.6913 7.24872C18.4847 7.77889 19.1031
        8.53243 19.4683 9.41406C19.8335 10.2957 19.9291 11.2658 19.7429 12.2017C19.5567 13.1377 19.0972
        13.9974 18.4224 14.6722C17.7477 15.3469 16.8879 15.8065 15.952 15.9926C15.0161 16.1788 14.046
        16.0832 13.1643 15.7181C12.2827 15.3529 11.5292 14.7345 10.999 13.941C10.4688 13.1476 10.1859
        12.2147 10.1859 11.2605C10.1859 9.98082 10.6942 8.7536 11.599 7.84876C12.5039 6.94392 13.7311
        6.43559 15.0107 6.43559ZM6.44068 24.5878C6.45928 23.18 7.0314 21.8361 8.03327 20.8469C9.03514
        19.8577 10.3862 19.3026 11.7941 19.3019H18.2273C19.6353 19.3026 20.9863 19.8577 21.9882 20.8469C22.9901
        21.8361 23.5622 23.18 23.5808 24.5878C21.2293 26.7069 18.1761 27.8796 15.0107 27.8796C11.8453 27.8796
        8.79217 26.7069 6.44068 24.5878Z"
        fill={fill}
      />
    </svg>
  )
}
