import { createAsyncAction, ActionType } from 'typesafe-actions'
import { IApiError } from 'api'
import { IContactResponseData } from 'api/response'
import { ICreateContactRequestData } from 'api/request'

export const updateContact = createAsyncAction(
  '@@mascot/UPDATE_CONTACT_REQUEST',
  '@@mascot/UPDATE_CONTACT_SUCCESS',
  '@@mascot/UPDATE_CONTACT_FAILURE'
)<undefined, IContactResponseData, IApiError>()

export const searchContact = createAsyncAction(
  '@@triage/chat/SEARCH_CONTACT_START',
  '@@triage/chat/SEARCH_CONTACT_SUCCESS',
  '@@triage/chat/SEARCH_CONTACT_FAIL'
)<undefined, { query: string; contacts: IContactResponseData[] }, IApiError>()

export const createContact = createAsyncAction(
  '@@mascot/CREATE_CONTACT_INFO_REQUEST',
  '@@mascot/CREATE_CONTACT_INFO_SUCCESS',
  '@@mascot/CREATE_CONTACT_INFO_FAILURE'
)<ICreateContactRequestData, IContactResponseData, IApiError>()

export type IContactsActions =
  | ActionType<typeof updateContact>
  | ActionType<typeof searchContact>
  | ActionType<typeof createContact>
