import { TextInput } from 'components/TextInput/TextInput'

interface IRowHelperTextProps {
  text: string
  level: number
  maxLevel: number
  readOnly?: boolean
}

export const RowHelperText = ({
  text,
  level,
  maxLevel,
  readOnly = false,
}: IRowHelperTextProps) => {
  return (
    <div className="ml-auto" style={{ width: (maxLevel - level + 3) * 25 }}>
      <TextInput value={text} disabled readOnly={readOnly} />
    </div>
  )
}
