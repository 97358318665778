import { IUpdateContactInfoPanelRequest } from 'api'
import { IUpdateContactAttributeValuesRequest } from 'api/request'
import classnames from 'classnames'
import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import { Button } from 'components/Button/Button'
import {
  ContactAttributeValuesForm,
  createContactAttributeValueSchema,
  IContactAttributeValuesFormData,
  mapContactAttrToFormValues,
} from 'components/ContactPanel/ContactAttributesValuesForm'
import {
  contactIsOptedOut,
  contactIsPaused,
  ContactPanelContent,
} from 'components/ContactPanel/ContactPanel'
import 'components/ContactPanel/ContactPanel.scss'
import { ContactPanelLabelSelector } from 'components/ContactPanel/ContactPanelLabelSelector'
import { EventAction } from 'components/EventTracker/EventTracker'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { LabeledTextInput } from 'components/LabeledInput/LabeledInput'
import { useLanguages } from 'components/LanguageSelection/LanguageSelectionDropdownConnected'
import { TextInput } from 'components/TextInput/TextInput'
import Tooltip from 'components/Tooltip/Tooltip'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import pickBy from 'lodash/pickBy'
import React from 'react'
import {
  DOMESTIC_PHONE_REGEX,
  IContactLabel,
  IFullContact,
  INTL_PHONE_REGEX,
  COUNTRY_CALLING_CODE_REGEX,
} from 'store/contacts/reducer'
import {
  getAllContactAttributes,
  IContactAttributes,
} from 'store/personalization/contactAttributes/selectors'
import { TransportEnum } from 'store/transport'
import { isSuccess, WebData, WebDataError } from 'store/webdata'
import { rEmailWithTLD } from 'util/email'
import { useFeatures, useSelector } from 'util/hooks'
import { Link } from 'util/routing'
import * as yup from 'yup'
import { E164PhoneNumber } from 'components/ContactPanel/E164PhoneNumber'
import { ProfileIcon } from 'components/Icons/ProfileIcon/ProfileIcon'
import { ToggleSwitchV2 } from 'components/ToggleSwitch/ToggleSwitch'
import Select from 'components/Select/SelectV2'
import { ValueType } from 'react-select/lib/types'
import scssVariables from 'scss/_variables.scss'
import { Circle } from 'components/Circle/Circle'

interface IOption {
  label: string | undefined
  value: string | undefined
}

export interface IContactLabeledInputProps {
  readonly label: string
  readonly id: string
  readonly type?: string
  readonly value?: string | null
  readonly children?: React.ReactNode
  readonly onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  readonly required?: boolean
  readonly eventObject?: string
  readonly eventAction?: EventAction
}

export interface IContactTextInputProps {
  readonly label: string
  readonly id: string
  readonly value: string
  readonly children?: React.ReactNode
  readonly onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  readonly eventObject?: string
  readonly eventAction?: EventAction
}

export interface IContactReadOnlyFieldProps {
  readonly label: string
  readonly value?: string | null
}

export function ContactTextInput({
  children,
  id,
  value,
  label,
  onChange,
  eventObject,
  eventAction,
}: IContactTextInputProps) {
  return (
    <div className="pb-2 d-flex align-items-center">
      <div className="col col-3 p-0 min-width-120px">
        <p className="mb-0 fw-600">{label}</p>
      </div>
      <div className="col-7">
        <TextInput
          eventObject={eventObject}
          eventAction={eventAction}
          onChange={onChange}
          className="pr-3"
          value={value}
          id={id}>
          {children}
        </TextInput>
      </div>
    </div>
  )
}

export function ContactLabeledInput({
  children,
  id,
  label,
  value,
  onChange,
  type,
  required,
  eventObject,
  eventAction,
}: IContactLabeledInputProps) {
  return (
    <LabeledTextInput
      eventObject={eventObject}
      eventAction={eventAction}
      id={id}
      label={label}
      type={type}
      required={required}
      onChange={onChange}
      value={value || ''}
      className="pr-3 mb-2 max-width-300">
      {children}
    </LabeledTextInput>
  )
}

export interface IContactFormData {
  readonly firstName: string
  readonly lastName: string
  readonly preferredName: string
  readonly phone: string
  readonly countryCallingCode: string
  readonly email: string
  readonly language: string
  readonly crmId: string
  readonly enrollmentId: string
  readonly isTempPaused: boolean
  readonly isOptedOut: boolean
  readonly isTestUser: boolean
  readonly isArchived: boolean
  readonly isAiDisabled: boolean
  readonly isInternal: boolean
  readonly contactLabels: ReadonlyArray<IContactLabel>
  attributeValues?: IContactAttributeValuesFormData
  readonly transport?: string
  readonly transports?: string[]
  readonly messagingStatus: {
    [k: string]: IMessagingStatusOption['value']
  }
}

const UNSET_LANGUAGE = ''

const mapContactToFormValues = (
  contact?: IFullContact
): Omit<IContactFormData, 'isArchived'> => ({
  firstName: contact?.name?.first || '',
  lastName: contact?.name?.last || '',
  preferredName: contact?.name?.preferred || '',
  phone: contact?.phone || '',
  countryCallingCode: contact?.countryCallingCode || '1',
  email: contact?.email || '',
  crmId: contact?.crmId || '',
  enrollmentId: contact?.enrollmentId || '',
  isTempPaused: contact ? contactIsPaused(contact) : false,
  language: contact?.language || UNSET_LANGUAGE,
  isOptedOut: contact ? contactIsOptedOut(contact) : false,
  isTestUser: contact?._testUser || false,
  isInternal: contact?.internal || false,
  isAiDisabled: contact?.aiDisabled || false,
  contactLabels: contact?.contactLabels || [],
  transport: contact?.transport || TransportEnum.twilio,
  transports:
    contact?.transports && contact?.transports.length
      ? contact?.transports
      : [TransportEnum.twilio],
  messagingStatus: contact?.messagingStatus ?? {},
})

interface IContactLanguageSelectDropdownProps {
  value: string
  onChange: (value: ValueType<IOption>) => void
  label?: string
}

const ContactLanguageSelectDropdown = ({
  value,
  onChange,
  label,
}: IContactLanguageSelectDropdownProps) => {
  const languages = useLanguages()
  return (
    <div className=" d-flex align-items-center">
      {label ? (
        <div className="col col-3 p-0 min-width-120px">
          <p className="mb-0 fw-600">{label}</p>
        </div>
      ) : null}

      <div className="col col-5">
        <Select
          classNamePrefix="react-select"
          value={{ label: value, value }}
          options={languages.map(o => ({
            label: [o.name, o.code].join(' - '),
            value: o.code,
          }))}
          onChange={onChange}
          components={{
            IndicatorSeparator: undefined,
          }}
          eventAction="change"
          eventObject="language"
          explicitPreventOverlap={true}
        />
      </div>
    </div>
  )
}

interface IContactFieldProps {
  readonly name: keyof IContactFormData
  readonly render: ({ form, field }: FieldProps<IContactFormData>) => void
}

function ContactField({ name, render }: IContactFieldProps) {
  return <Field className="py-2" name={name} render={render} />
}

interface ISetFieldValueProps<K extends keyof IContactFormData> {
  readonly form: FormikProps<IContactFormData>
  readonly fieldName: K
  readonly fieldValue: IContactFormData[K]
}

function setFieldValue<K extends keyof IContactFormData>({
  form,
  fieldName,
  fieldValue,
}: ISetFieldValueProps<K>) {
  form.setFieldValue(fieldName, fieldValue)
}

export const getContactValidationSchema = (
  contactAttributes?: WebData<IContactAttributes, WebDataError>,
  international?: boolean | undefined
) => {
  let schema = yup.object().shape({
    firstName: yup.string().required('First name cannot be blank'),
    lastName: yup.string().when('transport', {
      is: TransportEnum.twilio,
      then: (fieldSchema: yup.StringSchema<string>) =>
        fieldSchema.required('Last name cannot be blank'),
    }),
    phone: yup
      .string()
      .matches(international ? INTL_PHONE_REGEX : DOMESTIC_PHONE_REGEX, {
        message: international
          ? 'Phone number must contain only digits, and it can be up to 14 digits long.'
          : 'Phone number must contain exactly 10 digits.\nOnly US numbers (country code +1) are supported at this time.',
        excludeEmptyString: true,
      })
      .when('transport', {
        is: TransportEnum.twilio,
        then: (fieldSchema: yup.StringSchema<string>) =>
          fieldSchema.required('Phone number cannot be blank'),
      }),
    email: yup
      .string()
      .test('validate-email', 'Invalid email address', (value: string) => {
        if (!value || value === 'UNSET') {
          return true
        }
        return rEmailWithTLD.test(value)
      })
      .when('transport', {
        is: TransportEnum.slack,
        then: (fieldSchema: yup.StringSchema<string>) =>
          fieldSchema.required('Email cannot be blank'),
      }),
    transport: yup.string(),
    countryCallingCode: yup
      .string()
      .required('Country calling code cannot be blank.')
      .matches(COUNTRY_CALLING_CODE_REGEX, {
        message: 'The country calling code must be 1 to 4 digits.',
      }),
  })
  if (contactAttributes && isSuccess(contactAttributes)) {
    schema = schema.concat(
      yup.object().shape({
        attributeValues: createContactAttributeValueSchema(
          contactAttributes.data
        ),
      })
    )
  }
  return schema
}

const ContactInfoSection = ({
  title,
  className,
  children,
}: {
  title: string
  className?: string
  children: React.ReactNode
}) => (
  <div
    className={classnames(
      'd-flex flex-column justify-content-start w-100 px-4 position-relative border-bottom border-mainstay-dark-blue-20 py-3',
      className
    )}>
    <p className="text-mainstay-dark-blue fw-600"> {title} </p>
    {children}
  </div>
)

interface IEditableContactPanelProps {
  readonly contact?: IFullContact
  readonly className?: string
  readonly onSave: (
    contactData: IUpdateContactInfoPanelRequest['data'],
    contactAttributeData?: IUpdateContactAttributeValuesRequest['data']
  ) => void
  readonly onCancel?: () => void
  readonly contactIsArchived: boolean
  readonly permittedUserStatusMutable: boolean
  readonly newContact: boolean
  readonly saving: boolean
}

export interface IMessagingStatusOption {
  label: string
  value: 'OptedIn' | 'Paused' | 'Stopped'
  disabled?: boolean
}

export const MessagingStatusOptions: Array<IMessagingStatusOption> = [
  { label: 'Opted In', value: 'OptedIn' },
  { label: 'Paused', value: 'Paused', disabled: true },
  { label: 'Opted Out', value: 'Stopped' },
]

const TransportDisplayName: { [k: string]: string } = {
  twilio: 'SMS',
  slack: 'Slack',
  web: 'Webchat',
}

export const SMSOptInWarning = () => (
  <Tooltip content="This contact previously opted out of SMS messaging. As a result, new messages may be blocked by the carrier.">
    <div className="pl-2 align-warning-icon">
      <svg
        width="22"
        height="19"
        viewBox="0 0 22 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z"
          fill="#FEC84B"
        />
      </svg>
    </div>
  </Tooltip>
)

const MultiChannelMessagingStatusFields = ({
  contact,
}: {
  contact?: IFullContact
}) => {
  if (!contact || !contact.transports) {
    return null
  }
  return (
    <>
      {contact.transports.map(transport => {
        return (
          <div className="d-flex align-items-center pb-2" key={transport}>
            <div className="d-flex min-width-120px">
              <p className="mb-0 fw-600">{TransportDisplayName[transport]}</p>
            </div>
            <ContactField
              name="messagingStatus"
              render={({ form, field }) => {
                const selected = MessagingStatusOptions.find(
                  o => o.value === form.values.messagingStatus[transport]
                )
                return (
                  <div className="d-flex align-items-center">
                    <Select<IMessagingStatusOption>
                      classNamePrefix="react-select"
                      className="status-selector"
                      value={selected}
                      options={MessagingStatusOptions}
                      isOptionDisabled={option => !!option.disabled}
                      onChange={option => {
                        if (!option || Array.isArray(option)) {
                          return
                        }
                        form.setFieldValue(field.name, {
                          ...field.value,
                          [transport]: option.value,
                        })
                      }}
                      components={{
                        IndicatorSeparator: undefined,
                      }}
                      eventAction="change"
                      eventObject="language"
                    />
                    {transport === 'twilio' &&
                      contact.previouslyOptedOutOfSMS && <SMSOptInWarning />}
                  </div>
                )
              }}
            />
          </div>
        )
      })}
    </>
  )
}

interface IContactStatusToggle {
  title: string
  fieldName: keyof IContactFormData
  eventObject: string
  tooltipText: string
  disabled?: boolean
}

const ContactStatusToggle = ({
  title,
  fieldName,
  eventObject,
  disabled,
  tooltipText,
}: IContactStatusToggle) => {
  return (
    <div className="d-flex align-items-center py-2">
      <div className="d-flex min-width-120px">
        <p className="mb-0 fw-600"> {title} </p>
        <Tooltip content={tooltipText}>
          <div>
            <AHIcon name="info_outline" />
          </div>
        </Tooltip>
      </div>
      <ContactField
        name={fieldName}
        render={({ form }) => (
          <ToggleSwitchV2
            size="sm"
            disabled={disabled}
            eventAction="change"
            eventObject={eventObject}
            checked={!!form.values[fieldName]}
            onChange={checked => {
              setFieldValue({
                form,
                fieldName,
                fieldValue: checked,
              })
            }}
          />
        )}
      />
    </div>
  )
}

export const EditableContactPanel = ({
  contact,
  onSave,
  onCancel,
  className,
  contactIsArchived,
  permittedUserStatusMutable,
  newContact,
  saving,
}: IEditableContactPanelProps) => {
  const { hasFeature, FeaturesType } = useFeatures()
  const contactAttributes = useSelector(getAllContactAttributes)

  if (!contact && !newContact) {
    return <h4 className="w-100 text-align-center">No contact found</h4>
  }

  const handleSubmit = (formState: IContactFormData) => {
    // Note(sbdchd): we replace empty string as the backend expects email and
    // similar to not be empty, but it does allow them to not exist. Ideally
    // we'd refactor the backend to be more permissive.
    const contactDataDiff = pickBy(formState, x => x !== '')
    // We pass in all previously existing contactAttributeValues as null to the payload,
    // such that they're primed for deletion if those keys aren't overridden by the formValues below.
    // This is needed because the trash can deletes formValues from the payload so it knows that they're
    // "trashed", but the backend patch endpoint only deletes an attr value if its set to null.
    const contactAttributeData: {
      attributeValues: IContactAttributeValuesFormData
    } = {
      attributeValues: {
        ...contact?.attributeValues?.reduce(
          (acc, attrVal) => ({
            ...acc,
            [attrVal.contactAttribute]: {
              name: attrVal.attributeName,
              value: null,
            },
          }),
          {}
        ),
        ...(formState.attributeValues ?? {}),
      },
    }
    contactDataDiff['attributeValues'] = contactAttributeData.attributeValues
    onSave(contactDataDiff)
  }

  const validationSchema = getContactValidationSchema(
    contactAttributes,
    hasFeature(FeaturesType.INTL_TEXTING_V1)
  )

  return (
    <Formik<IContactFormData>
      initialValues={{
        ...mapContactToFormValues(contact),
        attributeValues: mapContactAttrToFormValues(contact?.attributeValues),
        isArchived: contactIsArchived,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={({ values }: FormikProps<IContactFormData>) => {
        return (
          <Form className="w-100">
            <ContactPanelContent
              className="pt-0"
              header={
                <div className="min-height-50px w-100 px-4 position-relative border-bottom border-mainstay-dark-blue-20 d-flex justify-content-between pb-2 flex-shrink-0">
                  <div className="d-flex align-items-center">
                    <ProfileIcon className="fill-mainstay-dark-blue-50 mr-2" />
                    <h5 className="m-0">Contact Profile</h5>
                  </div>
                  <div className="d-flex">
                    <Button
                      eventAction="click"
                      eventObject="save contact"
                      type="submit"
                      color="secondary-teal"
                      loading={saving}
                      loadingText="Saving...">
                      Save
                    </Button>
                    {onCancel ? (
                      newContact ? (
                        <Link to="/contacts">
                          <Button
                            eventAction="click"
                            eventObject="cancel"
                            className="bg-white ml-2 border-mainstay-dark-blue-50"
                            onClick={onCancel}>
                            Cancel
                          </Button>
                        </Link>
                      ) : (
                        <Button
                          eventAction="click"
                          eventObject="cancel"
                          className="bg-white ml-2 border-mainstay-dark-blue-50"
                          onClick={onCancel}>
                          Cancel
                        </Button>
                      )
                    ) : null}
                  </div>
                </div>
              }>
              <div className={classnames('w-100', className)}>
                <ContactInfoSection
                  title="Contact Information"
                  className="pt-2">
                  <ContactField
                    name="firstName"
                    render={({ form, field }) => (
                      <div>
                        <ContactLabeledInput
                          eventAction="change"
                          eventObject="first name"
                          onChange={field.onChange}
                          label="First name"
                          id="firstName"
                          required
                          value={form.values.firstName}
                        />
                        {form.errors.firstName && form.touched.firstName && (
                          <div className="mr-3 alert alert-danger">
                            {form.errors.firstName}
                          </div>
                        )}
                      </div>
                    )}
                  />
                  <ContactField
                    name="preferredName"
                    render={({ form, field }) => (
                      <div>
                        <ContactLabeledInput
                          eventAction="change"
                          eventObject="preferred name"
                          onChange={field.onChange}
                          label="Preferred name"
                          id="preferredName"
                          required={false}
                          value={form.values.preferredName}
                        />
                        {form.errors.preferredName &&
                          form.touched.preferredName && (
                            <div className="mr-3 alert alert-danger">
                              {form.errors.preferredName}
                            </div>
                          )}
                      </div>
                    )}
                  />
                  <ContactField
                    name="lastName"
                    render={({ form, field }) => (
                      <div>
                        <ContactLabeledInput
                          onChange={field.onChange}
                          eventAction="change"
                          eventObject="last name"
                          label="Last name"
                          id="lastName"
                          required
                          value={form.values.lastName}
                        />
                        {form.errors.lastName && form.touched.lastName && (
                          <div className="mr-3 alert alert-danger">
                            {form.errors.lastName}
                          </div>
                        )}
                      </div>
                    )}
                  />
                  <ContactField
                    name="phone"
                    render={({ form, field }) => (
                      <>
                        {hasFeature(FeaturesType.INTL_TEXTING_V1) ? (
                          <E164PhoneNumber
                            form={form}
                            onChangeCountryCallingCode={option =>
                              form.setFieldValue(
                                'countryCallingCode',
                                option && !Array.isArray(option) && option.value
                              )
                            }
                            onChangePhone={field.onChange}
                          />
                        ) : (
                          <div>
                            <ContactLabeledInput
                              eventAction="change"
                              eventObject="phone"
                              onChange={field.onChange}
                              label="Phone"
                              id="phone"
                              value={form.values.phone}
                            />
                            {form.errors.phone && form.touched.phone && (
                              <div className="mr-3 alert alert-danger">
                                {form.errors.phone}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  />
                  <ContactField
                    name="email"
                    render={({ form, field }) => (
                      <div>
                        <ContactLabeledInput
                          eventAction="change"
                          eventObject="email"
                          onChange={field.onChange}
                          label="Email"
                          id="email"
                          value={form.values.email}
                        />
                        {form.errors.email && form.touched.email && (
                          <div className="mr-3 alert alert-danger">
                            {form.errors.email}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </ContactInfoSection>
                <ContactInfoSection title="Labels">
                  <div className="w-100 max-width-300">
                    <ContactField
                      name="contactLabels"
                      render={({ form }) => (
                        <ContactPanelLabelSelector
                          contactLabels={form.values.contactLabels}
                          onChange={fieldValue =>
                            setFieldValue({
                              form,
                              fieldName: 'contactLabels',
                              fieldValue,
                            })
                          }
                          createOptionPosition="first"
                        />
                      )}
                    />
                  </div>
                </ContactInfoSection>
                <ContactInfoSection title="Status">
                  <MultiChannelMessagingStatusFields contact={contact} />
                  <ContactStatusToggle
                    title="AI Disabled"
                    fieldName="isAiDisabled"
                    eventObject="ai disabled toggle"
                    tooltipText="If on, the AI bot will not respond to incoming messages from this contact or trigger escalations."
                  />
                  <ContactStatusToggle
                    title="Archived"
                    fieldName="isArchived"
                    eventObject="archived"
                    disabled={!permittedUserStatusMutable}
                    tooltipText="If on, indicates that contact will appear in lists of available contacts to send test campaigns and scripts to."
                  />
                  <ContactStatusToggle
                    title="Test User"
                    fieldName="isTestUser"
                    eventObject="test user toggle"
                    tooltipText="If on, indicates that contact will appear in lists of available contacts to send test campaigns and scripts to."
                  />
                  <AdmithubOnly>
                    <div className="d-flex align-items-center py-2 bg-warning-light">
                      <ContactStatusToggle
                        title="Internal User"
                        fieldName="isInternal"
                        eventObject="internal user toggle"
                        tooltipText="If on, allow text messages to be sent to this contact on staging or testing institutions."
                      />
                    </div>
                  </AdmithubOnly>
                </ContactInfoSection>
                <ContactInfoSection title="Default Contact Fields">
                  <ContactField
                    name="crmId"
                    render={({ form, field }) => (
                      <ContactTextInput
                        eventAction="change"
                        eventObject="crm id"
                        onChange={field.onChange}
                        label="CRM ID"
                        id="crmId"
                        value={form.values.crmId}
                      />
                    )}
                  />
                  <ContactField
                    name="enrollmentId"
                    render={({ form, field }) => (
                      <ContactTextInput
                        eventAction="change"
                        eventObject="enrollment id"
                        onChange={field.onChange}
                        label="Enrollment ID"
                        id="enrollmentId"
                        value={form.values.enrollmentId}
                      />
                    )}
                  />
                  <ContactField
                    name="language"
                    render={({ form, field }) => (
                      <ContactLanguageSelectDropdown
                        label="Language"
                        onChange={option =>
                          form.setFieldValue(
                            field.name,
                            option && !Array.isArray(option) && option.value
                          )
                        }
                        value={form.values.language}
                      />
                    )}
                  />
                </ContactInfoSection>
                {isSuccess(contactAttributes) && (
                  <ContactInfoSection title="Custom Contact Fields">
                    <ContactAttributeValuesForm
                      contactAttributes={contactAttributes.data}
                      formValues={values.attributeValues}
                    />
                    <div className="w-100 d-flex justify-content-center pt-4 pb-3">
                      <Circle
                        radius={3}
                        fill={scssVariables.mainstayDisabledGray}
                      />
                    </div>
                  </ContactInfoSection>
                )}
              </div>
            </ContactPanelContent>
          </Form>
        )
      }}
    />
  )
}
