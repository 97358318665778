import * as React from 'react'
import classnames from 'classnames'
import {
  FilledInput,
  InputAdornment,
  Select,
  MenuItem,
} from 'components/MaterialComponents/MaterialComponents'

export enum TimeUnit {
  days = 'days',
  hours = 'hours',
  minutes = 'minutes',
  seconds = 'seconds',
}

export interface IDayHourMinutePickerProps {
  onChange: (unit: TimeUnit, value: number) => void
  days: number
  hours: number
  minutes: number
  error?: boolean
  ignoreErrorsOnChange?: boolean
  readonly?: boolean
}

export const CompactAdornment: React.FunctionComponent = props => {
  return (
    <InputAdornment className="m-0 text-black-50" position="end">
      <small>{props.children}</small>
    </InputAdornment>
  )
}

export const DayHourMinutePicker = ({
  days,
  hours,
  minutes,
  error,
  readonly,
  onChange,
  ignoreErrorsOnChange,
}: IDayHourMinutePickerProps) => {
  const [daysError, setDaysError] = React.useState<boolean>(false)
  const [hoursError, setHoursError] = React.useState<boolean>(false)
  const [minutesError, setMinutesError] = React.useState<boolean>(false)

  const [localDays, setLocalDays] = React.useState<number>(days)
  const [localMinutes, setLocalMinutes] = React.useState<number>(minutes)
  const [localHours, setLocalHours] = React.useState<number>(hours)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10)

    const nowDaysError = e.target.name === TimeUnit.days && newValue > 36499
    const nowHoursError = e.target.name === TimeUnit.hours && newValue > 23
    const nowMinutesError = e.target.name === TimeUnit.minutes && newValue > 59

    if (e.target.name === TimeUnit.days) {
      setDaysError(nowDaysError)
      setLocalDays(newValue)
    } else if (e.target.name === TimeUnit.hours) {
      setHoursError(nowHoursError)
      setLocalHours(newValue)
    } else if (e.target.name === TimeUnit.minutes) {
      setMinutesError(nowMinutesError)
      setLocalMinutes(newValue)
    }

    if (ignoreErrorsOnChange) {
      /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
      onChange(e.target.name as TimeUnit, newValue)
      return
    }

    if (!nowDaysError && !nowHoursError && !nowMinutesError) {
      /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
      onChange(e.target.name as TimeUnit, newValue)
    }
  }

  const inputProps = { className: 'py-3', min: 0 }

  return (
    <div className="d-flex align-items-center">
      <FilledInput
        readOnly={readonly}
        autoComplete="off"
        error={!!error || (!ignoreErrorsOnChange && daysError)}
        name={TimeUnit.days}
        type="number"
        className="mr-2 pr-2 flex-grow-1 border-radius-025rem"
        endAdornment={
          <CompactAdornment>
            {localDays === 1 ? 'day' : 'days'}
          </CompactAdornment>
        }
        disableUnderline={true}
        inputProps={{ ...inputProps, max: 36499 }}
        value={localDays}
        onChange={handleChange}
      />
      <FilledInput
        readOnly={readonly}
        autoComplete="off"
        error={!!error || (!ignoreErrorsOnChange && hoursError)}
        name={TimeUnit.hours}
        type="number"
        className="mr-2 pr-2 flex-grow-1 border-radius-025rem"
        endAdornment={
          <CompactAdornment>
            {localHours === 1 ? 'hour' : 'hours'}
          </CompactAdornment>
        }
        disableUnderline={true}
        inputProps={{ ...inputProps, max: 23 }}
        value={localHours}
        onChange={handleChange}
      />
      <FilledInput
        readOnly={readonly}
        autoComplete="off"
        error={!!error || (!ignoreErrorsOnChange && minutesError)}
        name={TimeUnit.minutes}
        type="number"
        className="pr-2 flex-grow-1 border-radius-025rem"
        endAdornment={
          <CompactAdornment>
            {localMinutes === 1 ? 'minute' : 'minutes'}
          </CompactAdornment>
        }
        disableUnderline={true}
        inputProps={{ ...inputProps, max: 59 }}
        value={localMinutes}
        onChange={handleChange}
      />
    </div>
  )
}

export interface ITimeUnitPickerProps {
  className?: string
  unit: TimeUnit
  value: number
  onChangeUnit: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  readonly?: boolean
}

export class TimeUnitPicker extends React.PureComponent<ITimeUnitPickerProps> {
  render() {
    return (
      <div className={classnames('d-flex', this.props.className)}>
        <FilledInput
          autoComplete="off"
          error={!!this.props.error}
          name={this.props.unit}
          type="number"
          className="flex-grow-0 rounded-0"
          disableUnderline={true}
          inputProps={{ className: 'py-3', min: 0 }}
          value={this.props.value}
          onChange={this.props.onChangeValue}
          readOnly={this.props.readonly}
        />
        <Select
          error={!!this.props.error}
          readOnly={this.props.readonly}
          className="flex-grow-0 border-dark border-left rounded-0"
          value={this.props.unit}
          onChange={this.props.onChangeUnit}
          disableUnderline={true}
          inputProps={{ className: 'py-3' }}
          input={<FilledInput name="unit" />}>
          {Object.keys(TimeUnit).map(u => {
            return (
              <MenuItem key={u} value={u}>
                {u}
              </MenuItem>
            )
          })}
        </Select>
      </div>
    )
  }
}
