import { ApprovalStatus } from 'store/triage/chat/messagesReducer'

export const PRODUCTION = process.env.NODE_ENV === 'production'
export const DEV = !PRODUCTION
/** Name to display on login page */
export const LOGIN_NAME = 'Mainstay Engagement Platform'
/** Path for Mascot login page */
export const MASCOT_LOGIN_PATH = '/login/'
/** Soft cap for single message texts */
export const SINGLE_MESSAGE_SOFT_CAP = 160
/** Hard limit enforced by backend and Twilio
 * 1600 utf-16 codepoints. The same length from string.length in JS.
 * https://www.twilio.com/docs/glossary/what-sms-character-limit
 */
export const TWILIO_MAX_MESSAGE_SIZE = 1600

/** ID of dialog nested on contact that indicates the Contact is paused in a campaign. */
export const SOFT_STOP_ID = 'defaultSoftStop'
export const SOFT_STOP_2_WEEK_STATE = 'twoWeeksSoftStop'

/** Sentry Configuration */
export const MARSHALL_UI_SENTRY_DSN =
  'https://1bdb920dae934abf80aebc528b34acc9@sentry.io/1227336'
export const WEBCHAT_UI_SENTRY_DSN =
  'https://04601d313acf4a2d958b26d7f87c0c09@o157672.ingest.sentry.io/5370062'

/** Limit of results forcampaign script list endpoint */
export const CAMPAIGN_SCRIPT_LIST_LIMIT = 10

/** Additional number of seconds to pause for in between dialog states. */
/** For sms messages we pause for 16 seconds by default and for web chat we pause for 2 seconds */
export const DEFAULT_ADDITIONAL_PAUSE_TIME = 1

/** Default message used for populating dialog states. */
export const DEFAULT_DIALOG_STATE_MESSAGE = 'Add message here.'

/** Default Voice message for when users call the bot instead of texting it */
export const DEFAULT_VOICE_MESSAGE =
  'Hi. Unfortunately, I can’t talk that well yet. Can you please text me if you want to chat?'

/**
 * Default text prepended to escalated messages. Hard coded in Neolith.
 * see: https://github.com/AdmitHub/NeOliTh/blob/f5311f164c7ff8d936459124143d08e55ea90909/lib/on-start.js#L260-L260
 */
export const DEFAULT_PREPENDED_ESCALATED_MESSAGE_RESPONSE =
  '<%= college.name + " responded to your earlier question:" %> "<%= options.prompt %>"'

/** Time of inactivity in milliseconds before displaying a prompt to continue session
 *
 * Ensure this matches the SESSION_COOKIE_AGE in settings.py. SESSION_COOKIE_AGE
 * is in seconds, while MAX_INACTIVE_SESSION_TIME is in milliseconds.
 */
export const MAX_INACTIVE_SESSION_TIME = 1000 * 60 * 90

export const TEMPLATE_STRING_MAP: { [key: string]: string } = {
  'userState.collegeName': 'College Name',
  'userState.crmId': 'CRM ID',
  'userState.enrollmentId': 'Enrollment Id',
  'userState._phone': 'Phone Number',
  'userState.name.first': 'First Name',
  'userState.name.last': 'Last Name',
  'userState.name.full': 'Full Name',
  'userState.location.address1': 'Address',
  'userState.location.city': 'City',
  'userState.email': 'Email Address',
  'userState.name.first?+userState.name.first:': 'First Name',
}

export const JS_TEMPLATE_LHS = '<%='

export const JS_TEMPLATE_RHS = '%>'

export const JS_TEMPLATE_CSS_CLASS = 'badge badge-secondary'

export const DEFAULT_CSV_FILE_NAME = 'mainstay-csv-report'

export const MAX_CSV_SIZE = '50MB'

export const ADMITHUB_DOMAIN = 'mainstay.com'

export enum IDKTopic {
  GENERAL = '/general/notfound',
  LONG_QUESTION = '/general/notfound/longQuestion',
  EDUCATION_SEQUENCE = '/general/notfound/educationSequence',
}

export const IDK_TOPICS: string[] = [
  IDKTopic.GENERAL,
  IDKTopic.LONG_QUESTION,
  IDKTopic.EDUCATION_SEQUENCE,
]

export const DENY_MESSAGES = [
  ApprovalStatus.Ignored,
  ApprovalStatus.Fixed,
  ApprovalStatus.InProgress,
]

export const DEFAULT_POLL_RATE_MS = 5000

export const TRIAGE_INBOX_ITEMS_PER_PAGE = 4

export const INTEGRATION_ERRORS_PER_PAGE = 50

export const FACEBOOK_START_INTEGRATION_URL = '/api/v0/oauth/fb/connect/'

export const ADMITHUB_PRIMARY = '#22a1ac'

export const DISTINCT_COLORS_LIST = [
  '#e6194b',
  '#3cb44b',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#ffe119',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
]
// we support both for lookup
export const CURRENT_INSTITUTION_LOOKUP_ID = 'current'
export const CURRENT_INSTITUTION_LOOKUP_ID_ALT = 'me'

/** The max height of an image to show on the conversations page via <MediaEmbed/> */
export const MAX_IMG_HEIGHT = 150

export const SCRIPT_LIBRARY_ORG_ID = 'admithubScriptLibrary'

/** The header key name that the frontend appends to requests post login. */
export const CURRENT_INSTITUTION_HEADER = 'X-AdmitHub-currentInstitution'
