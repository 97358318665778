import * as Ably from 'ably/promises'
import { getType } from 'typesafe-actions'
import {
  IAblyConnectionActions,
  updateAblyConnectionState,
  resetAblyConnectionState,
} from 'store/ablyConnection/actions'

export type IAblyConnectionState = {
  status: Ably.Types.ConnectionStateChange['current'] | undefined
}

const defaultState: IAblyConnectionState = {
  status: undefined,
}

const reducer = (
  state = defaultState,
  action: IAblyConnectionActions
): IAblyConnectionState => {
  switch (action.type) {
    case getType(updateAblyConnectionState):
      return {
        status: action.payload,
      }
    case getType(resetAblyConnectionState):
      return defaultState
    default:
      return state
  }
}

export default reducer
