import {
  ActionType,
  createAsyncAction,
  createStandardAction,
} from 'typesafe-actions'
import { ConversationListLiveUpdateMessageType } from 'page/conversations-v2/live-updates'
import { TransportId } from 'store/transport'
import { StatusFilter } from 'page/conversations-v2/ConversationList/ConversationHeader/ConversationListStatusFilter/index'

export type ContactListItem = {
  readonly id: string
  readonly name: string | null
  readonly preferredName: string | null
  readonly transport: TransportId | 'unknown'
  readonly transports: ReadonlyArray<TransportId | 'unknown'>
  readonly activeUntil: string | null
  readonly lastMessage: string | null
  readonly lastMessageAt: string | null
  readonly lastMessageTransport: TransportId | null
  readonly lastIncomingMessageAt: string | null
  readonly flagged: boolean | undefined
  readonly escalated: boolean | undefined
  readonly hasUnreadMessages: boolean | undefined
  readonly unreadMessagesStatusByChannel: Record<string, boolean>
  readonly frozenUntil: string | null
  readonly tags: readonly {
    readonly id: string
    readonly name: string
    readonly resolved: boolean
  }[]
}

export type SidebarFilter =
  | 'all_live_chats'
  | 'active_now_me'
  | 'past_chats_me'
  | 'flagged'
  | null

type ConversationsListResponse = {
  readonly contacts: ContactListItem[]
  readonly total_count: number
  readonly next_offset: number | null
}

export const addConversationsToCheckboxList = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_ADD_TO_CHECKBOX_LIST'
)<string[]>()
export const removeConversationFromCheckboxList = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_REMOVE_FROM_CHECKBOX_LIST'
)<string>()
export const selectAllConversationsFromCheckboxList = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_SELECT_ALL_FROM_CHECKBOX_LIST'
)<{ all: boolean; selectedIds: string[] }>()
export const resetConversationsCheckboxListItems = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_RESET_CHECKBOX_LIST_ITEMS'
)()
export const setActionsToggle = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_ACTIONS_TOGGLE'
)<boolean>()
export const conversationListLiveUpdate = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_LIVE_UPDATE'
)<ConversationListLiveUpdateMessageType>()
export const fetchConversationsList = createAsyncAction(
  '@@MASCOT/CONVERSATION_LIST_FETCH_START',
  '@@MASCOT/CONVERSATION_LIST_FETCH_SUCCESS',
  '@@MASCOT/CONVERSATION_LIST_FETCH_FAILURE'
)<{ readonly loadMore: boolean }, ConversationsListResponse, void>()
export const removeConversationItemFromList = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_REMOVE_ITEM'
)<string>()
export const fetchPinnedConversationsList = createAsyncAction(
  '@@MASCOT/CONVERSATION_LIST_PINNED_FETCH_START',
  '@@MASCOT/CONVERSATION_LIST_PINNED_FETCH_SUCCESS',
  '@@MASCOT/CONVERSATION_LIST_PINNED_FETCH_FAILURE'
)<void, ConversationsListResponse, void>()
export const togglePinConversation = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_PIN_TOGGLE'
)<{
  pin: boolean
  readonly conversationId: string
  readonly searching: boolean
}>()
export const setChosenContact = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_SET_CHOSEN_CONTACT'
)<string>()

/**
 * Set the current selected status filter.
 * @param payload New selected status filter value.
 */
export const statusFilterSetSelected = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_STATUS_FILTER_SET_SELECTED'
)<StatusFilter | null>()

/**
 * Set the current tab status filter.
 * @param payload New tab status filter value.
 */
export const statusFilterSetTab = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_STATUS_FILTER_SET_TAB'
)<StatusFilter | null>()

export type IActions =
  | ActionType<typeof addConversationsToCheckboxList>
  | ActionType<typeof removeConversationFromCheckboxList>
  | ActionType<typeof selectAllConversationsFromCheckboxList>
  | ActionType<typeof resetConversationsCheckboxListItems>
  | ActionType<typeof setActionsToggle>
  | ActionType<typeof fetchConversationsList>
  | ActionType<typeof conversationListLiveUpdate>
  | ActionType<typeof removeConversationItemFromList>
  | ActionType<typeof fetchPinnedConversationsList>
  | ActionType<typeof togglePinConversation>
  | ActionType<typeof statusFilterSetSelected>
  | ActionType<typeof statusFilterSetTab>
  | ActionType<typeof setChosenContact>
