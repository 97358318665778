import {
  IInstitutionActions,
  fetchCurrentInstitution,
  updateDefaultCounselor,
  updateDateFormat,
} from 'store/triage/institution/actions'
import { IInstitution } from 'api/response'
import * as _ from 'lodash'
import { getType } from 'typesafe-actions'
import { WebDataStatus } from 'store/escalation/reducer'

const INITIAL_STATE: IInstitutionsState = {
  allIds: [],
  currentInstitution: undefined,
  fetchingCurrent: WebDataStatus.Initial,
}

export interface IInstitutionsState {
  allIds: string[]
  currentInstitution: IInstitution | undefined
  fetchingCurrent: WebDataStatus
}

const reducer = (
  state: IInstitutionsState = INITIAL_STATE,
  action: IInstitutionActions
): IInstitutionsState => {
  switch (action.type) {
    case getType(fetchCurrentInstitution.request):
      return {
        ...state,
        fetchingCurrent: WebDataStatus.Loading,
      }
    case getType(fetchCurrentInstitution.failure):
      return {
        ...state,
        fetchingCurrent: WebDataStatus.Failure,
      }
    case getType(fetchCurrentInstitution.success):
      return {
        ...state,
        allIds: _.merge([action.payload.id], state.allIds),
        currentInstitution: action.payload,
        fetchingCurrent: WebDataStatus.Initial,
      }
    case getType(updateDefaultCounselor.success):
      return {
        ...state,
        currentInstitution: action.payload,
      }
    case getType(updateDateFormat.success):
      if (state.currentInstitution) {
        return {
          ...state,
          currentInstitution: {
            ...state.currentInstitution,
            dateFormat: action.payload.dateFormat,
          },
        }
      }
    default:
      return state
  }
}
export default reducer
