import { LOCATION_CHANGE } from 'connected-react-router'
import { Dispatch, IActions } from 'store/store'
import { get } from 'lodash'
import { Middleware } from '@reduxjs/toolkit'
import { LAST_PAGE_STORAGE_KEY } from 'util/routing'

/* Filter events to track */
export const loggerMiddleware: Middleware = () => (next: Dispatch) => (
  action: IActions
) => {
  /* NOTE(chdsbd): dealing with window is pretty unsafe. */
  if (!get(window, 'ga.loaded', false)) {
    /* GA not loaded, not tracking needed. */
    return next(action)
  }
  switch (action.type) {
    case LOCATION_CHANGE: {
      const { pathname, search, hash } = action.payload.location
      const page = `${pathname}${search}${hash}`
      /* eslint-disable @typescript-eslint/consistent-type-assertions */
      /* tslint:disable:no-any no-unsafe-any */
      if ((window as any).ga != null) {
        ;(window as any).ga('send', 'pageview', page)
      }
      if (window.Appcues) {
        window.Appcues.page()
      }
      /* eslint-enable @typescript-eslint/consistent-type-assertions */
      /* tslint:enable:no-any no-unsafe-any */
      /* tslint:disable-next-line no-console */
      console.info('NAVIGATE:', page)

      /* Save the new page to local storage */
      if (page !== '/login/') {
        localStorage.setItem(LAST_PAGE_STORAGE_KEY, page)
      }

      break
    }
  }
  return next(action)
}
