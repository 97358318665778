import { getType } from 'typesafe-actions'
import {
  IActions,
  showActionConfirmationModal,
  setActionConfirmationModalStatus,
  resetActionConfirmationModalState,
} from 'page/conversations-v2/ConversationList/ConversationHeader/ActionsConfirmationModal/actions'

export type ActionConfirmationModalAction = 'markAsRead' | 'archive' | 'assign'
export type ActionConfirmationModalStatus = 'initial' | 'loading' | 'error'

export type ActionsConfirmationModalState = {
  readonly show: boolean
  readonly action: ActionConfirmationModalAction
  readonly status: ActionConfirmationModalStatus
}

export const initialActionsConfirmationModalState: ActionsConfirmationModalState = {
  action: 'markAsRead',
  status: 'initial',
  show: false,
}

const actionConfirmationModal = (
  state: ActionsConfirmationModalState = initialActionsConfirmationModalState,
  action: IActions
): ActionsConfirmationModalState => {
  switch (action.type) {
    case getType(showActionConfirmationModal):
      return {
        ...state,
        show: action.payload.show,
        action: action.payload.action,
      }
    case getType(setActionConfirmationModalStatus):
      return {
        ...state,
        status: action.payload,
      }
    case getType(resetActionConfirmationModalState):
      return initialActionsConfirmationModalState
    default:
      return state
  }
}

export default actionConfirmationModal
