import { ActionType, createAsyncAction } from 'typesafe-actions'
import * as api from 'api'

export const fetchKnowledgeBaseTopics = createAsyncAction(
  '@@MASCOT/KNOWLEDGE_BASE_TOPICS_FETCH_START',
  '@@MASCOT/KNOWLEDGE_BASE_TOPICS_FETCH_SUCCESS',
  '@@MASCOT/KNOWLEDGE_BASE_TOPICS_FETCH_ERROR'
)<void, api.KBTopicsShapeType, void>()

export type IActions = ActionType<typeof fetchKnowledgeBaseTopics>
