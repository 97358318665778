import {
  IContactConditionGroupRequestData,
  IContactConditionRequestData,
  IContactFilterRequestData,
} from 'api/request'
import {
  IContactConditionGroupResponseData,
  IContactFilterResponseData,
} from 'api/response'
import { ComparisonOperator } from 'components/ContactFilterBuilder/SelectOperator'
import { getValidatorFromAttribute } from 'components/ContactPanel/ContactAttributesValuesForm'
import { FormikProps } from 'formik'
import { IContactSegmentList } from 'store/contact-segments/selectors'
import {
  ContactAttributeType,
  IContactAttribute,
  IContactAttributes,
  IContactField,
  isContactAttribute,
  ITopLevelContactFields,
} from 'store/personalization/contactAttributes/selectors'
import uuid from 'uuid/v4'
import * as nope from 'yup'

export enum ContactFilterRowType {
  GROUP_ALL = 'ALL',
  GROUP_ANY = 'ANY',
  ATTRIBUTE = 'ATTRIBUTE',
  TOP_LEVEL_FIELD = 'TOP_LEVEL_FIELD',
}
export enum CombinationOperator {
  ANY = 'ANY',
  ALL = 'ALL',
}

export interface IContactFilterFormRow {
  uuid?: string
  parentCombination?: CombinationOperator
  contactField?: IContactField | IContactAttribute
  level: number
  type?: ContactFilterRowType
  parameter?: number | string
  operator?: ComparisonOperator
  value?: string
}

interface IValidConditionRow extends IContactFilterFormRow {
  parentCombination: CombinationOperator
  dataType: ContactAttributeType
  type: ContactFilterRowType.ATTRIBUTE | ContactFilterRowType.TOP_LEVEL_FIELD
  operator: ComparisonOperator
  parameter: string | number
  value: string
}

interface IAttributeRow extends IContactFilterFormRow {
  parentCombination: CombinationOperator
  contactField: IContactField
  type: ContactFilterRowType.ATTRIBUTE
  parameter: number
}

interface ITopLevelFieldRow extends IContactFilterFormRow {
  parentCombination: CombinationOperator
  contactField: IContactField
  type: ContactFilterRowType.TOP_LEVEL_FIELD
  parameter: string
}

export interface IGroupRow extends IContactFilterFormRow {
  type: ContactFilterRowType.GROUP_ANY | ContactFilterRowType.GROUP_ALL
  parameter: CombinationOperator.ANY | CombinationOperator.ALL
}

export interface IContactFilterFormData {
  name: string
  id: number | undefined
  rows: IContactFilterFormRow[]
  region?: string
}

export const mapContactFilterToFormData = (
  data: IContactFilterResponseData,
  contactAttributes: IContactAttributes,
  topLevelFields: ITopLevelContactFields
): IContactFilterFormData => {
  return {
    id: data.id,
    name: data.name,
    rows: reduceConditionGroupsToFormRows(
      data.condition_group,
      contactAttributes,
      topLevelFields
    ),
    region: data.region,
  }
}

const reduceConditionGroupsToFormRows = (
  conditionGroup: IContactConditionGroupResponseData,
  contactAttributes: IContactAttributes,
  topLevelFields: ITopLevelContactFields,
  currentLevel: number = 0,
  parentCombination: CombinationOperator = CombinationOperator.ALL
): IContactFilterFormRow[] => {
  const currentRow: IContactFilterFormRow = {
    parentCombination,
    level: currentLevel,
    uuid: uuid(),
    parameter: conditionGroup.combination_type,
    type: conditionGroup.combination_type
      ? conditionGroup.combination_type === CombinationOperator.ALL
        ? ContactFilterRowType.GROUP_ALL
        : ContactFilterRowType.GROUP_ANY
      : undefined,
  }
  const subConditionRows = conditionGroup.conditions.map(c => {
    const parameter = c.contact_attribute
      ? c.contact_attribute.id
      : c.contact_field ?? undefined
    const type = c.contact_attribute
      ? ContactFilterRowType.ATTRIBUTE
      : c.contact_field
      ? ContactFilterRowType.TOP_LEVEL_FIELD
      : undefined
    return {
      uuid: uuid(),
      level: currentLevel + 1,
      parentCombination: conditionGroup.combination_type,
      contactField: getContactField(
        { parameter, type },
        contactAttributes,
        topLevelFields
      ),
      type,
      parameter,
      operator: c.comparison_type ?? undefined,
      value: c.value ?? undefined,
    }
  })

  const subConditionGroups = conditionGroup.condition_groups.reduce(
    (acc: IContactFilterFormRow[], subGroup): IContactFilterFormRow[] => {
      return [
        ...acc,
        ...reduceConditionGroupsToFormRows(
          subGroup,
          contactAttributes,
          topLevelFields,
          currentLevel + 1,
          conditionGroup.combination_type
        ),
      ]
    },
    []
  )

  return [currentRow, ...subConditionRows, ...subConditionGroups]
}

export const convertFormDataToPayload = (
  formData: IContactFilterFormData
): IContactFilterRequestData => {
  // TODO (Manan) - Add test for this function
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const firstRow = formData.rows[0] as IGroupRow
  return {
    id: formData.id ?? undefined,
    name: formData.name,
    condition_group: convertRowsToConditionGroupPayload(
      getRowsInGroup(formData.rows, 0),
      firstRow.parameter
    ),
    region: formData.region,
  }
}

export const convertRowsToConditionGroupPayload = (
  rows: IContactFilterFormRow[],
  combinationType: CombinationOperator
): IContactConditionGroupRequestData => {
  if (!rows || rows.length === 0) {
    return {
      combination_type: CombinationOperator.ANY,
      conditions: [],
      condition_groups: [],
    }
  }

  const conditions: IContactConditionRequestData[] = []
  const subGroups: IContactConditionGroupRequestData[] = []
  const level = rows[0].level
  rows.forEach((row, index) => {
    if (row.level !== level) {
      return
    }
    if (isGroup(row)) {
      subGroups.push(
        convertRowsToConditionGroupPayload(
          getRowsInGroup(rows, index),
          row.parameter
        )
      )
    } else if (isValidCondition(row)) {
      conditions.push({
        contact_attribute_id: isAttributeRow(row) ? row.parameter : undefined,
        contact_field: isTopLevelFieldRow(row) ? row.parameter : undefined,
        comparison_type:
          row.operator === ComparisonOperator.IN_LIST
            ? ComparisonOperator.EQ
            : row.operator === ComparisonOperator.NOT_IN_LIST
            ? ComparisonOperator.NEQ
            : row.operator,
        value: !opIsInListOperator(row.operator) ? row.value : undefined,
        values: opIsInListOperator(row.operator)
          ? Array.isArray(row.value)
            ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              ((row.value as unknown) as string[])
            : row.value.split(',').map(elem => elem.trim())
          : undefined,
      })
    }
  })
  return {
    combination_type: combinationType,
    conditions,
    condition_groups: subGroups,
  }
}

export const getRowsInGroup = (
  rows: IContactFilterFormRow[],
  index: number
): IContactFilterFormRow[] => {
  const parentRow = rows[index]
  const rowsInGroup: IContactFilterFormRow[] = []
  if (parentRow && isGroup(parentRow)) {
    for (let i = index + 1; i < rows.length; i++) {
      const currentRow = rows[i]
      if (currentRow.level <= parentRow.level) {
        break
      }
      rowsInGroup.push(currentRow)
    }
  }
  return rowsInGroup
}

export const isValidRow = (row: IContactFilterFormRow) => {
  return (isGroup(row) && !row.operator && !row.value) || isValidCondition(row)
}

export const isGroup = (
  row?: Pick<IContactFilterFormRow, 'type'>
): row is IGroupRow =>
  row?.type === ContactFilterRowType.GROUP_ALL ||
  row?.type === ContactFilterRowType.GROUP_ANY

const opIsInListOperator = (op: ComparisonOperator) =>
  [ComparisonOperator.IN_LIST, ComparisonOperator.NOT_IN_LIST].includes(op)

const opIsContainsOperator = (op: ComparisonOperator) =>
  [ComparisonOperator.CONTAINS, ComparisonOperator.NOT_CONTAINS].includes(op)

const opIsStartsOrEndsWithOperator = (op: ComparisonOperator) =>
  [ComparisonOperator.STARTS_WITH, ComparisonOperator.ENDS_WITH].includes(op)

export const isValidCondition = (
  row: IContactFilterFormRow
): row is IValidConditionRow =>
  (!isGroup(row) &&
    (row.operator !== undefined && opIsInListOperator(row.operator)
      ? typeof row.parameter === 'string'
      : false)) ||
  ((isAttributeRow(row) || isTopLevelFieldRow(row)) &&
    (row.operator === ComparisonOperator.DNE ||
    row.operator === ComparisonOperator.EXISTS
      ? true
      : !!row.value))

export const isAttributeRow = (
  row: IContactFilterFormRow
): row is IAttributeRow =>
  !isGroup(row) &&
  typeof row.parameter === 'number' &&
  row.type === ContactFilterRowType.ATTRIBUTE &&
  isContactAttribute(row.contactField)

export const isTopLevelFieldRow = (
  row: IContactFilterFormRow
): row is ITopLevelFieldRow =>
  !isGroup(row) &&
  typeof row.parameter === 'string' &&
  row.type === ContactFilterRowType.TOP_LEVEL_FIELD &&
  row.contactField !== undefined &&
  !isContactAttribute(row.contactField)

export const getCombinationType = (rowType: ContactFilterRowType) =>
  rowType === ContactFilterRowType.GROUP_ALL
    ? CombinationOperator.ALL
    : CombinationOperator.ANY

export const getContactField = (
  row: Pick<IContactFilterFormRow, 'parameter' | 'type'>,
  contactAttributes: IContactAttributes,
  topLevelContactFields: ITopLevelContactFields
): IContactField | undefined => {
  return row.type === ContactFilterRowType.ATTRIBUTE
    ? contactAttributes.find(attr => attr.id === row.parameter)
    : row.type === ContactFilterRowType.TOP_LEVEL_FIELD
    ? topLevelContactFields.find(field => field.field === row.parameter)
    : undefined
}

export const setRowFields = (
  form: FormikProps<IContactFilterFormData>,
  fieldPrefix: string,
  newRowData: Partial<IContactFilterFormRow>
) => {
  Object.keys(newRowData).forEach(key => {
    form.setFieldValue(
      `${fieldPrefix}.${key}`,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      newRowData[key as keyof IContactFilterFormRow]
    )
  })
}

export const createFiltersValidationSchema = (
  filters: IContactSegmentList = []
) =>
  nope.object().shape({
    id: nope.number().notRequired(),
    name: nope
      .string()
      .required()
      .min(1, 'Audience name cannot be blank')
      .when('id', (id: number, schema: nope.Schema<string>) => {
        const lowerCaseNames = filters
          .filter(segment => segment.id !== id)
          .map(segment => segment.name.toLowerCase())
        return schema.test(
          'is-dupe',
          'This audience name is already in use',
          (value: string = '') => !lowerCaseNames.includes(value.toLowerCase())
        )
      }),
    rows: nope
      .array()
      .min(2)
      .of(
        nope.object().shape({
          parentCombination: nope
            .mixed()
            .when('level', (level: number) =>
              level === 0
                ? nope.mixed().notRequired()
                : nope.mixed<CombinationOperator>().required()
            ),
          level: nope.number().required(),
          type: nope.mixed<ContactFilterRowType>().required(),
          parameter: nope
            .mixed()
            .when('type', (type: ContactFilterRowType) =>
              type === ContactFilterRowType.ATTRIBUTE
                ? nope.number().required('Parameter is a required field')
                : ContactFilterRowType.TOP_LEVEL_FIELD
                ? nope.string().required('Parameter is a required field')
                : nope.mixed().notRequired()
            ),
          contactField: nope.mixed<IContactField>().notRequired(),
          operator: nope
            .mixed()
            .when('type', (type: ContactFilterRowType) =>
              isGroup({ type })
                ? nope.mixed().notRequired()
                : nope
                    .mixed<ComparisonOperator>()
                    .required('Operator is a required field')
            ),
          value: nope
            .mixed()
            .when(
              ['type', 'operator', 'contactField'],
              (
                type: ContactFilterRowType,
                operator: ComparisonOperator,
                contactField?: IContactField
              ) => {
                if (
                  isGroup({ type }) ||
                  !contactField ||
                  [ComparisonOperator.DNE, ComparisonOperator.EXISTS].includes(
                    operator
                  )
                ) {
                  return nope.mixed().notRequired()
                }
                const allowPartialMatching =
                  opIsContainsOperator(operator) ||
                  opIsStartsOrEndsWithOperator(operator) ||
                  contactField.type === ContactAttributeType.DATE
                return getValidatorFromAttribute(
                  contactField,
                  allowPartialMatching,
                  opIsInListOperator(operator)
                ).required('Value is a required field')
              }
            ),
        })
      ),
  })

export const isFilterDataEqual = (
  a: IContactFilterRequestData,
  b: IContactFilterRequestData
): boolean => {
  return (
    a.region === b.region &&
    isConditionGroupEqual(a.condition_group, b.condition_group)
  )
}

const isConditionGroupEqual = (
  a: IContactConditionGroupRequestData,
  b: IContactConditionGroupRequestData
): boolean => {
  return (
    a.combination_type === b.combination_type &&
    a.condition_groups.length === b.condition_groups.length &&
    a.condition_groups.every((group, i) =>
      isConditionGroupEqual(group, b.condition_groups[i])
    ) &&
    a.conditions.length === b.conditions.length &&
    a.conditions.every((condition, i) =>
      isConditionRowEqual(condition, b.conditions[i])
    )
  )
}

const isConditionRowEqual = (
  a: IContactConditionRequestData,
  b: IContactConditionRequestData
): boolean => {
  return Object.keys(a).every(
    (key: string) =>
      /* eslint-disable @typescript-eslint/consistent-type-assertions */
      a[key as keyof IContactConditionRequestData] ===
      b[key as keyof IContactConditionRequestData]
    /* eslint-enable @typescript-eslint/consistent-type-assertions */
  )
}

export const isNewLevel = (
  currentRow: number,
  rows: IContactFilterFormRow[]
): boolean =>
  currentRow === 0 || rows[currentRow].level > rows[currentRow - 1].level

export const isOnlyRowInGroup = (
  currentRow: number,
  rows: IContactFilterFormRow[]
) => {
  for (let i = currentRow - 1; i > 0; i--) {
    if (rows[i].level < rows[currentRow].level) {
      break
    }
    if (rows[i].level === rows[currentRow].level) {
      return false
    }
  }
  for (let i = currentRow + 1; i < rows.length; i++) {
    if (rows[i].level < rows[currentRow].level) {
      break
    }
    if (rows[i].level === rows[currentRow].level) {
      return false
    }
  }
  return true
}
