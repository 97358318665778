import 'components/Chip/Chip.scss'
import cls from 'classnames'
import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'

export function Chip({
  label,
  className,
  onClear,
  onChange,
  type,
  eventLocation,
  eventAction,
  eventObject,
}: {
  readonly label: string | JSX.Element
  readonly type: 'clear' | 'change'
  readonly onClear?: () => void
  readonly onChange?: () => void
  readonly className?: string
  readonly eventLocation?: string
  readonly eventObject?: string
  readonly eventAction?: EventAction
}) {
  const isClearable = type === 'clear'
  return (
    <div
      className={cls(
        'bg-mainstay-dark-blue-05 border border-mainstay-dark-blue-65 text-mainstay-dark-blue-80 chip mt-1 mb-1 pr-2 border-radius-lg',
        className
      )}>
      <span className="fs-13px">{label}</span>
      <EventTrackerAttrAdder
        eventAction={eventAction}
        eventObject={eventObject}
        eventLocation={eventLocation}>
        <button
          type="button"
          className="btn chip-button fs-1rem"
          onClick={isClearable ? onClear : onChange}
          aria-label={isClearable ? 'Close' : 'Change'}>
          {isClearable ? (
            <span aria-hidden="true">&times;</span>
          ) : (
            <AHIcon name="edit" className="chip-pencil" />
          )}
        </button>
      </EventTrackerAttrAdder>
    </div>
  )
}
