import * as React from 'react'
import {
  getIsStaff,
  getIsAdmitHubUser,
  getUserProfile,
  getIsEngineeringEmployee,
} from 'store/triage/profile/selectors'
import { fetchProfileAsync } from 'store/triage/profile/thunks'
import { useSelector, useDispatch, useIsLoggedIn } from 'util/hooks'

interface IAdmithubOnlyProps {
  readonly children?: React.ReactNode
  readonly fallback?: React.ReactNode
  readonly allowStaffOnly?: boolean
  readonly allowEngineeringOnly?: boolean
}

const AdmithubOnly = ({
  children,
  fallback,
  allowStaffOnly,
  allowEngineeringOnly,
}: IAdmithubOnlyProps) => {
  const isAdmithubUser = useSelector(getIsAdmitHubUser)
  const isStaff = useSelector(getIsStaff)
  const isEngineering = useSelector(getIsEngineeringEmployee)
  const userProfile = useSelector(getUserProfile)
  const dispatch = useDispatch()
  const isAuthed = useIsLoggedIn()

  const shouldLoadData = isAuthed && userProfile.id === ''
  React.useEffect(() => {
    if (shouldLoadData) {
      fetchProfileAsync()(dispatch)
    }
  }, [shouldLoadData, dispatch])

  if (!isAuthed) {
    return <>{fallback}</>
  }

  if (allowEngineeringOnly && !isEngineering) {
    return <>{fallback}</>
  }

  if (allowStaffOnly && !isStaff) {
    return <>{fallback}</>
  }

  if (isAdmithubUser) {
    return <>{children}</>
  }
  return <>{fallback}</>
}

export default AdmithubOnly
