import {
  IConversationActions,
  sendMessage,
} from 'store/triage/chat/conversationActions'
import {
  IMessagesActionTypes,
  getMessagesInConversation,
} from 'store/triage/chat/messagesActions'
import { getType } from 'typesafe-actions'
import { WebData, Success, Loading, Failure, isSuccess } from 'store/webdata'
import { IApiError } from 'api'
import { IConversationsActions as IConversationsPageActions } from 'store/conversations/reducer'

interface IMessageSenderUIState {
  readonly sendingMessage: boolean
}

export interface IConversationState {
  readonly message_senders: {
    readonly [key: string]: IMessageSenderUIState
  }
  readonly conversationStatusById: {
    readonly [key: string]: undefined | WebData<undefined, IApiError>
  }
}

const INITIAL_STATE: IConversationState = {
  message_senders: {},
  conversationStatusById: {},
}

const reducer = (
  state: IConversationState = INITIAL_STATE,
  action:
    | IConversationActions
    | IMessagesActionTypes
    | IConversationsPageActions
): IConversationState => {
  switch (action.type) {
    case getType(getMessagesInConversation.request): {
      // If we already successfully loaded the data, don't do anything.
      const currentStatus = state.conversationStatusById[action.payload]
      if (isSuccess(currentStatus)) {
        return state
      }
      return {
        ...state,
        conversationStatusById: {
          ...state.conversationStatusById,
          [action.payload]: Loading(),
        },
      }
    }
    case getType(getMessagesInConversation.failure): {
      return {
        ...state,
        conversationStatusById: {
          ...state.conversationStatusById,
          [action.payload.contactId]: Failure(action.payload.err),
        },
      }
    }
    case getType(getMessagesInConversation.success): {
      // PERF(chdsbd): Don't update state unless we have new data
      if (isSuccess(state.conversationStatusById[action.payload.contactId])) {
        return state
      }
      return {
        ...state,
        conversationStatusById: {
          ...state.conversationStatusById,
          [action.payload.contactId]: Success(undefined),
        },
      }
    }
    case getType(sendMessage.request): {
      return {
        ...state,
        message_senders: {
          ...state.message_senders,
          [action.payload.userId]: {
            sendingMessage: true,
          },
        },
      }
    }
    case getType(sendMessage.failure): {
      return {
        ...state,
        message_senders: {
          ...state.message_senders,
          [action.payload.pendingId]: {
            sendingMessage: false,
          },
        },
      }
    }
    case getType(sendMessage.success): {
      return {
        ...state,
        message_senders: {
          ...state.message_senders,
          [action.payload.userId]: {
            sendingMessage: false,
          },
        },
      }
    }
    default:
      return state
  }
}
export default reducer
