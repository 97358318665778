import React, { useState } from 'react'
import classnames from 'classnames'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { Tag } from 'components/Tag/Tag'

export function Hint({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  const [hintVisible, toggleHintVisible] = useState(true)

  if (!hintVisible) {
    return null
  }
  return (
    <div
      className={classnames(
        'p-2 bg-light-purple text-dark-purple mt-3',
        className
      )}>
      <div className="d-flex align-items-center justify-content-between">
        <Tag value="Helpful Hints" color="dark-purple" className="text-white" />
        <AHIcon
          name="close"
          className="pointer"
          onClick={() => toggleHintVisible(false)}
        />
      </div>
      <div className="mt-3">{children}</div>
    </div>
  )
}
