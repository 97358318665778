import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'
import React from 'react'
import 'components/ToggleSwitch/ToggleSwitch.scss'
// eslint-disable-next-line no-restricted-imports
import Switch from 'react-switch'
import classNames from 'classnames'
import scssVariables from 'scss/_variables.scss'

interface IToggleSwitchProps {
  readonly id?: string
  readonly checked: boolean
  readonly disabled?: boolean
  readonly className?: string
  readonly ariaLabel?: string
  readonly onChange?: (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent
  ) => void
  readonly eventLocation?: string
  readonly eventAction?: EventAction
  readonly eventObject?: string
  readonly checkedIcon?: JSX.Element | boolean
  readonly uncheckedIcon?: JSX.Element | boolean
}

function noOp() {
  return
}

/**
 * React clone of an iOS style toggle switch
 */
export function ToggleSwitch({
  id,
  disabled,
  checked,
  onChange = noOp,
  className,
  ariaLabel,
  eventLocation,
  eventAction,
  eventObject,
  checkedIcon,
  uncheckedIcon,
}: IToggleSwitchProps) {
  return (
    <EventTrackerAttrAdder
      eventAction={eventAction}
      eventObject={eventObject}
      eventLocation={eventLocation}>
      <Switch
        id={id}
        aria-label={ariaLabel}
        disabled={disabled}
        className={className}
        onColor="#02854A"
        uncheckedIcon={uncheckedIcon ?? false}
        checkedIcon={checkedIcon ?? false}
        width={36}
        height={20}
        checked={checked}
        onChange={onChange}
      />
    </EventTrackerAttrAdder>
  )
}

export interface IToggleSwitchV2Props extends IToggleSwitchProps {
  readonly size: 'sm' | 'lg'
}

export function ToggleSwitchV2({
  id,
  disabled,
  checked,
  size,
  onChange = noOp,
  className,
  ariaLabel,
  eventLocation,
  eventAction,
  eventObject,
  checkedIcon,
  uncheckedIcon,
}: IToggleSwitchV2Props) {
  const width = size === 'sm' ? 36 : 44
  const height = size === 'sm' ? 20 : 24
  return (
    <EventTrackerAttrAdder
      eventAction={eventAction}
      eventObject={eventObject}
      eventLocation={eventLocation}>
      <Switch
        id={id}
        aria-label={ariaLabel}
        disabled={disabled}
        className={classNames(className, {
          [checked ? 'ms-toggle--on' : 'ms-toggle--off']: !disabled,
        })}
        onColor={scssVariables.mainstaySuccess600}
        offColor={scssVariables.mainstayDisabledGray}
        uncheckedIcon={uncheckedIcon ?? false}
        checkedIcon={checkedIcon ?? false}
        width={width}
        height={height}
        checked={checked}
        onChange={onChange}
        activeBoxShadow="none"
      />
    </EventTrackerAttrAdder>
  )
}
