import * as _ from 'lodash'
import {
  IMessagesActionTypes,
  getMessagesInConversation,
  approveMessage,
  undoAnswer,
  ignoreAnswer,
  forwardToStaff,
} from 'store/triage/chat/messagesActions'
import {
  IConversationActions,
  sendMessage,
} from 'store/triage/chat/conversationActions'
import { IExchangesActions } from 'store/triage/chat/exchangesActions'
import {
  IMessageResponseData,
  IBrandedApplicantConversation,
  IConversationReset,
} from 'api/response'
import { ISendSingleTextRequestData } from 'api/request'
import moment from 'moment'
import { getType } from 'typesafe-actions'
import head from 'lodash/head'
import { IAttributeNamesById } from 'store/personalization/institutionAttributes/selectors'

export interface IMedia {
  url: string
}

export interface IMessageState {
  kind: 'general'
  id: string
  body: string
  author: string
  authorName: string
  recipient: string
  inReplyTo?: string | null
  created: Date
  /** either a name or an ID for the user that approved the message */
  lastModifiedBy?: string
  lastModifiedByName: string | null
  approvalStatus: ApprovalStatus
  messageStatus: MessageStatus
  senderType: SenderType
  incoming: boolean
  topic?: string
  matchScore?: number
  matchString?: string
  translations?: {
    [lang: string]: string
  }
  inputContext?: string
  readonly media?: IMedia
  readonly failure?: IMessageResponseData['failure']
  readonly aiLogId: string | null
  readonly lastModifiedAt: string | null
  readonly isPartOfCampaign: boolean | null
  readonly resetAfterMessage?: IConversationReset
  templatedAnswer?: string
  understandingMeta?: IUnderstandingMeta
  attributeNames?: IAttributeNamesById
  contactFilterName?: string
  source?: 'marshall' | 'ai-matcher-1'
}

export interface IUnderstandingMeta {
  understandingId: string
  sampleQuestion: string
  understandingURL: string
  topic?: string
}

export enum MessageStatus {
  Sending = 'Sending',
  Sent = 'Sent',
  Failed = 'Failed',
  Success = 'Success',
  Recieved = 'Recieved',
  Forwarded = 'Sent to college',
}

export enum ApprovalStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Ignored = 'Ignored',
  Fixed = 'Fixed',
  None = 'None',
  /** client side indicator for messages that are being triaged by the current user */
  InProgress = 'In Progress',
}

export enum SenderType {
  /** A student sender */
  Contact = 'Contact',
  /** Mascot application user */
  User = 'User',
  /** Admithub Employee */
  Staff = 'Staff',
  /** Dialog */
  Dialog = 'Dialog',
  /** The bot */
  AI = 'AI',
}

const getMessageStatus = (type: string) => {
  switch (type) {
    case getType(ignoreAnswer.success):
      return ApprovalStatus.Ignored
    case getType(undoAnswer.success):
      return ApprovalStatus.Pending
    case getType(ignoreAnswer.failure):
    case getType(undoAnswer.failure):
    case getType(approveMessage.failure):
      return ApprovalStatus.Pending
    default:
      return ApprovalStatus.Approved
  }
}

export function getMedia(r: IMessageResponseData): IMedia | undefined {
  // TODO(sbdchd): choosing the first media file is copied from Mascot
  // see: https://github.com/AdmitHub/mascot/blob/22bac0218ad50addcab7d927aa6b31632f4683da/imports/client/ui/components/Conversation/MessageBubble/index.js#L211-L211
  // but when we add multiple mediaFiles per message this needs to be changed.
  return head(r.mediaFiles)
}

const INITIAL_MESSAGE_STATE: IMessagesState = {
  byId: {},
  repliesById: {},
  allIds: [],
  loading: {},
  touched: {},
  brandedApplicantConversationsById: {},
  brandedApplicantConversationsAllIds: [],
  bulkLoading: false,
}

export const getResponseMessageMapper = (
  resets?: readonly IConversationReset[]
) => {
  return (responseMessage: IMessageResponseData): IMessageState => {
    return {
      id: responseMessage.id,
      body: responseMessage.translations?.en || responseMessage.body,
      created: moment.utc(responseMessage.created).toDate(),
      inReplyTo: responseMessage.inReplyTo,
      author: responseMessage.author,
      authorName: responseMessage.authorName,
      recipient: responseMessage.recipient,
      senderType: responseMessage.senderType,
      messageStatus: responseMessage.messageStatus,
      approvalStatus: responseMessage.approvalStatus,
      lastModifiedBy: responseMessage.lastModifiedBy,
      lastModifiedByName: responseMessage.lastModifiedByName,
      incoming: responseMessage.messageStatus === MessageStatus.Recieved,
      media: getMedia(responseMessage),
      kind: 'general',
      failure: responseMessage.failure,
      translations: responseMessage.translations,
      matchString: responseMessage.matchString,
      matchScore: responseMessage.matchScore,
      topic: responseMessage.topic,
      inputContext: responseMessage.inputContext,
      aiLogId: responseMessage.aiLogId,
      lastModifiedAt: responseMessage.lastModifiedAt,
      isPartOfCampaign: responseMessage.isPartOfCampaign,
      resetAfterMessage: resets?.find(
        reset =>
          reset.lastMessageId ===
          (responseMessage.smsLogId || responseMessage.id)
      ),
      templatedAnswer: responseMessage.templatedAnswer || undefined,
      understandingMeta: responseMessage.understandingId
        ? {
            understandingId: responseMessage.understandingId,
            sampleQuestion: responseMessage.sampleQuestion || '',
            understandingURL: responseMessage.understandingURL || '',
            topic: responseMessage.topic,
          }
        : undefined,
      attributeNames: responseMessage.attributeNames || undefined,
      contactFilterName: responseMessage.contactFilterName || undefined,
      source: responseMessage.source,
    }
  }
}

const mapPendingMessage = (
  userId: string,
  pendingId: string,
  responseMessage: ISendSingleTextRequestData
): IMessageState => {
  return {
    id: pendingId,
    body: responseMessage.body,
    created: new Date(),
    inReplyTo: '',
    author: userId,
    // we shouldn't be using the authorName for these messages, but we need to satisfy IMessageState
    authorName: userId,
    recipient: responseMessage.userId,
    senderType: SenderType.User,
    messageStatus: MessageStatus.Sending,
    approvalStatus: ApprovalStatus.None,
    lastModifiedBy: '',
    lastModifiedByName: '',
    lastModifiedAt: null,
    incoming: false,
    kind: 'general',
    aiLogId: null,
    isPartOfCampaign: false,
  }
}

interface IMessagesState {
  byId: {
    [key: string]: IMessageState
  }
  repliesById: {
    [inReplyTo: string]: IMessageState
  }
  allIds: string[]
  readonly brandedApplicantConversationsById: {
    readonly [key: string]: IBrandedApplicantConversation | undefined
  }
  readonly brandedApplicantConversationsAllIds: ReadonlyArray<
    IBrandedApplicantConversation['id']
  >
  loading: {
    [key: string]: boolean
  }
  touched: {
    [userId: string]: boolean
  }
  bulkLoading: boolean
}

const reducer = (
  state: IMessagesState = INITIAL_MESSAGE_STATE,
  action: IMessagesActionTypes | IConversationActions | IExchangesActions
): IMessagesState => {
  switch (action.type) {
    case getType(forwardToStaff.success): {
      const username = action.payload.user.name
      const name = `${username.first} ${username.last}`
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            messageStatus: MessageStatus.Forwarded,
            lastModifiedBy: name,
          },
        },
        loading: {
          ...state.loading,
          [action.payload.id]: false,
        },
      }
    }
    case getType(undoAnswer.success):
    case getType(ignoreAnswer.success):
    case getType(approveMessage.success): {
      const message = state.byId[action.payload.id]
      if (!message) {
        // TODO(sbdchd): we shouldn't throw
        throw new Error('Message does not exist.')
      }
      const username = action.payload.user.name
      const name = `${username.first} ${username.last}`
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...message,
            approvalStatus: getMessageStatus(action.type),
            lastModifiedBy: name,
          },
        },
        loading: {
          ...state.loading,
          [action.payload.id]: false,
        },
      }
    }
    case getType(forwardToStaff.request): {
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload]: true,
        },
      }
    }
    case getType(undoAnswer.request):
    case getType(ignoreAnswer.request):
    case getType(approveMessage.request):
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload]: {
            ...state.byId[action.payload],
            approvalStatus: getMessageStatus(action.type),
          },
        },
        loading: {
          ...state.loading,
          [action.payload]: true,
        },
      }
    case getType(undoAnswer.failure):
    case getType(ignoreAnswer.failure):
    case getType(forwardToStaff.failure):
    case getType(approveMessage.failure): {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            approvalStatus: getMessageStatus(action.type),
          },
        },
        loading: {
          ...state.loading,
          [action.payload.id]: false,
        },
      }
    }
    case getType(getMessagesInConversation.failure): {
      return {
        ...state,
        touched: {
          ...state.touched,
          [action.payload.contactId]: true,
        },
      }
    }
    case getType(getMessagesInConversation.success): {
      const ids = action.payload.data.messages.map(x => x.id)
      const filtered = _.pickBy(
        state.byId,
        x => x.messageStatus !== MessageStatus.Success
      )
      const mapped = action.payload.data.messages.map(
        getResponseMessageMapper(action.payload.data.conversationResets)
      )
      const messages = _.keyBy(mapped, 'id')
      const replies = _.keyBy(mapped, 'inReplyTo')
      const brandedApplicantConversationsAllIds = action.payload.data.brandedApplicantConversations.map(
        x => x.id
      )

      const baseObj: Mutable<IMessagesState['brandedApplicantConversationsById']> = {}

      return {
        ...state,
        allIds: _.uniq([...Object.keys(filtered), ...ids]),
        byId: {
          ...filtered,
          ...messages,
        },
        repliesById: {
          ...replies,
        },
        brandedApplicantConversationsById: action.payload.data.brandedApplicantConversations.reduce(
          (acc, cur) => {
            acc[cur.id] = cur
            return acc
          },
          baseObj
        ),
        brandedApplicantConversationsAllIds,
        touched: {
          ...state.touched,
          [action.payload.contactId]: true,
        },
      }
    }
    case getType(sendMessage.success): {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pendingId]: {
            ...state.byId[action.payload.pendingId],
            messageStatus: MessageStatus.Success,
          },
        },
      }
    }
    case getType(sendMessage.failure): {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pendingId]: {
            ...state.byId[action.payload.pendingId],
            messageStatus: MessageStatus.Failed,
          },
        },
      }
    }
    case getType(sendMessage.request): {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pendingId]: mapPendingMessage(
            action.payload.userId,
            action.payload.pendingId,
            action.payload.message
          ),
        },
      }
    }
    default:
      return state
  }
}

export default reducer
