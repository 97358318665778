import * as React from 'react'
import { OutsideClickHandler } from 'components/OutsideClickHandler/OutsideClickHandler'
import { Popper, Reference, Manager } from 'react-popper'
import { Placement, Modifiers } from 'popper.js'
import classnames from 'classnames'

export interface IPopoverComponentProps {
  visible: boolean
  popoverPlacement: Placement
  onClickOutside: () => void
  renderReference: () => React.ReactElement
  renderPopper: () => React.ReactElement | null
  modifiers?: Modifiers
  positionFixed?: boolean
  className?: string
  tabIndex?: number
}

export class PopoverComponent extends React.PureComponent<
  IPopoverComponentProps
> {
  render() {
    const {
      onClickOutside,
      renderReference,
      visible,
      modifiers,
      popoverPlacement,
      renderPopper,
      positionFixed,
      className,
      tabIndex = 0,
    } = this.props
    return (
      <OutsideClickHandler
        tabbable={tabIndex >= 0}
        onClickOutside={onClickOutside}>
        <Manager>
          <Reference>
            {({ ref }) => (
              <span ref={ref} className={className} tabIndex={tabIndex}>
                {renderReference()}
              </span>
            )}
          </Reference>
          {visible && (
            <Popper
              modifiers={modifiers}
              positionFixed={positionFixed}
              placement={popoverPlacement}>
              {({ ref, style, placement }) => (
                <div
                  className={classnames('popper', className)}
                  ref={ref}
                  style={style}
                  data-placement={placement}>
                  {renderPopper()}
                </div>
              )}
            </Popper>
          )}
        </Manager>
      </OutsideClickHandler>
    )
  }
}
