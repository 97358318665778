import { RootState as IState } from 'store/store'
import { createSelector } from 'reselect'
import { IImportReportListPageRouteProps } from 'components/IRouteProps'

import * as _ from 'lodash'
import { IImportReport, IImportReportState } from 'store/import-reports/reducer'
import { WebData } from 'store/webdata'

export const IMPORT_REPORTS_PER_PAGE = 30

export const getImportReportIds = (state: IState) => {
  return state.importReports.allIds
}

export const getImportReportsById = (state: IState) => {
  return state.importReports.byId
}

export const getSelectedImportReport = (
  state: IState,
  id: string
): IImportReport | undefined => {
  return state.importReports.byId[id]
}

export const categorizeErrors = (report?: IImportReport) => {
  const allErrors: { [index: string]: string[] } = {}
  if (report && report?.errors?.length) {
    report.errors.forEach(e => {
      if (!_.get(allErrors, e.error)) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        allErrors[e.error] = _.get(e, 'details.rows', []) as string[]
      } else {
        allErrors[e.error] = allErrors[e.error].concat(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          _.get(e, 'details.rows', []) as string[]
        )
      }
    })
  }
  return allErrors
}

export const getPageNumber = (ownProps: IImportReportListPageRouteProps) => {
  if (ownProps.match.params.currentPage) {
    return parseInt(ownProps.match.params.currentPage, 10) || 1
  }
  return 1
}

export const getPageSize = (state: IState) => state.importReports.ui.pageSize

export const getTotalNumberOfImportReportsPages = (state: IState) =>
  Math.ceil(state.importReports.totalCount / IMPORT_REPORTS_PER_PAGE)

export const getFullImportReportList = createSelector(
  getImportReportIds,
  getImportReportsById,
  (ids: string[], importReportsById) =>
    ids.map(id => {
      return importReportsById[id]
    })
)

export const getIsLoadingImportReports = (
  state: IImportReportState
): WebData<{}> => {
  return state.loadingStatus
}

export const getSearchQuery = (state: IState) => {
  return state.importReports.ui.searchQuery
}

export const getOrdering = (state: IState) => {
  return state.importReports.ui.ordering
}

export const getFieldMapping = (report: IImportReport | null | undefined) => {
  if (report) {
    const alteredMapping: { [field: string]: string } = {}
    for (const field of Object.keys(report.csvColumnMapping)) {
      // if field has a value of _custom.{field} in importReport.csvColumnMapping, don't display "_custom."
      if (report.csvColumnMapping[field].includes('_custom.')) {
        alteredMapping[field] = report.csvColumnMapping[field].slice(8)
      } else {
        alteredMapping[field] = report.csvColumnMapping[field]
      }
    }
    return alteredMapping
  }
  return {}
}

export const getFieldMappingSelector = createSelector(
  getSelectedImportReport,
  report => {
    return getFieldMapping(report)
  }
)
