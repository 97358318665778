import {
  createAsyncAction,
  ActionType,
  createStandardAction,
} from 'typesafe-actions'
import { IApiError } from 'api'
import {
  IContactSegmentsListResponse,
  IFilteredContactsResponseData,
} from 'api/response'
import { IContactFilterRequestData } from 'api/request'

export const listContactSegmentsAction = createAsyncAction(
  '@@@mascot/campaign-scheduler/LIST_CONTACT_SEGMENTS_START',
  '@@@mascot/campaign-scheduler/LIST_CONTACT_SEGMENTS_END',
  '@@@mascot/campaign-scheduler/LIST_CONTACT_SEGMENTS_FAIL'
)<void, IContactSegmentsListResponse, IApiError>()

export const fetchFilteredContacts = createAsyncAction(
  '@@mascot/contact-segments/FETCH_RECIPIENTS_START',
  '@@mascot/contact-segments/FETCH_RECIPIENTS_SUCCESS',
  '@@mascot/contact-segments/FETCH_RECIPIENTS_FAIL'
)<IContactFilterRequestData | void, IFilteredContactsResponseData, IApiError>()

export const clearImportedContacts = createStandardAction(
  '@@mascot/contact-segments/CLEAR_RECIPIENTS'
)<void>()

export const clearFilterTask = createAsyncAction(
  '@@mascot/contact-segments/CLEAR_FILTER_TASK_START',
  '@@mascot/contact-segments/CLEAR_FILTER_TASK_SUCCESS',
  '@@mascot/contact-segments/FETCH_RECIPIENTS_FAIL'
)<void, void, IApiError>()

export type IContactSegmentActions =
  | ActionType<typeof listContactSegmentsAction>
  | ActionType<typeof fetchFilteredContacts>
  | ActionType<typeof clearImportedContacts>
  | ActionType<typeof clearFilterTask>
