import * as React from 'react'
import * as Raven from '@sentry/browser'
import oops from 'assets/oli-robot.svg'
import { Button } from 'components/Button/Button'
import {
  getItemFromLocalStorage,
  LAST_AUTO_REFRESH_KEY,
  setItemToLocalStorage,
} from 'store/localStorage'

interface IErrorBoundaryState {
  error: ErrorKinds
}

type IError = ReturnType<typeof Error>

const enum ErrorKinds {
  Initial,
  General,
  ChunkLoading,
}

function reloadPage() {
  location.reload()
}

/*
  When testing this error boundary for chunk loading errors, it's useful to
  block specific chunks from loading via the chrome dev tools to raise a chunk
  loading exception. See here for details: https://stackoverflow.com/a/48379729
*/
function ChunkLoadingError() {
  return (
    <>
      <p className="mb-0">This page failed to load correctly.</p>
      <p className="mb-0">Reloading usually fixes this issue.</p>
      <Button
        color="primary"
        height="small"
        className="mt-1"
        onClick={reloadPage}>
        Reload Page
      </Button>
    </>
  )
}

function SentMessage() {
  return (
    <>
      <p className="mb-1">
        Our team has been notified. You can help us by optionally sending an
        error report.
      </p>
      <Button
        color="primary"
        height="small"
        className="mb-4"
        onClick={() => Raven.showReportDialog()}>
        Send Report
      </Button>
      <p className="mb-1">Sometimes reloading the page fixes the issue.</p>
      <Button
        color="primary"
        height="small"
        className="mb-1"
        onClick={reloadPage}>
        Reload Page
      </Button>
    </>
  )
}
function NoMessage() {
  return (
    <>
      You can help us by contacting Mainstay support at{' '}
      <a href="mailto:support@mainstay.com">support@mainstay.com</a>.
    </>
  )
}

export default class ErrorBoundary extends React.Component<
  {},
  IErrorBoundaryState
> {
  state = {
    error: ErrorKinds.Initial,
  }

  componentDidCatch(error: IError, _errorInfo: React.ErrorInfo) {
    const errorKind =
      error.message.includes('Loading chunk') ||
      error.message.includes('Loading CSS chunk')
        ? ErrorKinds.ChunkLoading
        : ErrorKinds.General
    this.setState({ error: errorKind })
    Raven.captureException(error)
    if (errorKind === ErrorKinds.ChunkLoading) {
      const lastRefresh = getItemFromLocalStorage(LAST_AUTO_REFRESH_KEY)
      // lastRefresh could be undefined if local storage access is prohibited
      // however - you can't really log into the platform without local storage APIs, so I'm not
      // 100% sure how a user would get to this point
      if (lastRefresh !== undefined) {
        // lastRefresh will be `null` the first time this happens
        if (lastRefresh === null) {
          setItemToLocalStorage(LAST_AUTO_REFRESH_KEY, Date.now().toString())
          reloadPage()
        } else if (Date.now() - Number(lastRefresh) > 300000) {
          // If lastRefresh token is 5 minutes or older, refresh for user
          setItemToLocalStorage(LAST_AUTO_REFRESH_KEY, Date.now().toString())
          reloadPage()
        }
      }
    }
  }

  render() {
    /* If we have a lastEventId, we know we have sent a message to Sentry successfuly. */
    const errorSent = !!Raven.lastEventId()
    if (this.state.error) {
      /* Display error dialog when we have caught an error */
      return (
        <div className="align-items-center d-flex flex-column justify-content-center height-50vh">
          <div className="media mt-auto mb-auto align-items-center col-sm-8 col-lg-6">
            <img className="mr-3 w-8rem" src={oops} alt="Oli Robot" />
            <div className="media-body">
              <h5 className="mt-0">We're sorry — something's gone wrong.</h5>
              {/* We have two error messages to address the cases of an error being sent,
                  or not sent, to Sentry sucessfully. */}
              {this.state.error === ErrorKinds.ChunkLoading ? (
                <ChunkLoadingError />
              ) : errorSent ? (
                <SentMessage />
              ) : (
                <NoMessage />
              )}
            </div>
          </div>
        </div>
      )
    } else {
      /* Render everything normally in happy case */
      return this.props.children
    }
  }
}
