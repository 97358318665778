import { createStandardAction, getType } from 'typesafe-actions'

const INITIAL_STATE = {
  orgSwitcherOpen: false,
}

export interface IPageState {
  orgSwitcherOpen: boolean
}

export const toggleOrgSwitcher = createStandardAction(
  '@@@MASCOT/TOGGLE_ORG_SWITCHER'
)<boolean>()

export type IPageActions = ReturnType<typeof toggleOrgSwitcher>

const reducer = (
  state: IPageState = INITIAL_STATE,
  action: IPageActions
): IPageState => {
  switch (action.type) {
    case getType(toggleOrgSwitcher):
      return {
        ...state,
        orgSwitcherOpen: action.payload,
      }
    default:
      return state
  }
}

export default reducer
