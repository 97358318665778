import { createAsyncAction, ActionType, getType } from 'typesafe-actions'
import {
  WebData,
  Loading,
  Success,
  Failure,
  HttpErrorKind,
} from 'store/webdata'
import { InstitutionListItem } from 'api'

export const fetchInstitutions = createAsyncAction(
  '@@MASCOT/v2/FETCH_INSTITUTIONS_START',
  '@@MASCOT/v2/FETCH_INSTITUTIONS_SUCCESS',
  '@@MASCOT/v2/FETCH_INSTITUTIONS_FAILURE'
)<undefined, InstitutionListItem[], HttpErrorKind>()

export type IInstitutionActions = ActionType<typeof fetchInstitutions>

interface IInstitutionState {
  readonly institutions: WebData<InstitutionListItem[]>
}

const defaultState = {
  institutions: undefined,
}

export const institutionReducer = (
  state: IInstitutionState = defaultState,
  action: IInstitutionActions
) => {
  switch (action.type) {
    case getType(fetchInstitutions.request):
      return {
        ...state,
        institutions: Loading(),
      }
    case getType(fetchInstitutions.success):
      return {
        ...state,
        institutions: Success(action.payload),
      }
    case getType(fetchInstitutions.failure):
      return {
        ...state,
        institutions: Failure(action.payload),
      }
    default:
      return state
  }
}

export default institutionReducer
