import { TimeUnit } from 'components/DurationPicker/DurationPicker'
import pluralize from 'pluralize'

/**
 * Convert a duration in minutes into hours or days, rounded down
 */
export const minutesToUnit = (
  timeInMinutes: number,
  unit: TimeUnit
): number => {
  const integerInMinutes = Math.floor(timeInMinutes)
  switch (unit) {
    case TimeUnit.days:
      return Math.floor(integerInMinutes / 1440)
    case TimeUnit.hours:
      return Math.floor(integerInMinutes / 60)
    case TimeUnit.minutes:
      return integerInMinutes
    case TimeUnit.seconds:
      return integerInMinutes * 60
  }
}

/**
 * Convert a duration in hours or days into minutes
 */
export const unitToMinutes = (timeInUnit: number, unit: TimeUnit): number => {
  const integerInUnit = Math.floor(timeInUnit)
  switch (unit) {
    case TimeUnit.days:
      return integerInUnit * 1440
    case TimeUnit.hours:
      return integerInUnit * 60
    case TimeUnit.minutes:
      return integerInUnit
    case TimeUnit.seconds:
      return Math.floor(integerInUnit / 60)
  }
}

/**
 * Convert a duration in days, hours, and minutes to only minutes
 */

export const DHMToMinutes = (DHM: {
  days: number
  hours: number
  minutes: number
}): number => {
  return (
    Math.floor(DHM.days) * 1440 +
    Math.floor(DHM.hours) * 60 +
    Math.floor(DHM.minutes)
  )
}

/**
 * Convert a duration in minutes to days, hours, and minutes
 */

export const minutesToDHM = (
  timeInMinutes: number
): {
  days: number
  hours: number
  minutes: number
} => {
  const integerInMinutes = Math.floor(timeInMinutes)
  const days = Math.floor(integerInMinutes / 1440)
  const hours = Math.floor((integerInMinutes % 1440) / 60)
  const minutes = Math.floor(integerInMinutes % 60)
  return { days, hours, minutes }
}

/**
 * Convert a duration in minutes to the most accurrate unit
 */

export const minutesToMostAccurateUnit = (
  timeInMinutes: number
): {
  unit: TimeUnit
  value: number
} => {
  if (timeInMinutes % 1440 === 0) {
    return {
      unit: TimeUnit.days,
      value: timeInMinutes / 1440,
    }
  } else if (timeInMinutes % 60 === 0) {
    return {
      unit: TimeUnit.hours,
      value: timeInMinutes / 60,
    }
  } else {
    return {
      unit: TimeUnit.minutes,
      value: timeInMinutes,
    }
  }
}

export const secondsToDHMS = (
  timeInSeconds: number
): {
  days: number
  hours: number
  minutes: number
  seconds: number
} => {
  const integerInSeconds = Math.floor(timeInSeconds)
  const days = Math.floor(integerInSeconds / (60 * 60 * 24))
  const hours = Math.floor((integerInSeconds % (60 * 60 * 24)) / (60 * 60))
  const minutes = Math.floor((integerInSeconds % (60 * 60)) / 60)
  const seconds = Math.floor(integerInSeconds % 60)
  return { days, hours, minutes, seconds }
}

/*
 * Convert a duration in days, hours, and minutes to flat value in seconds
 */
export const DHMToSeconds = (DHM: {
  days?: number
  hours?: number
  minutes?: number
}): number => {
  return (
    Math.floor(DHM.days || 0) * 86400 +
    Math.floor(DHM.hours || 0) * 3600 +
    Math.floor(DHM.minutes || 0) * 60
  )
}

export const minutesToDisplayUnits = (timeInMinutes: number): string => {
  return timeInMinutes < 60
    ? timeInMinutes + pluralize(' Minute', timeInMinutes)
    : timeInMinutes < 1440
    ? timeInMinutes / 60 + pluralize(' Hour', timeInMinutes / 60)
    : timeInMinutes < 365 * 1440
    ? timeInMinutes / 1440 + pluralize(' Day', timeInMinutes / 1440)
    : timeInMinutes / (365 * 1440) +
      pluralize(' Year', timeInMinutes / (365 * 1440))
}
