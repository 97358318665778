import * as Raven from '@sentry/browser'

/**
 * @example
 * interface Square {
 *   kind: "square";
 *   size: number;
 * }
 * interface Rectangle {
 *   kind: "rectangle";
 *   width: number;
 *   height: number;
 * }
 * interface Circle {
 *   kind: "circle";
 *   radius: number;
 * }
 * type Shape = Square | Rectangle | Circle | Triangle;
 * function area(s: Shape) {
 *     switch (s.kind) {
 *         case "square": return s.size * s.size;
 *         case "rectangle": return s.height * s.width;
 *         case "circle": return Math.PI * s.radius ** 2;
 *         default: return assertNever(s); // error here if there are missing cases
 *     }
 * }
 * // from: https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
 */
export function assertNever(x: never): never {
  Raven.captureMessage(`Exhaustiveness check violation. Found ${x}`)
  return x
}
