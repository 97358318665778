import React from 'react'
import cls from 'classnames'
import {
  MainstaySidebarLinkType,
  MainstaySidebarSectionDivider,
  MainstaySidebarSectionLink,
} from 'mainstay-ui-kit/MainstaySidebar/MainstaySidebarSectionLink/MainstaySidebarSectionLink'

const LIST_SCROLL_TOP_OFFSET = 20
const SIDEBAR_NESTED_LIST_OFFSET = 100

export default function MainstaySidebarNestedSection({
  link,
}: {
  readonly link: MainstaySidebarLinkType
}) {
  const [shadows, setShadows] = React.useState<{
    readonly top: boolean
    readonly bottom: boolean
  }>({ top: false, bottom: true })
  const [
    listElementHeightUpdated,
    setListElementHeightUpdated,
  ] = React.useState<boolean>(false)

  React.useLayoutEffect(() => {
    const sidebarElement = document.querySelector('.mainstay-sidebar')
    const sectionsContainerElement = document.querySelector(
      '.mainstay-sidebar-sections-container'
    )
    const listElement = document.querySelector<HTMLElement>(
      `[data-uniq-id="${link.to}"]`
    )

    if (
      listElementHeightUpdated ||
      !link.active ||
      !link.expand ||
      !link.scrollable ||
      !sidebarElement ||
      !sectionsContainerElement ||
      !listElement
    ) {
      return
    }

    listElement.style.maxHeight = `${Math.abs(
      sidebarElement.clientHeight -
        (sectionsContainerElement.scrollHeight - listElement.scrollHeight) -
        SIDEBAR_NESTED_LIST_OFFSET
    )}px`

    const activeItem = listElement.querySelector('.active')
    if (activeItem) {
      activeItem.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }

    setListElementHeightUpdated(true)
  }, [link, listElementHeightUpdated])

  const onScroll = (e: React.UIEvent<HTMLElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight

    if (e.currentTarget.scrollTop < LIST_SCROLL_TOP_OFFSET) {
      setShadows({ bottom: true, top: false })
    } else if (e.currentTarget.scrollTop > LIST_SCROLL_TOP_OFFSET && !bottom) {
      setShadows({ bottom: true, top: true })
    } else if (bottom) {
      setShadows({ bottom: false, top: true })
    }
  }

  const getScrollClassNames = (link: MainstaySidebarLinkType) => {
    if (!link.scrollable) {
      return 'pl-4'
    }

    return cls(
      'mt-1 pl-4 overflow-y-auto d-flex flex-column',
      {
        'mainstay-sidebar-expand-list':
          link.expand && link.expand.length > 1 && link.scrollable,
        'mainstay-sidebar-expand-list-top-shadow':
          shadows.top && !shadows.bottom,
        'mainstay-sidebar-expand-list-bottom-shadow':
          shadows.bottom && !shadows.top,
        'mainstay-sidebar-expand-list-both-shadow':
          shadows.top && shadows.bottom,
      },
      link.scrollContentClassName
    )
  }

  return (
    <div
      key={link.to}
      className={cls('d-flex flex-column', link.containerClassName)}>
      <MainstaySidebarSectionLink key={link.to} link={link} />
      {(link.active || link.forceOpen) && link.expand?.length && (
        <div
          className={cls('d-flex flex-column', {
            'flex-grow-1': link.active && !!link.expand,
          })}>
          <div
            data-uniq-id={link.to}
            onScroll={onScroll}
            className={getScrollClassNames(link)}>
            {(link.expand || []).map(l => (
              <React.Fragment key={l.to}>
                <MainstaySidebarNestedSection link={l} />
                {link.divider && <MainstaySidebarSectionDivider />}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
      {link.divider && <MainstaySidebarSectionDivider />}
    </div>
  )
}
