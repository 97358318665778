import { setMostRecentAIResponse } from 'store/ask-ai/actions'
import { IActions } from 'store/store'

import { getType } from 'typesafe-actions'

export type AskAIQueryAnswer =
  | {
      kind: 'answer'
      query: string
      answer: string
      transactionId: number
    }
  | { kind: 'initial' }
  | { kind: 'loading'; query: string }
  | { kind: 'error'; query: string }

export interface IAskAIState {
  response: AskAIQueryAnswer
}

const initialState: IAskAIState = {
  response: { kind: 'initial' },
}

export const askAIReducer = (
  state: IAskAIState = initialState,
  action: IActions
): IAskAIState => {
  switch (action.type) {
    case getType(setMostRecentAIResponse): {
      return {
        response: action.payload,
      }
    }
    default:
      return state
  }
}
