export function LaptopIcon({
  online,
  className,
  fillOnline = '#00C6A4',
  fillOffline = '#536D92',
  width = '22',
  height = '16',
}: {
  readonly online?: boolean
  readonly className?: string
  readonly fillOnline?: string
  readonly fillOffline?: string
  readonly width?: string
  readonly height?: string
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 22 16"
      fill={online ? fillOnline : fillOffline}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M18.348 11.2835V1.89563H3.65292V11.2835H18.348ZM12.848 14.1044V13.1791H9.15292V14.1044H12.848ZM20.152 14.1044H22V16H0V14.1044H1.848V0H20.1529V14.1044H20.152Z" />
    </svg>
  )
}
