import React, { useCallback, ChangeEvent } from 'react'
import { IContactLabel } from 'store/contacts/reducer'
import { IAutoSuggestOption } from 'components/AutoSuggest/AutoSuggest'
import { getContactLabels, createContactLabel } from 'api'
import { ValueType } from 'react-select/lib/types'
import { AxiosError } from 'typings/axios'
import { toastOnHttpError500or400 } from 'api/http'
import AsyncAutoSuggest from 'components/AutoSuggest/AsyncAutoSuggest'
import { debounce, uniqBy } from 'lodash'
import { DropdownIndicator } from 'components/SettingsEscalation/reactSelectUtils'

export type ContactLabelAutoSuggestValueType = ValueType<
  IAutoSuggestOption<IContactLabel>
>

export const contactLabelToAutoSuggestOption = (
  label: IContactLabel
): IAutoSuggestOption<IContactLabel> => {
  return {
    value: label.id,
    label: label.text,
    data: label,
  }
}

export function ContactLabelAutoSuggest({
  name,
  value,
  onSelectLabel,
  creatable,
  multi,
  error,
  isValid,
  touched,
  onBlur,
  placeholder,
  createOptionPosition,
}: {
  name?: string
  creatable?: boolean
  multi?: boolean
  value?: ContactLabelAutoSuggestValueType
  error?: string
  isValid?: boolean
  touched?: boolean
  onBlur?: (event: { target: { name?: string } }) => void
  placeholder?: string
  createOptionPosition?: 'first' | 'last'
  onSelectLabel: (
    event: React.ChangeEvent<{
      value: string | string[]
      data: ContactLabelAutoSuggestValueType
    }>
  ) => void
}) {
  const handleCreateContactLabel = useCallback(
    (v: string) => {
      createContactLabel(v)
        .then(r => {
          const newOption: IAutoSuggestOption<IContactLabel> = contactLabelToAutoSuggestOption(
            r.data
          )
          let newData: ContactLabelAutoSuggestValueType = newOption
          let newValue: string | string[] = newOption.value
          if (multi && Array.isArray(value)) {
            newData = [...value, newData]
            newValue = newData.map(x => x.value)
          }
          /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
          onSelectLabel({
            target: {
              name: name || '',
              value: newValue,
              data: newData,
            },
          } as ChangeEvent<{ value: string | string[]; name: string; data: ContactLabelAutoSuggestValueType }>)
        })
        .catch((e: AxiosError) => {
          toastOnHttpError500or400(e)
        })
    },
    [multi, value, name, onSelectLabel]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchContactLabels = useCallback(
    debounce(
      (
        inputValue: string,
        callback: (options: IAutoSuggestOption<IContactLabel>[]) => void
      ) => {
        let defaultOptions: IAutoSuggestOption<IContactLabel>[] = []
        if (value) {
          if (Array.isArray(value)) {
            defaultOptions = value
          } else {
            defaultOptions = [value]
          }
        }
        getContactLabels({ search: inputValue })
          .then(r => {
            callback(
              uniqBy(
                [
                  ...r.data.results.map(x =>
                    contactLabelToAutoSuggestOption(x)
                  ),
                  ...defaultOptions,
                ],
                'value'
              )
            )
          })
          .catch((e: AxiosError) => {
            toastOnHttpError500or400(e)
          })
      },
      300
    ),
    [value]
  )

  return (
    <AsyncAutoSuggest<IContactLabel>
      eventAction="change"
      eventObject="label"
      creatable={creatable}
      defaultOptions
      multi={multi}
      name={name}
      value={value}
      className="h-auto"
      onCreate={handleCreateContactLabel}
      onChange={onSelectLabel}
      loadOptions={fetchContactLabels}
      touched={touched}
      isValid={isValid}
      error={error}
      onBlur={onBlur}
      placeholder={placeholder}
      createOptionPosition={createOptionPosition}
      components={{
        NoOptionsMessage: () => (
          <div className="text-center text-muted p-2 font-italic">
            Type a new label name to create one.
          </div>
        ),
        DropdownIndicator,
        IndicatorSeparator: undefined,
      }}
    />
  )
}
