import * as React from 'react'
import classNames from 'classnames'
import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'
import 'components/FancyTextBox/FancyTextBox.scss'

export interface ITextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean
  height?: 'sm' | 'md' | 'lg'
  placeholder?: string
  ariaLabel?: string
  eventLocation?: string
  eventObject?: string
  eventAction?: EventAction
  readOnly?: boolean
}

export const TextInput = React.forwardRef<HTMLInputElement, ITextInputProps>(
  (
    { error, height = 'md', readOnly = false, ...props }: ITextInputProps,
    ref
  ) => {
    const [active, setActive] = React.useState(false)
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setActive(false)
      if (props.onBlur) {
        props.onBlur(e)
      }
    }
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setActive(true)
      if (props.onFocus) {
        props.onFocus(e)
      }
    }
    // for info on the 'is-invalid' class, see:
    // see: https://getbootstrap.com/docs/4.1/components/forms/#server-side
    const className = classNames(
      [props.className],
      'form-control',
      `form-control-${height}`,
      active && !readOnly ? 'active-input' : 'inactive-input',
      readOnly ? 'read-only-input' : '',
      { 'is-invalid': error, 'has-value': !!props.value }
    )

    const { eventLocation, eventAction, eventObject, ...inputProps } = props
    return (
      <EventTrackerAttrAdder
        eventLocation={eventLocation}
        eventAction={eventAction}
        eventObject={eventObject}>
        <input
          {...inputProps}
          disabled={!!inputProps.disabled || !!readOnly}
          required={!!props.required}
          ref={ref}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={className}
          autoComplete="off"
          placeholder={props.placeholder}
          aria-label={props.ariaLabel}
        />
      </EventTrackerAttrAdder>
    )
  }
)
