import scssVariables from 'scss/_variables.scss'

export function AudienceIcon({
  width = 30,
  height = 31,
  fill = scssVariables.mainstayDarkBlue80,
  className,
}: {
  readonly className?: string
  readonly width?: number
  readonly height?: number
  readonly fill?: string
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 0C5.38327 0 0 5.38327 0 12C0 18.6167 5.38327 24 12 24C18.6167 24 24 18.6167 24 12C24 5.38327 18.6167 0 12 0ZM15.9981 5.54827C16.8251 5.54941 17.6332 5.79569 18.3203 6.25598C19.0074 6.71628 19.5426 7.36992 19.8583 8.13431C20.174 8.8987 20.256 9.73951 20.0939 10.5505C19.9319 11.3615 19.5331 12.1062 18.9479 12.6906C18.3627 13.275 17.6174 13.6728 16.8062 13.8337C15.995 13.9946 15.1543 13.9115 14.3903 13.5947C13.6264 13.278 12.9735 12.7419 12.5141 12.0541C12.0548 11.3664 11.8096 10.558 11.8096 9.73096C11.81 9.1813 11.9186 8.6371 12.1293 8.12943C12.34 7.62176 12.6486 7.16056 13.0376 6.77216C13.4265 6.38376 13.8881 6.07577 14.3961 5.86577C14.9041 5.65578 15.4484 5.54789 15.9981 5.54827V5.54827ZM7.05 7.06904C7.72667 7.06995 8.38788 7.27144 8.95007 7.64804C9.51226 8.02465 9.95019 8.55946 10.2085 9.18488C10.4668 9.81031 10.5339 10.4983 10.4014 11.1618C10.2688 11.8254 9.94249 12.4348 9.46369 12.9129C8.98489 13.3911 8.37508 13.7166 7.71135 13.8482C7.04761 13.9799 6.35974 13.9119 5.73466 13.6527C5.10958 13.3935 4.57537 12.9549 4.19952 12.3922C3.82368 11.8295 3.62308 11.168 3.62308 10.4913C3.6233 10.0416 3.71213 9.5963 3.88449 9.18089C4.05684 8.76548 4.30935 8.38809 4.62759 8.07028C4.94582 7.75248 5.32355 7.50048 5.73919 7.32868C6.15483 7.15689 6.60025 7.06866 7.05 7.06904ZM2.67404 16.0113C4.0875 15.2446 5.89731 14.861 7.05 14.861C7.91538 14.861 9.03 15.034 10.1267 15.4494C9.39154 15.8597 8.72884 16.3881 8.16519 17.0135C7.40423 17.8788 6.9675 18.9127 6.9675 19.946V20.8113C5.0567 19.7169 3.55072 18.0342 2.67404 16.0142V16.0113ZM12 22.1538C10.8318 22.1548 9.67234 21.9532 8.57308 21.5579V19.9973C8.57308 16.9633 13.5202 15.4344 15.9981 15.4344C17.325 15.4344 19.3662 15.8746 20.9717 16.7504C20.1077 18.3807 18.8162 19.745 17.2356 20.6969C15.655 21.6489 13.8451 22.1525 12 22.1538Z"
        fill={fill}
      />
    </svg>
  )
}
