import React from 'react'
import { PanelDrawer } from 'components/Panel/Panel'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { FilteredContacts } from 'components/FilteredContacts/FilteredContacts'
import { IContactFilterRequestData } from 'api/request'
import { connect } from 'react-redux'
import { RootState as IState } from 'store/store'
import { getLatestImport } from 'store/import-label/selectors'
import { IImportLabel } from 'store/import-label/reducer'
import moment from 'moment'
import { InfoAlert } from 'components/Alert/Alert'

interface ICampaignRecipientsPreviewContainerProps {
  readonly onClose: () => void
  readonly filterByImport?: boolean
  readonly filterBySegment?: boolean
  readonly filterByFilterObj?: boolean
  readonly selectedFilterId?: number
  readonly importLabels?: string[]
  readonly contactFilterObj?: IContactFilterRequestData
  readonly hidden?: boolean
  readonly latestImport: IImportLabel | undefined
}

interface ICampaignRecipientsPreviewHeaderProps {
  readonly onClose: () => void
}

const CampaignRecipientsPreviewHeader = ({
  onClose,
}: ICampaignRecipientsPreviewHeaderProps) => (
  <div className="mt-4 ml-5 d-flex flex-column">
    <AHIcon className="mb-3 pointer" name="close" onClick={onClose} />
    <h5 className="ml-1">Recipient Preview</h5>
  </div>
)

const CampaignRecientsPreviewContainerUnconnected = ({
  onClose,
  filterByImport,
  filterBySegment,
  filterByFilterObj,
  selectedFilterId,
  importLabels,
  contactFilterObj,
  hidden,
  latestImport,
}: ICampaignRecipientsPreviewContainerProps) => {
  // Some orgs take a while to load the initial preview, but we don't know if that's the
  // case until the 1st failed fetch. Once we do, we should  persist the message shown on
  // slower fetches throughought opening and closing of this preview
  const [isSlowFilter, setSlowFilter] = React.useState(false)

  // Contact changes can take some time to sync to PG, so provide a warning to the the
  // user if there was a recent import.
  const showRecentImportWarning =
    latestImport != null &&
    moment().diff(latestImport.createdAt, 'minutes') < 30

  return hidden ? (
    <></>
  ) : (
    <PanelDrawer
      showHr={false}
      onClose={onClose}
      header={<CampaignRecipientsPreviewHeader onClose={onClose} />}
      children={
        <>
          {showRecentImportWarning && (
            <InfoAlert>
              Contact data may take up to 30 minutes to sync with Segments after
              a new import.
            </InfoAlert>
          )}
          <FilteredContacts
            showCampaignRecipientsParagraph={true}
            filterByImport={filterByImport || false}
            filterBySegment={filterBySegment || false}
            filterByFilterObj={filterByFilterObj || false}
            selectedFilterId={selectedFilterId || undefined}
            importLabels={importLabels || []}
            contactFilterObj={contactFilterObj || undefined}
            isSlowFilter={isSlowFilter}
            setSlowFilter={setSlowFilter}
          />
        </>
      }
    />
  )
}

const mapStateToProps = (state: IState) => ({
  latestImport: getLatestImport(state),
})

export const CampaignRecientsPreviewContainer = connect(mapStateToProps)(
  CampaignRecientsPreviewContainerUnconnected
)
