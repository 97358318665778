const firstChar = (text: string): string => {
  return [...text][0] || ''
}

export function nameToInitials(
  firstName?: string | null,
  lastName?: string | null
) {
  if (firstName && lastName) {
    return firstChar(firstName) + firstChar(lastName)
  }
  if (firstName && !lastName) {
    return firstChar(firstName)
  }
  return '?'
}
