import * as React from 'react'
import { Picker, EmojiData, PartialI18n } from 'emoji-mart'
import 'components/NewEmojiPicker/NewEmojiPicker.scss'
import { useTranslation } from 'react-i18next'

interface IEmojiPickerProps {
  disabled?: boolean
  onPickEmoji?: (
    emojiData: EmojiData,
    event: React.MouseEvent<HTMLElement>
  ) => void
  className?: string
  style?: React.CSSProperties
}

export const NewEmojiPicker = (props: IEmojiPickerProps) => {
  const { t } = useTranslation('emoji-mart')
  return (
    <Picker
      onClick={(emojiData: EmojiData, event: React.MouseEvent<HTMLElement>) => {
        const { disabled, onPickEmoji } = props
        if (disabled) {
          return
        }
        if (onPickEmoji) {
          onPickEmoji(emojiData, event)
        }
      }}
      title=""
      style={props.style}
      custom={[]}
      native={true}
      i18n={
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          search: t('Search'),
          clear: t('Clear'),
          notfound: t('No Emoji Found'),
          skintext: t('Choose your default skin tone'),
          categories: {
            search: t('Search Results'),
            recent: t('Frequently Used'),
            people: t('Smileys & People'),
            nature: t('Animals & Nature'),
            foods: t('Food & Drink'),
            activity: t('Activity'),
            places: t('Travel & Places'),
            objects: t('Objects'),
            symbols: t('Symbols'),
            flags: t('Flags'),
            custom: t('Custom'),
          },
          categorieslabel: t('Emoji categories'), // Accessible title for the list of categories
          skintones: {
            1: t('Default Skin Tone'),
            2: t('Light Skin Tone'),
            3: t('Medium-Light Skin Tone'),
            4: t('Medium Skin Tone'),
            5: t('Medium-Dark Skin Tone'),
            6: t('Dark Skin Tone'),
          },
          // NOTE(chdsbd): The types for the i18n prop don't match the emoji mart docs, so there are fewer allowed props than documented.
          // https://github.com/missive/emoji-mart/tree/3576091fc03979b99f59d21db7f131660edfcd9f#i18n
        } as PartialI18n
      }
    />
  )
}
