import { getType } from 'typesafe-actions'
import {
  IActions,
  resetConversationListFilterModal,
  conversationListGetFilterLabels,
  showConversationListFilterModal,
  conversationListRemoveFilter,
} from 'page/conversations-v2/ConversationList/ConversationHeader/actions'
import { SelectInputValue } from 'page/conversations-v2/ConversationList/ConversationHeader/ConversationsListFilterModal/ConversationsSelectInput/ConversationsSelectInput'
import isArray from 'lodash/isArray'
import { ConversationListFilterParams } from 'page/conversations-v2/ConversationList/Conversations/Conversations'

export type ConversationListFiltersType = {
  readonly campaign?: SelectInputValue | null
  readonly script?: SelectInputValue | null
  readonly audiences?: SelectInputValue[]
}

type ConversationListFilters = {
  readonly showFilterModal: boolean
  readonly status: ConversationListFilterFetchStatus
  readonly filters: ConversationListFiltersType
}

export enum ConversationListFilterFetchStatus {
  initial = 'initial',
  loading = 'loading',
  error = 'error',
  ok = 'ok',
}

export function noFiltersApplied(urlParams: ConversationListFilterParams) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  for (const filter of Object.keys(
    urlParams
  ) as (keyof ConversationListFilterParams)[]) {
    // status is configured via a separate menu and should be ignored.
    if (filter === 'status' || filter === 'sidebarFilter') {
      continue
    }

    const res = urlParams[filter]
    if ((isArray(res) && res.length > 0) || (!isArray(res) && res)) {
      return false
    }
  }
  return true
}

const initialState: ConversationListFilters = {
  status: ConversationListFilterFetchStatus.initial,
  showFilterModal: false,
  filters: {
    campaign: null,
    script: null,
    audiences: [],
  },
}

const conversationListFilters = (
  state: ConversationListFilters = initialState,
  action: IActions
): ConversationListFilters => {
  switch (action.type) {
    case getType(showConversationListFilterModal):
      return {
        ...state,
        showFilterModal: action.payload,
      }
    case getType(resetConversationListFilterModal):
      return initialState
    case getType(conversationListGetFilterLabels.request):
      return {
        ...state,
        status: ConversationListFilterFetchStatus.loading,
      }
    case getType(conversationListGetFilterLabels.success):
      return {
        ...state,
        status: ConversationListFilterFetchStatus.ok,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }
    case getType(conversationListGetFilterLabels.failure):
      return {
        ...state,
        status: ConversationListFilterFetchStatus.error,
      }
    case getType(conversationListRemoveFilter):
      switch (action.payload.type) {
        case 'audience': {
          return {
            ...state,
            filters: {
              ...state.filters,
              audiences: state.filters.audiences?.filter(
                audience => audience.value !== action.payload.id
              ),
            },
          }
        }
        case 'script':
        case 'campaign': {
          return {
            ...state,
            filters: { ...state.filters, [action.payload.type]: null },
          }
        }
        default: {
          return {
            ...state,
            status: ConversationListFilterFetchStatus.error,
          }
        }
      }
    default:
      return state
  }
}

export default conversationListFilters
