import { CSSTransition as ReactTransitionCSSTransition } from 'react-transition-group'
import { CSSTransitionProps } from 'react-transition-group/CSSTransition'

export const CSSTransition = (
  props: CSSTransitionProps & {
    children: React.ReactNode
    disable?: boolean
  }
) => {
  const { children, disable, ...rest } = props
  if (disable) {
    return rest.in ? <>{children}</> : null
  }
  return (
    <ReactTransitionCSSTransition {...rest}>
      {children}
    </ReactTransitionCSSTransition>
  )
}
