import * as Api from 'api'
import {
  ICounselorsResponseData,
  IEscalationRulesResponseData,
  IEscalationRuleResponseData,
  IEscalationUnderstandingsResponseData,
} from 'api/response'
import {
  createAsyncAction,
  ActionType,
  createStandardAction,
} from 'typesafe-actions'
import {
  ICounselorRequestData,
  ISaveEscalationRuleRequestData,
} from 'api/request'
import { IEscalationRules } from 'store/escalation/selectors'
import { AxiosError, CancelTokenSource } from 'typings/axios'

export enum ruleDirection {
  UP = 'UP',
  DOWN = 'DOWN',
}

export enum ruleType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

export const getCounselors = createAsyncAction(
  '@@MASCOT/ESCALATION/GET_COUNSELORS_START',
  '@@MASCOT/ESCALATION/GET_COUNSELORS_SUCCESS',
  '@@MASCOT/ESCALATION/GET_COUNSELORS_FAILURE'
)<void, ICounselorsResponseData, Api.IApiError>()

export const deleteCounselor = createAsyncAction(
  '@@MASCOT/ESCALATION/DELETE_COUNSELOR_START',
  '@@MASCOT/ESCALATION/DELETE_COUNSELOR_SUCCESS',
  '@@MASCOT/ESCALATION/DELETE_COUNSELOR_FAILURE'
)<void, { id: number }, Api.IApiError>()

export const createCounselor = createAsyncAction(
  '@@MASCOT/ESCALATION/CREATE_COUNSELOR_START',
  '@@MASCOT/ESCALATION/CREATE_COUNSELOR_SUCCESS',
  '@@MASCOT/ESCALATION/CREATE_COUNSELOR_FAILURE'
)<ICounselorRequestData, ICounselorsResponseData, AxiosError<string[]>>()

export const updateCounselor = createAsyncAction(
  '@@MASCOT/ESCALATION/UPDATE_COUNSELOR_START',
  '@@MASCOT/ESCALATION/UPDATE_COUNSELOR_SUCCESS',
  '@@MASCOT/ESCALATION/UPDATE_COUNSELOR_FAILURE'
)<
  { counselor: ICounselorRequestData; id: number },
  ICounselorsResponseData,
  AxiosError<string[]>
>()

export const getRules = createAsyncAction(
  '@@MASCOT/ESCALATION/GET_RULES_START',
  '@@MASCOT/ESCALATION/GET_RULES_SUCCESS',
  '@@MASCOT/ESCALATION/GET_RULES_FAILURE'
)<void, IEscalationRulesResponseData, Api.IApiError>()

export const saveRule = createAsyncAction(
  '@@MASCOT/ESCALATION/SAVE_RULE_START',
  '@@MASCOT/ESCALATION/SAVE_RULE_SUCCESS',
  '@@MASCOT/ESCALATION/SAVE_RULE_FAILURE'
)<
  ISaveEscalationRuleRequestData,
  IEscalationRulesResponseData,
  { error: Api.IApiError; rule: ISaveEscalationRuleRequestData }
>()

export const getEscalationUnderstandings = createAsyncAction(
  '@@MASCOT/ESCALATION/GET_ESC_UNDERSTANDINGS_START',
  '@@MASCOT/ESCALATION/GET_ESC_UNDERSTANDINGS_SUCCESS',
  '@@MASCOT/ESCALATION/GET_ESC_UNDERSTANDINGS_FAILURE'
)<void, IEscalationUnderstandingsResponseData, Api.IApiError>()

export const prioritizeRules = createAsyncAction(
  '@@MASCOT/ESCALATION/PRIORITIZE_RULES_START',
  '@@MASCOT/ESCALATION/PRIORITIZE_RULES_SUCCESS',
  '@@MASCOT/ESCALATION/PRIORITIZE_RULES_FAILURE'
)<CancelTokenSource, IEscalationRuleResponseData[], Api.IApiError>()

export const updateRules = createStandardAction('@@ESCALATION/UPDATE_RULES')<{
  type: 'autoRules' | 'manualRules'
  rules: IEscalationRules
}>()

export const deleteRule = createAsyncAction(
  '@@ESCALATION/DELETE_RULE_START',
  '@@ESCALATION/DELETE_RULE_SUCCESS',
  '@@ESCALATION/DELETE_RULE_FAILURE'
)<void, { id: number }, Api.IApiError>()

export type IEscalationActions =
  | ActionType<typeof getCounselors>
  | ActionType<typeof deleteCounselor>
  | ActionType<typeof getRules>
  | ActionType<typeof createCounselor>
  | ActionType<typeof updateCounselor>
  | ActionType<typeof saveRule>
  | ActionType<typeof prioritizeRules>
  | ActionType<typeof updateRules>
  | ActionType<typeof deleteRule>
  | ActionType<typeof getEscalationUnderstandings>
