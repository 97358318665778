// eslint-disable-next-line no-restricted-imports
import { action, createAsyncAction, ActionType } from 'typesafe-actions'
import * as ACTION_TYPES from 'store/triage/profile/actionTypes'
import { IApiError } from 'api'
import { ILimitedInstitution, IUserResponseData } from 'api/response'
import { Http2ErrorUnion } from 'api/http'

// TODO(sbdchd): use createAsyncAction or createStandardAction
export const switchCurrentInstitutionStart = (institution: string) =>
  action(ACTION_TYPES.SWITCH_CURRENT_INSTITUTION_START, institution)
export const switchCurrentInstitutionFail = (
  prevInstitution: string,
  error: IApiError
) =>
  action(ACTION_TYPES.SWITCH_CURRENT_INSTITUTION_FAIL, {
    prevInstitution,
    error,
  })
export const switchCurrentInstitutionSuccess = (
  newInstitution: ILimitedInstitution
) => action(ACTION_TYPES.SWITCH_CURRENT_INSTITUTION_SUCCESS, newInstitution)

export const fetchUserProfileStart = () =>
  action(ACTION_TYPES.FETCH_USER_PROFILE_START)
export const fetchUserProfileSuccess = (user: IUserResponseData) =>
  action(ACTION_TYPES.FETCH_USER_PROFILE_SUCCESS, user)
export const fetchUserProfileFail = (error: IApiError | Http2ErrorUnion) =>
  action(ACTION_TYPES.FETCH_USER_PROFILE_FAIL, error)
export const updateUserNameSuccess = (
  profile: Pick<IUserResponseData, 'profile'>
) => action(ACTION_TYPES.UPDATE_USER_NAME_SUCCESS, profile)

export const fetchUsersForOrg = createAsyncAction(
  '@@MASCOT/FETCH_USERS_FOR_ORG_START',
  '@@MASCOT/FETCH_USERS_FOR_ORG_SUCCESS',
  '@@MASCOT/FETCH_USERS_FOR_ORG_FAILURE'
)<undefined, IUserResponseData[], undefined>()

export type IProfileActions =
  | ReturnType<typeof switchCurrentInstitutionStart>
  | ReturnType<typeof switchCurrentInstitutionFail>
  | ReturnType<typeof switchCurrentInstitutionSuccess>
  | ReturnType<typeof fetchUserProfileStart>
  | ReturnType<typeof fetchUserProfileFail>
  | ReturnType<typeof fetchUserProfileSuccess>
  | ReturnType<typeof updateUserNameSuccess>
  | ActionType<typeof fetchUsersForOrg>
