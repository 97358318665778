import * as ReactDOM from 'react-dom'
import { store } from 'store/store'
import { Provider } from 'react-redux'
import { IWebBotToken } from 'store/embed/api'
import { initFontLibrary } from 'util/icons'
import ActivationButton from 'embed/components/ActivationButton'
import WebChatIFrameOuter from 'embed/WebChatIFrameOuter'

import 'scss/index.scss'
import 'embed/index.scss'

initFontLibrary()

interface IWindow extends Window {
  readonly admitHubBot?: IWebBotToken
}

// eslint-disable-next-line init-declarations
declare var window: IWindow

if (document.body == null) {
  throw Error(
    "Mainstay Embed: Initialization failed. Is the embed located at the bottom of the '<body>' and NOT in the '<head>'?"
  )
}

if (window.admitHubBot == null || !window.admitHubBot.botToken) {
  throw Error(
    "Mainstay Embed: Initialization failed. Is the 'window.admithubBot' token defined?"
  )
}

const container = document.createElement('div')
document.body.appendChild(container)

// Get the domain that the current webchat script is hosted on. We can't use
// the host config var here, since the prod bundle builds with staging config
// vars, and webchat.js is a static bundle, so it doesn't update at runtime.
const relativePath = '/static/js/webchat.js'
const scriptTag = document.querySelector(`script[src$="${relativePath}"]`)
const scriptSrc =
  scriptTag && scriptTag.getAttribute ? scriptTag.getAttribute('src') : ''
const scriptDomain = scriptSrc
  ? scriptSrc.replace(relativePath, '').replace('/n', '')
  : ''

ReactDOM.render(
  <Provider store={store}>
    <div
      className="_admithub_embed-container"
      role="complementary"
      aria-label="Web Chat">
      <ActivationButton scriptDomain={scriptDomain}>
        <WebChatIFrameOuter
          botToken={window.admitHubBot.botToken}
          collegeId={window.admitHubBot.collegeId}
          scriptDomain={scriptDomain}
        />
      </ActivationButton>
    </div>
  </Provider>,
  container
)
