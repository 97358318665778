import * as React from 'react'
import { Tag } from 'components/Tag/Tag'
import Tooltip from 'components/Tooltip/Tooltip'

interface ILabelPopover {
  labels: string[]
  popoverId?: string
  maxWidth?: number
}

export const LabelPopover: React.FC<ILabelPopover> = props => {
  const labels = props.labels
  return labels.length ? (
    <Tooltip
      interactive
      content={
        // don't trigger onClick for parent as that causes navigation in some places
        <div onClick={e => e.preventDefault()}>
          <p className="text-left text-muted mb-0">Labels</p>
          <ul className="list-unstyled">
            {labels.map(x => (
              <li key={x} className="mt-1">
                <Tag className="w-100 d-block" value={x} />
              </li>
            ))}
          </ul>
        </div>
      }>
      <span className="d-flex justify-between w-100">
        <Tag
          className="w-75 text-truncate"
          value={labels[0]}
          style={{ maxWidth: props.maxWidth }}
        />{' '}
        <span className="text-underline pointer text-muted ml-2">
          {labels.length > 1 && `+${labels.length - 1}`}
        </span>
      </span>
    </Tooltip>
  ) : null
}
