import { RootState as IState } from 'store/store'
import { createSelector } from 'reselect'
import { isSuccess, unWrap } from 'store/webdata'

export const shouldShowOrgToggle = createSelector(
  (state: IState) => state.institutions.institutions,
  institutions => {
    return isSuccess(institutions) && unWrap(institutions).length > 1
  }
)
