import {
  createAsyncAction,
  ActionType,
  createStandardAction,
} from 'typesafe-actions'
import { IApiError } from 'api'
import {
  IConversationResponseData,
  IContactResponseData,
  IMessageResponseData,
} from 'api/response'
import { push } from 'connected-react-router'
import * as queryString from 'query-string'
import { IUserState } from 'store/triage/profile/reducer'

export const fixMessage = (messageId: string, conversationId: string) =>
  push({
    pathname: `/triage/conversation/${conversationId}/knowledge-base`,
    search: queryString.stringify({ messageId }),
  })

export const openConversation = (conversationId: string) =>
  push({
    pathname: `/triage/conversation/${conversationId}`,
  })

export const selectMessage = (messageId: string) =>
  push({
    search: queryString.stringify({ messageId }),
  })

export const getMessagesInConversation = createAsyncAction(
  '@@triage/chat/GET_MESSAGES_IN_CONVERSATION_START',
  '@@triage/chat/GET_MESSAGES_IN_CONVERSATION_SUCCESS',
  '@@triage/chat/GET_MESSAGES_IN_CONVERSATION_FAIL'
)<
  IContactResponseData['id'],
  { contactId: IContactResponseData['id']; data: IConversationResponseData },
  { contactId: IContactResponseData['id']; err: IApiError }
>()

export const approveMessage = createAsyncAction(
  '@@triage/chat/APPROVE_MESSAGE_START',
  '@@triage/chat/APPROVE_MESSAGE_SUCCESS',
  '@@triage/chat/APPROVE_MESSAGE_FAIL'
)<
  IMessageResponseData['id'],
  { id: IMessageResponseData['id']; user: IUserState },
  { id: IMessageResponseData['id']; err: IApiError }
>()

export const undoAnswer = createAsyncAction(
  '@@triage/chat/UNDO_ANSWER_START',
  '@@triage/chat/UNDO_ANSWER_SUCCESS',
  '@@triage/chat/UNDO_ANSWER_FAIL'
)<
  IMessageResponseData['id'],
  { id: IMessageResponseData['id']; user: IUserState },
  { id: IMessageResponseData['id']; err: IApiError }
>()

export const ignoreAnswer = createAsyncAction(
  '@@triage/chat/IGNORE_ANSWER_START',
  '@@triage/chat/IGNORE_ANSWER_SUCCESS',
  '@@triage/chat/IGNORE_ANSWER_FAIL'
)<
  IMessageResponseData['id'],
  { id: IMessageResponseData['id']; user: IUserState },
  { id: IMessageResponseData['id']; err: IApiError }
>()

export const forwardToStaff = createAsyncAction(
  '@@triage/chat/FORWARD_TO_STAFF_START',
  '@@triage/chat/FORWARD_TO_STAFF_FAIL',
  '@@triage/chat/FORWARD_TO_STAFF_SUCCESS'
)<
  IMessageResponseData['id'],
  { id: IMessageResponseData['id']; user: IUserState },
  { id: IMessageResponseData['id']; err: IApiError }
>()
export const updatePinnedConversations = createStandardAction(
  '@@triage/profile/update_pinned_conversations'
)<{ conversationId: string; pinned: boolean }>()

export type IMessagesActionTypes =
  | ActionType<typeof getMessagesInConversation>
  | ActionType<typeof approveMessage>
  | ActionType<typeof undoAnswer>
  | ActionType<typeof ignoreAnswer>
  | ActionType<typeof forwardToStaff>
  | ActionType<typeof updatePinnedConversations>
