import { ActionType, createStandardAction } from 'typesafe-actions'
import {
  ActionConfirmationModalStatus,
  ActionConfirmationModalAction,
} from 'page/conversations-v2/ConversationList/ConversationHeader/ActionsConfirmationModal/reducer'

export const showActionConfirmationModal = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_ACTION_CONFIRMATION_MODAL_SHOW'
)<{ readonly show: boolean; readonly action: ActionConfirmationModalAction }>()
export const setActionConfirmationModalStatus = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_ACTION_CONFIRMATION_MODAL_STATUS'
)<ActionConfirmationModalStatus>()
export const resetActionConfirmationModalState = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_ACTION_CONFIRMATION_MODAL_RESET_STATE'
)()

export type IActions =
  | ActionType<typeof showActionConfirmationModal>
  | ActionType<typeof setActionConfirmationModalStatus>
  | ActionType<typeof resetActionConfirmationModalState>
