import classnames from 'classnames'
import { VALID_URL_REGEX } from 'components/AttributeDrawer/AttributeFormValidationSchema'
import { ContactAttributeValueInputField } from 'components/ContactPanel/ContactAttributeValueInputField'
import { IContactFormData } from 'components/ContactPanel/EditableContactPanel'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { DYNAMIC_DATE_REGEX } from 'components/ReadOnlyDatePicker/dynamicDateUtils'
import { Field, FieldProps } from 'formik'
import orderBy from 'lodash/orderBy'
import React from 'react'
import {
  ContactAttributeType,
  IContactAttribute,
  IContactAttributes,
  IContactAttributeValues,
  IContactField,
  isMessagingStatusField,
  MessagingStatusEnum,
} from 'store/personalization/contactAttributes/selectors'
import { TransportEnum } from 'store/transport'
import * as nope from 'yup'

export const getValidatorFromAttribute = (
  attribute: IContactField,
  allowPartialMatching: boolean = false,
  inList: boolean = false
) => {
  // For partial matches, treat EMAIL and URL Validation as TEXT
  const validationType =
    allowPartialMatching &&
    [ContactAttributeType.EMAIL, ContactAttributeType.URL].includes(
      attribute.type
    )
      ? ContactAttributeType.TEXT
      : attribute.type

  switch (validationType) {
    case ContactAttributeType.DATE:
      return allowPartialMatching
        ? nope.string().matches(DYNAMIC_DATE_REGEX)
        : nope.date().typeError('Please select a valid date')
    case ContactAttributeType.EMAIL:
      return nope
        .string()
        .email('Must enter a valid email')
        .max(1000, 'Values must be less than 1000 characters long')
    case ContactAttributeType.NUMBER:
      return nope
        .number()
        .typeError('Please use digits only')
        .max(10e29, 'Values must be less than 30 digits long')
    case ContactAttributeType.PHONE:
      nope
        .string()
        .matches(
          allowPartialMatching ? /^\d{3,10}$/ : /^[2-9][0-9]{9}$/,
          allowPartialMatching
            ? 'Value must contain 3 to 10 digits, with no dashes or special characters.\n'
            : 'Phone number must contain exactly 10 digits, with no dashes or special characters.\nOnly US numbers are supported at this time.\nExample: 5552551234'
        )
    case ContactAttributeType.TEXT:
      return inList
        ? nope
            .string()
            .max(1000, 'Values must be less than 1000 characters long')
            .matches(
              /^(?!,).*[^,]$/,
              'Must be a comma separated list with no leading or trailing commas'
            )
        : nope
            .string()
            .max(1000, 'Values must be less than 1000 characters long')
    case ContactAttributeType.URL:
      return nope
        .string()
        .matches(
          VALID_URL_REGEX,
          'Must be a valid URL, including a protocol (ex: https://) and domain (ex: example.com)'
        )
        .max(1000, 'Values must be less than 1000 characters long')
    case ContactAttributeType.BOOLEAN:
      return nope.boolean().notRequired()
    case ContactAttributeType.MULTI_CHOICE:
      return attribute.field === 'transport'
        ? nope.string().oneOf(Object.values(TransportEnum))
        : isMessagingStatusField(attribute)
        ? nope.string().oneOf(Object.values(MessagingStatusEnum))
        : nope
            .string()
            .oneOf(attribute.options?.map(option => option.value) ?? [])
    case ContactAttributeType.JSON_STRING_ARRAY:
      return nope
        .string()
        .max(1000, 'Values must be less than 1000 characters long')
  }
}

export const mapContactAttrToFormValues = (
  contactAttributeValues?: IContactAttributeValues
) =>
  (contactAttributeValues ?? []).reduce(
    (acc, attributeValue) => ({
      ...acc,
      [attributeValue.contactAttribute]: {
        name: attributeValue.attributeName,
        value: attributeValue.value,
      },
    }),
    {}
  )

export const createContactAttributeValueSchema = (
  contactAttributes: IContactAttributes
) =>
  nope.object().shape({
    ...contactAttributes.reduce((acc, attribute) => {
      return {
        ...acc,
        [attribute.id]: nope
          .object()
          .shape({
            value: getValidatorFromAttribute(attribute).notRequired(),
          })
          .notRequired(),
      }
    }, {}),
  })

interface IContactAttributeValueFieldRowProps {
  attribute: IContactAttribute
}
const ContactAttributeValueFieldRow = ({
  attribute,
}: IContactAttributeValueFieldRowProps) => {
  const fieldName = `attributeValues.${attribute.id}`
  return (
    <Field
      name={fieldName}
      render={(fieldProps: FieldProps<IContactFormData>) => {
        const { field, form } = fieldProps

        /* eslint-disable @typescript-eslint/consistent-type-assertions */
        // Yup validation schema doesn't properly type nested errors
        // for that reason, we extract those errors from FieldProps and type them on their own here
        const formErrors = (form.errors as unknown) as {
          attributeValues?: {
            [key: number]: {
              value: string
            }
          }
        }

        const touched = (((form.touched.attributeValues as unknown) as Array<
          boolean
        >) ?? [])[attribute.id]
        /* eslint-enable @typescript-eslint/consistent-type-assertions */

        const error = touched
          ? formErrors.attributeValues?.[attribute.id]?.value
          : undefined

        return (
          <div className="d-flex align-items-center pb-2">
            <div
              className={classnames('col col-5 p-0', {
                'text-danger': !!error,
              })}>
              <p className="mb-0 fw-600 text-ellipsis"> {attribute.field} </p>
            </div>
            <div className="col col-7 px-1">
              <ContactAttributeValueInputField
                attribute={attribute}
                fieldProps={fieldProps}
                error={error}
              />
            </div>
            <div className="col col-1 p-0 hover-text-danger">
              {field.value != null && (
                <AHIcon
                  name="remove_circle_outline"
                  className="pointer"
                  onClick={() => {
                    form.setFieldValue(fieldName, undefined)
                  }}
                />
              )}
            </div>
          </div>
        )
      }}
    />
  )
}

export interface IContactAttributeValuesFormData {
  [key: number]: {
    name: string
    value: string
  }
}

interface IContactAttributeValuesFormProps {
  contactAttributes: IContactAttributes
  formValues?: IContactAttributeValuesFormData
}

export const ContactAttributeValuesForm = ({
  contactAttributes,
  formValues = {},
}: IContactAttributeValuesFormProps) => {
  const [showEmptyAttributes, setShowEmptyAttributes] = React.useState(false)

  const visibleAttributes = showEmptyAttributes
    ? contactAttributes
    : contactAttributes.filter(attr => attr.id in formValues)
  const emptyFieldsCount =
    contactAttributes.length - Object.keys(formValues).length

  return (
    <>
      {orderBy(
        visibleAttributes,
        [attr => attr.field.toLowerCase()],
        ['asc']
      ).map(attribute => (
        <ContactAttributeValueFieldRow
          key={attribute.id}
          attribute={attribute}
        />
      ))}
      <div
        className="d-flex mt-2 pointer small align-items-center"
        onClick={() => setShowEmptyAttributes(!showEmptyAttributes)}>
        <AHIcon name={showEmptyAttributes ? 'expand_less' : 'expand_more'} />
        {showEmptyAttributes ? 'Hide' : 'Show'} {emptyFieldsCount} empty fields
      </div>
    </>
  )
}
