import { getType } from 'typesafe-actions'
import _ from 'lodash'
import { IContactLabel } from 'store/contacts/reducer'
import {
  IContactLabelActions,
  searchContactLabels,
  updateContactLabel,
  deleteContactLabel,
  updateSearchQuery,
  updateOrdering,
  updatePageNumber,
} from 'store/contact-labels/actions'

export interface IContactLabelState {
  allIds: string[]
  byId: { [id: string]: IContactLabel | undefined }
  totalCount: number
  ui: {
    search: string
    ordering: 'createdAt' | '-createdAt' | 'text' | '-text'
    pageNumber: number
    loadingList: boolean
    updatingLabel: boolean
    deletingLabel: boolean
  }
}

const initialState: IContactLabelState = {
  allIds: [],
  byId: {},
  totalCount: 0,
  ui: {
    search: '',
    ordering: 'text',
    pageNumber: 1,
    loadingList: false,
    updatingLabel: false,
    deletingLabel: false,
  },
}

const reducer = (
  state: IContactLabelState = initialState,
  action: IContactLabelActions
) => {
  switch (action.type) {
    case getType(searchContactLabels.request): {
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingList: true,
        },
      }
    }
    case getType(searchContactLabels.success): {
      return {
        ...state,
        totalCount: action.payload.count,
        byId: _.keyBy(action.payload.results, 'id'),
        allIds: action.payload.results.map(x => x.id),
        ui: {
          ...state.ui,
          loadingList: false,
        },
      }
    }
    case getType(searchContactLabels.failure): {
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingList: false,
        },
      }
    }
    case getType(updateContactLabel.request): {
      return {
        ...state,
        ui: {
          ...state.ui,
          updatingLabel: true,
        },
      }
    }
    case getType(updateContactLabel.success): {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
        ui: {
          ...state.ui,
          updatingLabel: false,
        },
      }
    }
    case getType(updateContactLabel.failure): {
      return {
        ...state,
        ui: {
          ...state.ui,
          updatingLabel: false,
        },
      }
    }
    case getType(deleteContactLabel.request): {
      return {
        ...state,
        ui: {
          ...state.ui,
          deletingLabel: false,
        },
      }
    }
    case getType(deleteContactLabel.success): {
      const baseObj: IContactLabelState['byId'] = {}
      return {
        ...state,
        allIds: state.allIds.filter(x => x !== action.payload),
        byId: state.allIds.reduce((acc, curr) => {
          if (curr !== action.payload) {
            acc[curr] = state.byId[curr]
          }
          return acc
        }, baseObj),
        ui: {
          ...state.ui,
          deletingLabel: false,
        },
      }
    }
    case getType(deleteContactLabel.failure): {
      return {
        ...state,
        ui: {
          ...state.ui,
          deletingLabel: false,
        },
      }
    }
    case getType(updateSearchQuery): {
      return {
        ...state,
        ui: {
          ...state.ui,
          search: action.payload,
          pageNumber: 1,
        },
      }
    }
    case getType(updateOrdering): {
      return {
        ...state,
        ui: {
          ...state.ui,
          ordering: action.payload,
          pageNumber: 1,
        },
      }
    }
    case getType(updatePageNumber): {
      return {
        ...state,
        ui: {
          ...state.ui,
          pageNumber: action.payload,
        },
      }
    }
    default:
      return state
  }
}

export default reducer
