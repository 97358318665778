import { assertNever } from 'util/exhaustiveness'

// NOTE(sbdchd): we use an object instead of an enum so we can derive a union of
// literals. Deriving the union from an enum is only possible in TypeScript 4.1+
export const TransportEnum = {
  web: 'web',
  twilio: 'twilio',
  facebook: 'facebook',
  slack: 'slack',
} as const

export const Transports = [
  TransportEnum.web,
  TransportEnum.twilio,
  TransportEnum.facebook,
  TransportEnum.slack,
] as const

export type TransportId = typeof Transports[number]

export function transportToChannel(
  transport: TransportId | 'unknown'
): Channel | 'unknown' {
  switch (transport) {
    case 'facebook':
      return 'facebook'
    case 'twilio':
      return 'sms'
    case 'web':
      return 'web_bot'
    case 'slack':
      return 'slack'
    case 'unknown':
      return 'unknown'
    default:
      return assertNever(transport)
  }
}
export function channelToTransport(channel: Channel): TransportId {
  switch (channel) {
    case 'facebook':
      return 'facebook'
    case 'sms':
      return 'twilio'
    case 'web_bot':
      return 'web'
    case 'slack':
      return 'slack'
    default:
      return assertNever(channel)
  }
}

export const ChannelEnum = {
  web_bot: 'web_bot',
  sms: 'sms',
  facebook: 'facebook',
  slack: 'slack',
} as const

export type Channel = keyof typeof ChannelEnum

// Order in which the channel tabs should be displayed in the conversations view
export const CHANNEL_TAB_ORDERING: Channel[] = [
  'sms',
  'web_bot',
  'slack',
  'facebook',
]

export const isChannel = (maybeChannel: string) =>
  !!CHANNEL_TAB_ORDERING.filter(channel => channel === maybeChannel).length
