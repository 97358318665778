import {
  IInstitutionAttributes,
  InstitutionAttributeType,
  IInstitutionAttribute,
  IAttributeTypeCounter,
} from 'store/personalization/institutionAttributes/selectors'
import {
  IPersonalizationActions,
  getInstitutionAttributesTable as getInstitutionAttributesTableAction,
  getAllInstitutionAttributes as getAllInstitutionAttributesAction,
  getInstitutionAttrsCountByType,
  getInstitutionAttrsCount,
} from 'store/personalization/institutionAttributes/actions'
import { getType } from 'typesafe-actions'
import { IInstitutionAttributeResponseData } from 'api/response'
import {
  WebData,
  Loading,
  Success,
  Failure,
  Refetching,
  isSuccess,
  isSuccessOrRefetching,
} from 'store/webdata'
import * as ACTION_TYPES from 'store/triage/profile/actionTypes'
import { IProfileActions } from 'store/triage/profile/actions'

interface IPersonalizationState {
  institutionAttributesTable: WebData<IInstitutionAttributes>
  allInstitutionAttributes: WebData<IInstitutionAttributes>
  ui: {
    pageNumber: number
  }
  countInstitutionAttributes: number
  countInstitutionAttributesByType: IAttributeTypeCounter
}

const INITIAL_PERSONALIZATION_STATE: IPersonalizationState = {
  institutionAttributesTable: undefined,
  allInstitutionAttributes: undefined,
  ui: {
    pageNumber: 1,
  },
  countInstitutionAttributes: 0,
  countInstitutionAttributesByType: {
    NUMBER: 0,
    TEXT: 0,
    PHONE: 0,
    EMAIL: 0,
    URL: 0,
    DATE: 0,
  },
}

const mapResponseDataToAttributes = (
  data: Array<IInstitutionAttributeResponseData>
): IInstitutionAttributes => {
  return data.map(
    (elem: IInstitutionAttributeResponseData): IInstitutionAttribute => ({
      id: elem.id,
      field: elem.name,
      /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
      type: elem.data_type as InstitutionAttributeType,
      value: elem.value,
      locationsUsed:
        (elem?.understandings_count ?? 0) + (elem?.scripts_count ?? 0),
    })
  )
}

const reducer = (
  state: IPersonalizationState = INITIAL_PERSONALIZATION_STATE,
  action: IPersonalizationActions | IProfileActions
): IPersonalizationState => {
  switch (action.type) {
    case getType(getInstitutionAttributesTableAction.request): {
      return {
        ...state,
        institutionAttributesTable: isSuccess(state.institutionAttributesTable)
          ? Refetching(state.institutionAttributesTable.data)
          : Loading(),
      }
    }
    case getType(getInstitutionAttributesTableAction.success): {
      const [attributeResponseData, shouldAppendResults] = action.payload
      return {
        ...state,
        institutionAttributesTable: Success([
          ...(shouldAppendResults &&
          isSuccessOrRefetching(state.institutionAttributesTable)
            ? state.institutionAttributesTable.data
            : []),
          ...mapResponseDataToAttributes(attributeResponseData),
        ]),
      }
    }
    case getType(getInstitutionAttributesTableAction.failure): {
      return {
        ...state,
        institutionAttributesTable: Failure('Failed to fetch data'),
      }
    }
    case getType(getAllInstitutionAttributesAction.request): {
      return {
        ...state,
        allInstitutionAttributes: isSuccess(state.allInstitutionAttributes)
          ? Refetching(state.allInstitutionAttributes.data)
          : Loading(),
      }
    }
    case getType(getAllInstitutionAttributesAction.success): {
      const attributeResponseData = action.payload
      return {
        ...state,
        allInstitutionAttributes: Success(
          mapResponseDataToAttributes(attributeResponseData)
        ),
      }
    }
    case getType(getAllInstitutionAttributesAction.failure): {
      return {
        ...state,
        allInstitutionAttributes: Failure('Failed to fetch data'),
      }
    }
    case getType(getInstitutionAttrsCount.success): {
      return {
        ...state,
        countInstitutionAttributes: action.payload,
      }
    }
    case getType(getInstitutionAttrsCountByType.success): {
      return {
        ...state,
        countInstitutionAttributesByType: action.payload,
      }
    }
    case ACTION_TYPES.SWITCH_CURRENT_INSTITUTION_SUCCESS: {
      return INITIAL_PERSONALIZATION_STATE
    }
    default: {
      return state
    }
  }
}

export default reducer
