import * as Api from 'api'
import { createAsyncAction, ActionType } from 'typesafe-actions'
import * as ACTION_TYPES from 'store/triage/chat/exchangesActionTypes'
import { IInboxResponseData } from 'api/response'

export const getInbox = createAsyncAction(
  ACTION_TYPES.GET_INBOX_MESSAGES_START,
  ACTION_TYPES.GET_INBOX_MESSAGES_SUCCESS,
  ACTION_TYPES.GET_INBOX_MESSAGES_FAIL
)<void, IInboxResponseData, Api.IApiError>()

export const pollInbox = createAsyncAction(
  '@@MASCOT/TRIAGE/POLL_INBOX_START',
  '@@MASCOT/TRIAGE/POLL_INBOX_SUCCESS',
  '@@MASCOT/TRIAGE/POLL_INBOX_FAILURE'
)<void, IInboxResponseData, Api.IApiError>()

export type IExchangesActions =
  | ActionType<typeof getInbox>
  | ActionType<typeof pollInbox>
