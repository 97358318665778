export function InfoIcon({
  fill = '#F2F4F7',
  className,
}: {
  readonly fill?: string
  readonly className?: string
}) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.016 7.00011V4.98411H10.985V7.00011H9.016ZM10 18.0161C14.406 18.0161 18.016 14.4071 18.016 10.0001C18.016 5.59311 14.407 1.98411 10 1.98411C5.593 1.98411 1.984 5.59311 1.984 10.0001C1.984 14.4071 5.593 18.0161 10 18.0161ZM10 0.0161133C15.531 0.0161133 19.984 4.46911 19.984 10.0001C19.984 15.5311 15.531 19.9841 10 19.9841C4.469 19.9841 0.0159988 15.5311 0.0159988 10.0001C0.0159988 4.46911 4.469 0.0161133 10 0.0161133ZM9.016 15.0161V9.01611H10.985V15.0161H9.016Z"
        fill={fill}
      />
    </svg>
  )
}
