export default function Loader({
  size,
  centeredSpinner,
}: {
  readonly size?: 'small' | 'md' | 'big'
  readonly centeredSpinner?: boolean
}) {
  if (centeredSpinner) {
    return <CenteredLoader size={size} />
  }

  return (
    <div className={`ring-spinner${size ? ` ring-spinner-${size}` : ''}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

type ICenteredLoaderProps = {
  readonly className?: string
  readonly size?: 'small' | 'md' | 'big'
}

export function CenteredLoader({ className, size }: ICenteredLoaderProps) {
  const cls = className || ''
  return (
    <div className={`d-flex align-items-center justify-content-center ${cls}`}>
      <Loader size={size} />
    </div>
  )
}
