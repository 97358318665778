import {
  ActionType,
  createStandardAction,
  getType,
  createAsyncAction,
} from 'typesafe-actions'

export interface IInstitutionSettingsReducerState {
  readonly initial: string | null | undefined | number | boolean
  readonly changed: string | null | undefined | number | boolean
  readonly updateStatus: 'initial' | 'loading' | 'success' | 'failure'
}

export const updateFromProps = createStandardAction(
  '@@mascot/settings/general/institution/updateFromProps'
)<string | null | number | boolean | undefined>()

export const handleInputChange = createStandardAction(
  '@@mascot/settings/general/institution/handleInputChange'
)<string | boolean | number>()

export const saveField = createAsyncAction(
  '@@mascot/settings/general/institution/saveField/request',
  '@@mascot/settings/general/institution/saveField/succces',
  '@@mascot/settings/general/institution/saveField/failure'
)<void, string | number | boolean | null | undefined, void>()

export const cancelChanges = createStandardAction(
  '@@mascot/settings/general/institution/cancelChanges'
)()

type SettingsActions =
  | ActionType<typeof updateFromProps>
  | ActionType<typeof handleInputChange>
  | ActionType<typeof saveField>
  | ActionType<typeof cancelChanges>

export function institutionSettingsUpdateReducer(
  state: IInstitutionSettingsReducerState,
  action: SettingsActions
): IInstitutionSettingsReducerState {
  switch (action.type) {
    case getType(updateFromProps):
      return {
        ...state,
        initial: action.payload,
        changed: action.payload,
        updateStatus: 'initial',
      }
    case getType(handleInputChange):
      return { ...state, changed: action.payload }
    case getType(saveField.request):
      return { ...state, updateStatus: 'loading' }
    case getType(saveField.success):
      return {
        ...state,
        updateStatus: 'success',
        initial: action.payload,
        changed: action.payload,
      }
    case getType(saveField.failure):
      return {
        ...state,
        changed: state.initial,
        updateStatus: 'failure',
      }
    case getType(cancelChanges):
      return {
        ...state,
        changed: state.initial,
      }
    default:
      return state
  }
}
