import * as Api from 'api'
import { IContactFilterRequestData } from 'api/request'
import axios from 'axios'
import classNames from 'classnames'
import { Button } from 'components/Button/Button'
import 'components/ContactList/ContactList.scss'
import {
  getFirstQueryParam,
  SortableContactList,
} from 'components/ContactList/SortableContactList'
import ContactPanel from 'components/ContactPanel/ContactPanel'
import { IContactFormData } from 'components/ContactPanel/EditableContactPanel'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import UnarchiveIcon from 'components/Icons/UnarchiveIcon/UnarchiveIcon'
import { ImportReportNavbarPage } from 'components/ImportReportNavbarPage/ImportReportNavbarPage'
import { ConfirmationModal } from 'components/Modal/Modal'
import {
  ProgressBar,
  ProgressBarItem,
} from 'components/ProgressBar/ProgressBar'
import { Spinner } from 'components/Spinner/Spinner'
import { CONTACTS } from 'const/routes'
import { History } from 'history'
import { capitalize } from 'lodash'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { PageHeader } from 'components/PageHeader/PageHeader'
import pluralize from 'pluralize'
import * as React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router'
import { Link, RouteComponentProps } from 'react-router-dom'
import { clearCurrentContact } from 'store/contacts/actions'
import { IArchiveActionType, IContactCounts } from 'store/contacts/reducer'
import {
  getarchiveActionCompleted,
  getArchiveActionProgress,
  getContactCounts as getContactCountsSelector,
  getContactCountsAreLoading,
  getContactCountsisSuccessful,
} from 'store/contacts/selectors'
import {
  archiveOrUnarchiveContactsAsync,
  createContactAsync,
  getContactCountsAsync,
} from 'store/contacts/thunks'
import { Dispatch, RootState as IState } from 'store/store'
import { assertNever } from 'util/exhaustiveness'
import { useDispatch, useFeatures, useSelector } from 'util/hooks'
import { openDownloadURL } from 'util/links'
import { PERMISSIONS } from 'util/permissions/permissions'
import PermissionsGuard from 'util/permissions/PermissionsGuard'
import { getQueryFilters, getReturnLocation } from 'util/queryFilters'
import { propsDiffer } from 'util/react'
import { PanelDrawer } from 'components/Panel/Panel'
import {
  CustomDownloadModal,
  IPreferencesRequestPayload,
  generateReportFileName,
} from 'components/TrendsV2/CustomizedDownloadModal'
import { ActionMenu } from 'components/ActionMenu/ActionMenu'

type IContactPanelRouteProps = RouteComponentProps<{ id: string }>

interface IContactListOwnProps {
  createContact: (data: Partial<IContactFormData>) => void
  archiveOrUnarchiveContacts: (
    searchQuery: string,
    action: IArchiveActionType,
    source: string,
    filter?: string
  ) => Promise<void>
  archiveActionCompleted: boolean
  archiveProgress: number
  clearCurrentContact: () => void
  history: History
}

export type ContactsListRouteProps = RouteComponentProps<{}>

type ContactListLocalState = {
  selectedArchiveAction: IArchiveActionType | undefined
  isLoadingSortable: boolean
  totalCountSortable: number | undefined
  shouldResetFilters: boolean
}
class ContactList extends React.PureComponent<
  IContactListOwnProps,
  ContactListLocalState
> {
  constructor(props: IContactListOwnProps) {
    super(props)
    this.state = {
      selectedArchiveAction: undefined,
      isLoadingSortable: true,
      totalCountSortable: undefined,
      shouldResetFilters: false,
    }
  }

  componentDidUpdate(prevProps: IContactListOwnProps) {
    if (
      propsDiffer<IContactListOwnProps>(prevProps, this.props, [
        'archiveActionCompleted',
      ])
    ) {
      this.setState({ shouldResetFilters: true })
    }
  }

  handleArchiveAction = (searchQuery: string, action: IArchiveActionType) => {
    return this.props.archiveOrUnarchiveContacts(searchQuery, action, 'BULK')
  }

  handleArchiveActionSelected = (action: IArchiveActionType) => {
    this.setState({ selectedArchiveAction: action })
  }

  handleContactsLoading = (isLoading: boolean) => {
    this.setState({ isLoadingSortable: isLoading })
  }

  setTotalCount = (count: number) => {
    this.setState({ totalCountSortable: count })
  }

  handleClearFilters = () => {
    this.setState({
      shouldResetFilters: true,
    })
  }

  render() {
    return (
      <ImportReportNavbarPage title="Contacts">
        <>
          <PageHeader title="Contacts" count={this.state.totalCountSortable}>
            <div className="d-flex align-items-center">
              <ContactOverFlowMenu
                archiveOrUnarchiveContacts={this.handleArchiveAction}
                archiveActionInProgress={!!this.props.archiveProgress}
                selectedArchiveAction={this.state.selectedArchiveAction}
                handleArchiveActionSelected={this.handleArchiveActionSelected}
                isLoading={this.state.isLoadingSortable}
              />
              <PermissionsGuard
                permission={PERMISSIONS.CONTACT.CREATE}
                placement="bottom">
                <NewContactButton
                  onClick={() => {
                    this.props.clearCurrentContact()
                    this.handleClearFilters()
                  }}
                />
              </PermissionsGuard>
              <PermissionsGuard
                permission={PERMISSIONS.IMPORT.VIEW}
                placement="bottom">
                <ImportContactsButton />
              </PermissionsGuard>
            </div>
          </PageHeader>
          {this.props.archiveProgress ? (
            <ArchiveActionProgressBar
              archiveProgress={this.props.archiveProgress}
              selectedArchiveAction={this.state.selectedArchiveAction}
              className="max-width-300 pb-3 mb-3"
            />
          ) : null}
          <SortableContactList
            setContactsLoading={this.handleContactsLoading}
            setTotalCount={this.setTotalCount}
            onSelectContactNavLink={`${CONTACTS.INDEX}`}
            shouldResetFilters={this.state.shouldResetFilters}
          />
          <Route
            exact
            path={CONTACTS.CONTACT_PANEL}
            render={(props: IContactPanelRouteProps) => {
              return (
                <PanelDrawer
                  header={undefined}
                  onClose={() => this.props.history.push(CONTACTS.INDEX)}
                  width="480px"
                  panelContentClassName="overflow-y-hidden">
                  <ContactPanel
                    closeReturnLocation={getReturnLocation(CONTACTS.INDEX)}
                    className="pt-0"
                    contactId={props.match.params.id}
                    createContact={this.props.createContact}
                  />
                </PanelDrawer>
              )
            }}
          />
          <Route
            exact
            path={CONTACTS.NEW_CONTACT_PANEL}
            render={() => {
              return (
                <PanelDrawer
                  header={undefined}
                  onClose={() => this.props.history.push(CONTACTS.INDEX)}
                  width="480px"
                  panelContentClassName="overflow-y-hidden">
                  <ContactPanel
                    editOnOpen={true}
                    closeReturnLocation={getReturnLocation(CONTACTS.INDEX)}
                    className="pt-0"
                    contactId={undefined}
                    createContact={this.props.createContact}
                  />
                </PanelDrawer>
              )
            }}
          />
        </>
      </ImportReportNavbarPage>
    )
  }
}

interface INewContactButtonProps {
  onClick: () => void
}

export function NewContactButton({ onClick }: INewContactButtonProps) {
  return (
    <Link to={CONTACTS.NEW_CONTACT_PANEL}>
      <Button
        eventAction="click"
        eventObject="add contact"
        className="mr-3"
        color="secondary-teal"
        outlined={true}
        onClick={onClick}>
        Add Contact
      </Button>
    </Link>
  )
}

export const ArchiveActionProgressBar = ({
  archiveProgress,
  selectedArchiveAction,
  className,
}: {
  archiveProgress: number | undefined
  selectedArchiveAction: IArchiveActionType | undefined
  className?: string
}) => {
  if (!archiveProgress) {
    return null
  }
  return (
    <div
      className={classNames(
        'd-flex align-items-center justify-content-between',
        className
      )}>
      {!!archiveProgress && selectedArchiveAction && (
        <div className="d-flex flex-column w-100">
          <ProgressBar className="mb-1">
            <ProgressBarItem
              className="progress-bar-striped progress-bar-animated"
              value={archiveProgress}
              maxValue={1}
            />
          </ProgressBar>
          <small className="caption text-muted">
            {`${capitalize(
              selectedArchiveAction?.slice(0, selectedArchiveAction.length - 1)
            )}ing contacts...`}
          </small>
        </div>
      )}
    </div>
  )
}

export function ImportContactsButton() {
  return (
    <Link to={CONTACTS.IMPORT_PAGE}>
      <Button color="secondary-teal">Import Contacts</Button>
    </Link>
  )
}

export function useContactsDownload({
  importLabels,
  contactFilterId,
  contactFilterObj,
  useCachedAudienceData,
  onDownloadComplete,
}: {
  readonly useCachedAudienceData?: boolean
  readonly contactFilterId?: number | string | null
  readonly importLabels?: string[]
  readonly contactFilterObj?: IContactFilterRequestData
  readonly onDownloadComplete?: () => void
}) {
  const [buttonState, setButtonState] = React.useState<ContactButtonState>(
    ContactButtonState.Default
  )
  const searchQuery = getFirstQueryParam(
    getQueryFilters(window.location)['search']
  )
  const pollProgress = React.useCallback(
    (taskId: string) => {
      Api.pollDownloadContactList(taskId)
        .then(res => {
          if (res.data.status === 'SUCCESS') {
            setButtonState(ContactButtonState.Success)
            onDownloadComplete?.()
            openDownloadURL({
              url: res.data.report_url,
            })
            setTimeout(() => setButtonState(ContactButtonState.Default), 3000)
          } else {
            setTimeout(pollProgress, 2000, taskId)
          }
        })
        .catch(() => {
          toast.error('Error Downloading Contacts. Please try again later.')
          setButtonState(ContactButtonState.Default)
          onDownloadComplete?.()
        })
    },
    [onDownloadComplete]
  )

  const onDownload = React.useCallback(
    (preferences?: IPreferencesRequestPayload) => {
      setButtonState(ContactButtonState.Progress)
      toast(
        'Downloading contacts. This may take a few minutes. Please do not close this page.'
      )
      const filters = getQueryFilters(window.location)
      Api.generateContactList(
        searchQuery,
        filters,
        importLabels,
        contactFilterId,
        contactFilterObj,
        useCachedAudienceData,
        preferences
      )
        .then(r => {
          pollProgress(r.data.taskId)
        })
        .catch(() => {
          toast.error('Error Downloading Contacts. Please try again later.')
          setButtonState(ContactButtonState.Default)
          onDownloadComplete?.()
        })
    },
    [
      pollProgress,
      searchQuery,
      importLabels,
      contactFilterId,
      contactFilterObj,
      useCachedAudienceData,
      onDownloadComplete,
    ]
  )

  const getPreferenceData = React.useCallback(() => {
    const filters = getQueryFilters(window.location)
    return Api.getReportPreferences({
      insight: 'contacts_report',
      contactsParams: { searchQuery, filters, contactFilterId },
    })
  }, [searchQuery, contactFilterId])

  return {
    buttonState,
    downloadInProgress: buttonState === ContactButtonState.Progress,
    onDownload,
    getPreferenceData,
  }
}

enum ContactButtonState {
  Default = 'Download',
  Progress = 'Downloading...',
  Success = 'Downloaded',
}

interface IAudiencePreviewDownloadButtonProps {
  readonly count: number
  readonly isLoading: boolean
  readonly disabled: boolean
  readonly importLabels: string[]
  readonly contactFilterId: number | undefined | null
  readonly contactFilterObj: IContactFilterRequestData | undefined
  readonly eventLocation?: string
  readonly eventObject?: string
}

export function AudiencePreviewDownloadButton({
  count,
  isLoading,
  disabled,
  importLabels,
  contactFilterId,
  contactFilterObj,
  eventLocation,
  eventObject,
}: IAudiencePreviewDownloadButtonProps) {
  const { buttonState, onDownload } = useContactsDownload({
    importLabels,
    contactFilterId,
    contactFilterObj,
    useCachedAudienceData: false,
  })
  const pluralizedButtonString = `${buttonState} ${pluralize(
    'Contact',
    count,
    true
  )}`
  return (
    <Button
      onClick={() => onDownload()}
      color="primary"
      className="d-flex align-items-center"
      disabled={
        buttonState !== ContactButtonState.Default || isLoading || disabled
      }
      eventAction="click"
      eventObject={eventObject}
      eventLocation={eventLocation}>
      <>
        <AHIcon
          name={
            buttonState !== ContactButtonState.Success
              ? 'file_download'
              : 'file_download_done'
          }
        />
        {isLoading ? (
          <Spinner className="stroke-white" size="sm" />
        ) : (
          <span className="mr-1">{pluralizedButtonString}</span>
        )}
      </>
    </Button>
  )
}

interface IContactDownloadButtonProps {
  archiveActionInProgress?: boolean
  isLoading: boolean
  disabled?: boolean
  contactFilterId?: number
  useCachedAudienceData?: boolean
  className?: string
  eventLocation?: string
  eventObject?: string
  onOpenModal: () => void
}

export function ContactDownloadButton({
  archiveActionInProgress = false,
  isLoading,
  disabled,
  contactFilterId,
  useCachedAudienceData,
  className,
  eventLocation,
  eventObject,
  onOpenModal,
}: IContactDownloadButtonProps) {
  const { buttonState, onDownload } = useContactsDownload({
    contactFilterId,
    useCachedAudienceData,
  })

  const { FeaturesType, hasFeature } = useFeatures()
  const onClickDownloadButton = hasFeature(
    FeaturesType.MULTI_CHANNEL_CONTACTS_REPORTS
  )
    ? onOpenModal
    : onDownload

  return (
    <PermissionsGuard
      permission={PERMISSIONS.REPORT.VIEW}
      className="p-0 w-100">
      <Button
        onClick={() => onClickDownloadButton()}
        disabled={
          buttonState !== ContactButtonState.Default ||
          isLoading ||
          archiveActionInProgress ||
          disabled
        }
        className={classNames(
          'text-decoration-none btn bg-white list-group-item rounded-0 action-btn',
          className
        )}
        eventAction="click"
        eventObject={eventObject}
        eventLocation={eventLocation}>
        <>
          <AHIcon
            name={
              buttonState !== ContactButtonState.Success
                ? 'file_download'
                : 'file_download_done'
            }
          />
          {isLoading ? (
            <Spinner className="stroke-white" size="sm" />
          ) : (
            <span className="mr-1">{buttonState}</span>
          )}
        </>
      </Button>
    </PermissionsGuard>
  )
}

function useContactCounts(
  searchQuery: string,
  contactFilterId?: number
):
  | { status: 'success'; data: IContactCounts }
  | { status: 'loading' }
  | { status: 'error' } {
  const dispatch = useDispatch()
  const contactCounts = useSelector(getContactCountsSelector)
  const contactCountsLoading = useSelector(getContactCountsAreLoading)
  const success = useSelector(getContactCountsisSuccessful)
  React.useEffect(() => {
    const source = axios.CancelToken.source()
    getContactCountsAsync(dispatch)(source.token, searchQuery, contactFilterId)
    return () => {
      source.cancel()
    }
  }, [dispatch, searchQuery, contactFilterId])
  if (contactCountsLoading) {
    return { status: 'loading' }
  }
  if (success) {
    return { status: 'success', data: contactCounts }
  }
  return { status: 'error' }
}

interface IArchiveContactsModalProps {
  archiveAction: IArchiveActionType
  show: boolean
  contactFilterId?: number
  eventLocation?: string
  eventObject?: string
  onClose: () => void
  onConfirm: (searchQuery: string, action: IArchiveActionType) => void
}

export const ArchiveContactsModal = ({
  archiveAction,
  show,
  contactFilterId,
  eventLocation,
  eventObject,
  onClose,
  onConfirm,
}: IArchiveContactsModalProps) => {
  const searchQuery = getFirstQueryParam(
    getQueryFilters(window.location)['search']
  )
  const contactCounts = useContactCounts(searchQuery, contactFilterId)
  if (contactCounts.status === 'error') {
    return null
  }
  const canBeArchived =
    contactCounts.status === 'success' &&
    (archiveAction === IArchiveActionType.ARCHIVE
      ? contactCounts.data.activeNotTest > 0
      : contactCounts.data.archived > 0)
  const helpText =
    contactCounts.status === 'success' ? (
      <div>
        {archiveAction === IArchiveActionType.ARCHIVE ? (
          <>
            <span>
              <strong>
                {`${contactCounts.data.activeNotTest} active ${pluralize(
                  'contacts',
                  contactCounts.data.activeNotTest
                )} will be archived`}
              </strong>
            </span>
            {contactCounts.data.activeTest > 0 && (
              <p>
                {`(${contactCounts.data.activeTest} test ${pluralize(
                  'contacts',
                  contactCounts.data.activeTest
                )} will remain active but can be archived manually)`}
              </p>
            )}
          </>
        ) : (
          <span>
            <strong>{contactCounts.data.archived}</strong>{' '}
            {pluralize('contacts', contactCounts.data.archived)} will be added
            to the active population
          </span>
        )}
      </div>
    ) : contactCounts.status === 'loading' ? (
      <div>loading contact counts...</div>
    ) : (
      assertNever(contactCounts)
    )
  return (
    <ConfirmationModal
      onClose={onClose}
      show={show}
      title={`Are you sure you want to ${archiveAction.toLowerCase()} contacts?`}
      onConfirm={() => onConfirm(searchQuery, archiveAction)}
      confirmButtonText={`${capitalize(archiveAction)}`}
      helpText={helpText}
      isDisabled={!canBeArchived}
      eventLocation={eventLocation}
      eventObject={eventObject}
      confirmButtonColor={
        archiveAction === IArchiveActionType.ARCHIVE
          ? 'new-ui-danger'
          : 'primary'
      }
      hideCheckbox
    />
  )
}

interface IContactOverFlowMenuProps {
  archiveOrUnarchiveContacts: (
    searchQuery: string,
    action: IArchiveActionType,
    source: string,
    filter?: string
  ) => void
  archiveActionInProgress: boolean
  selectedArchiveAction: IArchiveActionType | undefined
  handleArchiveActionSelected: (action: IArchiveActionType) => void
  isLoading: boolean
}

const ContactOverFlowMenu = ({
  archiveOrUnarchiveContacts,
  archiveActionInProgress,
  selectedArchiveAction,
  handleArchiveActionSelected,
  isLoading,
}: IContactOverFlowMenuProps) => {
  const [visibleModal, setVisibleModal] = React.useState<
    'archive-contact' | 'custom-report' | undefined
  >()
  const handleCloseModal = React.useCallback(
    () => setVisibleModal(undefined),
    []
  )
  const { downloadInProgress, onDownload } = useContactsDownload({
    onDownloadComplete: handleCloseModal,
  })

  const handleConfirmArchiveAction = (
    searchQuery: string,
    action: IArchiveActionType
  ) => {
    handleCloseModal()
    archiveOrUnarchiveContacts(searchQuery, action, 'BULK')
  }
  const searchQuery = getFirstQueryParam(
    getQueryFilters(window.location)['search']
  )
  const getPreferenceData = React.useCallback(() => {
    const filters = getQueryFilters(window.location)
    return Api.getReportPreferences({
      insight: 'contacts_report',
      contactsParams: { searchQuery, filters },
    })
  }, [searchQuery])

  return (
    <>
      <ActionMenu
        className="mr-3"
        menuClassName="mr-3"
        popoverPlacement="bottom-end">
        <PermissionsGuard
          permission={PERMISSIONS.CONTACT.DELETE}
          className="w-100 list-group-item p-0 border-0">
          <Button
            eventAction="click"
            eventObject="archive"
            className="btn bg-white list-group-item rounded-0 action-btn text-mainstay-dark-blue-80"
            disabled={
              getQueryFilters(window.location)['filter'] === 'archived' ||
              archiveActionInProgress
            }
            loading={archiveActionInProgress}
            onClick={() => {
              handleArchiveActionSelected(IArchiveActionType.ARCHIVE)
              setVisibleModal('archive-contact')
            }}>
            <AHIcon name="archive" />
            Archive Contacts
          </Button>
        </PermissionsGuard>
        <Button
          eventAction="click"
          eventObject="unarchive"
          className="btn bg-white list-group-item action-btn text-mainstay-dark-blue-80"
          disabled={
            getQueryFilters(window.location)['filter'] === 'active' ||
            archiveActionInProgress
          }
          loading={archiveActionInProgress}
          onClick={() => {
            handleArchiveActionSelected(IArchiveActionType.UNARCHIVE)
            setVisibleModal('archive-contact')
          }}>
          <UnarchiveIcon className="mr-2" width="18px" />
          Unarchive Contacts
        </Button>
        <ContactDownloadButton
          eventObject="download"
          archiveActionInProgress={archiveActionInProgress}
          isLoading={isLoading}
          onOpenModal={() => setVisibleModal('custom-report')}
        />
      </ActionMenu>
      {selectedArchiveAction && visibleModal === 'archive-contact' && (
        <ArchiveContactsModal
          archiveAction={selectedArchiveAction}
          show={visibleModal === 'archive-contact'}
          onClose={handleCloseModal}
          onConfirm={handleConfirmArchiveAction}
        />
      )}
      <CustomDownloadModal
        reportTitle="Contacts Report"
        initialFileName={generateReportFileName('contacts_report')}
        show={visibleModal === 'custom-report'}
        getPreferenceData={getPreferenceData}
        onClose={handleCloseModal}
        submissionInProgress={downloadInProgress}
        onSubmit={onDownload}
      />
    </>
  )
}

const mapStateToProps = (
  state: IState
): Pick<IContactListOwnProps, 'archiveActionCompleted' | 'archiveProgress'> => {
  return {
    archiveActionCompleted: getarchiveActionCompleted(state),
    archiveProgress: getArchiveActionProgress(state),
  }
}
const mapDispatchToProps = (
  dispatch: Dispatch
): Pick<
  IContactListOwnProps,
  'createContact' | 'archiveOrUnarchiveContacts' | 'clearCurrentContact'
> => {
  return {
    createContact: createContactAsync(dispatch),
    archiveOrUnarchiveContacts: archiveOrUnarchiveContactsAsync(dispatch),
    clearCurrentContact: () => dispatch(clearCurrentContact()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactList)
