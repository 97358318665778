import {
  CanonicalGenerativeTextPromptCategoriesShapeType,
  ConversationSettingsShapeType,
  GenerativeAISettingsShapeType,
} from 'api'
import {
  fetchConversationSettings,
  IActions,
  updateConversationSettings,
  fetchGenerativeTextPromptSettings,
  updateGenerativeTextPromptSetting,
  updateGenerativeAISettings,
} from 'page/settings/ConversationSettings/actions'
import { Failure, Success, WebData } from 'store/webdata'
import { getType } from 'typesafe-actions'

const DEFAULT_FREEZE_WINDOW = 180

type ConversationSettingsState = {
  readonly liveChatDuration: number
  readonly liveChatDisabled: boolean
  readonly dialogSettings: ConversationSettingsShapeType['dialogSettings']
  readonly generativeTextPromptSettings: GenerativeTextPromptSettings
  readonly generativeAISettings: WebData<GenerativeAISettingsShapeType>
  readonly status: ConversationSettingsRequestStatus
  readonly updateStatus: ConversationSettingsRequestStatus
}

export enum ConversationSettingsRequestStatus {
  initial = 'initial',
  loading = 'loading',
  error = 'error',
  ok = 'ok',
}

export type GenerativeTextPromptSettings = {
  readonly data: GenerativeTextPromptSettingsData
  readonly updateStatus: {
    readonly brevity: GenerativeTextPromptSettingsRequestStatus
    readonly tone: GenerativeTextPromptSettingsRequestStatus
  }
}

export type GenerativeTextPromptSettingsData =
  | {
      status: GenerativeTextPromptSettingsRequestStatus.initial
    }
  | {
      status: GenerativeTextPromptSettingsRequestStatus.loading
    }
  | {
      status: GenerativeTextPromptSettingsRequestStatus.error
    }
  | {
      status: GenerativeTextPromptSettingsRequestStatus.ok
      data: CanonicalGenerativeTextPromptCategoriesShapeType
    }

export enum GenerativeTextPromptSettingsRequestStatus {
  initial = 'initial',
  loading = 'loading',
  error = 'error',
  ok = 'ok',
}

const initialConversationSettingsState: ConversationSettingsState = {
  liveChatDuration: DEFAULT_FREEZE_WINDOW,
  liveChatDisabled: false,
  dialogSettings: null,
  generativeTextPromptSettings: {
    data: {
      status: GenerativeTextPromptSettingsRequestStatus.initial,
    },
    updateStatus: {
      brevity: GenerativeTextPromptSettingsRequestStatus.initial,
      tone: GenerativeTextPromptSettingsRequestStatus.initial,
    },
  },
  generativeAISettings: undefined,
  status: ConversationSettingsRequestStatus.initial,
  updateStatus: ConversationSettingsRequestStatus.initial,
}

const conversationSettings = (
  state: ConversationSettingsState = initialConversationSettingsState,
  action: IActions
): ConversationSettingsState => {
  switch (action.type) {
    case getType(fetchConversationSettings.request):
      return {
        ...state,
        status: ConversationSettingsRequestStatus.loading,
      }
    case getType(fetchConversationSettings.success):
      return {
        ...state,
        liveChatDuration: action.payload.liveChatDuration,
        liveChatDisabled: action.payload.liveChatDisabled,
        dialogSettings: action.payload.dialogSettings,
        status: ConversationSettingsRequestStatus.ok,
      }
    case getType(fetchConversationSettings.failure):
      return {
        ...state,
        status: ConversationSettingsRequestStatus.error,
      }
    case getType(updateConversationSettings.request):
      return {
        ...state,
        updateStatus: ConversationSettingsRequestStatus.loading,
      }
    case getType(updateConversationSettings.success):
      return {
        ...state,
        liveChatDisabled: action.payload.liveChatDisabled,
        liveChatDuration: action.payload.liveChatDuration,
        updateStatus: ConversationSettingsRequestStatus.ok,
      }
    case getType(updateConversationSettings.failure):
      return {
        ...state,
        updateStatus: ConversationSettingsRequestStatus.error,
      }
    case getType(fetchGenerativeTextPromptSettings.request):
      return {
        ...state,
        generativeTextPromptSettings: {
          ...state.generativeTextPromptSettings,
          data: {
            status: GenerativeTextPromptSettingsRequestStatus.loading,
          },
        },
      }
    case getType(fetchGenerativeTextPromptSettings.success):
      return {
        ...state,
        generativeTextPromptSettings: {
          ...state.generativeTextPromptSettings,
          data: {
            status: GenerativeTextPromptSettingsRequestStatus.ok,
            data: action.payload.prompt_settings,
          },
        },
        generativeAISettings: Success(action.payload.generative_ai_settings),
      }
    case getType(fetchGenerativeTextPromptSettings.failure):
      return {
        ...state,
        generativeAISettings: Failure('Failed to load settings'),
        generativeTextPromptSettings: {
          ...state.generativeTextPromptSettings,
          data: {
            status: GenerativeTextPromptSettingsRequestStatus.error,
          },
        },
      }
    case getType(updateGenerativeTextPromptSetting.request):
      return {
        ...state,
        generativeTextPromptSettings: {
          ...state.generativeTextPromptSettings,
          updateStatus: {
            ...state.generativeTextPromptSettings.updateStatus,
            [action.payload]: GenerativeTextPromptSettingsRequestStatus.loading,
          },
        },
      }
    case getType(updateGenerativeAISettings):
      return {
        ...state,
        generativeAISettings: Success(action.payload),
      }
    case getType(updateGenerativeTextPromptSetting.success):
      const currentGenTextSettings = state.generativeTextPromptSettings.data
      const newGenTextData =
        currentGenTextSettings.status ===
        GenerativeTextPromptSettingsRequestStatus.ok
          ? {
              ...currentGenTextSettings,
              data: {
                ...currentGenTextSettings.data,
                [action.payload.categoryKey]: {
                  ...currentGenTextSettings.data[action.payload.categoryKey],
                  default_value: action.payload.defaultValue,
                },
              },
            }
          : currentGenTextSettings
      return {
        ...state,
        generativeTextPromptSettings: {
          data: newGenTextData,
          updateStatus: {
            ...state.generativeTextPromptSettings.updateStatus,
            [action.payload.categoryKey]:
              GenerativeTextPromptSettingsRequestStatus.ok,
          },
        },
      }
    case getType(updateGenerativeTextPromptSetting.failure):
      return {
        ...state,
        generativeTextPromptSettings: {
          ...state.generativeTextPromptSettings,
          updateStatus: {
            ...state.generativeTextPromptSettings.updateStatus,
            [action.payload]: GenerativeTextPromptSettingsRequestStatus.error,
          },
        },
      }
    default:
      return state
  }
}

export default conversationSettings
