import React from 'react'
// eslint-disable-next-line no-restricted-imports
import {
  toast as reactToast,
  ToastOptions,
  Slide,
  ToastContainer as ReactToastifyContainer,
} from 'react-toastify'

export { ToastOptions }

import { CloseButtonIcon } from 'components/Icons/CloseButtonIcon/CloseButtonIcon'
import { InfoIcon } from 'mainstay-ui-kit/MainstayToast/Icons/InfoIcon/InfoIcon'
import { ErrorIcon } from 'mainstay-ui-kit/MainstayToast/Icons/ErrorIcon/ErrorIcon'
import { SuccessIcon } from 'mainstay-ui-kit/MainstayToast/Icons/SuccessIcon/SuccessIcon'
import { WarningIcon } from 'mainstay-ui-kit/MainstayToast/Icons/WarningIcon/WarningIcon'

import 'react-toastify/dist/ReactToastify.css'
import 'mainstay-ui-kit/MainstayToast/MainstayToast.scss'
import {
  EventTrackerAttrAdder,
  ITrackingEvent,
} from 'components/EventTracker/EventTracker'

export type ToastType = 'info' | 'success' | 'warning' | 'error'

type LinkOptions = {
  readonly onClick: () => void
  readonly text: string
}

export type ToastArgs = {
  readonly type?: ToastType
  readonly link?: LinkOptions
  trackingEvent?: ITrackingEvent
  readonly options?: ToastOptions | undefined
}

type PartialToastArgs = Omit<ToastArgs, 'type'>

const DEFAULT_CLOSING_TIME_MS = 6000
const DEFAULT_TEXT_WORDS_COUNT_FOR_ADDITIONAL_TIME = 30
const ADDITIONAL_SECONDS_AFTER_EACH_N_WORDS = 5

function mainstayToast(
  text: string,
  { type = 'success', link, options, trackingEvent }: ToastArgs = {},
  header?: string
) {
  let toastId: number | undefined = undefined

  const calculateToastClosingTime = (text: string) => {
    const words = text.split(' ')
    if (words.length > DEFAULT_TEXT_WORDS_COUNT_FOR_ADDITIONAL_TIME) {
      const additionalMS =
        Math.ceil(
          (words.length - DEFAULT_TEXT_WORDS_COUNT_FOR_ADDITIONAL_TIME) /
            ADDITIONAL_SECONDS_AFTER_EACH_N_WORDS
        ) * 1000
      return DEFAULT_CLOSING_TIME_MS + additionalMS
    }

    return DEFAULT_CLOSING_TIME_MS
  }

  const defaultOptions: ToastOptions = {
    position: 'top-center',
    className: `mainstay-toast-${type}`,
    closeButton: (
      <div
        className="d-flex pointer p-3 mt-1"
        onClick={() => reactToast.dismiss(toastId)}>
        <CloseButtonIcon />
      </div>
    ),
    closeOnClick: !link,
    autoClose: calculateToastClosingTime(text),
    hideProgressBar: true,
    type,
    ...options,
  }

  const ui = (
    <div className="d-flex flex-row px-2 pt-3 pb-3">
      <div className="px-2">
        {type === 'info' && <InfoIcon />}
        {type === 'error' && <ErrorIcon />}
        {type === 'success' && <SuccessIcon />}
        {type === 'warning' && <WarningIcon />}
      </div>
      <div className="pl-1 d-flex flex-row justify-content-between width-100 flex-wrap">
        {header && (
          <p className="m-0 fs-16px text-mainstay-gray-20 fw-600 pb-2">
            {header}
          </p>
        )}
        <p className="m-0 fs-16px text-mainstay-gray-20">{text}</p>
        {link && (
          <EventTrackerAttrAdder
            eventLocation={trackingEvent?.location}
            eventAction={trackingEvent?.action}
            eventObject={trackingEvent?.object}>
            <div
              className="pointer fs-16px text-mainstay-gray-20 font-weight-bold whitespace-nowrap text-underline"
              onClick={link.onClick}>
              {link.text}
            </div>
          </EventTrackerAttrAdder>
        )}
      </div>
    </div>
  )

  toastId = reactToast(ui, defaultOptions)

  return toastId
}

// tslint:disable-next-line: prefer-object-spread
export const toast = Object.assign(mainstayToast, {
  dismiss: reactToast.dismiss,
  error: (text: string, args?: PartialToastArgs, header?: string) =>
    mainstayToast(text, { type: 'error', ...args }, header),
  warning: (text: string, args?: PartialToastArgs, header?: string) =>
    mainstayToast(text, { type: 'warning', ...args }, header),
  info: (text: string, args?: PartialToastArgs, header?: string) =>
    mainstayToast(text, { type: 'info', ...args }, header),
  success: (text: string, args?: PartialToastArgs, header?: string) =>
    mainstayToast(text, { type: 'success', ...args }, header),
})

export class ToastContainer extends React.Component {
  render() {
    return (
      <ReactToastifyContainer
        autoClose={5000}
        position="top-center"
        hideProgressBar
        toastClassName="rounded"
        transition={Slide}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    )
  }
}
