import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'
import { AxiosError } from 'typings/axios'
import { RootState } from 'store/store'
import { commandsApi } from 'api'
import { TransportId } from 'store/transport'

export type HashtagCommandDialog = {
  id: number
  transport: TransportId | 'unknown'
  dialog_id: string
  label: string
}

export type HashtagCommand = {
  id: number
  name: string
  description: string
  words: {
    id: number
    command_text: string
  }[]
  dialogs: HashtagCommandDialog[]
  enabled: boolean
  institution: number | null
  order: number
  show_in_quick_menu: boolean
  quick_menu_text: string | null
}

export type HashtagCommandCreateRequest = {
  name: string
  description: string
  words: string[]
  enabled: boolean
  dialogs: { [key in TransportId]?: string }
}

export type HashtagCommandUpdateRequest = HashtagCommandCreateRequest & {
  id: number
}

export type HashtagPartialUpdateRequest = Partial<
  HashtagCommandCreateRequest
> & {
  id: number
  enabled?: boolean
  show_in_quick_menu?: boolean
  quick_menu_text?: string | null
  order?: number
}

type ValidationError<T> = {
  [K in keyof T]: string
}

const commandsAdapter = createEntityAdapter<HashtagCommand>({
  selectId: command => command.id,
  sortComparer: (a, b) => {
    if (a.order > b.order) {
      return 1
    }
    if (a.order === b.order) {
      return 0
    }
    return -1
  },
})

const listCommands = createAsyncThunk(
  'commands/listCommands',
  async (_arg, thunkApi) => {
    try {
      const response = await commandsApi.list()
      return response.data
    } catch (err) {
      // tslint:disable-next-line: no-unsafe-any
      const error: AxiosError<ValidationError<HashtagCommand>> = err
      if (!error.response) {
        throw err
      }
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

const retrieveCommand = createAsyncThunk(
  'commands/retrieveCommand',
  async (arg: { id: number }, thunkApi) => {
    try {
      const response = await commandsApi.retrieve(arg)
      return response.data
    } catch (err) {
      // tslint:disable-next-line: no-unsafe-any
      const error: AxiosError<ValidationError<HashtagCommand>> = err
      if (!error.response) {
        throw err
      }
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

const partialUpdate = createAsyncThunk<
  { id: number; changes: Partial<HashtagCommand> },
  HashtagPartialUpdateRequest,
  { state: RootState }
>(
  'commands/updateCommand',
  async (arg, thunkApi) => {
    try {
      const response = await commandsApi.partialUpdate(arg)
      return { id: arg.id, changes: response.data }
    } catch (err) {
      // tslint:disable-next-line: no-unsafe-any
      const error: AxiosError<ValidationError<HashtagCommand>> = err
      if (!error.response) {
        throw err
      }
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
  {}
)

const updateCommand = createAsyncThunk<
  { id: number; changes: Partial<HashtagCommand> },
  HashtagCommandUpdateRequest,
  { state: RootState }
>(
  'commands/updateCommand',
  async (arg, thunkApi) => {
    try {
      const response = await commandsApi.update(arg)
      return { id: arg.id, changes: response.data }
    } catch (err) {
      // tslint:disable-next-line: no-unsafe-any
      const error: AxiosError<ValidationError<HashtagCommand>> = err
      if (!error.response) {
        throw err
      }
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
  {}
)

const deleteCommand = createAsyncThunk(
  'commands/deleteCommand',
  async (arg: { id: number }, thunkApi) => {
    try {
      await commandsApi.delete(arg)
      return { id: arg.id }
    } catch (err) {
      // tslint:disable-next-line: no-unsafe-any
      const error: AxiosError<ValidationError<HashtagCommand>> = err
      if (!error.response) {
        throw err
      }
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

const createCommand = createAsyncThunk(
  'commands/createCommand',
  async (arg: HashtagCommandCreateRequest, thunkApi) => {
    try {
      const response = await commandsApi.create(arg)
      return response.data
    } catch (err) {
      // tslint:disable-next-line: no-unsafe-any
      const error: AxiosError<ValidationError<HashtagCommand>> = err
      if (!error.response) {
        throw err
      }
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
type LoadingState = 'idle' | 'pending' | 'success' | 'failure'
const commandsSlice = createSlice({
  name: 'commands',
  initialState: commandsAdapter.getInitialState<{
    retrieving: LoadingState
    updating: LoadingState
    bulkUpdating: LoadingState
    creating: LoadingState
    listing: LoadingState
    deleting: LoadingState
  }>({
    retrieving: 'idle',
    updating: 'idle',
    bulkUpdating: 'idle',
    creating: 'idle',
    listing: 'idle',
    deleting: 'idle',
  }),
  reducers: {
    upsertOne: commandsAdapter.upsertOne,
    setAll: commandsAdapter.setAll,
    removeOne: commandsAdapter.removeOne,
  },
  extraReducers: builder => {
    builder.addCase(listCommands.fulfilled, (state, action) => {
      state.listing = 'success'
      commandsAdapter.setAll(state, action)
    })
    builder.addCase(listCommands.pending, (state, _action) => {
      state.listing = 'pending'
    })
    builder.addCase(listCommands.rejected, (state, _action) => {
      state.listing = 'failure'
    })
    builder.addCase(createCommand.fulfilled, (state, action) => {
      state.creating = 'success'
      commandsAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(createCommand.pending, (state, _action) => {
      state.creating = 'pending'
    })
    builder.addCase(createCommand.rejected, (state, _action) => {
      state.creating = 'failure'
    })
    builder.addCase(retrieveCommand.fulfilled, (state, action) => {
      state.retrieving = 'success'
      commandsAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(retrieveCommand.pending, (state, _action) => {
      state.retrieving = 'pending'
    })
    builder.addCase(retrieveCommand.rejected, (state, _action) => {
      state.retrieving = 'failure'
    })
    builder.addCase(updateCommand.fulfilled, (state, action) => {
      state.updating = 'success'
      commandsAdapter.updateOne(state, action.payload)
    })
    builder.addCase(updateCommand.pending, (state, _action) => {
      state.updating = 'pending'
    })
    builder.addCase(updateCommand.rejected, (state, _action) => {
      state.updating = 'failure'
    })
    builder.addCase(deleteCommand.fulfilled, (state, action) => {
      state.deleting = 'success'
      commandsAdapter.removeOne(state, action.payload.id)
    })
    builder.addCase(deleteCommand.pending, (state, _action) => {
      state.deleting = 'pending'
    })
    builder.addCase(deleteCommand.rejected, (state, _action) => {
      state.deleting = 'failure'
    })
  },
})

export const commandsThunks = {
  createCommand,
  deleteCommand,
  updateCommand,
  partialUpdate,
  retrieveCommand,
  listCommands,
}

export const commandsReducer = commandsSlice.reducer

const adapterSelectors = commandsAdapter.getSelectors<RootState>(
  state => state.commands
)

const selectNonGlobalCommands = createSelector(
  adapterSelectors.selectAll,
  commands => commands.filter(x => !!x.institution)
)
const selectQuickActionsMenu = createSelector(
  selectNonGlobalCommands,
  commands =>
    commands.filter(x => x.show_in_quick_menu).sort((a, b) => a.order - b.order)
)

export const commandsSelectors = {
  ...adapterSelectors,
  selectNonGlobalCommands,
  selectQuickActionsMenu,
}
