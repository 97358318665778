import {
  ActionType,
  createStandardAction,
  createAsyncAction,
} from 'typesafe-actions'
import { ConversationListFiltersType } from 'page/conversations-v2/ConversationList/ConversationHeader/reducer'
import { TransportId } from 'store/transport'

export const showConversationListFilterModal = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_FILTER_MODAL_SHOW'
)<boolean>()
export const resetConversationListFilterModal = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_FILTER_MODAL_RESET_STATE'
)()
export const conversationListGetFilterLabels = createAsyncAction(
  '@@MASCOT/CONVERSATION_LIST_GET_FILTER_LABELS_REQUEST',
  '@@MASCOT/CONVERSATION_LIST_GET_FILTER_LABELS_SUCCESS',
  '@@MASCOT/CONVERSATION_LIST_GET_FILTER_LABELS_FAILURE'
)<void, ConversationListFiltersType, void>()

export type FilterType =
  | { type: 'audience'; id: string }
  | { type: 'transport'; id: TransportId }
  | { type: 'script'; id: string }
  | { type: 'campaign'; id: string }

export const conversationListRemoveFilter = createStandardAction(
  '@@MASCOT/CONVERSATION_LIST_REMOVE_FILTER'
)<FilterType>()

export type IActions =
  | ActionType<typeof showConversationListFilterModal>
  | ActionType<typeof resetConversationListFilterModal>
  | ActionType<typeof conversationListGetFilterLabels>
  | ActionType<typeof conversationListRemoveFilter>
