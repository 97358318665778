import { getType } from 'typesafe-actions'
import {
  createNewAttributes,
  getExistingMappings,
  preMapOnName,
  updateMappingsSync,
  updateMappingsAsync,
  cancelMapping,
  addTargetAttribute,
} from 'store/attribute-mapper/actions'
import { IMappingAttribute, IAttributeMap } from 'api/response'
import { WebData, Loading, Success, Failure, isSuccess } from 'store/webdata'
import {
  updateMapping as updateMappingUtil,
  preMapOnName as preMapOnNameUtil,
} from 'components/AttributeMapper/utils'
import { IActions } from 'store/store'

export const attributeTypes = ['CONTACT', 'INSTITUTION'] as const
export type AttributeType = typeof attributeTypes[number]

export const parseAttributeType = (typeString: string): AttributeType => {
  const maybeAttributeType: unknown = typeString
  if (
    typeof maybeAttributeType === 'string' &&
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    attributeTypes.includes(maybeAttributeType as AttributeType)
  ) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return maybeAttributeType as AttributeType
  }
  throw new Error('Invalid AttributeType.')
}

interface IAttributeMapperState {
  sourceInstitution: string | undefined
  targetInstitution: string | undefined
  attributeType: 'CONTACT' | 'INSTITUTION' | undefined
  sourceAttrs: WebData<IMappingAttribute[]>
  targetAttrs: WebData<IMappingAttribute[]>
  mappings: WebData<IAttributeMap[]>
  attrCreationsLoading: boolean
  mappingsLoading: boolean
}

const initialState: IAttributeMapperState = {
  sourceInstitution: undefined,
  targetInstitution: undefined,
  attributeType: undefined,
  sourceAttrs: undefined,
  targetAttrs: undefined,
  mappings: undefined,
  attrCreationsLoading: false,
  mappingsLoading: false,
}

const attributeMappingReducer = (
  state: IAttributeMapperState = initialState,
  action: IActions
): IAttributeMapperState => {
  switch (action.type) {
    case getType(getExistingMappings.request): {
      return {
        ...state,
        sourceAttrs: Loading(),
        targetAttrs: Loading(),
        mappings: Loading(),
        mappingsLoading: true,
      }
    }
    case getType(getExistingMappings.success): {
      return {
        ...state,
        sourceAttrs: Success(action.payload.source_attrs),
        targetAttrs: Success(action.payload.target_attrs),
        mappings: Success(action.payload.mappings),
        mappingsLoading: false,
      }
    }
    case getType(getExistingMappings.failure): {
      return {
        ...state,
        sourceAttrs: Failure('Failed to fetch source attributes'),
        targetAttrs: Failure('Failed to fetch target attributes'),
        mappings: Failure('Failed to fetch existing mappings'),
        mappingsLoading: false,
      }
    }
    case getType(createNewAttributes.request): {
      return {
        ...state,
        targetAttrs: Loading(),
        attrCreationsLoading: true,
      }
    }
    case getType(createNewAttributes.success): {
      if (isSuccess(state.mappings)) {
        return {
          ...state,
          targetAttrs: Success(action.payload.target_attributes),
          mappings: Success([
            ...state.mappings.data,
            ...Success(action.payload.mappings).data,
          ]),

          attrCreationsLoading: false,
        }
      }
    }
    case getType(createNewAttributes.failure): {
      return {
        ...state,
        targetAttrs: state.targetAttrs,
        mappings: isSuccess(state.mappings)
          ? Success(state.mappings.data)
          : Failure('Failed to fetch mappings'),
        attrCreationsLoading: false,
      }
    }
    case getType(updateMappingsAsync.request): {
      return {
        ...state,
        mappings: Loading(),
      }
    }
    case getType(updateMappingsAsync.success): {
      return {
        ...state,
        mappings: Success(action.payload),
      }
    }
    case getType(updateMappingsAsync.failure): {
      return {
        ...state,
        mappings: state.mappings,
      }
    }
    case getType(updateMappingsSync): {
      if (
        isSuccess(state.mappings) &&
        isSuccess(state.sourceAttrs) &&
        isSuccess(state.targetAttrs)
      ) {
        const updatedMappings =
          action.payload &&
          updateMappingUtil(
            state.mappings.data,
            action.payload?.sourceAttrId,
            action.payload?.targetAttrId,
            state.sourceAttrs.data,
            state.targetAttrs.data,
            action.payload?.unmap
          )
        return {
          ...state,
          mappings: Success(updatedMappings || state.mappings.data || []),
        }
      }
    }
    case getType(preMapOnName): {
      if (
        isSuccess(state.mappings) &&
        isSuccess(state.sourceAttrs) &&
        isSuccess(state.targetAttrs)
      ) {
        const updatedMappings = preMapOnNameUtil(
          state.sourceAttrs.data,
          state.targetAttrs.data,
          state.mappings.data
        )
        return {
          ...state,
          mappings: Success(updatedMappings || state.mappings.data || []),
        }
      }
    }
    case getType(cancelMapping): {
      return {
        ...state,
        sourceInstitution: undefined,
        targetInstitution: undefined,
        sourceAttrs: Success([]),
        targetAttrs: Success([]),
        mappings: Success([]),
        attributeType: undefined,
      }
    }
    case getType(addTargetAttribute): {
      if (isSuccess(state.targetAttrs)) {
        return {
          ...state,
          targetAttrs: Success([...state.targetAttrs.data, action.payload]),
        }
      }
    }
    default:
      return state
  }
}

export default attributeMappingReducer
