import * as React from 'react'
import * as Raven from '@sentry/browser'
import { Link } from 'util/routing'
import * as ROUTES from 'const/routes'

export default class NoMatch extends React.Component {
  componentDidMount() {
    const location = window.location.href
    const message = `404: Page not found for path: ${location}`
    Raven.addBreadcrumb({ type: 'navigation', message })
  }
  render() {
    return (
      <div className="align-items-center d-flex flex-column justify-content-center height-50vh">
        <h1 className="h1 title bold text-center">404</h1>
        <p className="h4 text-center">Nothing here 🌵</p>
        <Link to={ROUTES.DEFAULT_ROUTE}>Return to Dashboard</Link>
      </div>
    )
  }
}
