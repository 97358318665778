/**
 *
 * Utility function for comparing a subset of old and new props. Will return true if
 * any differ in value according to a shallow equality compare.
 *
 * @param oldProps the old props
 * @param newProps the new props
 * @param propsToCompare an array of prop keys to compare
 */
export function propsDiffer<T>(
  oldProps: T,
  newProps: T,
  propsToCompare: Array<keyof T>
): boolean {
  return propsToCompare.some((p: keyof T) => oldProps[p] !== newProps[p])
}
