import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css'
import { DayPickerProps } from 'react-day-picker/types/props'
import { EventTrackerAttrAdder } from 'components/EventTracker/EventTracker'
import 'components/ReadOnlyDatePicker/datePicker.scss'

export const ReadOnlyDatePicker = ({
  value,
  onDayChange,
  classNames,
  dayPickerProps,
  eventLocation,
  eventObject,
  readOnly,
}: {
  readonly value?: string | Date | undefined
  readonly onDayChange?: ((day: Date) => void) | undefined
  readonly classNames?: Record<string, string>
  readonly dayPickerProps?: DayPickerProps
  readonly eventLocation?: string
  readonly eventObject?: string
  readonly readOnly?: boolean
}): JSX.Element => {
  return (
    <EventTrackerAttrAdder
      eventLocation={eventLocation}
      eventAction="change"
      eventObject={eventObject}>
      {/** Empty div is necessary for event attributes to attach to */}
      <div>
        <DayPickerInput
          format="LL"
          inputProps={{
            disabled: readOnly,
            readOnly: true,
            className: `form-control form-control-md ${
              readOnly
                ? 'read-only-day-picker-input'
                : 'daypicker-contact-panel-input'
            } ${classNames?.input}`,
          }}
          /* eslint-disable @typescript-eslint/consistent-type-assertions */
          /* tslint:disable:no-any no-unsafe-any */
          classNames={
            {
              ...classNames,
              container: `w-100 ${classNames?.container}`,
            } as any
          }
          formatDate={formatDate}
          parseDate={parseDate}
          placeholder="Select a date"
          value={value}
          onDayChange={onDayChange}
          dayPickerProps={dayPickerProps}
        />
      </div>
    </EventTrackerAttrAdder>
  )
}
