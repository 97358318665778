import {
  createAsyncAction,
  ActionType,
  createStandardAction,
} from 'typesafe-actions'
import { IApiError, ITemplateCollection } from 'api'
import {
  ICampaignScriptPreview,
  ICampaignScriptStep,
  IIntroDialog,
  IExitAction,
  TestContact,
  ICampaignScriptDragState,
} from 'store/campaign-scripts/reducer'
import {
  ICampaignScriptStateDeleteEdgeRequest,
  ICampaignScriptStateSpliceRequest,
  ICampaignScriptUpdateRequest,
  ICampaignScriptStateUpdateRequest,
  ITransitionType,
} from 'api/request'
import {
  ICampaignScriptPreviewListResponse,
  ICampaignScriptRetrieveResponse,
  ICampaignScriptResponse,
  ICampaignScriptPreviewResponse,
  ITemplateListResponse,
} from 'api/response'
import { IIntroDialogListItem } from 'components/IntroDialogList/IntroDialogList'

export const searchCampaignScripts = createAsyncAction(
  '@@@mascot/campaign-scripts/SEARCH_CAMPAIGN_SCRIPTS_START',
  '@@@mascot/campaign-scripts/SEARCH_CAMPAIGN_SCRIPTS_END',
  '@@@mascot/campaign-scripts/SEARCH_CAMPAIGN_SCRIPTS_FAIL'
)<string | undefined, ICampaignScriptPreviewListResponse, IApiError>()

export const searchScriptTemplates = createAsyncAction(
  '@@@mascot/campaign-scripts/SEARCH_SCRIPTS_TEMPLATES_START',
  '@@@mascot/campaign-scripts/SEARCH_SCRIPTS_TEMPLATES_END',
  '@@@mascot/campaign-scripts/SEARCH_SCRIPTS_TEMPLATES_FAIL'
)<string | undefined, ITemplateListResponse, IApiError>()

export const retrieveCampaignScript = createAsyncAction(
  '@@@mascot/campaign-scripts/RETRIEVE_CAMPAIGN_SCRIPT_START',
  '@@@mascot/campaign-scripts/RETRIEVE_CAMPAIGN_SCRIPT_END',
  '@@@mascot/campaign-scripts/RETRIEVE_CAMPAIGN_SCRIPT_FAIL'
)<string | undefined, ICampaignScriptRetrieveResponse, IApiError>()

export const listTemplateScripts = createAsyncAction(
  '@@@mascot/campaign-scripts/LIST_TEMPLATE_SCRIPTS_START',
  '@@@mascot/campaign-scripts/LIST_TEMPLATE_SCRIPTS_END',
  '@@@mascot/campaign-scripts/LIST_TEMPLATE_SCRIPTS_FAIL'
)<void, ITemplateListResponse, IApiError>()

export const getAllCampaignScripts = createAsyncAction(
  '@@@mascot/campaign-scripts/GET_ALL_CAMPAIGNS_SCRIPTS_START',
  '@@@mascot/campaign-scripts/GET_ALL_CAMPAIGNS_SCRIPTS_END',
  '@@@mascot/campaign-scripts/GET_ALL_CAMPAIGNS_SCRIPTS_FAIL'
)<void, ICampaignScriptPreviewResponse[], IApiError>()

interface IUpdateTemplateCollectionsRequest {
  templateIds: string[]
  collectionsToAdd: ITemplateCollection[]
  collectionsToRemove: ITemplateCollection[]
}
export const updateTemplateCollections = createAsyncAction(
  '@@@mascot/campaign-scripts/UPDATE_TEMPLATE_COLLECTIONS_START',
  '@@@mascot/campaign-scripts/UPDATE_TEMPLATE_COLLECTIONS_END',
  '@@@mascot/campaign-scripts/UPDATE_TEMPLATE_COLLECTIONS_FAIL'
)<void, IUpdateTemplateCollectionsRequest, IApiError>()

export const updateSortfield = createStandardAction(
  '@@mascot/campaign-scripts/UPDATE_SORT_FIELD'
)<keyof ICampaignScriptPreview>()

export const updateSortOrder = createStandardAction(
  '@@mascot/campaign-scripts/UPDATE_SORT_ORDER'
)<'asc' | 'desc'>()

export const updateSelectedDialogTags = createStandardAction(
  '@@mascot/campaign-scripts/UPDATE_SELECTED_TAGS'
)<number[]>()

export const updateScriptSearchQuery = createStandardAction(
  '@@mascot/campaign-scripts/UPDATE_SCRIPT_SEARCH_QUERY'
)<string>()

export const spliceCampaignScriptState = createAsyncAction(
  '@MASCOT/CAMPAIGN_SCRIPTS_STATE/SPLICE_REQUEST',
  '@MASCOT/CAMPAIGN_SCRIPTS_STATE/SPLICE_SUCCESS',
  '@MASCOT/CAMPAIGN_SCRIPTS_STATE/SPLICE_FAILURE'
)<
  ICampaignScriptStateSpliceRequest,
  {
    dialogStateId: string
    newDialogStateData: ICampaignScriptStep
    transitionType: ITransitionType
    spliceTransition?: ITransitionType
  },
  void
>()

export const deleteCampaignScriptStateEdge = createAsyncAction(
  '@MASCOT/CAMPAIGN_SCRIPTS_STATE/DELETE_EDGE_REQUEST',
  '@MASCOT/CAMPAIGN_SCRIPTS_STATE/DELETE_EDGE_SUCCESS',
  '@MASCOT/CAMPAIGN_SCRIPTS_STATE/DELETE_EDGE_FAILURE'
)<
  ICampaignScriptStateDeleteEdgeRequest,
  ICampaignScriptStateDeleteEdgeRequest,
  void
>()

export const updateCampaignScriptState = createAsyncAction(
  '@MASCOT/CAMPAIGN_SCRIPTS_STATE/UPDATE_REQUEST',
  '@MASCOT/CAMPAIGN_SCRIPTS_STATE/UPDATE_SUCCESS',
  '@MASCOT/CAMPAIGN_SCRIPTS_STATE/UPDATE_FAILURE'
)<ICampaignScriptStateUpdateRequest, ICampaignScriptStep, void>()

export const campaignScriptDragState = createStandardAction(
  '@MASCOT/CAMPAIGN_SCRIPTS_STATE/CAMPAIGN_SCRIPTS_DRAG_STATE'
)<ICampaignScriptDragState>()

export const setShowPauseTimeForm = createStandardAction(
  '@@mascot/campaign-scripts/SET_SHOW_PAUSE_TIME_FORM'
)<{ dialogStateId: string; showForm: boolean }>()

export const addBlankExitAction = createStandardAction(
  '@@mascot/campaign-scripts/ADD_BLANK_EXIT_ACTION'
)<{ action: IExitAction; dialogStateId: string }>()

export const clearBlankExitAction = createStandardAction(
  '@@mascot/campaign-scripts/CLEAR_BLANK_EXIT_ACTION'
)<{ dialogStateId: string }>()

export const duplicateCampaignScript = createAsyncAction(
  '@MASCOT/CAMPAIGN_SCRIPTS/DUPLICATE_REQUEST',
  '@MASCOT/CAMPAIGN_SCRIPTS/DUPLICATE_SUCCESS',
  '@MASCOT/CAMPAIGN_SCRIPTS/DUPLICATE_FAILURE'
)<string, ICampaignScriptRetrieveResponse, undefined>()

export const destroyCampaignScript = createAsyncAction(
  '@MASCOT/CAMPAIGN_SCRIPTS/DESTROY_REQUEST',
  '@MASCOT/CAMPAIGN_SCRIPTS/DESTROY_SUCCESS',
  '@MASCOT/CAMPAIGN_SCRIPTS/DESTROY_FAILURE'
)<string, string, void>()

export const updateCampaignScript = createAsyncAction(
  '@MASCOT/CAMPAIGN_SCRIPTS/UPDATE_REQUEST',
  '@MASCOT/CAMPAIGN_SCRIPTS/UPDATE_SUCCESS',
  '@MASCOT/CAMPAIGN_SCRIPTS/UPDATE_FAILURE'
)<ICampaignScriptUpdateRequest, ICampaignScriptResponse, void>()

export const copyToLibrary = createAsyncAction(
  '@MASCOT/CAMPAIGN_SCRIPT_LIBRARY/COPY_TO_REQUEST',
  '@MASCOT/CAMPAIGN_SCRIPT_LIBRARY/COPY_TO_SUCCESS',
  '@MASCOT/CAMPAIGN_SCRIPT_LIBRARY/COPY_TO_FAILURE'
)<ICampaignScriptUpdateRequest, ICampaignScriptResponse, void>()

export const copyFromLibrary = createAsyncAction(
  '@MASCOT/CAMPAIGN_SCRIPT_LIBRARY/COPY_FROM_REQUEST',
  '@MASCOT/CAMPAIGN_SCRIPT_LIBRARY/COPY_FROM_SUCCESS',
  '@MASCOT/CAMPAIGN_SCRIPT_LIBRARY/COPY_FROM_FAILURE'
)<ICampaignScriptUpdateRequest, ICampaignScriptResponse, void>()

export const createCampaignScript = createAsyncAction(
  '@MASCOT/CAMPAIGN_SCRIPTS/CREATE_REQUEST',
  '@MASCOT/CAMPAIGN_SCRIPTS/CREATE_SUCCESS',
  '@MASCOT/CAMPAIGN_SCRIPTS/CREATE_FAILURE'
)<void, ICampaignScriptRetrieveResponse, void>()

export const createCampaignScriptInitialState = createAsyncAction(
  '@MASCOT/CAMPAIGN_SCRIPTS/CREATE_INITIAL_STATE_REQUEST',
  '@MASCOT/CAMPAIGN_SCRIPTS/CREATE_INITIAL_STATE_SUCCESS',
  '@MASCOT/CAMPAIGN_SCRIPTS/CREATE_INITIAL_STATE_FAILURE'
)<void, ICampaignScriptRetrieveResponse, void>()

export const fetchTestContacts = createAsyncAction(
  '@MASCOT/CAMPAIGN_SCRIPTS/FETCH_TEST_CONTACTS_REQUEST',
  '@MASCOT/CAMPAIGN_SCRIPTS/FETCH_TEST_CONTACTS_SUCCESS',
  '@MASCOT/CAMPAIGN_SCRIPTS/FETCH_TEST_CONTACTS_FAILURE'
)<void, TestContact[], void>()

export const refreshDialogUpdatedAt = createStandardAction(
  '@@mascot/campaign-scripts/REFRESH_DIALOG_UPDATED_AT'
)<void, void>()

export const editScriptNodeAction = createStandardAction(
  '@MASCOT/CAMPAIGN_SCRIPTS_STATE/EDIT_SCRIPT_NODE_ACTION'
)<{ dialogStateId: string; editing: boolean }>()

export interface IStartLinkingPayload {
  linkStepId: string
  linkTransitionKey: ITransitionType
}
export const startLinking = createStandardAction(
  '@MASCOT/CAMPAIGN_SCRIPTS/START_LINKING'
)<IStartLinkingPayload>()

export const stopLinking = createStandardAction(
  '@MASCOT/CAMPAIGN_SCRIPTS/STOP_LINKING'
)<void>()

export interface ICampaignScriptCreateLinkPayload {
  dialogId: string
  dialogStateId: string
  linkTransitionKey: ITransitionType
  data: Partial<ICampaignScriptStep>
}

export interface ICampaignScriptCreateLinkResponse {
  dialogId: string
  dialogStateId: string
  linkTransitionKey: ITransitionType
  data: ICampaignScriptStep
}

export const createLink = createAsyncAction(
  '@MASCOT/CAMPAIGN_SCRIPTS/CREATE_LINK_REQUEST',
  '@MASCOT/CAMPAIGN_SCRIPTS/CREATE_LINK_SUCCESS',
  '@MASCOT/CAMPAIGN_SCRIPTS/CREATE_LINK_FAILURE'
)<ICampaignScriptCreateLinkPayload, ICampaignScriptCreateLinkResponse, void>()

export const fetchIntroDialogsList = createAsyncAction(
  '@MASCOT/CAMPAIGN_SCRIPTS/FETCH_INTRO_DIALOGS_LIST_REQUEST',
  '@MASCOT/CAMPAIGN_SCRIPTS/FETCH_INTRO_DIALOGS_LIST_SUCCESS',
  '@MASCOT/CAMPAIGN_SCRIPTS/FETCH_INTRO_DIALOGS_LIST_FAILURE'
)<void, ReadonlyArray<IIntroDialogListItem>, void>()

export const fetchIntroDialogDetails = createAsyncAction(
  '@MASCOT/CAMPAIGN_SCRIPTS/FETCH_INTRO_DIALOG_DETAILS_REQUEST',
  '@MASCOT/CAMPAIGN_SCRIPTS/FETCH_INTRO_DIALOG_DETAILS_SUCCESS',
  '@MASCOT/CAMPAIGN_SCRIPTS/FETCH_INTRO_DIALOG_DETAILS_FAILURE'
)<void, { dialog: IIntroDialog; states: ICampaignScriptStep[] }, void>()

export const generateDialogReport = createAsyncAction(
  '@@MASCOT/CAMPAIGN_SCRIPTS/GENERATE_DIALOG_REPORT_START',
  '@@MASCOT/CAMPAIGN_SCRIPTS/GENERATE_DIALOG_REPORT_SUCCES',
  '@@MASCOT/CAMPAIGN_SCRIPTS/GENERATE_DIALOG_REPORT_FAILURE'
)<{ dialogId: string }, { dialogId: string }, { dialogId: string }>()

export const generateDialogReportProgress = createStandardAction(
  '@@MASCOT/CAMPAIGN_SCRIPTS/GENERATE_DIALOG_REPORT_PROGRESS'
)<{ dialogId: string; progress: number }>()

export type ICampaignScriptActions =
  | ActionType<typeof searchCampaignScripts>
  | ActionType<typeof retrieveCampaignScript>
  | ActionType<typeof updateScriptSearchQuery>
  | ActionType<typeof updateSortOrder>
  | ActionType<typeof updateSortfield>
  | ActionType<typeof createCampaignScript>
  | ActionType<typeof updateCampaignScript>
  | ActionType<typeof destroyCampaignScript>
  | ActionType<typeof duplicateCampaignScript>
  | ActionType<typeof updateCampaignScriptState>
  | ActionType<typeof addBlankExitAction>
  | ActionType<typeof clearBlankExitAction>
  | ActionType<typeof deleteCampaignScriptStateEdge>
  | ActionType<typeof spliceCampaignScriptState>
  | ActionType<typeof getAllCampaignScripts>
  | ActionType<typeof fetchTestContacts>
  | ActionType<typeof startLinking>
  | ActionType<typeof stopLinking>
  | ActionType<typeof createLink>
  | ActionType<typeof createCampaignScriptInitialState>
  | ActionType<typeof fetchIntroDialogsList>
  | ActionType<typeof fetchIntroDialogDetails>
  | ActionType<typeof generateDialogReport>
  | ActionType<typeof generateDialogReportProgress>
  | ActionType<typeof copyToLibrary>
  | ActionType<typeof copyFromLibrary>
  | ActionType<typeof updateSelectedDialogTags>
  | ActionType<typeof updateTemplateCollections>
  | ActionType<typeof searchScriptTemplates>
  | ActionType<typeof listTemplateScripts>
  | ActionType<typeof setShowPauseTimeForm>
  | ActionType<typeof refreshDialogUpdatedAt>
  | ActionType<typeof campaignScriptDragState>
  | ActionType<typeof editScriptNodeAction>
