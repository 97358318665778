import * as React from 'react'
import ClassNames from 'classnames'
import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'

export interface ICheckableInputProps {
  type: CheckType
  id?: string
  name?: string
  value?: string
  checked?: boolean
  disabled?: boolean
  inline?: boolean
  className?: string
  inputClassName?: string
  labelClassName?: string
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (event: React.FocusEvent<HTMLInputElement>) => void
  eventLocation?: string
  eventObject?: string
  eventAction?: EventAction
}

export type CheckType = 'radio' | 'checkbox'

export class CheckableInput extends React.PureComponent<ICheckableInputProps> {
  render() {
    const {
      id,
      type,
      disabled,
      inline,
      children,
      className,
      onBlur,
      onClick,
      onChange,
      checked,
      name,
      inputClassName,
      labelClassName,
      value,
      eventLocation,
      eventObject,
      eventAction,
    } = this.props
    const classNames = ClassNames(
      'form-check mb-1',
      {
        'form-check-inline': inline,
      },
      className
    )
    const inputClassNames = ClassNames(
      'form-check-input',
      { 'cursor-pointer': !disabled },
      { 'border-mainstay-dark-blue-20': disabled },
      'mr-0',
      inputClassName
    )
    const labelClassNames = ClassNames('form-check-label px-2', labelClassName)
    return (
      <EventTrackerAttrAdder
        eventLocation={eventLocation}
        eventAction={eventAction}
        eventObject={eventObject}>
        <div className={classNames}>
          <input
            className={inputClassNames}
            type={type}
            id={id}
            onBlur={onBlur}
            onClick={onClick}
            onChange={onChange}
            checked={checked}
            disabled={disabled}
            name={name}
            value={value}
          />
          <label className={labelClassNames} htmlFor={id}>
            {children}
          </label>
        </div>
      </EventTrackerAttrAdder>
    )
  }
}
