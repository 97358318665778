import * as React from 'react'
import ClassNames from 'classnames'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'

export interface ITagProps {
  className?: string
  index?: number
  icon?: string
  value?: string
  name?: string
  id?: string
  readonly color?: 'success' | 'default' | 'dark-purple' | 'blue-grey-050'
  onClick?: (index?: number) => void
  onMouseOver?: () => void
  readonly children?: React.ReactNode
  style?: React.CSSProperties
}
export class Tag extends React.PureComponent<ITagProps> {
  onClick = () => {
    if (this.props.onClick) {
      if (this.props.index) {
        this.props.onClick(this.props.index)
      } else {
        this.props.onClick()
      }
    }
  }
  render() {
    const { color = 'default' } = this.props
    const primaryClassName = ClassNames(
      'border',
      `border-${color}`,
      'badge',
      'm-0',
      'badge-pill',
      'text-ellipsis',
      'text-mainstay-body-caption',
      'px-2 py-2',
      { 'bg-white text-muted': color === 'default' },
      { [`badge-${color}`]: color !== 'default' },
      'font-weight-normal',
      this.props.className
    )
    return (
      <p
        id={this.props.id}
        onMouseOver={this.props.onMouseOver}
        onClick={this.onClick}
        style={this.props.style}
        className={primaryClassName}>
        {this.props.onClick && <AHIcon name="close" />}
        <span className="align-middle">
          {this.props.value || this.props.children}
        </span>
      </p>
    )
  }
}
