import 'components/TrendsV2/ChartRenderer.scss'
import { FireFlyIcon } from 'components/FireflyIcon/FireflyIcon'
import 'components/AIMenu/AIMenu.scss'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { PriorityNav } from 'components/PriorityNav/PriorityNav'
import { ActionMenu } from 'components/ActionMenu/ActionMenu'
import classNames from 'classnames'
import { EventAction } from 'components/EventTracker/EventTracker'
import { ChevronIcon } from 'components/MainstayPageContainer/MainstayPageContainer'
import { ContainerButton } from 'components/ContainerButton/ContainerButton'
import { CloseButtonIcon } from 'components/Icons/CloseButtonIcon/CloseButtonIcon'
import { MultipleLinksViewer } from 'components/ContentCard/ContentCard'
import { useCallback, useEffect, useState } from 'react'
import {
  EmbeddedDataSourceType,
  GenAIKBResponseUsage,
  generativeTextService,
} from 'api'
import { isLeft } from 'fp-ts/lib/Either'
import { isNumber } from 'lodash'
import {
  Failure,
  Loading,
  Success,
  WebData,
  WebDataError,
  isFailure,
  isInitial,
  isLoading,
  isSuccess,
} from 'store/webdata'
import * as Raven from '@sentry/browser'
import { KNOWLEDGE_SOURCES } from 'const/routes'
import { Link } from 'react-router-dom'
import { generateLinksFromEmbeddedSources } from 'page/conversations-v2/ConversationsDetail/BotReply/BotReply'
import { LoadingSkeleton } from 'components/LoadingSkeleton/LoadingSkeleton'
const KB_HEAP_EVENT_LOCATION = 'knowledge base'

export enum MenuSize {
  NARROW = 'NARROW',
  WIDE = 'WIDE',
}
export type MenuSizeEnum = typeof MenuSize[keyof typeof MenuSize]

// TODO: Here is where our decided AI Actions should be stored
// Use these temp values for now
export enum AIAction {
  WRITE_QUESTIONS = 'Write 10 Questions',
  WRITE_RESPONSE = 'Write Response',
  CONVERSATION_SUMMARY = 'Summarize conversation',
}

export interface IAIAction {
  action: AIAction
  onClick: () => void
  text: string
}

interface IAIMenuProps {
  size: MenuSize
  actions: IAIAction[]
  className?: string
  menuItemClassName?: string
  overrideButtonGroupStyles?: boolean
  triggerButtonDisabled?: boolean
  eventTrackerObj?: {
    eventLocation?: string
    eventAction?: EventAction
    eventObject?: string
  }
}

export const AIIndicator = ({ className }: { className?: string }) => {
  return (
    <div className={classNames('firefly', className)}>
      <FireFlyIcon />
      <div className="firefly-text">AI</div>
    </div>
  )
}

export const AIMenu = ({
  size,
  actions,
  className,
  menuItemClassName,
  overrideButtonGroupStyles = false,
  triggerButtonDisabled = false,
  eventTrackerObj,
}: IAIMenuProps) => {
  return size === MenuSize.NARROW ? (
    <ActionMenu
      className={classNames(
        { 'disabled-background': triggerButtonDisabled },
        className
      )}
      popoverPlacement="bottom-end"
      removePadding={true}
      stopPropagation={true}
      menuItems={actions.map(action => {
        return {
          label: action.text,
          onSelect: action.onClick,
          icon: undefined,
        }
      })}
      overrideButtonGroupStyles={overrideButtonGroupStyles}
      buttonClassName={menuItemClassName}
      eventLocation={eventTrackerObj?.eventLocation}
      eventAction={eventTrackerObj?.eventAction}
      eventObject={eventTrackerObj?.eventObject}
      trackEventOnTriggerButton={false}
      triggerIcon={
        <div
          className={classNames('d-flex align-items-center ai-menu narrow', {
            'disabled-background disabled-opacity': triggerButtonDisabled,
          })}>
          <AIIndicator className="pr-0" />
          <AHIcon
            className="ai-action-dropdown-icon"
            name="arrow_drop_down"
            overrideWidth={true}
          />
        </div>
      }
    />
  ) : (
    <div className="ai-menu wide d-flex justify-content-between">
      <AIIndicator />
      <PriorityNav className="ai-actions-list d-flex">
        {actions.map(action => (
          <ContainerButton
            key={action.text}
            eventLocation={eventTrackerObj?.eventLocation}
            eventAction={eventTrackerObj?.eventAction}
            eventObject={eventTrackerObj?.eventObject}
            className={classNames('ai-actions-list-item', menuItemClassName)}
            onClick={action.onClick}>
            {action.text}
          </ContainerButton>
        ))}
      </PriorityNav>
    </div>
  )
}

interface IGenAIResponseObj {
  readonly response: WebData<string>
  readonly transaction_id?: number
  readonly embedded_sources?: EmbeddedDataSourceType[]
}

function errorCodeToDisplayComponent(errorCode: WebDataError) {
  if (errorCode === 'not-enough-sources') {
    return (
      <>
        <span>
          No suggested response. Improve suggestions by adding content sources
          to{' '}
        </span>
        <Link to={KNOWLEDGE_SOURCES.INDEX}>Knowledge Scraping</Link>.
      </>
    )
  } else if (errorCode === 'no-knowledge-source') {
    return (
      <>
        <span>
          No Knowledge Sources found. To use this feature, please provide
          content.{' '}
        </span>
        <Link to={KNOWLEDGE_SOURCES.INDEX}>Add Knowledge Sources</Link>
      </>
    )
  } else {
    return (
      <span className="text-danger">
        Unable to generate a response. Please try again.
      </span>
    )
  }
}

const GeneratedResponse = ({ response }: { response: WebData<string> }) => {
  if (isLoading(response) || isInitial(response)) {
    return <LoadingSkeleton rowCount={3} />
  }
  if (isFailure(response)) {
    return errorCodeToDisplayComponent(response.failure)
  }
  if (isSuccess(response)) {
    return <span>{response.data}</span>
  }
  return <></>
}

interface IAiResponseProps {
  summaryQuestion: string
  understandingId: string
  onSelect: (t: string) => void
  onCancel: () => void
}
export const GenAIResponseGenerator = ({
  summaryQuestion,
  understandingId,
  onSelect,
  onCancel,
}: IAiResponseProps) => {
  const [genAIResponseData, setGenAIRepsonseData] = useState<
    IGenAIResponseObj[]
  >([])
  const [currentGenAIIndex, setCurrentGenAIIndex] = useState<number>(0)

  const updateResponseTransactionUsage = async (
    index: number,
    responseUsage: GenAIKBResponseUsage
  ) => {
    const transactionId = genAIResponseData[index].transaction_id
    if (isNumber(transactionId)) {
      const res = await generativeTextService.updateTransaction({
        transactionId,
        responseUsage,
      })
      if (isLeft(res)) {
        Raven.captureException(
          `Error updating gen AI transaction usage. Transaction ID: ${transactionId}, Usage: ${responseUsage}`
        )
        return
      }
    }
  }

  const rejectGenAIOutput = async (index: number) => {
    if (index === 0) {
      setGenAIRepsonseData([...genAIResponseData.slice(1)])
      setCurrentGenAIIndex(genAIResponseData.length - 2) // element right after the one being removed if exists
    } else {
      setGenAIRepsonseData([
        ...genAIResponseData.slice(0, index),
        ...genAIResponseData.slice(index + 1),
      ])
      setCurrentGenAIIndex(currentGenAIIndex - 1) // element right before the one being removed
    }
    await updateResponseTransactionUsage(index, 'dismissed')
  }

  const successfulResponsesCount = genAIResponseData.filter(elem =>
    isSuccess(elem.response)
  ).length

  const askAISummaryQuestion = useCallback(async () => {
    const updateGenAIResponseData = (data: IGenAIResponseObj) => {
      // update the last entry with the `loading` state
      setGenAIRepsonseData(prev => [...prev.slice(0, -1), data])
    }
    setGenAIRepsonseData(prev => [
      ...prev.filter(elem => isSuccess(elem.response)),
      {
        response: Loading(),
      },
    ])
    setCurrentGenAIIndex(successfulResponsesCount)
    const res = await generativeTextService.scrape.getKBResponseFromScrapedContent(
      {
        query: summaryQuestion,
        understandingId,
      }
    )
    if (isLeft(res)) {
      if (
        'http' in res.left &&
        // tslint:disable-next-line no-unsafe-any
        res.left.http.response?.data.detail ===
          'Missing scraped knowledge sources'
      ) {
        updateGenAIResponseData({ response: Failure('no-knowledge-source') })
      } else if (
        'http' in res.left &&
        (res.left.http.response?.status ?? 0 >= 400)
      ) {
        updateGenAIResponseData({ response: Failure('error') })
        Raven.captureException(res.left)
      }
    } else {
      const {
        transaction_id,
        answer,
        confident_in_answer,
        embedded_sources,
      } = res.right

      if (!confident_in_answer) {
        updateGenAIResponseData({
          transaction_id,
          response: Failure('not-enough-sources'),
        })
      } else {
        updateGenAIResponseData({
          transaction_id,
          response: Success(answer),
          embedded_sources,
        })
      }
    }
  }, [successfulResponsesCount, summaryQuestion, understandingId])

  useEffect(() => {
    if (genAIResponseData.length === 0) {
      askAISummaryQuestion()
    }
  }, [askAISummaryQuestion, genAIResponseData.length])

  const currentRepsonse = genAIResponseData[currentGenAIIndex]?.response
  const embeddedSourcesForCurrentResponse =
    genAIResponseData[currentGenAIIndex]?.embedded_sources ?? []

  return (
    <div className="ai-response-container rounded shadow">
      <div className="d-flex align-items-start pt-3 px-3 overflow-scroll">
        <div className="w-100 pr-3">
          <GeneratedResponse
            response={genAIResponseData[currentGenAIIndex]?.response}
          />
        </div>
        <ContainerButton
          className="text-mainstay-dark-blue-80"
          eventLocation={KB_HEAP_EVENT_LOCATION}
          disabled={!isSuccess(currentRepsonse)}
          eventAction="click"
          eventObject="ai response reject"
          onClick={() => rejectGenAIOutput(currentGenAIIndex)}>
          <AHIcon name="thumb_down" />
        </ContainerButton>
      </div>
      <div>
        <div className="d-flex px-3 pb-2">
          {isSuccess(currentRepsonse) &&
            embeddedSourcesForCurrentResponse.length > 0 && (
              <>
                <span className="fs-0_8rem pr-2">Source:</span>{' '}
                <MultipleLinksViewer
                  linkProps={generateLinksFromEmbeddedSources(
                    embeddedSourcesForCurrentResponse
                  )}
                />
              </>
            )}
        </div>
        <hr className="w-100 m-0" />
        <div className="d-flex justify-content-between align-items-center px-3 py-1">
          <div className="d-flex align-items-center ">
            <ContainerButton
              className="pr-2"
              disabled={currentGenAIIndex === 0}
              onClick={() => setCurrentGenAIIndex(prev => prev - 1)}
              eventLocation={KB_HEAP_EVENT_LOCATION}
              eventAction="click"
              eventObject="ai response left">
              <ChevronIcon transform="scale(-1,1)" width="10" height="15" />
            </ContainerButton>
            <ContainerButton
              className="px-2"
              disabled={currentGenAIIndex + 1 === genAIResponseData.length}
              onClick={() => setCurrentGenAIIndex(prev => prev + 1)}
              eventLocation={KB_HEAP_EVENT_LOCATION}
              eventAction="click"
              eventObject="ai response right">
              <ChevronIcon width="10" height="15" />
            </ContainerButton>
            {!!genAIResponseData.length && (
              <span className="mx-1 fs-0_8rem response-counter">
                {currentGenAIIndex + 1} / {genAIResponseData.length}
              </span>
            )}
          </div>
          <ContainerButton
            className="ai-actions-list-item h-30px"
            disabled={genAIResponseData.some(({ response }) =>
              isLoading(response)
            )}
            onClick={askAISummaryQuestion}
            eventLocation={KB_HEAP_EVENT_LOCATION}
            eventAction="click"
            eventObject="ai response write">
            <span>Write new response</span>
          </ContainerButton>
          <div className="d-flex align-items-center">
            <ContainerButton
              className="px-2"
              onClick={onCancel}
              eventLocation={KB_HEAP_EVENT_LOCATION}
              eventAction="click"
              eventObject="ai response close">
              <CloseButtonIcon className="fill-mainstay-dark-blue-80" />
            </ContainerButton>
            <ContainerButton
              className="action-btn fs-1_5rem pl-2 ahicon-container-btn"
              disabled={!isSuccess(currentRepsonse)}
              onClick={() => {
                onCancel()
                if (!isSuccess(currentRepsonse)) {
                  return
                }
                onSelect(currentRepsonse.data)
              }}
              eventLocation={KB_HEAP_EVENT_LOCATION}
              eventAction="click"
              eventObject="ai response accept">
              <AHIcon name="check" className="text-secondary-teal" />
            </ContainerButton>
          </div>
        </div>
      </div>
    </div>
  )
}
