import * as React from 'react'
import ClassNames from 'classnames'
import 'components/ProgressBar/ProgressBar.scss'

export interface IProgressBarItem {
  value: number
  maxValue: number
  className?: string
}

export class ProgressBarItem extends React.PureComponent<IProgressBarItem> {
  static defaultProps = {
    className: 'bg-primary',
  }

  render() {
    const { className, value, maxValue } = this.props

    return (
      <div
        className={ClassNames('progress-bar', className)}
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={maxValue}
        style={{ width: `${(value / maxValue) * 100}%` }}
      />
    )
  }
}

export interface IProgressBarProps {
  className?: string
  loading?: boolean
}

export class ProgressBar extends React.PureComponent<IProgressBarProps> {
  static defaultProps = {
    className: 'bg-blue-grey-050',
  }

  render() {
    const { className, loading } = this.props

    return (
      <div
        className={ClassNames(
          {
            progress: !loading,
            'progress-placeholder bg-blue-grey-010': loading,
          },
          className
        )}>
        {this.props.children}
      </div>
    )
  }
}

export interface IProgressBarLegendProps {
  progressBarLegendItems: ILabeledProgressBarItem[]
  totalCountLegendItem?: ILabeledProgressBarItem
}

export class ProgressBarLegend extends React.PureComponent<
  IProgressBarLegendProps
> {
  render() {
    const { progressBarLegendItems, totalCountLegendItem } = this.props
    return (
      <div>
        {progressBarLegendItems.map(b => {
          return (
            <div
              key={b.label}
              className="d-flex justify-content-between align-items-center mb-2">
              <div className="d-flex align-items-center">
                <div
                  className={ClassNames(
                    'rounded',
                    'mr-2',
                    'key-square',
                    b.className
                  )}
                />
                <div>{b.label}</div>
              </div>
              <div>{b.value}</div>
            </div>
          )
        })}
        {!!totalCountLegendItem && (
          // render a legend item for total count if provided
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="d-flex align-items-center">
              <div
                className={ClassNames(
                  'progress',
                  'rounded',
                  'mr-2',
                  'key-square',
                  totalCountLegendItem.className
                )}
              />
              <div>{totalCountLegendItem.label}</div>
            </div>
            <div>{totalCountLegendItem.value}</div>
          </div>
        )}
      </div>
    )
  }
}

export interface ILabeledProgressBarItem extends IProgressBarItem {
  label: string
}

export interface IProgressBarCollectionProps {
  items: ILabeledProgressBarItem[]
}

export class GroupedProgressBars extends React.PureComponent<
  IProgressBarCollectionProps
> {
  render() {
    return (
      <>
        {this.props.items.map(item => {
          return (
            <div key={item.label} className="pb-2">
              <div className="d-flex align-items-center my-2">
                <ProgressBar className="bg-blue-grey-050 flex-grow-1">
                  <ProgressBarItem
                    className={item.className}
                    maxValue={item.maxValue}
                    value={item.value}
                  />
                </ProgressBar>
                <span className="ml-3">{item.value}</span>
              </div>
              <div>{item.label}</div>
            </div>
          )
        })}
      </>
    )
  }
}

export class StackedProgressBars extends React.PureComponent<
  IProgressBarCollectionProps
> {
  render() {
    const { items } = this.props
    return (
      <div>
        <ProgressBar className="bg-blue-grey-050 my-2">
          {items.map(item => {
            return (
              <ProgressBarItem
                className={item.className}
                maxValue={item.maxValue}
                value={item.value}
                key={item.label}
              />
            )
          })}
        </ProgressBar>
        <ProgressBarLegend progressBarLegendItems={items} />
      </div>
    )
  }
}
