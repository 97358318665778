import {
  IUserActions,
  fetchUsers,
  addUser,
  resendUserInvite,
  removeUser,
} from 'store/triage/users/actions'
import { getType } from 'typesafe-actions'
import { omit, uniq } from 'lodash'

export interface IUser {
  id: string
  emails: Array<{
    address: string
    verified: boolean | undefined
  }>
  admitHubUser: boolean
  engineeringEmployee: boolean
  currentInstitution?: string
  profile: {
    name: string
    firstName?: string | null
    lastName?: string | null
  }
  roles?: {
    [institutionId: string]: {
      allowed?: boolean
      userGroup?: string
    }
  }
  region?: string | null
  lastLoginAt?: string | null
  permissionRoles?: string[]
}

interface IUserState {
  allIds: string[]
  byId: {
    [id: string]: IUser
  }
  loading: boolean
  addingUser: boolean
  removingUser: boolean
  resendingUserInvite: boolean
}

const initialState = {
  allIds: [],
  byId: {},
  loading: false,
  addingUser: false,
  removingUser: false,
  resendingUserInvite: false,
}

const reducer = (
  state: IUserState = initialState,
  action: IUserActions
): IUserState => {
  switch (action.type) {
    case getType(fetchUsers.request): {
      return {
        ...state,
        loading: true,
      }
    }
    case getType(fetchUsers.success): {
      return {
        ...state,
        loading: false,
        allIds: action.payload.map(a => a.id),
        byId: action.payload.reduce(
          (obj: { [id: string]: IUser }, u: IUser) => {
            obj[u.id] = u
            return obj
          },
          {}
        ),
      }
    }
    case getType(fetchUsers.failure): {
      return {
        ...state,
        loading: false,
      }
    }
    case getType(addUser.request): {
      return {
        ...state,
        addingUser: true,
      }
    }
    case getType(addUser.success): {
      return {
        ...state,
        addingUser: false,
        allIds: uniq([...state.allIds, action.payload.id]),
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
      }
    }
    case getType(addUser.failure): {
      return {
        ...state,
        addingUser: false,
      }
    }
    case getType(removeUser.request): {
      return {
        ...state,
        removingUser: true,
      }
    }
    case getType(removeUser.success): {
      return {
        ...state,
        removingUser: false,
        allIds: state.allIds.filter(x => x !== action.payload),
        byId: omit(state.byId, [action.payload]),
      }
    }
    case getType(removeUser.failure): {
      return {
        ...state,
        removingUser: false,
      }
    }
    case getType(resendUserInvite.request): {
      return {
        ...state,
        resendingUserInvite: true,
      }
    }
    case getType(resendUserInvite.success):
    case getType(resendUserInvite.failure): {
      return {
        ...state,
        resendingUserInvite: false,
      }
    }
    default:
      return state
  }
}

export default reducer
