import * as t from 'store/scheduledCampaigns/actionTypes'
import { uniq } from 'lodash'
import { IScheduledCampaignsActions } from 'store/scheduledCampaigns/actions'
import {
  ChangedOccurrenceTypeEnum,
  IRecurrenceSettings,
} from 'store/campaign-scheduler/reducer'

export interface IScheduledCampaign {
  readonly id: string
  readonly name: string
  readonly description: string
  readonly messagingService: string
  readonly on: string
  readonly campaign: string
  readonly deleting?: boolean
  readonly importReportId: string | null
  readonly recipientLabel: string | null
  readonly started: boolean | null
  readonly region: string
  readonly workflowHumanName: string
  readonly filterName: string | null
  readonly recurring: boolean
  readonly recurrenceSettings: IRecurrenceSettings
  readonly contactFilter?: number | undefined
  readonly completedRecurringCampaigns: { [date: string]: string }
}

interface IScheduledCampaignsState {
  readonly isLoadingScheduledCampaigns: boolean
  readonly isErrorLoadingScheduledCampaigns: boolean
  readonly allIds: Array<string>
  campaignsById: { [key: string]: IScheduledCampaign }
}

const defaultState: IScheduledCampaignsState = {
  isLoadingScheduledCampaigns: false,
  isErrorLoadingScheduledCampaigns: false,
  allIds: [],
  campaignsById: {},
}

export enum DeleteRecurringCampaignTypeEnum {
  all = 'all',
  futureOccurrences = 'future',
  occurrence = 'occurrence',
}

// TODO(sbdchd): refactor to use createAsyncAction()

const schedueldCampaigns = (
  state = defaultState,
  action: IScheduledCampaignsActions
) => {
  switch (action.type) {
    case t.SET_LOADING_SCHEDULED_CAMPAIGNS:
      return {
        ...state,
        isLoadingScheduledCampaigns: action.payload,
      }
    case t.SET_ERROR_LOADING_SCHEDULED_CAMPAIGNS:
      return {
        ...state,
        isErrorLoadingScheduledCampaigns: action.payload,
      }
    case t.SET_SCHEDULED_CAMPAIGNS:
      return {
        ...state,
        campaignsById: {
          ...action.payload.reduce(
            (acc, scheduledCampaign) => ({
              ...acc,
              [scheduledCampaign.id]: scheduledCampaign,
            }),
            {}
          ),
        },
        allIds: uniq(state.allIds.concat(action.payload.map(x => x.id))),
      }
    case t.DELETE_SCHEDULED_CAMPAIGN:
      return {
        ...state,
        allIds: state.allIds.filter(id => id !== action.payload),
      }
    case t.DELETE_RECURRING_CAMPAIGN:
      const campaignToUpdate = state.campaignsById[action.payload.id]
      if (action.payload.date) {
        if (
          action.payload.type === DeleteRecurringCampaignTypeEnum.occurrence
        ) {
          campaignToUpdate?.recurrenceSettings?.changedOccurrences?.push({
            date: action.payload.date,
            type: ChangedOccurrenceTypeEnum.deleted,
          })
        } else if (
          action.payload.type ===
          DeleteRecurringCampaignTypeEnum.futureOccurrences
        ) {
          // Use the date the backend calculates and returns as the new end date
          if (action.payload.data?.new_end_date) {
            campaignToUpdate.recurrenceSettings.endDate =
              action.payload.data.new_end_date
          }
        }
        return {
          ...state,
          ...campaignToUpdate,
        }
      }
    default:
      return state
  }
}

export default schedueldCampaigns
