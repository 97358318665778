import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import {
  CombinationOperator,
  IContactFilterFormRow,
} from 'components/ContactFilterBuilder/formUtils'
import classnames from 'classnames'

interface IAddRemoveRowProps {
  row: IContactFilterFormRow
  onAddRow?: (parentCombination: CombinationOperator, level: number) => void
  onDeleteRow?: () => void
}

export const AddRemoveRow = ({
  row,
  onAddRow,
  onDeleteRow,
}: IAddRemoveRowProps) => {
  return (
    <div className="d-flex flex-row">
      <AHIcon
        name="remove_circle"
        className={classnames('text-blue-grey-500', {
          'disabled-icon': !onDeleteRow,
        })}
        onClick={onDeleteRow}
      />
      <AHIcon
        name="add_circle"
        className={classnames('text-blue-grey-500', {
          'disabled-icon': !onAddRow,
        })}
        onClick={() => {
          if (row.parentCombination && onAddRow) {
            onAddRow(row.parentCombination, row.level)
          }
        }}
      />
    </div>
  )
}
