import * as Api from 'api'
import { IInstitutionAttributeResponseData } from 'api/response'
import {
  createAsyncAction,
  ActionType,
  createStandardAction,
} from 'typesafe-actions'
import { IAttributeTypeCounter } from 'store/personalization/institutionAttributes/selectors'

export const getInstitutionAttributesTable = createAsyncAction(
  '@@MASCOT/PERSONALIZATION/GET_INSTITUTION_ATTRIBUTES_START',
  '@@MASCOT/PERSONALIZATION/GET_INSTITUTION_ATTRIBUTES_SUCCESS',
  '@@MASCOT/PERSONALIZATION/GET_INSTITUTION_ATTRIBUTES_FAILURE'
)<void, [Array<IInstitutionAttributeResponseData>, boolean], Api.IApiError>()

export const getAllInstitutionAttributes = createAsyncAction(
  '@@MASCOT/PERSONALIZATION/GET_ALL_INSTITUTION_ATTRIBUTES_START',
  '@@MASCOT/PERSONALIZATION/GET_ALL_INSTITUTION_ATTRIBUTES_SUCCESS',
  '@@MASCOT/PERSONALIZATION/GET_ALL_INSTITUTION_ATTRIBUTES_FAILURE'
)<void, IInstitutionAttributeResponseData[], Api.IApiError>()

export const getInstitutionAttrsCount = createAsyncAction(
  '@@MASCOT/PERSONALIZATION/GET_INSTITUTION_ATTRS_COUNT_START',
  '@@MASCOT/PERSONALIZATION/GET_INSTITUTION_ATTRS_COUNT_SUCCESS',
  '@@MASCOT/PERSONALIZATION/GET_INSTITUTION_ATTRS_COUNT_FAILURE'
)<void, number, Api.IApiError>()

export const getInstitutionAttrsCountByType = createAsyncAction(
  '@@MASCOT/PERSONALIZATION/GET_INSTITUTION_ATTRS_COUNT_BY_TYPE_START',
  '@@MASCOT/PERSONALIZATION/GET_INSTITUTION_ATTRS_COUNT_BY_TYPE_SUCCESS',
  '@@MASCOT/PERSONALIZATION/GET_INSTITUTION_ATTRS_COUNT_BY_TYPE_FAILURE'
)<void, IAttributeTypeCounter, Api.IApiError>()

export const updateAttributesPageNumber = createStandardAction(
  '@@MASCOT/PERSONALIZATION/UPDATE_PAGE_NUMBER'
)<number>()

export type IPersonalizationActions = ActionType<
  | typeof getInstitutionAttributesTable
  | typeof getAllInstitutionAttributes
  | typeof updateAttributesPageNumber
  | typeof getInstitutionAttrsCount
  | typeof getInstitutionAttrsCountByType
>
