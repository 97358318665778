import { IWebChatActions, getBotAction } from 'store/embed/actions'
import { getType } from 'typesafe-actions'
import { IWebBotMeta } from 'embed/WebChatInner'

type IWebChatContainerState =
  | { readonly status: 'loading'; readonly webBotMeta: null }
  | { readonly status: 'failure'; readonly webBotMeta: null }
  | { readonly status: 'success'; readonly webBotMeta: IWebBotMeta }

const INITIAL_STATE: IWebChatContainerState = {
  webBotMeta: null,
  status: 'loading',
}

const reducer = (
  state: IWebChatContainerState = INITIAL_STATE,
  action: IWebChatActions
): IWebChatContainerState => {
  switch (action.type) {
    case getType(getBotAction.request): {
      return {
        ...state,
        status: 'loading',
        webBotMeta: null,
      }
    }
    case getType(getBotAction.success): {
      return {
        ...state,
        webBotMeta: {
          ...action.payload,
          // convert from image being optionally null to being optionally undefined
          image: action.payload.image || undefined,
          quickMenu: action.payload.quickMenu || [],
        },
        status: 'success',
      }
    }
    case getType(getBotAction.failure): {
      return {
        ...state,
        status: 'failure',
        webBotMeta: null,
      }
    }
    // TODO (Manan) handle error state that notifies the iframe's parent via an event
    default:
      return state
  }
}

export default reducer
