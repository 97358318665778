import { Http2ErrorUnion } from 'api/http'
import classNames from 'classnames'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { CollapsibleSection } from 'components/CollapsibleSection/CollapsibleSection'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { CenteredLoader } from 'components/Loader/Loader'
import { TextInput } from 'components/TextInput/TextInput'
import Tooltip from 'components/Tooltip/Tooltip'
import 'components/TrendsV2/CustomizedDownloadModal.scss'
import { Field, FieldProps, Formik, FormikProps, yupToFormErrors } from 'formik'
import { Either, isRight } from 'fp-ts/lib/Either'
import orderBy from 'lodash/orderBy'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import pluralize from 'pluralize'
import React from 'react'
import * as nope from 'yup'
import { Radio } from 'components/MaterialComponents/MaterialComponents'
import { FloatingLabelSelect } from 'components/SettingsEscalation/reactSelectUtils'
import { isNull, range } from 'lodash'
import { format } from 'date-fns'
import { MainstayModal, ModalSize } from 'components/Modal/Modal'

const SELECT_MENU_Z_INDEX = 9999
export interface IAutoLogoutModalProps {
  show: boolean
  onClose: () => void
  updateLoginExpiration: () => void
  logout: () => void
  onLogout: () => void
}

interface ICustomDownloadModalField<T> {
  id: T
  displayName: string
  checked: boolean
  readonly: boolean
}

interface ICustomDownloadModalFormData {
  reportFileName: string
  shouldSavePreferences: boolean
  topLevel: { fields: ICustomDownloadModalField<string>[] }
  reportSpecific: { fields: ICustomDownloadModalField<string>[] }
  contactAttributes: {
    fields: ICustomDownloadModalField<number>[]
    includeEmpty: boolean
  }
  includeRecentCampaignsCount: number | null
}

interface IAllOrNoneButtonProps {
  onClickAll: () => void
  onClickNone: () => void
  checkAllButtonDisabled?: boolean
  checkNoneButtonDisabled?: boolean
}

const AllOrNoneButton = ({
  onClickAll,
  onClickNone,
  checkAllButtonDisabled,
  checkNoneButtonDisabled,
}: IAllOrNoneButtonProps) => {
  const defaultClassName = 'p-0 mx-1 d-flex bg-transparent align-items-center'
  return (
    <div className="d-flex flex-row">
      Select:
      <Button
        className={classNames(defaultClassName, {
          'text-secondary-teal hover-text-mainstay-teal': !checkAllButtonDisabled,
        })}
        onClick={e => {
          e.stopPropagation()
          onClickAll()
        }}
        disabled={checkAllButtonDisabled}>
        All{' '}
      </Button>
      <span className="d-inline-block text-mainstay-dark-blue-20"> | </span>
      <Button
        onClick={e => {
          e.stopPropagation()
          onClickNone()
        }}
        className={classNames(defaultClassName, {
          'text-secondary-teal hover-text-mainstay-teal': !checkNoneButtonDisabled,
        })}
        disabled={checkNoneButtonDisabled}>
        {' '}
        None{' '}
      </Button>
    </div>
  )
}

const FieldsSelector = ({
  fields,
  sortFields,
  onChange,
  includeEmptyCheckboxComponent,
}: {
  fields: ICustomDownloadModalField<string | number>[]
  sortFields?: boolean
  onChange: (field: number | string) => void
  includeEmptyCheckboxComponent?: React.ReactNode
}) => {
  const reportFields = sortFields
    ? orderBy(fields, [attr => attr.displayName.toLowerCase()], ['asc'])
    : fields
  return (
    <div className="wrapper">
      {reportFields.map(props => (
        <ReportField key={props.id} onChange={onChange} {...props} />
      ))}
      {includeEmptyCheckboxComponent}
    </div>
  )
}

const ReportField = ({
  id,
  displayName,
  checked,
  readonly,
  onChange,
}: ICustomDownloadModalField<string | number> & {
  onChange: (field: number | string) => void
}) => {
  return (
    <Checkbox
      id={String(id)}
      type="check"
      titleComponent={
        <div
          className={classNames('px-2 text-ellipsis field-name', {
            'text-muted': readonly,
          })}>
          <span>{displayName}</span>
        </div>
      }
      containerClassName="py-2"
      checked={checked}
      disabled={readonly}
      onClick={() => onChange(id)}
    />
  )
}

export const CollapsibleFieldsSection = ({
  name,
  title,
  includeEmptyCheckboxComponent,
}: {
  name: 'topLevel' | 'contactAttributes' | 'reportSpecific'
  title: string
  includeEmptyCheckboxComponent?: React.ReactNode
}) => {
  return (
    <Field
      name={name}
      render={({ form }: FieldProps<ICustomDownloadModalFormData>) => {
        const enableCheckNoneButton = form.values[name].fields.some(
          ({ readonly, checked }) => !readonly && checked
        )
        const enableCheckAllButton = form.values[name].fields.some(
          ({ readonly, checked }) => !readonly && !checked
        )
        return (
          <CollapsibleSection
            title={title}
            headerChildren={
              <AllOrNoneButton
                onClickAll={() =>
                  form.setFieldValue(name, {
                    ...form.values[name],
                    fields: form.values[name].fields.map(field => ({
                      ...field,
                      checked: true,
                    })),
                    ...(name === 'contactAttributes' && { includeEmpty: true }),
                  })
                }
                onClickNone={() =>
                  form.setFieldValue(name, {
                    ...form.values[name],
                    fields: form.values[name].fields.map(field => ({
                      ...field,
                      checked: field.readonly,
                    })),
                    ...(name === 'contactAttributes' && {
                      includeEmpty: false,
                    }),
                  })
                }
                checkAllButtonDisabled={!enableCheckAllButton}
                checkNoneButtonDisabled={!enableCheckNoneButton}
              />
            }>
            <FieldsSelector
              fields={form.values[name].fields}
              sortFields={name === 'contactAttributes'}
              includeEmptyCheckboxComponent={includeEmptyCheckboxComponent}
              onChange={(fieldId: string | number) => {
                form.setFieldValue(name, {
                  ...form.values[name],
                  fields: form.values[name].fields.map(field => {
                    if (field.id === fieldId) {
                      return {
                        ...field,
                        checked: !field.checked,
                      }
                    }
                    return field
                  }),
                })
              }}
            />
          </CollapsibleSection>
        )
      }}
    />
  )
}

interface IOption {
  label: number
  value: number
}

const RadioGroup = ({
  selectOption,
  numberOfRecurringCampaigns,
  onChange,
}: {
  selectOption: number | null
  numberOfRecurringCampaigns: number
  onChange: (v: number | null) => void
}) => {
  const options = range(1, numberOfRecurringCampaigns).map(v => ({
    value: v,
    label: v,
  }))
  const [
    enableCampaignCountSelect,
    setEnableCampaignCountSelect,
  ] = React.useState(!!selectOption)

  React.useEffect(() => {
    setEnableCampaignCountSelect(!!selectOption)
  }, [selectOption])

  const isAllCampaignsSelected =
    isNull(selectOption) && !enableCampaignCountSelect

  return (
    <div className="wrapper">
      <div className="d-flex align-items-center">
        <Radio
          color="primary"
          className="px-0 history-radio"
          value="custom"
          checked={isAllCampaignsSelected}
          onClick={() => {
            onChange(null)
            setEnableCampaignCountSelect(false)
          }}
        />
        <span className="px-2">All</span>
      </div>
      <div className="d-flex align-items-center">
        <Radio
          color="primary"
          className="px-0 history-radio"
          value="custom"
          checked={!isAllCampaignsSelected}
          onClick={() => {
            onChange(selectOption ?? 1)
            setEnableCampaignCountSelect(true)
          }}
        />
        <div className="px-2">Last</div>
        <FloatingLabelSelect<IOption>
          options={options}
          isDisabled={!enableCampaignCountSelect}
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          onChange={option => onChange((option as IOption).value)}
          value={options.find(({ value }) => value === selectOption)}
          classNamePrefix="react-select"
          placeholder=""
          menuZIndex={SELECT_MENU_Z_INDEX}
          defaultValue={options?.[0]}
          className="history-count-select"
        />
        <div className="px-2">Campaigns</div>
      </div>
    </div>
  )
}

const CollapsibleCampaignHistorySection = ({
  name,
  title,
  countCampaignsSent,
}: {
  name: 'topLevel' | 'contactAttributes' | 'reportSpecific'
  title: string
  countCampaignsSent: number
}) => {
  return (
    <Field
      name={name}
      render={({ form }: FieldProps<ICustomDownloadModalFormData>) => (
        <CollapsibleSection title={title}>
          <RadioGroup
            selectOption={form.values.includeRecentCampaignsCount ?? null}
            onChange={v => form.setFieldValue('includeRecentCampaignsCount', v)}
            numberOfRecurringCampaigns={countCampaignsSent}
          />
        </CollapsibleSection>
      )}
    />
  )
}

export interface IReportPreference {
  include_empty_custom_fields: boolean
  recurring_campaigns_to_include: number | null
  custom_fields: {
    checked: boolean
    id: number
    name: string
  }[]
  top_level_fields: {
    checked: boolean
    id: string
    name: string
    // readonly fields are checked by default and cannot be unchecked
    readonly: boolean
  }[]
  report_specific_fields: {
    checked: boolean
    name: string
  }[]
  empty_custom_fields: number[]
}

const filterCheckedFields = (
  fields: ICustomDownloadModalField<string | number>[]
) => {
  return fields.filter(({ checked }) => checked === true).map(field => field.id)
}

export interface IPreferencesRequestPayload {
  topLevel?: {
    fields: (string | number)[]
  }
  contactAttributes?: {
    fields: (string | number)[]
    includeEmpty: boolean
  }
  reportSpecific?: {
    fields: (string | number)[]
  }
  reportFileName?: string
  shouldSavePreferences?: boolean
  emptyCustomFields?: number[]
  startDate?: Date | null
  endDate?: Date | null
  range?: string
}

const mapFormDataToRequestPayload = (
  formData: ICustomDownloadModalFormData
): Omit<IPreferencesRequestPayload, 'emptyCustomFields'> => {
  return {
    ...formData,
    topLevel: {
      ...formData.topLevel,
      fields: filterCheckedFields(formData.topLevel.fields),
    },
    contactAttributes: {
      ...formData.contactAttributes,
      fields: filterCheckedFields(formData.contactAttributes.fields),
    },
    reportSpecific: {
      ...formData.reportSpecific,
      fields: filterCheckedFields(formData.reportSpecific.fields),
    },
  }
}

export interface ICustomDownloadModalProps {
  initialFileName: string
  reportTitle: string
  show: boolean
  submissionInProgress: boolean
  getPreferenceData: () => Promise<Either<Http2ErrorUnion, IReportPreference>>
  onClose: () => void
  onSubmit: (preferencesRequestData: IPreferencesRequestPayload) => void
  onFormError?: () => void
  eventLocation?: string
  eventObject?: string
  reportSpecificFieldsSection?: React.ReactNode
}

export const CustomCampaignReportDownloadModal = ({
  countCampaignsSent,
  isAggregate,
  initialFileName,
  hideCampaignHistory,
  ...restProps
}: ICustomDownloadModalProps & {
  isAggregate: boolean
  countCampaignsSent?: number
  hideCampaignHistory?: boolean
}) => {
  const reportFileName = `${initialFileName}${isAggregate ? '-agg' : ''}`
  return (
    <CustomDownloadModal
      {...restProps}
      initialFileName={reportFileName}
      reportSpecificFieldsSection={
        <>
          <CollapsibleFieldsSection
            name="reportSpecific"
            title="Campaign Fields"
          />
          {!hideCampaignHistory && isAggregate && countCampaignsSent && (
            <CollapsibleCampaignHistorySection
              name="reportSpecific"
              title="Campaign History"
              countCampaignsSent={countCampaignsSent}
            />
          )}
        </>
      }
    />
  )
}

export const CustomInsightsReportDownloadModal = (
  props: ICustomDownloadModalProps
) => {
  return (
    <CustomDownloadModal
      {...props}
      reportSpecificFieldsSection={
        <CollapsibleFieldsSection name="reportSpecific" title="Report Fields" />
      }
    />
  )
}

export const CustomDownloadModal = ({
  initialFileName,
  reportTitle,
  show,
  submissionInProgress,
  getPreferenceData,
  onClose,
  onSubmit,
  onFormError,
  eventLocation,
  eventObject,
  reportSpecificFieldsSection,
}: ICustomDownloadModalProps) => {
  const [showFooterShadow, setShowFooterShadow] = React.useState(false)
  const bodyRef = React.useRef<HTMLDivElement>(null)
  function updateShadow() {
    if (bodyRef && bodyRef.current) {
      setShowFooterShadow(
        bodyRef.current.scrollHeight > bodyRef.current.clientHeight
      )
    }
  }
  React.useLayoutEffect(() => {
    updateShadow()
    if (bodyRef && bodyRef.current) {
      window.addEventListener('resize', updateShadow)
    }
    return () => window.removeEventListener('resize', updateShadow)
  }, [bodyRef])

  const [preference, setPreference] = React.useState<IReportPreference>()
  const reportHasContactFields = !!preference?.top_level_fields.length

  React.useEffect(() => {
    if (show) {
      getPreferenceData().then(res => {
        if (isRight(res)) {
          setPreference(res.right)
          setShowFooterShadow(true)
        } else {
          onClose()
          toast('Problem fetching preferences', { type: 'error' })
        }
      })
    }
    return () => setPreference(undefined)
  }, [getPreferenceData, show, onClose])

  const validationSchema = nope.object().shape({
    reportFileName: nope
      .string()
      .max(120, 'File name is too long')
      .trim()
      .required('File name cannot be blank'),
  })

  const eventObjectPrefix =
    (eventObject ? eventObject + ' ' : '') + 'download report modal '

  return (
    <Formik<ICustomDownloadModalFormData>
      enableReinitialize
      initialValues={{
        reportFileName: initialFileName,
        shouldSavePreferences: false,
        topLevel: {
          fields: (preference?.top_level_fields ?? []).map(_field => ({
            id: _field.id,
            displayName: _field.name,
            checked: _field.checked,
            readonly: _field.readonly,
          })),
        },
        reportSpecific: {
          fields: (preference?.report_specific_fields ?? []).map(_field => ({
            id: _field.name,
            displayName: _field.name,
            checked: _field.checked,
            readonly: false,
          })),
        },
        contactAttributes: {
          fields: (preference?.custom_fields ?? []).map(_field => ({
            id: _field.id,
            displayName: _field.name,
            checked: _field.checked,
            readonly: false,
          })),
          includeEmpty: !!preference?.include_empty_custom_fields,
        },
        includeRecentCampaignsCount:
          preference?.recurring_campaigns_to_include ?? null,
      }}
      validate={(values: ICustomDownloadModalFormData) =>
        validationSchema.validate(values, { abortEarly: false }).catch(err => {
          onFormError?.()
          throw yupToFormErrors(err)
        })
      }
      onSubmit={formData =>
        onSubmit({
          ...mapFormDataToRequestPayload(formData),
          emptyCustomFields: preference?.empty_custom_fields ?? [],
        })
      }
      render={(formikProps: FormikProps<ICustomDownloadModalFormData>) => {
        const { errors } = formikProps
        const hasErrors = errors && Object.keys(errors).length > 0
        updateShadow()
        return (
          <MainstayModal
            wrapContentInForm
            hideFooter
            className="custom-download-modal"
            size={ModalSize.Large}
            contentClassName="p-0"
            show={show}
            onClose={onClose}
            cancelTrackingEvent={{
              location: eventLocation,
              action: 'click',
              object: eventObjectPrefix + 'x',
            }}
            text={`Download ${reportTitle}`}>
            <div
              ref={bodyRef}
              className={classNames('custom-download-body pb-4', {
                'scroll-shadow': showFooterShadow,
              })}>
              {!!preference ? (
                <div className="px-4">
                  <Field
                    name="reportFileName"
                    render={({
                      form,
                      field,
                    }: FieldProps<ICustomDownloadModalFormData>) => (
                      <div className="mb-4">
                        <div className="text-mainstay-dark-blue-80 mb-2 pb-1 pt-3">
                          Add custom name to the report? (optional)
                        </div>
                        <TextInput
                          {...field}
                          eventLocation={eventLocation}
                          eventAction="change"
                          eventObject={eventObjectPrefix + 'custom name'}
                          id="reportFileName"
                          className="w-100 max-width-450"
                          onChange={event => {
                            field.onChange(event)
                          }}
                          error={!!form.errors.reportFileName}
                        />
                        {form.errors.reportFileName &&
                          form.touched.reportFileName && (
                            <div className="text-danger small">
                              {form.errors.reportFileName}
                            </div>
                          )}
                      </div>
                    )}
                  />
                  <div className="text-mainstay-dark-blue-80 my-2 pt-3 pb-1">
                    Select fields to include in the report.
                  </div>
                  {reportHasContactFields && (
                    <>
                      <CollapsibleFieldsSection
                        name="topLevel"
                        title="Default Fields"
                      />
                      <CollapsibleFieldsSection
                        name="contactAttributes"
                        title="Custom Fields"
                        includeEmptyCheckboxComponent={
                          <Field
                            name="includeEmpty"
                            render={({
                              form,
                            }: FieldProps<ICustomDownloadModalFormData>) => (
                              <Checkbox
                                id="include_empty_fields"
                                type="check"
                                titleComponent={
                                  <div className="d-flex align-items-end pl-2">
                                    <span className="fw-600 space-after">
                                      Empty Fields{' '}
                                    </span>
                                    ({preference?.empty_custom_fields.length}){' '}
                                    <Tooltip content="These report fields contain no data in this instance.">
                                      <div>
                                        <AHIcon
                                          name="help"
                                          className="ml-1 text-mainstay-dark-blue-65 help-icon"
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                }
                                containerClassName="py-2"
                                checked={
                                  form.values.contactAttributes.includeEmpty
                                }
                                onClick={() => {
                                  form.setFieldValue('contactAttributes', {
                                    ...form.values.contactAttributes,
                                    includeEmpty: !form.values.contactAttributes
                                      .includeEmpty,
                                  })
                                }}
                              />
                            )}
                          />
                        }
                      />
                    </>
                  )}
                  {reportSpecificFieldsSection}
                </div>
              ) : (
                <div className="my-5 py-5 d-flex justify-content-center flex-grow-1 align-items-center w-100">
                  <CenteredLoader className="px-5" />
                </div>
              )}
            </div>
            <div className="w-100 py-2 d-flex justify-content-between px-4">
              <Field
                name="shouldSavePreferences"
                render={({
                  form,
                }: FieldProps<ICustomDownloadModalFormData>) => (
                  <Checkbox
                    id="shouldSavePreferences"
                    disabled={!preference}
                    type="check"
                    title={`Save selection as default for ${pluralize(
                      reportTitle.toLowerCase()
                    )}`}
                    checked={form.values.shouldSavePreferences}
                    onChange={() => {
                      form.setFieldValue(
                        'shouldSavePreferences',
                        !form.values.shouldSavePreferences
                      )
                    }}
                  />
                )}
              />
              <div className="d-flex">
                <Button
                  eventLocation={eventLocation}
                  eventAction="click"
                  eventObject={eventObjectPrefix + 'download report'}
                  type="submit"
                  disabled={!preference || !!hasErrors || submissionInProgress}
                  className="submit-btn mr-3 btn btn-secondary-teal px-3 py-2">
                  {submissionInProgress ? (
                    <CenteredLoader size="small" className="px-5" />
                  ) : (
                    <span>Download Report</span>
                  )}
                </Button>
                <Button
                  eventLocation={eventLocation}
                  eventAction="click"
                  eventObject={eventObjectPrefix + 'cancel'}
                  className="text-mainstay-dark-blue-65 border-mainstay-dark-blue-65 bg-white px-3 py-2"
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </MainstayModal>
        )
      }}
    />
  )
}

export function generateReportFileName(reportName: string) {
  return `${format(new Date(), 'MMddyyyy-HH-mm')}_${reportName}`
}
