import { Link } from 'util/routing'
import { INTERNAL } from 'const/routes'
import 'components/AdmithubUserMenu/AdmithubUserMenu.scss'
import { ScriptLibraryShow } from 'components/ScriptLibraryConditionalRenderer/ScriptLibraryConditionalRenderer'
import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import { useSelector } from 'util/hooks'
import { getCanAuditUsers } from 'store/triage/profile/selectors'
import { Feature, FeaturesType } from 'components/Feature/Feature'
const AdmithubUserMenu = () => {
  const canAuditUsers = useSelector(getCanAuditUsers)
  return (
    <div className="admithub-menu">
      <small className="text-blue-grey-300">Admin Menu</small>
      <hr className="horizontal-line-blue-grey-300" />
      <ul className="list-unstyled">
        <li>
          <Link rawLink to={INTERNAL.DATA_PARTY}>
            Data Party
          </Link>
        </li>
        <ScriptLibraryShow
          render={() => (
            <li>
              <Link to={INTERNAL.SCRIPT_LIBRARY_ADMIN}>
                Script Collections Admin
              </Link>
            </li>
          )}
        />
      </ul>
      <small className="text-blue-grey-300">Internal Tools</small>
      <hr className="horizontal-line-blue-grey-300" />
      <ul className="list-unstyled">
        <li>
          <Link to={INTERNAL.MAP_ATTRIBUTES}>
            Map Attributes Between Institutions
          </Link>
        </li>
        <AdmithubOnly allowStaffOnly>
          <li>
            <Link to={INTERNAL.DELETE_BOT}>Remove Institution Data</Link>
          </li>
        </AdmithubOnly>
        <AdmithubOnly allowStaffOnly>
          <li>
            <Link to={INTERNAL.UPLOAD_KNOWLEDGE_BASE}>
              Upload Knowledge Base Data
            </Link>
          </li>
        </AdmithubOnly>
        {canAuditUsers && (
          <AdmithubOnly allowStaffOnly>
            <li>
              <Link to={INTERNAL.AUDIT_ACCESS}>Audit User Access</Link>
            </li>
          </AdmithubOnly>
        )}
        <Feature name={FeaturesType.GENERATIVE_AI_LIVE_CHAT_SUGGESTIONS}>
          {() => (
            <AdmithubOnly allowStaffOnly>
              <li>
                <Link to={INTERNAL.GENERATIVE_AI_TRANSACTIONS}>
                  Generative AI Transactions
                </Link>
              </li>
            </AdmithubOnly>
          )}
        </Feature>
        <AdmithubOnly allowStaffOnly>
          <li>
            <Link to={INTERNAL.KNOWLEDGE_BASE_SCRAPING}>
              Knowledge Base Scraping
            </Link>
          </li>
        </AdmithubOnly>
      </ul>
      <small className="text-blue-grey-300">Test Pages</small>
      <hr className="horizontal-line-blue-grey-300" />
      <ul className="list-unstyled">
        <li>
          <a href="/test-chat">Test the Bot</a>
        </li>
        <li>
          <a href="/webchat.html">Web Bot Product Demo</a>
        </li>
      </ul>
    </div>
  )
}

export default AdmithubUserMenu
