import {
  CombinationOperator,
  ContactFilterRowType,
  getCombinationType,
  getContactField,
  IContactFilterFormData,
  IContactFilterFormRow,
  isGroup,
  isValidRow,
  setRowFields,
} from 'components/ContactFilterBuilder/formUtils'
import {
  IContactAttributes,
  ITopLevelContactFields,
} from 'store/personalization/contactAttributes/selectors'

import { MainstayFlexTableCol } from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import { MainstayFlexTableRow } from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import * as React from 'react'
import {
  ComparisonOperator,
  OperatorSelect,
} from 'components/ContactFilterBuilder/SelectOperator'
import { ParameterSelect } from 'components/ContactFilterBuilder/SelectParameter'
import { ValueFormField } from 'components/ContactFilterBuilder/FormFieldValue'
import { RowHelperText } from 'components/ContactFilterBuilder/RowHelperText'
import { Field, FieldConfig, FieldProps, getIn } from 'formik'
import { AddRemoveRow } from 'components/ContactFilterBuilder/AddRemoveRow'
import Tooltip from 'components/Tooltip/Tooltip'
import 'components/ContactFilterBuilder/ContactFilterBuilder.scss'

export enum RowText {
  WHERE = 'Where',
  AND = 'And',
  OR = 'Or',
}

const getDataTypeFromRow = (
  row: IContactFilterFormRow,
  contactAttributes: IContactAttributes,
  topLevelFields: ITopLevelContactFields
) => {
  if (row.type === ContactFilterRowType.ATTRIBUTE) {
    return contactAttributes.find(attr => attr.id === row.parameter)?.type
  } else if (row.type === ContactFilterRowType.TOP_LEVEL_FIELD) {
    return topLevelFields.find(field => field.field === row.parameter)?.type
  }
  return undefined
}

interface IFilterRowProps {
  isFirstRow?: boolean
  formikPrefix: string
  row: IContactFilterFormRow
  isNewLevel?: boolean
  isOnlyRuleInGroup?: boolean
  contactAttributes: IContactAttributes
  topLevelContactFields: ITopLevelContactFields
  maxLevel: number
  setMaxLevel: React.Dispatch<React.SetStateAction<number>>
  onAddRow: (parentCombination: CombinationOperator, level: number) => void
  onDeleteRow: (callback?: () => void) => void
  onDeleteGroup: (callback?: () => void) => void
  onChangeGroupType: (type: ContactFilterRowType) => void
  showErrors?: boolean
  readOnly?: boolean
}

export const FilterRow = ({
  row,
  isFirstRow,
  formikPrefix,
  isNewLevel,
  contactAttributes,
  topLevelContactFields,
  maxLevel,
  isOnlyRuleInGroup,
  setMaxLevel,
  onAddRow,
  onDeleteRow,
  onDeleteGroup,
  onChangeGroupType,
  showErrors = true,
  readOnly = false,
}: IFilterRowProps) => {
  const [isHover, setIsHover] = React.useState(false)
  const dataType = getDataTypeFromRow(
    row,
    contactAttributes,
    topLevelContactFields
  )

  const helperText = isNewLevel
    ? RowText.WHERE
    : row.parentCombination === CombinationOperator.ALL
    ? RowText.AND
    : RowText.OR

  React.useEffect(() => {
    if (maxLevel < row.level) {
      setMaxLevel(row.level)
    }
  }, [maxLevel, row.level, setMaxLevel])

  const shouldHideComparisonOp =
    readOnly &&
    (row?.type === ContactFilterRowType.GROUP_ALL ||
      row?.type === ContactFilterRowType.GROUP_ANY)

  const shouldHideValueField =
    readOnly &&
    (shouldHideComparisonOp ||
      row.operator === ComparisonOperator.EXISTS ||
      row.operator === ComparisonOperator.DNE)

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}>
      <MainstayFlexTableRow noBorder>
        <MainstayFlexTableCol
          xs={1}
          style={
            readOnly
              ? { minWidth: (maxLevel + 4) * 25 - 9 }
              : { minWidth: (maxLevel + 4) * 25 }
          }
          className={readOnly ? 'read-only-filter-row' : ''}
          noDefaultPadding={readOnly}
          yPadding={2}>
          <RowHelperText
            text={helperText}
            level={row.level}
            maxLevel={maxLevel}
            readOnly={readOnly}
          />
        </MainstayFlexTableCol>
        <MainstayFlexTableCol
          xs={3}
          className={readOnly ? 'read-only-filter-row' : ''}
          noDefaultPadding={readOnly}
          yPadding={2}>
          <FieldWithErrorTooltip
            showErrors={showErrors}
            name={`${formikPrefix}.parameter`}
            render={(
              formikProps: FieldProps<IContactFilterFormData>,
              hasError
            ) => {
              return (
                <ParameterSelect
                  contactAttributes={contactAttributes}
                  topLevelContactFields={topLevelContactFields}
                  hideContactFields={isFirstRow}
                  error={hasError}
                  row={row}
                  onChange={(parameter, type) => {
                    const updateFields = () => {
                      setRowFields(formikProps.form, formikPrefix, {
                        operator: undefined,
                        value: undefined,
                        parameter,
                        type,
                        contactField: getContactField(
                          { parameter, type },
                          contactAttributes,
                          topLevelContactFields
                        ),
                      })
                    }

                    if (isGroup({ type }) && !isGroup(row)) {
                      // If this row is a new group
                      onAddRow(getCombinationType(type), row.level + 1)
                    } else if (!isGroup({ type }) && isGroup(row)) {
                      // If this row used to be a group
                      onDeleteGroup(updateFields)
                      return
                    } else if (isGroup({ type })) {
                      onChangeGroupType(type)
                    }
                    updateFields()
                  }}
                  readOnly={readOnly}
                />
              )
            }}
          />
        </MainstayFlexTableCol>
        <MainstayFlexTableCol
          xs={2}
          className={readOnly ? 'read-only-filter-row' : ''}
          noDefaultPadding={readOnly}
          yPadding={2}>
          {!isFirstRow && !shouldHideComparisonOp && (
            <FieldWithErrorTooltip
              showErrors={showErrors}
              name={`${formikPrefix}.operator`}
              render={(
                formikProps: FieldProps<IContactFilterFormData>,
                hasError
              ) => {
                return (
                  <OperatorSelect
                    row={row}
                    dataType={dataType}
                    error={hasError}
                    onChange={value => {
                      formikProps.form.setFieldValue(
                        formikProps.field.name,
                        value
                      )
                    }}
                    readOnly={readOnly}
                  />
                )
              }}
            />
          )}
        </MainstayFlexTableCol>
        <MainstayFlexTableCol
          xs={4}
          className={readOnly ? 'read-only-filter-row' : ''}
          noDefaultPadding={readOnly}
          yPadding={2}>
          {!isFirstRow && !shouldHideValueField && (
            <FieldWithErrorTooltip
              name={`${formikPrefix}.value`}
              showErrors={showErrors}
              render={(
                formikProps: FieldProps<IContactFilterFormData>,
                hasError
              ) => {
                return (
                  <ValueFormField
                    row={row}
                    formikProps={formikProps}
                    error={hasError}
                    onChange={value => {
                      formikProps.form.setFieldValue(
                        formikProps.field.name,
                        value
                      )
                    }}
                    readOnly={readOnly}
                  />
                )
              }}
            />
          )}
        </MainstayFlexTableCol>
        <MainstayFlexTableCol xs={1} yPadding={2} className="align-self-center">
          {isHover && !isFirstRow && !readOnly && (
            <AddRemoveRow
              onAddRow={isFirstRow || !isValidRow(row) ? undefined : onAddRow}
              onDeleteRow={isOnlyRuleInGroup ? undefined : onDeleteRow}
              row={row}
            />
          )}
        </MainstayFlexTableCol>
      </MainstayFlexTableRow>
    </div>
  )
}

interface IFieldWithErrorTooltipProps extends FieldConfig {
  showErrors?: boolean
  render: (
    props: FieldProps<IContactFilterFormData>,
    hasError?: boolean
  ) => React.ReactNode
}

export const FieldWithErrorTooltip = ({
  showErrors,
  children,
  render,
  ...fieldConfig
}: IFieldWithErrorTooltipProps) => (
  <Field
    {...fieldConfig}
    render={(fieldProps: FieldProps<IContactFilterFormData>) => {
      const error = showErrors
        ? String(getIn(fieldProps.form.errors, fieldProps.field.name) || '')
        : ''

      return (
        <Tooltip isEnabled={!!error} content={error}>
          <div>{render(fieldProps, showErrors && !!error)}</div>
        </Tooltip>
      )
    }}
  />
)
