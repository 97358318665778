import { getType } from 'typesafe-actions'
import uniq from 'lodash/uniq'
import difference from 'lodash/difference'
import orderBy from 'lodash/orderBy'
import {
  IActions,
  addConversationsToCheckboxList,
  removeConversationFromCheckboxList,
  resetConversationsCheckboxListItems,
  selectAllConversationsFromCheckboxList,
  setActionsToggle,
  fetchConversationsList,
  conversationListLiveUpdate,
  ContactListItem,
  removeConversationItemFromList,
  fetchPinnedConversationsList,
  togglePinConversation,
  setChosenContact,
} from 'page/conversations-v2/ConversationList/actions'

const DEFAULT_MIN_PAGE_OFFSET = 50
export const DEFAULT_PINNED_MIN_PAGE_OFFSET = 30

type CurrentSelection = {
  contactName: string // full name + preferred name
}

type ConversationsListState = {
  readonly all: boolean
  readonly selectedIds: string[]
  readonly deselectedIds: string[]
  readonly actionsToggle: boolean
  readonly pinned: {
    readonly status: ConversationListFetchStatus
    readonly items: ContactListItem[]
    readonly totalCount: number
    readonly nextOffset: number | null
  }
  readonly data: {
    readonly status: ConversationListFetchStatus
    readonly items: ContactListItem[]
    readonly totalCount: number
    readonly nextOffset: number | null
    readonly loadMore: boolean
    readonly currentSelection: CurrentSelection
  }
}

export enum ConversationListFetchStatus {
  initial = 'initial',
  loading = 'loading',
  error = 'error',
  ok = 'ok',
}

const initialConversationsListState: ConversationsListState = {
  all: false,
  selectedIds: [],
  deselectedIds: [],
  actionsToggle: false,
  pinned: {
    status: ConversationListFetchStatus.initial,
    items: [],
    totalCount: 0,
    nextOffset: 0,
  },
  data: {
    status: ConversationListFetchStatus.initial,
    items: [],
    totalCount: 0,
    nextOffset: 0,
    loadMore: false,
    currentSelection: {
      contactName: '',
    },
  },
}

const conversationsList = (
  state: ConversationsListState = initialConversationsListState,
  action: IActions
): ConversationsListState => {
  switch (action.type) {
    case getType(addConversationsToCheckboxList):
      const selectedIds = uniq([...state.selectedIds, ...action.payload])
      return {
        ...state,
        selectedIds,
        deselectedIds: difference(state.deselectedIds, selectedIds),
      }
    case getType(removeConversationFromCheckboxList):
      return {
        ...state,
        selectedIds: state.selectedIds.filter(item => item !== action.payload),
        deselectedIds: [...state.deselectedIds, action.payload],
      }
    case getType(selectAllConversationsFromCheckboxList):
      return {
        ...state,
        all: action.payload.all,
        selectedIds: action.payload.selectedIds,
        deselectedIds: action.payload.all ? state.deselectedIds : [],
      }
    case getType(resetConversationsCheckboxListItems):
      return {
        ...state,
        all: false,
        selectedIds: [],
        deselectedIds: [],
      }
    case getType(setActionsToggle):
      return {
        ...state,
        actionsToggle: action.payload,
      }
    case getType(fetchConversationsList.request):
      return {
        ...state,
        data: {
          ...state.data,
          loadMore: action.payload.loadMore,
          status: ConversationListFetchStatus.loading,
        },
      }
    case getType(fetchConversationsList.success):
      const allItems = [...state.data.items, ...action.payload.contacts]

      return {
        ...state,
        selectedIds: state.data.loadMore
          ? allItems.map(c => c.id)
          : state.selectedIds,
        data: {
          ...state.data,
          items: state.data.loadMore ? allItems : action.payload.contacts,
          totalCount: action.payload.total_count,
          nextOffset: action.payload.next_offset,
          status: ConversationListFetchStatus.ok,
          loadMore: false,
        },
      }
    case getType(fetchConversationsList.failure):
      return {
        ...state,
        data: {
          ...state.data,
          status: ConversationListFetchStatus.error,
        },
      }
    case getType(conversationListLiveUpdate):
      switch (action.payload.name) {
        case 'contact-profile': {
          const data = action.payload.data
          const stateKey = state.pinned.items.some(x => x.id === data.id)
            ? 'pinned'
            : 'data'

          const newConversationList = sortConversations(data, state, stateKey)

          return {
            ...state,
            [stateKey]: {
              ...state[stateKey],
              items: newConversationList,
            },
          }
        }
        default:
          return state
      }
    case getType(removeConversationItemFromList):
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items.filter(item => item.id !== action.payload),
        },
      }
    case getType(setChosenContact):
      return {
        ...state,
        data: {
          ...state.data,
          currentSelection: {
            ...state.data.currentSelection,
            contactName: action.payload,
          },
        },
      }
    case getType(fetchPinnedConversationsList.request):
      return {
        ...state,
        pinned: {
          ...state.pinned,
          status: ConversationListFetchStatus.loading,
        },
      }
    case getType(fetchPinnedConversationsList.success):
      return {
        ...state,
        pinned: {
          items: action.payload.contacts,
          totalCount: action.payload.total_count,
          nextOffset: action.payload.next_offset,
          status: ConversationListFetchStatus.ok,
        },
      }
    case getType(fetchPinnedConversationsList.failure):
      return {
        ...state,
        pinned: {
          ...state.pinned,
          status: ConversationListFetchStatus.error,
        },
      }
    case getType(togglePinConversation):
      const { pin, conversationId, searching } = action.payload
      // if we're searching, we don't want to move list items between sections.
      if (searching) {
        return state
      }

      let totalCount = state.pinned.totalCount

      let pinnedState = state.pinned.items
      let unpinnedState = state.data.items

      // find our item in both lists.
      const unpinnedItem = unpinnedState.find(x => x.id === conversationId)
      const pinnedItem = pinnedState.find(x => x.id === conversationId)

      // move item between lists.
      if (pin) {
        if (unpinnedItem) {
          // Pin contact
          pinnedState = [...pinnedState, unpinnedItem]
          unpinnedState = unpinnedState.filter(x => x.id !== conversationId)

          totalCount += 1
        }
      } else if (pinnedItem) {
        // Unpin contact
        unpinnedState = [...unpinnedState, pinnedItem]
        pinnedState = pinnedState.filter(x => x.id !== conversationId)

        totalCount -= 1
      }

      return {
        ...state,
        pinned: {
          ...state.pinned,
          items: sortContacts(pinnedState),
          totalCount,
        },
        data: {
          ...state.data,
          items: sortContacts(unpinnedState),
        },
      }
    default:
      return state
  }
}

function sortContacts(arr: ContactListItem[]): ContactListItem[] {
  return orderBy(
    arr,
    // ensure nulls get sorted last.
    //
    // the API response is sorted by lastIncomingMessageAt, sorting
    // keeps the UI consistent after live updates.
    x => x.lastIncomingMessageAt || '',
    ['desc']
  )
}

function sortConversations(
  conversation: ContactListItem,
  state: ConversationsListState,
  key: 'data' | 'pinned'
) {
  return (
    sortContacts([
      conversation,
      ...state[key].items.filter(x => x.id !== conversation.id),
    ])
      // ensure the page memory doesn't balloon
      .slice(
        0,
        key === 'data'
          ? state[key].nextOffset ?? DEFAULT_MIN_PAGE_OFFSET
          : DEFAULT_PINNED_MIN_PAGE_OFFSET
      )
  )
}

export default conversationsList
