import {
  ActionType,
  createAsyncAction,
  createStandardAction,
  getType,
} from 'typesafe-actions'
import { IInstitution } from 'api/response'

export interface ITrustedEmailDomainFormReducerState {
  readonly emailDomains: ReadonlyArray<string>
  readonly textValue: string
  readonly saveStatus: 'initial' | 'loading' | 'success' | 'failure'
  readonly removeStatus: {
    readonly [key: string]: undefined | 'removing' | 'failure'
  }
}

export const addEmailDomain = createAsyncAction(
  '@@mascot/settings/general/trusted_email/addEmailDomain/request',
  '@@mascot/settings/general/trusted_email/addEmailDomain/success',
  '@@mascot/settings/general/trusted_email/addEmailDomain/failure'
)<void, IInstitution, void>()

export const removeEmailDomain = createAsyncAction(
  '@@mascot/settings/general/trusted_email/removeEmailDomain/request',
  '@@mascot/settings/general/trusted_email/removeEmailDomain/success',
  '@@mascot/settings/general/trusted_email/removeEmailDomain/failure'
)<
  string,
  { readonly emailDomain: string; readonly institution: IInstitution },
  string
>()

export const setTextInput = createStandardAction(
  '@@mascot/settings/general/trusted_email/setTextInput'
)<string>()

type TrustedEmailDomainFormActions =
  | ActionType<typeof addEmailDomain>
  | ActionType<typeof setTextInput>
  | ActionType<typeof removeEmailDomain>

export function trustedEmailDomainFormReducer(
  state: ITrustedEmailDomainFormReducerState,
  action: TrustedEmailDomainFormActions
): ITrustedEmailDomainFormReducerState {
  switch (action.type) {
    case getType(addEmailDomain.request):
      return { ...state, saveStatus: 'loading' }
    case getType(addEmailDomain.success):
      return {
        ...state,
        saveStatus: 'success',
        textValue: '',
        emailDomains: action.payload.emailDomains,
      }
    case getType(addEmailDomain.failure):
      return { ...state, saveStatus: 'failure' }
    case getType(removeEmailDomain.request):
      return {
        ...state,
        removeStatus: {
          ...state.removeStatus,
          [action.payload]: 'removing',
        },
      }
    case getType(removeEmailDomain.success):
      return {
        ...state,
        emailDomains: action.payload.institution.emailDomains,
        removeStatus: {
          ...state.removeStatus,
          [action.payload.emailDomain]: undefined,
        },
      }

    case getType(removeEmailDomain.failure):
      return {
        ...state,
        removeStatus: {
          ...state.removeStatus,
          [action.payload]: 'failure',
        },
      }
    case getType(setTextInput):
      return { ...state, textValue: action.payload }
    default:
      return state
  }
}
