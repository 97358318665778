import moment from 'moment-timezone'

export enum RepeatInterval {
  noRepeat = 'noRepeat',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  weekdays = 'weekdays',
  weekends = 'weekends',
}

const momentParsableIntervals = ['day', 'week']

/**
 * Type Guard to assert that a parsed interval is usable by `moment.add`.
 *
 * @param intervalName - name of interval to check.
 */
const isMomentParsableInterval = (
  intervalName: string
): intervalName is moment.unitOfTime.Diff => {
  return momentParsableIntervals.includes(intervalName)
}

/**
 * Convert repeat interval enum to a string to be consumed by
 * `DateRepeatInterval`.
 *
 * @param interval - enum value to convert.
 */
const parseInterval = (interval: RepeatInterval): string => {
  if (interval === RepeatInterval.daily) {
    return 'day'
  }
  if (interval === RepeatInterval.weekly) {
    return 'week'
  }
  if (interval === RepeatInterval.weekends) {
    return 'weekend'
  }
  if (interval === RepeatInterval.weekdays) {
    return 'weekday'
  }
  if (interval === RepeatInterval.monthly) {
    return 'month'
  }
  return 'month'
}

interface IDateRepeatIteratorContext {
  currentDate: moment.Moment
  endDate: moment.Moment
  timeZone: string
  dateIndex: number
}

// DATE ITERATORS
// each of these functions are pure and take context data and return the
// next valid date for the interval type

type DateRepeatIntervalIterator = (
  context: IDateRepeatIteratorContext
) => moment.Moment

/**
 * Default iterator finished after `start` value is returned.
 *
 * @param context - date iteration context object.
 */
const defaultIterator: DateRepeatIntervalIterator = context =>
  context.endDate.clone().add(1, 'days')

/**
 * Custom iterator to get dates a month apart.
 *
 * This has been customized beyond what `moment` provides to match the
 * logic used by `agenda` cron strings, where if the starting date has
 * a day number between 29-31 (say 2022-01-31), months where that day
 * number does not exist should be skipped.
 *
 * `moment` default behavior is to move down the day number to the
 * largest one available for a month, which means it fails to skip
 * months and deviates from the `agenda` behavior.
 *
 * @param context - date iteration context object.
 */
const getNextMonthIterator: DateRepeatIntervalIterator = context => {
  const dayOfMonth = context.currentDate.date()
  let next = context.currentDate.clone()

  // return `start` first
  if (context.dateIndex === 0) {
    return next
  }

  // iterate through months in range and skip months with less days
  // in them then the days in the `start` date
  while (true) {
    next.add(1, 'months')
    const daysInMonth = next.daysInMonth()

    // finish iteration if loop has exceeded `context.endDate`
    if (next.isAfter(context.endDate)) {
      next = context.endDate.clone().add(1, 'days')
      break
    }

    // skip month if it has less days than days in `start` date
    if (dayOfMonth > daysInMonth) {
      continue

      // if month has enough days to include days in `start` date
      // reset the day to that date and exit the loop
    } else {
      next.date(dayOfMonth)
      break
    }
  }

  return next
}

/**
 * Custom iterator to get next weekday.
 *
 * @param context - date iteration context object.
 */
const getNextWeekdayIterator: DateRepeatIntervalIterator = context => {
  const currentDayIndex = context.currentDate.isoWeekday()
  const currentWeekIndex = context.currentDate.isoWeek()
  const next = context.currentDate.clone()

  // return `start` if it is a weekday
  if (context.dateIndex === 0 && [1, 2, 3, 4, 5].includes(currentDayIndex)) {
    return next
  }

  // handle Fri, Sat, Sun
  if ([5, 6, 7].includes(currentDayIndex)) {
    next.isoWeek(currentWeekIndex + 1)
    next.isoWeekday(1)

    // handle other weekdays
  } else {
    next.isoWeekday(currentDayIndex + 1)
  }

  return next
}

/**
 * Custom iterator to get next weekend days.
 *
 * @param context - date iteration context object.
 */
const getNextWeekendIterator: DateRepeatIntervalIterator = context => {
  const currentDayIndex = context.currentDate.isoWeekday()
  const currentWeekIndex = context.currentDate.isoWeek()
  const next = context.currentDate.clone()

  // return `start` if it is a weekend
  if (context.dateIndex === 0 && [6, 7].includes(currentDayIndex)) {
    return next
  }

  // handle weekdays
  if ([1, 2, 3, 4, 5].includes(currentDayIndex)) {
    next.isoWeekday(6)

    // handle Sat
  } else if (currentDayIndex === 6) {
    next.isoWeekday(7)
    // handle Sun
  } else {
    next.isoWeek(currentWeekIndex + 1)
    next.isoWeekday(6)
  }

  return next
}

/**
 * Generates iterator using `moment.add()` logic for a given interval.
 *
 * @param parsedInterval - interval to create `moment.add` iterator from.
 */
const generateMomentIntervalIterator = (
  parsedInterval: moment.unitOfTime.Diff
): DateRepeatIntervalIterator => {
  return context => {
    const next = context.currentDate.clone()

    // return `start` first
    if (context.dateIndex === 0) {
      return next
    }

    return next.add(1, parsedInterval)
  }
}

export class DateRepeatInterval implements Iterable<moment.Moment> {
  currentDate: moment.Moment
  endDate: moment.Moment
  timeZone: string
  dateIndex: number = 0
  intervalIterator: DateRepeatIntervalIterator

  /**
   * Iterator that produces ranges of `moment` date objects based on start, stop,
   * and interval. Interval iteration respects date values for the given `timeZone`.
   *
   * @param start inclusive start date of range in UTC timezone.
   * @param end inclusive end date of range in UTC timezone.
   * @param timeZone time zone to calculate intervals within.
   * @param interval interval between dates.
   */
  constructor(
    start: moment.Moment,
    end: moment.Moment,
    timeZone: string,
    interval: RepeatInterval
  ) {
    // set the time zone for all dates if it is valid, otherwise guess
    // it from the running context
    this.timeZone =
      moment.tz.zone(timeZone) == null ? moment.tz.guess(true) : timeZone

    // convert input UTC date range to timezone
    this.currentDate = moment(start)
      .utc()
      .tz(this.timeZone)
    this.endDate = moment(end)
      .utc()
      .tz(this.timeZone)

    this.intervalIterator = this._getIterator(interval)
  }

  /**
   * Private method to get the appropriate date interval generator given an
   * interval.
   *
   * @param interval - interval used to select date interval iterator
   */
  _getIterator = (interval: RepeatInterval): DateRepeatIntervalIterator => {
    const parsedInterval = parseInterval(interval)

    // for unknown interval, allow the default iterator to be selected
    let intervalIterator = defaultIterator

    // interval types directly parsed by `moment().add() as second arg`
    if (isMomentParsableInterval(parsedInterval)) {
      intervalIterator = generateMomentIntervalIterator(parsedInterval)

      // interval types parsed by custom iterators
    } else {
      if (parsedInterval === 'weekday') {
        intervalIterator = getNextWeekdayIterator
      }
      if (parsedInterval === 'weekend') {
        intervalIterator = getNextWeekendIterator
      }
      if (parsedInterval === 'month') {
        intervalIterator = getNextMonthIterator
      }
    }

    return intervalIterator
  };

  [Symbol.iterator]() {
    return {
      next: () => {
        const context = {
          currentDate: this.currentDate.clone(),
          endDate: this.endDate.clone(),
          timeZone: this.timeZone,
          dateIndex: this.dateIndex,
        }
        this.currentDate = this.intervalIterator(context)
        this.dateIndex++

        return this.currentDate.isAfter(this.endDate)
          ? { done: true, value: this.currentDate }
          : { done: false, value: this.currentDate }
      },
    }
  }
}
