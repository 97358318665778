import { ActionType, createStandardAction, getType } from 'typesafe-actions'

export interface IContactNotesReducerState {
  readonly menuOpen: boolean
  readonly editMode: boolean
  readonly noteText: string
}

export const setNoteText = createStandardAction(
  '@@mascot/ContactNotes/setNoteText'
)<string>()

export const closeMenu = createStandardAction(
  '@@mascot/ContactNotes/closeMenu'
)()

export const setEditMode = createStandardAction(
  '@@mascot/ContactNotes/setEditMode'
)()

export const cancelEdit = createStandardAction(
  '@@mascot/ContactNotes/cancelEdit'
)()

export const deleteNote = createStandardAction(
  '@@mascot/ContactNotes/deleteNote'
)()

type ContactNotesActions =
  | ActionType<typeof setNoteText>
  | ActionType<typeof setEditMode>
  | ActionType<typeof cancelEdit>
  | ActionType<typeof deleteNote>
  | ActionType<typeof closeMenu>

export function contactNotesReducer(
  state: IContactNotesReducerState,
  action: ContactNotesActions
): IContactNotesReducerState {
  switch (action.type) {
    case getType(setNoteText):
      return {
        ...state,
        noteText: action.payload,
      }
    case getType(closeMenu):
      return {
        ...state,
        menuOpen: false,
      }
    case getType(setEditMode):
      return {
        ...state,
        editMode: true,
        menuOpen: false,
      }
    case getType(cancelEdit):
      return {
        ...state,
        editMode: false,
      }
    default:
      return state
  }
}
