import {
  IContactFilterFormData,
  IContactFilterFormRow,
  isGroup,
} from 'components/ContactFilterBuilder/formUtils'

import { ComparisonOperator } from 'components/ContactFilterBuilder/SelectOperator'
import { TextInput } from 'components/TextInput/TextInput'
import { FieldProps } from 'formik'
import Select from 'components/Select/SelectV2'
import * as Raven from '@sentry/browser'
import {
  ContactAttributeType,
  isContactAttribute,
  isMultiChoiceField,
  MESSAGING_STATUS_FIELD_PREFIX,
  MESSAGING_STATUS_READABLE_NAME_PREFIX,
  MessagingStatusEnum,
  getHumanizedMessagingStatus,
  humanizedMessagingStatuses,
} from 'store/personalization/contactAttributes/selectors'
import { getInstitutionChannels } from 'store/triage/profile/selectors'
import { TransportEnum, channelToTransport } from 'store/transport'
import 'react-day-picker/lib/style.css'
import moment from 'moment'
import classnames from 'classnames'
import { ReadOnlyDatePicker } from 'components/ReadOnlyDatePicker/ReadOnlyDatePicker'
import {
  Failure,
  isLoading,
  isSuccess,
  Loading,
  Success,
  WebData,
} from 'store/webdata'
import {
  listContactLabelNames,
  listImportLabelNames,
  listWebbotTokensAndTitles,
} from 'api'
import { isRight } from 'fp-ts/lib/Either'
import { useDebounce, useSelector } from 'util/hooks'
import axios from 'axios'
import { AsyncCampaignSearch } from 'components/AsyncCampaignSearch/AsyncCampaignSearch'
import { AsyncCustomScriptSearch } from 'components/AsyncCustomScriptSearch/AsyncCustomScriptSearch'
import React from 'react'
import { DynamicDatePicker } from 'components/ReadOnlyDatePicker/DynamicDatePicker'

interface IMultiChoiceOption {
  label: string
  value: string
}

interface IValueFormFieldProps {
  row: IContactFilterFormRow
  formikProps: FieldProps<IContactFilterFormData>
  onChange: (value: string) => void
  error?: boolean
  readOnly?: boolean
}

const LabelSelect = ({
  onChange,
  formikProps,
  row,
  error,
  readOnly = false,
}: IValueFormFieldProps) => {
  const fieldType = row.contactField?.field
  const [contactLabels, setContactLabels] = React.useState<WebData<string[]>>(
    undefined
  )
  const [importLabels, setImportLabels] = React.useState<WebData<string[]>>(
    undefined
  )
  const [webBotMapping, setWebBotMapping] = React.useState<
    WebData<{ [key: string]: string }>
  >(undefined)
  const [importLabelQuery, setImportLabelQuery] = React.useState('')
  const [contactLabelQuery, setContactLabelQuery] = React.useState('')
  const [webBotQuery, setWebBotQuery] = React.useState('')
  const debouncedImportLabelQuery = useDebounce(importLabelQuery, 500)
  const debouncedContactLabelQuery = useDebounce(contactLabelQuery, 500)
  const debouncedWebBotQuery = useDebounce(webBotQuery, 500)

  React.useEffect(() => {
    const handle = axios.CancelToken.source()
    listContactLabelNames({
      search: debouncedContactLabelQuery,
      limit: 50,
      cancelToken: handle.token,
    }).then(res => {
      if (isRight(res)) {
        setContactLabels(Success(res.right.labels))
      } else {
        setContactLabels(Failure('Contact labels failed to load'))
      }
    })
    return () => {
      handle.cancel()
    }
  }, [debouncedContactLabelQuery])

  React.useEffect(() => {
    const handle = axios.CancelToken.source()
    setImportLabels(Loading())
    listImportLabelNames({
      search: debouncedImportLabelQuery,
      limit: 50,
      cancelToken: handle.token,
    })
      .then(res => setImportLabels(Success(res.data.labels)))
      .catch(() => setImportLabels(Failure('Import labels failed to load')))
    return () => {
      handle.cancel()
    }
  }, [debouncedImportLabelQuery])

  React.useEffect(() => {
    setWebBotMapping(Loading())
    const handle = axios.CancelToken.source()
    listWebbotTokensAndTitles({
      search: debouncedWebBotQuery,
      cancelToken: handle.token,
    }).then(res => {
      if (isRight(res)) {
        setWebBotMapping(Success(res.right))
      } else {
        setWebBotMapping(Failure('Web bots failed to load'))
      }
    })
    return () => {
      handle.cancel()
    }
  }, [debouncedWebBotQuery])

  let options: IMultiChoiceOption[] = []
  if (fieldType === 'contact_labels') {
    options = isSuccess(contactLabels)
      ? contactLabels.data.map(opt => ({
          label: opt,
          value: opt,
        }))
      : []
  }
  if (fieldType === 'import_segment_labels') {
    options = isSuccess(importLabels)
      ? importLabels.data.map(opt => ({
          label: opt,
          value: opt,
        }))
      : []
  }
  if (fieldType === 'web_bot_tokens') {
    const newOptions = []
    if (isSuccess(webBotMapping)) {
      for (const [token, title] of Object.entries(webBotMapping.data)) {
        newOptions.push({ label: title, value: token })
        newOptions.reverse()
      }
    }
    options = newOptions
  }

  const loading =
    (fieldType === 'import_segment_labels' && isLoading(importLabels)) ||
    (fieldType === 'contact_labels' && isLoading(contactLabels)) ||
    (fieldType === 'web_bot_tokens' && isLoading(webBotMapping))

  const getOption = (value?: string) => {
    if (!value) {
      return undefined
    }
    if (loading) {
      return { label: '', value: '' }
    }
    if (fieldType === 'web_bot_tokens' && isSuccess(webBotMapping)) {
      return { value, label: webBotMapping.data[value] || value }
    }
    return { value, label: value }
  }

  return (
    <Select<IMultiChoiceOption>
      {...formikProps.field}
      options={options}
      readOnly={readOnly}
      value={getOption(row.value)}
      placeholder=""
      error={error}
      isDisabled={!row.operator || readOnly}
      isLoading={loading}
      explicitPreventOverlap={true}
      onChange={(opt, actionMeta) => {
        if (!Array.isArray(opt) && opt) {
          onChange(opt.value)
        }
        if (actionMeta.action === 'clear') {
          onChange('')
        }
      }}
      isClearable={true}
      onInputChange={val => {
        if (fieldType === 'import_segment_labels') {
          setImportLabelQuery(val)
          if (val) {
            setImportLabels(Loading())
          }
        } else if (fieldType === 'contact_labels') {
          setContactLabelQuery(val)
          if (val) {
            setContactLabels(Loading())
          }
        } else if (fieldType === 'web_bot_tokens') {
          setWebBotQuery(val)
          if (val) {
            setWebBotMapping(Loading())
          }
        }
      }}
    />
  )
}

const humanizedTransports: { [key in keyof typeof TransportEnum]: string } = {
  [TransportEnum.twilio]: 'SMS',
  [TransportEnum.web]: 'Web Chat',
  [TransportEnum.facebook]: 'Facebook Messenger',
  [TransportEnum.slack]: 'Slack',
}

const getHumanizedTransport = (
  transport: keyof typeof TransportEnum
): string => {
  const humanized = humanizedTransports[transport]
  if (!humanized) {
    Raven.captureException(
      `Unable to identify humanized channel name: ${transport}`
    )
  }
  return humanized
}

export const ValueFormField = ({
  row,
  formikProps,
  onChange,
  error,
  readOnly = false,
}: IValueFormFieldProps) => {
  const enabledTransports = useSelector(getInstitutionChannels).map(elem =>
    channelToTransport(elem)
  )
  if (
    isGroup(row) ||
    row.operator === ComparisonOperator.EXISTS ||
    row.operator === ComparisonOperator.DNE
  ) {
    return (
      <Select<IMultiChoiceOption>
        className="disabled-input"
        isDisabled={true}
        placeholder=""
        value={{ label: '', value: '' }}
      />
    )
  }
  const disabled = !row.operator

  const rowIsMessagingStatusRow =
    (row.contactField?.field ?? '').startsWith(MESSAGING_STATUS_FIELD_PREFIX) &&
    (row.contactField?.readableName ?? '').startsWith(
      MESSAGING_STATUS_READABLE_NAME_PREFIX
    )

  const getMessagingStatusRow = (
    row: IContactFilterFormRow
  ): { label: string; value: string } =>
    !!row.value
      ? {
          label: getHumanizedMessagingStatus(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            row.value as keyof typeof MessagingStatusEnum
          ),
          value: row.value,
        }
      : { label: '', value: '' }
  const selectedOption = row.value
    ? row.contactField?.field === 'transport'
      ? {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          label: getHumanizedTransport(row.value as keyof typeof TransportEnum),
          value: row.value,
        }
      : rowIsMessagingStatusRow
      ? getMessagingStatusRow(row)
      : { label: row.value, value: row.value }
    : undefined
  switch (row.contactField?.type) {
    case ContactAttributeType.MULTI_CHOICE:
    case ContactAttributeType.BOOLEAN:
      const options = isMultiChoiceField(row.contactField)
        ? row.contactField.field === 'transport'
          ? enabledTransports.map(elem => ({
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              label: getHumanizedTransport(elem as keyof typeof TransportEnum),
              value: elem,
            }))
          : rowIsMessagingStatusRow
          ? Object.keys(humanizedMessagingStatuses).map(elem => ({
              label: getHumanizedMessagingStatus(
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                elem as keyof typeof MessagingStatusEnum
              ),
              value: elem,
            }))
          : row.contactField.options?.map(opt => ({
              label: opt.value,
              value: opt.value,
            }))
        : [
            { label: 'True', value: 'True' },
            { label: 'False', value: 'False' },
          ]

      return (
        <Select<IMultiChoiceOption>
          {...formikProps.field}
          options={options}
          isDisabled={disabled || readOnly}
          readOnly={readOnly}
          error={error}
          explicitPreventOverlap={true}
          value={selectedOption || null}
          placeholder=""
          onChange={opt => {
            if (Array.isArray(opt) || opt == null) {
              return
            }
            onChange(opt.value)
          }}
        />
      )
    case ContactAttributeType.DATE:
      return isContactAttribute(row.contactField) ? (
        <DynamicDatePicker
          value={row.value}
          onDayChange={onChange}
          classNames={{
            overlay: 'DayPickerInput-Overlay daypicker-align-left',
          }}
          disabled={readOnly}
        />
      ) : (
        <ReadOnlyDatePicker
          value={row.value}
          onDayChange={(e: Date) =>
            onChange(moment(e).format(moment.HTML5_FMT.DATE))
          }
          classNames={{
            overlay: 'DayPickerInput-Overlay daypicker-align-left',
          }}
          readOnly={readOnly}
        />
      )
    case ContactAttributeType.JSON_STRING_ARRAY:
      return row?.parameter === 'received_dialog_ids' ? (
        <AsyncCustomScriptSearch
          key={row?.parameter}
          className="mb-0"
          name="script-filter-builder"
          value={selectedOption}
          onChange={e => {
            if (Array.isArray(e.target?.value) || e == null) {
              return
            }
            onChange(e.target?.value)
          }}
          readOnly={readOnly}
        />
      ) : row?.parameter === 'received_scheduled_message_ids' ? (
        <AsyncCampaignSearch
          name="campaign-filter-builder"
          key={row?.parameter}
          className="mb-0"
          value={selectedOption}
          onChange={e => {
            if (Array.isArray(e.target?.value) || e == null) {
              return
            }
            onChange(e.target?.value)
          }}
          readOnly={readOnly}
        />
      ) : (
        <LabelSelect
          row={row}
          error={error}
          onChange={onChange}
          formikProps={formikProps}
          readOnly={readOnly}
        />
      )

    case ContactAttributeType.NUMBER:
    case ContactAttributeType.TEXT:
    case ContactAttributeType.EMAIL:
    case ContactAttributeType.PHONE:
    case ContactAttributeType.URL:
    default:
      return (
        <TextInput
          disabled={disabled || readOnly}
          error={error}
          height="md"
          className={classnames('mb-0 input-react-select', {
            'disabled-input': disabled && !readOnly,
            'read-only-input': readOnly,
          })}
          value={
            Array.isArray(row.value) ? row.value.join(', ') : row.value || ''
          }
          onChange={e => onChange(e.target.value)}
        />
      )
  }
}
