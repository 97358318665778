interface IFireFlyIconProps {
  width?: string
  height?: string
}

export function FireFlyIcon({
  width = '24',
  height = '22.8',
}: IFireFlyIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 23"
      fill="none">
      <path
        d="M12.401 20.2543C15.0285 20.2543 17.1585 18.1243 17.1585 15.4969C17.1585 12.8694 15.0285 10.7394 12.401 10.7394C9.77355 10.7394 7.64355 12.8694 7.64355 15.4969C7.64355 18.1243 9.77355 20.2543 12.401 20.2543Z"
        fill="#D8E267"
      />
      <path
        d="M17.1597 11.6499V8.19204C17.1704 7.56059 17.0553 6.93333 16.821 6.34683C16.5868 5.76034 16.2381 5.22636 15.7953 4.77602C15.3525 4.32569 14.8245 3.96801 14.2421 3.72386C13.6597 3.47972 13.0344 3.35397 12.4029 3.35397C11.7713 3.35397 11.1461 3.47972 10.5637 3.72386C9.98124 3.96801 9.45324 4.32569 9.01047 4.77602C8.5677 5.22636 8.21902 5.76034 7.98477 6.34683C7.75053 6.93333 7.63539 7.56059 7.64609 8.19204V11.6499C6.91792 12.5485 6.45915 13.6349 6.32291 14.7834C6.18666 15.932 6.37851 17.0956 6.87624 18.1396C7.37397 19.1836 8.15723 20.0652 9.13532 20.6825C10.1134 21.2997 11.2463 21.6273 12.4029 21.6273C13.5595 21.6273 14.6924 21.2997 15.6705 20.6825C16.6486 20.0652 17.4318 19.1836 17.9295 18.1396C18.4273 17.0956 18.6191 15.932 18.4829 14.7834C18.3466 13.6349 17.8879 12.5485 17.1597 11.6499ZM9.00849 8.19204C8.99944 7.74091 9.08048 7.29252 9.24686 6.8731C9.41324 6.45368 9.66162 6.07166 9.97747 5.74942C10.2933 5.42718 10.6703 5.17118 11.0863 4.99642C11.5023 4.82166 11.9489 4.73164 12.4002 4.73164C12.8514 4.73164 13.2981 4.82166 13.7141 4.99642C14.1301 5.17118 14.507 5.42718 14.8228 5.74942C15.1387 6.07166 15.3871 6.45368 15.5535 6.8731C15.7198 7.29252 15.8009 7.74091 15.7918 8.19204V10.4036C14.7877 9.73327 13.6075 9.37554 12.4002 9.37554C11.1929 9.37554 10.0126 9.73327 9.00849 10.4036V8.19204ZM12.4008 20.2612C11.4602 20.2609 10.5408 19.9818 9.75881 19.4592C8.97677 18.9366 8.3672 18.1939 8.00713 17.3249C7.64706 16.456 7.55264 15.4998 7.73581 14.5772C7.91898 13.6546 8.37152 12.807 9.03624 12.1416C9.70095 11.4761 10.548 11.0226 11.4704 10.8383C12.3928 10.6541 13.3491 10.7474 14.2184 11.1065C15.0878 11.4656 15.8312 12.0743 16.3547 12.8557C16.8782 13.6372 17.1583 14.5563 17.1597 15.4969C17.1606 16.1223 17.0381 16.7418 16.7994 17.3199C16.5606 17.898 16.2102 18.4233 15.7682 18.8658C15.3262 19.3083 14.8013 19.6593 14.2234 19.8988C13.6456 20.1382 13.0263 20.2613 12.4008 20.2612Z"
        fill="#1D1F4E"
      />
      <path
        d="M11.5243 4.42565C11.4794 4.42583 11.435 4.41712 11.3935 4.40004C11.352 4.38295 11.3143 4.35782 11.2826 4.32609C11.2508 4.29436 11.2257 4.25667 11.2086 4.21518C11.1915 4.17369 11.1828 4.12923 11.183 4.08436C11.183 3.26529 11.0096 2.17318 10.1865 1.6831C10.1454 1.66145 10.1092 1.63165 10.0801 1.59553C10.0509 1.55941 10.0295 1.51773 10.017 1.47303C10.0045 1.42833 10.0013 1.38156 10.0075 1.33556C10.0137 1.28957 10.0292 1.24533 10.0531 1.20554C10.077 1.16575 10.1087 1.13126 10.1464 1.10416C10.1841 1.07706 10.2269 1.05793 10.2722 1.04795C10.3175 1.03796 10.3644 1.03732 10.41 1.04607C10.4556 1.05481 10.4989 1.07277 10.5373 1.09882C11.4055 1.61894 11.8656 2.65234 11.8656 4.08436C11.8658 4.12923 11.8571 4.17369 11.84 4.21518C11.8229 4.25667 11.7977 4.29436 11.766 4.32609C11.7343 4.35782 11.6966 4.38295 11.6551 4.40004C11.6136 4.41712 11.5692 4.42583 11.5243 4.42565Z"
        fill="#1D1F4E"
      />
      <path
        d="M13.3198 4.42565C13.2293 4.42565 13.1425 4.38969 13.0785 4.32569C13.0145 4.26169 12.9785 4.17488 12.9785 4.08437C12.9785 2.65234 13.4372 1.61894 14.3054 1.09883C14.3446 1.0757 14.3879 1.06052 14.433 1.05414C14.478 1.04776 14.5239 1.05032 14.5679 1.06167C14.612 1.07301 14.6534 1.09292 14.6897 1.12026C14.7261 1.1476 14.7567 1.18183 14.7798 1.221C14.8029 1.26018 14.8181 1.30352 14.8245 1.34856C14.8309 1.3936 14.8283 1.43945 14.817 1.4835C14.8056 1.52755 14.7857 1.56894 14.7584 1.60529C14.731 1.64165 14.6968 1.67226 14.6576 1.69539C13.8385 2.18956 13.6611 3.27484 13.6611 4.09665C13.6579 4.18502 13.6205 4.2687 13.5569 4.33007C13.4932 4.39144 13.4082 4.42571 13.3198 4.42565Z"
        fill="#1D1F4E"
      />
      <path
        d="M21.6034 14.0444L16.9456 9.38516L15.1586 11.1721L13.3662 12.9632L18.0254 17.621C18.2598 17.858 18.5387 18.0463 18.8461 18.1752C19.1535 18.3041 19.4834 18.3709 19.8167 18.3718C20.1501 18.3728 20.4803 18.3079 20.7884 18.1808C21.0966 18.0537 21.3766 17.867 21.6123 17.6313C21.8481 17.3956 22.0349 17.1157 22.1621 16.8076C22.2893 16.4995 22.3544 16.1693 22.3536 15.836C22.3527 15.5026 22.286 15.1728 22.1573 14.8653C22.0286 14.5578 21.8403 14.2788 21.6034 14.0444Z"
        fill="#F7F6F4"
      />
      <path
        d="M22.5684 13.0778L17.4328 7.93676C16.7742 7.2747 15.9908 6.74982 15.128 6.39248C14.2653 6.03514 13.3402 5.85243 12.4064 5.85494C11.4714 5.85153 10.545 6.0338 9.68099 6.39116C8.81698 6.74853 8.03253 7.27389 7.37312 7.93676L2.23204 13.0778C1.52318 13.8127 1.13115 14.7965 1.14029 15.8176C1.14944 16.8386 1.55903 17.8152 2.28093 18.5373C3.00284 19.2593 3.97935 19.6692 5.00036 19.6786C6.02137 19.688 7.00527 19.2962 7.74034 18.5875L12.4009 13.9283L17.0601 18.5861C17.4218 18.9478 17.8511 19.2347 18.3237 19.4305C18.7963 19.6262 19.3027 19.727 19.8142 19.727C20.3257 19.727 20.8322 19.6262 21.3048 19.4305C21.7773 19.2347 22.2067 18.9478 22.5684 18.5861C22.9301 18.2245 23.217 17.7951 23.4127 17.3225C23.6084 16.85 23.7092 16.3435 23.7092 15.832C23.7092 15.3205 23.6084 14.814 23.4127 14.3415C23.217 13.8689 22.9301 13.4395 22.5684 13.0778ZM19.8149 18.3623C19.4824 18.3631 19.1531 18.298 18.8459 18.1708C18.5387 18.0435 18.2598 17.8567 18.0252 17.621L13.366 12.9632L15.1585 11.1721L16.9454 9.38516L21.6032 14.0444C21.9569 14.3981 22.1977 14.8488 22.2952 15.3394C22.3928 15.83 22.3427 16.3385 22.1513 16.8006C21.9599 17.2627 21.6358 17.6578 21.2199 17.9357C20.804 18.2137 20.3151 18.3621 19.8149 18.3623ZM3.19718 17.621C2.96224 17.3862 2.77586 17.1074 2.6487 16.8006C2.52154 16.4937 2.45609 16.1648 2.45609 15.8327C2.45609 15.5005 2.52154 15.1716 2.6487 14.8648C2.77586 14.5579 2.96224 14.2791 3.19718 14.0444L7.85501 9.38516L9.64333 11.1721L11.4344 12.9632L6.77519 17.621C6.54029 17.856 6.26139 18.0424 5.95443 18.1696C5.64746 18.2968 5.31845 18.3622 4.98619 18.3622C4.65392 18.3622 4.32491 18.2968 4.01795 18.1696C3.71099 18.0424 3.43209 17.856 3.19718 17.621ZM15.9516 8.44868L12.4023 11.998L8.85292 8.44868C9.86526 7.65378 11.1151 7.22172 12.4023 7.22172C13.6894 7.22172 14.9393 7.65378 15.9516 8.44868Z"
        fill="#1D1F4E"
      />
      <path
        d="M11.4342 12.9632L9.6431 11.1721L7.85478 9.38516L3.19695 14.0444C2.96003 14.2788 2.7718 14.5578 2.64306 14.8653C2.51432 15.1728 2.44762 15.5026 2.44679 15.836C2.44595 16.1693 2.51101 16.4995 2.63822 16.8076C2.76543 17.1157 2.95227 17.3956 3.18802 17.6313C3.42376 17.867 3.70376 18.0537 4.01191 18.1808C4.32007 18.3079 4.6503 18.3728 4.98363 18.3718C5.31696 18.3709 5.64682 18.3041 5.95423 18.1752C6.26165 18.0463 6.54057 17.858 6.77496 17.621L11.4342 12.9632Z"
        fill="#F7F6F4"
      />
      <path
        d="M8.84863 8.44868L12.398 11.998L15.9473 8.44868C14.935 7.65378 13.6851 7.22171 12.398 7.22171C11.1108 7.22171 9.86097 7.65378 8.84863 8.44868Z"
        fill="#F7F6F4"
      />
    </svg>
  )
}
