export function FacebookMessengerIcon({
  fill = '#95A7B0',
  width = '20',
  height = '20',
  className,
}: {
  readonly fill?: string
  readonly className?: string
  readonly width?: string
  readonly height?: string
}) {
  return (
    <svg
      width={width}
      className={className}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99967 0.832555C4.93738 0.832555 0.833008 4.63214 0.833008 9.32012C0.833008 11.9922 2.16524 14.3725 4.24682 15.9308V19.1659L7.36959 17.4532C8.20376 17.6824 9.08528 17.8077 9.99965 17.8077C15.0619 17.8077 19.1663 14.0073 19.1663 9.32085C19.1663 4.63439 15.0619 0.83252 9.99965 0.83252L9.99967 0.832555ZM10.9095 12.2626L8.57578 9.77235L4.02072 12.2626L9.03107 6.94366L11.4228 9.43318L15.9213 6.94366L10.9095 12.2626Z" />
    </svg>
  )
}
