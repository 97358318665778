import * as api from 'api'
import { Dispatch, store } from 'store/store'
import {
  listContactSegmentsAction,
  fetchFilteredContacts,
  clearFilterTask,
} from 'store/contact-segments/actions'
import { AxiosError } from 'typings/axios'
import { toastOnHttpError500or400 } from 'api/http'
import { IContactFilterRequestData } from 'api/request'
import { getLastTaskId } from 'store/contact-segments/selectors'

const RECIPIENTS_PER_PAGE = 20

export const listContactSegmentsAsync = (dispatch: Dispatch) => {
  dispatch(listContactSegmentsAction.request())
  return api
    .listContactSegments()
    .then(response => {
      dispatch(listContactSegmentsAction.success(response.data))
    })
    .catch((err: AxiosError) => {
      dispatch(listContactSegmentsAction.failure(err))
      toastOnHttpError500or400(err)
    })
}

export const fetchFilteredContactsAsync = (dispatch: Dispatch) => (
  importLabels: string[] = [],
  contactFilterId: number | undefined = undefined,
  contactFilterObj: IContactFilterRequestData | undefined = undefined,
  page: number,
  pageSize: number = RECIPIENTS_PER_PAGE,
  ordering: string = '-createdAt',
  searchQuery: string = '',
  taskId: string | undefined = undefined
) => {
  dispatch(fetchFilteredContacts.request(contactFilterObj))
  return api
    .getRecipients(
      importLabels,
      contactFilterId,
      contactFilterObj,
      page,
      pageSize,
      ordering,
      searchQuery,
      taskId
    )
    .then(res => {
      dispatch(fetchFilteredContacts.success(res.data))
    })
    .catch((err: AxiosError) => {
      dispatch(fetchFilteredContacts.failure(err))
      toastOnHttpError500or400(err)
    })
}

export const clearFilterTaskAsync = (dispatch: Dispatch) => () => {
  // Normally, we wouldn't reference the store in a thunk, but here it's
  // particularly useful to only send a network request if there actually is
  // task data to clear.
  const lastTaskId = getLastTaskId(store.getState())
  dispatch(clearFilterTask.request())
  if (lastTaskId) {
    api
      .deleteFilterTask(lastTaskId)
      .then(() => {
        dispatch(clearFilterTask.success())
      })
      .catch((err: AxiosError) => {
        dispatch(clearFilterTask.failure(err))
      })
  }
}
