import React from 'react'
import LanguageSelectDropdown from 'components/LanguageSelection/LanguageSelectionDropdown'
import { getLanguages } from 'store/embed/api'
import { useTranslation } from 'react-i18next'
import { HideShowContext } from 'embed/HideShowContext'

interface ILanguageDropdownConnectedProps {
  onChange: (language: string) => Promise<void>
  value: string
}
export const useLanguages = () => {
  const [languages, setLanguages] = React.useState<
    { code: string; name: string }[]
  >([])
  const { i18n } = useTranslation()
  React.useEffect(() => {
    if (languages.length === 0) {
      getLanguages(i18n.language).then(response => {
        if (!response.data.data) {
          return
        }
        setLanguages(
          response.data.data.languages.map(x => ({
            code: x.language,
            name: x.name,
          }))
        )
      })
    }
  }, [languages, i18n.language])
  return languages
}
const LanguageSelectDropdownConnected: React.FC<ILanguageDropdownConnectedProps> = props => {
  const languages = useLanguages()
  const { i18n } = useTranslation()

  const { hideShowStates, updateState } = React.useContext(HideShowContext)
  const [menuOpen, setMenuOpen] = React.useState(
    hideShowStates.LANGUAGE_DROPDOWN
  )

  const toggleMenu = React.useCallback(
    (open: boolean) => {
      setMenuOpen(open)
      updateState({ component: 'LANGUAGE_DROPDOWN', toShow: open })
    },
    [updateState]
  )

  return (
    <div tabIndex={-1}>
      <LanguageSelectDropdown
        languages={languages}
        value={props.value || i18n.language}
        menuOpen={menuOpen}
        toggleMenu={(open: boolean) => toggleMenu(open)}
        onSelect={async (language: string) => {
          // set language using i18n library
          await i18n.changeLanguage(language)
          await props.onChange(language)
        }}
      />
    </div>
  )
}
export default LanguageSelectDropdownConnected
