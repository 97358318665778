import { combineReducers } from 'redux'

import knowledgeReview from 'page/knowledge-base/KnowledgeReview/reducer'
import contactFeedback from 'page/knowledge-base/ContactFeedback/reducer'
import knowledgeBase from 'page/knowledge-base/reducer'

const reducers = combineReducers({
  knowledgeReview,
  contactFeedback,
  knowledgeBase,
})

export { reducers }
