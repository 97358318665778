import React from 'react'
import classNames from 'classnames'
import { Button } from 'components/Button/Button'
import { PageHeader } from 'components/PageHeader/PageHeader'

interface IIntegrationDescriptionProps {
  readonly description: React.ReactNode
  readonly icon: React.ReactNode
  readonly className?: string
}
export function IntegrationDescription({
  description,
  icon,
  className,
}: IIntegrationDescriptionProps) {
  return (
    <section
      className={classNames('row bg-blue-grey-050 m-0 px-2 py-4', className)}>
      <div className="col-6">{description}</div>
      <div className="mr-auto offset-2">{icon}</div>
    </section>
  )
}

interface IIntegrationPanel {
  readonly title: React.ReactNode
  readonly className?: string
  readonly children: React.ReactNode
  readonly includeButton?: boolean
  readonly buttonOnClick?: (e: React.MouseEvent<HTMLElement>) => void
  readonly loading?: boolean
  readonly loadingText?: string
  readonly buttonText?: string
}
export function IntegrationPanel({
  title,
  className,
  children,
  includeButton = false,
  loading = false,
  loadingText,
  buttonText,
  buttonOnClick,
}: IIntegrationPanel) {
  return (
    <section className={className}>
      <h3 className={classNames('d-flex flex-wrap justify-content-between')}>
        <div className="w-100 d-flex align-items-center justify-content-between">
          <PageHeader className="mb-2" title={title} />
          {includeButton ? (
            <div>
              <Button
                loading={loading}
                loadingText={loadingText}
                color="secondary-teal"
                onClick={buttonOnClick}>
                {buttonText}
              </Button>
            </div>
          ) : null}
        </div>
      </h3>
      {children}
    </section>
  )
}
