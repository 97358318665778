import * as React from 'react'
import { PopoverComponent } from 'components/PopoverComponent/PopoverComponent'
import classnames from 'classnames'
import 'components/PopoutMenu/PopoutMenu.scss'

interface IPopoutMenuProps {
  readonly icon: React.ReactNode
  readonly label: React.ReactNode
  readonly isActive?: boolean
}

const PopoutMenuLink: React.FC<{
  active: boolean
  icon: React.ReactNode
  onClick: () => void
}> = props => {
  const className = classnames('global-nav-link', {
    'global-nav-link-active': props.active,
  })
  return (
    <button className={className} onClick={props.onClick}>
      <span className="nav-icon">{props.icon}</span>
      {props.children && !props.active && (
        <span className="nav-label">{props.children}</span>
      )}
    </button>
  )
}

const PopoutMenu: React.FC<IPopoutMenuProps> = props => {
  const [shown, setShown] = React.useState(false)

  return (
    <PopoverComponent
      visible={shown}
      tabIndex={-1}
      popoverPlacement="auto"
      modifiers={{
        preventOverflow: {
          boundariesElement: 'viewport',
        },
      }}
      renderReference={() => (
        <PopoutMenuLink
          onClick={() => setShown(() => true)}
          active={shown || !!props.isActive}
          icon={props.icon}>
          {props.label}
        </PopoutMenuLink>
      )}
      onClickOutside={() => setShown(() => false)}
      renderPopper={() => (
        <div className="popout-menu pt-3 px-3 pb-3">{props.children}</div>
      )}
    />
  )
}

export default PopoutMenu
