import { RootState as IState } from 'store/store'

export interface IContactSegmentShort {
  id: number
  name: string
  created: Date
  modified: Date
}

export interface IContactSegmentList extends Array<IContactSegmentShort> {}

export const listContactSegments = (state: IState) => {
  return state.contactSegments.segments
}

export const getFilteredContacts = (state: IState) =>
  state.contactSegments.filteredContacts

export const getTotalRecipients = (state: IState) =>
  state.contactSegments.totalRecipients

export const getAttributeNames = (state: IState) =>
  state.contactSegments.attributeNames

export const getAdditionalContactFields = (state: IState) =>
  state.contactSegments.additionalContactFields

export const getCountFilteredContactsPager = (state: IState) =>
  state.contactSegments.countForMaxPage

export const getLastTaskId = (state: IState) => state.contactSegments.lastTaskId

export const getLastTaskFilterData = (state: IState) =>
  state.contactSegments.lastTaskFilterData
