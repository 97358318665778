import React from 'react'
import ClassNames from 'classnames'

import 'components/ContainerButton/ContainerButton.scss'
import {
  EventTrackerAttrAdder,
  IEventTrackerEventDetail,
} from 'components/EventTracker/EventTracker'

type ContainerButtonProps = React.ComponentProps<'button'> &
  IEventTrackerEventDetail

/**
 * A button which tries to not effect any styling or layout at all. Its main purpose is to house UI elements which can be clicked, so that browsers are able to correctly provide accessibility features.
 * @params props - https://github.com/DefinitelyTyped/DefinitelyTyped/issues/36505#issuecomment-521404298
 */
export const ContainerButton = React.forwardRef<
  HTMLButtonElement,
  ContainerButtonProps
>((props, ref) => {
  const { eventAction, eventObject, eventLocation, ...btnProps } = props
  return (
    <EventTrackerAttrAdder
      eventAction={eventAction}
      eventObject={eventObject}
      eventLocation={eventLocation}
      disabled={props.disabled}>
      <button
        {...btnProps}
        className={ClassNames('container-button', btnProps.className, {
          pointer: !btnProps.disabled,
          'not-allowed': btnProps.disabled,
        })}
        ref={ref}>
        {btnProps.children}
      </button>
    </EventTrackerAttrAdder>
  )
})
