import * as React from 'react'
import ClassNames from 'classnames'

interface IMenuProps {
  className?: string
}
export class Menu extends React.PureComponent<IMenuProps> {
  render() {
    const classNames = ClassNames(
      'list-group',
      'list-group-flush',
      'border-radius',
      'shadow',
      this.props.className
    )
    return <ul className={classNames}>{this.props.children}</ul>
  }
}
