import classNames from 'classnames'

interface IFancyTextCounterProps {
  readonly value: string
  readonly countTextType?: string
  readonly softCap: number
  readonly className?: string
  readonly isApproximate?: boolean
}

export function FancyTextCounter({
  value,
  countTextType,
  softCap,
  className,
  isApproximate = false,
}: IFancyTextCounterProps) {
  return (
    <span
      className={classNames(
        'px-1 text-mainstay-dark-blue-50 fs-14 align-items-center d-flex',
        className
      )}>
      <span className={countTextType}>{`${isApproximate ? '~ ' : ''}${
        value ? value.length : 0
      }`}</span>
      <span>{`/${softCap}`}</span>
    </span>
  )
}
