import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import 'components/ContactPanel/ContactPanel.scss'
import { fetchContactAsync, updateContactAsync } from 'store/contacts/thunks'
import { IFullContact, IContact } from 'store/contacts/reducer'
import { LocationDescriptor } from 'history'
import {
  getActiveContactLoading,
  getActiveContact,
  getContactIsPermitted,
  getPermittedUserStatusMutable,
} from 'store/contacts/selectors'
import {
  EditableContactPanel,
  IContactFormData,
} from 'components/ContactPanel/EditableContactPanel'
import { SOFT_STOP_ID } from 'const/settings'
import { IUpdateContactInfoPanelRequest } from 'api'
import { ReadOnlyContactPanel } from 'components/ContactPanel/ReadOnlyContactPanel'
import { getAllContactAttributesAsync } from 'store/personalization/contactAttributes/thunks'
import { getAllContactAttributes } from 'store/personalization/contactAttributes/selectors'
import { isInitial, isSuccess } from 'store/webdata'
import { CenteredLoader } from 'components/Loader/Loader'
import { useDispatch, useSelector } from 'util/hooks'

interface IContactPanelContainerProps {
  readonly header?: React.ReactNode
  readonly children: React.ReactNode
  readonly className?: string
}

export function ContactPanelContent({
  header,
  children,
  className,
}: IContactPanelContainerProps) {
  return (
    <aside className="panel panel-last d-none d-lg-flex height-100">
      {header}
      <section className="panel-content pl-0 pr-0 pb-1">
        <div className={classnames('align-items-center', className)}>
          {children}
        </div>
      </section>
    </aside>
  )
}

export function contactIsOptedOut(contact: IFullContact): boolean {
  return Boolean(
    (contact._contactSettings && contact._contactSettings.canText === false) ||
      (contact._contactSettings &&
        contact._contactSettings.permanentlySoftStopped &&
        contact._dialog &&
        contact._dialog.id === SOFT_STOP_ID)
  )
}

export function contactIsPaused(contact: IFullContact): boolean {
  return Boolean(
    contact._dialog &&
      contact._dialog.id === SOFT_STOP_ID &&
      contact._contactSettings &&
      !contact._contactSettings.permanentlySoftStopped
  )
}

interface IContactPanelProps {
  readonly contactId: string | undefined
  readonly editOnOpen?: boolean
  readonly className?: string
  readonly closeReturnLocation?: LocationDescriptor
  readonly onClose?: () => void
  readonly createContact?: (data: Partial<IContactFormData>) => void
}

export const ContactPanel = ({
  contactId,
  editOnOpen,
  className,
  closeReturnLocation,
  onClose,
  createContact,
}: IContactPanelProps) => {
  const dispatch = useDispatch()

  const loading = useSelector(getActiveContactLoading)
  const contactAttributes = useSelector(getAllContactAttributes)
  const contact = useSelector(getActiveContact)
  const contactIsArchived = !useSelector(getContactIsPermitted)
  const permittedUserStatusMutable = useSelector(getPermittedUserStatusMutable)

  const [edit, setEdit] = useState(editOnOpen || false)
  const [saving, setSaving] = useState(false)

  const handleEditSave = async (
    contactId: IContact['id'],
    contactData: IUpdateContactInfoPanelRequest['data']
  ) => {
    setSaving(true)
    const res = await updateContactAsync(dispatch)(contactId, contactData)
    setSaving(false)
    if (res === 'ok') {
      setEdit(false)
    }
  }

  useEffect(() => {
    if (contactId && (!contact || contactId !== contact.id)) {
      fetchContactAsync(dispatch)(contactId)
    }
  }, [contact, dispatch, contactId])

  useEffect(() => {
    if (isInitial(contactAttributes)) {
      getAllContactAttributesAsync(dispatch)()
    }
  }, [contactAttributes, dispatch])

  if (loading) {
    return <CenteredLoader className="mt-5 w-100" />
  }

  if (!contact && contactId) {
    return <h4 className="w-100 text-align-center">No contact found</h4>
  }

  // Create Mode
  if (!contact && !contactId && createContact && edit) {
    return (
      <EditableContactPanel
        className={className}
        contact={undefined}
        onSave={(contactData: Partial<IContactFormData>) =>
          createContact(contactData)
        }
        onCancel={() => setEdit(false)}
        contactIsArchived={false}
        permittedUserStatusMutable={true}
        newContact={true}
        saving={saving}
      />
    )
  }

  // Edit Mode
  if (contact && edit) {
    return (
      <EditableContactPanel
        className={className}
        contact={contact}
        onSave={contactData => handleEditSave(contact.id, contactData)}
        onCancel={() => setEdit(false)}
        contactIsArchived={contactIsArchived}
        permittedUserStatusMutable={permittedUserStatusMutable}
        newContact={false}
        saving={saving}
      />
    )
  }

  // Readonly Mode
  if (contact && !edit) {
    return (
      <ReadOnlyContactPanel
        contact={contact}
        className={className}
        closeReturnLocation={closeReturnLocation}
        onClickEdit={
          isSuccess(contactAttributes) ? () => setEdit(true) : undefined
        }
        contactIsPermitted={!contactIsArchived}
        onClose={onClose}
      />
    )
  }

  return <></>
}

export default ContactPanel
