import * as React from 'react'
import classNames from 'classnames'
import { ModifierColor } from 'components/Button/Button'
import 'components/Badge/Badge.scss'

interface IBadgeProps {
  readonly className?: string
  readonly color?: ModifierColor
  readonly children: React.ReactNode
  readonly pill?: boolean
}

export function Badge(props: IBadgeProps) {
  const colorClass = props.color ? 'badge-' + props.color : ''
  const cls = classNames(
    'badge',
    'caption',
    'm-0',
    'align-bottom',
    colorClass,
    { 'badge-pill': props.pill },
    props.className
  )
  return <div className={cls}>{props.children}</div>
}
