import { IIconProps } from 'components/Icons/utils'

interface ISortArrowProps extends IIconProps {
  readonly direction: 'asc' | 'desc' | undefined
}

const SortArrow = ({
  direction,
  className,
  width = '16',
  height = '16',
}: ISortArrowProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        {direction === 'desc' ? (
          <path
            d="M7.88309 0.00999117L8.00008 0.00299072C8.24517 0.0030518 8.4817 0.0931157 8.66476 0.25608C8.84782 0.419044 8.96465 0.643559 8.99308 0.886991L9.00008 1.00299V12.587L11.2931 10.293C11.4652 10.1207 11.6942 10.0171 11.9372 10.0016C12.1802 9.98612 12.4206 10.0599 12.6131 10.209L12.7071 10.293C12.8794 10.4651 12.983 10.6941 12.9985 10.9371C13.014 11.1801 12.9402 11.4205 12.7911 11.613L12.7071 11.707L8.71109 15.707C8.53902 15.8793 8.30999 15.9829 8.06696 15.9984C7.82393 16.0139 7.58361 15.9401 7.39108 15.791L7.29708 15.707L3.29309 11.707C3.11183 11.5274 3.00605 11.2854 2.9974 11.0304C2.98875 10.7755 3.07788 10.5268 3.24655 10.3354C3.41522 10.144 3.65067 10.0243 3.90471 10.0008C4.15875 9.97729 4.41216 10.0518 4.61308 10.209L4.70708 10.293L7.00008 12.582V1.00299C7.00012 0.758057 7.09004 0.521654 7.2528 0.338619C7.41556 0.155584 7.63983 0.0386484 7.88309 0.00999117L8.00008 0.00299072L7.88309 0.00999117Z"
            fill="#2E3B40"
          />
        ) : null}
        {direction === 'asc' ? (
          <path
            d="M8.11423 15.9934L7.99723 16.0004C7.75214 16.0003 7.51561 15.9102 7.33255 15.7473C7.1495 15.5843 7.03266 15.3598 7.00423 15.1164L6.99723 15.0004L6.99723 3.41637L4.70423 5.71037C4.53216 5.88269 4.30313 5.9863 4.0601 6.00177C3.81707 6.01724 3.57675 5.94349 3.38423 5.79437L3.29023 5.71037C3.1179 5.5383 3.01429 5.30927 2.99882 5.06624C2.98336 4.82321 3.05711 4.58289 3.20623 4.39037L3.29023 4.29637L7.28623 0.296366C7.4583 0.124042 7.68733 0.0204265 7.93036 0.00496101C8.17339 -0.0105045 8.41371 0.0632431 8.60623 0.212366L8.70023 0.296366L12.7042 4.29637C12.8855 4.47591 12.9913 4.71794 12.9999 4.97291C13.0086 5.22789 12.9194 5.47653 12.7508 5.66795C12.5821 5.85936 12.3466 5.97907 12.0926 6.00257C11.8386 6.02607 11.5852 5.95159 11.3842 5.79437L11.2902 5.71037L8.99723 3.42137L8.99723 15.0004C8.9972 15.2453 8.90727 15.4817 8.74451 15.6647C8.58176 15.8478 8.35748 15.9647 8.11423 15.9934L7.99723 16.0004L8.11423 15.9934Z"
            fill="#2E3B40"
          />
        ) : null}
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SortArrow
