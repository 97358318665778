import { RootState as IState } from 'store/store'
import { isSuccess, unWrap } from 'store/webdata'

export enum BotType {
  academicSuccess = 'Academic Success',
  active = 'Active',
  collegeAccess = 'College Access',
  communityCollege = 'Community College',
  custom = 'Custom',
  demo = 'Demo',
  freeLiveChat = 'Free Live Chat',
  graduate = 'Graduate',
  granduateAndUndergraduate = 'Graduate & Undergraduate',
  internal = 'Internal',
  openEnrollmentRetention = 'Open Enrollment & Retention',
  recruitEnroll = 'Recruit & Enroll',
  retention = 'Retention',
  sandbox = 'Sandbox',
  workforce = 'Workforce',
}

export enum BotStatus {
  active = 'Active',
  internal = 'Internal',
  retired = 'Retired',
}

export const getInstitutions = (state: IState) => {
  return isSuccess(state.institutions.institutions)
    ? unWrap(state.institutions.institutions)
    : []
}
