import numeral from 'numeral'
import * as queryString from 'query-string'
import * as Raven from '@sentry/browser'

/** Utility function that replaces a range of characters within a string with a specified substring.
 * Used for replacing a range of characters within a textarea with a pasted/selected value. Accepts
 * falsey values to account empty inputs.
 *
 * @param value string to replace a range of characters in, or undefined
 * @param start start of the replacement range
 * @param end end of the replacement range
 * @param replacement the value to replace the selected range with
 *
 * See also: `MessageSender`
 */
export function replaceRange(
  value: string | undefined,
  start: number,
  end: number,
  replacement: string
): string {
  if (!value || value === undefined || value.length === 0) {
    return replacement
  }
  const before = value.substr(0, Math.max(start, 0))
  const after = value.substr(Math.min(end, value.length))
  return `${before}${replacement}${after}`
}

export function parseIntOrNull(s: string | null): number | null {
  if (s == null) {
    return s
  }
  const res = parseInt(s, 10)
  if (isNaN(res)) {
    return null
  }
  return res
}

export function toTitleCase(str: string) {
  return str
    .split(' ')
    .map((subString: string) => {
      return subString.charAt(0).toUpperCase() + subString.slice(1)
    })
    .join(' ')
}

/** normalize string for naive search */
export function normalize(s: string): string {
  return s.toLowerCase().replace(/\s+/g, '')
}

export const formatNumber = (n: number): string => numeral(n).format('0,0')

interface IOptionalObject {
  [a: string]: string | undefined
}

export function queryStringValue(
  query: string,
  key: string
): string | undefined {
  /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
  return (queryString.parse(query) as IOptionalObject)[key]
}

export function queryStringArrayValue(query: string, key: string): string[] {
  /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
  const selectedTagsQuery = (queryString.parse(query) as IOptionalObject)[key]
  return typeof selectedTagsQuery === 'string'
    ? [selectedTagsQuery]
    : selectedTagsQuery || []
}

interface IParsedQueryString {
  [key: string]: string | undefined
}

type QueryStringValue = string | number | undefined
interface IUpdateQueryString {
  [key: string]: QueryStringValue | QueryStringValue[]
}

/** Update a query string with provided params.
 * This does not update the browser url. It takes in a query string and gives a
 * new one updated with the provided params.
 *
 * @example
 *  updateQueryString({id: 'abc'}, '?org=1234') == 'id=abc&org=1234'
 */
export function updateQueryString(params: IUpdateQueryString, search: string) {
  /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
  const parsed = queryString.parse(search) as IParsedQueryString
  return queryString.stringify({ ...parsed, ...params })
}

/** Remove trailing slash from a string.
 * Only remove trailing slash if it exists
 *
 * @example
 *  removeTrailingSlash("/foo/") == '/foo'
 *
 */
export function removeTrailingSlash(url: string): string {
  return url.endsWith('/') ? url.slice(0, -1) : url
}

/** remove all forward slashes from string */
export const removeSlashes = (x: string) => x.replace(/\//g, '')

/** Wrapper around query string parser so autocomplete works a bit better.
 * @example
 * parseQueryString("?foo=bar") == {foo: "bar"}
 */
export function parseQueryString(qs: string) {
  return queryString.parse(qs)
}

/** Convert query-string result to an array. */
export function queryParamToArray(x: string | string[] | undefined): string[] {
  if (Array.isArray(x)) {
    return x
  }
  if (x == null) {
    return []
  }
  return [x]
}

/** Find the first query param for a key or null. */
export function parseFirstQueryParam(
  x: string | string[] | undefined
): string | null {
  return queryParamToArray(x)[0] || null
}

export const enumKeyFromString = <T extends Record<string, string>>(
  _key: string,
  _enum: T
) => {
  /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
  const enumKey = Object.keys(_enum).find(k => k === _key) as keyof T
  if (!enumKey) {
    Raven.captureException('Invalid enum key found')
  }
  return enumKey
}
