import {
  IContactAttribute,
  ContactAttributeType,
} from 'store/personalization/contactAttributes/selectors'
import { FieldProps } from 'formik'
import moment from 'moment-timezone'
import isDate from 'lodash/isDate'
import strftime from 'strftime'
import { TextInput } from 'components/TextInput/TextInput'
import { ToggleSwitchV2 } from 'components/ToggleSwitch/ToggleSwitch'
import 'react-day-picker/lib/style.css'
import { IContactFormData } from 'components/ContactPanel/EditableContactPanel'
import classnames from 'classnames'
import { ReadOnlyDatePicker } from 'components/ReadOnlyDatePicker/ReadOnlyDatePicker'
import { getInstitutionDateFormat } from 'store/triage/institution/selectors'
import { useSelector } from 'util/hooks'
import { EventTrackerAttrAdder } from 'components/EventTracker/EventTracker'
import Select from 'components/Select/SelectV2'

interface IContactAttributeValueFieldProps {
  attribute: IContactAttribute
  fieldProps: FieldProps<IContactFormData>
  error?: string
}
export const ContactAttributeValueInputField = ({
  attribute,
  fieldProps,
  error,
}: IContactAttributeValueFieldProps) => {
  const { field, form } = fieldProps
  const fieldName = `attributeValues.${attribute.id}`
  // tslint:disable-next-line no-unsafe-any
  const fieldValue: string | undefined = field?.value?.value
  const attrName = attribute.field
  const dateFormat = useSelector(getInstitutionDateFormat)
  switch (attribute.type) {
    case ContactAttributeType.DATE:
      const value = fieldValue
        ? moment(String(fieldValue)).toDate()
        : fieldValue
      return (
        <ReadOnlyDatePicker
          classNames={{
            container: 'w-100',
            overlayWrapper:
              'DayPickerInput-OverlayWrapper daypicker-contact-panel-overlay-wrapper',
            overlay: 'DayPickerInput-Overlay daypicker-contact-panel-overlay',
          }}
          onDayChange={(e: Date) => {
            form.setFieldValue(fieldName, {
              name: attrName,
              value: moment(e).format(moment.HTML5_FMT.DATE),
            })
          }}
          value={
            value && isDate(value) ? strftime(dateFormat, value) : undefined
          }
        />
      )
    case ContactAttributeType.MULTI_CHOICE:
      return (
        <Select
          {...field}
          eventAction="change"
          eventObject="custom field"
          classNamePrefix="react-select"
          value={{ label: fieldValue, value: fieldValue }}
          name={attribute.field}
          placeholder={attribute.field}
          options={(attribute.options ?? []).map(({ value }) => ({
            label: value,
            value,
          }))}
          components={{
            IndicatorSeparator: undefined,
          }}
          onChange={option => {
            form.setFieldValue(fieldName, {
              name: attrName,
              value: option && !Array.isArray(option) && option.value,
            })
          }}
        />
      )
    case ContactAttributeType.BOOLEAN:
      return fieldValue ? (
        <ToggleSwitchV2
          size="sm"
          eventAction="change"
          eventObject="custom field"
          checked={fieldValue === 'True'}
          onChange={checked => {
            form.setFieldValue(fieldName, {
              name: attrName,
              value: checked ? 'True' : 'False',
            })
          }}
        />
      ) : (
        <div className="text-muted small">
          No value set.{' '}
          <EventTrackerAttrAdder
            eventAction="change"
            eventObject="custom field">
            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                form.setFieldValue(fieldName, {
                  name: attrName,
                  value: 'False',
                })
              }}>
              Enable Toggle
            </a>
          </EventTrackerAttrAdder>
        </div>
      )
    case ContactAttributeType.EMAIL:
    case ContactAttributeType.NUMBER:
    case ContactAttributeType.PHONE:
    case ContactAttributeType.TEXT:
    case ContactAttributeType.URL:
    default:
      return (
        <>
          <TextInput
            {...field}
            eventAction="change"
            eventObject="custom field"
            value={fieldValue}
            className={classnames('pr-3', { 'text-danger': !!error })}
            id={fieldName}
            error={!!error}
            onChange={e =>
              form.setFieldValue(fieldName, {
                name: attrName,
                value: e.target.value,
              })
            }
          />
          {error && <div className="mb-1 small text-danger">{error}</div>}
        </>
      )
  }
}
