import * as React from 'react'
import classNames from 'classnames'
import Loader from 'components/Loader/Loader'
import { omit } from 'lodash'
import 'components/Button/Button.scss'
import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'
import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import Tooltip from 'components/Tooltip/Tooltip'

export type ModifierColor =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'
  | 'warning'
  | 'danger'
  | 'link'
  | 'light'
  | 'dark'
  | 'archived-badge-color'
  | 'blue-grey-005'
  | 'mainstay-dark-blue-10'
  | 'mainstay-dark-blue-65'
  | 'new-ui-danger'
  | 'white'
  | 'secondary-teal'
  | 'mainstay-success-700'
  | 'mainstay-gray-labels'
  | 'mainstay-error-red'
  | 'mainstay-teal'

export type ButtonColor = ModifierColor

export type ButtonType = 'submit' | 'reset' | 'button'

export type ButtonSize = 'large' | 'medium' | 'small' | 'outline-small'

export interface IButtonProps
  extends React.InputHTMLAttributes<HTMLButtonElement> {
  block?: boolean
  color?: ButtonColor
  height?: ButtonSize
  loading?: boolean
  readonly outlined?: boolean
  spinner?: boolean
  loadingText?: React.ReactNode
  innerRef?: React.Ref<HTMLButtonElement>
  type?: ButtonType
  active?: boolean
  eventLocation?: string
  eventObject?: string
  eventAction?: EventAction
}

function getSize(size: ButtonSize | null | undefined): string {
  switch (size) {
    case 'large':
      return 'lg'
    case 'small':
      return 'sm'
    case 'outline-small':
      return 'outline-sm'
    case 'medium':
      return 'md'
    default:
      return ''
  }
}

class InnerButton extends React.PureComponent<IButtonProps, {}> {
  static defaultProps = {
    className: '',
    type: 'button',
    loadingText: 'loading...',
  }
  render() {
    const className = classNames('btn', this.props.className, {
      [`btn-${this.props.outlined ? 'outline-' : ''}${this.props.color}`]:
        this.props.color != null,
      [`btn-${getSize(this.props.height)}`]: this.props.height != null,
      'btn-block': this.props.block,
      active: this.props.active,
    })

    const props = omit(this.props, [
      'block',
      'loading',
      'loadingText',
      'color',
      'height',
      'spinner',
      'innerRef',
      'outlined',
    ])
    const { eventLocation, eventAction, eventObject, ...buttonProps } = props

    const loading = this.props.spinner ? <Loader /> : this.props.loadingText
    return (
      <EventTrackerAttrAdder
        eventAction={eventAction}
        eventObject={eventObject}
        eventLocation={eventLocation}
        disabled={this.props.disabled}>
        <button
          {...buttonProps}
          ref={this.props.innerRef}
          className={className}
          disabled={this.props.disabled || this.props.loading}>
          {this.props.loading ? loading : this.props.children}
        </button>
      </EventTrackerAttrAdder>
    )
  }
}

// Forward refs to button element. This is required for things like <Tooltip/>
// to function.
export const Button = React.forwardRef(
  (props: IButtonProps, ref: React.Ref<HTMLButtonElement>) => (
    <InnerButton {...props} innerRef={ref} />
  )
)

export const AdmithubOnlyButton = (
  props: IButtonProps & {
    allowEngineeringOnly?: boolean
    allowStaffOnly?: boolean
  }
) => {
  const { allowEngineeringOnly, allowStaffOnly, className, ...btnProps } = props
  return (
    <AdmithubOnly
      allowEngineeringOnly={allowEngineeringOnly}
      allowStaffOnly={allowStaffOnly}
      fallback={<></>}>
      <Tooltip content="This feature is only available to Mainstay users">
        <Button
          className={classNames(
            className,
            'bg-warning color-mainstay-dark-blue-80'
          )}
          {...btnProps}
        />
      </Tooltip>
    </AdmithubOnly>
  )
}
