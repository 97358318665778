export function CaretIcon({
  fill = '#092E63',
  onClick,
  className,
  expanded,
}: {
  readonly fill?: string
  readonly onClick?: () => void
  readonly className?: string
  readonly expanded?: boolean
}) {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="5"
      height="8"
      viewBox="0 0 5 8"
      fill="none"
      style={{ transform: expanded ? 'rotate(90deg)' : '' }}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.488007 7.76221L0.488006 0.238956L4.25001 4.00096L0.488007 7.76221Z"
        fill={fill}
      />
    </svg>
  )
}
