import { createAsyncAction, ActionType } from 'typesafe-actions'
import { IUser } from 'store/triage/users/reducer'
import { IApiError } from 'api'

export const fetchUsers = createAsyncAction(
  '@@MASCOT/FETCH_USERS_START',
  '@@MASCOT/FETCH_USERS_SUCCESS',
  '@@MASCOT/FETCH_USERS_FAILURE'
)<undefined, IUser[], IApiError>()

export const addUser = createAsyncAction(
  '@@MASCOT/ADD_USER_START',
  '@@MASCOT/ADD_USER_SUCCESS',
  '@@MASCOT/ADD_USER_FAILURE'
)<void, IUser, void>()

export const removeUser = createAsyncAction(
  '@@MASCOT/REMOVE_USER_START',
  '@@MASCOT/REMOVE_USER_SUCCESS',
  '@@MASCOT/REMOVE_USER_FAILURE'
)<string, string, void>()

export const resendUserInvite = createAsyncAction(
  '@@MASCOT/RESEND_USER_INVITE_START',
  '@@MASCOT/RESEND_USER_INVITE_SUCCESS',
  '@@MASCOT/RESEND_USER_INVITE_FAILURE'
)<string, void, void>()

export type IUserActions =
  | ActionType<typeof fetchUsers>
  | ActionType<typeof addUser>
  | ActionType<typeof removeUser>
  | ActionType<typeof resendUserInvite>
