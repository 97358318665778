import * as React from 'react'
import { Link } from 'util/routing'
import { AUTH } from 'const/routes'

export interface IUserMenuProps {
  readonly name: string | null
  readonly isLoggingOut: boolean
  onLogout: () => Promise<void>
}

const UserMenu: React.FC<IUserMenuProps> = props => (
  <div className="user-menu">
    <small className="text-mainstay-cream-30">User</small>
    <h5 className="text-mainstay-cream-30 fs-16px font-weight-normal">
      {props.name}
    </h5>
    <hr className="horizontal-line-mainstay-almost-black" />
    <ul className="list-unstyled m-0">
      <li>
        <Link
          className="pt-2 pb-2 fs-16px settings-menu-link"
          to={AUTH.ACCOUNT}>
          Account
        </Link>
      </li>
    </ul>
    <hr className="horizontal-line-mainstay-almost-black" />
    <ul className="list-unstyled m-0">
      <li>
        <a
          href="#"
          className="pt-2 pb-2 fs-16px settings-menu-link"
          onClick={props.onLogout}>
          Logout
        </a>
      </li>
    </ul>
  </div>
)

export default UserMenu
