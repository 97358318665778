import { createAsyncAction, ActionType } from 'typesafe-actions'
import { IGetBotResponse } from 'store/embed/api'

export const getBotAction = createAsyncAction(
  '@@MASCOT/WEBCHAT/GET_BOT_START',
  '@@MASCOT/WEBCHAT/GET_BOT_SUCCESS',
  '@@MASCOT/WEBCHAT/GET_BOT_ERROR'
)<
  { botToken?: string; userId?: string; messagingService?: string },
  IGetBotResponse,
  void
>()

export type IWebChatActions = ActionType<typeof getBotAction>
