import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import Tooltip from 'components/Tooltip/Tooltip'
import React from 'react'
import classnames from 'classnames'

interface ICollapsibleSection {
  title: string | React.ReactElement
  info?: React.ReactElement | string
  children: React.ReactNode
  headerChildren?: React.ReactNode
  headerRef?: React.Ref<HTMLDivElement>
  startExpanded?: boolean
  className?: string
  alignItems?: 'center' | 'start'
  overrideMargin?: boolean
  onClick?: () => void
  chevronClassName?: string
}

interface ICollapsibleSectionHeader {
  title: React.ReactElement | string
  info?: React.ReactElement | string
  innerRef?: React.Ref<HTMLDivElement>
  titleClassName?: string
}

export const CollapsibleSectionHeader: React.FC<ICollapsibleSectionHeader> = ({
  title,
  info,
  children,
  innerRef,
  titleClassName,
}) => {
  return (
    <div
      className="d-flex align-items-center justify-content-between w-100"
      ref={innerRef}>
      <div className="d-flex align-items-center">
        <div
          className={
            titleClassName ||
            'm-0 mainstay-header-h4 text-mainstay-dark-blue-80'
          }>
          {title}
        </div>
        {info && (
          <Tooltip content={info}>
            <AHIcon
              className="text-muted text-mainstay-dark-blue-65"
              name="help"
            />
          </Tooltip>
        )}
      </div>
      <div>{children}</div>
    </div>
  )
}

export const CollapsibleSection = ({
  title,
  info,
  children,
  headerChildren,
  headerRef,
  className,
  startExpanded = true,
  alignItems = 'center',
  overrideMargin = false,
  onClick,
  chevronClassName,
}: ICollapsibleSection) => {
  const [expanded, setExpanded] = React.useState(startExpanded)

  return (
    <>
      <div
        className={classnames(
          'd-flex pointer mt-4',
          `align-items-${alignItems}`,
          className
        )}
        onClick={() => {
          if (onClick) {
            onClick()
          }
          setExpanded(!expanded)
        }}>
        <AHIcon
          name={expanded ? 'expand_more' : 'navigate_next'}
          className={classnames('text-mainstay-dark-blue-65', chevronClassName)}
        />
        <CollapsibleSectionHeader
          title={title}
          info={info}
          innerRef={headerRef}
          children={headerChildren || undefined}
        />
      </div>
      <div className={classnames({ 'ml-4 mr-1 pl-2': !overrideMargin })}>
        {expanded && children}
      </div>
    </>
  )
}
