import { LaptopIcon } from 'components/Icons/LaptopIcon/LaptopIcon'
import { MobileIcon } from 'components/Icons/MobileIcon/MobileIcon'
import { SlackIcon } from 'components/Icons/SlackIcon/SlackIcon'
import { FacebookMessengerIcon } from 'components/Icons/FacebookMessengerIcon/FacebookMessengerIcon'
import { assertNever } from 'util/exhaustiveness'
import { TransportId } from 'store/transport'
import 'components/Icons/TranspotIcon/TransportIcon.scss'
import 'page/dashboard/ImportantTopics/ImportantTopics.scss'
import classNames from 'classnames'

export function TransportIcon({
  transport,
  isOffline,
  className,
}: {
  readonly transport: TransportId | 'unknown'
  readonly isOffline: boolean
  readonly className?: string
}): JSX.Element | null {
  const transportClassName = classNames('conv-v2-transport-icon', className)
  switch (transport) {
    case 'twilio': {
      return (
        <MobileIcon width="16" height="20" className={transportClassName} />
      )
    }
    case 'web': {
      return (
        <LaptopIcon
          width="24"
          height="20"
          className={transportClassName}
          online={!isOffline}
        />
      )
    }
    case 'facebook': {
      return (
        <FacebookMessengerIcon
          width="24"
          height="20"
          className={transportClassName}
        />
      )
    }
    case 'slack':
      return <SlackIcon width="24" height="20" className={transportClassName} />
    case 'unknown':
      return null
    default:
      return assertNever(transport)
  }
}
