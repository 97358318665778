import { Link } from 'util/routing'
import cls from 'classnames'

export interface ILinkWithMiddleEllipsisProps {
  target: string | null
  displayText?: string | null
  isInternal?: boolean
  offset?: number
  className?: string
}

/**
 * Link component that has the ellipsis in the middle to help display longer strings.
 * eg:
 *     - https://www.britannica.co...hemical-element
 *     - Boron | Properties, Use... Facts | Britannica
 */
export const LinkWithMiddleEllipsis = ({
  target,
  className,
  displayText,
  isInternal = true,
  offset = 15,
}: ILinkWithMiddleEllipsisProps) => {
  if (!target) {
    return null
  }
  const linkText = displayText ?? target
  const linkClassName = cls(
    'mid-ellipsis-link d-flex text-secondary-teal fs-0_8rem',
    className
  )
  if (isInternal) {
    return (
      <Link target="_blank" className={linkClassName} to={target}>
        <span className="d-inline-block text-ellipsis">
          {linkText.slice(0, linkText.length - offset)}
        </span>
        <span className="d-inline-block no-wrap">
          {linkText.slice(-1 * offset)}
        </span>
      </Link>
    )
  }
  return (
    <a className={linkClassName} target="_blank" rel="noreferrer" href={target}>
      <span className="d-inline-block text-ellipsis">
        {linkText.slice(0, linkText.length - offset)}
      </span>
      <span className="d-inline-block no-wrap">
        {linkText.slice(-1 * offset)}
      </span>
    </a>
  )
}
