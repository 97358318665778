import React from 'react'
import NavBarContainer from 'page/NavBarPage'
import { Row, Col } from 'reactstrap'
import { TextInput } from 'components/TextInput/TextInput'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { Badge } from 'components/Badge/Badge'
import * as Api from 'api'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { fetchCurrentInstitutionAsync } from 'store/triage/institution/thunks'
import { Dispatch, RootState } from 'store/store'
import {
  getFullCurrentInstitution,
  getCurrentInstitutionStatus,
} from 'store/triage/institution/selectors'
import { IInstitution } from 'api/response'
import {
  WebData,
  Loading,
  Success,
  isFailure,
  isUnresolved,
} from 'store/webdata'

import ContactAuthSettings from 'components/ContactAuthSettings/ContactAuthSettings'
import { CenteredLoader } from 'components/Loader/Loader'
import {
  FeaturesType,
  AVAILABLE_FEATURES,
  IFeature,
} from 'components/Feature/Feature'
import { InstitutionSettingUpdateField } from 'components/SettingsInstitutionUpdateField/SettingsInstitutionUpdateField'
import { InstitutionMetadataUpdateField } from 'components/SettingsInstitutionUpdateField/SettingsInstitutionMetadataUpdateField'
import { TrustedEmailDomainsForm } from 'components/SettingsTrustedEmailDomainForm/SettingsTrustedEmailDomainForm'
import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import { PrependedEscalatedMesageResponseForm } from 'components/SettingsPrependedEscalatedMessageResponseForm/SettingsPrependedEscalatedMessageResponseForm'

import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch'
import classnames from 'classnames'
import { IntegrationPanel } from 'components/IntegrationPanel/IntegrationPanel'
import { DateFormatSettings } from 'components/DateFormatSettings/DateFormatSettings'
import { WebDataStatus } from 'store/escalation/reducer'
import { solutionTitleMapping } from 'page/SettingsBrowseIntegrations'
import { BotStatus, BotType } from 'store/institution/selectors'
import { TransportId, Transports } from 'store/transport'
import { isRight } from 'fp-ts/lib/Either'
import TimezoneSettings from 'components/TimezoneSettings/TimezoneSettings'
import { PERMISSIONS } from 'util/permissions/permissions'
import { SSOSettings } from 'components/SettingsSSOForm/SettingsSSOForm'
import { ArrowDropDownIcon } from 'components/Icons/ArrowDropDownIcon/ArrowDropDownIcon'
import { MainstayPage } from 'components/MainstayPageContainer/MainstayPageContainer'
import { MainstaySidebarLinkType } from 'mainstay-ui-kit/MainstaySidebar/MainstaySidebarSectionLink/MainstaySidebarSectionLink'
import MainstaySidebarSection from 'mainstay-ui-kit/MainstaySidebar/MainstaySidebarSection/MainstaySidebarSection'
import { useSelector, useFeatures } from 'util/hooks'
import { connect } from 'react-redux'
import { notUndefined } from 'util/typeguards'
import { UserGroupSettings } from 'components/UserGroupSettings/UserGroupSettings'
import { getIsEngineeringEmployee } from 'store/triage/profile/selectors'
import { classNames } from 'react-select/lib/utils'
import { orderBy, partition } from 'lodash'

function getSolutionTitleMappingLinks() {
  return Object.values(solutionTitleMapping)
    .map(elem => {
      if (elem && !elem.hidden) {
        let urlName = ''
        if (elem.isTray) {
          urlName = elem.name
          if (urlName.toLowerCase().includes('ellucian')) {
            urlName = urlName.split(' ')[1]
          } else if (urlName === 'SFTP') {
            urlName = 'sftp-sync'
          }
          return {
            text: elem.name,
            to: `/settings/browse-integrations/${urlName.toLowerCase()}`,
            permission: PERMISSIONS.INTEGRATION.VIEW,
          }
          // re-skinned SFTP integrations that don't originate on Tray should all redirect to the SFTP page
        } else {
          return {
            text: elem.name,
            to: `/settings/browse-integrations/sftp-sync`,
            permission: PERMISSIONS.INTEGRATION.VIEW,
            active: false,
          }
        }
      }
    })
    .filter(notUndefined)
}

const generalSettingsLinks: MainstaySidebarLinkType[] = [
  {
    to: '/settings',
    text: 'General',
    permission: PERMISSIONS.SETTINGS.VIEW,
  },
  {
    to: '/users-v2',
    text: 'Users',
  },
  {
    to: '/settings/sms',
    text: 'SMS / Text Messaging',
    permission: PERMISSIONS.SETTINGS.VIEW,
  },
  {
    to: '/settings/conversations',
    text: 'Conversations',
    permission: PERMISSIONS.SETTINGS.VIEW,
  },
  {
    to: '/settings/generativeAI',
    text: 'Generative AI',
    permission: PERMISSIONS.SETTINGS.VIEW,
  },
  {
    to: '/settings/hello-pages',
    text: 'Hello Pages',
    permission: PERMISSIONS.SETTINGS.VIEW,
    feature: FeaturesType.HELLO_PAGES,
  },
  {
    to: '/settings/escalation',
    text: 'Forwarding & Escalations',
    permission: PERMISSIONS.SETTINGS.VIEW,
  },
  {
    to: '/settings/webchat',
    text: 'Webchat',
    permission: PERMISSIONS.SETTINGS.VIEW,
  },
  {
    to: '/settings/api-tokens',
    text: 'Mainstay API',
    permission: PERMISSIONS.SETTINGS.VIEW,
  },
  {
    to: '/settings/talk-to-bot',
    text: 'Test the Bot',
  },
]
interface ISettingWithoutFieldProps {
  readonly name: string | JSX.Element
  readonly children: React.ReactNode
  readonly helpText?: string | JSX.Element
  readonly helpElement?: JSX.Element
  readonly className?: string
}

export function SettingWithoutField({
  name,
  children,
  helpText,
  helpElement,
  className,
}: ISettingWithoutFieldProps) {
  return (
    <>
      <hr className="w-100" />
      <Row className={classNames('mb-4', className)}>
        <Col lg={3}>{name}</Col>
        <Col lg={4}>{children}</Col>
        <Col>
          <div className="caption text-muted mb-0">
            {helpElement || <p className="caption">{helpText}</p>}
          </div>
        </Col>
      </Row>
    </>
  )
}

export function ReadonlyIcon() {
  return (
    // tippy uses forward ref so it's easier to wrap the icon in an extra div
    // than add ref forwarding the AHIcon
    <Tooltip content="Contact support@mainstay.com to edit">
      <div>
        <AHIcon name="lock" className="mr-2" />
      </div>
    </Tooltip>
  )
}

export interface ISettingFieldProps {
  readonly name: string
  readonly value: string | boolean | null | undefined
  readonly helpText?: string | JSX.Element
  /** User can't modify field */
  readonly readOnly?: boolean
  readonly placeholder?: string
  readonly onToggle?: (value: boolean) => void
  readonly toggleClass?: string
}

export function SettingField({
  name,
  value,
  helpText,
  readOnly = false,
  placeholder,
  onToggle,
  toggleClass,
}: ISettingFieldProps) {
  return (
    <SettingWithoutField name={name} helpText={helpText}>
      <div className="d-flex align-items-center">
        {readOnly && <ReadonlyIcon />}
        {typeof value === 'boolean' ? (
          <ToggleSwitch
            onChange={onToggle}
            checked={value}
            disabled={readOnly}
            className={toggleClass}
          />
        ) : (
          <TextInput
            name={name}
            value={value || undefined}
            placeholder={placeholder}
            disabled={readOnly}
            required
          />
        )}
      </div>
    </SettingWithoutField>
  )
}

export function CollapsableSettingFieldList({
  settings,
  name,
  helpText,
}: {
  settings: ISettingFieldProps[]
  name: string
  helpText: string
}) {
  const [isExpanded, setExpanded] = React.useState(false)
  if (!settings || settings.length < 0) {
    return <></>
  }
  return (
    <>
      <SettingWithoutField name={name} helpText={helpText}>
        <div onClick={() => setExpanded(!isExpanded)}>
          <ArrowDropDownIcon collapsed={!isExpanded} />
        </div>
      </SettingWithoutField>
      {isExpanded &&
        settings.map(setting => {
          return (
            <div className="ml-4" key={setting.name}>
              Cohort Name:
              <SettingField {...setting} />
            </div>
          )
        })}
    </>
  )
}

interface ISettingFeatureFlagFieldProps {
  readonly name: string
  readonly enabled: boolean
  /** we send the feature to the server for enabling/disabling */
  readonly feature: FeaturesType
  readonly helpText?: string | JSX.Element
  readonly engineeringOnly: boolean | undefined
  readonly editable: boolean
}

export const SettingFeatureFlagField = ({
  name,
  enabled,
  helpText,
  feature,
  engineeringOnly,
  editable,
}: ISettingFeatureFlagFieldProps) => {
  // we want to invert the current value. So it the feature is enabled, then we
  // want to disable it and vice versa
  const [isEnabled, setState] = React.useState(enabled)

  const onClick = () => {
    setState(prev => !prev)
    Api.updateFeatureFlagForInstitution({ feature, enable: !isEnabled })
      .then(() => {
        toast.success('successfully updated feature')
      })
      .catch(() => {
        toast.error('failure updating feature flag')
        setState(prev => !prev)
      })
  }
  return (
    <SettingWithoutField
      name={
        <>
          {engineeringOnly === true && (
            <>
              <Badge color="light">Engineering Only</Badge>
              <br />
            </>
          )}
          {name}
        </>
      }
      className={
        engineeringOnly === true
          ? 'border-radius pt-1 pb-1 bg-charcoal-grey fg-white'
          : ''
      }
      helpText={
        <span className={engineeringOnly === true ? 'fg-white' : ''}>
          {helpText}
        </span>
      }>
      <div className="pt-1 d-flex align-items-center">
        <ToggleSwitch
          disabled={!editable}
          checked={isEnabled}
          onChange={onClick}
        />
      </div>
    </SettingWithoutField>
  )
}

interface ISettingInstitutionCheckboxFieldProps {
  readonly name: string
  readonly enabled: boolean
  readonly helpText?: string | JSX.Element
  readonly engineeringOnly?: boolean
  readonly editable: boolean
  readonly fieldName: keyof Pick<
    IInstitution,
    | 'internal'
    | 'matchingEnabled'
    | 'dialogSettings'
    | 'internalTextingOnly'
    | 'isManaged'
  >
}

function SettingInstitutionCheckboxField({
  name,
  enabled,
  helpText,
  fieldName,
  engineeringOnly,
  editable,
}: ISettingInstitutionCheckboxFieldProps) {
  // we want to invert the current value. So it the feature is enabled, then we
  // want to disable it and vice versa
  const [isEnabled, setState] = React.useState(enabled)

  const onClick = () => {
    setState(prev => !prev)
    Api.updateInstitutionData('me', { [fieldName]: !isEnabled })
      .then(() => {
        toast.success('Successfully updated setting.')
      })
      .catch(() => {
        toast.error('Failure updating setting.')
        setState(prev => !prev)
      })
  }
  return (
    <SettingWithoutField
      name={
        <>
          {engineeringOnly === true && (
            <>
              <Badge color="light">Engineering Only</Badge>
              <br />
            </>
          )}
          {name}
        </>
      }
      className={
        engineeringOnly === true
          ? 'border-radius pt-1 pb-1 bg-charcoal-grey fg-white'
          : ''
      }
      helpText={
        <span className={engineeringOnly === true ? 'fg-white' : ''}>
          {helpText}
        </span>
      }>
      <div className="pt-1 d-flex align-items-center">
        <ToggleSwitch
          disabled={!editable}
          checked={isEnabled}
          onChange={onClick}
        />
      </div>
    </SettingWithoutField>
  )
}

interface ISettingInstitutionFilterChannelToggleProps {
  readonly name: string
  readonly currentChannels: ReadonlyArray<TransportId>
  readonly helpText?: string | JSX.Element
  readonly channelName: TransportId
  readonly onUpdateFilteredChannels: (c: TransportId[]) => void
}

function SettingInstitutionFilterChannelToggle({
  name,
  currentChannels,
  helpText,
  channelName,
  onUpdateFilteredChannels,
}: ISettingInstitutionFilterChannelToggleProps) {
  // we want to invert the current value. So it the feature is enabled, then we
  // want to disable it and vice versa
  const [isEnabled, setState] = React.useState(
    !currentChannels.includes(channelName)
  )

  const onClick = () => {
    setState(prev => !prev)
    const newFilteredChannels = currentChannels.includes(channelName)
      ? currentChannels.filter(n => n !== channelName)
      : [...currentChannels, channelName]
    Api.updateInstitutionData('me', { filteredChannels: newFilteredChannels })
      .then(() => {
        onUpdateFilteredChannels(newFilteredChannels)
        toast.success('Successfully updated channel filter settings.')
      })
      .catch(() => {
        toast.error('Failure channel filter settings.')
        setState(prev => !prev)
      })
  }
  return (
    <SettingWithoutField name={name} helpText={helpText}>
      <div className="d-flex align-items-center">
        <ToggleSwitch checked={isEnabled} onChange={onClick} />
      </div>
    </SettingWithoutField>
  )
}

interface ITransportFiltersSettingsSectionProps {
  readOnly?: boolean
  filteredChannels: ReadonlyArray<TransportId> | undefined
  onUpdateFilteredChannels: (c: TransportId[]) => void
}

function TransportFiltersSettingsSection({
  readOnly,
  filteredChannels,
  onUpdateFilteredChannels,
}: ITransportFiltersSettingsSectionProps) {
  const displayText: { [key in TransportId]: string } = {
    facebook: 'Facebook',
    twilio: 'SMS',
    web: 'Web Chat',
    slack: 'Slack',
  }
  const displayCopy: { [key in TransportId]: string } = {
    twilio:
      'If toggled on, your bot will respond to any incoming message sent to your phone numbers, even if it’s from an unknown contact. (As opposed to verified contacts that respond to campaigns sent to them first). ',
    facebook:
      'If toggled on, your bot will respond to any incoming message sent to your facebook page’s inbox, even if it’s from an unknown contact. ',
    web:
      'If toggled on, your bot will respond to any incoming message sent to your web bot, even if it’s from an unknown contact. ',
    slack:
      "If toggled on, your slack bot will respond to any incoming message, even if it's from an unknown contact. ",
  }
  const readOnlyDisclaimer =
    'Currently, additional setup is necessary, Contact your partner success rep for help.'

  return (
    <>
      <SectionTitle>Channel Filters</SectionTitle>
      {Transports.filter(t => t !== 'facebook').map(t =>
        readOnly ? (
          <SettingField
            key={t}
            name={`Accept messages from unknown ${displayText[t]} contacts`}
            value={!filteredChannels?.includes(t)}
            helpText={displayCopy[t] + readOnlyDisclaimer}
            readOnly
          />
        ) : (
          <SettingInstitutionFilterChannelToggle
            key={t}
            name={`Accept messages from unknown ${displayText[t]} contacts`}
            channelName={t}
            currentChannels={filteredChannels ?? []}
            onUpdateFilteredChannels={onUpdateFilteredChannels}
            helpText={displayCopy[t]}
          />
        )
      )}
    </>
  )
}

interface ISectionTitleProps {
  readonly children: string
}
export function SectionTitle({ children }: ISectionTitleProps) {
  return <h4 className="table-subheader pt-2">{children}</h4>
}

const FeaturesFlagSection = ({
  features,
  enabledFeatures,
  isEngineeringEmployee,
}: {
  features: IFeature[]
  enabledFeatures: Set<FeaturesType>
  isEngineeringEmployee: boolean
}) => {
  return (
    <div className="mb-3">
      {orderBy(features, ['engineeringOnly', 'name'], ['desc', 'asc']).map(
        (f: IFeature) => {
          if (f.hidden) {
            return null
          }

          const enabled = enabledFeatures.has(f.feature)
          const editable =
            f.engineeringOnly === true ? isEngineeringEmployee : true

          const SettingsField = () => (
            <SettingFeatureFlagField
              name={f.name}
              enabled={enabled}
              helpText={f.helpText}
              feature={f.feature}
              engineeringOnly={f.engineeringOnly}
              editable={editable}
            />
          )
          if (editable) {
            return <SettingsField key={f.name} />
          }

          return (
            <Tooltip
              key={f.name}
              content="Due to the complicated nature of this feature flag it can only be enabled or disabled by engineering. Please contact engineering to change this value.">
              <div>
                <SettingsField />
              </div>
            </Tooltip>
          )
        }
      )}
    </div>
  )
}

interface IAdmitHubStaffSettingsSectionProps {
  readonly title?: string
  readonly children: React.ReactNode
  readonly className?: string
  readonly padding?: boolean
  readonly background?: boolean
}

export function AdmitHubStaffSettingsSection({
  title,
  children,
  className,
  padding = true,
  background = true,
}: IAdmitHubStaffSettingsSectionProps) {
  const cls = classnames({
    'bg-warning-light': background,
    'panel-content-padding': padding,
    [className || '']: !!className,
  })
  return (
    <AdmithubOnly>
      <section className={cls}>
        <Badge color="warning">Mainstay</Badge>
        <p className="text-warning mb-1">
          Fields below are only visible to Mainstay employee admins.
        </p>
        {title && <h3 className="mb-5">{title}</h3>}
        {children}
      </section>
    </AdmithubOnly>
  )
}
export const SettingsPageContainer: React.FC<{
  children?: React.ReactElement
  className?: string
}> = ({
  children,
  className,
}: {
  children?: React.ReactElement
  className?: string
}) => {
  const { hasFeature, FeaturesType } = useFeatures()

  const thirdPartySettingsLinks: MainstaySidebarLinkType[] = [
    {
      to: '/settings/browse-integrations',
      text: 'Browse Available',
      permission: PERMISSIONS.INTEGRATION.VIEW,
    },
    ...(hasFeature(FeaturesType.SLACK_SETTTINGS_PAGE)
      ? [
          {
            to: '/settings/slack',
            text: 'Slack',
            permission: PERMISSIONS.INTEGRATION.VIEW,
          },
        ]
      : []),
    {
      to: '/settings/salesforce',
      text: 'Salesforce',
      permission: PERMISSIONS.INTEGRATION.VIEW,
    },
    ...getSolutionTitleMappingLinks(),
    {
      to: '/settings/sync-log',
      text: 'Sync Log',
      permission: PERMISSIONS.INTEGRATION.VIEW,
    },
  ]
  return (
    <NavBarContainer title="Settings" className="d-flex h-100">
      <MainstayPage
        header="Settings"
        sidebarContent={
          <>
            <MainstaySidebarSection links={generalSettingsLinks} />
            <hr className="mb-1 w-100" />
            <MainstaySidebarSection
              header="Third Party Integrations"
              links={thirdPartySettingsLinks}
            />
          </>
        }>
        <div className={classnames('pb-5', className)}>{children}</div>
      </MainstayPage>
    </NavBarContainer>
  )
}
export function LoadingSettings() {
  return (
    <SettingsPageContainer>
      <CenteredLoader className="h-100" />
    </SettingsPageContainer>
  )
}

export function FailureLoadingSettings() {
  return (
    <SettingsPageContainer>
      <p className="m-auto">failure fetching settings</p>
    </SettingsPageContainer>
  )
}
export function WaitingToLoadSettings() {
  return (
    <SettingsPageContainer>
      <p className="m-auto">waiting to fetch settings</p>
    </SettingsPageContainer>
  )
}

interface ISettingsPageProps {
  readonly fetchSettings: () => void
  readonly institution: WebData<IInstitution>
}

function SettingsPageBasic({ fetchSettings, institution }: ISettingsPageProps) {
  const [filteredTransports, setFilteredTransports] = React.useState<
    ReadonlyArray<TransportId>
  >()
  const [metadataCategories, setMetadataCategories] = React.useState<{
    [k: string]: Array<{ name: string; id: string }> | undefined
  }>()
  React.useEffect(() => {
    fetchSettings()
  }, [fetchSettings])

  const isEngineeringEmployee = useSelector(getIsEngineeringEmployee)

  React.useEffect(() => {
    Api.fetchCollegeMetadataCategories().then(r => {
      if (isRight(r)) {
        setMetadataCategories(r.right)
      }
    })
  }, [])

  if (isUnresolved(institution)) {
    return <LoadingSettings />
  }
  if (isFailure(institution)) {
    return <FailureLoadingSettings />
  }

  if (!filteredTransports && institution.data.filteredChannels) {
    setFilteredTransports(institution.data.filteredChannels)
  }

  const prependedMessageResponse = institution.data.prependCounselorResponse
  const enabledFeatures = new Set(institution.data.enabledFeatures)

  const [partnerFeatures, admithubOnlyFeatures] = partition(
    AVAILABLE_FEATURES,
    f => f.visibleToPartners
  )
  return (
    <SettingsPageContainer>
      <>
        <IntegrationPanel title="General">
          <SectionTitle>Organization</SectionTitle>
          <InstitutionSettingUpdateField
            name="Official organization name"
            value={institution.data.name}
            fieldName="name"
            helpText={
              <p className="caption">
                The official name of your organization. Contacts may see this
                info in messages from the bot.
              </p>
            }
          />
          <InstitutionSettingUpdateField
            name="Organization abbreviation"
            value={institution.data.abbr}
            fieldName="abbr"
            helpText={
              <p className="caption">
                This is the common abbreviation used to refer to your
                organization. Contacts may see this info in messages from the
                bot.
              </p>
            }
          />
          <InstitutionSettingUpdateField
            name="Organization display name"
            value={institution.data.displayName}
            reloadOnUpdate={true}
            fieldName="displayName"
            helpText="An optional shorthand only used in the platform to help differentiate between organizations. Your contacts will not see this."
          />
          <SectionTitle>Your Bot</SectionTitle>
          <InstitutionSettingUpdateField
            name="Bot's name"
            helpText="The given name of your bot. Contacts may see this info in messages from the bot."
            value={institution.data.oliName}
            fieldName="oliName"
          />
          <InstitutionSettingUpdateField
            name="Bot's Persona"
            helpText="A bot's persona is its physical form. E.g. owl or panther. Does not affect bot behavior."
            value={institution.data.animal}
            fieldName="animal"
          />
          <UserGroupSettings
            availableRegions={institution.data.availableRegions ?? []}
            regionField={institution.data.regionField?.name}
            regionsEnabled={enabledFeatures.has(
              FeaturesType.PREMS_REGIONS_ENABLED
            )}
          />
          <SectionTitle>Email</SectionTitle>
          <SettingWithoutField
            name="Trusted email domains"
            helpElement={
              <>
                <p className="caption">
                  Add any email domain that you'd like to allow your staff to
                  use to accept or send messages with Mainstay.
                </p>

                <p className="caption">
                  If an email suffix (domain) is not listed here, you will not
                  be able to use the email escalation feature to reply to
                  contacts.
                </p>

                <p className="caption">
                  We require this to limit email spam and increase security.
                </p>
              </>
            }>
            <TrustedEmailDomainsForm emails={institution.data.emailDomains} />
          </SettingWithoutField>
          <TransportFiltersSettingsSection
            filteredChannels={filteredTransports}
            onUpdateFilteredChannels={setFilteredTransports}
            readOnly
          />
          <ContactAuthSettings />
          <DateFormatSettings />
          <TimezoneSettings timezone={institution.data.timeZone} />
          <SectionTitle>Miscellaneous Settings</SectionTitle>
          <FeaturesFlagSection
            features={partnerFeatures}
            isEngineeringEmployee={isEngineeringEmployee}
            enabledFeatures={enabledFeatures}
          />
          <SectionTitle>Credits</SectionTitle>
          <p>
            Our new message sound,{' '}
            <a href="https://notificationsounds.com/notification-sounds/direct-545">
              "Direct"
            </a>
            , is courtesy of "Notification Sounds", (CC BY 4.0).{' '}
          </p>
        </IntegrationPanel>
        <AdmitHubStaffSettingsSection title="Organization Setup and Permissions">
          <SectionTitle>Setup</SectionTitle>
          <SettingField
            name="Organization ID"
            value={institution.data.id}
            helpText="This is the unique identifier for this organization. It cannot be changed after initial setup."
            readOnly
          />
          {institution.data.messagingServiceSid && (
            <SettingField
              name="Twilio Messaging service SID"
              value={institution.data.messagingServiceSid}
              helpText="An identifier used by Twilio."
              readOnly
            />
          )}
          {institution.data.cohorts &&
            Object.keys(institution.data.cohorts).length > 0 && (
              <CollapsableSettingFieldList
                name="Cohort SIDs"
                helpText="Twilio SIDs for institution cohorts"
                settings={
                  institution.data.cohorts
                    ? Object.values(institution.data.cohorts).map(cohort => {
                        return {
                          name: cohort.displayName,
                          value: cohort.twilioMessagingServiceSid,
                          readOnly: true,
                        }
                      })
                    : []
                }
              />
            )}
          <InstitutionSettingUpdateField
            name="Child Hubspot ID"
            value={institution.data.hubspotId}
            fieldName="hubspotId"
            helpText="Add a unique ID from HubSpot to associate this org with an HubSpot Org."
          />
          <InstitutionSettingUpdateField
            name="Hubspot Parent ID"
            value={institution.data.hubspotParentId}
            fieldName="hubspotParentId"
            helpText="Add a hubspot parent ID to group different colleges of the same institution"
          />
          <SSOSettings
            enabled={enabledFeatures.has(FeaturesType.INCOMMON_SSO)}
            ssoDomain={institution.data.ssoDomain}
            ssoEntityID={institution.data.ssoEntityID}
            passwordBasedAuthDisabled={
              institution.data.passwordBasedAuthDisabled ?? false
            }
          />
          <InstitutionSettingUpdateField
            name="Bot Type"
            value={institution.data.botType}
            dropdownOptions={Object.values(BotType).map(v => ({
              value: v,
              label: v,
            }))}
            fieldName="botType"
            helpText="The type of contact population served by the bot"
          />
          <InstitutionSettingUpdateField
            name="Bot Status"
            value={institution.data.botStatus}
            dropdownOptions={Object.values(BotStatus).map(v => ({
              value: v,
              label: v,
            }))}
            fieldName="botStatus"
            helpText="The current status of the bot"
          />
          <InstitutionSettingUpdateField
            name="Number of contacts purchased"
            value={institution.data.numberOfContactsPurchased}
            fieldName="numberOfContactsPurchased"
            helpText="Number of contacts contractually agreed upon"
          />

          {metadataCategories && (
            <>
              <InstitutionMetadataUpdateField
                name="Package"
                value={institution.data.package}
                multi={false}
                dropdownOptions={metadataCategories?.packages?.map(p => ({
                  value: p.id,
                  label: p.name,
                }))}
                fieldName="package"
                helpText="The package under which this bot is classified"
              />
              <InstitutionMetadataUpdateField
                name="Channels"
                value={institution.data.channelsMetadata || []}
                multi={true}
                dropdownOptions={metadataCategories?.channels?.map(p => ({
                  value: p.id,
                  label: p.name,
                }))}
                fieldName="channels"
                helpText="The communication channels available to this bot"
              />
              <InstitutionMetadataUpdateField
                name="Knowledge Packs"
                value={institution.data.knowledgePacks || []}
                multi={true}
                dropdownOptions={metadataCategories?.knowledgePacks?.map(p => ({
                  value: p.id,
                  label: p.name,
                }))}
                fieldName="knowledgePacks"
                helpText="The knowledge packages available to this bot"
              />
            </>
          )}

          <SettingWithoutField
            name="Prepend text for escalated message responses"
            helpText="This is the text that comes before the body of escalated messages.">
            <PrependedEscalatedMesageResponseForm
              prependedMessageResponse={prependedMessageResponse || null}
            />
          </SettingWithoutField>
          <SettingInstitutionCheckboxField
            name="Mark institution as Internal"
            fieldName="internal"
            enabled={institution.data.internal}
            editable={true}
            helpText="Should be used for demo institutions and any institutions created for testing."
          />
          <SettingInstitutionCheckboxField
            name="Mark institution as Managed"
            fieldName="isManaged"
            enabled={!!institution.data.isManaged}
            editable={true}
            helpText="When toggled on, institution will be denoted as managed by Mainstay's Expert Services team"
          />
          <TransportFiltersSettingsSection
            filteredChannels={filteredTransports}
            onUpdateFilteredChannels={setFilteredTransports}
          />
          <SectionTitle>Features</SectionTitle>

          <FeaturesFlagSection
            features={admithubOnlyFeatures}
            isEngineeringEmployee={isEngineeringEmployee}
            enabledFeatures={enabledFeatures}
          />

          {/** Note: some features aren't in feature flags and are instead random field settings */}
          <SettingInstitutionCheckboxField
            name="Restrict external texting"
            fieldName="internalTextingOnly"
            enabled={institution.data.internalTextingOnly}
            engineeringOnly={true}
            editable={isEngineeringEmployee}
            helpText="Restricts outgoing SMS messaging to only internal-flagged Contacts. SMS messaging is always restricted on environments other than Production"
          />

          <SectionTitle>Bot AI</SectionTitle>
          <SettingInstitutionCheckboxField
            name="Connect bot to Holocene"
            fieldName="matchingEnabled"
            enabled={institution.data.matchingEnabled}
            engineeringOnly={true}
            editable={isEngineeringEmployee}
            helpText="THIS SHOULD ONLY BE CHANGED BY AN ENGINEER. This flag controls whether the bot is connected to Holocene. This should only be off for bots that are shut down or not in use."
          />
        </AdmitHubStaffSettingsSection>
      </>
    </SettingsPageContainer>
  )
}

function maybeInstitutionToWebData<T>(
  inst: T | undefined,
  isLoading: boolean
): WebData<T> {
  if (inst == null) {
    return undefined
  }
  if (isLoading) {
    return Loading()
  }
  return Success(inst)
}

const mapStateToProps = (
  state: RootState
): Pick<ISettingsPageProps, 'institution'> => {
  const loading = getCurrentInstitutionStatus(state) === WebDataStatus.Loading
  const institution = getFullCurrentInstitution(state)

  return {
    institution: maybeInstitutionToWebData(institution, loading),
  }
}
const mapDispatchToProps = (
  dispatch: Dispatch
): Pick<ISettingsPageProps, 'fetchSettings'> => ({
  fetchSettings: fetchCurrentInstitutionAsync(dispatch),
})

export const SettingsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsPageBasic)

export default SettingsPage
