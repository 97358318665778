import {
  fetchDialogLabels,
  createDialogLabel,
  deleteDialogLabel,
  updateDialogLabel,
  IDialogLabelActions,
  bulkAddDialogLabels,
  fetchAdmithubTags,
} from 'store/dialog-labels/actions'
import { getType } from 'typesafe-actions'
import { uniqBy } from 'lodash'
import {
  WebData,
  Loading,
  Success,
  Failure,
  isSuccess,
  unWrap,
} from 'store/webdata'

export interface IDialogLabel {
  id: number
  name: string
  count: number
}

export interface IDialogLabelState {
  labels: WebData<IDialogLabel[]>
  tags: WebData<IDialogLabel[]>
}

const initialState: IDialogLabelState = {
  labels: undefined,
  tags: undefined,
}

export default (
  state: IDialogLabelState = initialState,
  action: IDialogLabelActions
): IDialogLabelState => {
  switch (action.type) {
    case getType(fetchDialogLabels.request):
      return {
        ...state,
        labels: Loading(),
      }
    case getType(fetchDialogLabels.success):
      return {
        ...state,
        labels: Success(action.payload),
      }
    case getType(fetchDialogLabels.failure):
      return {
        ...state,
        labels: Failure(undefined),
      }
    case getType(createDialogLabel.success):
      const labels = [
        ...(isSuccess(state.labels) ? unWrap(state.labels) : []),
        action.payload.newLabel,
      ]
      return {
        ...state,
        labels: Success(labels),
      }
    case getType(deleteDialogLabel.success):
      if (isSuccess(state.labels)) {
        // Remove deleted label from store
        const labels = unWrap(state.labels).filter(
          l => l.id !== action.payload.labelId
        )
        return {
          ...state,
          labels: Success(labels),
        }
      }
      return state
    case getType(updateDialogLabel.success):
      if (isSuccess(state.labels)) {
        // Update modified label in store
        const labels = unWrap(state.labels).map(l =>
          l.id === action.payload.updatedLabel.id
            ? action.payload.updatedLabel
            : l
        )
        return {
          ...state,
          labels: Success(labels),
        }
      } else {
        return {
          ...state,
          labels: Success([action.payload.updatedLabel]),
        }
      }
    case getType(bulkAddDialogLabels.success):
      const forTemplates = action.payload.forTemplates
      const storeLocation = forTemplates ? state.tags : state.labels

      if (isSuccess(storeLocation)) {
        // Add newly created labels and update counts of applied labels
        let newlyAddedLabels: IDialogLabel[] = []
        const labelCountMap: { [dialogId: number]: number } = {}
        Object.values(action.payload.dialogLabelMap).forEach(labels => {
          newlyAddedLabels = newlyAddedLabels.concat(labels)
          labels.forEach(l => {
            labelCountMap[l.id] = labelCountMap[l.id]
              ? labelCountMap[l.id] + 1
              : 1
          })
        })
        const allLabels = unWrap(storeLocation).concat(newlyAddedLabels)
        const countUpdatedLabels = allLabels.map(l => {
          if (labelCountMap[l.id]) {
            return {
              ...l,
              count: l.count + labelCountMap[l.id],
            }
          }
          return l
        })
        return {
          ...state,
          [forTemplates ? 'tags' : 'labels']: Success(
            uniqBy(countUpdatedLabels, 'id')
          ),
        }
      }
      return state
    case getType(fetchAdmithubTags.success):
      return {
        ...state,
        tags: Success(action.payload),
      }
    default:
      return state
  }
}
