export function ActivationIcon({
  fill = 'white',
  className = 'icon',
  width = '30',
  height = '30',
}: {
  readonly className?: string
  readonly fill?: string
  readonly width?: string
  readonly height?: string
}) {
  return (
    <svg
      className={className}
      height={height}
      viewBox="0 0 21 21"
      width={width}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m18.9999 3.99934h-1.9998v9.00036h-12.9999v1.9998c.00024.2652.10553.5195.29283.7074.1873.1878.44137.2937.70661.2947h11.00186l4.0005 3.9995v-15.00143c-.0007-.26539-.1066-.51969-.2944-.70719-.1878-.18749-.4423-.29291-.7077-.29314zm-4.0005 6.00022v-8.99944c-.0007-.264932-.1062-.518825-.2934-.706246-.1873-.187421-.4411-.29314406-.706-.29408762h-12.99991c-.265087.00070785-.519115.10632762-.706561.29377362s-.29306529.441474-.29377314.70656v13.99938l4.00044414-4.0041h9.9998c.2641-.0009.5173-.106.7044-.2925.1871-.1864.2931-.4392.295-.70334z"
        fill={fill}
      />
    </svg>
  )
}
