import * as React from 'react'
import {
  EventTrackerAttrAdder,
  ITrackingEvent,
} from 'components/EventTracker/EventTracker'
import 'components/Checkbox/Checkbox.scss'
import classNames from 'classnames'
import scssVariables from 'scss/_variables.scss'
import { noOp } from 'util/noOp'

/*************************************************************************
 *
 * As of 5/5/2022, this is the Checkbox that will enter our styling guide
 * to be used for the entirety of thte application.
 *
 *************************************************************************/

const DEFAULT_WIDTH = 12
const DEFAULT_HEIGHT = 11

export function Check({
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  className = '',
  color = 'white',
}: {
  readonly width?: number
  readonly height?: number
  readonly className?: string
  readonly color?: string
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6667 1.5L4.25004 7.91667L1.33337 5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function Minus({
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  className = '',
  color = 'white',
}: {
  readonly width?: number
  readonly height?: number
  readonly className?: string
  readonly color?: string
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.9165 1H10.0832"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

/*
 * TODO (tmoliter 5/16/22):
 * 1. Use in a new component, CheckBoxWithText or something like that to account for text and subtext.
 * comps: https://www.figma.com/file/kCBfYR8bveACH7PcPfJ7zI/UI-KIT-v1?node-id=1097%3A63652
 */
export const Checkbox = (
  props: Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> &
    ITrackingEvent & {
      type: 'check' | 'minus'
      title?: string
      titleComponent?: React.ReactNode
      containerClassName?: string
      checkBoxSize?: 'sm' | 'md'
      checkBoxClassName?: string
      onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    }
) => {
  const {
    titleComponent,
    containerClassName,
    checkBoxSize,
    checkBoxClassName,
    ...inputProps
  } = props
  const {
    location,
    action,
    object,
    onClick,
    checked,
    type,
    disabled,
    title,
  } = inputProps
  const ref = React.useRef<HTMLInputElement | null>(null)

  const handleOnClick = !disabled ? onClick : noOp
  const Icon = type === 'check' ? Check : Minus

  return (
    <EventTrackerAttrAdder
      eventLocation={location}
      eventAction={action}
      eventObject={object}>
      <div
        className={classNames('d-flex align-items-center', containerClassName)}>
        <div
          className={classNames(
            checkBoxSize === 'md'
              ? 'checkbox-container'
              : 'checkbox-container-sm',
            {
              checked,
              'bg-disabled': disabled,
            },
            checkBoxClassName
          )}>
          {checked && (
            <Icon
              className="check"
              color={disabled ? scssVariables.mainstayDisabledGray : 'white'}
            />
          )}
          <input
            {...inputProps}
            disabled={disabled}
            ref={ref}
            onClick={e => {
              handleOnClick?.(e)
              ref?.current?.focus()
            }}
            checked={checked}
            className={classNames('checkbox', inputProps.className)}
            type="checkbox"
          />
        </div>
        {title && (
          <div
            className={classNames('pl-2', {
              'text-muted': disabled,
            })}>
            {title}
          </div>
        )}
        {titleComponent}
      </div>
    </EventTrackerAttrAdder>
  )
}
