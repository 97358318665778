export const SWITCH_CURRENT_INSTITUTION_START =
  '@@mascot/legacy/profile/SWITCH_CURRENT_INSTITUTION_START'
export const SWITCH_CURRENT_INSTITUTION_FAIL =
  '@@mascot/legacy/profile/SWITCH_CURRENT_INSTITUTION_FAIL'
export const SWITCH_CURRENT_INSTITUTION_SUCCESS =
  '@@mascot/legacy/profile/SWITCH_CURRENT_INSTITUTION_SUCCESS'

export const FETCH_USER_PROFILE_START =
  '@@mascot/legacy/profile/FETCH_USER_PROFILE_START'
export const FETCH_USER_PROFILE_SUCCESS =
  '@@mascot/legacy/profile/FETCH_USER_PROFILE_SUCCESS'
export const FETCH_USER_PROFILE_FAIL =
  '@@mascot/legacy/profile/FETCH_USER_PROFILE_FAIL'

export const UPDATE_USER_NAME_SUCCESS =
  '@@mascot/legacy/profile/UPDATE_USER_NAME_SUCCESS'
