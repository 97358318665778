import { useState, useEffect } from 'react'
import {
  SettingsPageContainer,
  LoadingSettings,
  FailureLoadingSettings,
} from 'page/SettingsGeneral'
import { IntegrationPanel } from 'components/IntegrationPanel/IntegrationPanel'
import { SalesforceIcon } from 'components/SalesforceIcon/SalesforceIcon'
import { HubSpotIcon } from 'components/Icons/HubSpotIcon/HubSpotIcon'
import { PeopleSoftIcon } from 'components/Icons/PeopleSoftIcon/PeopleSoftIcon'
import { EllucianIcon } from 'components/Icons/EllucianIcon/EllucianIcon'
import { SlateIcon } from 'components/Icons/SlateIcon/SlateIcon'
import { CanvasIcon } from 'components/Icons/CanvasIcon/CanvasIcon'
import { SFTPIcon } from 'components/Icons/SFTPIcon/SFTPIcon'
import { Button } from 'components/Button/Button'
import { Link } from 'util/routing'
import {
  WebData,
  Success,
  Failure,
  isFailure,
  isUnresolved,
} from 'store/webdata'
import * as api from 'api'
import { ILimitedInstitution, ITraySolutionResponseData } from 'api/response'
import { WorkdayIcon } from 'components/Icons/WorkdayIcon/WorkdayIcon'

export const getIcon = (icon: string, height: number, width?: number) => {
  switch (icon.toLowerCase()) {
    case 'hubspot':
      return <HubSpotIcon height={height} width={width} />
    case 'peoplesoft':
      return <PeopleSoftIcon height={height} width={width} />
    case 'colleague':
    case 'banner':
    case 'recruit':
      return <EllucianIcon height={height} width={width} />
    case 'slate':
      return <SlateIcon height={height} width={width} />
    case 'canvas':
      return <CanvasIcon height={height} width={width} />
    case 'workday':
      return <WorkdayIcon height={height} width={width} />
    case 'sftp-sync':
      return <SFTPIcon height={height} width={width} />
    default:
      return null
  }
}

interface IIntegrationName {
  name: string
  hidden: boolean
  isTray?: boolean
}

export const solutionTitleMapping: {
  [key: string]: IIntegrationName | undefined
} = {
  hubspot: {
    name: 'HubSpot',
    hidden: false,
    isTray: true,
  },
  slate: { name: 'Slate', hidden: false, isTray: true },
  'sftp-sync': { name: 'SFTP', hidden: false, isTray: true },
  colleague: { name: 'Ellucian Colleague', hidden: false, isTray: true },
  banner: {
    name: 'Ellucian Banner',
    hidden: false,
    isTray: false,
  },
  recruit: {
    name: 'Ellucian Recruit',
    hidden: false,
    isTray: false,
  },
  workday: {
    name: 'Workday',
    hidden: false,
    isTray: false,
  },
  // NOTE(neckenth - 6/25/20): these `hidden` bools will be toggled to `false` as more Tray workflows are completed
  peoplesoft: { name: 'PeopleSoft', hidden: true },

  canvas: { name: 'Canvas', hidden: true },
}

interface IIntegrationBoxProps {
  icon: React.ReactNode
  name: string
  description: string
  enabled: boolean
  moreLink: string
}

const IntegrationBox = ({
  icon,
  name,
  description,
  enabled,
  moreLink,
}: IIntegrationBoxProps) => {
  return (
    <div className="d-flex flex-column w-25 border px-3 py-4 m-3 justify-content-between">
      <div>
        <div className="justify-content-center mb-4" style={{ height: '48px' }}>
          {icon}
        </div>
        <div>
          <b>{name}</b>
        </div>
        <div>{description}</div>
      </div>
      <div className="align-self-center mt-4">
        <Link to={moreLink}>
          <Button outlined color="primary">
            {enabled ? 'Enabled, View Details' : 'Learn More'}
          </Button>
        </Link>
      </div>
    </div>
  )
}

export function SettingsBrowseIntegrationsPage() {
  const [state, setState] = useState<
    WebData<{
      institution: ILimitedInstitution
      solutions: Array<ITraySolutionResponseData>
    }>
  >()

  useEffect(() => {
    Promise.all([api.getInstitution(), api.fetchTraySolutions()])
      .then(([institutionResponse, solutionsResponse]) => {
        setState(
          Success({
            solutions: solutionsResponse.data,
            institution: institutionResponse.data,
          })
        )
      })
      .catch(() => setState(Failure(undefined)))
  }, [])

  if (isUnresolved(state)) {
    return <LoadingSettings />
  }
  if (isFailure(state)) {
    return <FailureLoadingSettings />
  }

  const salesforceEnabled =
    state.data.institution.salesforceIntegration?.syncEnabled ?? false

  // filter solutions to only show one per platform (doesn't matter which one)
  // customFields for solutions from the same platform contain:
  // `platform` and `platform_description` which should match so data displayed is the same
  // one of each of these solutions should also have a customField for `display` with a value True
  const displaySolutions = state.data.solutions.filter(
    elem =>
      elem.customFields.some(elem => elem.key === 'platform') &&
      elem.customFields.some(elem => elem.key === 'display')
  )

  return (
    <SettingsPageContainer>
      <IntegrationPanel
        title="Third Party Integrations"
        className="d-flex flex-column h-100">
        <section>
          <p className="font-weight-bold mb-0">
            Do you have ideas or requests for other integrations?
          </p>
          <p>
            <a href="mailto:support@mainstay.com">Contact Mainstay Support</a>{' '}
            team today to discuss ideas.
          </p>
        </section>
        <div className="d-flex flex-wrap">
          <IntegrationBox
            icon={<SalesforceIcon height="48px" />}
            name="Salesforce"
            description="Two way integration to push and pull contact data between platforms"
            moreLink="/settings/salesforce"
            enabled={salesforceEnabled}
          />
          {displaySolutions.map(elem => {
            // the exact customField value for "platform" from Tray
            const customFieldPlatform =
              elem.customFields.find(elem => elem.key === 'platform')?.value ??
              ''

            const isReskinnedSFTP =
              elem.customFields.find(elem => elem.key === 'tray')?.value ===
              'false'

            if (customFieldPlatform) {
              // the proper integration platform name per the customField "platform" value
              const platform: string =
                solutionTitleMapping[customFieldPlatform]?.name ?? ''

              const description: string =
                elem.customFields.find(
                  elem => elem.key === 'platform_description'
                )?.value ?? ''

              return (
                <IntegrationBox
                  key={elem.title}
                  icon={getIcon(customFieldPlatform, 48)}
                  name={platform}
                  description={description}
                  moreLink={
                    isReskinnedSFTP
                      ? `/settings/browse-integrations/sftp-sync`
                      : `/settings/browse-integrations/${customFieldPlatform.toLowerCase()}`
                  }
                  enabled={false}
                />
              )
            }
          })}
        </div>
      </IntegrationPanel>
    </SettingsPageContainer>
  )
}

export default SettingsBrowseIntegrationsPage
