import * as React from 'react'
import ClassNames from 'classnames'
import AutosizingTextArea from 'react-textarea-autosize'

export interface ITextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  className?: string
  placeholder?: string
  value?: string
  rows?: number
  maxRows?: number
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onSelectionChange?: (value: string, start: number, end: number) => void
  onPressEnter?: () => void
  onKeyPress?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  onClick?: (e: React.MouseEvent) => void
  name?: string
  required?: boolean
  error?: string
  isValid?: boolean
  resizable?: boolean
  autoFocus?: boolean
  id?: string
  textAreaRef?: React.RefObject<HTMLTextAreaElement>
  autosize?: boolean
  readOnly?: boolean
  ariaLabel?: string
  maxlength?: number
  overrideTextAreaMargin?: boolean
}

export class TextArea extends React.PureComponent<
  ITextAreaProps,
  { active: boolean }
> {
  elementReference: React.RefObject<HTMLTextAreaElement>

  constructor(props: ITextAreaProps) {
    super(props)
    // HACK(chdsbd): We need a ref for this component and the parent but it
    // seems we can only set one to the ref attribute. There's probably a better
    // way to do this.
    this.elementReference =
      props.textAreaRef != null ? props.textAreaRef : React.createRef()
  }

  static defaultProps = {
    rows: 3,
    isValid: true,
    resizable: false,
  }

  state = {
    active: false,
  }

  handlePressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { onPressEnter } = this.props
    if (e.key === 'Enter' && onPressEnter) {
      e.preventDefault()
      onPressEnter()
    }
  }

  handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
    this.setState({ active: false })
    this.handleCursorChange()
  }

  handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
    this.setState({ active: true })
  }

  handleCursorChange = () => {
    const { onSelectionChange } = this.props
    if (onSelectionChange && this.elementReference.current) {
      const {
        value,
        selectionStart,
        selectionEnd,
      } = this.elementReference.current
      onSelectionChange(value, selectionStart, selectionEnd)
    }
  }

  render() {
    const {
      value,
      onChange,
      onPressEnter,
      disabled,
      onSelectionChange,
      className,
      error,
      isValid,
      id,
      resizable,
      autoFocus,
      autosize,
      rows,
      maxRows,
      textAreaRef,
      readOnly,
      ariaLabel,
      ref,
      ...rest
    } = this.props
    const classNames = ClassNames(
      'form-control',
      {
        'is-invalid': !isValid || !!error,
        'has-value': !!value,
        disabled,
      },
      this.state.active ? 'active-input' : 'inactive-input',
      className
    )
    const style: React.CSSProperties = this.props.resizable
      ? { resize: 'both' }
      : { resize: 'none' }
    if (autosize) {
      return (
        <AutosizingTextArea
          {...rest}
          autoFocus={autoFocus}
          disabled={disabled}
          inputRef={this.elementReference}
          onChange={onChange}
          minRows={rows}
          maxRows={maxRows}
          onBlur={this.handleBlur}
          onKeyPress={this.handlePressEnter}
          onClick={this.handleCursorChange}
          className={ClassNames(classNames, 'no-transition')}
          value={value}
          id={id}
          style={style}
          readOnly={readOnly}
        />
      )
    }
    return (
      <textarea
        {...rest}
        rows={rows}
        autoFocus={autoFocus}
        disabled={disabled}
        ref={this.elementReference}
        onChange={onChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onKeyPress={this.handlePressEnter}
        onClick={this.handleCursorChange}
        className={classNames}
        value={value}
        id={id}
        style={style}
        aria-label={ariaLabel}
        readOnly={readOnly}
      />
    )
  }
}
