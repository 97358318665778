import * as React from 'react'
import { TextInput } from 'components/TextInput/TextInput'
import { Button } from 'components/Button/Button'
import * as api from 'api'
import {
  WebData,
  Loading,
  Success,
  Failure,
  isLoading,
  isFailure,
} from 'store/webdata'

const Mega = 1e6

export function ImagePicker(props: {
  onPickImage?: (imageLink: string, name?: string) => void
  textInputRef?: React.RefObject<HTMLInputElement>
}) {
  const fileRef = React.useRef<HTMLInputElement | null>(null)
  const [fileState, setFileState] = React.useState<WebData<unknown, string>>()
  const [picLink, setPicLink] = React.useState('')
  const pollHandle = React.useRef<{ cancel: () => void }>()
  const [uploadFromUrlState, setUploadFromUrlState] = React.useState<
    { type: 'error'; error: string } | { type: 'pending' }
  >()

  React.useEffect(() => {
    return () => {
      pollHandle.current?.cancel()
    }
  }, [])

  const handleInputChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setPicLink(event.target.value)
  }
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files == null || e.target.files.length === 0) {
      return
    }
    const file = e.target.files[0]
    // Twilio requires images be under 5MB
    // https://support.twilio.com/hc/en-us/articles/223133547-Will-Twilio-automatically-resize-my-images-for-MMS-messaging-
    if (file.size >= 5 * Mega) {
      setFileState(Failure('file size must be less than 5MB'))
      return
    }
    setFileState(Loading())
    api
      .uploadImage(file)
      .then(x => {
        setFileState(Success({}))
        setPicLink(x.href)
        if (props.onPickImage) {
          props.onPickImage(x.href, x.name)
        }
      })
      .catch(() => {
        setFileState(Failure('Image upload failed'))
      })
    e.target.value = ''
  }

  const handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    // cancel any existing poll
    pollHandle.current?.cancel()

    setUploadFromUrlState({ type: 'pending' })
    // start a new poll to upload from url
    api
      .uploadFromURL({
        url: picLink,
        onData: ({ payload }) => {
          if (payload.type === 'failure') {
            setUploadFromUrlState({ type: 'error', error: payload.error })
          } else if (payload.type === 'pending') {
            setUploadFromUrlState({ type: 'pending' })
          } else if (payload.type === 'success') {
            props.onPickImage?.(payload.url, '')
          }
        },
      })
      .then(res => {
        pollHandle.current = res.handle
      })
  }

  return (
    <div className="card shadow min-width-350px max-width-350">
      <div className="card-body">
        {/* Hidden input we access with the "Upload new image" button  */}
        <input
          id="avatar-upload"
          type="file"
          ref={fileRef}
          accept="image/png, image/jpeg, image/gif"
          className="d-none"
          onChange={handleFileInputChange}
        />
        <div className="d-flex justify-content-around">
          <Button
            color="primary"
            loading={isLoading(fileState)}
            loadingText="Uploading image..."
            onClick={() => {
              fileRef.current?.click()
            }}>
            Upload Image
          </Button>
        </div>
        {isFailure(fileState) && (
          <div className="text-danger small mt-2">{fileState.failure}</div>
        )}
        <div className="align-items-center d-flex d-inline-block my-3">
          <hr className="w-100" />
          <span className="text-muted text-center px-2">OR</span>
          <hr className="w-100" />
        </div>
        <div className="mt-2 input-group">
          <TextInput
            ref={props.textInputRef}
            value={picLink}
            onChange={handleInputChange}
            placeholder="https://"
            className={uploadFromUrlState?.type === 'error' ? 'is-invalid' : ''}
          />
          {/*
              need to specify order-last to get invalid-feedback to play nice with input groups.
              https://stackoverflow.com/a/60886925 */}
          {uploadFromUrlState?.type === 'error' && (
            <div className="invalid-feedback order-last">
              {uploadFromUrlState.error}
            </div>
          )}
          <div className="input-group-append">
            <Button
              color="primary"
              disabled={picLink.length <= 0}
              loading={uploadFromUrlState?.type === 'pending'}
              loadingText="Uploading..."
              onClick={handleSubmit}>
              Insert Link
            </Button>
          </div>
        </div>

        <p className="mb-0 fs-14 text-muted mt-3">
          Supported formats: .png, .jpg, .gif
        </p>
        <p className="mb-0 fs-14 text-muted">{`Recommended file size: < 1MB`}</p>
      </div>
    </div>
  )
}
