import { Dispatch, store } from 'store/store'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import * as Raven from '@sentry/browser'
import * as api from 'api'
import { push } from 'connected-react-router'
import {
  loggingOut,
  login,
  pulsing,
  changePassword,
  redirectToLoginWithHistory,
} from 'store/auth/actions'
import { AxiosError } from 'axios'
import { Location } from 'history'
import { isObject } from 'lodash'
import {
  http2DetailOrDefault,
  keyOrDefault,
  toastOnHttpError500or400,
} from 'api/http'
import { closeAblyConnection } from 'page/conversations-v2/pubsub'
import * as ROUTES from 'const/routes'
import { isRight } from 'fp-ts/lib/Either'

export const PASSWORD_RESET_MSG =
  'If an account with that email address was found, a password reset email was sent.'
const SSO_AUTH_MISCONFIGURED_MSG =
  'Logging in with SSO is not configured properly. Please contact support@mainstay.com'

export const logoutAsync = (dispatch: Dispatch) => async () => {
  dispatch(loggingOut.request())
  // see also: api/http.ts
  // manually copy to prevent cloning issues.
  const currentLocation: Location = {
    pathname: location.pathname,
    state: undefined,
    hash: location.hash,
    search: location.search,
  }
  try {
    await api.logout()
    dispatch(loggingOut.success())
    closeAblyConnection()
    dispatch(redirectToLoginWithHistory(currentLocation))
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const err = error as AxiosError
    if (!(err.response && err.response.status === 401)) {
      /* We can ignore a 401 response.
        A user will get a 401 if they are already logged out.*/
      toast('Problem logging out.', { type: 'error' })
      Raven.captureException(err)
    }
    dispatch(loggingOut.failure())
  }
}

export interface ILoginErrors {
  readonly email?: string
  readonly password?: string
  general?: string
}

interface ILoginAPIErrors {
  readonly email: string[]
  readonly password: string[]
  readonly non_field_errors?: string[]
}

const GENERAL_ERROR_MSG = 'Problem contacting backend services.'

// This needs to be identical to the message in the backend
const GENERAL_AUTH_ERROR_MSG =
  'This email and password combination is incorrect.'

const redirectAfterLogin = (dispatch: Dispatch) => {
  /*
      Find possible redirect URL. When a user attempts to access a page, but needs
      authentication, we should try and redirect them back to that page after they
      authenticate.
       */
  let url = '/'
  const location = store.getState().router.location
  /* tslint:disable:no-unsafe-any */
  if (
    location != null &&
    isObject(location.state) &&
    isObject(location.state.from)
  ) {
    const { pathname, search, hash }: Location = location.state.from
    url = `${pathname}${search}${hash}`
  }
  if (url !== ROUTES.AUTH.LOGIN) {
    // tslint:disable-next-line: no-console
    console.log(`Redirecting to post login location: ${url}`)
    dispatch(push(url))
  } else {
    // tslint:disable-next-line: no-console
    console.log(`Avoiding redirect to ${ROUTES.AUTH.LOGIN}`)
    dispatch(push('/'))
  }
}

export const loggingInAsync = (dispatch: Dispatch) => (
  email: string,
  password: string
) =>
  api
    .login(email, password)
    .then(res => {
      const user = res.data.user
      dispatch(login(user))
      redirectAfterLogin(dispatch)
    })
    .catch((error: AxiosError) => {
      // Convert errors to LoginErrors object
      let errors: ILoginErrors = {}
      if (error.response && error.response.status === 400) {
        /* tslint:disable-next-line:no-unsafe-any */
        const data: ILoginAPIErrors = error.response.data
        // response will not be malformed in this case
        errors = {
          email: (data.email || []).join('. '),
          password: (data.password || []).join('. '),
          general: data.non_field_errors
            ? (data.non_field_errors || []).join('. ')
            : GENERAL_ERROR_MSG,
        }
      } else {
        errors.general = GENERAL_ERROR_MSG
        Raven.captureException(error)
      }
      toast(errors?.general || GENERAL_ERROR_MSG, {
        type: 'error',
        link:
          errors?.general === GENERAL_AUTH_ERROR_MSG
            ? {
                text: 'Reset Password',
                onClick: async () => {
                  await api.sendPasswordReset(email)
                  toast(
                    'If there is an account associated with this email, you should have received a message with a reset password link.'
                  )
                  dispatch(push(ROUTES.AUTH.LOGIN))
                },
              }
            : undefined,
      })
      throw errors
    })

export const loggingInAsyncSSOVerify = (dispatch: Dispatch) => async () => {
  const res = await api.loginSSOVerify()
  if (isRight(res)) {
    // TODO(bjnsh08) update the types for IUserResponseData.currentFilterObj
    // @ts-expect-error
    dispatch(login(res.right.user))
    redirectAfterLogin(dispatch)
  } else {
    const message = http2DetailOrDefault(res.left, SSO_AUTH_MISCONFIGURED_MSG)
    dispatch(push(ROUTES.AUTH.LOGIN))
    toast(message, { type: 'error' })
  }
}

export const loggingInAsyncSSO = (dispatch: Dispatch) => async (
  email: string
) => {
  const res = await api.loginSSO(email)
  if (isRight(res)) {
    const redirectURL = res.right.redirect
    window.location.assign(redirectURL)
    return
  }
  dispatch(push(ROUTES.AUTH.LOGIN))
  const message =
    'http' in res.left
      ? keyOrDefault(res.left.http, 'email', SSO_AUTH_MISCONFIGURED_MSG)
      : SSO_AUTH_MISCONFIGURED_MSG
  toast(message, { type: 'error' })
}
export interface IChangePasswordErrors {
  readonly originalPassword?: string
  readonly newPassword?: string
  readonly newPassword2?: string
  general?: string
}

export interface IChangePasswordAPIErrors {
  readonly old_password?: string[]
  readonly new_password1?: string[]
  readonly new_password2?: string[]
  readonly non_field_errors?: string[]
}

interface IChangePasswordProps {
  readonly oldPassword: string
  readonly newPassword1: string
  readonly newPassword2: string
}

export const changingPasswordAsync = (dispatch: Dispatch) => ({
  oldPassword,
  newPassword1,
  newPassword2,
}: IChangePasswordProps) => {
  dispatch(changePassword.request())
  return api
    .changePassword(oldPassword, newPassword1, newPassword2)
    .then(res => {
      toast(res.data?.detail ? res.data.detail : 'Password changed', {
        type: 'success',
        options: {
          autoClose: 4000,
        },
      })
      dispatch(changePassword.success({ detail: undefined }))
      return Promise.resolve()
    })
    .catch((error: AxiosError) => {
      let errors: IChangePasswordErrors = {}
      if (error.response && error.response.status === 400) {
        /* tslint:disable-next-line:no-unsafe-any */
        const data: IChangePasswordAPIErrors = error.response.data
        errors = {
          originalPassword: (data.old_password || []).join('. '),
          newPassword: (data.new_password1 || []).join('. '),
          newPassword2: (data.new_password2 || []).join('. '),
          general: (data.non_field_errors || []).join('. '),
        }
        toast(
          errors?.general
            ? errors.general
            : 'Something went wrong changing your password.',
          { type: 'error', options: { autoClose: 4000 } }
        )
        dispatch(changePassword.failure())
        return Promise.reject(errors)
      } else {
        toastOnHttpError500or400(error)
      }
      throw errors
    })
}

export const pulseAsync = (dispatch: Dispatch) => async () => {
  dispatch(pulsing.request())
  try {
    await api.pulse()
    dispatch(pulsing.success())
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const err = error as AxiosError
    if (err.response && err.response.status !== 401) {
      Raven.captureException(err)
    }
    dispatch(pulsing.failure())
  }
}
