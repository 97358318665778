export const enum RDK {
  Loading,
  // We use Pending for async polling requests of incomplete tasks
  Pending,
  Failure,
  Success,
  Refetching,
}

export interface ILoading {
  readonly kind: RDK.Loading
}

export interface IPending {
  readonly kind: RDK.Pending
  readonly taskId: string
}

export function Loading(): ILoading {
  return { kind: RDK.Loading }
}

export function Pending(taskId: string): IPending {
  return { kind: RDK.Pending, taskId }
}

export interface IFailure<E> {
  readonly kind: RDK.Failure
  readonly failure: E
}
export function Failure<T>(failure: T): IFailure<T>
export function Failure(): IFailure<undefined>
export function Failure<T>(failure?: T): IFailure<T | undefined> {
  return {
    kind: RDK.Failure,
    failure,
  }
}

export function NotFound(): IFailure<HttpErrorKind.error404> {
  return {
    kind: RDK.Failure,
    failure: HttpErrorKind.error404,
  }
}

export interface ISuccess<T> {
  readonly kind: RDK.Success
  readonly data: T
}

export function Success<T>(data: T): ISuccess<T> {
  return {
    kind: RDK.Success,
    data,
  }
}

export interface IRefetching<T> {
  readonly kind: RDK.Refetching
  readonly data: T
}

export function Refetching<T>(data: T): IRefetching<T> {
  return {
    kind: RDK.Refetching,
    data,
  }
}

export function RefetchingOrLoading<T>(
  x: WebData<T>
): IRefetching<T> | ILoading {
  return isSuccess(x) ? Refetching(x.data) : Loading()
}

export type RemoteData<E, T> =
  | undefined
  | ILoading
  | IPending
  | IFailure<E>
  | ISuccess<T>
  | IRefetching<T>

export type WebDataError = HttpErrorKind | undefined | string

export type WebData<T, E = WebDataError> = RemoteData<E, T>

export const enum HttpErrorKind {
  error404,
  timeout,
  other,
}

// for now we have to specify the type guard
// see https://github.com/Microsoft/TypeScript/issues/16069
export const isSuccess = <T, E>(x: WebData<T, E>): x is ISuccess<T> =>
  x != null && x.kind === RDK.Success

export const isLoading = <T, E>(x: WebData<T, E>): x is ILoading =>
  x != null && x.kind === RDK.Loading

export const isPending = <T, E>(x: WebData<T, E>): x is IPending =>
  x != null && x.kind === RDK.Pending

export const isInitial = <T, E>(x: WebData<T, E>): x is undefined => x == null

export const isFailure = <T, E = WebDataError>(
  x: WebData<T, E>
): x is IFailure<E> => x != null && x.kind === RDK.Failure

export const isNotFound = <T>(x: WebData<T>): x is IFailure<WebDataError> =>
  x?.kind === RDK.Failure && x?.failure === HttpErrorKind.error404

export const isRefetching = <T, E>(x: WebData<T, E>): x is IRefetching<T> =>
  x != null && x.kind === RDK.Refetching

export const isSuccessOrRefetching = <T, E>(
  x: WebData<T, E>
): x is ISuccess<T> | IRefetching<T> => isSuccess(x) || isRefetching(x)

export const isUnresolved = <T, E>(
  x: WebData<T, E>
): x is ILoading | IPending | undefined =>
  isLoading(x) || isInitial(x) || isPending(x)

export const unWrap = <T>(d: ISuccess<T> | IRefetching<T>): T => d.data

/** map over WebData with @param func if data is a type structurally similar to Success */
export function mapSuccessLike<T, R, E>(
  d: WebData<T, E>,
  func: (data: T) => R
): WebData<R, E> {
  if (isSuccessOrRefetching(d)) {
    return { ...d, data: func(unWrap(d)) }
  }
  return d
}

export function toNullable<T, E>(d: WebData<T, E>): T | null {
  if (isSuccessOrRefetching(d)) {
    return d.data
  }
  return null
}
