import React, { useCallback, useMemo } from 'react'
import {
  ContactLabelAutoSuggest,
  contactLabelToAutoSuggestOption,
} from 'components/ContactLabelAutoSuggest/ContactLabelAutoSuggest'
import { IAutoSuggestOption } from 'components/AutoSuggest/AutoSuggest'
import { IContactLabel } from 'store/contacts/reducer'
import { ValueType } from 'react-select/lib/types'
import { notUndefined } from 'util/typeguards'

export interface IContactPanelLabelSelectorProps {
  readonly contactLabels: ReadonlyArray<IContactLabel>
  readonly onChange: (contactLabels: ReadonlyArray<IContactLabel>) => void
  readonly createOptionPosition?: 'first' | 'last'
}

export function ContactPanelLabelSelector({
  contactLabels,
  createOptionPosition,
  onChange,
}: IContactPanelLabelSelectorProps) {
  const formattedValue = useMemo(() => {
    return contactLabels.map(contactLabelToAutoSuggestOption)
  }, [contactLabels])
  const handleSelectLabel = useCallback(
    (
      event: React.ChangeEvent<{
        value: string | string[]
        data: ValueType<IAutoSuggestOption<IContactLabel>>
      }>
    ) => {
      const { data } = event.target
      let formattedValue: IAutoSuggestOption<IContactLabel>[] = []
      if (Array.isArray(data)) {
        formattedValue = data
      } else if (data) {
        formattedValue = [data]
      }
      onChange(formattedValue.map(x => x.data).filter(notUndefined))
    },
    [onChange]
  )

  return (
    <ContactLabelAutoSuggest
      multi
      creatable
      value={formattedValue}
      onSelectLabel={handleSelectLabel}
      createOptionPosition={createOptionPosition}
      placeholder="Search or Create New"
    />
  )
}
