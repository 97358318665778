import * as Raven from '@sentry/browser'

export function notUndefined<T>(x: undefined | T): x is T {
  return x != null
}

export const isKeyOfObject = <T extends unknown>(
  f: string | number | symbol,
  obj: T
): f is keyof T =>
  obj instanceof Object && typeof f === 'string' && Object.keys(obj).includes(f)

/* tslint:disable no-any no-unsafe-any */
export const isValueOfObject = <T extends unknown>(v: any, obj: T) =>
  obj instanceof Object && Object.values(obj).includes(v)
/* tslint:enable no-any no-unsafe-any */

export const isArrayOfStrings = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every(item => typeof item === 'string')

export const getValueIfKeyOfObject = <T extends unknown>(
  f: string | number | symbol,
  obj: T
) => {
  if (isKeyOfObject(f, obj)) {
    return obj[f]
  }
  Raven.captureException('Invalid obj key found')
  throw Error('Invalid obj key found')
}
