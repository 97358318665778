import { createAsyncAction, ActionType } from 'typesafe-actions'
import { IApiError } from 'api'
import { IImportLabelResponse } from 'api/response'

export const listImportLabels = createAsyncAction(
  '@@@mascot/campaign-scheduler/LIST_IMPORT_LABELS_START',
  '@@@mascot/campaign-scheduler/LIST_IMPORT_LABELS_END',
  '@@@mascot/campaign-scheduler/LIST_IMPORT_LABELS_FAIL'
)<number, IImportLabelResponse[], IApiError>()

export type IImportLabelActions = ActionType<typeof listImportLabels>
