import React from 'react'
import { IAutoSuggestOption } from 'components/AutoSuggest/AutoSuggest'
import { ICampaignHistoryResponseData } from 'api/response'
import * as api from 'api'
import AsyncAutoSuggest from 'components/AutoSuggest/AsyncAutoSuggest'
import { ValueType, OptionsType } from 'react-select/lib/types'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { SortBy, SortOrder } from 'store/campaign-history/reducer'
import axios from 'axios'
import {
  CustomMenuList,
  CustomOptionWithValue,
  ValueContainer,
} from 'components/AsyncCustomScriptSearch/AsyncCustomScriptSearch'

export interface IOption {
  value: string
  label: string
  data?: ICampaignHistoryResponseData
}

export type SelectChangeEvent = React.ChangeEvent<{
  readonly value: string | string[]
  readonly name?: string
  readonly data: ValueType<IAutoSuggestOption<IOption>>
}>

interface IAsyncCampaignSearchProps {
  readonly onChange: (e: SelectChangeEvent) => void
  readonly name?: string
  readonly value?: IAutoSuggestOption<IOption> | IAutoSuggestOption<IOption>[]
  readonly isValid?: boolean
  readonly touched?: boolean
  readonly className?: string
  readonly key?: string
  readonly readOnly?: boolean
}

const customStyles = {
  singleValue: (provided: React.CSSProperties) => ({
    ...provided,
    width: '70% !important',
  }),
}

export function AsyncCampaignSearch({
  onChange,
  value,
  name = 'campaign',
  isValid = true,
  touched = false,
  className,
  key,
  readOnly = false,
}: IAsyncCampaignSearchProps) {
  return (
    <AsyncAutoSuggest
      styles={customStyles}
      key={key}
      name={name}
      formGroupClassnames={className}
      defaultOptions
      onChange={onChange}
      loadOptions={fetchCampaigns}
      value={value}
      disabled={readOnly}
      touched={touched}
      isValid={isValid}
      components={{
        Option: props => (
          <CustomOptionWithValue
            getLink={(id: string) => `/campaigns/${id}`}
            {...props}
          />
        ),
        MenuList: props => (
          <CustomMenuList
            children={props.children}
            innerRef={props.innerRef}
            {...props}
          />
        ),
        ValueContainer: props => (
          <ValueContainer readOnly={readOnly} {...props} />
        ),
      }}
    />
  )
}

function fetchCampaigns(
  inputValue: string,
  callback: (options: OptionsType<IAutoSuggestOption<IOption>>) => void
) {
  const handle = axios.CancelToken.source()
  api
    .getCampaigns({
      query: inputValue,
      limit: 50,
      cancelToken: handle.token,
      sort: { name: SortBy.date, order: SortOrder.desc },
      lessData: true,
    })
    .then(r => {
      callback(
        r.data.campaigns.map(r => ({
          value: r.id,
          label: r.name,
        }))
      )
      handle.cancel()
    })
    .catch(_e => {
      toast('Problem fetching campaigns', {
        type: 'error',
        options: { autoClose: 2000 },
      })
    })
}
