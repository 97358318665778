import * as React from 'react'
import { Button } from 'components/Button/Button'
import { TextInput } from 'components/TextInput/TextInput'

import { Menu } from 'components/Menu/Menu'

import { keyOrDefault } from 'api/http'
import * as api from 'api'
import { isRight } from 'fp-ts/lib/Either'

interface ILinkShortenerProps {
  onGenerateShortLink: (shortLink: string) => void
}

interface ILinkShortenerState {
  status:
    | { type: 'loading' }
    | { type: 'success' }
    | { type: 'error'; error: string }
  linkToShorten: string
}

export default class NewLinkShortener extends React.PureComponent<
  ILinkShortenerProps,
  ILinkShortenerState
> {
  state: ILinkShortenerState = {
    status: { type: 'success' },
    linkToShorten: '',
  }

  handleInputChange = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ linkToShorten: event.target.value })
  }

  handleSubmitLink = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault()
    // NOTE(chdsbd): fix double submit issue on IE11 (AH-4875)
    e.stopPropagation()
    this.setState({ status: { type: 'loading' } })
    api.shortenLink(this.state.linkToShorten).then(res => {
      if (isRight(res)) {
        this.setState({ status: { type: 'success' } })
        this.props.onGenerateShortLink(res.right.shortLink)
      } else {
        const defaultErrorMessage = 'Problem shortening link'
        const message =
          'http' in res.left
            ? keyOrDefault(res.left.http, 'link', defaultErrorMessage)
            : defaultErrorMessage
        this.setState({ status: { type: 'error', error: message } })
      }
    })
  }

  render() {
    const { status, linkToShorten } = this.state
    return (
      <Menu className="bg-white">
        <form className="pb-3 pt-2 px-3">
          <p className="mb-2 text-black-50">Insert a link</p>
          <TextInput
            autoFocus
            className=""
            value={linkToShorten}
            onChange={this.handleInputChange}
            disabled={status.type === 'loading'}
            placeholder="https://example.com..."
          />
          <div className="d-flex justify-content-around align-items-center pt-3">
            <small className="flex-grow-1 text-muted font-italic mr-4 text-black-50">
              Link will be shortened with bit.ly
            </small>
            <Button
              color="primary"
              height="small"
              className="flex-grow-0 border px-4 py-1"
              disabled={linkToShorten.length <= 0}
              type="button"
              onClick={this.handleSubmitLink}
              loading={status.type === 'loading'}>
              Insert
            </Button>
          </div>
          {status.type === 'error' && (
            <small className="flex-grow-1 text-danger mr-4">
              Error: {status.error}
            </small>
          )}
        </form>
      </Menu>
    )
  }
}
