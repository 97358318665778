import { RootState } from 'store/store'
import { getCurrentInstitutionDisplayName } from 'store/triage/profile/selectors'
import { connect } from 'react-redux'
import SettingsMenu from 'components/SettingsMenu/SettingsMenu'

const mapStateToProps = (state: RootState) => ({
  orgName: getCurrentInstitutionDisplayName(state) || 'loading...',
})

export default connect(mapStateToProps)(SettingsMenu)
