import { createAsyncAction, ActionType } from 'typesafe-actions'
import { IApiError } from 'api'
import { IInstitution } from 'api/response'

export const fetchCurrentInstitution = createAsyncAction(
  '@@mascot/legacy/institution/FETCH_CURRENT_INSTITUTION_START',
  '@@mascot/legacy/institution/FETCH_CURRENT_INSTITUTION_SUCCESS',
  '@@mascot/legacy/institution/FETCH_CURRENT_INSTITUTION_FAIL'
)<undefined, IInstitution, IApiError>()

export const updateDefaultCounselor = createAsyncAction(
  '@@mascot/legacy/institution/UUPDATE_DEFAULT_COUNSELOR_START',
  '@@mascot/legacy/institution/UUPDATE_DEFAULT_COUNSELOR_SUCCESS',
  '@@mascot/legacy/institution/UUPDATE_DEFAULT_COUNSELOR_FAIL'
)<undefined, IInstitution, IApiError>()

export const updateDateFormat = createAsyncAction(
  '@@mascot/legacy/profile/UPDATE_DATE_FORMAT_START',
  '@@mascot/legacy/profile/UPDATE_DATE_FORMAT_SUCCESS',
  '@@mascot/legacy/profile/UPDATE_DATE_FORMAT_FAIL'
)<undefined, { dateFormat: string }, IApiError>()

export type IInstitutionActions =
  | ActionType<typeof fetchCurrentInstitution>
  | ActionType<typeof updateDefaultCounselor>
  | ActionType<typeof updateDateFormat>
