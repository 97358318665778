// TODO(chdsbd): We should remove all dependencies on triage and unify our logic
// in this reducer for the conversations page.
import {
  createAsyncAction,
  ActionType,
  getType,
  createStandardAction,
} from 'typesafe-actions'

import {
  WebData,
  HttpErrorKind,
  Loading,
  Success,
  Failure,
} from 'store/webdata'
import { ICounselorsResponseData } from 'api/response'
import { ICounselors } from 'store/escalation/selectors'

export const fetchCounselors = createAsyncAction(
  '@@MASCOT/FETCH_COLLEGE_COUNSELORS_POSTGRES_START',
  '@@MASCOT/FETCH_COLLEGE_COUNSELORS_POSTGRES_SUCCESS',
  '@@MASCOT/FETCH_COLLEGE_COUNSELORS_POSTGRES_FAILURE'
)<void, ICounselorsResponseData, HttpErrorKind>()

export const getSuggestedCounselors = createAsyncAction(
  '@@MASCOT/GET_SUGGESTED_COUNSELORS_START',
  '@@MASCOT/GET_SUGGESTED_COUNSELORS_SUCCESS',
  '@@MASCOT/GET_SUGGESTED_COUNSELORS_FAILURE'
)<{ smsLogId: string }, ICounselorsResponseData, HttpErrorKind>()

export const forwardMessage = createAsyncAction(
  '@@MASCOT/FORWARD_MESSAGE_START',
  '@@MASCOT/FORWARD_MESSAGE_SUCCESS',
  '@@MASCOT/FORWARD_MESSAGE_FAILURE'
)<undefined, undefined, HttpErrorKind>()

export const enum ForwardMessagePanel {
  FORWARD,
  HIDDEN,
}

export const forwardPanel = (messageId: string) => ({
  type: ForwardMessagePanel.FORWARD as const,
  messageId,
})

export const hideForwardPanel = () => ({
  type: ForwardMessagePanel.HIDDEN as const,
})

export type ConversationsForwardState =
  | ReturnType<typeof forwardPanel>
  | ReturnType<typeof hideForwardPanel>

export const setForwardPanelMessageId = createStandardAction(
  '@@MASCOT/SET_CONVERSATIONS_FORWARD_PANEL'
)<string>()
export const setForwardPanelMessageHidden = createStandardAction(
  '@@MASCOT/HIDE_CONVERSATIONS_FORWARD_PANEL'
)()

export type IConversationsActions =
  | ActionType<typeof fetchCounselors>
  | ActionType<typeof getSuggestedCounselors>
  | ActionType<typeof forwardMessage>
  | ActionType<typeof setForwardPanelMessageId>
  | ActionType<typeof setForwardPanelMessageHidden>
  | ActionType<typeof setForwardPanelMessageHidden>

export interface IConversationsState {
  readonly dropdownCounselors: WebData<ICounselors>
  readonly suggestedCounselors: WebData<ICounselors>
  /** Status of a request to forward a message */
  readonly forwardingMessage: WebData<{}>
  /** Status of polling for new conversation messages in the message list */
  readonly conversationsForward: ConversationsForwardState
}

export const defaultConversationsState: IConversationsState = {
  dropdownCounselors: undefined,
  suggestedCounselors: undefined,
  forwardingMessage: undefined,
  conversationsForward: hideForwardPanel(),
}

export const conversationsReducer = (
  state: IConversationsState = defaultConversationsState,
  action: IConversationsActions
): IConversationsState => {
  switch (action.type) {
    case getType(fetchCounselors.request):
      return state
    case getType(fetchCounselors.success):
      return {
        ...state,
        dropdownCounselors: Success(
          action.payload.map(elem => ({ ...elem, collegeId: elem.college_id }))
        ),
      }
    case getType(fetchCounselors.failure):
      return {
        ...state,
        dropdownCounselors: Failure(action.payload),
      }
    case getType(getSuggestedCounselors.request):
      return { ...state, suggestedCounselors: Loading() }
    case getType(getSuggestedCounselors.success):
      return {
        ...state,
        suggestedCounselors: Success(
          action.payload.map(elem => ({ ...elem, collegeId: elem.college_id }))
        ),
      }
    case getType(getSuggestedCounselors.failure):
      return { ...state, suggestedCounselors: Failure(action.payload) }
    case getType(forwardMessage.request):
      return { ...state, forwardingMessage: Loading() }
    case getType(forwardMessage.success):
      return {
        ...state,
        forwardingMessage: Success({}),
        conversationsForward: hideForwardPanel(),
      }
    case getType(forwardMessage.failure):
      return { ...state, forwardingMessage: Failure(action.payload) }
    case getType(setForwardPanelMessageId):
      return { ...state, conversationsForward: forwardPanel(action.payload) }
    case getType(setForwardPanelMessageHidden):
      return { ...state, conversationsForward: hideForwardPanel() }
    default:
      return state
  }
}

export default conversationsReducer
