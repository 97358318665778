import { AxiosError } from 'axios'
import { Dispatch, saveAuthToLocalStorage } from 'store/store'
import { toastOnHttpError500or400 } from 'api/http'

import {
  switchCurrentInstitutionStart,
  switchCurrentInstitutionSuccess,
  switchCurrentInstitutionFail,
  fetchUsersForOrg,
  fetchUserProfileStart,
  fetchUserProfileSuccess,
  fetchUserProfileFail,
} from 'store/triage/profile/actions'
import * as Api from 'api'
import { assertNever } from 'util/exhaustiveness'

export const switchCurrentInstitutionAsync = ({
  currentInstitution,
  newInstitution,
  reloadBehavior = 'reloadPage',
  redirect = '',
  propagateError = false,
}: {
  currentInstitution: string
  newInstitution: string
  reloadBehavior?: 'reloadPage' | 'navigateRoot' | 'redirect'
  redirect?: string
  propagateError?: boolean
}) => (dispatch: Dispatch) => {
  dispatch(switchCurrentInstitutionStart(newInstitution))
  return Api.updateCurrentInstitution(newInstitution)
    .then(response => {
      dispatch(switchCurrentInstitutionSuccess(response.data))
      // note(jsimms): This is sorta ugly but necessary. Since we do a force refresh here on
      // institution change, we need to ensure that the current institution is in the store
      // and then the store is serialized to local storage before the refresh happens.
      // We do this by manually calling the save function and then doing the refresh.
      saveAuthToLocalStorage()
      sessionStorage.setItem('currentInstitution', newInstitution)
      if (reloadBehavior === 'reloadPage') {
        document.location.search = ''
      } else if (reloadBehavior === 'navigateRoot') {
        document.location.pathname = ''
      } else if (reloadBehavior === 'redirect') {
        document.location.assign(redirect || '')
      } else {
        assertNever(reloadBehavior)
      }
    })
    .catch((error: AxiosError) => {
      if (propagateError) {
        throw error
      }
      toastOnHttpError500or400(error)
      dispatch(switchCurrentInstitutionFail(currentInstitution, error))
    })
}
export const fetchUsersForOrgAsync = (dispatch: Dispatch) => {
  return () => {
    dispatch(fetchUsersForOrg.request())
    return Api.fetchUsersForOrg()
      .then(res => {
        dispatch(fetchUsersForOrg.success(res.data))
      })
      .catch((err: AxiosError) => {
        toastOnHttpError500or400(err)
        dispatch(fetchUsersForOrg.failure())
      })
  }
}

export const fetchProfileAsync = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchUserProfileStart())
    return Api.getCurrentUserProfile()
      .then(response => {
        dispatch(fetchUserProfileSuccess(response.data))
      })
      .catch((error: AxiosError) => {
        toastOnHttpError500or400(error)
        dispatch(fetchUserProfileFail(error))
      })
  }
}
