import { IAuthState } from 'store/auth/reducer'
export const LAST_AUTO_REFRESH_KEY = 'LAST_AUTO_REFRESH'

export const loadAuthState = (): IAuthState | undefined => {
  try {
    const serializedState = localStorage.getItem('authState')

    if (serializedState === null) {
      return undefined
    }
    const currentOrg: string | null = sessionStorage.getItem(
      'currentInstitution'
    )
    /* eslint-disable @typescript-eslint/consistent-type-assertions */
    // tslint:disable-next-line:no-unsafe-any
    const authState = JSON.parse(serializedState) as IAuthState
    /* eslint-enable @typescript-eslint/consistent-type-assertions */
    return {
      ...authState,
      orgSlug: currentOrg ?? authState.orgSlug,
    }
  } catch (err) {
    return undefined
  }
}

export const saveAuthState = (authState: IAuthState) => {
  try {
    const serializedState = JSON.stringify(authState)
    // Note(jsimms): we want to cache the institution in session storage
    // since session storage is scoped to a given tab/window, that way when
    // a user opens a new tab and switches orgs, hard refreshes wont cause
    // the user to be switched into the updated org in the old tab.
    localStorage.setItem('authState', serializedState)
    // tslint:disable-next-line no-empty
  } catch (err) {}
}

export function getItemFromLocalStorage(key: string) {
  try {
    if (window && window.localStorage) {
      return window.localStorage.getItem(key)
    }
    return undefined
  } catch {
    return undefined
  }
}

export function setItemToLocalStorage(key: string, value: string) {
  try {
    if (window && window.localStorage) {
      return window.localStorage.setItem(key, value)
    }
    return undefined
  } catch {
    return undefined
  }
}
