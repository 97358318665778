import React from 'react'
import { RootState as IState } from 'store/store'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { SearchInput } from 'components/SearchInput/SearchInput'
import { connect } from 'react-redux'
import {
  getTopLevelContactFields,
  IContactAttributes,
  IContactField,
  ITopLevelContactFields,
  getAllContactAttributes,
} from 'store/personalization/contactAttributes/selectors'
import {
  WebData,
  isSuccessOrRefetching,
  isRefetching,
  isSuccess,
} from 'store/webdata'
import { Spinner } from 'components/Spinner/Spinner'
import { RefetchingOverlay } from 'components/RefetchingOverlay/RefetchingOverlay'

import 'components/AttributeSelector/AttributeSelector.scss'
import {
  AttributeSubSection,
  IAttributeSelectorProps,
} from 'components/AttributeSelector/AttributeSelector'
import { IContactAttributeDraftEditor } from 'store/knowledgeSeeder/reducer'

interface IContactAttributeSelectorProps extends IAttributeSelectorProps {
  contactAttributeResults: WebData<IContactAttributes>
  topLevelContactFields: WebData<ITopLevelContactFields>
  includeSensitiveContactAttributes: boolean
  onSelectAttribute: (attribute: IContactAttributeDraftEditor) => void
}

export const UnconnectedContactAttributeSelector = ({
  contactAttributeResults,
  topLevelContactFields,
  includeSensitiveContactAttributes,
  onSelectAttribute,
  onClose,
}: IContactAttributeSelectorProps) => {
  const [query, setQuery] = React.useState('')
  const [contactFields, setcontactFields] = React.useState<
    Array<IContactField>
  >([])

  React.useEffect(() => {
    if (
      isSuccess(topLevelContactFields) &&
      isSuccess(contactAttributeResults)
    ) {
      setcontactFields(
        [
          ...topLevelContactFields.data.filter(
            x =>
              ![
                'mongo_college_id',
                'facebook_id',
                'facebook_opt_in',
                'name_middle',
                'name_full',
                'permitted_user_mutable',
                'last_incoming_message_id',
                'last_outgoing_message_id',
                'last_message_id',
                'ab_group',
                'cohort_id',
                'import_segment_labels',
                'contact_labels',
                'transport',
                'permitted_user',
                'test_user',
                'contacted',
                'delivery_failure_count',
                'received_scheduled_message_ids',
                'received_dialog_ids',
                'web_bot_tokens',
                'currently_opted_in',
              ].includes(x.field)
          ),
          ...contactAttributeResults.data,
        ].filter(({ field }) =>
          field.toLowerCase().includes(query.toLowerCase())
        )
      )
    }
  }, [topLevelContactFields, contactAttributeResults, query])

  return (
    <div className="w-100 border rounded shadow-lg bg-white">
      <AttributeSubSection className="justify-content-between">
        <span className="text-mainstay-dark-blue-65">
          Insert Contact Fields
        </span>
        {onClose && (
          <AHIcon
            name="close"
            className="large pointer text-mainstay-dark-blue-65"
            onClick={onClose}
          />
        )}
      </AttributeSubSection>
      <AttributeSubSection>
        <SearchInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setQuery(e.target.value)
          }}
          placeholder="Search Fields"
          name=""
        />
      </AttributeSubSection>
      <AttributeSubSection className="flex-column attribute-search-results">
        {isSuccessOrRefetching(contactAttributeResults) &&
        isSuccess(topLevelContactFields) ? (
          <RefetchingOverlay enabled={isRefetching(contactAttributeResults)}>
            <>
              {contactFields.length > 0 ? (
                <>
                  {contactFields.map(attribute => {
                    if (
                      attribute.requires_auth &&
                      !includeSensitiveContactAttributes
                    ) {
                      return null
                    }
                    return (
                      <div
                        className="d-flex justify-content-between p-2 react-select__option"
                        key={attribute?.id + attribute.field}
                        onClick={() => {
                          onSelectAttribute({
                            id: attribute.id,
                            field: attribute.field,
                            type: attribute.type,
                          })
                          onClose()
                        }}>
                        <div>{attribute?.readableName || attribute.field}</div>
                      </div>
                    )
                  })}
                </>
              ) : (
                <div className="d-flex justify-content-center w-100 py-5">
                  No results to show
                </div>
              )}
            </>
          </RefetchingOverlay>
        ) : (
          <div className="w-100 h-100 justify-content-center align-content-center d-flex p-4">
            <Spinner className="stroke-bg-mainstay-dark-blue" />
          </div>
        )}
      </AttributeSubSection>
    </div>
  )
}

const mapStateToProps = (
  state: IState,
  ownProps: Pick<
    IContactAttributeSelectorProps,
    'onSelectAttribute' | 'onClose' | 'includeSensitiveContactAttributes'
  >
) => {
  return {
    ...ownProps,
    contactAttributeResults: getAllContactAttributes(state),
    topLevelContactFields: getTopLevelContactFields(state),
  }
}

export const ContactAttributeSelector = connect(mapStateToProps)(
  UnconnectedContactAttributeSelector
)
