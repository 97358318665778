import classNames from 'classnames'
import { IContactState } from 'store/triage/chat/contactsReducer'
import { Link } from 'util/routing'

interface INonProps {
  className?: string
}
export function None({ className }: INonProps) {
  return <b className={classNames('text-muted', className)}>None</b>
}

interface ICampaignHistoryProps {
  readonly history: IContactState['campaignHistory']
}
export const CampaignHistory = ({ history }: ICampaignHistoryProps) => {
  const sortedHistory = Object.keys(history)
    .map(key => {
      return {
        dialogId: key,
        ...history[key],
      }
    })
    .sort((a, b) => {
      if (b.started_at === null || a.started_at === null) {
        return 0
      }
      return new Date(b.started_at).valueOf() - new Date(a.started_at).valueOf()
    })
  if (sortedHistory.length === 0) {
    return <None />
  }

  return (
    <>
      {sortedHistory.map(({ dialogId, name, finished_at, started_at }) => {
        return (
          <div className="mb-2 d-flex flex-column" key={dialogId}>
            <Link
              className="mb-0 text-ellipsis fw-600"
              to={`/campaign-scripts/${dialogId}/view`}>
              {name || dialogId}
            </Link>
            <div className="text-mainstay-dark-blue-65">
              {started_at
                ? `Started at: ${new Date(started_at).toLocaleString()}`
                : 'Unstarted'}
            </div>
            <div className="text-mainstay-dark-blue-65">
              {finished_at
                ? `Completed at: ${new Date(finished_at).toLocaleString()}`
                : 'Unfinished'}
            </div>
          </div>
        )
      })}
    </>
  )
}
