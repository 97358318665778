import {
  getAllContactAttributes,
  listTopLevelContactFields,
} from 'store/personalization/contactAttributes/actions'
import { Dispatch } from 'store/store'
import * as Api from 'api'
import { AxiosError } from 'typings/axios'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { toastOnHttpError500or400 } from 'api/http'
import { getAllInstitutionAttributes } from 'store/personalization/institutionAttributes/actions'
import { isRight, isLeft } from 'fp-ts/lib/Either'

export const getAllContactAttributesAsync = (dispatch: Dispatch) => {
  return () => {
    dispatch(getAllContactAttributes.request())
    return Api.fetchContactAttributes({ pageSize: 2000 })
      .then(response => {
        dispatch(getAllContactAttributes.success(response.data.results))
      })
      .catch((err: AxiosError) => {
        if (err.response && err.response.status === 404) {
          toast('Failed to fetch contact attributes.', {
            type: 'error',
            options: { autoClose: 1000 },
          })
        }
        toastOnHttpError500or400(err)
        dispatch(getAllContactAttributes.failure(err))
      })
  }
}

export const listTopLevelContactFieldsAsync = (dispatch: Dispatch) => () => {
  dispatch(listTopLevelContactFields.request())
  return Api.fetchTopLevelContactFields()
    .then(res => {
      dispatch(listTopLevelContactFields.success(res.data))
    })
    .catch((e: AxiosError) => {
      toastOnHttpError500or400(e)
      dispatch(listTopLevelContactFields.failure(e))
    })
}

export const getAllAttributesAndFieldsAsync = (dispatch: Dispatch) => (
  locationsUsed = false
) => {
  dispatch(getAllInstitutionAttributes.request())
  dispatch(getAllContactAttributes.request())
  dispatch(listTopLevelContactFields.request())
  return Api.fetchAllAttributesAndFields(locationsUsed)
    .then(res => {
      if (isRight(res)) {
        dispatch(
          getAllInstitutionAttributes.success(res.right.institutionAttributes)
        )
        dispatch(getAllContactAttributes.success(res.right.contactAttributes))
        dispatch(listTopLevelContactFields.success(res.right.topLevelFields))
      }
      if (isLeft(res)) {
        throw new Error('Failed to fetch attributes and fields')
      }
    })
    .catch((e: AxiosError) => {
      toastOnHttpError500or400(e)
      dispatch(getAllInstitutionAttributes.failure(e))
      dispatch(getAllContactAttributes.failure(e))
      dispatch(listTopLevelContactFields.failure(e))
    })
}
