import {
  ActionType,
  createStandardAction,
  createAsyncAction,
  getType,
} from 'typesafe-actions'
import { IPhoneNumbersResponseData } from 'api/response'
import { IPhoneNumber } from 'page/SettingsSMS'

export interface IPurchasePhoneNumbersFormReducerState {
  readonly areaCode: string
  // Note(sbdchd): we keep quantity as a string since using `parseInt` and assigning to
  // `value` of the input results in glitchy behavior when deleting.
  readonly quantity: string
  readonly purchaseStatus: 'initial' | 'loading' | 'success' | 'failure'
  readonly phoneNumbers: Array<IPhoneNumber>
}

export const setAreaCode = createStandardAction(
  '@@mascot/settings/purchasePhoneNumbers/setAreaCode'
)<string>()

export const setQuantity = createStandardAction(
  '@@mascot/settings/purchasePhoneNumbers/setQuantity'
)<string>()

export const purchasePhoneNumbers = createAsyncAction(
  '@@mascot/settings/purchasePhoneNumbers/purchase/request',
  '@@mascot/settings/purchasePhoneNumbers/purchase/success',
  '@@mascot/settings/purchasePhoneNumbers/purchase/failure'
)<void, IPhoneNumbersResponseData, void>()

type PurchasePhoneNumbersFormActions =
  | ActionType<typeof setAreaCode>
  | ActionType<typeof setQuantity>
  | ActionType<typeof purchasePhoneNumbers>

export function purchasePhoneNumbersFormReducer(
  state: IPurchasePhoneNumbersFormReducerState,
  action: PurchasePhoneNumbersFormActions
): IPurchasePhoneNumbersFormReducerState {
  switch (action.type) {
    case getType(setAreaCode):
      return { ...state, areaCode: action.payload }
    case getType(setQuantity):
      return { ...state, quantity: action.payload }
    case getType(purchasePhoneNumbers.request):
      return { ...state, purchaseStatus: 'loading' }
    case getType(purchasePhoneNumbers.success):
      // (jerardi 8/14) Since we're only supporting the purchasing of US phones numbers through Mascot,
      // we can tag every phone number purchased with countryCode: US
      // When we enable purchasing of international phone numbers within Mascot, this code will need to change
      const newPhoneNumbers = action.payload.numbers.map(n => {
        return { phoneE164: n, countryCode: 'US' }
      })
      return {
        ...state,
        purchaseStatus: 'success',
        quantity: '0',
        phoneNumbers: [...state.phoneNumbers, ...newPhoneNumbers],
      }
    case getType(purchasePhoneNumbers.failure):
      return { ...state, purchaseStatus: 'failure' }
    default:
      return state
  }
}
