import {
  createAsyncAction,
  ActionType,
  createStandardAction,
} from 'typesafe-actions'
import { IApiError } from 'api'
import {
  IQuestionSearchResponseData,
  ISearchUnderstandingByTopicResult,
} from 'api/response'

export const searchUnderstandings = createAsyncAction(
  '@@mascot/knowledge/UNDERSTANDING_SEARCH_START',
  '@@mascot/knowledge/UNDERSTANDING_SEARCH_SUCCESS',
  '@@mascot/knowledge/UNDERSTANDING_SEARCH_FAIL'
)<
  void,
  { query: string; understandings: ISearchUnderstandingByTopicResult[] },
  IApiError
>()

export const suggestedQuestionSearch = createAsyncAction(
  '@@mascot/knowledge/SUGGESTED_QUESTION_SEARCH_START',
  '@@mascot/knowledge/SUGGESTED_QUESTION_SEARCH_SUCCESS',
  '@@mascot/knowledge/SUGGESTED_QUESTION_SEARCH_FAIL'
)<void, { query: string; matches: IQuestionSearchResponseData }, IApiError>()

export const getUnderstandingTopics = createAsyncAction(
  '@@mascot/knowledge/GET_UNDERSTANDING_TOPICS_START',
  '@@mascot/knowledge/GET_UNDERSTANDING_TOPICS_SUCCESS',
  '@@mascot/knowledge/GET_UNDERSTANDING_TOPICS_FAIL'
)<void, string[], IApiError>()

export const initKnowledgeReducer = createStandardAction(
  '@@MASCOT/KNOWLEDGE/INIT'
)()

export type IKnowledgeActions =
  | ActionType<typeof searchUnderstandings>
  | ActionType<typeof suggestedQuestionSearch>
  | ActionType<typeof getUnderstandingTopics>
  | ActionType<typeof initKnowledgeReducer>
