import { AHIcon } from 'components/Icons/AHIcon/AHIcon'

import {
  BarChartIcon,
  IBarChartIconProps,
} from 'components/Icons/BarChartIcon/BarChartIcon'
import {
  StackedBarChartIcon,
  IStackedBarChartIconProps,
} from 'components/Icons/StackedBarChartIcon/StackedBarChartIcon'
import {
  LineChartIcon,
  ILineChartIconProps,
} from 'components/Icons/LineChartIcon/LineChartIcon'
import {
  DonutChartIcon,
  IDonutChartIconProps,
} from 'components/Icons/DonutChartIcon/DonutChartIcon'
import { Graphs, humanizedInsightGraphsName } from 'components/TrendsV2/types'

import { isKeyOfObject, isValueOfObject } from 'util/typeguards'

import {
  NestedDropdown,
  INestedDropItem,
} from 'components/NestedDropdown/NestedDropdown'
import { ClickEvent } from '@szhsin/react-menu'

export enum ChartType {
  BAR = 'Bar',
  STACKED_BAR = 'Stacked bar',
  LINE = 'Line',
  DONUT = 'Donut',
}

export const SupportedCharts: ReadonlyArray<ChartType> = [
  ChartType.BAR,
  ChartType.STACKED_BAR,
  ChartType.LINE,
  ChartType.DONUT,
]

export enum ChartWidth {
  NARROW = 'NARROW',
  WIDE = 'WIDE',
}
export type ChartWidthEnum = typeof ChartWidth[keyof typeof ChartWidth]

export const ChartSize = {
  Narrow: 'narrow',
  Wide: 'wide',
} as const

export type ChartSizeEnum = typeof ChartSize[keyof typeof ChartSize]

type IChartIconProps = IBarChartIconProps &
  IStackedBarChartIconProps &
  ILineChartIconProps &
  IDonutChartIconProps

export const InsightsWithoutDataView = [
  'Report Bundle',
  'Important Topics',
  'Missed Questions',
  'Answered Messages',
  'Webchat Feedback',
]

export const getAllowedChartTypes = (dataView: Graphs): ChartType[] => {
  if (InsightsWithoutDataView.includes(dataView)) {
    return []
  }

  const baseChartTypes = [ChartType.BAR, ChartType.LINE]
  switch (dataView) {
    case 'Campaigns':
    case 'Engagement':
    case 'Opt Outs':
    case 'Conversations':
      return baseChartTypes.concat([ChartType.STACKED_BAR])
    case 'Individuals Contacted':
    case 'Incoming Messages By Time Of Day':
    case 'Incoming Messages By Day Of Week':
      return baseChartTypes.concat(ChartType.DONUT)
    default:
      return baseChartTypes.concat([ChartType.DONUT, ChartType.STACKED_BAR])
  }
}

export function getChartIcon(type: string, iconProps: IChartIconProps) {
  if (type === ChartType.BAR) {
    return <BarChartIcon {...iconProps} />
  }
  if (type === ChartType.LINE) {
    return <LineChartIcon {...iconProps} />
  }
  if (type === ChartType.DONUT) {
    return <DonutChartIcon {...iconProps} />
  }
  if (type === ChartType.STACKED_BAR) {
    return <StackedBarChartIcon {...iconProps} />
  }
  return <></>
}

interface IInsightsChartMenuProps {
  chartWidth: string
  chartType: string
  insight: string
  onChartSizeToggle: (size: ChartWidth) => void
  onChartTypeToggle: (type: string) => void
}

export function isChartTypeEnabledForInsight(
  insightStr: string,
  chartType: ChartType
) {
  return (
    isKeyOfObject(insightStr, humanizedInsightGraphsName) &&
    getAllowedChartTypes(humanizedInsightGraphsName[insightStr]).includes(
      chartType
    )
  )
}

export const InsightsChartMenu = ({
  chartWidth,
  chartType,
  insight,
  onChartSizeToggle,
  onChartTypeToggle,
}: IInsightsChartMenuProps) => {
  const insightStr = String(insight)
  const location = 'insights'
  const action = 'click'
  const subMenuItems: INestedDropItem[] = SupportedCharts.map(type => {
    const disabled = !getAllowedChartTypes(insightStr).includes(type)
    return {
      label: type,
      iconBefore: getChartIcon(type, { muted: disabled }),
      iconAfter: chartType === type ? <AHIcon name="check" /> : null,
      disabled,
      event: {
        location,
        action,
        object: `${type.toLowerCase()} chart`,
      },
    }
  })

  const handleToggleChartSize = (e: ClickEvent) => {
    const eventValue = e?.value ? String(e.value) : ''
    const widthVerb = eventValue.split(' ')[0]
    if (widthVerb.toLowerCase() === 'widen') {
      onChartSizeToggle(ChartWidth.WIDE)
      return
    }
    onChartSizeToggle(ChartWidth.NARROW)
  }

  const handleChangeChartType = (e: ClickEvent) => {
    const newChartType = e?.value
    if (isValueOfObject(newChartType, ChartType)) {
      onChartTypeToggle(String(newChartType))
    }
  }

  const changeChartSizeVerb =
    chartWidth === ChartWidth.NARROW ? 'Widen' : 'Narrow'

  const items: INestedDropItem[] = [
    {
      label: `${changeChartSizeVerb} chart`,
      event: {
        location,
        action,
        object: `${changeChartSizeVerb.toLowerCase()} chart`,
      },
    },
    {
      label: 'Chart style',
      isSubMenuItem: true,
      subMenuItems,
      hidden: InsightsWithoutDataView.includes(insightStr),
    },
  ]

  return (
    <NestedDropdown
      items={items}
      onClickItem={handleToggleChartSize}
      onClickSubItem={handleChangeChartType}
    />
  )
}
