import React from 'react'
import {
  Async as ReactSelectAsync,
  AsyncCreatable as ReactSelectAsyncCreatable,
} from 'react-select'
import { Props as AsyncProps } from 'react-select/lib/Async'
import { Props as CreatableProps } from 'react-select/lib/Creatable'
import {
  FormGroup,
  IAutoSuggestOption,
  Control,
  NoOptionsMessage,
  formatCreateLabel,
  IAutoSuggestProps,
} from 'components/AutoSuggest/AutoSuggest'
import { useAutoSuggestHandlers } from 'util/hooks'
import classnames from 'classnames'
import { ValueType } from 'react-select/lib/types'

import { FormHelperText } from '@material-ui/core'
import { EventAction } from 'components/EventTracker/EventTracker'
import { ControlProps } from 'react-select/lib/components/Control'
import scssVariables from 'scss/_variables.scss'
import MultiValue, {
  MultiValueProps,
} from 'react-select/lib/components/MultiValue'
import { DropdownIndicator } from 'components/SettingsEscalation/reactSelectUtils'

interface IAsyncOwnProps {
  readOnly?: boolean
}
class Async<T> extends React.PureComponent<AsyncProps<T> & IAsyncOwnProps> {
  render() {
    const { components, ...rest } = this.props

    return (
      <ReactSelectAsync<T>
        classNamePrefix="react-select"
        components={{
          Control: (controlProps: ControlProps<T>) => (
            <Control readOnly={this.props.readOnly} {...controlProps} />
          ),
          NoOptionsMessage,
          ...components,
        }}
        {...rest}
      />
    )
  }
}

class AsyncCreatable<T> extends React.PureComponent<
  AsyncProps<T> & CreatableProps<T>
> {
  render() {
    const { components, ...rest } = this.props
    return (
      <ReactSelectAsyncCreatable<T>
        classNamePrefix="react-select"
        components={{ Control, NoOptionsMessage, ...components }}
        formatCreateLabel={formatCreateLabel}
        {...rest}
      />
    )
  }
}

interface IAsyncAutoSuggestOwnProps<T> {
  render: (
    renderProps: CreatableProps<IAutoSuggestOption<T>> &
      AsyncProps<IAutoSuggestOption<T>>
  ) => JSX.Element
  value?: ValueType<IAutoSuggestOption<T>>
  defaultOptions?: IAutoSuggestOption<T>[] | boolean
  helpText?: React.ReactNode
  formGroupClassnames?: string
  readOnly?: boolean
}

export interface IAsyncAutoSuggestProps<T>
  extends Omit<IAutoSuggestProps<T>, 'value'>,
    Pick<
      CreatableProps<IAutoSuggestOption<T>>,
      'onInputChange' | 'createOptionPosition'
    >,
    Pick<
      AsyncProps<IAutoSuggestOption<T>>,
      | 'loadOptions'
      | 'components'
      | 'hideSelectedOptions'
      | 'closeMenuOnSelect'
      | 'cacheOptions'
      | 'options'
      | 'noOptionsMessage'
    >,
    IAsyncAutoSuggestOwnProps<T> {}

type AsyncAutoSuggestContainerProps<T> = Pick<
  IAsyncAutoSuggestProps<T>,
  | 'name'
  | 'id'
  | 'onChange'
  | 'onCreate'
  | 'onInputChange'
  | 'onBlur'
  | 'value'
  | 'loadOptions'
  | 'defaultOptions'
  | 'render'
  | 'hideSelectedOptions'
  | 'closeMenuOnSelect'
  | 'components'
  | 'cacheOptions'
  | 'options'
  | 'noOptionsMessage'
  | 'createOptionPosition'
>

export function AsyncAutoSuggestContainer<T>({
  name,
  id,
  onChange,
  onCreate,
  onInputChange,
  onBlur,
  value,
  loadOptions,
  defaultOptions,
  render,
  hideSelectedOptions,
  closeMenuOnSelect,
  components,
  cacheOptions,
  options,
  noOptionsMessage,
  createOptionPosition,
}: AsyncAutoSuggestContainerProps<T>) {
  const {
    handleBlur,
    handleCreate,
    getOptionLabel,
    getOptionValue,
    handleChange,
  } = useAutoSuggestHandlers({ onBlur, onChange, onCreate, id, name })

  return render({
    value,
    defaultOptions,
    loadOptions,
    onInputChange,
    getOptionLabel,
    getOptionValue,
    onChange: handleChange,
    onCreateOption: handleCreate,
    onBlur: handleBlur,
    hideSelectedOptions,
    closeMenuOnSelect,
    components,
    cacheOptions,
    options,
    noOptionsMessage,
    createOptionPosition,
  })
}

export function CustomMultiValue<T>(props: MultiValueProps<T>) {
  return (
    <MultiValue
      {...props}
      className={classnames(props.className, 'bg-mainstay-dark-blue-10')}
    />
  )
}

export type AsyncAutoSuggestProps<T> = Omit<
  IAsyncAutoSuggestProps<T>,
  'render'
> & { eventAction?: EventAction; eventObject?: string }

function AsyncAutoSuggest<T>(props: AsyncAutoSuggestProps<T>) {
  const className = classnames(
    'form-control',
    'p-0',
    'border-0',
    {
      'is-invalid': props.touched && !props.isValid,
    },
    props.className
  )
  const defaultStyles = {
    multiValueLabel: (base: React.CSSProperties) => ({
      ...base,
      background: 'transparent !important',
      borderColor: scssVariables.mainstayBlue80,
      color: scssVariables.mainstayDarkBlue,
      padding: '0px 4px',
      fontSize: '0.8rem !important',
    }),
  }
  return (
    <div className={props.wrapperClassName}>
      <AsyncAutoSuggestContainer
        {...props}
        render={innerProps => {
          return (
            <FormGroup
              eventAction={props.eventAction}
              eventObject={props.eventObject}
              name={props.name}
              id={props.id}
              error={props.error}
              isValid={props.isValid}
              touched={props.touched}
              className={props.formGroupClassnames}
              label={props.label}>
              {props.creatable ? (
                <AsyncCreatable<IAutoSuggestOption<T>>
                  styles={{ ...defaultStyles, ...props.styles }}
                  isDisabled={props.disabled}
                  id={props.id || props.name}
                  name={props.name}
                  isMulti={props.multi}
                  isLoading={props.loading}
                  placeholder={props.placeholder}
                  className={className}
                  noOptionsMessage={innerProps.noOptionsMessage}
                  {...innerProps}
                  components={{
                    MultiValue: CustomMultiValue,
                    DropdownIndicator,
                    IndicatorSeparator: undefined,
                    ...props.components,
                  }}
                />
              ) : (
                <Async<IAutoSuggestOption<T>>
                  styles={{ ...defaultStyles, ...props.styles }}
                  id={props.id || props.name}
                  name={props.name}
                  isMulti={props.multi}
                  isDisabled={props.disabled}
                  isLoading={props.loading}
                  closeMenuOnSelect={props.closeOnSelect}
                  className={className}
                  aria-label="Search"
                  readOnly={props.readOnly}
                  {...innerProps}
                  components={{
                    MultiValue: CustomMultiValue,
                    DropdownIndicator,
                    IndicatorSeparator: undefined,
                    ...props.components,
                  }}
                />
              )}
              {props.helpText && (
                <FormHelperText>{props.helpText}</FormHelperText>
              )}
            </FormGroup>
          )
        }}
      />
    </div>
  )
}

export default AsyncAutoSuggest
