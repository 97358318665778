import { updateSettingsSSO, updateFeatureFlagForInstitution } from 'api'
import { Button } from 'components/Button/Button'
import { TextInput } from 'components/TextInput/TextInput'
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch'
import { FastField, FieldProps, Form, Formik, FormikProps } from 'formik'
import { isRight } from 'fp-ts/lib/Either'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { SettingWithoutField } from 'page/SettingsGeneral'
import * as React from 'react'
import * as nope from 'yup'
import { FeaturesType } from 'components/Feature/Feature'

interface ISSOFormProps {
  readonly ssoDomain: string
  readonly ssoEntityID: string
  readonly passwordBasedAuthDisabled: boolean
}

interface ISSOSettingsProps {
  enabled: boolean
  ssoDomain?: string
  ssoEntityID?: string
  passwordBasedAuthDisabled: boolean
}

const SSOSettingsValidationSchema = nope.object().shape({
  ssoEntityID: nope.string().required('Required'),
  ssoDomain: nope.string().required('Required'),
})

export const SSOSettings = ({
  enabled,
  ssoDomain,
  ssoEntityID,
  passwordBasedAuthDisabled,
}: ISSOSettingsProps) => {
  const [isEnabled, setIsEnabled] = React.useState(enabled)
  const [formChanged, setFormChanged] = React.useState(false)

  const handleToggle = () => {
    if (isEnabled) {
      updateFeatureFlagForInstitution({
        feature: FeaturesType.INCOMMON_SSO,
        enable: !isEnabled,
      })
        .then(() => {
          toast.success('successfully updated feature')
          setIsEnabled(false)
        })
        .catch(() => {
          toast.error('failure updating feature flag')
          setIsEnabled(prev => !prev)
        })
    } else {
      setIsEnabled(true)
      setFormChanged(true)
    }
  }

  const handleSubmit = async ({
    ssoEntityID,
    ssoDomain,
    passwordBasedAuthDisabled,
  }: ISSOFormProps) => {
    const res = await updateSettingsSSO({
      ssoDomain,
      ssoEntityID,
      passwordBasedAuthDisabled,
      enableSSO: isEnabled,
    })
    if (isRight(res)) {
      toast('Successfully updated setting.', { type: 'success' })
    } else {
      toast('Failed to update setting', { type: 'error' })
    }
  }

  const helpText = 'Allow users to log into Mainstay using SSO authentication.'

  return (
    <SettingWithoutField name="Use SSO" helpText={helpText}>
      <div className="d-flex align-items-center mb-4">
        <ToggleSwitch checked={isEnabled} onChange={handleToggle} />
      </div>
      {isEnabled && (
        <Formik
          initialValues={{
            ssoDomain: ssoDomain ?? '',
            ssoEntityID: ssoEntityID ?? '',
            passwordBasedAuthDisabled,
          }}
          onSubmit={handleSubmit}
          validationSchema={SSOSettingsValidationSchema}
          render={(props: FormikProps<ISSOFormProps>) => {
            const { errors, touched } = props
            return (
              <Form className="d-flex flex-column">
                <FastField
                  name="ssoDomain"
                  render={({ field }: FieldProps<ISSOFormProps>) => (
                    <div className="form-group position-relative mb-2">
                      <TextInput
                        {...field}
                        className="w-100 mb-3"
                        placeholder="Enter Org Domain"
                        error={touched.ssoDomain && !!errors.ssoDomain}
                        onChange={event => {
                          setFormChanged(true)
                          field.onChange(event)
                        }}
                        type="text"
                      />
                      {touched.ssoDomain && !!errors.ssoDomain ? (
                        <span className="input--error error small">
                          {errors.ssoDomain}
                        </span>
                      ) : null}
                    </div>
                  )}
                />
                <FastField
                  name="ssoEntityID"
                  render={({ field }: FieldProps<ISSOFormProps>) => (
                    <div className="form-group position-relative">
                      <TextInput
                        {...field}
                        className="w-100 mb-3"
                        placeholder="Enter InCommon ID"
                        error={touched.ssoEntityID && !!errors.ssoEntityID}
                        onChange={event => {
                          setFormChanged(true)
                          field.onChange(event)
                        }}
                        type="text"
                      />
                      {touched.ssoEntityID && !!errors.ssoEntityID ? (
                        <span className="input--error error small">
                          {errors.ssoEntityID}
                        </span>
                      ) : null}
                    </div>
                  )}
                />
                <FastField
                  name="passwordBasedAuthDisabled"
                  render={({ form }: FieldProps<ISSOFormProps>) => (
                    <div className="form-group position-relative d-flex align-items-center">
                      Prevent Email/Password Login
                      <ToggleSwitch
                        className="px-3"
                        checked={!!form.values.passwordBasedAuthDisabled}
                        onChange={value => {
                          setFormChanged(true)
                          form.setFieldValue('passwordBasedAuthDisabled', value)
                        }}
                      />
                    </div>
                  )}
                />
                {formChanged && (
                  <Button
                    className="mt-1 btn btn-secondary-teal"
                    type="submit"
                    disabled={!!(errors.ssoDomain || errors.ssoEntityID)}>
                    Update
                  </Button>
                )}
              </Form>
            )
          }}
        />
      )}
    </SettingWithoutField>
  )
}
