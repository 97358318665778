import Tippy, { TippyProps } from '@tippy.js/react'
import 'tippy.js/themes/light.css'
import cls from 'classnames'

Tippy.defaultProps = {
  ...Tippy.defaultProps,
  arrow: true,
  arrowType: 'round',
  interactive: false,
  animation: 'scale',
  delay: [250, 0],
  duration: 100,
  maxWidth: 250,
}

/**
 * Display a tooltip.
 * @params children - The target component which on hover will show the tooltip. If this is a functional component then you must declare your component using React.forwardRef like so:
 *   ```tsx
 *   React.forwardRef((props, ref) => (
 *     <yourchildcomponent {...props} ref={ref] />
 *   ))
 *   ```
 */
export function Tooltip({
  isEnabled = true,
  content,
  noPadding,
  interactive,
  ...props
}: TippyProps & { noPadding?: boolean }) {
  const paddedContent = <div className="py-1">{content}</div>

  return (
    // rendering an empty string results in an ambiguous splotch on the screen
    // so we disable the tooltip instead.
    <Tippy
      className={cls('text-muted text-left shadow', props.className)}
      {...props}
      interactive={interactive}
      isEnabled={isEnabled && content !== ''}
      content={noPadding ? content : paddedContent}
      theme="light"
    />
  )
}
export default Tooltip
