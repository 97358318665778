import React from 'react'
import {
  WebData,
  isSuccess,
  isRefetching,
  isFailure,
  isInitial,
  isPending,
  isUnresolved,
} from 'store/webdata'
import {
  fetchFilteredContactsAsync,
  clearFilterTaskAsync,
} from 'store/contact-segments/thunks'
import { MainstayFlexTable } from 'mainstay-ui-kit/MainstayFlexTable/MainstayFlexTable'
import {
  MainstayFlexTableHeader,
  MainstayFlexTableRow,
} from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import {
  MainstayFlexTableHeaderCol,
  MainstayFlexTableCol,
} from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import { connect } from 'react-redux'
import { useDebounce } from 'util/hooks'
import Loader from 'components/Loader/Loader'
import { Pager } from 'components/LinkPager/LinkPager'
import { RootState as IState, Dispatch } from 'store/store'
import { RouteComponentProps, withRouter } from 'react-router'
import {
  getFilteredContacts,
  getTotalRecipients,
  getAttributeNames,
  getAdditionalContactFields,
  getCountFilteredContactsPager,
  getLastTaskId,
  getLastTaskFilterData,
} from 'store/contact-segments/selectors'
import pluralize from 'pluralize'
import { SearchInput } from 'components/SearchInput/SearchInput'
import { LabelPopover } from 'components/LabelPopover/LabelPopover'
import { AudiencePreviewDownloadButton } from 'components/ContactList/ContactListConnected'
import { IContactFilterRequestData } from 'api/request'
import { clearImportedContacts } from 'store/contact-segments/actions'
import { isFilterDataEqual } from 'components/ContactFilterBuilder/formUtils'
import { IFilteredContact } from 'store/contact-segments/reducer'
import { isKeyOfObject } from 'util/typeguards'

const fieldNameToHumanReadable = (name: string, topLevel: boolean = false) => {
  /*
   *   Turns "camelCasedString" into an "Each Word Capitalized With Spaces String"
   *   Top level fields are sometimes nested, and if so we remove the first field and dot,
   *   so that "_contactSettings.permittedUserMutable" -> "Permitted User Mutable"
   */

  const trimmed = topLevel ? name.replace(/^.*\./, '') : name
  const separated = trimmed.replace(/([A-Z])/g, ' $1')
  return separated.charAt(0).toUpperCase() + separated.slice(1)
}

const getPreviewCopy = (totalRecipients?: number) => {
  const contactCountSpan = (
    <span>
      <strong>
        {` ${totalRecipients} ${pluralize('Contact', totalRecipients)}`}
      </strong>
    </span>
  )

  return (
    <>
      {totalRecipients && (
        <>
          <span>This Audience criteria currently matches to</span>
          {contactCountSpan}.
        </>
      )}
      <span>
        {' '}
        If you send a Campaign to this Audience, contacts who are archived,
        opted out, paused, or have an invalid phone will not receive messages.
        If you schedule a Campaign to this Audience for a future date, the
        recipients may be different, based on their data at that point.
      </span>
    </>
  )
}

interface IFilteredContactsDispatchProps {
  fetchFilteredContacts: (
    importLabels: string[],
    contactFilterId: number | undefined,
    contactFilterObj: IContactFilterRequestData | undefined,
    page: number,
    pageSize: number,
    ordering: string,
    searchQuery: string,
    taskId: string | undefined
  ) => void
  clearImportedContacts: () => void
  clearFilterTask: () => void
}

interface IFilteredContactsStateProps {
  filteredContacts: WebData<IFilteredContact[]>
  totalRecipients: number
  countFilteredContactsForPager: WebData<number>
  attributeNames: string[]
  additionalContactFields: string[]
}

interface IFilteredContactsOwnProps extends RouteComponentProps<{}> {
  showCampaignRecipientsParagraph?: boolean
  filterByImport?: boolean
  filterBySegment?: boolean
  filterByFilterObj?: boolean
  selectedFilterId: number | undefined | null
  importLabels: string[]
  contactFilterObj: IContactFilterRequestData | undefined
  isSlowFilter?: boolean
  setSlowFilter?: (isSlow: boolean) => void
  lastTaskId?: string
  lastFilterObj?: IContactFilterRequestData
}

const FilteredContactsUnconnected = ({
  importLabels = [],
  filteredContacts,
  totalRecipients,
  attributeNames,
  additionalContactFields,
  countFilteredContactsForPager,
  showCampaignRecipientsParagraph,
  fetchFilteredContacts,
  clearImportedContacts,
  filterByImport = false,
  filterBySegment = false,
  filterByFilterObj = false,
  selectedFilterId = undefined,
  contactFilterObj = undefined,
  isSlowFilter,
  setSlowFilter,
  lastTaskId,
  lastFilterObj,
  clearFilterTask,
}: IFilteredContactsOwnProps &
  IFilteredContactsDispatchProps &
  IFilteredContactsStateProps) => {
  const [page, setPage] = React.useState<number>(1)
  const [searchQuery, setSearchQuery] = React.useState<string>('')
  const [retries, setRetries] = React.useState(0)
  const debouncedQuery = useDebounce(searchQuery, 500)

  const handleFetch = React.useCallback(
    (taskId?: string) => {
      const labels = filterByImport ? importLabels : []
      const filterId = filterBySegment ? selectedFilterId : undefined
      const filterObj = filterByFilterObj ? contactFilterObj : undefined
      fetchFilteredContacts(
        labels,
        filterId || undefined,
        filterObj,
        page,
        20,
        '-createdAt',
        debouncedQuery,
        taskId
      )
    },
    [
      contactFilterObj,
      debouncedQuery,
      fetchFilteredContacts,
      filterByFilterObj,
      filterByImport,
      filterBySegment,
      importLabels,
      page,
      selectedFilterId,
    ]
  )

  React.useEffect(() => {
    clearImportedContacts()
  }, [debouncedQuery, clearImportedContacts, page])

  React.useEffect(() => {
    if (retries > 10 && setSlowFilter) {
      setSlowFilter(true)
    }
  }, [retries, setSlowFilter])

  React.useEffect(() => {
    let task = lastTaskId
    // We should fetch the same task as before UNLESS the filter requests data has changed
    if (
      !contactFilterObj ||
      !lastFilterObj ||
      !isFilterDataEqual(lastFilterObj, contactFilterObj)
    ) {
      clearFilterTask()
      task = undefined
    }

    if (isInitial(filteredContacts)) {
      setRetries(0)
      handleFetch(task)
    }
    if (isPending(filteredContacts)) {
      setRetries(r => r + 1)
      const timeout = setTimeout(() => {
        handleFetch(filteredContacts.taskId)
      }, 2500)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [
    filteredContacts,
    lastTaskId,
    handleFetch,
    setRetries,
    debouncedQuery,
    lastFilterObj,
    contactFilterObj,
    clearFilterTask,
  ])

  const handleChangeSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
    setPage(1)
  }

  return (
    <div className="mx-5">
      {showCampaignRecipientsParagraph &&
        getPreviewCopy(
          isSuccess(filteredContacts) ? totalRecipients : undefined
        )}
      <div className="d-flex align-items-center justify-content-between">
        <div className="w-50 my-3">
          <SearchInput
            placeholder="Search within these contacts"
            onChange={handleChangeSearchQuery}
            value={searchQuery}
            eventAction="change"
            eventLocation="audiences"
            eventObject="search contacts of audience"
          />
        </div>
        <AudiencePreviewDownloadButton
          count={totalRecipients}
          isLoading={isUnresolved(filteredContacts)}
          disabled={isFailure(filteredContacts)}
          importLabels={importLabels}
          contactFilterId={selectedFilterId}
          contactFilterObj={contactFilterObj}
          eventLocation="audiences"
          eventObject="download contacts of audience"
        />
      </div>
      <div className="overflow-x-table overflow-y-table">
        <MainstayFlexTable
          className={`contact-table table-min-width-${attributeNames.length +
            additionalContactFields.length}`}>
          <MainstayFlexTableHeader>
            <MainstayFlexTableHeaderCol>First</MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol>Last</MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol>Phone</MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol>Email</MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol>Labels</MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol>ID</MainstayFlexTableHeaderCol>
            {additionalContactFields.map(field => (
              <MainstayFlexTableHeaderCol key={field}>
                {fieldNameToHumanReadable(field, true)}
              </MainstayFlexTableHeaderCol>
            ))}
            {attributeNames.map(field => (
              <MainstayFlexTableHeaderCol key={field}>
                {fieldNameToHumanReadable(field)}
              </MainstayFlexTableHeaderCol>
            ))}
          </MainstayFlexTableHeader>
          {(isRefetching(filteredContacts) ||
            isUnresolved(filteredContacts)) && (
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ width: 500, height: 600 }}>
              <Loader />
              {isSlowFilter && (
                <div className="mt-4">
                  This can take a few minutes the first time you preview a
                  audience if you have a large number of contacts. This will
                  continue in the background. You do not need to keep this panel
                  open, and you can continue making changes to your segment.
                </div>
              )}
            </div>
          )}
          {isFailure(filteredContacts) && (
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ width: 500, height: 600 }}>
              Failed to load contacts. Please try again.
            </div>
          )}
          {isSuccess(filteredContacts) &&
            filteredContacts.data.map(elem => (
              <MainstayFlexTableRow key={elem.id}>
                <MainstayFlexTableCol className="text-truncate">
                  {elem.name?.first || '-'}
                </MainstayFlexTableCol>
                <MainstayFlexTableCol className="text-truncate">
                  {elem.name?.last || '-'}
                </MainstayFlexTableCol>
                <MainstayFlexTableCol className="text-truncate">
                  {elem.phone}
                </MainstayFlexTableCol>
                <MainstayFlexTableCol className="text-truncate">
                  {elem.email}
                </MainstayFlexTableCol>
                <MainstayFlexTableCol>
                  {elem.contactLabels?.length ? (
                    <LabelPopover
                      labels={elem.contactLabels.map(label => label.text)}
                      popoverId={elem.id}
                      maxWidth={100}
                    />
                  ) : (
                    '-'
                  )}
                </MainstayFlexTableCol>
                <MainstayFlexTableCol className="text-truncate">
                  {elem.id}
                </MainstayFlexTableCol>
                {additionalContactFields.map(field => {
                  /*
                   *  Additional Contact Fields are strings, and sometimes singly nested in dot notation, e.g.
                   * "_contactSettings.permittedUserMutable", so we need to split them and verify that they
                   *  exist as contact fields before accessing them with brackets.
                   */
                  const rootField = field.split('.')[0]
                  if (!isKeyOfObject(rootField, elem)) {
                    return
                  }
                  const subField = field.includes('.')
                    ? field.split('.')[1]
                    : null
                  const value = elem[rootField]
                  // Non-nested fields can be accessed as elem[rootField]
                  if (!subField) {
                    return (
                      <MainstayFlexTableCol
                        key={`${elem.id} ${field}`}
                        className="text-truncate">
                        {value ? String(value) : ''}
                      </MainstayFlexTableCol>
                    )
                  }
                  // Nested fields get type checked again and accessed ultimately as elem[rootField][subField]
                  if (isKeyOfObject(subField, value)) {
                    return (
                      <MainstayFlexTableCol
                        key={`${elem.id} ${field}`}
                        className="text-truncate">
                        {value
                          ? value[subField]
                            ? String(value[subField])
                            : ''
                          : ''}
                      </MainstayFlexTableCol>
                    )
                  }
                })}
                {attributeNames.map(attribute => (
                  <MainstayFlexTableCol
                    key={`${elem.id} ${attribute}`}
                    className="text-truncate">
                    {elem.attributes[attribute]
                      ? String(elem.attributes[attribute])
                      : ''}
                  </MainstayFlexTableCol>
                ))}
              </MainstayFlexTableRow>
            ))}
        </MainstayFlexTable>
        {isSuccess(filteredContacts) &&
          isSuccess(countFilteredContactsForPager) && (
            <Pager
              className="mt-5"
              size="sm"
              first={1}
              current={page}
              urlFormat={() => location.pathname}
              onClick={e => {
                setPage(parseInt(e.currentTarget.dataset.page || '1', 10) || 1)
              }}
              last={Math.max(
                Math.ceil(countFilteredContactsForPager.data / 20),
                1
              )}
              eventAction="click"
              eventLocation="audiences"
              eventObject="recipient preview"
            />
          )}
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFilteredContacts: fetchFilteredContactsAsync(dispatch),
  clearImportedContacts: () => dispatch(clearImportedContacts()),
  clearFilterTask: clearFilterTaskAsync(dispatch),
})

const mapStateToProps = (state: IState) => ({
  filteredContacts: getFilteredContacts(state),
  totalRecipients: getTotalRecipients(state),
  countFilteredContactsForPager: getCountFilteredContactsPager(state),
  lastTaskId: getLastTaskId(state),
  lastFilterObj: getLastTaskFilterData(state),
  attributeNames: getAttributeNames(state),
  additionalContactFields: getAdditionalContactFields(state),
})

export const FilteredContacts = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FilteredContactsUnconnected)
)
