import * as _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { datadogRum } from '@datadog/browser-rum'

import * as ACTION_TYPES from 'store/triage/profile/actionTypes'
import { IProfileActions, fetchUsersForOrg } from 'store/triage/profile/actions'
import { ILimitedInstitution, IUserResponseData } from 'api/response'
import {
  IMessagesActionTypes,
  getMessagesInConversation,
  updatePinnedConversations,
} from 'store/triage/chat/messagesActions'
import { getType } from 'typesafe-actions'
import { FeaturesType } from 'components/Feature/Feature'

const INITIAL_STATE = {
  currentUser: {
    id: '',
    createdAt: null,
    name: {
      first: '',
      last: '',
      full: '',
    },
    email: undefined,
    idle: false,
    institution: {
      id: '',
      collegeId: '',
      facebook: null,

      channels: [],
      name: '',
      messagingService: '',
      oliName: '',
      displayName: '',
      enabledFeatures: [],
      passwordConstraints: [],
      internal: false,
      abbr: '',
      dateFormat: '',
      timeZone: '',
    },
    region: '',
    isRegionalUser: false,
    admitHubUser: false,
    engineeringEmployee: false,
    permissions: [],
    permissionRoles: [],
    isStaff: false,
  },
  byId: {},
  allIds: [],
  isLoggedIn: false,
  loading: false,
  loadingFetchUsers: false,
}

const identifyUserForTracking = (currentUser: IUserState) => {
  Sentry.configureScope(scope =>
    scope.setUser({
      id: currentUser.id,
      name: currentUser.name.full,
      email: currentUser.email,
      org: currentUser.institution.id,
    })
  )
  datadogRum.setUser({
    id: currentUser.id,
    email: currentUser.email ?? '',
    name: currentUser.name.full ?? '',
  })
  const userData = {
    createdAt: currentUser.createdAt?.valueOf(),
    email: currentUser.email,
    firstName: currentUser.name.first,
    lastName: currentUser.name.last,
    institutionId: currentUser.institution.id,
    region: currentUser.region || undefined,
    admithubUser: currentUser.admitHubUser,
    botType: currentUser.institution.botType,
    botPackage: currentUser.institution?.package?.name,
    role: currentUser?.permissionRoles && currentUser.permissionRoles[0],
    // Flatten arrays as most tracking platforms want flat data
    botKnowledgePacks:
      currentUser.institution?.knowledgePacks?.reduce(
        (m, v) => m + v.name + ',',
        ''
      ) || '',
    botChannels:
      currentUser.institution?.channelsMetadata?.reduce(
        (m, v) => m + v.name + ',',
        ''
      ) || '',
    botNumberOfContactsPurchased:
      currentUser.institution?.numberOfContactsPurchased,
  }
  if (window.Appcues) {
    window.Appcues.identify(currentUser.id, userData)
  }
  if (window.heap) {
    window.heap.identify(currentUser.id)
    window.heap.addUserProperties(userData)
  }
}

const mapUserStateFromResponseData = (
  response: IUserResponseData
): IUserState => {
  const nameSplit =
    (response.profile &&
      response.profile.name &&
      response.profile.name.split(' ')) ||
    []
  const email: IUserResponseData['emails'][0] | undefined =
    response.emails && response.emails[0]
  return {
    id: response.id,
    name: {
      first:
        (response.profile && response.profile.firstName) || nameSplit[0] || '',
      last:
        (response.profile && response.profile.lastName) || nameSplit[1] || '',
      full: (response.profile && response.profile.name) || '',
    },
    email: (email && email.address) || undefined,
    idle: false,
    institution: response.currentInstitutionObj,
    admitHubUser: response.admitHubUser,
    engineeringEmployee: response.engineeringEmployee,
    createdAt: new Date(response.createdAt),
    region: response.region,
    isRegionalUser: !!response.region,
    enabledFeatures: response.enabledFeatures,
    permissions: response.permissions,
    permissionRoles: response.permissionRoles,
    pinnedConversationIds: response.pinnedConversationIds,
    isStaff: response.isStaff,
  }
}

export interface IProfileState {
  currentUser: IUserState
  byId: {
    [id: string]: IUserState
  }
  allIds: string[]
  isLoggedIn: boolean
  loading: boolean
  loadingFetchUsers: boolean
}

export interface IUserState {
  id: string
  createdAt: Date | null
  name: {
    first: string
    last: string
    full: string
  }
  email?: string
  idle: boolean
  institution: ILimitedInstitution
  admitHubUser: boolean
  engineeringEmployee: boolean
  region: string | null
  isRegionalUser: boolean
  readonly enabledFeatures?: ReadonlyArray<FeaturesType>
  permissions: string[]
  permissionRoles: string[]
  pinnedConversationIds?: string[]
  isStaff: boolean
}

const reducer = (
  state: IProfileState = INITIAL_STATE,
  action: IProfileActions | IMessagesActionTypes
): IProfileState => {
  switch (action.type) {
    // TODO(sbdchd): use createAsyncActions()
    case ACTION_TYPES.FETCH_USER_PROFILE_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case ACTION_TYPES.FETCH_USER_PROFILE_START: {
      return {
        ...state,
        loading: true,
      }
    }
    case ACTION_TYPES.FETCH_USER_PROFILE_SUCCESS: {
      const currentUser = mapUserStateFromResponseData(action.payload)
      identifyUserForTracking(currentUser)
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        currentUser,
      }
    }
    case ACTION_TYPES.UPDATE_USER_NAME_SUCCESS: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          name: {
            first: action.payload.profile.firstName ?? '',
            last: action.payload.profile.lastName ?? '',
            full: action.payload.profile.name,
          },
        },
      }
    }
    case getType(getMessagesInConversation.success): {
      const users = _.chain(action.payload.data.handledBy)
        .map(mapUserStateFromResponseData)
        .keyBy(x => x.id)
        .value()
      const ids = _.uniq([...state.allIds, ...Object.keys(users)])
      // PERF(chdsbd): Don't update state unless we have new data
      if (_.isEqual(ids, state.allIds)) {
        return state
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          ...users,
        },
        allIds: [...ids],
      }
    }
    case getType(updatePinnedConversations): {
      let pinnedConversationIds = _.uniq([
        ...(state.currentUser.pinnedConversationIds || []),
        action.payload.conversationId,
      ])
      if (!action.payload.pinned) {
        pinnedConversationIds = pinnedConversationIds.filter(
          x => x !== action.payload.conversationId
        )
      }
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          pinnedConversationIds,
        },
      }
    }
    case ACTION_TYPES.SWITCH_CURRENT_INSTITUTION_START: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          institution: {
            ...state.currentUser.institution,
            id: action.payload,
          },
        },
      }
    }
    case ACTION_TYPES.SWITCH_CURRENT_INSTITUTION_SUCCESS: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          institution: action.payload,
        },
      }
    }
    case ACTION_TYPES.SWITCH_CURRENT_INSTITUTION_FAIL: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          institution: {
            ...state.currentUser.institution,
            id: action.payload.prevInstitution,
          },
        },
      }
    }
    case getType(fetchUsersForOrg.request): {
      return {
        ...state,
        loadingFetchUsers: true,
      }
    }
    case getType(fetchUsersForOrg.failure): {
      return {
        ...state,
        loadingFetchUsers: false,
      }
    }
    case getType(fetchUsersForOrg.success): {
      const ids = _.uniq([...state.allIds, ...action.payload.map(x => x.id)])
      if (_.isEqual(ids, state.allIds)) {
        return state
      }
      const users = _.chain(action.payload)
        .map(mapUserStateFromResponseData)
        .keyBy(x => x.id)
        .value()
      return {
        ...state,
        allIds: [...state.allIds, ...ids],
        byId: {
          ...state.byId,
          ...users,
        },
        loadingFetchUsers: false,
      }
    }
    default:
      return state
  }
}

export default reducer
