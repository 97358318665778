import { IInsightsData } from 'api/response'
import {
  addWeeks,
  format,
  getISOWeek,
  getISOWeekYear,
  startOfISOWeek,
  subDays,
  differenceInDays,
} from 'date-fns'
import { isoWeekStart, isoWeekRange } from 'util/datetime'
import { isFirefox } from 'react-device-detect'
import { MainstayLogo } from 'components/Icons/MainstayIcon/MainstayIcon'
import { renderToStaticMarkup } from 'react-dom/server'
import html2canvas from 'html2canvas'
import moment from 'moment'
import { DashboardResponse, InsightsFilter } from 'components/TrendsV2/types'
import {
  ChannelEnum,
  TransportEnum,
  channelToTransport,
  isChannel,
  transportToChannel,
} from 'store/transport'
import {
  DEFAULT_FILTERS_INITIAL_STATE,
  IFilterStates,
  TODAY,
} from 'components/FilterContactsModal/filterContactsReducer'
import { RelativeDateRangeEnum } from 'components/FilterContactsModal/FilterContactsModal'
import { parseQueryString } from 'util/string'
import { createContext } from 'react'

export const GuestPageContext = createContext<{
  isGuest?: boolean
  clickToLogin?: () => void
}>({})

// datetime format returned from the backend for the Week buckets
const ISO_WEEK_AND_YEAR_FORMAT = 'I::yyyy'

const formatWeekTick = (week: number, year: number) => {
  const weekStart = isoWeekStart(year, week)
  return `${weekStart.format('MM/DD')}`
}

export const getTruncatedWeek = ({
  startDate,
  endDate,
  year,
  week,
}: {
  startDate: Date
  endDate: Date
  year: number
  week: number
}) => {
  /*
      From a year and week number, get isoWeek that has been truncated only if the start or end
      dates provided would cut it short.
  */
  const { start: bucketStart, end: bucketEnd } = isoWeekRange(year, week)
  return {
    start: moment(startDate).isAfter(bucketStart)
      ? moment(startDate)
      : bucketStart,
    end: moment(endDate).isBefore(bucketEnd) ? moment(endDate) : bucketEnd,
  }
}

export const zeroFillInsightsData = <T>(
  data: Array<IInsightsData<T>>,
  blankRecord: Partial<T>,
  from: Date,
  to: Date
) => {
  /*
      Take a single insight data bucketed by *Week*, and add blank values to 
      missing records throughout the selected date range to complete the data points. 
      Also, add labels for the graph.
    */
  // should not happen, but handle it to display an empty graph
  if (from > to) {
    return
  }

  const zeroFilledPoints = []
  for (
    let d = startOfISOWeek(from);
    d <= startOfISOWeek(to);
    d = addWeeks(d, 1)
  ) {
    const bucketData = data.find(
      x => x.bucket_datetime === format(d, ISO_WEEK_AND_YEAR_FORMAT)
    )
    const _week = getISOWeek(d)
    const _year = getISOWeekYear(d)
    if (!!bucketData) {
      zeroFilledPoints.push({
        ...bucketData.points,
        label: formatWeekTick(_week, _year),
      })
    } else {
      zeroFilledPoints.push({
        ...blankRecord,
        week: _week,
        year: _year,
        label: formatWeekTick(_week, _year),
      })
    }
  }
  return zeroFilledPoints
}

export const generateDivWithMainstayWaterMark = (
  element: HTMLDivElement,
  title: string
) => {
  const mainstayLogo = document.createElement('div')
  mainstayLogo.innerHTML = renderToStaticMarkup(MainstayLogo())
  mainstayLogo.className = 'graph-ms-logo'

  const waterMarkedGraph = document.createElement('div')

  const header = document.createElement('h5')
  header.className = 'text-mainstay-dark-blue m-0 ml-2'
  header.innerHTML = title
  waterMarkedGraph.appendChild(header)

  waterMarkedGraph.append(element.cloneNode(true))
  waterMarkedGraph.appendChild(mainstayLogo)
  waterMarkedGraph.className = 'watermarked-graph-container'
  waterMarkedGraph.style.maxWidth = `${element.offsetWidth + 70}px`
  return waterMarkedGraph
}

/* 
    Takes a ref for a div, converts the div into an image, adds a Mainstay watermark and then copies the image to clipboard.
    Will throw errors if copy to clipboard functionality is not supported.
    * @param ref ref of the div to process
    * @param onCopyComplete (optional) callback URL to perform any necessary cleanup after the copying is complete
*/
export const copyDivToCliboardAsImage = async (
  title: string,
  ref: React.MutableRefObject<HTMLDivElement | null>,
  onCopyComplete?: () => void
) => {
  const divElement = ref.current
  if (!divElement) {
    throw new Error('Could not copy image to clipboard. Div not found.')
  }

  const waterMarkedGraph = generateDivWithMainstayWaterMark(divElement, title)
  document.body.appendChild(waterMarkedGraph)

  function blobPromise(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      html2canvas(waterMarkedGraph)
        .then(canvas => {
          canvas.toBlob(blob => {
            if (!blob) {
              return reject(
                new Error(
                  'Could not copy image to clipboard. No blob generated'
                )
              )
            }
            resolve(blob)
          })
        })
        .catch(e => {
          waterMarkedGraph.remove()
          reject(e)
        })
    })
  }

  // Special handling is needed for firefox
  if (isFirefox) {
    const reader = new FileReader()
    reader.onload = () => {
      // Firefox does not support ClipboardItem obj, nor does it allow copy/paste of images
      // Workaround: add a contentEditable div to the DOM and add an img as its child
      // The img src is the result of FileReader.readAsDataURL(Blob) (not a Promise this time)
      const wrapper = document.createElement('div')
      wrapper.contentEditable = 'true'
      document.body.appendChild(wrapper)
      const image = document.createElement('img')
      if (reader.result && typeof reader.result === 'string') {
        image.src = reader.result
        wrapper.appendChild(image)
      } else {
        throw new Error(
          'Could not copy image to clipboard. Invalid result from file reader.'
        )
      }
      try {
        window.getSelection()?.selectAllChildren(wrapper)
        // document.execCommand is technically deprecated but still supported by some browsers
        // If this does not work, the fallback of downloading the image will suffice.
        document.execCommand('copy')
        wrapper.remove()
        onCopyComplete?.()
      } catch (e) {
        wrapper.remove()
        throw e
      }
    }
    const blob = await blobPromise()
    reader.readAsDataURL(blob)
    waterMarkedGraph.remove()
    onCopyComplete?.()
  } else {
    // Try to copy the image to the clipboard
    // This attempt should work for Chrome/Safari/Edge
    await navigator.clipboard.write([
      new ClipboardItem({ 'image/png': blobPromise() }),
    ])
    waterMarkedGraph.remove()
    onCopyComplete?.()
  }
}

/*
    Takes a canvas element, adds Mainstay watermark and downloads it as an image.
*/
export const downloadImageFromCanvas = async (
  title: string,
  element: HTMLDivElement,
  onDownloadComplete?: () => void
) => {
  const waterMarkedGraph = generateDivWithMainstayWaterMark(element, title)
  document.body.appendChild(waterMarkedGraph)
  const canvas = await html2canvas(waterMarkedGraph)
  waterMarkedGraph.remove()
  document.body.appendChild(canvas)
  const link = document.createElement('a')
  link.download = 'mainstay-graph.png'
  link.href = canvas.toDataURL()
  link.click()
  canvas.remove()
  onDownloadComplete?.()
}

export const getFilterQueryString = ({
  contact_filter: contactFilter,
  date_range_expression: dateRangeExpression,
  transports,
}: InsightsFilter) => {
  // Expected format:
  // from=today-90&to=today&audience=671&channel=sms&channel=web_bot
  let queryString = dateRangeExpression
  if (contactFilter) {
    queryString = queryString + `&audience=${contactFilter}`
  }
  transports.forEach(transport => {
    queryString = queryString + `&channel=${transportToChannel(transport)}`
  })
  return queryString
}

export const getTransportsFromChannelFiltersObj = (
  channelFiltersObj: IFilterStates['channelFiltersObj']
): typeof TransportEnum[keyof typeof TransportEnum][] => {
  // @ts-expect-error
  const channelFilterArray: typeof ChannelEnum[keyof typeof ChannelEnum][] = Object.keys(
    channelFiltersObj
  ).filter(key => {
    if (!isChannel(key)) {
      return false
    }
    // @ts-expect-error
    const channel: typeof ChannelEnum[keyof typeof ChannelEnum] = key
    return channelFiltersObj[channel]
  })
  return channelFilterArray.map(channel => channelToTransport(channel))
}

const getFormattedDate = (date: Date): string =>
  date.toISOString().split('T')[0]

const getDateExpressionFromRelativeDateRangeObj = ({
  relativeDateRangeType,
  daysAgo,
  relStartDate,
  relEndDate,
}: IFilterStates['relativeDateRangeObj']): string => {
  switch (relativeDateRangeType) {
    case RelativeDateRangeEnum.last:
      return `from=today-${daysAgo}&to=today`
    case RelativeDateRangeEnum.dateRange:
      return `from=${getFormattedDate(relStartDate)}&to=${getFormattedDate(
        relEndDate
      )}`
    case RelativeDateRangeEnum.startingOn:
      return `from=${getFormattedDate(relStartDate)}&to=today`
  }
}

export const getInsightsFilterFromFilterStates = (
  filterStates: IFilterStates
): DashboardResponse['dashboard']['insights_filter'] => {
  return {
    contact_filter: filterStates.audienceFilter ?? null,
    transports: getTransportsFromChannelFiltersObj(
      filterStates.channelFiltersObj
    ),
    date_range_expression: getDateExpressionFromRelativeDateRangeObj(
      filterStates.relativeDateRangeObj
    ),
  }
}

const getRelativeDateRangeObjFromDateExpression = (
  dateRangeExpression: string
): IFilterStates['relativeDateRangeObj'] => {
  const { from, to } = parseQueryString(dateRangeExpression)
  const parsedFrom = from && !Array.isArray(from) ? from : 'today-90'
  const parsedTo = to && !Array.isArray(to) ? to : 'today'

  if (parsedTo === 'today') {
    if (parsedFrom.includes('today-')) {
      const daysAgo = parseInt(parsedFrom.split('-')[1], 10)
      return {
        relativeDateRangeType: 'last',
        daysAgo,
        relStartDate: subDays(TODAY.setHours(0, 0, 0, 0), daysAgo),
        relEndDate: TODAY,
      }
    }
    const relStartDate = new Date(parsedFrom)
    return {
      relativeDateRangeType: 'starting on',
      daysAgo: differenceInDays(TODAY, relStartDate),
      relStartDate,
      relEndDate: TODAY,
    }
  }
  const relStartDate = new Date(parsedFrom)
  const relEndDate = new Date(parsedTo)
  return {
    relativeDateRangeType: 'date range',
    daysAgo: differenceInDays(relEndDate, relStartDate),
    relStartDate,
    relEndDate,
  }
}

export const getFilterStatesFromInsightsFilter = (
  insightsFilter: DashboardResponse['dashboard']['insights_filter']
): IFilterStates => {
  return {
    ...DEFAULT_FILTERS_INITIAL_STATE,
    // @ts-expect-error
    channelFiltersObj: Object.keys(ChannelEnum).reduce((obj, item) => {
      return {
        ...obj,
        // @ts-expect-error
        [item]: insightsFilter.transports.includes(channelToTransport(item)),
      }
    }, {}),
    audienceFilter: insightsFilter.contact_filter,
    relativeDateRangeObj: getRelativeDateRangeObjFromDateExpression(
      insightsFilter.date_range_expression
    ),
  }
}
