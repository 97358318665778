import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
  ChangeEvent,
  useMemo,
} from 'react'
import {
  IAutoSuggestProps,
  IAutoSuggestOption,
} from 'components/AutoSuggest/AutoSuggest'
import { ValueType } from 'react-select/lib/types'
import { isArray } from 'util'
// eslint-disable-next-line no-restricted-imports
import {
  TypedUseSelectorHook,
  useDispatch as useDispatchRedux,
  useSelector as useSelectorRedux,
} from 'react-redux'
import { Dispatch, RootState } from 'store/store'
import { FeaturesType } from 'components/Feature/Feature'
import { getEnabledFeatures } from 'store/triage/profile/selectors'
import axios, { CancelToken, AxiosPromise, AxiosResponse } from 'axios'
import { WebData, Loading, Success, Failure } from 'store/webdata'
import * as api from 'api'
import { isEqual } from 'lodash'
import { UNSET_ID } from 'store/auth/reducer'
import { ILimitedInstitution } from 'api/response'
import { useHistory } from 'react-router'
import { History } from 'history'
import { ESCAPE_STRINGS } from 'embed/HideShowContext'

/**
 * Takes a value, and only returns a new one after `delay`.
 * @param value updates more rapidly than the desired `delay`
 * @param delay seconds to wait before producing a new `value`
 * from: https://usehooks.com/useDebounce/
 */
export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

// tslint:disable:no-any
/**
 * See which prop changes are causing a component to re-render
 * @param name component name to log to console
 * @param props component props to track
 *
 * source: https://usehooks.com/useWhyDidYouUpdate/
 *
 * NOTE(chdsbd): It's best to _not_ destructure functional component props
 * before passing them into useWhyDidYouUpdate.
 * @example
 * function Example (props: IExampleProps) {
 *    useWhyDidYouUpdate('Example', props)
 *    const { name } = props
 *    return <p>{name}</p>
 * }
 *
 */
// Hook
export function useWhyDidYouUpdate<T extends {}>(name: string, props: T) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef(props)

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ...(previousProps.current as {}),
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ...(props as {}),
      })
      // Use this object to keep track of changed props
      const changesObj: { [key: string]: any } = {}
      // Iterate through keys
      allKeys.forEach(key => {
        // If previous is different from current
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        if ((previousProps.current as any)[key] !== (props as any)[key]) {
          // Add to changesObj
          changesObj[key] = {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            from: (previousProps.current as any)[key],
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            to: (props as any)[key],
          }
        }
      })

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        // tslint:disable-next-line:no-console
        console.log('[why-did-you-update]', name, changesObj)
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props
  })
}

// tslint:enable:no-any
export function useInterval(
  callback: () => void,
  delayMs: number,
  callImmediately: boolean = false
) {
  const savedCallback = useRef<() => void>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }
    if (delayMs !== null) {
      if (callImmediately) {
        tick()
      }
      const id = setInterval(tick, delayMs)
      return () => clearInterval(id)
    }
  }, [delayMs, callImmediately])
}

export interface IDimensionObject {
  readonly width: number
  readonly height: number
  readonly top: number
  readonly left: number
  readonly x: number
  readonly y: number
  readonly right: number
  readonly bottom: number
}

export type UseDimensionsHook = [
  (node: HTMLElement) => void,
  IDimensionObject | null,
  HTMLElement | null
]

export interface IUseDimensionsProps {
  readonly liveMeasure?: boolean
}

function getDimensionObject(node: HTMLElement): IDimensionObject {
  const rect = node.getBoundingClientRect()

  return {
    width: rect.width,
    height: rect.height,
    top: rect.x ?? rect.top,
    left: rect.y ?? rect.left,
    x: rect.x ?? rect.left,
    y: rect.y ?? rect.top,
    right: rect.right,
    bottom: rect.bottom,
  }
}

// from:
// https://github.com/Swizec/useDimensions/blob/c0d1f429f5bd342ee5668e135739cc10f0f1a2cc/src/index.ts
// License MIT: https://github.com/Swizec/useDimensions/tree/c0d1f429f5bd342ee5668e135739cc10f0f1a2cc#license
/**
 *
 * @example
 *
 * function MyComponent() {
 *   const [ref, dims] = useDimensions()
 *   const height = dims != null ? dims.height : 400
 *   const maxHeight = `calc(100vh - ${height + 50}px)`
 *   return <section>
 *    <Bar ref={ref}/>
 *    <Foo maxHeight={maxHeight} />
 *   </section>
 * }
 */
export function useDimensions({
  liveMeasure = true,
}: IUseDimensionsProps = {}): UseDimensionsHook {
  const [dimensions, setDimensions] = useState<IDimensionObject | null>(null)
  const [node, setNode] = useState<HTMLElement | null>(null)

  const ref = useCallback((node: HTMLElement) => {
    setNode(node)
  }, [])

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        )
      measure()

      if (liveMeasure) {
        window.addEventListener('resize', measure)
        window.addEventListener('scroll', measure)

        return () => {
          window.removeEventListener('resize', measure)
          window.removeEventListener('scroll', measure)
        }
      }
    }
  }, [liveMeasure, node])

  return [ref, dimensions, node]
}

export const useAutoSuggestHandlers = <T>({
  id,
  onChange,
  name,
  onCreate,
  onBlur,
}: Pick<
  IAutoSuggestProps<T>,
  'id' | 'name' | 'onChange' | 'onCreate' | 'onBlur'
>) => {
  const getOptionLabel = useCallback((opt: IAutoSuggestOption<T>) => {
    return opt.label
  }, [])

  const getOptionValue = useCallback((opt: IAutoSuggestOption<T>) => {
    return opt.value
  }, [])

  const handleChange = useCallback(
    (v: ValueType<IAutoSuggestOption<T>>) => {
      if (onChange) {
        let newValue: string | string[] = ''
        if (v) {
          if (isArray(v)) {
            newValue = v.map(x => x.value)
          } else {
            newValue = v.value
          }
        }
        /* eslint-disable @typescript-eslint/consistent-type-assertions */
        onChange({
          target: {
            value: newValue,
            name: name || id || '',
            data: v as ValueType<IAutoSuggestOption<T>>,
          },
        } as ChangeEvent<{ value: string; name: string; data: ValueType<IAutoSuggestOption<T>> }>)
        /* eslint-enable @typescript-eslint/consistent-type-assertions */
      }
    },
    [onChange, name, id]
  )

  const handleCreate = useCallback(
    (v: string) => {
      if (onCreate) {
        onCreate(v)
      }
    },
    [onCreate]
  )

  const handleBlur = useCallback(
    (_e: React.FocusEvent<HTMLElement>) => {
      if (onBlur) {
        onBlur({
          target: {
            name,
          },
        })
      }
    },
    [onBlur, name]
  )

  return {
    getOptionLabel,
    getOptionValue,
    handleChange: onChange && handleChange,
    handleBlur: onBlur && handleBlur,
    handleCreate: onCreate && handleCreate,
  }
}

// Type useDispatch for our actions
export const useDispatch = () => useDispatchRedux<Dispatch>()

// Type useSelector for our root state
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorRedux

/**
 * Treat the default value, empty string, as a loading state
 */
export function useInstitutionId(): string | null {
  const orgSlug = useSelector(s => s.auth.orgSlug)
  if (orgSlug === UNSET_ID) {
    return null
  }
  return orgSlug
}

export function useCurrentInstitution(): ILimitedInstitution {
  return useSelector(s => s.triage.application.profile.currentUser.institution)
}

/**
 * Return ID of current Mascot user. If loading, we return null.
 */
export function useUserId(): string | null {
  const userId = useSelector(s => s.auth.userID)
  if (userId === UNSET_ID) {
    return null
  }
  return userId
}

/**
 * Check if we're authenticated with the API
 */
export function useIsLoggedIn(): boolean {
  return useSelector(state => state.auth.authed)
}

export function useFeatures(): {
  features: readonly FeaturesType[]
  FeaturesType: typeof FeaturesType
  hasFeature: (x: FeaturesType) => boolean
} {
  // we refetch the current institution on every page nav, which isn't ideal.
  // This causes the institution's feature array to charge too. Deep equal
  // prevents rerendering.
  const features = useSelector(getEnabledFeatures, isEqual)
  const hasFeature = useCallback((x: FeaturesType) => features.includes(x), [
    features,
  ])
  return useMemo(
    () => ({
      features,
      FeaturesType,
      hasFeature,
    }),
    [features, hasFeature]
  )
}

interface IUsePoll<T> {
  readonly fetch: (cancelToken: CancelToken) => AxiosPromise<T>
  readonly onRequest: (isInitialFetch: boolean) => void
  readonly onSuccess: (data: AxiosResponse<T>, isInitialFetch: boolean) => void
  readonly onFailure: (data: unknown, isInitialFetch: boolean) => void
  readonly delay?: number
}
/**
 *
 * Make an initial fetch on load and then poll the given async `fetch`
 * function.
 *
 * If `delay` isn't provided, then usePoll only makes an initial fetch request.
 */
export function usePoll<T>({
  fetch,
  onRequest,
  onSuccess,
  onFailure,
  delay,
}: IUsePoll<T>) {
  // based off https://codesandbox.io/s/5yo1o9xm6p
  useEffect(() => {
    let isRunning = false
    let savedTimeout: null | number = null
    const handle = axios.CancelToken.source()

    const tick = async (isPoll: boolean = true) => {
      // When we want to cancel the request we update the `isRunning` flag to
      // false. We check inside our handlers here so we only call onSuccess and
      // onFailure if the item should be running.
      if (!isRunning) {
        return
      }
      try {
        onRequest(isPoll)
        // Note: the fetch function doesn't need to take a CancelToken for
        // cancelation to work. It we don't pass in the token, we just won't
        // cancel the in-flight requests.
        const res = await fetch(handle.token)
        if (!isRunning) {
          return
        }
        onSuccess(res, isPoll)
      } catch (e) {
        if (!isRunning) {
          return
        }
        onFailure(e, isPoll)
      }
      // Only start the next poll if `delay` is defined and we are `isRunning`.
      // When `delay` isn't defined we only make the initial fetch request on
      // mount.
      if (isRunning && delay != null) {
        savedTimeout = setTimeout(tick, delay)
      }
    }

    isRunning = true

    if (delay != null) {
      // Make an initial request on mount which we start polling after.
      tick(/* isPoll = */ false)
    }

    return () => {
      isRunning = false
      handle.cancel()
      if (savedTimeout != null) {
        clearTimeout(savedTimeout)
      }
    }
  }, [delay, fetch, onFailure, onRequest, onSuccess])
}

interface IUseInstitutionNamesData {
  readonly messagingService: string
  readonly displayName: string
}
export function useInstitutionNames() {
  const [state, setState] = useState<
    WebData<ReadonlyArray<IUseInstitutionNamesData>, undefined>
  >(undefined)

  useEffect(() => {
    setState(Loading())
    api
      .getAllInstitutionNames()
      .then(res => {
        setState(Success(res.data))
      })
      .catch(() => {
        setState(Failure(undefined))
      })
  }, [])

  return state
}

export function useAsyncCall<
  ResponseType,
  /* tslint:disable-next-line no-any */
  FunctionType extends (...args: any) => Promise<ResponseType> = (
    /* tslint:disable-next-line no-any */
    ...args: any
  ) => Promise<ResponseType>,
  ErrorType = Error
>(
  func: FunctionType,
  args: Parameters<typeof func>,
  successCallback?: (res: ResponseType) => void
): [boolean, ResponseType | undefined, ErrorType | undefined] {
  const asyncFuncArgs = useRef(args)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorType | undefined>(undefined)
  const [response, setResponse] = useState<ResponseType | undefined>(undefined)
  useEffect(() => {
    setLoading(true)
    func(asyncFuncArgs.current)
      .then((res: ResponseType) => {
        setResponse(res)
        setLoading(false)
      })
      .catch((e: ErrorType) => {
        setError(e)
        setLoading(false)
      })
  }, [setLoading, setError, setResponse, func, asyncFuncArgs, successCallback])

  return [loading, response, error]
}

/**
 *
 * This hook binds a method that will get called when the user presses esc on a
 * given ref element. If no ref is provided, one is created and returned.
 *
 */
export function useEscapeKeyPress(
  onEscape: () => void,
  ref?: React.MutableRefObject<HTMLDivElement>
) {
  const emptyRef = useRef<HTMLDivElement>(null)
  const escRef = ref || emptyRef
  const escHandler = useCallback(
    (e: KeyboardEvent) => {
      if (ESCAPE_STRINGS.includes(e.key)) {
        onEscape()
      }
    },
    [onEscape]
  )

  useEffect(() => {
    // Caching ref.current into a const is needed to ensure the cleanup method
    // below is applied to the correct element, as ref.current can change.
    const current = escRef && escRef.current
    if (current) {
      current.addEventListener('keydown', escHandler, false)
    }

    return () => {
      if (current) {
        current.removeEventListener('keydown', escHandler, false)
      }
    }
  }, [escHandler, escRef])

  return escRef
}

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth

export function useCurrentWidth() {
  const [width, setWidth] = useState(getWidth())

  useEffect(() => {
    const resizeListener = () => {
      setWidth(getWidth())
    }
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return width
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export interface IUseBlockHistoryNavigationReturn {
  /**
   * `react-router` history.
   */
  history: History
  /**
   * Whether browser and router navigation is blocked.
   */
  isBlocked: boolean
  /**
   * Set whether or not browser and router navigation should be blocked.
   */
  setIsBlocked: (next: boolean) => void
}

/**
 * Custom react hook that conditionally blocked browser and `react-router` navigation.
 *
 * @param {() => void} onBlockedNavigationAttempt - callback to call when blocked navigation is
 *                                                  attempted.
 * @param {boolean} [initialIsBlocked=true] - should browser and router navigation start blocked.
 *
 * @returns {IUseBlockHistoryNavigationReturn}
 */
export const useBlockHistoryNavigation = (
  onBlockedNavigationAttempt: () => void,
  initialIsBlocked: boolean = true
): IUseBlockHistoryNavigationReturn => {
  // STATE

  const [isBlocked, setIsBlocked] = useState(initialIsBlocked)

  // HOOKS

  const history = useHistory()

  // if navigation is attempted while blocked, run the `onBlockedNavigationAttempt` callback.
  // if blocked state is cleared, unblock navigation.
  useEffect(() => {
    const unblock = history.block(() => {
      if (isBlocked) {
        onBlockedNavigationAttempt()
        return false
      } else {
        unblock()
      }
    })

    // cleanup
    return () => unblock()
  }, [onBlockedNavigationAttempt, history, isBlocked])

  return { history, isBlocked, setIsBlocked }
}

/**
 * Takes a key and allows to set the value or retrieve it from local storage.
 * https://usehooks.com/useLocalStorage/
 */

export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
      return (item ? JSON.parse(item) : initialValue) as T
    } catch (error) {
      // If error also return initialValue
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      // tslint:disable-next-line:no-console
      console.log(error)
    }
  }
  return [storedValue, setValue] as const
}

export function useIsRefVisible(
  targetRef: React.MutableRefObject<HTMLDivElement | null>
) {
  // From https://dev.to/fpaghar/how-to-check-if-an-element-is-visible-in-the-viewport-using-javascript-and-react-hook-4648
  const [isVisible, setIsVisible] = useState(false)
  useEffect(() => {
    const curr = targetRef.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    )

    if (curr) {
      observer.observe(curr)
    }

    return () => {
      if (curr) {
        observer.unobserve(curr)
      }
    }
  }, [targetRef])

  return isVisible
}
