import * as React from 'react'
import ClassNames from 'classnames'
import 'components/Spinner/Spinner.scss'

export interface ISpinnerProps {
  size?: 'sm' | 'md' | 'lg'
  heightInPixels?: number
  className?: string
}

/**
 * nh:
 * If your spinner isn't showing up trying setting its stroke by giving it a utility class like stroke-mainstay-dark-blue
 */
export class Spinner extends React.PureComponent<ISpinnerProps> {
  static defaultProps = {
    size: 'md',
    heightInPixels: 80,
  }
  render() {
    const className = ClassNames(
      'spinner',
      this.props.size,
      this.props.className
    )
    return (
      <svg
        width="80px"
        className={className}
        height={`${this.props.heightInPixels}px`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid">
        <circle
          cx="50"
          cy="50"
          fill="none"
          strokeWidth="15"
          r="35"
          strokeDasharray="164.93361431346415 56.97787143782138"
          transform="rotate(30 50 50)">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    )
  }
}
