import DraftEditor from 'components/DraftEditor/DraftEditor'

interface IEditableFallbackMessageProps {
  content: string
  onChange: (value: string) => void
  error?: string
}

export const EditableFallbackMessage = ({
  content,
  onChange,
  error,
}: IEditableFallbackMessageProps) => {
  return (
    <>
      <p className="ml-1 pt-2 text-mainstay-dark-blue-65 fs-14 m-0 w-100">
        Contacts without this profile information receive this text instead:
      </p>
      <DraftEditor
        autoFocus={true}
        className="shadow-border mb-2"
        onFocusClassName="shadow-top-dark-spread"
        error={error}
        initialAnswer={content}
        onChange={onChange}
        textBoxLabel=""
        toggleResetDraftEditor={false}
        disableInstitutionAttributes={false}
        disableContactAttributes={true}
        hasContactAttributes={false}
        includeSensitiveContactAttributes={false}
      />
    </>
  )
}
