const DASHBOARD_REGEX = /^\/dashboard\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/

/* 
URLS with these path patterns are available for non-application users.
Taken from backend file: backend/web/permissions.py
*/
const ALLOWED_GUEST_URLS = [DASHBOARD_REGEX]

export const pathAllowsGuests = (path: string) =>
  ALLOWED_GUEST_URLS.some(allowedRegex => path.match(allowedRegex))

export const getCampaignRequestPath = ({
  isRecurring,
  isDataTriggered,
  isHelloPage,
  isWebBot,
}: {
  isRecurring?: boolean
  isDataTriggered?: boolean
  isHelloPage?: boolean
  isWebBot?: boolean
}) => {
  if (isRecurring) {
    return 'recurring-campaigns'
  }
  if (isDataTriggered) {
    return 'data-triggered-campaigns'
  }
  if (isHelloPage || isWebBot) {
    return 'intro-campaign-variants'
  }
  return 'campaigns'
}

export const getCampaignIdParams = ({
  isRecurring,
  isDataTriggered,
  isHelloPage,
  isWebBot,
  id,
}: {
  isRecurring: boolean
  isDataTriggered?: boolean
  isHelloPage?: boolean
  isWebBot?: boolean
  id?: string
}) => {
  if (isRecurring) {
    return { recurringCampaignId: id }
  }
  if (isDataTriggered) {
    return { campaignTriggerId: id }
  }
  if (isHelloPage) {
    return { helloPageId: id }
  }
  if (isWebBot) {
    return { webBotId: id }
  }
  return { campaignId: id }
}
