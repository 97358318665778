import { getType } from 'typesafe-actions'
import {
  createScheduledMessage,
  fetchGlobalAppropriateTimeSettings,
  fetchScheduledMessage,
  fetchCampaignTrigger,
  ICampaignSchedulerActions,
  resetFormData,
  updateFormData,
  updateHasBeenSubmitted,
  createCampaignTrigger,
  updateScheduledMessage,
  updateCampaignTrigger,
  updateAdditionalContactFilters,
  updateSelectedContactFilter,
  updateTouchedFormFields,
  updateValidationErrors,
} from 'store/campaign-scheduler/actions'
import { secondsToDHMS } from 'util/timeConversion'
import { IContactFilterFormData } from 'components/ContactFilterBuilder/formUtils'
import { ICampaignData } from '/api/request'
import { ICampaignSendWindow } from 'api/response'
import { RepeatInterval } from 'util/dateRepeatIntervals'

export enum StudentSelectionType {
  UploadCSV = 'upload',
  ImportLabel = 'import',
  CustomImportLabel = 'custom_import',
  ContactSegment = 'contact_segment',
  NewContactSegment = 'new_contact_segment',
  MultiAudience = 'multi_audience',
  None = 'none',
}

interface ICampaignSchedulerState {
  scheduledMessage: {
    loading: boolean
  }
  importLabels: null | string[]
  uploadingScheduledMessage: boolean
  form: ICampaignSchedulerFormData
  errors: Partial<ICampaignSchedulerValidationData>
  touched: Partial<ICampaignSchedulerTouchedFields>
  hasSubmitted: boolean
  selectedContactFilter: IContactFilterFormData
  globalAppropriateHourSettings: ICampaignSendWindow
}

export enum TriggerType {
  Immediate = 'immediate',
  SpecificDate = 'specific_date',
  Recurring = 'recurring',
  DataTriggered = 'data_triggered',
  None = 'none',
}

export const MINIMUM_DTC_MINUTES_DELAY: number = 30

export const initialState: ICampaignSchedulerState = {
  scheduledMessage: {
    loading: false,
  },
  importLabels: null,
  form: {
    name: '',
    description: '',
    dialogId: '',
    studentSelectionType: StudentSelectionType.None,
    triggerType: TriggerType.None,
    importLabels: [],
    contactLabels: [],
    importAutoLabel: '',
    selectedAutoLabels: [],
    multiContactFilterDelay: 15,
    multiContactFilters: [],
    recurrenceSettings: {
      endDate: undefined,
      startDate: undefined,
      interval: RepeatInterval.daily,
      infinite: false,
    },
    dataTriggeredSettings: {
      timeDelay: {
        days: 0,
        hours: 0,
        minutes: MINIMUM_DTC_MINUTES_DELAY,
      },
      enabled: true,
      sendCampaignOnce: false,
    },
  },
  uploadingScheduledMessage: false,
  errors: {},
  touched: {},
  hasSubmitted: false,
  selectedContactFilter: { name: '', id: undefined, rows: [{ level: 0 }] },
  globalAppropriateHourSettings: {
    hoursStart: null,
    hoursEnd: null,
    minutesStart: null,
    minutesEnd: null,
    timezone: 'America/New_York',
  },
}

export enum ChangedOccurrenceTypeEnum {
  deleted = 'deleted',
}

export interface IChangedOccurrence {
  date: Date | string
  type: ChangedOccurrenceTypeEnum
}

export interface IRecurrenceSettings {
  changedOccurrences?: IChangedOccurrence[]
  startDate?: string
  endDate?: string
  infinite?: boolean | null
  interval?: RepeatInterval
  sendCampaignOnce?: boolean
}

export interface IDataTriggeredSettings {
  timeDelay?: {
    days?: number
    hours?: number
    minutes?: number
  }
  sendCampaignOnce?: boolean
  enabled?: boolean
}

export interface ICampaignSchedulerFormData extends ICampaignData {
  contactFilter?: number
  multiContactFilters: number[]
  multiContactFilterDelay: number
  studentSelectionType: StudentSelectionType
  triggerType: TriggerType
  importLabels: string[]
  importAutoLabel: string
  selectedAutoLabels: string[]
  dataTriggeredSettings: IDataTriggeredSettings
}

type ConditionalKeys<Base, Condition> = {
  [Key in keyof Base]: Key extends Condition ? Base[Key] : never
}

type IndexSignature = (string | number) | undefined

type KeyedCollection<T, TV> = {
  [Key in keyof ConditionalKeys<T, IndexSignature>]: TV
}

export interface ICampaignSchedulerTouchedFields
  extends KeyedCollection<ICampaignSchedulerFormData, boolean> {}

export interface ICampaignSchedulerValidationData
  extends KeyedCollection<ICampaignSchedulerFormData, string[]> {}

const reducer = (
  state: ICampaignSchedulerState = initialState,
  action: ICampaignSchedulerActions
): ICampaignSchedulerState => {
  switch (action.type) {
    case getType(updateFormData): {
      return { ...state, form: { ...state.form, ...action.payload } }
    }
    case getType(updateTouchedFormFields): {
      return { ...state, touched: { ...state.touched, ...action.payload } }
    }
    case getType(updateValidationErrors): {
      return { ...state, errors: { ...action.payload } }
    }
    case getType(createScheduledMessage.request): {
      return {
        ...state,
        uploadingScheduledMessage: true,
      }
    }
    case getType(createScheduledMessage.success): {
      return {
        ...state,
        form: initialState.form,
        uploadingScheduledMessage: false,
      }
    }
    case getType(createScheduledMessage.failure): {
      return {
        ...state,
        uploadingScheduledMessage: false,
      }
    }
    case getType(updateHasBeenSubmitted): {
      return {
        ...state,
        hasSubmitted: action.payload,
      }
    }
    case getType(updateScheduledMessage.failure):
    case getType(updateCampaignTrigger.failure):
    case getType(fetchScheduledMessage.failure): {
      return {
        ...state,
        scheduledMessage: {
          ...state.scheduledMessage,
          loading: false,
        },
      }
    }
    case getType(fetchCampaignTrigger.failure): {
      return {
        ...state,
        scheduledMessage: {
          ...state.scheduledMessage,
          loading: false,
        },
      }
    }
    case getType(updateScheduledMessage.request):
    case getType(updateCampaignTrigger.request):
    case getType(fetchScheduledMessage.request): {
      return {
        ...state,
        scheduledMessage: {
          ...state.scheduledMessage,
          loading: true,
        },
      }
    }
    case getType(createCampaignTrigger.request): {
      return {
        ...state,
        uploadingScheduledMessage: true,
      }
    }
    case getType(createCampaignTrigger.success): {
      return {
        ...state,
        form: initialState.form,
        uploadingScheduledMessage: false,
      }
    }
    case getType(createCampaignTrigger.failure): {
      return {
        ...state,
        uploadingScheduledMessage: false,
      }
    }
    case getType(fetchCampaignTrigger.request): {
      return {
        ...state,
        scheduledMessage: {
          ...state.scheduledMessage,
          loading: true,
        },
      }
    }
    case getType(updateScheduledMessage.success): {
      return {
        ...state,
        scheduledMessage: {
          ...state.scheduledMessage,
          loading: false,
        },
      }
    }
    case getType(resetFormData): {
      return {
        ...state,
        form: {
          ...initialState.form,
        },
        touched: {},
        errors: {},
      }
    }
    case getType(updateCampaignTrigger.success): {
      return {
        ...state,
        scheduledMessage: {
          ...state.scheduledMessage,
          loading: false,
        },
      }
    }
    case getType(fetchScheduledMessage.success): {
      let selectionType = StudentSelectionType.None
      if (action.payload.contactLabels.length > 0) {
        selectionType = StudentSelectionType.CustomImportLabel
      }
      if (action.payload.importLabels.length > 0) {
        selectionType = StudentSelectionType.ImportLabel
      }
      if (action.payload.contactFilter) {
        selectionType = StudentSelectionType.ContactSegment
      }
      let newState = {
        ...state,
        scheduledMessage: {
          loading: false,
        },
        form: {
          ...state.form,
          name: action.payload.name,
          description: action.payload.description,
          dialogId: action.payload.dialogId,
          importLabels: action.payload.importLabels,
          selectedAutoLabels: action.payload.importLabels,
          contactLabels: action.payload.contactLabels,
          contactFilter: action.payload.contactFilter,
          studentSelectionType: selectionType,
          triggerType: action.payload.recurrenceSettings.interval
            ? TriggerType.Recurring
            : TriggerType.SpecificDate,
          date: action.payload.scheduledAt,
          importAutoLabel: action.payload.importAutoLabel || '',
          recurrenceSettings: action.payload.recurrenceSettings,
        },
      }
      if (action.payload.contactFilter && action.payload.filterName) {
        newState = {
          ...newState,
          selectedContactFilter: {
            id: action.payload.contactFilter,
            rows: [{ level: 0 }],
            name: action.payload.filterName,
          },
        }
      }
      return newState
    }
    case getType(fetchCampaignTrigger.success): {
      const trigger = action.payload.trigger
      let selectionType = StudentSelectionType.None
      if (trigger.contactFilterId) {
        selectionType = StudentSelectionType.ContactSegment
      }

      const calculatedTimeDelay = secondsToDHMS(trigger.timeDelay)

      let newState = {
        ...state,
        scheduledMessage: {
          loading: false,
        },
        form: {
          ...state.form,
          name: trigger.name,
          description: trigger.description,
          dialogId: trigger.dialogId,
          contactFilter: trigger.contactFilterId,
          studentSelectionType: selectionType,
          triggerType: TriggerType.DataTriggered,
          dataTriggeredSettings: {
            sendCampaignOnce: trigger.sendCampaignOnce,
            timeDelay: calculatedTimeDelay,
            enabled: trigger.enabled,
          },
        },
      }
      if (trigger.contactFilterId && trigger.filterName) {
        newState = {
          ...newState,
          selectedContactFilter: {
            id: trigger.contactFilterId,
            rows: [{ level: 0 }],
            name: trigger.filterName,
          },
        }
      }
      return newState
    }
    case getType(updateSelectedContactFilter): {
      return {
        ...state,
        selectedContactFilter: action.payload,
      }
    }
    case getType(updateAdditionalContactFilters): {
      return {
        ...state,
        form: {
          ...state.form,
          multiContactFilters: action.payload,
        },
      }
    }
    case getType(fetchGlobalAppropriateTimeSettings.request): {
      return {
        ...state,
      }
    }
    case getType(fetchGlobalAppropriateTimeSettings.success): {
      return {
        ...state,
        globalAppropriateHourSettings: action.payload,
      }
    }
    case getType(fetchGlobalAppropriateTimeSettings.failure): {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}

export default reducer
