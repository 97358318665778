import {
  IUserLocationActions,
  fetchUserByLocation,
  updateUserLocation,
} from 'store/triage/userLocation/actions'
import { IViewerCollectionData } from 'page/triage/ViewerCollection/ViewerCollection'
import { getType } from 'typesafe-actions'

const initialState = {
  byLocation: {},
  loading: {},
  updating: false,
}

interface IUserLocationState {
  byLocation: {
    [key: string]: IViewerCollectionData[]
  }
  loading: {
    [key: string]: boolean
  }
  updating: boolean
}

const reducer = (
  state: IUserLocationState = initialState,
  action: IUserLocationActions
): IUserLocationState => {
  switch (action.type) {
    case getType(fetchUserByLocation.request):
      return {
        ...state,
        loading: {
          [action.payload.path]: true,
        },
      }
    case getType(fetchUserByLocation.failure):
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.path]: false,
        },
      }
    case getType(fetchUserByLocation.success):
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.path]: false,
        },
        byLocation: {
          ...state.byLocation,
          [action.payload.path]: action.payload.data,
        },
      }
    case getType(updateUserLocation.request):
      return {
        ...state,
        updating: true,
      }
    case getType(updateUserLocation.success):
    case getType(updateUserLocation.failure):
      return {
        ...state,
        updating: false,
      }
    default:
      return state
  }
}

export default reducer
