import { BotStatus } from 'store/institution/selectors'
import scssVariables from 'scss/_variables.scss'

export function Circle({
  className,
  fill = '#FAFBFB',
  radius = 3,
}: {
  readonly className?: string
  readonly fill?: string
  readonly radius?: number
}) {
  return (
    <svg
      className={className}
      width={radius * 2}
      height={radius * 2}
      xmlns="http://www.w3.org/2000/svg">
      <circle cx={radius} cy={radius} r={radius} fill={fill} />
    </svg>
  )
}

export const BotStatusCircle = ({
  botStatus,
}: {
  botStatus: BotStatus
}): null | JSX.Element => {
  if (botStatus === BotStatus.internal) {
    return null
  }
  const colorMapper = {
    [BotStatus.active]: scssVariables.mainstaySuccess500,
    [BotStatus.retired]: scssVariables.newUIDanger,
  }

  return <Circle radius={4} className="mr-2" fill={colorMapper[botStatus]} />
}
