import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faLock,
  faThumbsDown,
  faThumbsUp,
  faTrash,
  faUndo,
  faWindowClose,
  faLightbulb,
  faPlus,
  faPlusCircle,
  faMobile,
  faStop,
  faProjectDiagram,
  faArrowRight,
  faCommentAlt,
  faExclamationTriangle,
  faFlag,
  faCheck,
  faBan,
  faComments,
  faBookmark,
  faCircle,
  faLink,
  faInfoCircle,
  faArrowCircleRight,
  faUser,
  faUsers,
  faGraduationCap,
  faPencilAlt,
  faCrosshairs,
  faBook,
  faReply,
  faTimesCircle,
  faInbox,
  faQuestionCircle,
  faStopwatch,
  faSave,
  faEnvelope,
  faPause,
  faCalendarPlus,
  faExclamationCircle,
  faPaperPlane,
  faChartLine,
  faArrowAltCircleRight,
  faSync,
  faChevronCircleDown,
  faClipboard,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'

/*
  Here we build a library of SVG icons that can be used elsewhere in the application.
  Must be provided with all icons used throughout the app and called before icons can be used.
*/
export const initFontLibrary = () => {
  library.add(
    faLock,
    faThumbsDown,
    faThumbsUp,
    faTrash,
    faUndo,
    faWindowClose,
    faLightbulb,
    faPlus,
    faArrowRight,
    faCommentAlt,
    faPlusCircle,
    faMobile,
    faStop,
    faProjectDiagram,
    faExclamationTriangle,
    faPencilAlt,
    faFlag,
    faLink,
    faCheck,
    faBan,
    faCircle,
    faComments,
    faBookmark,
    faInfoCircle,
    faArrowCircleRight,
    faUser,
    faUsers,
    faGraduationCap,
    faPencilAlt,
    faCrosshairs,
    faBook,
    faReply,
    faTimesCircle,
    faInbox,
    faQuestionCircle,
    faStopwatch,
    faSave,
    faEnvelope,
    faPause,
    faCalendarPlus,
    faExclamationCircle,
    faPaperPlane,
    faChartLine,
    faArrowAltCircleRight,
    faSync,
    faChevronCircleDown,
    faClipboard,
    faEye,
    faEyeSlash
  )
}
