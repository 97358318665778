export function ErrorIcon({
  fill = '#F4456D',
  className,
}: {
  readonly fill?: string
  readonly className?: string
}) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.984 10.9841V4.98411H9.01499V10.9841H10.984ZM10.984 15.0161V13.0001H9.01499V15.0161H10.984ZM9.99999 0.0161133C15.531 0.0161133 19.984 4.46911 19.984 10.0001C19.984 15.5311 15.531 19.9841 9.99999 19.9841C4.46899 19.9841 0.0159912 15.5311 0.0159912 10.0001C0.0159912 4.46911 4.46899 0.0161133 9.99999 0.0161133Z"
        fill={fill}
      />
    </svg>
  )
}
