// from: https://commons.wikimedia.org/wiki/File:Slack_icon_2019.svg
export function SlackIconColor({
  height = 20,
  className,
}: {
  readonly height?: number
  readonly className?: string
}) {
  return (
    <svg
      width={height}
      height={height}
      className={className}
      viewBox="0 0 127 127"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.2 80c0 7.3-5.9 13.2-13.2 13.2C6.7 93.2.8 87.3.8 80c0-7.3 5.9-13.2 13.2-13.2h13.2V80zm6.6 0c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2v33c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V80z"
        fill="#E01E5A"
      />
      <path
        d="M47 27c-7.3 0-13.2-5.9-13.2-13.2C33.8 6.5 39.7.6 47 .6c7.3 0 13.2 5.9 13.2 13.2V27H47zm0 6.7c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H13.9C6.6 60.1.7 54.2.7 46.9c0-7.3 5.9-13.2 13.2-13.2H47z"
        fill="#36C5F0"
      />
      <path
        d="M99.9 46.9c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H99.9V46.9zm-6.6 0c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V13.8C66.9 6.5 72.8.6 80.1.6c7.3 0 13.2 5.9 13.2 13.2v33.1z"
        fill="#2EB67D"
      />
      <path
        d="M80.1 99.8c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V99.8h13.2zm0-6.6c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2h33.1c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H80.1z"
        fill="#ECB22E"
      />
    </svg>
  )
}

export function SlackIcon({
  height = '22',
  width = '22',
  className,
  fill = '#95A7B0',
}: {
  readonly height?: string
  readonly width?: string
  readonly className?: string
  readonly fill?: string
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 22 22"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70721 1C7.54521 1.00086 6.60478 1.94866 6.60564 3.11657C6.60478 4.28448 7.54606 5.23227 8.70807 5.23314H10.8105V3.11743C10.8114 1.94952 9.87008 1.00173 8.70721 1C8.70807 1 8.70807 1 8.70721 1ZM8.70721 6.64533H3.10244C1.94043 6.6462 0.999144 7.59399 1 8.7619C0.998285 9.92981 1.93957 10.8776 3.10158 10.8793H8.70721C9.86922 10.8785 10.8105 9.93068 10.8096 8.76276C10.8105 7.59399 9.86922 6.6462 8.70721 6.64533Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0209 8.7619C22.0218 7.59399 21.0805 6.6462 19.9185 6.64533C18.7565 6.6462 17.8152 7.59399 17.8161 8.7619V10.8793H19.9185C21.0805 10.8785 22.0218 9.93068 22.0209 8.7619ZM16.4153 8.7619V3.11657C16.4162 1.94952 15.4757 1.00173 14.3137 1C13.1517 1.00086 12.2104 1.94866 12.2113 3.11657V8.7619C12.2096 9.92981 13.1509 10.8776 14.3129 10.8793C15.4749 10.8785 16.4162 9.93068 16.4153 8.7619Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3129 22.1701C15.4749 22.1692 16.4162 21.2214 16.4153 20.0535C16.4162 18.8856 15.4749 17.9378 14.3129 17.9369H12.2104V20.0535C12.2096 21.2205 13.1509 22.1683 14.3129 22.1701ZM14.3129 16.5239H19.9185C21.0805 16.523 22.0218 15.5752 22.0209 14.4073C22.0227 13.2394 21.0814 12.2916 19.9194 12.2899H14.3137C13.1517 12.2907 12.2104 13.2385 12.2113 14.4064C12.2104 15.5752 13.1509 16.523 14.3129 16.5239Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.00003 14.4073C0.999172 15.5752 1.94046 16.523 3.10247 16.5238C4.26447 16.523 5.20576 15.5752 5.2049 14.4073V12.2907H3.10247C1.94046 12.2916 0.999172 13.2394 1.00003 14.4073ZM6.60567 14.4073V20.0526C6.60395 21.2205 7.54523 22.1683 8.70724 22.17C9.86925 22.1692 10.8105 21.2214 10.8097 20.0535V14.409C10.8114 13.2411 9.87011 12.2933 8.7081 12.2916C7.54523 12.2916 6.60481 13.2394 6.60567 14.4073Z"
      />
    </svg>
  )
}
