import { ButtonGroup } from 'reactstrap'
import { ActionButton } from 'components/ActionButton/ActionButton'
import { IDropdownItem } from 'components/Dropdown/Dropdown'

export function DropdownActionPopoverMenu({
  onSelect,
  items,
}: {
  onSelect: ({ label, value }: IDropdownItem) => void
  items: IDropdownItem[]
}) {
  return (
    <ButtonGroup className="rounded shadow min-width-200px" vertical>
      {items?.map(item => (
        <ActionButton
          className="react-select__option"
          key={item.value}
          onClick={() => onSelect(item)}>
          {item.label}
        </ActionButton>
      ))}
    </ButtonGroup>
  )
}
