import * as nope from 'yup'
import {
  InstitutionAttributeType,
  IInstitutionAttributes,
  IInstitutionAttribute,
} from 'store/personalization/institutionAttributes/selectors'
import {
  ContactAttributeType,
  IContactAttribute,
  IContactField,
} from 'store/personalization/contactAttributes/selectors'

// NOTE(neckenth) - We need to extend the TestContext type in order to access `from`
// `from` is available in the TestContext but is missing from the type.
// https://github.com/jquense/yup/issues/1631
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/49512
interface ITestContextExtended extends nope.TestContext {
  from: { schema: nope.ObjectSchema; value: IInstitutionAttribute }[]
}

// NOTE(rcantos) Updating front-end regex validation to match back-end regex pulled from
// https://github.com/AdmitHub/marshall/blob/b2b9765cbfd69be40b6982936acae0c9b8e7b3c8/backend/web/utils/attribute_types.py#L180
export const VALID_URL_REGEX = /^(?:http(s)?:\/\/)?[\w\.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#%[\]@!\$&'\(\)\*\+,;=.]+$/

const valueErrorMsgMapping = {
  [InstitutionAttributeType.TEXT]:
    'Value cannot be blank. Enter a value for this attribute',
  [InstitutionAttributeType.NUMBER]: 'Must enter a valid number',
  [InstitutionAttributeType.DATE]: 'Must enter a valid date',
  [InstitutionAttributeType.EMAIL]: 'Must enter a valid email',
  [InstitutionAttributeType.PHONE]:
    'Phone number must contain exactly 10 digits, with no dashes or special characters.\nOnly US numbers are supported at this time.\nExample: 5552551234',
  [InstitutionAttributeType.URL]:
    'Must enter a valid URL, including a protocol (ex: https://) and domain (ex: example.com)',
}
export const InstitutionAttributeFormValidationSchema = (
  institutionAttributes: IInstitutionAttributes
) => {
  return nope.object().shape({
    name: nope
      .string()
      .required('Name cannot be blank. Enter a name for this attribute')
      .min(1, 'Name cannot be blank. Enter a name for this attribute')
      .when('id', (id: number, schema: nope.Schema<string>) => {
        const lowerCaseNames = institutionAttributes
          .filter(attr => attr.id !== id)
          .map(attr => attr.field.toLowerCase())
        return schema.test(
          'is-dupe',
          'This field name is already in use',
          (value: string) => !lowerCaseNames.includes(value.toLowerCase())
        )
      }),
    type: nope
      .mixed<keyof typeof InstitutionAttributeType>()
      .oneOf(Object.values(InstitutionAttributeType))
      .required('Must select an attribute type'),
    value: nope
      .mixed()
      .required(valueErrorMsgMapping[InstitutionAttributeType.TEXT])
      .when('type', (type: InstitutionAttributeType) => {
        switch (type) {
          case InstitutionAttributeType.DATE:
            return nope
              .date()
              .required(valueErrorMsgMapping[InstitutionAttributeType.DATE])
          case InstitutionAttributeType.EMAIL:
            return nope
              .string()
              .email(valueErrorMsgMapping[InstitutionAttributeType.EMAIL])
              .required(valueErrorMsgMapping[InstitutionAttributeType.EMAIL])
          case InstitutionAttributeType.NUMBER:
            return nope
              .number()
              .required(valueErrorMsgMapping[InstitutionAttributeType.NUMBER])
          case InstitutionAttributeType.PHONE:
            return nope
              .string()
              .matches(
                /^[2-9][0-9]{9}$/,
                valueErrorMsgMapping[InstitutionAttributeType.PHONE]
              )
          case InstitutionAttributeType.TEXT:
            return nope
              .string()
              .required(valueErrorMsgMapping[InstitutionAttributeType.TEXT])
              .min(1, valueErrorMsgMapping[InstitutionAttributeType.TEXT])
          case InstitutionAttributeType.URL:
            return nope
              .string()
              .url(valueErrorMsgMapping[InstitutionAttributeType.URL])
              .required(valueErrorMsgMapping[InstitutionAttributeType.URL])
        }
      }),
    audienceSpecificValues: nope.array().of(
      nope.object().shape({
        value: nope
          .mixed()
          .test(
            'isValidDateIfDate',
            valueErrorMsgMapping[InstitutionAttributeType.DATE],
            function(value) {
              /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
              const { from } = this as ITestContextExtended
              return from[1].value.type === InstitutionAttributeType.DATE
                ? nope
                    .date()
                    .required()
                    .isValid(value)
                : true
            }
          )
          .test(
            'isValidEmailIfEmail',
            valueErrorMsgMapping[InstitutionAttributeType.EMAIL],
            function(value) {
              /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
              const { from } = this as ITestContextExtended
              return from[1].value.type === InstitutionAttributeType.EMAIL
                ? nope
                    .string()
                    .email()
                    .required()
                    .isValid(value)
                : true
            }
          )
          .test(
            'isValidNumberIfNumber',
            valueErrorMsgMapping[InstitutionAttributeType.NUMBER],
            function(value) {
              /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
              const { from } = this as ITestContextExtended
              return from[1].value.type === InstitutionAttributeType.NUMBER
                ? nope
                    .number()
                    .required()
                    .isValid(value)
                : true
            }
          )
          .test(
            'isValidPhoneIfPhone',
            valueErrorMsgMapping[InstitutionAttributeType.PHONE],
            function(value) {
              /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
              const { from } = this as ITestContextExtended
              return from[1].value.type === InstitutionAttributeType.PHONE
                ? nope
                    .string()
                    .required()
                    .matches(/^[2-9][0-9]{9}$/)
                    .isValid(value)
                : true
            }
          )
          .test(
            'isValidTextIfText',
            valueErrorMsgMapping[InstitutionAttributeType.TEXT],
            function(value) {
              /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
              const { from } = this as ITestContextExtended
              return from[1].value.type === InstitutionAttributeType.TEXT
                ? nope
                    .string()
                    .required()
                    .min(1)
                    .isValid(value)
                : true
            }
          )
          .test(
            'isValidURLIfURL',
            valueErrorMsgMapping[InstitutionAttributeType.URL],
            function(value) {
              /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
              const { from } = this as ITestContextExtended
              return from[1].value.type === InstitutionAttributeType.URL
                ? nope
                    .string()
                    .url()
                    .required()
                    .isValid(value)
                : true
            }
          ),
        contactFilterId: nope.number().required('Must select an Audience'),
        contactFilterName: nope.string().required('Must select an Audience'),
      })
    ),
  })
}

const ContactAttributeOptionValidationSchema = nope.object().shape({
  value: nope
    .string()
    .required('Option cannot be blank')
    .min(1, 'Option cannot be blank'),
})

export const attributeNameMax = 30
const attributeDescriptionMax = 300

export const ContactAttributeFormValidationSchema = (
  contactAttributes: Pick<IContactAttribute, 'id' | 'field'>[],
  allTopLevelFields: IContactField[]
) => {
  return nope.object().shape({
    id: nope.number().notRequired(),
    name: nope
      .string()
      .required('Field Name cannot be blank')
      .min(1, 'Field Name cannot be blank')
      .max(attributeNameMax, 'Field Names are limited to 30 characters')
      .test(
        'is-toplevel-dupe',
        'A default field with this name already exists',
        (value: string) => {
          const lowerCaseNames = allTopLevelFields.map(attr =>
            attr.field.toLowerCase()
          )
          return !lowerCaseNames.includes(value.toLowerCase())
        }
      )
      .test(
        'contains-quotes',
        'Field names cannot contain quotation marks',
        (value: string) => {
          return !value.includes('"')
        }
      )
      .when('id', (id: number, schema: nope.Schema<string>) => {
        const lowerCaseNames = contactAttributes
          .filter(attr => attr.id !== id)
          .map(attr => attr.field.toLowerCase())
        return schema.test(
          'is-dupe',
          'This field name is already in use',
          (value: string) => !lowerCaseNames.includes(value.toLowerCase())
        )
      }),
    description: nope
      .string()
      .nullable()
      .max(
        attributeDescriptionMax,
        'Field Descriptions are limited to 300 characters'
      ),
    type: nope
      .mixed<keyof typeof ContactAttributeType>()
      .oneOf(Object.values(ContactAttributeType))
      .required('Must select a type'),
    options: nope.array().when('type', {
      is: ContactAttributeType.MULTI_CHOICE,
      then: nope
        .array()
        .of(ContactAttributeOptionValidationSchema)
        .required('Must have at least one option'),
    }),
  })
}

export const ContactAttributeInputValidationSchema = (allStrings?: boolean) =>
  nope
    .mixed()
    .when('attributeType', (type: ContactAttributeType) => {
      const stringMods = (field: nope.StringSchema) =>
        field
          .min(1, 'Value cannot be blank. Enter a value for this attribute')
          .matches(/^(\s*\S+\s*)*$/, 'Value cannot be all spaces')

      switch (type) {
        case ContactAttributeType.DATE:
          return allStrings
            ? stringMods(nope.string().required('Must enter a valid date'))
            : nope.date().required('Must enter a valid date')
        case ContactAttributeType.EMAIL:
          return stringMods(nope.string().email('Must enter a valid email'))
        case ContactAttributeType.NUMBER:
          return stringMods(
            nope
              .string()
              .matches(/^\d*\.?\d+$/, 'Must enter a valid number')
              .required('Must enter a valid number')
          )
        case ContactAttributeType.PHONE:
          return stringMods(
            nope
              .string()
              .matches(
                /^[2-9][0-9]{9}$/,
                'Phone number must contain exactly 10 digits, with no dashes or special characters.\nOnly US numbers are supported at this time.\nExample: 5552551234'
              )
          )
        case ContactAttributeType.TEXT:
          return stringMods(
            nope
              .string()
              .required(
                'Value cannot be blank. Enter a value for this attribute'
              )
          )
        case ContactAttributeType.URL:
          return stringMods(
            nope
              .string()
              .url(
                'Must enter a valid URL, including a protocol (ex: https://) and domain (ex: example.com)'
              )
          )
        case ContactAttributeType.BOOLEAN:
          return allStrings
            ? stringMods(nope.string().required('Must select True or False'))
            : nope.boolean().required('Must select true or false')
      }
    })
    .required('Value cannot be blank. Enter a value for this attribute')

export const ContactAttributePreviewValidationSchema = nope.object().shape({
  type: nope
    .mixed<keyof typeof ContactAttributeType>()
    .oneOf(Object.values(ContactAttributeType)),
  preview: ContactAttributeInputValidationSchema(),
})
