import {
  EventTrackerAttrAdder,
  ITrackingEvent,
} from 'components/EventTracker/EventTracker'
import 'components/Checkbox/Checkbox.scss'
import { AlertType } from 'components/Alert/Alert'
import scssVariables from 'scss/_variables.scss'
import classNames from 'classnames'
import 'components/Icons/AlertIcon/AlertIcon.scss'

const DEFAULT_WIDTH = 16.67
const DEFAULT_HEIGHT = 16.67

function SuccessIcon({
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  className = '',
  color = scssVariables.mainstaySuccess600,
}: {
  readonly width?: number
  readonly height?: number
  readonly className?: string
  readonly color?: string
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3334 9.2333V9.99997C18.3324 11.797 17.7505 13.5455 16.6745 14.9848C15.5986 16.4241 14.0862 17.477 12.3629 17.9866C10.6396 18.4961 8.7978 18.4349 7.11214 17.8121C5.42648 17.1894 3.98729 16.0384 3.00922 14.5309C2.03114 13.0233 1.56657 11.24 1.68481 9.4469C1.80305 7.65377 2.49775 5.94691 3.66531 4.58086C4.83288 3.21482 6.41074 2.26279 8.16357 1.86676C9.91641 1.47073 11.7503 1.65192 13.3918 2.3833M18.3334 3.3333L10.0001 11.675L7.50009 9.17497"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function WarningIcon({
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  className = '',
  color = scssVariables.mainstayWarning600,
}: {
  readonly width?: number
  readonly height?: number
  readonly className?: string
  readonly color?: string
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.9999 6.66667V10M9.9999 13.3333H10.0082M8.5749 2.38334L1.51656 14.1667C1.37104 14.4187 1.29403 14.7044 1.29322 14.9954C1.2924 15.2865 1.3678 15.5726 1.51192 15.8254C1.65603 16.0783 1.86383 16.289 2.11465 16.4366C2.36547 16.5841 2.65056 16.6635 2.94156 16.6667H17.0582C17.3492 16.6635 17.6343 16.5841 17.8851 16.4366C18.136 16.289 18.3438 16.0783 18.4879 15.8254C18.632 15.5726 18.7074 15.2865 18.7066 14.9954C18.7058 14.7044 18.6288 14.4187 18.4832 14.1667L11.4249 2.38334C11.2763 2.13843 11.0672 1.93594 10.8176 1.79541C10.568 1.65488 10.2863 1.58105 9.9999 1.58105C9.71345 1.58105 9.43184 1.65488 9.18223 1.79541C8.93263 1.93594 8.72345 2.13843 8.5749 2.38334Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function DangerIcon({
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  className = '',
  color = scssVariables.mainstayError600,
  fill = 'none',
}: {
  readonly width?: number
  readonly height?: number
  readonly className?: string
  readonly color?: string
  readonly fill?: string
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99993 6.66675V10.0001M9.99993 13.3334H10.0083M18.3333 10.0001C18.3333 14.6025 14.6023 18.3334 9.99993 18.3334C5.39756 18.3334 1.6666 14.6025 1.6666 10.0001C1.6666 5.39771 5.39756 1.66675 9.99993 1.66675C14.6023 1.66675 18.3333 5.39771 18.3333 10.0001Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function InfoIcon({
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  className = '',
  color = scssVariables.mainstayBlue60,
}: {
  readonly width?: number
  readonly height?: number
  readonly className?: string
  readonly color?: string
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0001 13.3333V10M10.0001 6.66666H10.0084M18.3334 10C18.3334 14.6024 14.6025 18.3333 10.0001 18.3333C5.39771 18.3333 1.66675 14.6024 1.66675 10C1.66675 5.39762 5.39771 1.66666 10.0001 1.66666C14.6025 1.66666 18.3334 5.39762 18.3334 10Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const AlertIcon = ({
  type,
  className,
  color,
  height,
  width,
  location,
  action,
  object,
}: {
  type: AlertType
  className?: string
  color?: string
  height?: number
  width?: number
} & ITrackingEvent) => {
  return (
    <EventTrackerAttrAdder
      eventLocation={location}
      eventAction={action}
      eventObject={object}>
      {type === AlertType.Success ? (
        <SuccessIcon
          color={color}
          height={height}
          width={width}
          className={className}
        />
      ) : type === AlertType.Warning ? (
        <WarningIcon
          color={color}
          height={height}
          width={width}
          className={className}
        />
      ) : type === AlertType.Danger ? (
        <DangerIcon
          color={color}
          height={height}
          width={width}
          className={className}
        />
      ) : (
        <InfoIcon
          color={
            color || type === AlertType.Neutral
              ? scssVariables.mainstayGrayHeader
              : scssVariables.mainstayBlue60
          }
          height={height}
          width={width}
          className={className}
        />
      )}
    </EventTrackerAttrAdder>
  )
}

export const ZitDangerIcon = ({ className }: { className: string }) => {
  return (
    <div
      className={classNames(
        'bg-mainstay-error-50 h-2rem w-2rem border-radius-1em py-0 px-0 align-middle',
        className
      )}>
      <DangerIcon
        className="zit-danger"
        fill={scssVariables.mainstayError600}
        color="white"
        height={20}
        width={20}
      />
    </div>
  )
}
