import { ITopicActions, getTopicsPostgres } from 'store/triage/topic/actions'
import { ICounselorsResponseData } from 'api/response'
import { getType } from 'typesafe-actions'
import first from 'lodash/first'

const INITIAL_STATE = {
  byOrg: {},
  loading: false,
}

const mapPostgresTopicsResponseDataToState = (
  data: ICounselorsResponseData
): ReadonlyArray<ITopic> =>
  data.map(elem => ({
    email: elem.email,
    name: elem.reason || '',
    counselor: elem.name,
    collegeId: elem.college_id,
  }))

export interface ITopic {
  name: string
  email: string
  counselor: string
  collegeId?: string
}

interface ITopicState {
  byOrg: {
    [org: string]: ITopic[]
  }
  loading: boolean
}

const reducer = (state = INITIAL_STATE, action: ITopicActions): ITopicState => {
  switch (action.type) {
    case getType(getTopicsPostgres.request): {
      return {
        ...state,
        loading: true,
      }
    }
    case getType(getTopicsPostgres.success): {
      const college = first(action.payload)
      if (college == null) {
        return state
      }
      return {
        ...state,
        byOrg: {
          ...state.byOrg,
          [college.college_id]: mapPostgresTopicsResponseDataToState(
            action.payload
          ),
        },
        loading: false,
      }
    }
    case getType(getTopicsPostgres.failure): {
      return {
        ...state,
        loading: false,
      }
    }
    default:
      return state
  }
}
export default reducer
