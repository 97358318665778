import * as Raven from '@sentry/browser'
import ReactDOM from 'react-dom'
import { MainstayModal } from 'components/Modal/Modal'

export const showNavConfirmation = (
  message: string,
  callback: (answer: boolean) => void
) => {
  const modal = document.createElement('div')
  document.body.appendChild(modal)

  const closeAndCleanup = (answer: boolean) => {
    ReactDOM.unmountComponentAtNode(modal)
    try {
      document.body.removeChild(modal)
    } catch (e) {
      // tslint:disable:no-unsafe-any
      Raven.captureException(e)
    }
    callback(answer)
  }

  ReactDOM.render(
    <MainstayModal
      show={true}
      zIndex={3000}
      text="Are you sure you want to leave?"
      onClose={() => closeAndCleanup(false)}
      onSubmit={() => closeAndCleanup(true)}
      onCancel={() => closeAndCleanup(false)}
      submitText="Leave"
      cancelText="Cancel">
      <p className="text-muted">{message}</p>
    </MainstayModal>,
    modal
  )
}
