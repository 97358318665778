import * as React from 'react'
import { noOp } from 'util/noOp'

// https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
export const ESCAPE_STRINGS = ['Escape', 'Esc']

export const ChatComponent = {
  ACTIONS_MENU: 'ACTIONS_MENU',
  LANGUAGE_DROPDOWN: 'LANGUAGE_DROPDOWN',
  LANGUAGE_TOOLTIP: 'LANGUAGE_TOOLTIP',
  RESET_MODAL: 'RESET_MODAL',
  RESET_TOOLTIP: 'RESET_TOOLTIP',
  FEEDBACK_TEXTAREA: 'FEEDBACK_TEXTAREA',
  MINIMIZE_TOOLTIP: 'MINIMIZE_TOOLTIP',
  SEND_TOOLTIP: 'SEND_TOOLTIP',
} as const

export type ChatComponentType = typeof ChatComponent[keyof typeof ChatComponent]

interface IHideShowContext {
  updateState: ({
    component,
    toShow,
  }: {
    component?: keyof typeof ChatComponent
    toShow?: boolean
  }) => void
  hideShowStates: { [key in ChatComponentType]: boolean }
}

const initialStates = {
  [ChatComponent.ACTIONS_MENU]: false,
  [ChatComponent.LANGUAGE_DROPDOWN]: false,
  [ChatComponent.LANGUAGE_TOOLTIP]: false,
  [ChatComponent.RESET_MODAL]: false,
  [ChatComponent.RESET_TOOLTIP]: false,
  [ChatComponent.FEEDBACK_TEXTAREA]: false,
  [ChatComponent.MINIMIZE_TOOLTIP]: false,
  [ChatComponent.SEND_TOOLTIP]: false,
}

export const HideShowContext = React.createContext<IHideShowContext>({
  hideShowStates: initialStates,
  updateState: noOp,
})

export const useHideShowManager = () => {
  const initialStates = React.useMemo(
    () => ({
      [ChatComponent.ACTIONS_MENU]: false,
      [ChatComponent.LANGUAGE_DROPDOWN]: false,
      [ChatComponent.LANGUAGE_TOOLTIP]: false,
      [ChatComponent.RESET_MODAL]: false,
      [ChatComponent.RESET_TOOLTIP]: false,
      [ChatComponent.FEEDBACK_TEXTAREA]: false,
      [ChatComponent.MINIMIZE_TOOLTIP]: false,
      [ChatComponent.SEND_TOOLTIP]: false,
    }),
    []
  )

  const [hideShowStates, setHideShowStates] = React.useState(initialStates)

  const updateState = React.useCallback(
    ({
      component,
      toShow = false,
    }: {
      component?: keyof typeof ChatComponent
      toShow?: boolean
    }) => {
      if (component) {
        // Update the hide/show boolean only for the component we're currently updating.
        // Leave the rest as-is.
        // This enables us to have a tooltip open via onMouseEnter, have a modal open via
        // onClick, and then onMouseLeave, keep the modal open, but close the tooltip.
        setHideShowStates(prev => ({ ...prev, [component]: toShow }))
      } else {
        // If no component is passed, hide all components.
        setHideShowStates(initialStates)
      }
    },
    [initialStates]
  )
  return { hideShowStates, updateState }
}

export const HideShowContextProvider: React.FC = ({ children }) => {
  /*
    This Provider is used to manage the hide/show states of all the sub-components
    of the webchat.
  
    We need to enable keyboard-only users to use the Escape key to close these
    inner components without closing the entire webchat. Hoisting all the
    hide/show states and the update function that controls them into this
    context provider allows us to do that.
    */
  const { hideShowStates, updateState } = useHideShowManager()
  return (
    <HideShowContext.Provider value={{ hideShowStates, updateState }}>
      {children}
    </HideShowContext.Provider>
  )
}
