import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import scssVariables from 'scss/_variables.scss'
import uuid from 'uuid'

export const LoadingSkeleton = ({ rowCount = 2 }: { rowCount?: number }) => (
  <SkeletonTheme
    baseColor={scssVariables.mainstayFocusedGray}
    highlightColor="white">
    {Array.from(Array(rowCount - 1)).map(_elem => (
      <Skeleton
        key={uuid.v4()}
        className="loading-row top"
        containerClassName="w-100"
      />
    ))}
    <Skeleton className="loading-row bottom" containerClassName="w-100" />
  </SkeletonTheme>
)
