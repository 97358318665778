import { ActionType, createAsyncAction } from 'typesafe-actions'
import * as api from 'api'

type ContactFeedbackResponse = {
  readonly contact_feedback: api.ContactFeedbackShapeType[]
  readonly total_count: number
}

export const fetchContactFeedback = createAsyncAction(
  '@@MASCOT/DASHBOARD_CONTACT_FEEDBACK_FETCH_START',
  '@@MASCOT/DASHBOARD_CONTACT_FEEDBACK_FETCH_SUCCESS',
  '@@MASCOT/DASHBOARD_CONTACT_FEEDBACK_FETCH_ERROR'
)<void, ContactFeedbackResponse, void>()

export type IActions = ActionType<typeof fetchContactFeedback>
