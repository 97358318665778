import * as _ from 'lodash'
import uniq from 'lodash/uniq'
import {
  IContactsActions,
  updateContact,
  searchContact,
} from 'store/triage/chat/contactsActions'
import { IExchangesActions, getInbox } from 'store/triage/chat/exchangesActions'
import { IContactResponseData } from 'api/response'
import {
  IMessagesActionTypes,
  getMessagesInConversation,
} from 'store/triage/chat/messagesActions'
import { getType } from 'typesafe-actions'
import { SOFT_STOP_ID, SOFT_STOP_2_WEEK_STATE } from 'const/settings'
import { IConversationActions } from 'store/triage/chat/conversationActions'
import { IConversationsActions as IConversationsPageActions } from 'store/conversations/reducer'
import { IMessagingStatusOption } from 'components/ContactPanel/EditableContactPanel'

export interface ICampaignStatus {
  /** name of the corresponding Dialog */
  readonly name?: string
  readonly started: boolean | null
  readonly started_at: Date | null
  readonly finished: boolean | null
  readonly finished_at: Date | null
}

export interface IWebBotUserInfo {
  readonly isWebBotUser?: boolean | null
  readonly isActive?: boolean | null
}

export enum ContactMethod {
  SMS = 'SMS',
  Facebook = 'Facebook',
  WebBot = 'WebBot',
  Unknown = 'Unknown',
  Slack = 'Slack',
}

/**
 * Stores the old fields of the Contact Info that we update in the Conversations
 * Info Panel. Essentally, we update the data, make the request, and revert if
 * the request fails. This lets use revert only the fields that we have changed.
 */
export type IContactStateUpdateDiff = Pick<
  IContactState,
  'phoneNumber' | 'firstName' | 'lastName' | 'isTestUser'
>

export interface IContactState {
  id: string
  firstName: string
  middleName: string
  lastName: string
  phoneNumber: string | null
  canReceiveTexts: boolean
  isTestUser: boolean
  isFlagged: boolean
  readonly isArchived?: boolean
  org: string
  readonly messagingPaused: boolean
  readonly messagingTemporarilyPaused: boolean
  readonly crmId: string
  readonly contactMethod: ContactMethod
  readonly enrollmentId: string | null
  readonly created: Date
  readonly importSegmentLabels: ReadonlyArray<string>
  readonly previouslyOptedOutOfSMS: boolean
  readonly messagingStatus: Record<string, IMessagingStatusOption['value']>
  readonly lock: {
    expiresAt?: Date
    ownerId?: string
    ownerName?: string
    ownerIsAdmithubUser?: boolean
    timeLeftSeconds?: number
  }
  readonly campaignHistory: {
    readonly [key: string]: ICampaignStatus
  }
  readonly lastMessage?: {
    readonly id: string
    readonly body: string
    readonly createdAt: Date
  }
  readonly currentDialogId?: string
  // TODO(sbdchd): this should be a limited change set, aka only the fields that
  // we are changing rather than the entire obj
  readonly prev?: IContactStateUpdateDiff
  readonly webBot?: IWebBotUserInfo
}

function getLastMessage(c: IContactResponseData): IContactState['lastMessage'] {
  if (!c.smsInfo) {
    return undefined
  }
  if (
    c.smsInfo.lastMessageId == null ||
    c.smsInfo.lastMessageBody == null ||
    c.smsInfo.lastMessageAt == null
  ) {
    return undefined
  }

  return {
    id: c.smsInfo.lastMessageId,
    body: c.smsInfo.lastMessageBody,
    createdAt: new Date(c.smsInfo.lastMessageAt),
  }
}

function getCanRecieveTexts(
  c: IContactResponseData
): IContactState['canReceiveTexts'] {
  if (_.get(c, ['_contactSettings', 'canText']) === false) {
    return false
  }
  if (_.get(c, ['_contactSettings', 'permanentlySoftStopped']) === true) {
    return false
  }
  return true
}

function getIsFlagged(c: IContactResponseData): IContactState['isFlagged'] {
  if (c._contactSettings != null && c._contactSettings.wrongNumber !== null) {
    return c._contactSettings.wrongNumber
  }
  return false
}

function getCampaignHistory(
  c: IContactResponseData
): IContactState['campaignHistory'] {
  const resHistory = c.campaignHistory

  if (resHistory == null) {
    return {}
  }

  const baseObj: Mutable<NonNullable<IContactState['campaignHistory']>> = {}

  // convert from date strings to date objects
  return Object.entries(resHistory)
    .map(([key, value]): [string, ICampaignStatus] => {
      if (value == null) {
        return [key, value]
      }
      return [
        key,
        {
          ...value,
          started_at:
            value.started_at != null ? new Date(value.started_at) : null,
          finished_at:
            value.finished_at != null ? new Date(value.finished_at) : null,
        },
      ]
    })
    .reduce((acc, [key, value]) => {
      acc[key] = value
      return acc
    }, baseObj)
}

function mapContactMethod(c: IContactResponseData): ContactMethod {
  if (c.webBot != null) {
    return ContactMethod.WebBot
  }
  return ContactMethod.SMS
}

function mapWebBotUserInfo(c: IContactResponseData): IWebBotUserInfo {
  if (c.webBot != null) {
    const currentTime = new Date().getTime()
    const lastActiveTime = new Date(c.webBot.lastActive).getTime()

    return {
      isWebBotUser: c.webBot.webBotEnabled,
      // Check if User has pulsed in the last 20 seconds
      isActive: lastActiveTime > currentTime - 20000,
    }
  }

  return {
    isWebBotUser: false,
    isActive: false,
  }
}

export const mapContactFromResponseData = (
  responseContact: IContactResponseData
): IContactState => {
  return {
    id: responseContact.id,
    firstName:
      (responseContact.name && responseContact.name.first) || 'Anonymous',
    middleName: (responseContact.name && responseContact.name.middle) || '',
    lastName: (responseContact.name && responseContact.name.last) || '',
    canReceiveTexts: getCanRecieveTexts(responseContact),
    isArchived: responseContact.isArchived,
    isFlagged: getIsFlagged(responseContact),
    isTestUser: !!responseContact._testUser,
    phoneNumber: responseContact.phone,
    org: responseContact.collegeId,
    lock: responseContact.lock,
    lastMessage: getLastMessage(responseContact),
    created: new Date(responseContact.created),
    enrollmentId: responseContact.enrollmentId,
    campaignHistory: getCampaignHistory(responseContact),
    importSegmentLabels: responseContact.importSegmentLabels,
    contactMethod: mapContactMethod(responseContact),
    crmId: responseContact.crmId,
    currentDialogId: responseContact._dialog
      ? responseContact._dialog.id
      : undefined,
    messagingPaused:
      !!responseContact._dialog && responseContact._dialog.id === SOFT_STOP_ID,
    messagingTemporarilyPaused:
      !!responseContact._dialog &&
      responseContact._dialog.id === SOFT_STOP_ID &&
      responseContact._dialog.state === SOFT_STOP_2_WEEK_STATE,
    webBot: mapWebBotUserInfo(responseContact),
    previouslyOptedOutOfSMS: !!responseContact.previouslyOptedOutOfSMS,
    messagingStatus: responseContact.messagingStatus ?? {},
  }
}

const INITIAL_CONTACT_STATE = {
  byId: {},
  bySearchQuery: {},
  allIds: [],
  loading: false,
  searching: false,
  query: '',
}

export interface IContactsState {
  byId: {
    [key: string]: IContactState | undefined
  }
  bySearchQuery: {
    [key: string]: string[] | undefined
  }
  allIds: string[]
  loading: boolean
  searching: boolean
  query: string
}

const reducer = (
  state: IContactsState = INITIAL_CONTACT_STATE,
  action:
    | IContactsActions
    | IExchangesActions
    | IMessagesActionTypes
    | IConversationActions
    | IConversationsPageActions
): IContactsState => {
  switch (action.type) {
    case getType(getMessagesInConversation.success): {
      return {
        ...state,
        allIds: uniq(state.allIds.concat(action.payload.data.contact.id)),
        byId: {
          ...state.byId,
          [action.payload.data.contact.id]: {
            ...state.byId[action.payload.data.contact.id],
            ...mapContactFromResponseData(action.payload.data.contact),
          },
        },
      }
    }
    case getType(getInbox.success):
      const allIds = action.payload.results.contacts.map(c => c.id)
      const byId = _.chain(action.payload.results.contacts)
        .map(mapContactFromResponseData)
        .keyBy(c => c.id)
        .value()
      return {
        ...state,
        allIds: _.uniq([...allIds, ...state.allIds]),
        byId: {
          ...state.byId,
          ...byId,
        },
      }
    case getType(updateContact.failure):
      return {
        ...state,
        loading: false,
      }
    case getType(updateContact.success): {
      return {
        ...state,
        loading: false,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...mapContactFromResponseData(action.payload),
          },
        },
      }
    }
    case getType(searchContact.request): {
      return {
        ...state,
        searching: true,
      }
    }
    case getType(searchContact.failure): {
      return {
        ...state,
        searching: false,
      }
    }
    case getType(searchContact.success): {
      const mapped = _.chain(action.payload.contacts)
        .map(mapContactFromResponseData)
        .keyBy(x => x.id)
        .value()
      const newIds = Object.keys(mapped)
      return {
        ...state,
        query: action.payload.query,
        byId: {
          ...state.byId,
          ...mapped,
        },
        bySearchQuery: {
          ...state.bySearchQuery,
          [action.payload.query]: newIds,
        },
        allIds: _.uniq([...state.allIds, ...newIds]),
        searching: false,
      }
    }
    default:
      return state
  }
}

export default reducer
