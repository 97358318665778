import {
  CombinationOperator,
  ContactFilterRowType,
  IContactFilterFormRow,
} from 'components/ContactFilterBuilder/formUtils'
import Select from 'components/Select/SelectV2'
import {
  IContactAttributes,
  ITopLevelContactFields,
} from 'store/personalization/contactAttributes/selectors'

interface IParameterOption {
  label: string
  value: string | number
  parameterType: ContactFilterRowType
}

interface IParameterSelectProps {
  contactAttributes: IContactAttributes
  row: IContactFilterFormRow
  topLevelContactFields: ITopLevelContactFields
  onChange: (param: number | string, type: ContactFilterRowType) => void
  hideContactFields?: boolean
  error?: boolean
  readOnly?: boolean
}

export const ParameterSelect = ({
  contactAttributes,
  topLevelContactFields,
  row,
  onChange,
  hideContactFields,
  error,
  readOnly = false,
}: IParameterSelectProps) => {
  const conditionValidTopLevelFields = topLevelContactFields.filter(
    x =>
      ![
        'mongo_college_id',
        'facebook_id',
        'facebook_opt_in',
        'name_middle',
        'name_full',
        'permitted_user_mutable',
        'last_incoming_message_id',
        'last_outgoing_message_id',
        'last_message_id',
      ].includes(x.field)
  )

  const contactAttributeOptions: IParameterOption[] = contactAttributes.map(
    attr => ({
      label: attr.field,
      value: attr.id,
      parameterType: ContactFilterRowType.ATTRIBUTE,
    })
  )
  const topLevelFieldOptions: IParameterOption[] = conditionValidTopLevelFields.map(
    field => ({
      label: field.readableName || field.field,
      value: field.field,
      parameterType: ContactFilterRowType.TOP_LEVEL_FIELD,
    })
  )

  const groupOptions: IParameterOption[] = [
    {
      label: '(All of the following)',
      value: CombinationOperator.ALL,
      parameterType: ContactFilterRowType.GROUP_ALL,
    },
    {
      label: '(Any of the following)',
      value: CombinationOperator.ANY,
      parameterType: ContactFilterRowType.GROUP_ANY,
    },
  ]

  const parameterOptions = [
    ...groupOptions,
    ...(hideContactFields ? [] : topLevelFieldOptions),
    ...(hideContactFields ? [] : contactAttributeOptions),
  ]

  const selectedOption = parameterOptions.find(
    opt => opt.value === row.parameter
  )

  return (
    <Select<IParameterOption>
      options={parameterOptions}
      value={selectedOption}
      error={error}
      placeholder=""
      explicitPreventOverlap={true}
      onChange={opt => {
        if (Array.isArray(opt) || opt == null) {
          return
        }
        onChange(opt.value, opt.parameterType)
      }}
      isDisabled={readOnly}
      readOnly={readOnly}
    />
  )
}
