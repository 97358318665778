import React from 'react'
import { ModifierColor } from 'components/Button/Button'
import classNames from 'classnames'
import { CloseButtonIcon } from 'components/Icons/CloseButtonIcon/CloseButtonIcon'
import { AlertIcon, ZitDangerIcon } from 'components/Icons/AlertIcon/AlertIcon'
import scssVariables from 'scss/_variables.scss'

interface IAlertProps {
  readonly className?: string
  readonly children: React.ReactNode
  readonly type: ModifierColor
}

/** Bootstrap Alert
 * see: https://getbootstrap.com/docs/4.2/components/alerts/
 */
export function Alert(props: IAlertProps) {
  const color = 'alert-' + props.type
  const cls = props.className || ''
  return (
    <div className={`alert ${color} ${cls} border-${color}`} role="alert">
      {props.children}
    </div>
  )
}

export enum AlertType {
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Info = 'info',
  Neutral = 'neutral',
}

const AlertTypeToColorScheme = (type: AlertType) => {
  switch (type) {
    case AlertType.Success:
      return {
        backgroundColor: 'mainstay-success-50',
        borderColor: 'mainstay-success-500',
        subtitleColor: 'mainstay-success-600',
        titleColor: 'mainstay-success-700',
        closeColorHex: scssVariables.mainstaySuccess700,
      }
    case AlertType.Warning:
      return {
        backgroundColor: 'mainstay-warning-50',
        borderColor: 'mainstay-warning-300',
        subtitleColor: 'mainstay-warning-600',
        titleColor: 'mainstay-warning-700',
        closeColorHex: scssVariables.mainstayWarning700,
      }
    case AlertType.Danger:
      return {
        backgroundColor: 'mainstay-error-50',
        borderColor: 'mainstay-error-300',
        subtitleColor: 'mainstay-error-600',
        titleColor: 'mainstay-error-700',
        closeColorHex: scssVariables.mainstayError700,
      }
    case AlertType.Info:
      return {
        backgroundColor: 'mainstay-blue-10',
        borderColor: 'mainstay-blue-40',
        subtitleColor: 'mainstay-blue-60',
        titleColor: 'mainstay-blue-80',
        closeColorHex: scssVariables.mainstayBlue80,
      }
    case AlertType.Neutral:
    default:
      return {
        backgroundColor: 'mainstay-gray-backgrounds',
        borderColor: 'mainstay-gray-lines',
        subtitleColor: 'mainstay-gray-text',
        titleColor: 'mainstay-gray-header',
        closeColorHex: scssVariables.mainstayGrayHeader,
      }
  }
}

export function InfoAlert({
  children,
  className,
}: {
  readonly children: React.ReactNode
  readonly className?: string
}) {
  return (
    <Alert
      type="info"
      className={classNames(
        'd-flex align-items-center',
        'bg-mainstay-blue-10 border-none text-mainstay-blue-80',
        className
      )}>
      <AlertIcon type={AlertType.Info} className="pr-4" />
      <p className="mb-0">{children}</p>
    </Alert>
  )
}

interface IAlertBannerProps {
  readonly className?: string
  readonly title?: string
  readonly subtitle?: string
  readonly closeable?: boolean
  readonly type: AlertType
  readonly children?: React.ReactNode
  readonly iconWidth?: number
}

export function AlertBanner({
  className,
  closeable,
  title,
  subtitle,
  iconWidth,
  type,
  children,
}: IAlertBannerProps) {
  const [open, setOpen] = React.useState(true)
  const {
    backgroundColor,
    borderColor,
    subtitleColor,
    titleColor,
    closeColorHex,
  } = AlertTypeToColorScheme(type)
  return open ? (
    <div
      className={classNames(
        className,
        `bg-${backgroundColor}`,
        `border border-radius-05em border-${borderColor}`
      )}
      role="alert">
      <div className="d-flex justify-content-between">
        <div className="py-2 d-flex justify-content-center">
          <AlertIcon width={iconWidth} className="ml-2 mt-_1" type={type} />
          <div>
            {title && (
              <div className={`pl-2 mainstay-header-h5 text-${titleColor}`}>
                {title}
              </div>
            )}
            {subtitle && (
              <div
                className={classNames(
                  `pl-2 mainstay-body-paragraph text-${subtitleColor}`,
                  { 'pb-2': !!children }
                )}>
                {subtitle}
              </div>
            )}
            {children}
          </div>
        </div>
        {closeable ? (
          <div onClick={() => setOpen(false)}>
            <CloseButtonIcon
              width={10}
              height={10}
              className="mt-3 mr-3"
              fill={closeColorHex}
            />
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  ) : null
}

export function AlertBannerWithLinks({
  links,
  ...props
}: IAlertBannerProps & { links: { text: string; url: string }[] }) {
  return (
    <AlertBanner {...props}>
      <div>
        {links.map(link => (
          <a
            key={link.url}
            target="blank"
            className={`d-inline pl-2 pb-2 mainstay-body-paragraph mainstay-link text-${scssVariables.mainstayBlue70}`}
            href={link.url}>
            {link.text}
          </a>
        ))}
      </div>
    </AlertBanner>
  )
}

export function DangerText({ text }: { text: string }) {
  return (
    <>
      <ZitDangerIcon className="d-inline-block" />
      <span className="mainstay-body-paragraph text-mainstay-error-600 pl-1 align-middle">
        {text}
      </span>
    </>
  )
}
