import * as React from 'react'
import classnames from 'classnames'
import 'embed/components/ActivationButton.scss'
import PopUpMessageIFrame, {
  PopUpStatus,
} from 'embed/popUpMessage/PopUpMessageIFrame'
import { ActivationIcon } from 'components/Icons/ActivationIcon/ActivationIcon'

interface IActivationIconProps {
  readonly style?: React.CSSProperties
  readonly onClick: () => void
  readonly text?: string
}

export function ActivationIconWrapper({
  style,
  onClick,
  text,
}: IActivationIconProps) {
  return (
    <div
      className="activation-button d-flex flex-row align-items-center justify-content-center"
      onClick={onClick}
      onKeyPress={onClick}
      style={style}
      tabIndex={0}
      aria-label={text || 'Press to Open Chat.'}
      role="button">
      <ActivationIcon fill={style?.color} />
      {text && <span className="mx-3 label">{text}</span>}
    </div>
  )
}

interface IActivationButtonProps {
  style?: React.CSSProperties
  children: React.ReactElement
  expanded?: boolean
  scriptDomain: string
  labelText?: string
}

export default function ActivationButton(props: IActivationButtonProps) {
  const [visible, setIsVisible] = React.useState(props.expanded)
  const [popUpStatus, setPopUpStatus] = React.useState(PopUpStatus.Loading)
  const [style, setStyle] = React.useState(props.style)
  const [labelText, setLabelText] = React.useState(props.labelText)
  const showPopUp = () => {
    if (popUpStatus !== PopUpStatus.Completed) {
      setPopUpStatus(PopUpStatus.Visible)
    }
  }
  const open = () => {
    setIsVisible(true)
    setPopUpStatus(PopUpStatus.Completed)
  }
  const close = () => {
    setPopUpStatus(PopUpStatus.Completed)
    setIsVisible(false)
  }
  const closePopUp = React.useCallback(() => {
    setPopUpStatus(PopUpStatus.Completed)
  }, [])

  // The iFrame can't be display:none in order to send requests, but it must be
  // display:none to hide those divs from screen readers / focus. As such, we
  // need to handle hiding the frame differently before and after its loaded
  const [loaded, setLoaded] = React.useState(false)

  const updateButtonColor = React.useCallback(
    (color: string) =>
      setStyle(prev => {
        setLoaded(true)
        return { ...prev, background: color, visibility: 'visible' }
      }),
    []
  )

  const updateTextColor = React.useCallback(
    (textColor: string) =>
      setStyle(prev => {
        setLoaded(true)
        return { ...prev, color: textColor }
      }),
    []
  )

  const updateButtonLabel = React.useCallback((text: string) => {
    setLabelText(text)
  }, [])

  // Safari 10/11 don't properly render iframes of 0-size (which is needed on
  // other browsers to pre-fetch the contents of the iframe). As such, we need
  // different css behavior for these versions to fetch and show the webchat.
  // The regex below matches to: Safari 10.x or 10.x.x or 11.x or 11.x.x
  // Sample matching user Agent string:
  //     Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/605.1.15
  //     (KHTML, like Gecko) Version/11.1.2 Safari/605.1.15
  const isBuggySafari = !!window.navigator.userAgent.match(
    /\/(10|11)\.[0-9]+(\.[0-9]+)* Safari/
  )

  return (
    <>
      <PopUpMessageIFrame
        scriptDomain={props.scriptDomain}
        popUpStatus={popUpStatus}
        onClick={open}
        onClose={closePopUp}
        onShowPopUp={showPopUp}
      />
      <ActivationIconWrapper style={style} onClick={open} text={labelText} />
      <div
        className={classnames({
          hidden: !visible && loaded,
          loading: !loaded,
          'safari-loading-fix': isBuggySafari && !loaded,
        })}>
        {React.cloneElement(props.children, {
          onClose: close,
          updateTextColor,
          updateButtonColor,
          updateButtonLabel,
          isVisible: visible,
        })}
      </div>
    </>
  )
}
