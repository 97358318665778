import { DropdownControl } from 'components/Dropdown/DropdownControl'
import { DropdownActionPopoverMenu } from 'components/Dropdown/DropdownPopoverMenu'
import { PopoverComponent } from 'components/PopoverComponent/PopoverComponent'
import Popper from 'popper.js'
import { useCallback, useState } from 'react'

export interface IDropdownItem {
  label: string
  value: string
}

interface IDropdownProps {
  items: IDropdownItem[]
  onSelect: (item: IDropdownItem) => void
  popoverPlacement?: Popper.Placement
  modifiers?: Popper.Modifiers
  value?: IDropdownItem
  overrideWidth?: string
}

export function Dropdown({
  items,
  popoverPlacement = 'top-start',
  modifiers = {
    offset: {
      enabled: true,
      offset: '0, -38px',
    },
  },
  onSelect,
  value,
  overrideWidth,
}: IDropdownProps): JSX.Element {
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<IDropdownItem | undefined>(
    value
  )

  const onItemSelect = useCallback(
    (item: IDropdownItem): void => {
      setIsOpened(false)
      setSelectedItem(item)
      onSelect(item)
    },
    [setIsOpened, setSelectedItem, onSelect]
  )

  return (
    <PopoverComponent
      visible={isOpened}
      popoverPlacement={popoverPlacement}
      onClickOutside={() => setIsOpened(false)}
      modifiers={modifiers}
      renderReference={() => (
        <DropdownControl
          label={selectedItem?.label}
          onClick={() => setIsOpened(opened => !opened)}
          overrideWidth={overrideWidth}
        />
      )}
      renderPopper={() => (
        <DropdownActionPopoverMenu onSelect={onItemSelect} items={items} />
      )}
    />
  )
}
