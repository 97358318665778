import {
  getFirstQueryParam,
  humanizedFilterLabels,
} from 'components/ContactList/SortableContactList'
import { getAudienceName } from 'components/ContactSegmentSelect/ContactSegmentSelect'
import 'components/FilterContactsModal/FilterContactsModal.scss'
import { format, parse, subDays } from 'date-fns'
import { History } from 'history'
import moment from 'moment-timezone'
import { useLocation } from 'react-router'

import {
  FilterEnum,
  IContactsListFilterModalProps,
  QUERY_PARAM_DATE_FORMAT,
  RelativeDateRange,
  RelativeDateRangeEnum,
  StatusEnum,
  TestUserEnum,
} from 'components/FilterContactsModal/FilterContactsModal'
import {
  DEFAULT_DATE_QUERY_END,
  DEFAULT_DATE_QUERY_START,
  DEFAULT_DATE_RANGE_DAYS,
  IFilterStates,
  noneSelectedChannelFilters,
} from 'components/FilterContactsModal/filterContactsReducer'
import { useEffect } from 'react'
import {
  IContactSegmentList,
  listContactSegments,
} from 'store/contact-segments/selectors'
import { listContactSegmentsAsync } from 'store/contact-segments/thunks'
import { ChannelEnum } from 'store/transport'
import { isInitial, isSuccess } from 'store/webdata'
import { parseDateOrDefault } from 'util/datetime'
import { useDispatch, useSelector } from 'util/hooks'
import {
  appendQueryFilter,
  getQueryFilters,
  removeQueryFilter,
} from 'util/queryFilters'
import {
  enumKeyFromString,
  parseQueryString,
  queryParamToArray,
} from 'util/string'

const READABLE_DATE_FORMAT = 'MMMM d, yyyy'
export const todayStr = 'today'

export const getDateFromQueryParams = (
  type: 'from' | 'to'
): string | undefined => {
  const dateString = getFirstQueryParam(
    getQueryFilters(window.location)[type]
  ).toLowerCase()
  try {
    let daysAgo: number | undefined = undefined

    if (type === 'from') {
      if (dateString.includes(todayStr)) {
        daysAgo = Number(dateString.split(`${todayStr}-`)[1])
        const date = new Date()
        date.setDate(date.getDate() - daysAgo)
        return format(date, READABLE_DATE_FORMAT)
      }
      return format(
        parse(dateString, QUERY_PARAM_DATE_FORMAT, new Date()),
        READABLE_DATE_FORMAT
      )
    }
    if (dateString === todayStr) {
      return format(new Date(), READABLE_DATE_FORMAT)
    }
    return format(
      parse(dateString, QUERY_PARAM_DATE_FORMAT, new Date()),
      READABLE_DATE_FORMAT
    )
  } catch (e) {
    if (e instanceof RangeError) {
      return undefined
    }
  }
}

export const getAudienceIdFromParam = (): number | undefined => {
  const audienceIdParam = getQueryFilters(window.location)['audience']
  return typeof audienceIdParam === 'string'
    ? parseInt(audienceIdParam, 10)
    : undefined
}

const getAudienceNameFromQueryParams = (
  segments: IContactSegmentList
): string | undefined => {
  const audienceId = getAudienceIdFromParam() ?? -1
  return getAudienceName(audienceId, segments)
}

interface IFilterChipsData {
  // filter may have one value | multiple values | no value.
  value: string | string[] | undefined

  // humanize: a function to transform the field into displayable format. used in UI in the chips component.
  humanize: (v: string) => string

  // list of query params supported by the filter
  params: string[]
}

export const getChannelFiltersObj = (multi: boolean) => {
  return Object.keys(ChannelEnum).reduce<IFilterStates['channelFiltersObj']>(
    (acc, elem) => ({
      ...acc,
      [enumKeyFromString(elem, ChannelEnum)]: multi
        ? queryParamToArray(
            getQueryFilters(window.location)['channel']
          ).includes(elem)
        : getFirstQueryParam(
            getQueryFilters(window.location)['channel']
          ).includes(elem),
    }),
    noneSelectedChannelFilters
  )
}

export const getStatusFiltersObj = () => {
  return Object.keys(StatusEnum).reduce<IFilterStates['statusFilterObj']>(
    (acc, elem) => ({
      ...acc,
      [elem]: queryParamToArray(
        getQueryFilters(window.location)['filter']
      ).includes(elem),
    }),
    {
      [StatusEnum.active]: false,
      [StatusEnum.archived]: false,
    }
  )
}

export const getTestUserFilterObj = () => {
  return Object.values(TestUserEnum).reduce<IFilterStates['testUserFilterObj']>(
    (acc, elem) => {
      return {
        ...acc,
        [elem]: queryParamToArray(
          getQueryFilters(window.location)['testUser']
        ).includes(elem),
      }
    },
    {
      [TestUserEnum.test]: false,
      [TestUserEnum.nonTest]: false,
    }
  )
}

export const getRelativeDateRangeFilterObj = ({
  locationSearch,
  timeZone,
}: {
  locationSearch?: string
  timeZone: string
}): IFilterStates['relativeDateRangeObj'] => {
  let relativeDateRangeType: RelativeDateRange = RelativeDateRangeEnum.dateRange
  let daysAgo: number = DEFAULT_DATE_RANGE_DAYS
  let relStartDate: Date = DEFAULT_DATE_QUERY_START
  let relEndDate: Date = DEFAULT_DATE_QUERY_END
  const from = getFirstQueryParam(
    parseQueryString(locationSearch ?? window.location.search)['from']
  ).toLowerCase()
  const to = getFirstQueryParam(
    parseQueryString(locationSearch ?? window.location.search)['to']
  ).toLowerCase()

  // default - Last... 90 days
  if (!from || !to) {
    return {
      relativeDateRangeType: RelativeDateRangeEnum.last,
      daysAgo,
      relStartDate,
      relEndDate,
    }
  }

  // Last ... x days
  if (from.includes(todayStr)) {
    relativeDateRangeType = RelativeDateRangeEnum.last
    daysAgo = Number(from.split(`${todayStr}-`)[1])
    relStartDate = subDays(DEFAULT_DATE_QUERY_END.setHours(0, 0, 0, 0), daysAgo)
    return { relativeDateRangeType, daysAgo, relStartDate, relEndDate }
  }

  // Fixed start date used for other 2 relative date range types
  relStartDate = parseDateOrDefault(
    from,
    QUERY_PARAM_DATE_FORMAT,
    DEFAULT_DATE_QUERY_START
  )

  // Starting on... YYYY-MM-DD - TODAY
  if (to.includes(todayStr)) {
    relativeDateRangeType = RelativeDateRangeEnum.startingOn
    relEndDate = moment()
      .tz(timeZone)
      .toDate()
    return { relativeDateRangeType, daysAgo, relStartDate, relEndDate }
  }

  // Fixed date range - YYYY-MM-DD - YYYY-MM-DD
  relativeDateRangeType = RelativeDateRangeEnum.dateRange
  relEndDate = parseDateOrDefault(
    to,
    QUERY_PARAM_DATE_FORMAT,
    DEFAULT_DATE_QUERY_END
  )
  return { relativeDateRangeType, daysAgo, relStartDate, relEndDate }
}

interface IHandleUpdateQueryParams extends IFilterStates {
  enabledFilters: IContactsListFilterModalProps['enabledFilters']
  history: History
}

export const handleUpdateQueryParams = ({
  enabledFilters,
  history,
  channelFiltersObj,
  statusFilterObj,
  testUserFilterObj,
  audienceFilter,
  relativeDateRangeObj,
}: IHandleUpdateQueryParams) => {
  if (FilterEnum.channel in enabledFilters) {
    history.push(removeQueryFilter(window.location, 'channel'))
    Object.keys(channelFiltersObj)
      .filter(
        elem => channelFiltersObj[enumKeyFromString(elem, ChannelEnum)] === true
      )
      .forEach(elem =>
        history.replace(
          appendQueryFilter(window.location, 'channel', elem, true)
        )
      )
  }
  if (FilterEnum.filter in enabledFilters) {
    history.push(removeQueryFilter(window.location, 'filter'))
    Object.keys(statusFilterObj)
      .filter(
        elem => statusFilterObj[enumKeyFromString(elem, StatusEnum)] === true
      )
      .forEach(elem =>
        history.replace(
          appendQueryFilter(window.location, 'filter', elem, true)
        )
      )
  }
  if (FilterEnum.testUser in enabledFilters) {
    history.push(removeQueryFilter(window.location, 'testUser'))
    Object.keys(testUserFilterObj)
      .filter(
        elem =>
          testUserFilterObj[
            /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
            elem as typeof TestUserEnum[keyof typeof TestUserEnum]
          ] === true
      )
      .forEach(elem =>
        history.replace(
          appendQueryFilter(window.location, 'testUser', elem, true)
        )
      )
  }
  if (FilterEnum.audience in enabledFilters) {
    if (!!audienceFilter) {
      history.replace(
        appendQueryFilter(window.location, 'audience', String(audienceFilter))
      )
    } else {
      history.push(removeQueryFilter(window.location, 'audience'))
    }
  }
  if (FilterEnum.relativeDate in enabledFilters) {
    if (
      relativeDateRangeObj.relativeDateRangeType === RelativeDateRangeEnum.last
    ) {
      history.replace(
        appendQueryFilter(
          window.location,
          'from',
          `${todayStr}-${relativeDateRangeObj.daysAgo}`
        )
      )
      history.replace(appendQueryFilter(window.location, 'to', todayStr))
    } else if (
      relativeDateRangeObj.relativeDateRangeType ===
      RelativeDateRangeEnum.startingOn
    ) {
      history.replace(
        appendQueryFilter(
          window.location,
          'from',
          format(relativeDateRangeObj.relStartDate, QUERY_PARAM_DATE_FORMAT)
        )
      )
      history.replace(appendQueryFilter(window.location, 'to', todayStr))
    } else {
      history.replace(
        appendQueryFilter(
          window.location,
          'from',
          format(relativeDateRangeObj.relStartDate, QUERY_PARAM_DATE_FORMAT)
        )
      )
      history.replace(
        appendQueryFilter(
          window.location,
          'to',
          format(relativeDateRangeObj.relEndDate, QUERY_PARAM_DATE_FORMAT)
        )
      )
    }
  }
}

export type FilterChipsDataType = {
  [k in keyof typeof FilterEnum]: IFilterChipsData
}

/* 
  Hook to process data point filters from query params.
*/
export function useDataPointFiltersFromQueryParams(): FilterChipsDataType {
  const location = useLocation()
  const dispatch = useDispatch()
  const audiences = useSelector(listContactSegments)

  useEffect(() => {
    if (isInitial(audiences)) {
      listContactSegmentsAsync(dispatch)
    }
  }, [dispatch, audiences])

  return {
    relativeDate: {
      value:
        getDateFromQueryParams('from') && getDateFromQueryParams('to')
          ? `${getDateFromQueryParams('from')} – ${getDateFromQueryParams(
              'to'
            )}`
          : undefined,
      humanize: (v: string) => {
        const fromStr = parseQueryString(location.search)['from']
        const toStr = parseQueryString(location.search)['to']
        // Last...
        if (
          !!fromStr &&
          !Array.isArray(fromStr) &&
          fromStr?.toLowerCase().includes(todayStr)
        ) {
          const daysAgo = fromStr.toLowerCase().split('today-')[1]
          return `Last ${daysAgo} days`
          // Starting on...
        } else if (!!toStr && !Array.isArray(toStr) && toStr === todayStr) {
          return `Starting ${getDateFromQueryParams('from')} – Today`
        }
        // Fixed date range
        return v
      },
      params: ['from', 'to'],
    },
    audience: {
      value: isSuccess(audiences)
        ? getAudienceNameFromQueryParams(audiences.data)
        : '',
      humanize: (v: string) => `Audience: ${v}`,
      params: ['audience'],
    },
    channel: {
      value: queryParamToArray(parseQueryString(location.search)['channel']),
      humanize: (v: string) => humanizedFilterLabels[v],
      params: ['channel'],
    },
    filter: {
      value: queryParamToArray(parseQueryString(location.search)['filter']),
      humanize: (v: string) => humanizedFilterLabels[v],
      params: ['filter'],
    },
    testUser: {
      value: queryParamToArray(parseQueryString(location.search)['testUser']),
      humanize: (v: string) => humanizedFilterLabels[v],
      params: ['testUser'],
    },
  }
}

/* 
  Hook to process data point filters from the store.
*/
export function useParseChipsData(state: IFilterStates): FilterChipsDataType {
  const dispatch = useDispatch()
  const {
    channelFiltersObj,
    statusFilterObj,
    testUserFilterObj,
    audienceFilter,
    relativeDateRangeObj,
  } = state
  const audiences = useSelector(listContactSegments)

  useEffect(() => {
    if (isInitial(audiences)) {
      listContactSegmentsAsync(dispatch)
    }
  }, [dispatch, audiences])

  const fromStr = relativeDateRangeObj.relStartDate?.toDateString()
  const toStr = relativeDateRangeObj.relEndDate?.toDateString()

  return {
    relativeDate: {
      value: fromStr && toStr ? `${fromStr} – ${toStr}` : undefined,
      humanize: (v: string) => {
        // Last...
        if (
          relativeDateRangeObj.relativeDateRangeType ===
          RelativeDateRangeEnum.last
        ) {
          return `Last ${relativeDateRangeObj.daysAgo} days`
          // Starting on...
        } else if (
          relativeDateRangeObj.relativeDateRangeType ===
          RelativeDateRangeEnum.startingOn
        ) {
          return `Starting ${fromStr} – Today`
        }
        // Fixed date range
        return v
      },
      params: ['from', 'to'],
    },
    audience: {
      value:
        isSuccess(audiences) && audienceFilter
          ? getAudienceName(audienceFilter, audiences.data)
          : undefined,
      humanize: (v: string) => `Audience: ${v}`,
      params: ['audience'],
    },
    channel: {
      value: Object.entries(channelFiltersObj)
        .filter(([_key, val], _index) => val)
        .map(item => item[0]),
      humanize: (v: string) => humanizedFilterLabels[v],
      params: ['channel'],
    },
    filter: {
      value: Object.entries(statusFilterObj)
        .filter(([_key, val], _index) => val)
        .map(item => item[0]),
      humanize: (v: string) => humanizedFilterLabels[v],
      params: ['filter'],
    },
    testUser: {
      value: Object.entries(testUserFilterObj)
        .filter(([_key, val], _index) => val)
        .map(item => item[0]),
      humanize: (v: string) => humanizedFilterLabels[v],
      params: ['testUser'],
    },
  }
}
