import * as React from 'react'

interface IOutsideClickProps {
  onClickOutside: (e?: React.MouseEvent) => void
  containerClassName?: string
  tabbable?: boolean
  excludeClassname?: string
}

export class OutsideClickHandler extends React.Component<IOutsideClickProps> {
  contentRef: React.RefObject<HTMLDivElement>

  constructor(props: IOutsideClickProps) {
    super(props)
    this.contentRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress)
    document.addEventListener('mousedown', this.handleOuterClick, true)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress)
    document.removeEventListener('mousedown', this.handleOuterClick, true)
  }

  handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.props.onClickOutside()
    }
  }

  handleOuterClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const target = e.target as Element
    // This is an escape hatch used in really niche cases where we want to cancel outside click handlers from firing when
    // a click happens in another completely distinct subtree of the dom. An example is when we want to fire the outside click
    // handler action except when an open modal is clicked on.
    if (this.props.excludeClassname) {
      const excludeClassnames = this.props.excludeClassname.split(' ')
      for (const classname of excludeClassnames) {
        const excludeContainer = document.querySelector(`.${classname}`)
        if (excludeContainer?.contains(target)) {
          return
        }
      }
    }
    if (this.contentRef.current && !this.contentRef.current.contains(target)) {
      this.props.onClickOutside()
    }
  }

  render() {
    return (
      <div
        className={this.props.containerClassName}
        tabIndex={this.props.tabbable ? 0 : -1}
        ref={this.contentRef}>
        {this.props.children}
      </div>
    )
  }
}
