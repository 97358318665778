import * as React from 'react'
import classnames from 'classnames'
import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'
export interface IMainstayFlexTableColProps {
  xs?: number
  sm?: number
  md?: number
  lg?: number
  noDefaultPadding?: boolean
  className?: string
  style?: React.CSSProperties
  yPadding?: number
  xPadding?: number
  eventLocation?: string
  eventAction?: EventAction
  eventObject?: string
}

class MainstayFlexTableColBase extends React.PureComponent<
  IMainstayFlexTableColProps
> {
  render() {
    const {
      xs,
      sm,
      md,
      lg,
      className,
      children,
      style,
      eventAction,
      eventLocation,
      eventObject,
    } = this.props

    return (
      <EventTrackerAttrAdder
        eventAction={eventAction}
        eventLocation={eventLocation}
        eventObject={eventObject}>
        <div
          style={style}
          className={classnames(className, {
            [`col-${xs}`]: !!xs,
            [`col-sm-${sm}`]: !!sm,
            [`col-md-${md}`]: !!md,
            [`col-lg-${lg}`]: !!lg,
            col: !xs && !sm && !md && !lg,
          })}>
          {children}
        </div>
      </EventTrackerAttrAdder>
    )
  }
}
export class MainstayFlexTableCol extends React.PureComponent<
  IMainstayFlexTableColProps
> {
  render() {
    const yPaddingClass = `py-${this.props.yPadding || 3}`
    const xPaddingClass = `px-${this.props.xPadding || 2}`
    return (
      <MainstayFlexTableColBase
        {...this.props}
        className={classnames(
          {
            [yPaddingClass]: !this.props.noDefaultPadding,
            [xPaddingClass]: !this.props.noDefaultPadding,
          },
          this.props.className
        )}
      />
    )
  }
}

export class MainstayFlexTableHeaderCol extends React.PureComponent<
  IMainstayFlexTableColProps
> {
  render() {
    return (
      <EventTrackerAttrAdder
        eventAction={this.props.eventAction}
        eventLocation={this.props.eventLocation}
        eventObject={this.props.eventObject}>
        <MainstayFlexTableColBase
          {...this.props}
          className={classnames(
            'h6',
            'm-0',
            'small',
            'text-mainstay-dark-blue',
            { 'pb-2 pt-1 px-2': !this.props.noDefaultPadding },
            this.props.className
          )}
        />
      </EventTrackerAttrAdder>
    )
  }
}
