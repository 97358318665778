import React from 'react'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { Link } from 'util/routing'
import cls from 'classnames'
import * as History from 'history'
import { EventAction } from 'components/EventTracker/EventTracker'
import { PopoverComponent } from 'components/PopoverComponent/PopoverComponent'
import 'components/ContentCard/ContentCard.scss'
import { CaretIcon } from 'components/Icons/CaretIcon/CaretIcon'
import pluralize from 'pluralize'
import {
  ILinkWithMiddleEllipsisProps,
  LinkWithMiddleEllipsis,
} from 'components/LinkWithMiddleEllipsis/LinkWithMiddleEllipsis'

type ContentCardContainerProps = {
  readonly dataId?: string
  readonly children: React.ReactNode
  readonly link: History.LocationDescriptor | undefined
  readonly linkClassName: string | undefined
  readonly openInNewTab: boolean | undefined
  eventLocation?: string
  eventObject?: string
  eventAction?: EventAction
}
function ContentCardContainer({
  dataId,
  children,
  link,
  linkClassName,
  openInNewTab,
  eventLocation,
  eventObject,
  eventAction,
}: ContentCardContainerProps): JSX.Element {
  if (link) {
    return (
      <Link
        eventLocation={eventLocation}
        eventAction={eventAction}
        eventObject={eventObject}
        data-id={dataId}
        to={link}
        className={cls('text-decoration-none', 'w-100', linkClassName)}
        target={openInNewTab ? '_blank' : undefined}>
        {children}
      </Link>
    )
  }
  return (
    <div data-id={dataId} className={cls('w-100', linkClassName)}>
      {children}
    </div>
  )
}

type ContentCardProps = {
  readonly dataId?: string
  readonly children: React.ReactNode
  readonly link?: History.LocationDescriptor
  readonly openInNewTab?: boolean
  readonly className?: string
  readonly linkClassName?: string
  eventLocation?: string
  eventObject?: string
  eventAction?: EventAction
}

export function ContentCard({
  dataId,
  children,
  link,
  className,
  linkClassName,
  openInNewTab = true,
  eventLocation,
  eventObject,
  eventAction,
}: ContentCardProps) {
  const classNames = cls(
    'text-mainstay-dark-blue-80',
    'border',
    'bg-white',
    'rounded',
    'content-card',
    'h-auto',
    'border-radius-025em',
    'pl-_75rem',
    {
      'hover-opacity-100-parent hover-bg-mainstay-dark-blue-10 hover-text-blue-grey-600 hover-border-mainstay-dark-blue-50': !!link,
    },
    className
  )
  return (
    <div className={classNames}>
      <ContentCardContainer
        eventLocation={eventLocation}
        eventAction={eventAction}
        eventObject={eventObject}
        dataId={dataId}
        link={link}
        openInNewTab={openInNewTab}
        linkClassName={linkClassName}
        children={children}
      />
    </div>
  )
}

type ReferenceContentCardProps = ContentCardProps & {
  readonly type?: React.ReactNode
  readonly icon?: React.ReactElement
  readonly footer?: React.ReactElement
  readonly iconClassName?: string
}

export function ReferenceContentCard({
  type,
  icon,
  footer,
  children,
  openInNewTab = true,
  link,
  className,
  iconClassName,
  ...rest
}: ReferenceContentCardProps) {
  const classNames = cls(
    'd-flex',
    'justify-content-between',
    'align-items-start',
    'w-100',
    'content-card',
    'position-relative',
    className
  )
  return (
    <ContentCard
      openInNewTab={openInNewTab}
      className={classNames}
      link={link}
      {...rest}>
      <div className="d-flex py-2 pr-2 w-100">
        {icon && (
          <div
            className={cls(
              'fill-mainstay-dark-blue-65 text-mainstay-dark-blue-65 pr-2',
              iconClassName
            )}>
            {icon}
          </div>
        )}
        <div className="d-flex flex-column ellipsis-text-container w-100">
          <h6 className="text-uppercase text-mainstay-dark-blue m-0">{type}</h6>
          <div className="ellipsis-text-content">{children}</div>
        </div>
        {footer && (
          <div className="align-self-center flex-grow-1">{footer}</div>
        )}
        {link && openInNewTab && (
          <AHIcon
            name="open_in_new"
            className="mr-1"
            style={{ position: 'absolute', right: '0px' }}
          />
        )}
      </div>
    </ContentCard>
  )
}

export type MessageContentCardProps = Omit<ContentCardProps, 'children'> & {
  readonly time?: string
  readonly name?: string
  readonly body?: string
}

export function MessageContentCard({
  time,
  name,
  body,
  className,
  ...rest
}: MessageContentCardProps) {
  const classNames = cls(className)
  return (
    <ContentCard {...rest} openInNewTab={false} className={classNames}>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between pt-2 pr-2 w-100">
          <h6
            className="mainstay-body-paragraph text-mainstay-dark-blue m-0"
            style={{ width: '460px' }}>
            {body}
          </h6>
          <div className="flex-nowrap mainstay-body-caption text-mainstay-dark-blue-70">
            {time}
          </div>
        </div>
        <div className="mainstay-header-h6 text-mainstay-dark-blue-80 ellipsis-text-content pt-1 pb-2">
          {name}
        </div>
      </div>
    </ContentCard>
  )
}

/**
 * Component for rendering multiple links in a dropdown.
 * Only the first link is visible and rest of them are included in the dropdown which is visible upon toggle.
 */
export const MultipleLinksViewer = ({
  linkProps,
}: {
  readonly linkProps: ILinkWithMiddleEllipsisProps[]
}) => {
  const [showAllLinks, setShowAllLinks] = React.useState(false)
  if (linkProps.length === 0) {
    return null
  }
  if (linkProps.length === 1) {
    return (
      <LinkWithMiddleEllipsis
        className="no-max-width w-100"
        {...linkProps[0]}
      />
    )
  }
  const [firstLink, otherLinks] = [linkProps[0], linkProps.slice(1)]
  return (
    <div>
      <PopoverComponent
        visible={showAllLinks}
        renderReference={() => (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <LinkWithMiddleEllipsis {...firstLink} />
              <span className="fs-0_8rem no-wrap px-2">{`(+${
                otherLinks.length
              } ${pluralize('other', otherLinks.length)})`}</span>
            </div>
            <div
              className={cls('view-more-links-dropdown', {
                'view-more-links-dropdown--active': showAllLinks,
              })}
              onClick={() => setShowAllLinks(prev => !prev)}>
              <CaretIcon className="caret-icon" />
            </div>
          </div>
        )}
        renderPopper={() => (
          <div className="popper-links-container">
            {otherLinks.map((link, index) => (
              <div key={link.target}>
                <LinkWithMiddleEllipsis {...link} />
                {index !== otherLinks.length - 1 && (
                  <hr className="my-2 border-mainstay-dark-blue-10" />
                )}
              </div>
            ))}
          </div>
        )}
        onClickOutside={() => setShowAllLinks(false)}
        popoverPlacement="bottom-end"
      />
    </div>
  )
}
