import { RootState as IState } from 'store/store'
import { createSelector } from 'reselect'
import { ICampaignDetailsPageRouteProps } from 'components/IRouteProps'
import { IWorkflowStepResult, PromptType } from 'store/campaign-scripts/reducer'
import { ITransitionType } from 'api/request'
import {
  ICampaign,
  ICampaignTriggerDetails,
  ICampaignByDialogDetails,
} from 'store/campaign-history/reducer'
import { WebData } from 'store/webdata'
import { ICampaignImportDetails } from 'store/campaign-details/reducer'

export const getSelectedCampaignId = (
  _state: IState,
  ownProps: ICampaignDetailsPageRouteProps
) => ownProps.match.params.id

export const getSelectedCampaign = (state: IState): WebData<ICampaign> =>
  state.campaignDetails.selectedCampaign

export const getSelectedTrigger = (
  state: IState
): WebData<ICampaignTriggerDetails> => state.campaignDetails.selectedTrigger

export const getSelectedTriggerId = (
  _state: IState,
  ownProps: ICampaignDetailsPageRouteProps
) => ownProps.match.params.id

export const getSelectedHelloPageCampaign = (
  state: IState
): WebData<ICampaignByDialogDetails> =>
  state.campaignDetails.selectedHelloPageCampaign

export const getWorkflowStepIds = (state: IState) =>
  state.campaignScripts.steps.allIds

export const getWorkflowSteps = (state: IState) =>
  state.campaignScripts.steps.byId

export const getAggregateWorkflowStepIds = (state: IState) =>
  state.campaignScripts.aggregateSteps.allIds

export const getAggregateWorkflowSteps = (state: IState) => {
  return state.campaignScripts.aggregateSteps.byId
}

export const getDownloadProgressById = (state: IState) =>
  state.campaignDetails.reportProgressByCampaignId

export const getReportDownloadProgress = createSelector(
  getSelectedCampaignId,
  getDownloadProgressById,
  (id, progressById) => {
    return progressById[id]
  }
)

export const getCampaignImportDetails = (
  state: IState
): WebData<ICampaignImportDetails> =>
  state.campaignDetails.campaignImportDetails

export const getResults: (
  state: IState
) => IWorkflowStepResult[] = createSelector(
  getWorkflowStepIds,
  getWorkflowSteps,
  (stepIds, stepdsById) => {
    const results: IWorkflowStepResult[] = []
    stepIds.forEach(id => {
      const step = stepdsById[id]
      // do not report results of workflow_step where promptType == 'Auto'
      if (step == null || step.promptType === PromptType.auto) {
        return
      }
      results.push({
        id: step.id,
        count: step.dialogStateSentToCount ?? 0,
        promptType: step.promptType,
        prompt: step.prompt,
        personalizedPrompt: step.personalizedPrompt,
        choices: step.choiceResponseCounts ?? null,
        totalResponseCount: step.totalResponseCount ?? 0,
      })
    })
    return results
  }
)

export const getAggregateResults: (
  state: IState
) => IWorkflowStepResult[] = createSelector(
  getAggregateWorkflowStepIds,
  getAggregateWorkflowSteps,
  (stepIds, stepsById) => {
    const results: IWorkflowStepResult[] = []
    stepIds.forEach(id => {
      const step = stepsById[id]
      // do not report results of workflow_step where promptType == 'Auto'
      if (step == null || step.promptType === PromptType.auto) {
        return
      }
      results.push({
        id: step.id,
        count: step.dialogStateSentToCount ?? 0,
        promptType: step.promptType,
        prompt: step.prompt,
        personalizedPrompt: step.personalizedPrompt,
        choices: step.choiceResponseCounts ?? null,
        totalResponseCount: step.totalResponseCount ?? 0,
      })
    })
    return results
  }
)

export const getWorkflowStepById = createSelector(
  getWorkflowSteps,
  (_state: IState, ownProps: { workflowStepId: string }) =>
    ownProps.workflowStepId,
  (workflowSteps, id) => workflowSteps[id]
)

export const getAggregateWorkflowStepById = createSelector(
  getAggregateWorkflowSteps,
  (_state: IState, ownProps: { workflowStepId: string }) =>
    ownProps.workflowStepId,
  (workflowSteps, id) => {
    return workflowSteps[id]
  }
)

export const getWorkflowInitialState = (state: IState): string | null => {
  return (
    state.campaignDetails.workflow &&
    state.campaignDetails.workflow.initialState
  )
}
export const isLinkBranch = (
  state: IState,
  ownProps: {
    parentWorkflowStepId?: string
    branchName?: ITransitionType
  }
): boolean => {
  if (!ownProps.parentWorkflowStepId || !ownProps.branchName) {
    return false
  }
  const linkStep =
    state.campaignScripts.steps.linkBranches[ownProps.parentWorkflowStepId]
  return !!(linkStep && linkStep[ownProps.branchName])
}
