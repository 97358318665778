import * as React from 'react'
import ClassNames from 'classnames'
import { range, isInteger } from 'lodash'
import { Link } from 'react-router-dom'
import * as Raven from '@sentry/browser'
import { DEV } from 'const/settings'
import { LocationDescriptor } from 'history'
import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'

interface IPagerNumberButton {
  name?: string
  url: LocationDescriptor
  num: number
  current?: boolean
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  eventLocation?: string
  eventObject?: string
  eventAction?: EventAction
}

const PagerNumberButton = ({
  num,
  name,
  url,
  current,
  disabled,
  onClick,
  eventAction,
  eventLocation,
  eventObject,
}: IPagerNumberButton) => (
  <EventTrackerAttrAdder
    eventAction={eventAction}
    eventLocation={eventLocation}
    eventObject={eventObject}>
    <li
      title={String(num)}
      className={ClassNames('page-item', {
        active: !!current,
        disabled,
      })}>
      <Link to={url} className="page-link" onClick={onClick} data-page={num}>
        {name || num}
      </Link>
    </li>
  </EventTrackerAttrAdder>
)

interface IPagerProps {
  first?: number
  current: number
  last: number
  urlFormat: (x: string) => LocationDescriptor
  size?: 'sm' | 'md' | 'lg'
  className?: string
  margin?: number
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  eventLocation?: string
  eventObject?: string
  eventAction?: EventAction
}

const MIN_MARGIN_SIZE = 4

export const Pager = ({
  first = 1,
  current,
  last,
  urlFormat,
  size = 'md',
  className,
  margin = MIN_MARGIN_SIZE,
  onClick,
  eventLocation,
  eventObject,
  eventAction,
}: IPagerProps) => {
  if (DEV && margin < 0) {
    throw Error(`Invalid arg: margin ($(margin)). value must be > 0`)
  }

  current = Math.min(current, last)

  margin = Math.max(MIN_MARGIN_SIZE, margin)

  if (!isInteger(first)) {
    Raven.captureMessage(
      `page number 'first' must be integer value. Found '${first}'.`
    )
  }
  if (!isInteger(current)) {
    Raven.captureMessage(
      `page number 'current' must be integer value. Found '${current}'.`
    )
  }
  if (!isInteger(last)) {
    Raven.captureMessage(
      `page number 'last' must be integer value. Found '${last}'.`
    )
  }
  let leftPos = Math.max(current - margin, 1)
  let rightPos = Math.min(current + margin, last)

  /* Pad left and right with any missing margin to fill out pager.
  We want to have a pager with with 2 * margin, if allowed by the page count.
  At the ends of our page range we can have a side that has less than the margin in
  numbers. If possible, we want to pad the opposite side to fill out pager to 2 * margin.
   */
  if (rightPos - current < margin) {
    margin = margin - (rightPos - current)
    leftPos = Math.max(leftPos - margin, first)
  } else if (current - leftPos < margin) {
    margin = margin - (current - leftPos)
    rightPos = Math.min(rightPos + margin, last)
  }

  const previousPage = Math.max(current - 1, first)
  const nextPage = Math.min(current + 1, last)

  const getEventObject = (eventObject?: string, prefix?: string) =>
    eventObject && prefix ? `${prefix} ${eventObject}` : undefined

  const PagerNumberButtons = range(leftPos, rightPos + 1).map(num => (
    <PagerNumberButton
      key={num}
      num={num}
      url={urlFormat(String(num))}
      current={num === current}
      onClick={onClick}
      eventLocation={eventLocation}
      eventAction={eventAction}
      eventObject={getEventObject(eventObject, 'page number')}
    />
  ))
  const classes = className != null ? className : ''
  return (
    <nav className={`d-flex justify-content-center ${classes}`}>
      <ul
        className={ClassNames('pagination', {
          [`pagination-${size}`]: size != null,
        })}>
        <PagerNumberButton
          name="First"
          num={first}
          url={urlFormat(String(first))}
          disabled={first === current}
          onClick={onClick}
          eventLocation={eventLocation}
          eventAction={eventAction}
          eventObject={getEventObject(eventObject, 'first page')}
        />
        <PagerNumberButton
          name="Previous"
          num={previousPage}
          url={urlFormat(String(previousPage))}
          disabled={previousPage === current}
          onClick={onClick}
          eventLocation={eventLocation}
          eventAction={eventAction}
          eventObject={getEventObject(eventObject, 'previous page')}
        />
        {PagerNumberButtons}
        <PagerNumberButton
          name="Next"
          num={nextPage}
          url={urlFormat(String(nextPage))}
          disabled={nextPage === current}
          onClick={onClick}
          eventLocation={eventLocation}
          eventAction={eventAction}
          eventObject={getEventObject(eventObject, 'next page')}
        />
        <PagerNumberButton
          name="Last"
          num={last}
          url={urlFormat(String(last))}
          disabled={last === current}
          onClick={onClick}
          eventLocation={eventLocation}
          eventAction={eventAction}
          eventObject={getEventObject(eventObject, 'last page')}
        />
      </ul>
    </nav>
  )
}
