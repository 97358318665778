import {
  ActionType,
  createAsyncAction,
  createStandardAction,
} from 'typesafe-actions'
import { IContactLabel } from 'store/contacts/reducer'
import { IPaginatedResponse } from 'api/response'

export const searchContactLabels = createAsyncAction(
  '@MASCOT/CONTACT_LABELS/SEARCH_LABELS_REQUEST',
  '@MASCOT/CONTACT_LABELS/SEARCH_LABELS_END',
  '@MASCOT/CONTACT_LABELS/SEARCH_LABELS_FAIL'
)<void, IPaginatedResponse<IContactLabel[]>, void>()

export const updateContactLabel = createAsyncAction(
  '@MASCOT/CONTACT_LABELS/UPDATE_LABEL_REQUEST',
  '@MASCOT/CONTACT_LABELS/UPDATE_LABEL_END',
  '@MASCOT/CONTACT_LABELS/UPDATE_LABEL_FAIL'
)<string, IContactLabel, void>()

export const deleteContactLabel = createAsyncAction(
  '@MASCOT/CONTACT_LABELS/DELETE_LABEL_REQUEST',
  '@MASCOT/CONTACT_LABELS/DELETE_LABEL_END',
  '@MASCOT/CONTACT_LABELS/DELETE_LABEL_FAIL'
)<string, string, void>()

export const updateSearchQuery = createStandardAction(
  '@MASCOT/CONTACT_LABELS/UPDATE_SEARCH_QUERY'
)<string>()

export const updateOrdering = createStandardAction(
  '@MASCOT/CONTACT_LABELS/UPDATE_ORDERING'
)<'createdAt' | '-createdAt' | 'text' | '-text'>()

export const updatePageNumber = createStandardAction(
  '@MASCOT/CONTACT_LABELS/UPDATE_PAGE_NUMBER'
)<number>()

export type IContactLabelActions =
  | ActionType<typeof searchContactLabels>
  | ActionType<typeof updateContactLabel>
  | ActionType<typeof deleteContactLabel>
  | ActionType<typeof updateSearchQuery>
  | ActionType<typeof updateOrdering>
  | ActionType<typeof updatePageNumber>
