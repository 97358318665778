import moment from 'moment'
import pluralize from 'pluralize'

/*
Description of matching groups in the following dynamic date regex:
Group 1: Year (e.g. "2021", "****", "THISYEAR")
Group 2: Month (e.g. "10", "5", "THISMONTH")
Group 3: Day (e.g. "22", "1", "THISDAY")
Optional Group 4: Operator (e.g. "+", "-")
Optional Group 5: Offset Value (e.g. "0", "3")
*/
export const DYNAMIC_DATE_REGEX = /(\d{4}|\*{4}|THISYEAR)-(\d{1,2}|THISMONTH)-(\d{1,2}|THISDAY)(?: *([-\+]) *(\d*))?/

export enum DayPronouns {
  TODAY = 'Today',
}

export enum RelativeDateConsts {
  YEAR = 'THISYEAR',
  MONTH = 'THISMONTH',
  DAY = 'THISDAY',
  ANY_YEAR = '****',
}

export interface IDynamicDate {
  year?: string
  month: string
  day: string
  offset: number
}

export const shouldIgnoreYear = (value?: string | IDynamicDate | Date) => {
  if (!value || value instanceof Date) {
    return false
  }
  if (typeof value === 'string') {
    return value.startsWith(RelativeDateConsts.ANY_YEAR)
  }
  return value.year === RelativeDateConsts.ANY_YEAR
}

function getOffsetString(offset: number) {
  if (offset === 0) {
    return ''
  } else if (offset > 0) {
    return `${offset} ${pluralize('day', offset)} after `
  } else {
    return `${Math.abs(offset)} ${pluralize('day', Math.abs(offset))} before `
  }
}

export const getDayPronoun = (dynamicDate: IDynamicDate) => {
  return dynamicDate.month === RelativeDateConsts.MONTH &&
    dynamicDate.day === RelativeDateConsts.DAY
    ? DayPronouns.TODAY
    : undefined
}

export const getDisplayValue = (dynamicDate: IDynamicDate) => {
  /*
    Returns the stringified value of the dynamic date that is displayed in the UI.
    Eg: 
      2024-10-11
      10-11 (any year)
      Today
      Today (any year)
      3 days after Today (any year)
      4 days before Today
  */
  const pronoun = getDayPronoun(dynamicDate)

  const anyYearSuffix = !dynamicDate.year ? ' (any year)' : ''
  if (pronoun) {
    return `${getOffsetString(dynamicDate.offset)}${pronoun}${anyYearSuffix}`
  }
  return `${dynamicDate.year ? `${dynamicDate.year}-` : ''}${
    dynamicDate.month
  }-${dynamicDate.day}${anyYearSuffix}`
}

export const getDynamicDate = (
  date: string | Date,
  ignoreYear?: boolean
): IDynamicDate => {
  if (typeof date === 'string') {
    const matches = new RegExp(DYNAMIC_DATE_REGEX).exec(date)
    if (matches && matches.length >= 6) {
      return {
        offset: getOffset(matches[4], matches[5]),
        year:
          ignoreYear || matches[1] === RelativeDateConsts.ANY_YEAR
            ? undefined
            : matches[1],
        month: matches[2],
        day: matches[3],
      }
    }
  }

  const momentDate = moment(date)
  return {
    offset: 0,
    month: momentDate.format('MM'),
    day: momentDate.format('DD'),
    year: ignoreYear ? undefined : momentDate.format('YYYY'),
  }
}

export const serializeDynamicDate = (
  dynamicDate: IDynamicDate,
  ignoreYear?: boolean
) => {
  /*
    Returns a serialized date in a format that's accepted by the backend.
    Eg:
      Parsed date                           Display value
      2024-10-11                            2024-10-11
      ****-10-11                            [10-11 (any year)]
      THISYEAR-THISMONTH-THISDAY            [Today]
      ****-THISMONTH-THISDAY                [Today (any year)]
      THISYEAR-THISMONTH-THISDAY + 3        [3 days after Today]
      THISYEAR-THISMONTH-THISDAY - 4        [4 days before Today]
  */
  if (ignoreYear) {
    dynamicDate.year = RelativeDateConsts.ANY_YEAR
  }
  if (!ignoreYear && getDayPronoun(dynamicDate)) {
    dynamicDate.year = RelativeDateConsts.YEAR
  }
  const { year, month, day, offset } = dynamicDate
  const offsetString =
    offset !== 0 ? (offset > 0 ? ` + ${offset}` : ` - ${Math.abs(offset)}`) : ''

  return `${year || RelativeDateConsts.ANY_YEAR}-${month}-${day}${offsetString}`
}

const getOffset = (operator?: string, value?: string | number): number => {
  if (!operator || !value) {
    return 0
  }
  return operator === '-' ? -Number(value) : Number(value)
}
