import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'store/store'
import { SCRIPT_LIBRARY_ORG_ID } from 'const/settings'
import { getCurrentMessagingService } from 'store/triage/profile/selectors'

interface IScriptLibraryOnlyOwnProps {
  fallback?: React.ReactNode
  render: () => React.ReactNode
}
interface IScriptLibraryConnectProps {
  isScriptLibraryUser: boolean
}

const ScriptLibraryShowUnconnected = (
  props: IScriptLibraryOnlyOwnProps & IScriptLibraryConnectProps
) => {
  if (!props.isScriptLibraryUser) {
    if (props.fallback) {
      return <>{props.fallback}</>
    } else {
      return null
    }
  }
  return <>{props.render()}</>
}

const ScriptLibraryHideUnconnected = (
  props: IScriptLibraryOnlyOwnProps & IScriptLibraryConnectProps
) => {
  if (props.isScriptLibraryUser) {
    if (props.fallback) {
      return <>{props.fallback}</>
    } else {
      return null
    }
  }
  return <>{props.render()}</>
}

const mapStateToProps = (
  state: RootState,
  ownProps: IScriptLibraryOnlyOwnProps
) => {
  return {
    ...ownProps,
    isScriptLibraryUser:
      state.triage.application.profile &&
      getCurrentMessagingService(state) === SCRIPT_LIBRARY_ORG_ID,
  }
}

export const ScriptLibraryShow = connect(mapStateToProps)(
  ScriptLibraryShowUnconnected
)
export const ScriptLibraryHide = connect(mapStateToProps)(
  ScriptLibraryHideUnconnected
)
