import * as React from 'react'
import { Helmet } from 'react-helmet'
import { NavLink, INavLinkProps, Link } from 'util/routing'
import CopyToClipboard from 'react-copy-to-clipboard'
import classnames from 'classnames'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { MainstayIcon } from 'components/Icons/MainstayIcon/MainstayIcon'
import { CommentIcon } from 'components/Icons/CommentIcon/CommentIcon'
import { DASHBOARD, CONTACTS } from 'const/routes'
import { sendEvent } from 'api/events'
import Loader from 'components/Loader/Loader'
import NoMatch from 'page/NoMatch'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import PopoutMenu from 'components/PopoutMenu/PopoutMenu'
import AdmithubUserMenu from 'components/AdmithubUserMenu/AdmithubUserMenu'
import { connect } from 'react-redux'
import { useSelector, useDispatch, useInstitutionId } from 'util/hooks'
import { Dispatch, RootState } from 'store/store'
import { match as Match } from 'react-router-dom'
import {
  fetchProfileAsync,
  switchCurrentInstitutionAsync,
} from 'store/triage/profile/thunks'
import { Badge } from 'components/Badge/Badge'
import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import SettingsMenuConnected from 'components/SettingsMenu/SettingsMenuConnected'
import UserMenuConnected from 'components/UserMenu/UserMenuConnected'
import UserIconConnected from 'components/UserIcon/UserIconConnected'

import 'page/NavBarPage.scss'
import { Location } from 'history'
import { fetchingInstitutions } from 'store/institution/thunks'
import { toggleOrgSwitcher } from 'store/page/pageReducer'
import { shouldShowOrgToggle } from 'store/page/selectors'
import { BotStatus, getInstitutions } from 'store/institution/selectors'
import { sortBy, isEqual } from 'lodash'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import {
  IPermissions,
  PermissionsContext,
  usePermissionsContext,
} from 'util/permissions/PermissionsContext'
import { getUserProfile } from 'store/triage/profile/selectors'
import { IUserState } from 'store/triage/profile/reducer'
import PermissionsGuard from 'util/permissions/PermissionsGuard'
import { PERMISSIONS } from 'util/permissions/permissions'
import { AudienceIcon } from 'components/Icons/AudienceIcon/AudienceIcon'
import { Button } from 'components/Button/Button'
import { InsightsHubIcon } from 'components/InsightsHubIcon/InsightsHubIcon'
import { GuestPageContext } from 'components/TrendsV2/sharedUtils'
import { months } from 'util/datetime'
import { BotStatusCircle } from 'components/Circle/Circle'
import { InstitutionListItem } from 'api'

const SortedBots = ({
  orgs,
  currentOrg,
}: {
  orgs: InstitutionListItem[]
  currentOrg: Omit<InstitutionListItem, 'isActive' | 'botStatus'>
}) => (
  <>
    <OrgsByBotStatus
      orgs={orgs.filter(o => o.botStatus === BotStatus.internal)}
      currentOrg={currentOrg}
    />
    <OrgsByBotStatus
      orgs={orgs.filter(o => o.botStatus === BotStatus.active)}
      currentOrg={currentOrg}
    />
    <OrgsByBotStatus
      orgs={orgs.filter(o => o.botStatus === BotStatus.retired)}
      currentOrg={currentOrg}
    />
  </>
)

const OrgsByBotStatus = ({
  orgs,
  currentOrg,
}: {
  orgs: InstitutionListItem[]
  currentOrg: Omit<InstitutionListItem, 'isActive' | 'botStatus'>
}) => {
  const dispatch = useDispatch()

  return (
    <>
      {sortBy(orgs, x => x.displayName?.toLowerCase()).map(o => (
        <div key={o.id} className="mb-2 d-flex align-items-center">
          <BotStatusCircle botStatus={o.botStatus} />
          <Link
            to="#"
            className={classnames('link-unstyled pointer', {
              'font-weight-bold text-white': o.id === currentOrg.id,
              'font-italic text-mainstay-gray-labels':
                o.botStatus === BotStatus.retired,
            })}
            onClick={e => {
              e.preventDefault()
              switchCurrentInstitutionAsync({
                currentInstitution: currentOrg.id,
                newInstitution: o.id,
              })(dispatch)
              dispatch(toggleOrgSwitcher(false))
            }}>
            {o.displayName}
          </Link>
        </div>
      ))}
    </>
  )
}

const OrgSwitcher = () => {
  const dispatch = useDispatch()
  const currentOrg = useSelector(state => {
    return state.triage.application.profile.currentUser.institution
  })
  const orgs = useSelector(getInstitutions)

  const shouldShowSwitcher = useSelector(shouldShowOrgToggle)

  const switcherIsOpen = useSelector(
    state => state.triage.ui.page.orgSwitcherOpen
  )
  const { isGuest, clickToLogin } = React.useContext(GuestPageContext)
  const isSkeleton = React.useContext(SkeletonNavBarPageContext)

  React.useEffect(() => {
    if (!isSkeleton && !isGuest) {
      fetchingInstitutions(dispatch)
    }
  }, [isSkeleton, isGuest, dispatch])

  if (isGuest) {
    return <DummyOrgSwitcher onClick={clickToLogin} />
  }

  return (
    <li className="position-relative">
      <a
        className={classnames('global-nav-link no-padding position-relative', {
          pointer: shouldShowSwitcher,
        })}
        onClick={() => {
          if (shouldShowSwitcher) {
            dispatch(toggleOrgSwitcher(!switcherIsOpen))
          }
        }}>
        {shouldShowSwitcher && (
          <span className="nav-label">Switch Organization</span>
        )}
        <h6 className="color-mainstay-gray no-padding font-weight-bold text-center d-flex justify-content-center align-items-center">
          {currentOrg.abbr}
        </h6>
      </a>
      {switcherIsOpen && (
        <div className="popout-menu position-absolute z-index-100 popout-org-switcher d-flex flex-column">
          <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
            <h5 className="text-mainstay-almost-black-30 fs-16px mb-0">
              Switch Organization
            </h5>
            <AHIcon
              name="close"
              className="pointer"
              onClick={() => dispatch(toggleOrgSwitcher(false))}
            />
          </div>
          <div className="popout-org-switcher-menu scroll-y p-3">
            <AdmithubOnly>
              <div className="mb-2">
                <Link
                  to="/organizations"
                  className="link-unstyled pointer d-flex align-items-center"
                  onClick={() => dispatch(toggleOrgSwitcher(false))}>
                  Switch Organization
                </Link>
              </div>
            </AdmithubOnly>
            <SortedBots orgs={orgs} currentOrg={currentOrg} />
          </div>
        </div>
      )}
    </li>
  )
}

const DummyOrgSwitcher = ({ onClick }: { onClick?: () => void }) => {
  const orgAbbr = useSelector(state => {
    return state.triage.application.profile.currentUser.institution.abbr
  })
  return (
    <li className="position-relative">
      <div
        className="global-nav-link no-padding position-relative pointer"
        onClick={onClick}>
        <h6 className="color-mainstay-gray no-padding font-weight-bold text-center d-flex justify-content-center align-items-center">
          {orgAbbr}
        </h6>
      </div>
    </li>
  )
}

interface IListLink extends INavLinkProps {
  readonly icon: JSX.Element
  readonly rawLink?: boolean
  readonly exact?: boolean
  readonly disableActive?: boolean
  readonly to: string
}

function ListLink({
  to,
  exact = false,
  children,
  icon,
  rawLink,
  className,
  disableActive,
  ...rest
}: IListLink) {
  const classNames = classnames('global-nav-link', className)
  const { isGuest, clickToLogin } = React.useContext(GuestPageContext)
  if (isGuest) {
    return (
      <li className="pointer">
        <div onClick={clickToLogin} style={rest.style} className={classNames}>
          <span className="nav-icon">{icon}</span>
          {children && <span className="nav-label">{children}</span>}
        </div>
      </li>
    )
  }
  return (
    <li className="pointer">
      <NavLink
        {...rest}
        to={to}
        exact={exact}
        rawLink={rawLink}
        activeClassName={disableActive ? '' : 'global-nav-link-active'}
        className={classNames}>
        <span className="nav-icon">{icon}</span>
        {children && <span className="nav-label">{children}</span>}
      </NavLink>
    </li>
  )
}

const NavFooter = () => (
  <ul className="list-unstyled w-100">
    <AdmithubOnly>
      <CurrentPagePermalink />
      <PopoutMenu
        label="Admin Settings"
        icon={
          <Badge color="warning" className="py-1 px-2">
            <div className="caption m-0">M</div>
          </Badge>
        }>
        <AdmithubUserMenu />
      </PopoutMenu>
    </AdmithubOnly>
    <PopoutMenu
      label="Settings"
      icon={<AHIcon name="settings" />}
      isActive={
        location.pathname.startsWith('/settings') ||
        location.pathname.startsWith('/users-v2')
      }>
      <SettingsMenuConnected />
    </PopoutMenu>
    <PopoutMenu
      label="Account"
      icon={<UserIconConnected size="sm" outline={true} />}>
      <UserMenuConnected />
    </PopoutMenu>
  </ul>
)

const CurrentPagePermalink = () => {
  const currentInstitutionId = useInstitutionId()
  const redirectURL = `${document.location.pathname}${document.location.search}`
  const switchURL = `${document.location.protocol}//${
    document.location.host
  }/switch/${currentInstitutionId}?redirect=${encodeURIComponent(redirectURL)}`

  // Sometimes when a drawer is open you cannot click this permalink button, so put it in a global var
  window.mainstayPermalink = switchURL

  return (
    <CopyToClipboard
      text={switchURL}
      onCopy={() => {
        toast(
          'Copied a permalink for this page/institution to your clipboard!',
          { type: 'success' }
        )
      }}>
      <Button className="global-nav-link global-nav-link-no-transition golden-link">
        <span className="nav-icon">
          <AHIcon name="link" className="ah-only-color" />
        </span>
        <span className="nav-label">Copy Permalink to this page</span>
      </Button>
    </CopyToClipboard>
  )
}

export function isCampaignsTabActive<T>(_: Match<T>, location: Location) {
  return (
    location.pathname.startsWith('/campaigns') ||
    location.pathname.startsWith('/schedule-campaign')
  )
}

function isCalendarTabActive<T>(_: Match<T>, location: Location) {
  return location.pathname.startsWith('/calendar')
}

function isScriptTabActive<T>(_: Match<T>, location: Location) {
  return (
    location.pathname.startsWith('/campaign-scripts') ||
    location.pathname.startsWith('/campaign-script-library')
  )
}

export function isKnowledgeBaseTabActive<T>(_: Match<T>, location: Location) {
  return (
    location.pathname.startsWith('/knowledge') ||
    location.pathname.startsWith('/review-items') ||
    location.pathname.startsWith('/commands') ||
    location.pathname.startsWith('/special-response') ||
    location.pathname.startsWith('/attributes')
  )
}

export function isAudiencesActive<T>(_: Match<T>, location: Location) {
  return (
    location.pathname.startsWith('/contacts') ||
    location.pathname.startsWith('/audiences')
  )
}

export function isIncomingMessagesTabActive<T>(
  _: Match<T>,
  location: Location
) {
  return location.pathname.includes('/incoming-messages')
}

const NavBarPageSideBar = () => {
  const { isGuest } = React.useContext(GuestPageContext)
  const onOliClick = () => {
    // log event to database
    sendEvent('click:navbar:ah_icon')
  }

  const now = new Date()
  const year = now.getFullYear().toString()
  const month = months[now.getMonth()].toLocaleLowerCase()

  return (
    <aside className="nav-side-bar bg-mainstay-dark-blue">
      <ul className="list-unstyled">
        <ListLink
          onClick={onOliClick}
          to="/"
          exact
          className="ah-logo-main"
          icon={<MainstayIcon />}
          disableActive={true}
        />
        <OrgSwitcher />
      </ul>
      <div className="sidebar-items-container d-flex flex-direction-column align-items-center justify-content-center">
        <ul className="list-unstyled">
          <span className="d-block">
            <ListLink to={DASHBOARD.INDEX} icon={<InsightsHubIcon />}>
              Insights Hub
            </ListLink>
          </span>
          <PermissionsGuard permission={PERMISSIONS.CONVERSATION.VIEW}>
            <ListLink
              to="/conversations-v2"
              icon={<CommentIcon className="nav-side-bar-icon" />}>
              Conversations
            </ListLink>
          </PermissionsGuard>
          <PermissionsGuard permission={PERMISSIONS.UNDERSTANDING.VIEW}>
            <ListLink
              to="/knowledge"
              icon={<AHIcon name="knowledge-base" />}
              isActive={isKnowledgeBaseTabActive}>
              Knowledge Base
            </ListLink>
          </PermissionsGuard>
          <ListLink
            to={`/calendar?month=${month}&year=${year}`}
            isActive={isCalendarTabActive}
            icon={<AHIcon name="calendar_day" />}>
            Calendar
          </ListLink>
          <PermissionsGuard permission={PERMISSIONS.SCRIPT.VIEW}>
            <ListLink
              to="/campaign-scripts"
              isActive={isScriptTabActive}
              icon={<AHIcon name="comment" />}>
              Scripts
            </ListLink>
          </PermissionsGuard>
          <ListLink
            to="/campaigns"
            isActive={isCampaignsTabActive}
            icon={<AHIcon name="campaign" />}>
            Campaigns
          </ListLink>
          <AudienceContactLink />
        </ul>
      </div>
      <div className="nav-side-bar-footer">{!isGuest && <NavFooter />}</div>
    </aside>
  )
}

const AudienceContactLink = () => {
  const { hasPermission } = usePermissionsContext()
  const canSeeAudiences = hasPermission(PERMISSIONS.AUDIENCE.VIEW)
  const canSeeContacts = hasPermission(PERMISSIONS.CONTACT.VIEW)
  let url = CONTACTS.AUDIENCES

  if (!canSeeAudiences && !canSeeContacts) {
    return (
      <PermissionsGuard permission={PERMISSIONS.CONTACT.VIEW}>
        <ListLink
          to={CONTACTS.AUDIENCES}
          icon={
            <AudienceIcon
              height={24}
              width={24}
              className="fill-mainstay-cream-30"
            />
          }
          isActive={isAudiencesActive}>
          Audiences & Contacts
        </ListLink>
      </PermissionsGuard>
    )
  }

  if (!canSeeAudiences && canSeeContacts) {
    url = CONTACTS.INDEX
  }

  return (
    <ListLink
      to={url}
      icon={
        <AudienceIcon
          height={24}
          width={24}
          className="fill-mainstay-cream-30"
        />
      }
      isActive={isAudiencesActive}>
      Audiences & Contacts
    </ListLink>
  )
}

interface INavBarProps {
  readonly title: string
  readonly fetch: () => void
  readonly pageMainClassName?: string
  readonly loading?: boolean
  readonly error?: boolean
  readonly errorMsg?: string
  readonly className?: string
  readonly notFound?: boolean
  readonly scrollY?: boolean
  readonly user?: IUserState
  readonly skipFetchUser?: boolean
}

class NavBarPage extends React.PureComponent<INavBarProps> {
  static contextType: React.Context<IPermissions> = PermissionsContext

  static defaultProps = {
    errorMsg: 'Error fetching data. Please try again.',
    border: true,
    className: 'm-3 p-3 border rounded shadow-sm',
    scrollY: false,
  }

  renderChildren = () => {
    const {
      notFound,
      loading,
      error,
      errorMsg,
      className,
      children,
      scrollY,
    } = this.props
    if (notFound) {
      return <NoMatch />
    }
    if (loading) {
      return (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Loader />
        </div>
      )
    }

    if (error) {
      return (
        <div className="h4 p-5 text-center text-danger text-muted">
          {errorMsg}
        </div>
      )
    }

    const childrenNode = className ? (
      <section className={className}>{children}</section>
    ) : (
      children
    )

    if (scrollY) {
      return (
        <div className="scroll-y h-100">
          <section className={className}>{childrenNode}</section>
        </div>
      )
    }

    return childrenNode
  }

  componentDidMount() {
    if (this.props.skipFetchUser) {
      return
    }
    this.props.fetch()
  }

  componentDidUpdate(prevProps: INavBarProps) {
    const updatedPermissions = this.props.user?.permissions?.sort() || []
    const currentPermissions = prevProps.user?.permissions?.sort() || []
    /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
    const context = this.context as IPermissions
    const contextPermissions = context.permissions
      ? context.permissions.sort()
      : undefined

    if (
      !isEqual(updatedPermissions, currentPermissions) &&
      !isEqual(updatedPermissions, contextPermissions)
    ) {
      context.updatePermissions(updatedPermissions)
    }
  }

  render() {
    const { title, pageMainClassName } = this.props
    return (
      <>
        <a
          href="#maincontent"
          className="bg-white border p-1 show-on-focus text-dark z-index-1000">
          Skip to main content
        </a>
        <main className={classnames('d-flex h-100', pageMainClassName)}>
          <Helmet title={title} defer={false} />
          <NavBarPageSideBar />
          <div
            className="main-container flex-grow-1 d-flex flex-column min-width-0 position-relative"
            id="maincontent">
            <ErrorBoundary>{this.renderChildren()}</ErrorBoundary>
          </div>
        </main>
      </>
    )
  }
}

const SkeletonNavBarPageContext = React.createContext<boolean>(false)

/**
 * When fetching chunks we want to display a skeleton of the page so when don't
 * have a major visual distruption once the chunk loads.
 */
export const SkeletonNavBarPage = () => (
  <SkeletonNavBarPageContext.Provider value={true}>
    <NavBarPage title="" loading scrollY className="" fetch={() => undefined} />
  </SkeletonNavBarPageContext.Provider>
)

export type IContainerProps = Omit<INavBarProps, 'fetch'>

const mapStateToProps = (state: RootState) => {
  return {
    user: getUserProfile(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetch: () => {
    fetchProfileAsync()(dispatch)
  },
})

const ConnectedNavBarPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBarPage)

export default ConnectedNavBarPage
