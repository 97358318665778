import { connect } from 'react-redux'
import { RootState as IState } from 'store/store'
import {
  getAuthedUserFirstName,
  getAuthedUserLastName,
} from 'store/auth/selectors'
import { SimpleUserIcon } from 'components/UserIcon/UserIcon'

const mapStateToProps = (state: IState) => ({
  firstName: getAuthedUserFirstName(state),
  lastName: getAuthedUserLastName(state),
})

export default connect(mapStateToProps)(SimpleUserIcon)
