import {
  createAsyncAction,
  ActionType,
  createStandardAction,
} from 'typesafe-actions'
import { IUserResponseData } from 'api/response'
import { push } from 'connected-react-router'
import * as ROUTES from 'const/routes'
import { Location } from 'history'

export const login = createStandardAction('@@MASCOT/AUTH/LOGIN')<
  IUserResponseData
>()

export const setLoginMessage = createStandardAction(
  '@@MASCOT/AUTH/SET_LOGIN_MESSAGE'
)<string>()

export const loggingOut = createAsyncAction(
  '@@MASCOT/AUTH/LOGOUT_START',
  '@@MASCOT/AUTH/LOGOUT_SUCCESS',
  '@@MASCOT/AUTH/LOGOUT_FAILURE'
)<undefined, undefined, undefined>()

export function redirectToLoginWithHistory(currentLocation: Location) {
  return push({
    pathname: ROUTES.AUTH.LOGIN,
    // the `from` state is used to redirect to the user's current page on
    // login.
    state: { from: currentLocation },
  })
}

export const changePassword = createAsyncAction(
  '@@MASCOT/AUTH/CHANGE_PASSWORD_START',
  '@@MASCOT/AUTH/CHANGE_PASSWORD_SUCCESS',
  '@@MASCOT/AUTH/CHANGE_PASSWORD_FAILURE'
)<undefined, { detail: string | undefined }, undefined>()

export const pulsing = createAsyncAction(
  '@@MASCOT/AUTH/PULSING_START',
  '@@MASCOT/AUTH/PULSING_SUCCESS',
  '@@MASCOT/AUTH/PULSING_FAILURE'
)<undefined, undefined, undefined>()

export type IAuthActions =
  | ActionType<typeof login>
  | ActionType<typeof loggingOut>
  | ActionType<typeof changePassword>
  | ActionType<typeof setLoginMessage>
  | ActionType<typeof pulsing>
