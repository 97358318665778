import { RootState as IState } from 'store/store'
import { isLoading } from 'store/webdata'
import * as _ from 'lodash'
import {
  IContactAttributeDraftEditor,
  IInstitutionAttributeDraftEditor,
} from 'store/knowledgeSeeder/reducer'
import { IContactField } from 'store/personalization/contactAttributes/selectors'

export enum InstitutionAttributeType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  URL = 'URL',
  NUMBER = 'NUMBER',
}

export interface IInstitutionAttribute {
  id: number
  field: string
  type: InstitutionAttributeType
  value: string | number | Date
  locationsUsed?: number
  onAttrRowClick?: (attrId: number) => void
}

export interface IInstitutionAttributes extends Array<IInstitutionAttribute> {}
export interface IAttributesById {
  institution: {
    [id: number]: IInstitutionAttributeDraftEditor
  }
  contact: {
    [id: number]: IContactAttributeDraftEditor
  }
  toplevel: {
    [field: string]: IContactField
  }
}
export interface IAttributeNamesById {
  institution?: {
    [id: number]: string
  } | null
  contact?: {
    [id: number]: string
  } | null
  toplevel?: {
    [field: string]: string
  } | null
}
export type IAttributeTypeCounter = {
  -readonly [key in keyof typeof InstitutionAttributeType]: number
}
export const getAllInstitutionAttributes = (state: IState) => {
  return state.personalization.allInstitutionAttributes
}
export const getInstitutionAttributesTable = (state: IState) => {
  return state.personalization.institutionAttributesTable
}
export const getAllInstitutionAttributesAreLoading = (state: IState) =>
  isLoading(state.personalization.allInstitutionAttributes)
export const getInstitutionAttributesTableisLoading = (state: IState) =>
  isLoading(state.personalization.institutionAttributesTable)
export const getCountInstitutionAttributes = (state: IState) =>
  state.personalization.countInstitutionAttributes
export const getCountsOfInstAttributesByType = (
  state: IState
): IAttributeTypeCounter => {
  return state.personalization.countInstitutionAttributesByType
}
