import { RootState as IState } from 'store/store'
import { TransportId } from 'store/transport'

export const getActiveContactLoading = (state: IState) => {
  return state.contacts.currentEditContactLoading
}

export const getAttributeValuesLoading = (state: IState) => {
  return state.contacts.loadingContactAttributeValues
}

export const getActiveContact = (state: IState) => {
  return state.contacts.currentEditContact
}

export const getActiveContactId = (state: IState) => {
  return (
    state.contacts.currentEditContact && state.contacts.currentEditContact.id
  )
}

export const getActiveContactNotes = (state: IState) => {
  if (!state.contacts.currentEditContact) {
    return []
  } else {
    return state.contacts.currentEditContact.notes.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
  }
}

export const getCreatingNote = (state: IState) => {
  return Boolean(state.contacts.ui.creatingNote)
}

export const getErrorCreatingNote = (state: IState) => {
  return Boolean(state.contacts.ui.getErrorCreatingNote)
}

export const getGeneratingSummary = (state: IState) => {
  return Boolean(state.contacts.ui.generatingSummary)
}

function getTransportType(s: string): 'twilio' | 'web' | 'facebook' | 'other' {
  switch (s) {
    case 'twilio':
    case 'web':
    case 'facebook':
      return s
    default:
      return 'other'
  }
}

export const getContactIsPermitted = (state: IState) => {
  const filteredChannels: ReadonlyArray<TransportId | 'other'> =
    state.triage.application.institution.currentInstitution?.filteredChannels ||
    []

  const contactPermittedUser =
    state.contacts.currentEditContact?._contactSettings?.permittedUser

  const contactTransport = getTransportType(
    state.contacts.currentEditContact?.transport || 'other'
  )

  return (
    contactPermittedUser === true ||
    (contactPermittedUser == null &&
      !filteredChannels.includes(contactTransport))
  )
}

export const getPermittedUserStatusMutable = (state: IState) => {
  if (
    state.contacts.currentEditContact?._contactSettings?.permittedUserMutable !=
    null
  ) {
    return state.contacts.currentEditContact?._contactSettings
      ?.permittedUserMutable
  } else {
    return true
  }
}

export const getContactCounts = (state: IState) => state.contacts.contactCounts

export const getarchiveActionCompleted = (state: IState) =>
  state.contacts.archiveActionCompleted

export const getArchiveActionProgress = (state: IState) =>
  state.contacts.archiveProgress

export const getContactCountsAreLoading = (state: IState) =>
  state.contacts.loadingContactCounts

export const getContactCountsisSuccessful = (state: IState) =>
  !state.contacts.errorFetchingContactCounts
