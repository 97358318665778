import React from 'react'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { SearchInput } from 'components/SearchInput/SearchInput'
import classnames from 'classnames'
import {
  getAllInstitutionAttributes,
  IInstitutionAttribute,
} from 'store/personalization/institutionAttributes/selectors'
import { isSuccessOrRefetching, isRefetching } from 'store/webdata'
import { Spinner } from 'components/Spinner/Spinner'
import { RefetchingOverlay } from 'components/RefetchingOverlay/RefetchingOverlay'

import 'components/AttributeSelector/AttributeSelector.scss'
import { useDispatch, useSelector } from 'util/hooks'
import { getAllAttributesAndFieldsAsync } from 'store/personalization/contactAttributes/thunks'

export const AttributeSubSection = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => {
  return (
    <section className={classnames('p-2 border-bottom d-flex', className)}>
      {children}
    </section>
  )
}

export interface IAttributeSelectorProps {
  onClose: () => void
}

interface IInstitutionAttributeSelectorProps extends IAttributeSelectorProps {
  onSelectAttribute: (attribute: IInstitutionAttribute) => void
}

export const InstitutionAttributeSelector = ({
  onSelectAttribute,
  onClose,
}: IInstitutionAttributeSelectorProps) => {
  const [query, setQuery] = React.useState('')
  const results = useSelector(getAllInstitutionAttributes)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (!results) {
      getAllAttributesAndFieldsAsync(dispatch)()
    }
  }, [dispatch, results])
  return (
    <div className="border rounded shadow-lg bg-white">
      <AttributeSubSection className="justify-content-between">
        <span className="text-mainstay-dark-blue-65">
          Insert Organization Attributes
        </span>
        {onClose && (
          <AHIcon
            name="close"
            className="large pointer text-mainstay-dark-blue-65"
            onClick={onClose}
          />
        )}
      </AttributeSubSection>
      <AttributeSubSection>
        <SearchInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setQuery(e.target.value)
          }}
          placeholder="Search attributes"
          name="search"
        />
      </AttributeSubSection>
      <AttributeSubSection className="flex-column attribute-search-results">
        {isSuccessOrRefetching(results) ? (
          <RefetchingOverlay enabled={isRefetching(results)}>
            <>
              {results.data.length > 0 ? (
                results.data
                  .filter(
                    attribute =>
                      attribute.field
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      (attribute.value &&
                        attribute.value
                          .toString()
                          .toLowerCase()
                          .includes(query.toLowerCase()))
                  )
                  .map(attribute => {
                    return (
                      <div
                        className="d-flex justify-content-between p-2 react-select__option"
                        key={attribute.id}
                        onClick={() => {
                          onSelectAttribute(attribute)
                          onClose()
                        }}>
                        <div>{attribute.field}</div>
                        <div className="small text-muted">
                          {attribute.value}
                        </div>
                      </div>
                    )
                  })
              ) : (
                <div className="d-flex justify-content-center w-100 py-5">
                  No results to show
                </div>
              )}
            </>
          </RefetchingOverlay>
        ) : (
          <div className="w-100 h-100 justify-content-center align-content-center d-flex p-4">
            <Spinner className="stroke-bg-mainstay-dark-blue" />
          </div>
        )}
      </AttributeSubSection>
    </div>
  )
}
