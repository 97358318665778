import classNames from 'classnames'
import { mapColor } from 'util/color'
import 'components/UserIcon/UserIcon.scss'
import { nameToInitials } from 'util/names'
import { toTitleCase } from 'util/string'
import Tooltip from 'components/Tooltip/Tooltip'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'

export type UserIconSize = 'sm' | 'lg' | 'md'

export function getName(firstName?: string, lastName?: string) {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }
  if (firstName) {
    return firstName
  }
  if (lastName) {
    return lastName
  }
  return 'Unknown User'
}

export enum IOnlineStatus {
  online = 'ONLINE',
  offline = 'OFFLINE',
  other = 'OTHER',
}

interface IOnlineIndicatorProps {
  readonly status: IOnlineStatus
}

export const OnlineIndicator = ({ status }: IOnlineIndicatorProps) => {
  if (status === IOnlineStatus.online) {
    return <div className="rounded-circle user-online-status bg-success" />
  }
  if (status === IOnlineStatus.offline) {
    return (
      <AHIcon
        name="do_not_disturb"
        className="rounded-circle user-online-status bg-white gray-600"
      />
    )
  }
  return null
}

interface IUserIconProps {
  readonly firstName?: string
  readonly lastName?: string
  readonly size: UserIconSize
  readonly image?: string
  readonly onlineStatus?: IOnlineStatus
  readonly className?: string
}
export const UserIcon = ({
  firstName,
  lastName,
  size,
  image,
  onlineStatus = IOnlineStatus.other,
  className,
}: IUserIconProps) => {
  // Get ID so tooltip can reference node
  const initials = nameToInitials(firstName, lastName)
  const fullName = toTitleCase(getName(firstName, lastName))
  return (
    <div className={className}>
      <Tooltip content={fullName}>
        <div
          tabIndex={-1}
          className={classNames(
            'user-icon-inner',
            'd-flex',
            'align-items-center',
            'justify-content-center',
            'rounded-circle',
            'text-white',
            'text-uppercase',
            'mr-2',
            { 'bg-transparent': !!image },
            !image && onlineStatus === IOnlineStatus.offline
              ? 'bg-gray-600'
              : mapColor(initials[0]),
            size
          )}>
          {image ? (
            <img
              className="icon-image"
              src={image}
              aria-label={`${fullName}`}
            />
          ) : (
            <div>{initials}</div>
          )}
        </div>
      </Tooltip>
      <OnlineIndicator status={onlineStatus} />
    </div>
  )
}

interface ISimpleUserIconProps {
  readonly firstName?: string
  readonly lastName?: string
  readonly size: UserIconSize
  readonly className?: string
  readonly outline?: boolean
}

export const SimpleUserIcon = ({
  firstName,
  lastName,
  size,
  className,
  outline,
}: ISimpleUserIconProps) => {
  const initials = nameToInitials(firstName, lastName)
  const cnames = classNames(
    {
      outline,
      'bg-blue-grey-200': !outline,
      'font-weight-bold': outline,
    },
    'user-icon-inner d-flex align-items-center justify-content-center rounded-circle text-uppercase',
    size,
    className
  )
  return (
    <div className={cnames}>
      <p className="caption m-0 text-white font-weight-bold">{initials}</p>
    </div>
  )
}
