import random from 'lodash/random'

export const timeout = (delayMs: number) =>
  new Promise(resolve => setTimeout(resolve, delayMs))

interface IExponentialBackoff {
  baseFailureRateSeconds?: number
  maxRetry?: number
}
interface IExponentialBackoffResponse {
  delayMs: number | 'maxRetryReached'
}
export function ExponentialBackoff({
  baseFailureRateSeconds = 2,
  maxRetry = 5,
}: IExponentialBackoff = {}) {
  // Basic exponential backoff with jitter
  // https://docs.microsoft.com/en-us/dotnet/standard/microservices-architecture/implement-resilient-applications/implement-http-call-retries-exponential-backoff-polly
  let failureAttempt = 0
  return (): IExponentialBackoffResponse => {
    failureAttempt++
    const delayMs =
      (Math.pow(baseFailureRateSeconds, failureAttempt) + random(10)) * 1000
    if (failureAttempt > maxRetry) {
      return { delayMs: 'maxRetryReached' }
    }
    return {
      delayMs,
    }
  }
}
