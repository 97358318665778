import { PromptType } from 'store/campaign-scripts/reducer'
import { ICampaignScriptSpliceData } from 'api/request'

export const NEW_PROMPT_OPTION_INITIAL_MESSAGE = 'Enter an option'

export const getPromptTypeDefaults = (
  type: PromptType
): Partial<ICampaignScriptSpliceData> => {
  switch (type) {
    case PromptType.number: {
      return {
        multipleChoices: [{ prompt: NEW_PROMPT_OPTION_INITIAL_MESSAGE }],
        nextStates: {
          '1': { default: false },
        },
        spliceTransition: 1,
      }
    }
    case PromptType.boolean: {
      return {
        nextStates: {
          true: { default: false },
          false: { default: false },
        },
        spliceTransition: 'true',
      }
    }
    default:
      return {
        nextStates: {
          default: {
            default: false,
          },
        },
      }
  }
}
