import { AttributeKind } from 'components/DraftEditor/DraftEditor'
import {
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntity,
  RawDraftEntityRange,
} from 'draft-js'
import emojiRegex from 'emoji-regex'
import Mustache from 'mustache'
import React, { useEffect, useState } from 'react'
import {
  IContactAttributeDraftEditor,
  IInstitutionAttributeDraftEditor,
} from 'store/knowledgeSeeder/reducer'
import { ContactAttributeType } from 'store/personalization/contactAttributes/selectors'
import {
  IAttributeNamesById,
  IAttributesById,
  InstitutionAttributeType,
} from 'store/personalization/institutionAttributes/selectors'
import { getAttributeByIdMapping } from 'store/personalization/selectors'
import { isSuccess } from 'store/webdata'
import { useSelector } from 'util/hooks'

export const enum MutableEnum {
  IMMUTABLE = 'IMMUTABLE',
  MUTABLE = 'MUTABLE',
  SEGMENTED = 'SEGMENTED',
}

const INSTITUTION_ATTRIBUTE_REGEX = /^institution\.\d+$/
const CONTACT_ATTRIBUTE_REGEX = /^contact\.\d+$/
const TOPLEVEL_REGEX = /^toplevel\.[a-zA-Z_]+$/

export const CONTACT_ATTRIBUTE_TEMPLATE_REGEX = /{{\s*contact\.\d+\s*}}/
export const TOPLEVEL_TEMPLATE_REGEX = /{{\s*toplevel\.[a-zA-Z_]+\s*}}/

export const INSTITUTION_ATTRIBUTE_PREFIX = 'institution.'
export const CONTACT_ATTRIBUTE_PREFIX = 'contact.'
export const TOPLEVEL_FIELD_PREFIX = 'toplevel.'

export interface IEntityMap {
  [key: string]: RawDraftEntity<
    | IEmojiEntity
    | IContactAttributeEntity
    | IInstitutionAttributeEntity
    | ITopLevelContactFieldEntity
  >
}

export interface IAttributeEntityShort {
  id?: number | string
  field: string
  kind:
    | AttributeKind.CONTACT
    | AttributeKind.INSTITUTION
    | AttributeKind.TOPLEVEL
  originalText?: string
  value?: string | number | Date
  type?: InstitutionAttributeType | ContactAttributeType
}

export interface IContactAttributeEntity extends IContactAttributeDraftEditor {
  originalText?: string
  kind: AttributeKind.CONTACT
}

export interface ITopLevelContactFieldEntity
  extends IContactAttributeDraftEditor {
  originalText: string
  kind: AttributeKind.TOPLEVEL
  id: string | undefined
}

export interface IInstitutionAttributeEntity
  extends IInstitutionAttributeDraftEditor {
  originalText?: string
  kind: AttributeKind.INSTITUTION
}

interface IEmojiEntity {
  emoji: string
}

export const getHasContactAttribute = (
  answer: string,
  mapping: IAttributesById
) => {
  try {
    for (const elem of Mustache.parse(answer)) {
      if (
        (elem[0] === 'name' && isValidContactAttribute(elem[1], mapping)) ||
        isValidTopLevelField(elem[1], mapping)
      ) {
        return true
      }
    }
  } catch {
    // invalid template found. no contact attributes.
  }
}

export const useParseAnswer = (
  answer?: string
): {
  selectedContactAttributes: Array<string>
  hasValidAttributes: boolean
} => {
  /* Takes an answer string
  Returns 
  - `selectedContactAttributes`: contact attributes (custom fields) in the answer string.
  - `hasValidAttributes`: flag indicating if the answer has properly formatted mustache 
  templates and contains valid attributes only.
    Invalid answers include: 
      - '{{ contact.123 }}', where contact attribute with id 123
      does not exist for the institution
      - '{{ invalid_text }}'
      - '{{' 
      - '{{{ contact.11 }}' or any other invalid templates without a proper closing tag
   */
  const mappingResults = useSelector(getAttributeByIdMapping)
  const mapping = React.useMemo(
    () =>
      isSuccess(mappingResults)
        ? mappingResults.data
        : { institution: {}, contact: {}, toplevel: {} },
    [mappingResults]
  )

  const [
    selectedContactAttributes,
    setSelectedContactAttributes,
  ] = React.useState<string[]>([])
  const [hasValidAttributes, setHasValidAttributes] = useState<boolean>(true)
  useEffect(() => {
    const contactFields: Array<string> = []
    let isValid = true
    try {
      Mustache.parse(answer || '').forEach(elem => {
        if (elem[0] === 'name') {
          if (!isValidAttribute(elem[1], mapping)) {
            isValid = false
          }
          if (isValidContactAttribute(elem[1], mapping)) {
            const contactId: number = Number(
              elem[1].split(CONTACT_ATTRIBUTE_PREFIX)[1]
            )
            const { field } = mapping.contact[contactId]
            contactFields.push(field)
          }
          if (isValidTopLevelField(elem[1], mapping)) {
            const toplevelId: string = elem[1].split(TOPLEVEL_FIELD_PREFIX)[1]
            const { field } = mapping.toplevel[toplevelId]
            contactFields.push(field)
          }
        }
      })
      setSelectedContactAttributes(contactFields)
      setHasValidAttributes(isValid)
    } catch {
      setSelectedContactAttributes([])
      setHasValidAttributes(false)
    }
  }, [answer, mapping])
  return { selectedContactAttributes, hasValidAttributes }
}

export const getAttributeDataFromMustache = (
  mustacheString: string,
  mapping: IAttributesById | IAttributeNamesById
): IAttributeEntityShort | undefined => {
  /* Takes a mustache-formatted string (e.g. "institution.5"), and returns the
     data for the associated attribute by extracting the attribute type and id
     from the string and searching for it in the AttributesById mappings.
   */
  const parts = mustacheString
    .replace('{{', '')
    .replace('}}', '')
    .trim()
    .split('.')
  if (parts.length < 2 || !parts[1]) {
    return
  }
  if (parts[0] === 'institution') {
    if (mapping.institution) {
      const attrId = Number(parts[1])
      const institutionAttr = mapping.institution[attrId]
      if (!institutionAttr || typeof institutionAttr === 'string') {
        return {
          id: attrId || undefined,
          field: institutionAttr,
          kind: AttributeKind.INSTITUTION,
        }
      }
      return {
        ...institutionAttr,
        kind: AttributeKind.INSTITUTION,
        originalText: `${INSTITUTION_ATTRIBUTE_PREFIX}${parts[1]}`,
      }
    }
  }
  if (parts[0] === 'contact') {
    if (mapping.contact) {
      const attrId = Number(parts[1])
      const contactAttr = mapping.contact[attrId]
      if (!contactAttr || typeof contactAttr === 'string') {
        return {
          id: attrId || undefined,
          field: contactAttr,
          kind: AttributeKind.CONTACT,
        }
      }
      return {
        ...contactAttr,
        originalText: `${CONTACT_ATTRIBUTE_PREFIX}${parts[1]}`,
        kind: AttributeKind.CONTACT,
      }
    }
  }
  if (parts[0] === 'toplevel') {
    return {
      id: undefined,
      field: parts[1],
      originalText: `${TOPLEVEL_FIELD_PREFIX}${parts[1]}`,
      kind: AttributeKind.TOPLEVEL,
    }
  }
}

interface IEmojiOffsets
  extends Array<{
    index: number
    emoji: string
  }> {}

const replaceEmojisWithSpace = (text: string): [string, IEmojiOffsets] => {
  // This function takes an answer, and replaces each emoji with a space.
  // It returns the new answer, as well as the an object containing the emojis replaced and the index they should have been at
  let updatedText = text
  const emojis: IEmojiOffsets = []
  const regex = emojiRegex()

  let match: RegExpExecArray | null = regex.exec(text)

  while (match) {
    const emoji = match[0]
    emojis.push({
      index: match.index,
      emoji,
    })
    updatedText = updatedText.replace(emoji, ' ')
    // Since we're updating the `updatedText` inplace, we need to reset the regex index...
    // so it starts its search back at the beginning of the string w/ each loop.
    // Emojis will have been replaced w/ strings, so they won't be matched a second time
    regex.lastIndex = 0
    match = regex.exec(updatedText)
  }
  return [updatedText, emojis]
}

const convertEmojisToEntityMap = (
  emojis: IEmojiOffsets,
  entityKey = 0
): [RawDraftEntityRange[], IEntityMap] => {
  // given the object containing the emojis found and their indexes in the answer,
  // create the data necessary to create Entities for each:
  // - use indexes to generate an array of entityRanges
  // - add emoji to entityMap
  const entityRanges: RawDraftEntityRange[] = []
  const entityMap: IEntityMap = {}
  emojis.forEach((elem, i) => {
    const key = entityKey + i // prevent conflicts with attribute entity keys
    entityRanges.push({
      offset: elem.index,
      length: 1,
      key,
    })
    entityMap[key] = {
      type: 'emoji',
      mutability: MutableEnum.IMMUTABLE,
      data: { emoji: elem.emoji },
    }
  })
  return [entityRanges, entityMap]
}

const isValidInstitutionAttribute = (
  elem: string,
  mapping: IAttributesById
) => {
  return (
    elem.match(INSTITUTION_ATTRIBUTE_REGEX) &&
    Object.keys(mapping.institution).includes(
      elem.split(INSTITUTION_ATTRIBUTE_PREFIX)[1]
    )
  )
}

const isValidTopLevelField = (elem: string, mapping: IAttributesById) => {
  return (
    elem.match(TOPLEVEL_REGEX) &&
    Object.keys(mapping.toplevel).includes(elem.split(TOPLEVEL_FIELD_PREFIX)[1])
  )
}

const isValidAttribute = (elem: string, mapping: IAttributesById) => {
  return (
    isValidTopLevelField(elem, mapping) ||
    isValidContactAttribute(elem, mapping) ||
    isValidInstitutionAttribute(elem, mapping)
  )
}

const isValidContactAttribute = (elem: string, mapping: IAttributesById) => {
  return (
    elem.match(CONTACT_ATTRIBUTE_REGEX) &&
    Object.keys(mapping.contact).includes(
      elem.split(CONTACT_ATTRIBUTE_PREFIX)[1]
    )
  )
}

export const generateEntityMapper = (
  answer: string,
  mapping: IAttributesById
): RawDraftContentState | undefined => {
  try {
    // use Mustache to parse answer text and identify if any {{ }} are present
    const mustacheArray = Mustache.parse(answer)

    /* 
  sample Mustache.parse output:
  
  input: mustache.parse( "The phone number for the department of Residential Life is {{ institution.1 }} or {{ institution.2 }} .")
  output: [
    ["text", "The phone number for the department of Residential Life is ", 0, 59]
    ["name", "institution.1", 59, 78]
    ["text", " or ", 78, 82]
    ["name", "institution.2", 82, 101]
    ["text", " .", 101, 103]
  ]
  */

    // initialize final outputString
    let outputString = ''

    // collect relevant attribute data from db
    const attributeMatches = mustacheArray.filter(
      elem => elem[0] === 'name' && isValidAttribute(elem[1], mapping)
    )

    // initialize empty entityRanges array
    let entityRanges: RawDraftEntityRange[] = []

    // initialize empty entityMap
    let entityMap: IEntityMap = {}

    // initialize attribute counter
    let attributeIndex = 0

    mustacheArray.forEach(elem => {
      // if Mustache found text enclosed in {{ }}...
      if (elem[0] === 'name') {
        // if the text enclosed in {{ }} matches our institution or contact attribute code format and the id matches to an existing attribute...
        if (isValidAttribute(elem[1], mapping)) {
          // add attribute field name to outputString and store start/length for entityRange
          const start = outputString.length
          outputString += Mustache.render(`{{ ${elem[1]}.field }}`, mapping)

          // add start/length to entityRange array for this attribute
          const length = outputString.length - start
          const nextEntityKey = entityRanges.length
          entityRanges.push({ offset: start, length, key: nextEntityKey })

          // pull out matching attribute data from store per entity
          const match = attributeMatches[attributeIndex]
          attributeIndex++
          const keys = match[1].split('.')

          if (isValidInstitutionAttribute(elem[1], mapping)) {
            const attributeData = mapping.institution[Number(keys[1])]

            // add this entity data to the cumulative entityMap
            entityMap[nextEntityKey] = {
              type: 'attribute',
              mutability: MutableEnum.IMMUTABLE,
              data: {
                ...attributeData,
                originalText: match[1],
                kind: AttributeKind.INSTITUTION,
              },
            }
          } else if (isValidContactAttribute(elem[1], mapping)) {
            const attributeData = mapping.contact[Number(keys[1])]
            entityMap[nextEntityKey] = {
              type: 'attribute',
              mutability: MutableEnum.IMMUTABLE,
              data: {
                ...attributeData,
                originalText: match[1],
                kind: AttributeKind.CONTACT,
              },
            }
          } else {
            const attributeData = mapping.toplevel[keys[1]]
            entityMap[nextEntityKey] = {
              type: 'attribute',
              mutability: MutableEnum.IMMUTABLE,
              data: {
                ...attributeData,
                originalText: match[1],
                id: undefined,
                kind: AttributeKind.TOPLEVEL,
              },
            }
          }
        } else {
          // put the curly braces back and plainly render what was inside
          outputString += `{{ ${elem[1]} }}`
        }
      } else if (elem[0] === '&') {
        outputString += `{{{ ${elem[1]} }}}`
      } else {
        // handle any emojis in text _not_ wrappped in {{ }}
        // first, remove them from text and replace with a single space
        const entityKey = entityRanges.length
        const [newText, emojis] = replaceEmojisWithSpace(elem[1])
        // then generate entity data for found emojis
        const [emojiEntityRanges, emojiEntityMap] = convertEmojisToEntityMap(
          emojis,
          entityKey
        )

        // add any found emoji entity ranges to cumultative entityRanges array
        entityRanges = entityRanges.concat(
          emojiEntityRanges.map(entityRange => {
            return {
              ...entityRange,
              offset: entityRange.offset + outputString.length,
            }
          })
        )

        // add any found emoji entity data to cumulative entityMap
        entityMap = { ...entityMap, ...emojiEntityMap }

        // add text w/ emojis replaced with spaces to final outputString
        outputString += newText
      }
    })

    // `blocks` is plural, but there should be one block per answer (entire answer as `text` property) - blocks array should have length 1
    // answers w/ line breaks are all mapped to a singular block with newline characters in `text`
    const blocks: RawDraftContentBlock[] = [
      {
        key: 'first',
        text: outputString,
        type: 'unstyled',
        entityRanges,
        depth: 0,
        inlineStyleRanges: [],
      },
    ]

    // `blocks` and `entityMap` properties can be used to create `ContentState` via `convertFromRaw()`
    return {
      blocks,
      entityMap,
    }
  } catch {
    // If Mustache throws an exception, just return undefined.
    return
  }
}

export const getOutputWithAttributeValues = (
  output: string,
  mapping: IAttributesById
) => {
  try {
    Mustache.escape = (text: string): string => text
    return Mustache.render(output, getMustacheValuesFromAttributes(mapping))
  } catch (err) {
    return output
  }
}

export const getMustacheValuesFromAttributes = (mapping: IAttributesById) => {
  const keysToValues = Object.keys(mapping.institution).reduce(
    (acc: { [key: string]: string | number | Date }, elem) => {
      acc[elem] = mapping.institution[Number(elem)].value
      return acc
    },
    {}
  )
  return { institution: keysToValues }
}
