import { createAsyncAction, ActionType } from 'typesafe-actions'
import { IActivityLogQueryStringParameters } from 'api/request'
import {
  IActivityLogResponseData,
  IActivityLogTotalsResponseData,
  IPaginatedResponse,
  IActivityLogListResponseData,
} from 'api/response'
import { push } from 'connected-react-router'
import { Dispatch } from 'store/store'
import * as queryString from 'query-string'

export const fetchActivityLogs = createAsyncAction(
  '@@MASCOT/FETCH_ACTIVITY_LOG_START',
  '@@MASCOT/FETCH_ACTIVITY_LOG_SUCCESS',
  '@@MASCOT/FETCH_ACTIVITY_LOG_FAILURE'
)<
  Partial<IActivityLogQueryStringParameters>,
  IPaginatedResponse<IActivityLogListResponseData>,
  undefined
>()

export const fetchActivityLogTotals = createAsyncAction(
  '@@MASCOT/FETCH_ACTIVITY_LOG_TOTALS_START',
  '@@MASCOT/FETCH_ACTIVITY_LOG_TOTALS_SUCCESS',
  '@@MASCOT/FETCH_ACTIVITY_LOG_TOTALS_FAILURE'
)<void, IActivityLogTotalsResponseData, undefined>()

export const markActivityLog = createAsyncAction(
  '@@MASCOT/MARK_ACTIVITY_LOG_START',
  '@@MASCOT/MARK_ACTIVITY_LOG_SUCCESS',
  '@@MASCOT/MARK_ACTIVITY_LOG_FAILURE'
)<{ id: string; read: boolean }, IActivityLogResponseData, undefined>()

export const reportActivityLog = createAsyncAction(
  '@@MASCOT/REPORT_ACTIVITY_LOG_START',
  '@@MASCOT/REPORT_ACTIVITY_LOG_SUCCESS',
  '@@MASCOT/REPORT_ACTIVITY_LOG_FAILURE'
)<{ id: string; reportContent: string }, IActivityLogResponseData, undefined>()

export const navToFilteredView = (dispatch: Dispatch) => {
  return (filters: Partial<IActivityLogQueryStringParameters>) => {
    const qs = queryString.stringify(filters)
    dispatch(push({ search: qs }))
  }
}

export const navToUnfilteredView = (dispatch: Dispatch) => {
  return () => {
    dispatch(push({ search: undefined }))
  }
}

export type IActivityLogActions =
  | ActionType<typeof fetchActivityLogs>
  | ActionType<typeof fetchActivityLogTotals>
  | ActionType<typeof reportActivityLog>
  | ActionType<typeof markActivityLog>
