import * as api from 'api'
import difference from 'lodash/difference'
import uniq from 'lodash/uniq'
import union from 'lodash/union'
import {
  addQuestionToInstitution,
  addToCheckboxList,
  deleteQuestionForInstitution,
  fetchKnowledgeBaseReviewItems,
  IActions,
  markKnowledgeBaseItemAsReviewed,
  removeFromCheckboxList,
  resetCheckboxList,
  selectAllFromCheckboxList,
  setCheckboxActionLoading,
  setKnowledgeReviewItemsCount,
  showKBReviewItemsFilterModal,
  updateSelectedItems,
  fetchKnowledgeBaseDropdownItems,
  knowledgeBaseRemoveDropdownFilter,
  knowledgeBaseClearDropdownFilter,
} from 'page/knowledge-base/KnowledgeReview/actions'
import { getType } from 'typesafe-actions'
import { SelectInputValue } from 'page/conversations-v2/ConversationList/ConversationHeader/ConversationsListFilterModal/ConversationsSelectInput/ConversationsSelectInput'

export type KnowledgeBaseFiltersType = {
  readonly audiences?: SelectInputValue[]
}
type KnowledgeBaseReviewItemsState = {
  readonly showFilterModal: boolean
  readonly filterStatus: KnowledgeBaseReviewItemsStatus
  readonly filters: KnowledgeBaseFiltersType
  readonly markAsReviewedStatus: KnowledgeBaseReviewItemsStatus
  readonly addQuestionStatus: KnowledgeBaseReviewItemsStatus
  readonly deleteQuestionStatus: KnowledgeBaseReviewItemsStatus
  readonly checkboxList: {
    readonly all: boolean
    readonly selectedIds: string[]
    readonly deselectedIds: string[]
    readonly isLoading: boolean
    readonly resolvingStatus: KnowledgeBaseReviewItemsStatus
  }
  readonly data: {
    readonly status: KnowledgeBaseReviewItemsStatus
    readonly items: api.KnowledgeBaseReviewItemShapeType[]
    readonly totalCount: number
  }
  readonly counts: {
    readonly botCouldNotAnswerCount: number
    readonly needsPersonalizedAnswerCount: number
    readonly needsInteractiveAnswerCount: number
    readonly answerIncorrectCount: number
    readonly contactFeedbackCount: number
    readonly status: KnowledgeBaseReviewItemsStatus
  }
}

export type KnowledgeBaseReviewItemsFiltersType = {
  readonly search?: string
  readonly filterBySMS?: boolean
  readonly filterByWebChat?: boolean
  readonly filterByFacebook?: boolean
  readonly filterBySlack?: boolean
  readonly reviewed?: boolean
  readonly dateFrom?: Date
  readonly dateTo?: Date
  readonly audiences?: SelectInputValue[]
  readonly selectedAudiences?: SelectInputValue[]
}

export enum KnowledgeBaseReviewItemsStatus {
  initial = 'initial',
  loading = 'loading',
  error = 'error',
  ok = 'ok',
}

const initialKnowledgeBaseReviewItemsState: KnowledgeBaseReviewItemsState = {
  showFilterModal: false,
  filterStatus: KnowledgeBaseReviewItemsStatus.initial,
  markAsReviewedStatus: KnowledgeBaseReviewItemsStatus.initial,
  addQuestionStatus: KnowledgeBaseReviewItemsStatus.initial,
  deleteQuestionStatus: KnowledgeBaseReviewItemsStatus.initial,
  checkboxList: {
    all: false,
    selectedIds: [],
    deselectedIds: [],
    isLoading: false,
    resolvingStatus: KnowledgeBaseReviewItemsStatus.initial,
  },
  data: {
    status: KnowledgeBaseReviewItemsStatus.initial,
    items: [],
    totalCount: 0,
  },
  counts: {
    status: KnowledgeBaseReviewItemsStatus.initial,
    botCouldNotAnswerCount: 0,
    needsPersonalizedAnswerCount: 0,
    needsInteractiveAnswerCount: 0,
    answerIncorrectCount: 0,
    contactFeedbackCount: 0,
  },
  filters: {
    audiences: [],
  },
}

const knowledgeReview = (
  state: KnowledgeBaseReviewItemsState = initialKnowledgeBaseReviewItemsState,
  action: IActions
): KnowledgeBaseReviewItemsState => {
  switch (action.type) {
    case getType(addToCheckboxList):
      const selectedIds = uniq([
        ...state.checkboxList.selectedIds,
        ...action.payload,
      ])
      return {
        ...state,
        checkboxList: {
          ...state.checkboxList,
          selectedIds,
          deselectedIds: difference(
            state.checkboxList.deselectedIds,
            selectedIds
          ),
        },
      }
    case getType(removeFromCheckboxList):
      return {
        ...state,
        checkboxList: {
          ...state.checkboxList,
          selectedIds: state.checkboxList.selectedIds.filter(
            item => item !== action.payload
          ),
          deselectedIds: state.checkboxList.all
            ? [...state.checkboxList.deselectedIds, action.payload]
            : [],
        },
      }
    case getType(selectAllFromCheckboxList):
      return {
        ...state,
        checkboxList: {
          all: action.payload.all,
          selectedIds: action.payload.selectedIds,
          deselectedIds: action.payload.all
            ? difference(
                state.checkboxList.deselectedIds,
                action.payload.selectedIds
              )
            : [],
          isLoading: false,
          resolvingStatus: KnowledgeBaseReviewItemsStatus.initial,
        },
      }
    case getType(resetCheckboxList):
      return {
        ...state,
        checkboxList: {
          all: false,
          selectedIds: [],
          deselectedIds: [],
          isLoading: false,
          resolvingStatus: KnowledgeBaseReviewItemsStatus.initial,
        },
      }
    case getType(setCheckboxActionLoading):
      return {
        ...state,
        checkboxList: {
          ...state.checkboxList,
          isLoading: action.payload,
        },
      }
    case getType(updateSelectedItems.request):
      return {
        ...state,
        checkboxList: {
          ...state.checkboxList,
          resolvingStatus: KnowledgeBaseReviewItemsStatus.loading,
        },
      }
    case getType(updateSelectedItems.success):
      return {
        ...state,
        checkboxList: {
          ...state.checkboxList,
          resolvingStatus: KnowledgeBaseReviewItemsStatus.ok,
        },
      }
    case getType(updateSelectedItems.failure):
      return {
        ...state,
        checkboxList: {
          ...state.checkboxList,
          resolvingStatus: KnowledgeBaseReviewItemsStatus.error,
        },
      }
    case getType(fetchKnowledgeBaseReviewItems.request):
      return {
        ...state,
        data: {
          ...state.data,
          status: KnowledgeBaseReviewItemsStatus.loading,
        },
      }
    case getType(fetchKnowledgeBaseReviewItems.success):
      return {
        ...state,
        checkboxList: {
          ...state.checkboxList,
          selectedIds: [...state.checkboxList.selectedIds].filter(id =>
            action.payload.review_items
              .map(item => item.message.id)
              .includes(id)
          ),
          deselectedIds: union(
            state.checkboxList.deselectedIds,
            [...state.checkboxList.selectedIds].filter(
              id =>
                !action.payload.review_items
                  .map(item => item.message.id)
                  .includes(id)
            )
          ),
        },
        data: {
          items: action.payload.review_items,
          status: KnowledgeBaseReviewItemsStatus.ok,
          totalCount: action.payload.total_count,
        },
      }
    case getType(fetchKnowledgeBaseReviewItems.failure):
      return {
        ...state,
        data: {
          ...state.data,
          status: KnowledgeBaseReviewItemsStatus.error,
        },
      }
    case getType(markKnowledgeBaseItemAsReviewed.request):
      return {
        ...state,
        markAsReviewedStatus: KnowledgeBaseReviewItemsStatus.loading,
      }
    case getType(markKnowledgeBaseItemAsReviewed.success):
      return {
        ...state,
        markAsReviewedStatus: KnowledgeBaseReviewItemsStatus.ok,
      }
    case getType(markKnowledgeBaseItemAsReviewed.failure):
      return {
        ...state,
        markAsReviewedStatus: KnowledgeBaseReviewItemsStatus.error,
      }
    case getType(showKBReviewItemsFilterModal):
      return {
        ...state,
        showFilterModal: action.payload,
      }

    case getType(fetchKnowledgeBaseDropdownItems.request):
      return {
        ...state,
        filterStatus: KnowledgeBaseReviewItemsStatus.loading,
      }
    case getType(fetchKnowledgeBaseDropdownItems.success):
      return {
        ...state,
        filterStatus: KnowledgeBaseReviewItemsStatus.ok,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }
    case getType(fetchKnowledgeBaseDropdownItems.failure):
      return {
        ...state,
        filterStatus: KnowledgeBaseReviewItemsStatus.error,
      }
    case getType(knowledgeBaseClearDropdownFilter): {
      return {
        ...state,
        filters: {
          audiences: [],
        },
      }
    }
    case getType(knowledgeBaseRemoveDropdownFilter): {
      return {
        ...state,
        filters: {
          ...state.filters,
          audiences: state.filters.audiences?.filter(
            audience => audience.value !== action.payload.id
          ),
        },
      }
    }

    case getType(addQuestionToInstitution.request):
      return {
        ...state,
        addQuestionStatus: KnowledgeBaseReviewItemsStatus.loading,
      }
    case getType(addQuestionToInstitution.success):
      return {
        ...state,
        addQuestionStatus: KnowledgeBaseReviewItemsStatus.ok,
      }
    case getType(addQuestionToInstitution.failure):
      return {
        ...state,
        addQuestionStatus: KnowledgeBaseReviewItemsStatus.error,
      }
    case getType(deleteQuestionForInstitution.request):
      return {
        ...state,
        deleteQuestionStatus: KnowledgeBaseReviewItemsStatus.loading,
      }
    case getType(deleteQuestionForInstitution.success):
      return {
        ...state,
        deleteQuestionStatus: KnowledgeBaseReviewItemsStatus.ok,
      }
    case getType(setKnowledgeReviewItemsCount):
      const {
        bot_could_not_answer_count,
        needs_personalized_answer_count,
        needs_interactive_answer_count,
        answer_incorrect_count,
        contact_feedback_count,
      } = action.payload
      return {
        ...state,
        counts: {
          botCouldNotAnswerCount: bot_could_not_answer_count,
          needsPersonalizedAnswerCount: needs_personalized_answer_count,
          needsInteractiveAnswerCount: needs_interactive_answer_count,
          answerIncorrectCount: answer_incorrect_count,
          contactFeedbackCount: contact_feedback_count,
          status: KnowledgeBaseReviewItemsStatus.ok,
        },
      }
    default:
      return state
  }
}

export default knowledgeReview
