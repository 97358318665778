import { AHIcon } from 'components/Icons/AHIcon/AHIcon'

import 'components/Dropdown/DropdownControl.scss'

export function DropdownControl({
  label,
  overrideWidth,
  onClick,
}: {
  label?: string
  overrideWidth?: string
  onClick: () => void
}): JSX.Element {
  return (
    <div
      className="form-control pointer dropdown-control d-flex align-items-center justify-content-between"
      onClick={onClick}>
      <div className={overrideWidth || ''}>{label}</div>
      <div className="mt-1">
        <AHIcon
          name="arrow_drop_down"
          overrideWidth={overrideWidth !== undefined}
        />
      </div>
    </div>
  )
}
