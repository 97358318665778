import {
  IContactFilterFormRow,
  isGroup,
} from 'components/ContactFilterBuilder/formUtils'
import Select from 'components/Select/SelectV2'
import { ContactAttributeType } from 'store/personalization/contactAttributes/selectors'

export enum ComparisonOperator {
  EQ = 'EQ',
  NEQ = 'NEQ',
  LT = 'LT',
  GT = 'GT',
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  EXISTS = 'EXISTS',
  DNE = 'DNE',
  INCLUDES = 'INCLUDES',
  NOT_INCLUDES = 'NOT_INCLUDES',
  IN_LIST = 'IN_LIST',
  NOT_IN_LIST = 'NOT_IN_LIST',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
}

interface IOperatorOption {
  label: string
  value: ComparisonOperator
}

type IOperatorOptionsMap = {
  [key: string]: IOperatorOption
}

const defaultOperatorOptions: IOperatorOptionsMap = {
  [ComparisonOperator.EQ]: {
    label: 'is',
    value: ComparisonOperator.EQ,
  },
  [ComparisonOperator.NEQ]: {
    label: 'is not',
    value: ComparisonOperator.NEQ,
  },
  [ComparisonOperator.EXISTS]: {
    label: 'has value',
    value: ComparisonOperator.EXISTS,
  },
  [ComparisonOperator.DNE]: {
    label: 'is null',
    value: ComparisonOperator.DNE,
  },
}

const getComparisonOptions = (
  attributeType?: ContactAttributeType
): IOperatorOption[] => {
  switch (attributeType) {
    case ContactAttributeType.DATE:
      return Object.values({
        ...defaultOperatorOptions,
        [ComparisonOperator.GT]: {
          label: 'is after',
          value: ComparisonOperator.GT,
        },
        [ComparisonOperator.LT]: {
          label: 'is before',
          value: ComparisonOperator.LT,
        },
      })

    case ContactAttributeType.EMAIL:
    case ContactAttributeType.URL:
    case ContactAttributeType.PHONE:
      return Object.values({
        ...defaultOperatorOptions,
        [ComparisonOperator.CONTAINS]: {
          label: 'contains',
          value: ComparisonOperator.CONTAINS,
        },
        [ComparisonOperator.NOT_CONTAINS]: {
          label: 'does not contain',
          value: ComparisonOperator.NOT_CONTAINS,
        },
        [ComparisonOperator.STARTS_WITH]: {
          label: 'starts with',
          value: ComparisonOperator.STARTS_WITH,
        },
        [ComparisonOperator.ENDS_WITH]: {
          label: 'ends with',
          value: ComparisonOperator.ENDS_WITH,
        },
      })
    case ContactAttributeType.TEXT:
      return Object.values({
        ...defaultOperatorOptions,
        [ComparisonOperator.CONTAINS]: {
          label: 'contains',
          value: ComparisonOperator.CONTAINS,
        },
        [ComparisonOperator.NOT_CONTAINS]: {
          label: 'does not contain',
          value: ComparisonOperator.NOT_CONTAINS,
        },
        [ComparisonOperator.IN_LIST]: {
          label: 'is in list',
          value: ComparisonOperator.IN_LIST,
        },
        [ComparisonOperator.NOT_IN_LIST]: {
          label: 'is not in list',
          value: ComparisonOperator.NOT_IN_LIST,
        },
        [ComparisonOperator.STARTS_WITH]: {
          label: 'starts with',
          value: ComparisonOperator.STARTS_WITH,
        },
        [ComparisonOperator.ENDS_WITH]: {
          label: 'ends with',
          value: ComparisonOperator.ENDS_WITH,
        },
      })

    case ContactAttributeType.JSON_STRING_ARRAY:
      return Object.values({
        [ComparisonOperator.INCLUDES]: {
          label: 'includes',
          value: ComparisonOperator.INCLUDES,
        },
        [ComparisonOperator.NOT_INCLUDES]: {
          label: 'does not include',
          value: ComparisonOperator.NOT_INCLUDES,
        },
      })

    case ContactAttributeType.NUMBER:
      return Object.values({
        ...defaultOperatorOptions,
        [ComparisonOperator.LT]: {
          label: 'is less than',
          value: ComparisonOperator.LT,
        },
        [ComparisonOperator.GT]: {
          label: 'is greater than',
          value: ComparisonOperator.GT,
        },
      })

    case ContactAttributeType.BOOLEAN:
    case ContactAttributeType.MULTI_CHOICE:
    default:
      return Object.values(defaultOperatorOptions)
  }
}

interface IOperatorSelectProps {
  row: IContactFilterFormRow
  dataType?: ContactAttributeType
  onChange: (value: string) => void
  error?: boolean
  readOnly?: boolean
}

export const OperatorSelect = ({
  row,
  dataType,
  onChange,
  error,
  readOnly = false,
}: IOperatorSelectProps) => {
  if (isGroup(row) || !dataType) {
    return (
      <Select
        isDisabled={true}
        className="disabled-input"
        placeholder=""
        readOnly={readOnly}
      />
    )
  }

  const options = getComparisonOptions(dataType)
  const selectedOption = options.find(opt => opt.value === row.operator)
  return (
    <Select<IOperatorOption>
      key={row.uuid}
      options={options}
      value={selectedOption || null}
      error={error}
      explicitPreventOverlap={true}
      onChange={opt => {
        if (Array.isArray(opt) || opt == null) {
          return
        }
        onChange(opt.value)
      }}
      isDisabled={readOnly}
      readOnly={readOnly}
    />
  )
}
