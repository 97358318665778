import { combineReducers } from 'redux'

import conversationsList from 'page/conversations-v2/ConversationList/reducer'
import actionConfirmationModal from 'page/conversations-v2/ConversationList/ConversationHeader/ActionsConfirmationModal/reducer'
import conversationListFilters from 'page/conversations-v2/ConversationList/ConversationHeader/reducer'
import conversationDetails from 'page/conversations-v2/ConversationsDetail/reducer'
import conversationFilters from 'page/conversations-v2/ConversationFilters/reducer'

const reducers = combineReducers({
  conversationsList,
  actionConfirmationModal,
  conversationListFilters,
  conversationDetails,
  conversationFilters,
})

export { reducers }
