import { RootState as IState } from 'store/store'
import { createSelector } from 'reselect'
import first from 'lodash/first'
import last from 'lodash/last'
import sortBy from 'lodash/sortBy'
import moment from 'moment'

const getCsvImportsHash = (state: IState) => {
  return state.importLabel.byId
}

const getCsvImports = createSelector(
  getCsvImportsHash,
  (state: IState) => state.importLabel.allIds,
  (csvImports, ids) => {
    return ids.map(x => csvImports[x])
  }
)

export interface IImportLabelCounts {
  total: number
  create: number
  update: number
  'phone-update': number
}
export interface IImportLabelDropdownOption {
  label: string
  value: string
  date: string
  counts: IImportLabelCounts
}

export const getAllAutoImportLabels: (
  state: IState
) => { [key: string]: IImportLabelDropdownOption } = createSelector(
  getCsvImports,
  csvImports => {
    return csvImports.reduce((acc, x) => {
      const maybeLabel = first(x.labels.filter(y => y.auto))
      if (maybeLabel == null) {
        return acc
      }
      const reportName = x.reportName || x.csvName

      return {
        ...acc,
        [maybeLabel.name]: {
          label: `${reportName}`,
          value: maybeLabel.name,
          date: moment(x.createdAt).format('MM/DD/YYYY h:mma'),
          counts: {
            total: x.numTotalContacts,
            create: x.numContactsCreated,
            update: x.numContactsUpdated,
            'phone-update': x.numContactsPhoneUpdated,
          },
        },
      }
    }, {})
  }
)

export const getLatestImport = createSelector(getCsvImports, csvImports =>
  last(sortBy(csvImports, 'createdAt'))
)

export const getImportLabelFetchStatus = (state: IState) =>
  state.importLabel.status
