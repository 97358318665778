import * as Sentry from '@sentry/browser'
import { datadogRum } from '@datadog/browser-rum'

import {
  IAuthActions,
  loggingOut,
  changePassword,
  login,
  setLoginMessage,
} from 'store/auth/actions'
import { getType } from 'typesafe-actions'
import * as ACTION_TYPES from 'store/triage/profile/actionTypes'
import { IProfileActions } from 'store/triage/profile/actions'

export interface IAuthState {
  readonly authed: boolean
  readonly userID: string
  readonly userEmail: string | null
  readonly userName: string | null
  readonly orgSlug: string
  readonly loggingOutStatus: LoadingStatus
  readonly changePasswordStatus: LoadingStatus
  readonly loginRequiredMessage: string
}

export const enum LoadingStatus {
  Initial,
  Loading,
  Failure,
}

export const UNSET_ID = ''

const defaultState: IAuthState = {
  authed: false,
  orgSlug: UNSET_ID,
  userID: UNSET_ID,
  userEmail: null,
  userName: null,
  loggingOutStatus: LoadingStatus.Initial,
  changePasswordStatus: LoadingStatus.Initial,
  loginRequiredMessage: '',
}

const auth = (
  state = defaultState,
  action: IAuthActions | IProfileActions
): IAuthState => {
  switch (action.type) {
    case ACTION_TYPES.SWITCH_CURRENT_INSTITUTION_SUCCESS: {
      return {
        ...state,
        orgSlug: action.payload.id,
      }
    }
    case getType(login): {
      Sentry.configureScope(scope =>
        scope.setUser({
          id: action.payload.id,
          name: action.payload.profile.name,
          email: action.payload.emails[0].address,
          org: action.payload.currentInstitution,
        })
      )
      datadogRum.setUser({
        id: action.payload.id,
        email: action.payload.emails[0].address ?? '',
        name: action.payload.profile.name ?? '',
        botId: action.payload.currentInstitution ?? '',
      })

      return {
        ...state,
        authed: true,
        orgSlug: action.payload.currentInstitution,
        userID: action.payload.id,
        loginRequiredMessage: '',
        userEmail: action.payload.emails[0].address,
        userName: action.payload.profile.name ?? null,
      }
    }
    case getType(loggingOut.success):
      Sentry.configureScope(scope => scope.setUser(null))
      return {
        ...state,
        authed: false,
        userID: '',
        userEmail: '',
        userName: '',
        loggingOutStatus: LoadingStatus.Initial,
      }
    case getType(loggingOut.request):
      return { ...state, loggingOutStatus: LoadingStatus.Loading }
    case getType(loggingOut.failure):
      return { ...state, loggingOutStatus: LoadingStatus.Failure }
    case getType(changePassword.request):
      return { ...state, changePasswordStatus: LoadingStatus.Loading }
    case getType(changePassword.success):
      return { ...state, changePasswordStatus: LoadingStatus.Initial }
    case getType(changePassword.failure):
      return { ...state, changePasswordStatus: LoadingStatus.Failure }
    case getType(setLoginMessage):
      return { ...state, loginRequiredMessage: action.payload }
    default:
      return state
  }
}

export default auth
