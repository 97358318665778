import {
  createStandardAction,
  createAsyncAction,
  ActionType,
} from 'typesafe-actions'
import {
  IImportReport,
  IImportReportListOrdering,
} from 'store/import-reports/reducer'
import { IPaginatedResponse } from 'api/response'
import { IApiError } from 'api'

export const listImportReports = createAsyncAction(
  '@@@mascot/import-reports/LIST_IMPORT_REPORTS_START',
  '@@@mascot/import-reports/LIST_IMPORT_REPORTS_END',
  '@@@mascot/import-reports/LIST_IMPORT_REPORTS_FAIL'
)<number, IPaginatedResponse<IImportReport[]>, IApiError>()

export const getImportReport = createAsyncAction(
  '@@@mascot/import-reports/GET_IMPORT_REPORT_START',
  '@@@mascot/import-reports/GET_IMPORT_REPORT_END',
  '@@@mascot/import-reports/GET_IMPORT_REPORT_FAIL'
)<string, IImportReport, IApiError>()

export const updateSearchQuery = createStandardAction(
  '@@mascot/import-reports/UPDATE_SEARCH_QUERY'
)<string>()

export const updateOrdering = createStandardAction(
  '@@mascot/import-reports/UPDATE_ORDERING'
)<IImportReportListOrdering>()

export const updatePageSize = createStandardAction(
  '@@mascot/import-reports/UPDATE_PAGE_SIZE'
)<number>()

export const importReportsInit = createStandardAction(
  '@@mascot/import-reports/init'
)()

export type IImportReportActions =
  | ActionType<typeof listImportReports>
  | ActionType<typeof getImportReport>
  | ActionType<typeof updateSearchQuery>
  | ActionType<typeof updateOrdering>
  | ActionType<typeof updatePageSize>
  | ActionType<typeof importReportsInit>
