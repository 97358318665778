export function MobileIcon({
  className,
  fill = '#536D92',
  width = '12',
  height = '17',
}: {
  readonly className?: string
  readonly fill?: string
  readonly width?: string
  readonly height?: string
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 17"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M9.75 0.728203L2.25 0.720703C1.425 0.720703 0.75 1.3957 0.75 2.2207V15.7207C0.75 16.5457 1.425 17.2207 2.25 17.2207H9.75C10.575 17.2207 11.25 16.5457 11.25 15.7207V2.2207C11.25 1.3957 10.575 0.728203 9.75 0.728203ZM9.75 14.2207H2.25V3.7207H9.75V14.2207Z" />
    </svg>
  )
}
