import { getType } from 'typesafe-actions'
import {
  IActivityLogActions,
  fetchActivityLogs,
  fetchActivityLogTotals,
  markActivityLog,
  reportActivityLog,
} from 'store/triage/activity-log/actions'
import {
  IActivityLogResponseData,
  IActivityLogTotalsResponseData,
  IAiLogResponseData,
  IUserResponseData,
} from 'api/response'
import * as _ from 'lodash'

const initialState = {
  allIds: [],
  byId: {},
  contactsById: {},
  usersById: {},
  aiLogsById: {},
  totals: {
    actions: {},
    total: 0,
  },
  pager: {
    count: 0,
  },
  loadingLogs: false,
  loadingTotals: false,
  markingLog: false,
  reportingLog: false,
}

export interface IActivityLogState {
  allIds: string[]
  byId: {
    [id: string]: IActivityLogResponseData
  }
  contactsById: {
    readonly [id: string]:
      | {
          readonly name: {
            readonly first: string
          }
        }
      | undefined
  }
  usersById: {
    [id: string]: IUserResponseData | undefined
  }
  aiLogsById: {
    [id: string]: IAiLogResponseData
  }
  totals: IActivityLogTotalsResponseData
  pager: {
    count: number
  }
  loadingLogs: boolean
  loadingTotals: boolean
  markingLog: boolean
  reportingLog: boolean
}

const reducer = (
  state: IActivityLogState = initialState,
  action: IActivityLogActions
): IActivityLogState => {
  switch (action.type) {
    case getType(fetchActivityLogs.request): {
      return {
        ...state,
        loadingLogs: true,
      }
    }
    case getType(fetchActivityLogs.success): {
      return {
        ...state,
        allIds: action.payload.results.activityLogs.map(x => x.id),
        byId: _.keyBy(action.payload.results.activityLogs, x => x.id),
        usersById: _.keyBy(action.payload.results.users, x => x.id),
        contactsById: _.keyBy(action.payload.results.contacts, x => x.id),
        aiLogsById: _.keyBy(action.payload.results.aiLogs, x => x.id),
        pager: {
          count: action.payload.count,
        },
        loadingLogs: false,
      }
    }
    case getType(fetchActivityLogs.failure): {
      return {
        ...state,
        loadingLogs: false,
      }
    }
    case getType(fetchActivityLogTotals.request): {
      return { ...state, loadingTotals: true }
    }
    case getType(fetchActivityLogTotals.success): {
      return {
        ...state,
        totals: {
          ...action.payload,
        },
        loadingTotals: false,
      }
    }
    case getType(fetchActivityLogTotals.failure): {
      return { ...state, loadingTotals: false }
    }
    case getType(markActivityLog.request): {
      return {
        ...state,
        markingLog: true,
      }
    }
    case getType(markActivityLog.success): {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
          },
        },
        markingLog: false,
      }
    }
    case getType(markActivityLog.failure): {
      return {
        ...state,
        markingLog: false,
      }
    }
    case getType(reportActivityLog.request): {
      return {
        ...state,
        reportingLog: true,
      }
    }
    case getType(reportActivityLog.success): {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
          },
        },
        reportingLog: false,
      }
    }
    case getType(reportActivityLog.failure): {
      return {
        ...state,
        reportingLog: false,
      }
    }
    default:
      return state
  }
}

export default reducer
