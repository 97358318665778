import * as React from 'react'
import classNames from 'classnames'

export interface IInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean
  height?: 'sm' | 'md' | 'lg'
  plainText?: boolean
}

export const Input = React.forwardRef(
  (
    {
      error,
      height = 'md',
      plainText = false,
      autoComplete = 'off',
      ...props
    }: IInputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    // for info on the 'is-invalid' class, see:
    // https://getbootstrap.com/docs/4.1/components/forms/#server-side
    const className = classNames(
      [props.className],
      'form-control',
      `form-control-${height}`,
      {
        'is-invalid': error,
        'form-control-plaintext': plainText,
      }
    )

    return <input ref={ref} {...props} className={className} />
  }
)
