import * as React from 'react'
import ClassNames from 'classnames'
import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'

export type AHIconType =
  | 'Logo-Inverted-Lockup'
  | 'Logo-Inverted-Only'
  | 'Logo-Full'
  | 'Logo-Text-Only'
  | 'admithub-logo'
  | 'bot'
  | 'menu'
  | 'dashboard'
  | 'conversation'
  | 'knowledge-base'
  | 'campaign'
  | 'people'
  | 'search'
  | 'expand_less'
  | 'double-arrow-left'
  | 'expand_more'
  | 'navigate_before'
  | 'navigate_next'
  | 'arrow_drop_down'
  | 'check'
  | 'close'
  | 'add'
  | 'arrow_upward'
  | 'arrow_downward'
  | 'arrow_forward'
  | 'arrow_back'
  | 'arrow_drop_up'
  | 'chat_bubble'
  | 'chat_bubble_outline'
  | 'comment'
  | 'cached'
  | 'phone'
  | 'school'
  | 'home'
  | 'open_in_new'
  | 'mobile_phone'
  | 'visibility'
  | 'reply'
  | 'sort'
  | 'swap_vert'
  | 'thumb_down'
  | 'thumb_up'
  | 'thumbs_up_down'
  | 'tv'
  | 'verified_user'
  | 'check_box'
  | 'check_box_outline_blank'
  | 'do_not_disturb'
  | 'error'
  | 'calendar_day'
  | 'cancel'
  | 'highlight_off'
  | 'file'
  | 'laptop'
  | 'indeterminate_check_box'
  | 'radio_button_checked'
  | 'radio_button_unchecked'
  | 'Toggle-off'
  | 'Toggle-on'
  | 'lock_open'
  | 'lock'
  | 'more_horiz'
  | 'more_vert'
  | 'sentiment_very_satisfied'
  | 'tag_faces'
  | 'sentiment_satisfied'
  | 'sentiment_neutral'
  | 'sentiment_dissatisfied'
  | 'sentiment_very_dissatisfied'
  | 'settings'
  | 'account_box'
  | 'account_circle'
  | 'person'
  | 'new_releases'
  | 'bookmark-selected'
  | 'bookmark-empty'
  | 'device_hub'
  | 'send'
  | 'add_circle'
  | 'add_circle_outline'
  | 'remove_circle'
  | 'remove_circle_outline'
  | 'image'
  | 'link_off'
  | 'link'
  | 'check_circle'
  | 'check_circle_outline'
  | 'help'
  | 'help_outline'
  | 'info'
  | 'info_outline'
  | 'edit'
  | 'flag'
  | 'outlined-flag'
  | 'question-summary'
  | 'question'
  | 'answer'
  | 'delete'
  | 'Stats'
  | 'Book'
  | 'print'
  | 'mail-outline'
  | 'infinite'
  | 'rss'
  | 'youtube'
  | 'pocket'
  | 'medium'
  | 'github'
  | 'instagram'
  | 'facebook'
  | 'linkedin'
  | 'twitter'
  | 'messenger'
  | 'file_upload'
  | 'file_download'
  | 'get_app'
  | 'cloud_upload'
  | 'backup'
  | 'cloud_download'
  | 'file_download_done'
  | 'person_add'
  | 'drag_indicator'
  | 'drag_handle'
  | 'translate'
  | 'pin'
  | 'forward'
  | 'copy'
  | 'label'
  | 'label_off'
  | 'trending_up'
  | 'visibility_off'
  | 'historyrestore'
  | 'notes'
  | 'multiple-choice'
  | 'filter'
  | 'minimize'
  | 'pause'
  | 'reset'
  | 'attribute'
  | 'contacts-inverse'
  | 'script-inverse'
  | 'campaign-inverse'
  | 'knowledge-base-inverse'
  | 'attribute-inverse'
  | 'collection'
  | 'archive'

export const ICON_TYPES: AHIconType[] = [
  'Logo-Inverted-Lockup',
  'Logo-Inverted-Only',
  'Logo-Full',
  'Logo-Text-Only',
  'admithub-logo',
  'bot',
  'menu',
  'dashboard',
  'conversation',
  'knowledge-base',
  'campaign',
  'people',
  'search',
  'expand_less',
  'double-arrow-left',
  'expand_more',
  'navigate_before',
  'navigate_next',
  'arrow_drop_down',
  'check',
  'close',
  'add',
  'arrow_upward',
  'arrow_downward',
  'arrow_forward',
  'arrow_back',
  'arrow_drop_up',
  'chat_bubble',
  'chat_bubble_outline',
  'comment',
  'cached',
  'phone',
  'school',
  'home',
  'open_in_new',
  'mobile_phone',
  'visibility',
  'reply',
  'sort',
  'swap_vert',
  'thumb_down',
  'thumb_up',
  'thumbs_up_down',
  'tv',
  'verified_user',
  'check_box',
  'check_box_outline_blank',
  'do_not_disturb',
  'error',
  'calendar_day',
  'cancel',
  'highlight_off',
  'file',
  'laptop',
  'indeterminate_check_box',
  'radio_button_checked',
  'radio_button_unchecked',
  'Toggle-off',
  'Toggle-on',
  'lock_open',
  'lock',
  'more_horiz',
  'more_vert',
  'sentiment_very_satisfied',
  'tag_faces',
  'sentiment_satisfied',
  'sentiment_neutral',
  'sentiment_dissatisfied',
  'sentiment_very_dissatisfied',
  'settings',
  'account_box',
  'account_circle',
  'person',
  'new_releases',
  'bookmark-selected',
  'bookmark-empty',
  'device_hub',
  'send',
  'add_circle',
  'add_circle_outline',
  'remove_circle',
  'remove_circle_outline',
  'image',
  'link_off',
  'link',
  'check_circle',
  'check_circle_outline',
  'help',
  'help_outline',
  'info',
  'info_outline',
  'edit',
  'flag',
  'outlined-flag',
  'question-summary',
  'question',
  'answer',
  'delete',
  'Stats',
  'Book',
  'print',
  'mail-outline',
  'infinite',
  'rss',
  'youtube',
  'pocket',
  'medium',
  'github',
  'instagram',
  'facebook',
  'linkedin',
  'twitter',
  'messenger',
  'file_upload',
  'file_download',
  'get_app',
  'cloud_upload',
  'backup',
  'cloud_download',
  'file_download_done',
  'person_add',
  'drag_indicator',
  'drag_handle',
  'translate',
  'pin',
  'forward',
  'copy',
  'label',
  'label_off',
  'trending_up',
  'visibility_off',
  'historyrestore',
  'notes',
  'multiple-choice',
  'filter',
  'minimize',
  'pause',
  'attribute',
  'contacts-inverse',
  'script-inverse',
  'campaign-inverse',
  'knowledge-base-inverse',
  'attribute-inverse',
  'collection',
  'archive',
  'reset',
]

interface IAHIconProps {
  readonly ariaExpanded?: boolean
  readonly ariaHaspopup?: boolean
  readonly ariaHidden?: boolean
  readonly ariaLabel?: string
  readonly ariaPressed?: 'true' | 'false' | 'mixed'
  readonly name: AHIconType | null
  readonly className?: string
  readonly children?: React.ReactNode
  readonly onClick?: () => void
  readonly style?: React.CSSProperties
  readonly rotate?: number
  readonly overrideWidth?: boolean
  readonly flipX?: boolean
  readonly flipY?: boolean
  readonly tabIndex?: number
  readonly eventLocation?: string
  readonly eventObject?: string
  readonly eventAction?: EventAction
}

export const AHIcon = React.forwardRef(
  (props: IAHIconProps, ref: React.Ref<HTMLSpanElement>): JSX.Element => {
    const classNames = ClassNames(
      props.className,
      'ahicon',
      `icon-${props.name}`,
      {
        'w-32px': !props.overrideWidth,
      },
      'text-center'
    )
    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
        props.onClick?.()
      }
    }
    const rotation = props.rotate ?? 0
    return (
      <EventTrackerAttrAdder
        eventLocation={props.eventLocation}
        eventAction={props.eventAction}
        eventObject={props.eventObject}>
        <span
          ref={ref}
          onClick={props.onClick}
          onKeyDown={handleKeyDown}
          // make our icon look like a button if we have an onClick handler.
          role={props.onClick ? 'button' : undefined}
          tabIndex={props.tabIndex != null || props.onClick ? 0 : undefined}
          aria-label={props.ariaLabel}
          aria-haspopup={props.ariaHaspopup}
          aria-expanded={props.ariaExpanded}
          aria-hidden={props.ariaHidden}
          aria-pressed={props.ariaPressed}
          className={classNames}
          children={props.children}
          style={{
            transform: `scale(${props.flipX ? -1 : 1},${
              props.flipY ? -1 : 1
            }) rotate(${rotation}deg)`,
            ...props.style,
          }}
        />
      </EventTrackerAttrAdder>
    )
  }
)
