import * as React from 'react'
import classnames from 'classnames'

export interface IMainstayFlexTable {
  scrollY?: boolean
  scrollX?: boolean
  className?: string
  children?: React.ReactNode
}

/**
 * The size values for columns must add up to a maximum of 12.
 */
export class MainstayFlexTable extends React.PureComponent<IMainstayFlexTable> {
  render() {
    const { scrollY, scrollX, className, children } = this.props
    return (
      <div
        className={classnames(
          'table',
          {
            'scroll-x': scrollX,
            'scroll-y': scrollY,
          },
          className
        )}>
        {children}
      </div>
    )
  }
}
