export function formatPhone(
  phone: string,
  defaultMessage = 'Missing phone number'
) {
  if (phone) {
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`
  } else {
    return defaultMessage
  }
}

export function normalizePhone(phone: string) {
  return phone
    .replace(/[\D]/, '')
    .replace(/[()]/, '')
    .replace(/^(1|0)/, '')
    .replace(/-/g, '')
}
