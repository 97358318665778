import { IActions } from 'store/store'
import {
  listContactSegmentsAction,
  fetchFilteredContacts,
  clearImportedContacts,
  clearFilterTask,
} from 'store/contact-segments/actions'
import { IContactSegmentList } from 'store/contact-segments/selectors'
import { IContact } from 'store/contacts/reducer'
import { isSuccessfulAsyncResponse } from 'api/response'
import * as ACTION_TYPES from 'store/triage/profile/actionTypes'

import {
  WebData,
  Loading,
  Success,
  Failure,
  RefetchingOrLoading,
  Pending,
} from 'store/webdata'
import { getType } from 'typesafe-actions'
import { IContactFilterRequestData } from 'api/request'

export interface IFilteredContact extends IContact {
  attributes: { [field: string]: string }
}

export interface IContactSegmentState {
  segments: WebData<IContactSegmentList>
  filteredContacts: WebData<IFilteredContact[]>
  totalRecipients: number

  countForMaxPage: WebData<number>
  lastTaskId?: string
  lastTaskFilterData?: IContactFilterRequestData
  attributeNames: string[]
  additionalContactFields: string[]
}

const initialState: IContactSegmentState = {
  segments: undefined,
  filteredContacts: undefined,
  totalRecipients: 0,
  countForMaxPage: undefined,
  attributeNames: [],
  additionalContactFields: [],
}

export const contactSegmentsReducer = (
  state: IContactSegmentState = initialState,
  action: IActions
): IContactSegmentState => {
  switch (action.type) {
    case getType(listContactSegmentsAction.request): {
      return {
        ...state,
        segments: Loading(),
      }
    }
    case getType(listContactSegmentsAction.success): {
      return {
        ...state,
        segments: Success(action.payload),
      }
    }
    case getType(listContactSegmentsAction.failure): {
      return {
        ...state,
        segments: Failure('Could not load contact segments'),
      }
    }
    case getType(fetchFilteredContacts.request): {
      return {
        ...state,
        filteredContacts: RefetchingOrLoading(state.filteredContacts),
        lastTaskFilterData: action.payload || undefined,
      }
    }
    case getType(fetchFilteredContacts.success): {
      return {
        ...state,
        lastTaskId: action.payload.task_id || undefined,
        ...(isSuccessfulAsyncResponse(action.payload)
          ? {
              filteredContacts: Success(action.payload.results),
              countForMaxPage: Success(action.payload.count),
              totalRecipients: action.payload.total_recipient_count,
              attributeNames: action.payload.attribute_names,
              additionalContactFields: action.payload.additional_contact_fields,
            }
          : { filteredContacts: Pending(action.payload.task_id) }),
      }
    }
    case getType(fetchFilteredContacts.failure): {
      return {
        ...state,
        filteredContacts: Failure('Failed to fetch imported recipients'),
        lastTaskId: undefined,
      }
    }
    case getType(clearImportedContacts): {
      return {
        ...state,
        filteredContacts: undefined,
        totalRecipients: 0,
      }
    }
    case getType(clearFilterTask.request): {
      return {
        ...state,
        lastTaskId: undefined,
      }
    }
    case ACTION_TYPES.SWITCH_CURRENT_INSTITUTION_SUCCESS:
      return initialState
    default:
      return state
  }
}
