import { getType } from 'typesafe-actions'

import { WebData, Loading, Success, Failure, isSuccess } from 'store/webdata'

import { AudienceChoice } from 'page/conversations-v2/ConversationFilters'
import {
  IActions,
  fetchAudiencesChoices,
  fetchAudiencesPinned,
  selectAudience,
} from 'page/conversations-v2/ConversationFilters/actions'

/**
 * Store for the conversations sidebar which has different filter categories.
 */
type ConversationFiltersStore = {
  /**
   * Audience folders feature.
   */
  readonly audiences: {
    /**
     * Audiences which user can select when they go to pin an audience folder.
     */
    readonly choices: WebData<AudienceChoice[]>

    /**
     * Currently pinned audiences by which user can filter.
     */
    readonly pinned: WebData<AudienceChoice[]>

    /**
     * Status of actions related to audiences.
     */
    readonly actions: {
      /**
       * Status of the selectAudience action.
       * Keys are audience IDs.
       * Values are WebData storing the status of the action. The value of the WebData values is meaningless.
       */
      readonly select: { [key: number]: WebData<boolean> }
    }
  }
}

const initialConversationFiltersStoreState: ConversationFiltersStore = {
  audiences: {
    choices: Loading(),
    pinned: Loading(),
    actions: {
      select: {},
    },
  },
}

const conversationFilters = (
  state: ConversationFiltersStore = initialConversationFiltersStoreState,
  action: IActions
): ConversationFiltersStore => {
  switch (action.type) {
    case getType(fetchAudiencesChoices.request):
      return {
        ...state,
        audiences: {
          ...state.audiences,
          choices: Loading(),
        },
      }
    case getType(fetchAudiencesChoices.success):
      return {
        ...state,
        audiences: {
          ...state.audiences,
          choices: Success(action.payload),
        },
      }
    case getType(fetchAudiencesChoices.failure):
      return {
        ...state,
        audiences: {
          ...state.audiences,
          choices: Failure(),
        },
      }
    case getType(fetchAudiencesPinned.request):
      return {
        ...state,
        audiences: {
          ...state.audiences,
          pinned: Loading(),
        },
      }
    case getType(fetchAudiencesPinned.success):
      return {
        ...state,
        audiences: {
          ...state.audiences,
          pinned: Success(action.payload),
        },
      }
    case getType(fetchAudiencesPinned.failure):
      return {
        ...state,
        audiences: {
          ...state.audiences,
          pinned: Failure(),
        },
      }
    case getType(selectAudience.request):
      return {
        ...state,
        audiences: {
          ...state.audiences,
          actions: {
            ...state.audiences.actions,
            select: {
              ...state.audiences.actions.select,
              [action.payload.id]: Loading(),
            },
          },
        },
      }
    case getType(selectAudience.success):
      // Don't allow an audience to be selected if pinned is not loaded
      const pinned = state.audiences.pinned
      if (!isSuccess(pinned)) {
        return state
      }

      const newChoice = { id: action.payload.id, name: action.payload.name }

      const sortByName = (a: AudienceChoice, b: AudienceChoice) =>
        a.name > b.name ? 1 : -1

      const choicesState = (() => {
        const choices = state.audiences.choices

        if (!isSuccess(choices)) {
          return choices
        }

        const newChoicesData = action.payload.selected
          ? choices.data.filter(choice => choice.id !== action.payload.id) // If selected: remove from choices
          : Array.from(new Set([...choices.data, newChoice])) // If unselected: make sure exists as a choice

        return Success(newChoicesData.sort(sortByName))
      })()

      const newPinnedData = action.payload.selected
        ? Array.from(new Set([...pinned.data, newChoice])) // If selected: add to pinned
        : pinned.data.filter(choice => choice.id !== action.payload.id) // If unselected: remove from pinned

      return {
        ...state,
        audiences: {
          ...state.audiences,
          choices: choicesState,
          pinned: Success(newPinnedData.sort(sortByName)),
          actions: {
            ...state.audiences.actions,
            select: {
              ...state.audiences.actions.select,
              [action.payload.id]: Success(true),
            },
          },
        },
      }
    case getType(selectAudience.failure):
      return {
        ...state,
        audiences: {
          ...state.audiences,
          actions: {
            ...state.audiences.actions,
            select: {
              ...state.audiences.actions.select,
              [action.payload.id]: Failure(),
            },
          },
        },
      }

    default:
      return state
  }
}

export default conversationFilters
