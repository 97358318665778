import { getType } from 'typesafe-actions'
import * as api from 'api'
import { fetchKnowledgeBaseTopics, IActions } from 'page/knowledge-base/actions'

type KnowledgeBaseState = {
  readonly topics: {
    readonly status: KnowledgeBaseStatus
    readonly data: api.KBTopicsShapeType
  }
}

export enum KnowledgeBaseStatus {
  initial = 'initial',
  loading = 'loading',
  error = 'error',
  ok = 'ok',
}

const initialKnowledgeBaseState: KnowledgeBaseState = {
  topics: {
    status: KnowledgeBaseStatus.initial,
    data: { existingTopics: {}, standardTopics: [] },
  },
}

const knowledgeBase = (
  state: KnowledgeBaseState = initialKnowledgeBaseState,
  action: IActions
): KnowledgeBaseState => {
  switch (action.type) {
    case getType(fetchKnowledgeBaseTopics.request):
      return {
        ...state,
        topics: {
          ...state.topics,
          status: KnowledgeBaseStatus.loading,
        },
      }
    case getType(fetchKnowledgeBaseTopics.success):
      return {
        ...state,
        topics: {
          data: action.payload,
          status: KnowledgeBaseStatus.ok,
        },
      }
    case getType(fetchKnowledgeBaseTopics.failure):
      return {
        ...state,
        topics: {
          ...state.topics,
          status: KnowledgeBaseStatus.error,
        },
      }
    default:
      return state
  }
}

export default knowledgeBase
