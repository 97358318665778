import {
  ActionType,
  createStandardAction,
  createAsyncAction,
  getType,
} from 'typesafe-actions'
import { IInstitution } from 'api/response'

export const cancelChanges = createStandardAction(
  '@@mascot/settings/prependMessageForm/cancelChanges'
)()
export const setRadioButton = createStandardAction(
  '@@mascot/settings/prependMessageForm/setRadioButton'
)<string>()
export const setTextField = createStandardAction(
  '@@mascot/settings/prependMessageForm/setTextField'
)<string>()
export const updatePrependCounselorResponse = createAsyncAction(
  '@@mascot/settings/prependMessageForm/updatePrependCounselorResponse/request',
  '@@mascot/settings/prependMessageForm/updatePrependCounselorResponse/success',
  '@@mascot/settings/prependMessageForm/updatePrependCounselorResponse/failure'
)<void, IInstitution['prependCounselorResponse'], void>()

type PrependedEscalatedMesageResponseFormActions =
  | ActionType<typeof cancelChanges>
  | ActionType<typeof updatePrependCounselorResponse>
  | ActionType<typeof setRadioButton>
  | ActionType<typeof setTextField>

export interface IPrependedEscalatedMesageResponseFormReducerState {
  readonly prev: string | null
  readonly cur: string | null
  readonly updateStatus: 'initial' | 'loading' | 'success' | 'failure'
}

export function prependedEscalatedMesageResponseFormReducer(
  state: IPrependedEscalatedMesageResponseFormReducerState,
  action: PrependedEscalatedMesageResponseFormActions
): IPrependedEscalatedMesageResponseFormReducerState {
  switch (action.type) {
    case getType(cancelChanges):
      return { ...state, cur: state.prev }
    case getType(setRadioButton): {
      if (action.payload === 'default') {
        return { ...state, cur: null }
      }
      if (state.cur === null) {
        return { ...state, cur: '' }
      }
      return state
    }
    case getType(setTextField):
      return { ...state, cur: action.payload }
    case getType(updatePrependCounselorResponse.request):
      return { ...state, updateStatus: 'loading' }
    case getType(updatePrependCounselorResponse.success): {
      const newMsg = action.payload || null
      return { ...state, updateStatus: 'success', prev: newMsg, cur: newMsg }
    }
    case getType(updatePrependCounselorResponse.failure):
      return { ...state, updateStatus: 'failure', cur: state.prev }
  }
}
