import { datadogRum } from '@datadog/browser-rum'

import * as api from 'api'

interface IJsonObject {
  [property: string]: Json
}
type Json = string | number | boolean | null | IJsonObject | Json[]

export function sendEvent(kind: string, data: IJsonObject | undefined = {}) {
  data['dataDogSessionURL'] = datadogRum.getSessionReplayLink() ?? ''
  api
    .sendEvent({
      kind,
      data,
    })
    .catch(() => {
      // pass. We ignore any errors for event tracking because it's non-essential.
    })
}
