import { ActionType, createStandardAction, getType } from 'typesafe-actions'

export interface ITriageReducerState {
  inboxCount: number | null
}

export const setInboxCount = createStandardAction(
  '@@mascot/triage/setInboxCount'
)<{ inboxCount: number }>()

export type ITriageActions = ActionType<typeof setInboxCount>

const defaultState = {
  // used for the Inbox count indicator by InboxCountBadgeConnected on the sidebar of Triage. This is set by
  // the PollingInstitutionSwitcherConnected component.
  inboxCount: null,
}

export function triageReducer(
  state: ITriageReducerState = defaultState,
  action: ITriageActions
): ITriageReducerState {
  switch (action.type) {
    case getType(setInboxCount):
      return {
        ...state,
        inboxCount: action.payload.inboxCount,
      }
    default:
      return state
  }
}
