import { isValid, parse } from 'date-fns'
import moment from 'moment'

/**
 * Return a moment date representing the very start of the given week
 *
 * @param isoWeekYear the iso year number of the desired date
 * @param isoWeek the iso week number of the desired date
 */
export function isoWeekStart(
  isoWeekYear: number,
  isoWeek: number
): moment.Moment {
  return moment()
    .isoWeekYear(isoWeekYear)
    .isoWeek(isoWeek)
    .startOf('isoWeek')
}

interface IISOTimeRange {
  start: moment.Moment
  end: moment.Moment
}

/**
 * Return moment dates representing the very start and (nearly) very end of the given iso week. The range ends
 * at 1 second prior to the end of the week so that the end date lies on Sunday rather than the true end which
 * is technically on ms 0 of Monday.
 *
 * @param isoWeekYear the iso year number of the desired date
 * @param isoWeek the iso week number of the desired date
 */

export function isoWeekRange(
  isoWeekYear: number,
  isoWeek: number
): IISOTimeRange {
  const start = moment()
    .isoWeekYear(isoWeekYear)
    .isoWeek(isoWeek)
    .startOf('isoWeek')
  return {
    start,
    end: start
      .clone()
      .add(1, 'week')
      .subtract(1, 'second'),
  }
}

export function parseDateOrDefault(
  dateString: string,
  dateFormat: string,
  defaultDate: Date
) {
  const parsedDate = parse(dateString, dateFormat, defaultDate)
  return isValid(parsedDate) ? parsedDate : defaultDate
}

export const months = Array.from({ length: 12 }, (_item, i) =>
  new Date(0, i).toLocaleString('en-US', { month: 'long' })
)
