import { IApiError } from 'api'
import { createAsyncAction, ActionType } from 'typesafe-actions'
import { ISendSingleTextRequestData } from 'api/request'

export const sendMessage = createAsyncAction(
  '@@mascot/convo/SEND_MESSAGE_REQUEST',
  '@@mascot/convo/SEND_MESSAGE_SUCCESS',
  '@@mascot/convo/SEND_MESSAGE_FAILURE'
)<
  {
    userId: string
    pendingId: string
    message: ISendSingleTextRequestData
  },
  { userId: string; pendingId: string },
  { error: IApiError; pendingId: string }
>()

export type IConversationActions = ActionType<typeof sendMessage>
