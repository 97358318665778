import * as React from 'react'

interface IWebChatIFrameOuterProps {
  botToken: string
  collegeId?: string
  onClose?: () => void
  updateButtonColor?: (color: string) => void
  updateTextColor?: (color: string) => void
  updateButtonLabel?: (labelText: string) => void
  isVisible?: boolean
  scriptDomain?: string
}

export interface IAdmitHubEventData {
  type: string
  action: string
  value: string
}

export interface IAdmitHubMessageEvent extends MessageEvent {
  readonly data: IAdmitHubEventData
}

export default function WebChatIFrameOuter({
  botToken,
  onClose,
  collegeId,
  updateButtonColor,
  updateTextColor,
  updateButtonLabel,
  isVisible,
  scriptDomain,
}: IWebChatIFrameOuterProps) {
  React.useEffect(() => {
    const handleIFrameEvents = (e: IAdmitHubMessageEvent) => {
      if (e.data.type === 'admitHubEvent') {
        const data: IAdmitHubEventData = e.data
        switch (data.action) {
          case 'updateColor':
            return updateButtonColor && updateButtonColor(data.value)
          case 'closeChat':
            return onClose && onClose()
          case 'setButtonLabel':
            return updateButtonLabel && updateButtonLabel(data.value)
          case 'updateTextColor':
            return updateTextColor && updateTextColor(data.value)
        }
      }
    }
    window.addEventListener('message', handleIFrameEvents)
    return () => {
      window.removeEventListener('message', handleIFrameEvents)
    }
  }, [onClose, updateButtonColor, updateTextColor, updateButtonLabel])

  React.useEffect(() => {
    if (isVisible) {
      /* eslint-disable @typescript-eslint/consistent-type-assertions */
      const iframe = document.getElementById(
        'admithub_webchat_iframe'
      ) as HTMLIFrameElement | null
      if (iframe && iframe.contentWindow) {
        const postMessageData = {
          type: 'admitHubParentEvent',
          action: 'openChat',
        }
        iframe.contentWindow.postMessage(postMessageData, '*')
      }
    }
  }, [isVisible])

  let iframeUrl = `${scriptDomain || ''}/webchatIframe.html?token=${botToken}`
  if (collegeId) {
    iframeUrl += `&collegeId=${collegeId}`
  }

  return (
    <iframe
      src={iframeUrl}
      title="Web Chat"
      id="admithub_webchat_iframe"
      aria-label="Web Chat - Talk to our bot"
      className="webchat-iframe">
      Please refresh the browser with i-frames enabled in order to see the web
      chat client.
    </iframe>
  )
}
