import { Button } from 'components/Button/Button'
import { LabeledTextInput } from 'components/LabeledInput/LabeledInput'
import { FieldProps } from 'formik'
import { IContactFilterFormData } from 'components/ContactFilterBuilder/formUtils'
import { FieldWithErrorTooltip } from 'components/ContactFilterBuilder/FilterRow'

interface ISubmitFilterProps {
  disableSave?: boolean
  onSubmit?: () => void
  onCancel: () => void
  onMouseEnter: () => void
  showErrors?: boolean
}

export const SubmitFilter = ({
  disableSave,
  onSubmit,
  onCancel,
  onMouseEnter,
  showErrors,
}: ISubmitFilterProps) => {
  const eventLocation: string = 'audiences'
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="w-25 mb-3">
        <FieldWithErrorTooltip
          name="name"
          showErrors={showErrors}
          render={({ field }: FieldProps<IContactFilterFormData>, hasError) => (
            <LabeledTextInput
              {...field}
              label="Save Audience as..."
              error={hasError}
              eventAction="click"
              eventObject="audience name"
              eventLocation={eventLocation}
            />
          )}
        />
      </div>

      <div className="d-flex flex-row" onMouseEnter={onMouseEnter}>
        <Button
          className="btn btn-secondary-teal px-3 py-2"
          onClick={onSubmit}
          type="submit"
          disabled={disableSave}
          eventAction="click"
          eventLocation={eventLocation}
          eventObject="save audience">
          Save and continue
        </Button>
        <Button
          className="ml-2 btn text-mainstay-dark-blue-65 border-color-mainstay-dark-blue-65 border-1px bg-white px-3 py-2"
          onClick={onCancel}
          eventAction="click"
          eventObject="audience builder modal cancel"
          eventLocation={eventLocation}>
          Cancel
        </Button>
      </div>
    </div>
  )
}
