import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import classnames from 'classnames'

interface IAddNodeMenuButtonProps {
  onClick: () => void
  className?: string
}

export default function AddNodeMenuButton({
  onClick,
  className,
}: IAddNodeMenuButtonProps) {
  return (
    <h5
      onClick={onClick}
      className={classnames(
        'text-mainstay-dark-blue-50 opacity-50 hover-text-secondary-teal fs-1rem mx-4 hover-opacity-100 pointer m-0',
        className
      )}>
      <AHIcon name="add" className="icon-height-adjust" /> Add
    </h5>
  )
}
