import * as React from 'react'

export type EventAction = 'click' | 'view' | 'submit' | 'change'

export interface ITrackingEvent {
  location?: string
  action?: EventAction
  object?: string
}

export interface IEventTrackerEventDetail {
  eventLocation?: string
  eventObject?: string
  eventAction?: EventAction
}
interface IEventTrackerProps extends IEventTrackerEventDetail {
  children: JSX.Element
  disabled?: boolean
}

export const EventTrackerAttrAdder = ({
  eventLocation,
  eventObject,
  eventAction,
  children,
  disabled,
}: IEventTrackerProps) => {
  /*
   * This wrapping component renders its children without adding any extra DOM nodes.
   * Its role is only to optionally add a `data-heapEvent` attribute to its children in accordance with Heap's API.
   */

  const eventName =
    eventAction && eventObject
      ? `${eventLocation ||
          window.location.pathname.split(
            '/'
          )[1]} - ${eventAction} - ${eventObject}`.toLowerCase()
      : null

  // only allow lowercase alpha-num chars, spaces, and hyphens
  const validRegex = new RegExp(/^[a-z0-9\s\-]*$/)

  return !disabled && eventName && validRegex.test(eventName)
    ? React.cloneElement(children, { 'data-heapevent': eventName })
    : children
}
