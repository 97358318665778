import React, { memo } from 'react'

import classnames from 'classnames'
import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'
import { ArrowDropDownIcon } from 'components/Icons/ArrowDropDownIcon/ArrowDropDownIcon'

export interface IMainstayFlexTableRowProps {
  className?: string
  children?: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  style?: React.CSSProperties
  noBorder?: boolean
  eventObject?: string
  eventLocation?: string
  eventAction?: EventAction
  expanded?: boolean
  expandableRow?: React.ReactNode
  onToggle?: (expanded: boolean) => void
}

export const MainstayFlexTableRow = memo(
  (props: IMainstayFlexTableRowProps) => {
    const [expanded, setExpanded] = React.useState<boolean>(!!props.expanded)

    return (
      <EventTrackerAttrAdder
        eventLocation={props.eventLocation}
        eventAction={props.eventAction}
        eventObject={props.eventObject}>
        <div>
          <div
            onClick={e => {
              if (props.expandableRow) {
                setExpanded(prev => {
                  if (props.onToggle) {
                    props.onToggle(!prev)
                  }
                  return !prev
                })
              }
              return props.onClick && props.onClick(e)
            }}
            style={props.style}
            className={classnames(
              'row',
              'flex-nowrap',
              'no-gutters',
              props.className,
              { 'border-bottom': !props.noBorder },
              { pointer: !!props.expandableRow }
            )}>
            {props.expandableRow && (
              <div className="d-flex align-items-center">
                <ArrowDropDownIcon collapsed={!expanded} />
              </div>
            )}{' '}
            {props.children}
          </div>
          {expanded && props.expandableRow}
        </div>
      </EventTrackerAttrAdder>
    )
  }
)

export class MainstayFlexTableHeader extends React.PureComponent<
  IMainstayFlexTableRowProps
> {
  render() {
    return (
      <MainstayFlexTableRow
        {...this.props}
        style={this.props.style}
        className={classnames(
          'border-mainstay-dark-blue border-1px',
          this.props.className
        )}
      />
    )
  }
}

export class MainstayFlexTableSubHeader extends React.PureComponent<
  IMainstayFlexTableRowProps
> {
  render() {
    return (
      <MainstayFlexTableRow
        {...this.props}
        style={this.props.style}
        className={classnames(
          'text-muted',
          'border-blue-grey-200',
          'border-top',
          this.props.className
        )}
      />
    )
  }
}
