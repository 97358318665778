import { ValueType } from 'react-select/lib/types'
import momentTZ from 'moment-timezone'
import classnames from 'classnames'
import Select from 'components/Select/SelectV2'

export type SelectInputValue = { value: string; label: string }

export default function TimezoneSelect({
  onSelect,
  placeholder = 'select',
  selectedOption,
  onClear,
  className,
}: {
  onSelect: (value: SelectInputValue | null) => void
  onClear?: () => void
  placeholder: string
  selectedOption?: SelectInputValue | null
  className?: string
}) {
  return (
    <Select
      className={classnames('form-control', 'p-0', 'border-0', className)}
      classNamePrefix="react-select"
      name="targetAttrs"
      placeholder={placeholder}
      value={selectedOption}
      onChange={(value: ValueType<SelectInputValue>, actionMeta) => {
        if (Array.isArray(value)) {
          throw Error('invalid type for select. Expected object, found array.')
        }
        if (onClear && actionMeta.action === 'clear') {
          return onClear()
        }
        onSelect(value || null)
      }}
      options={momentTZ.tz
        .names()
        .map(timezone => ({ label: timezone, value: timezone }))}
      closeMenuOnSelect={true}
      isClearable={false}
    />
  )
}
