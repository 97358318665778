import {
  ICounselors,
  IEscalationRule,
  IEscalationRules,
  IEscalationUnderstandings,
} from 'store/escalation/selectors'
import {
  getCounselors,
  IEscalationActions,
  getRules,
  deleteCounselor,
  createCounselor,
  updateCounselor,
  saveRule,
  prioritizeRules,
  updateRules,
  ruleType,
  deleteRule,
  getEscalationUnderstandings,
} from 'store/escalation/actions'
import { getType } from 'typesafe-actions'
import {
  IEscalationRuleResponseData,
  ICounselorResponseData,
  IEscalationUnderstandingResponseData,
} from 'api/response'
import {
  WebData,
  Loading,
  Refetching,
  Success,
  Failure,
  isSuccess,
  isSuccessOrRefetching,
} from 'store/webdata'
import { CancelTokenSource } from 'typings/axios'

export const enum WebDataStatus {
  Initial,
  Loading,
  Failure,
}
interface IEscalationState {
  counselors: WebData<ICounselors>
  sensitiveUnderstandings: WebData<IEscalationUnderstandings>
  usedUnderstandings: WebData<IEscalationUnderstandings>
  loadingRules: WebDataStatus
  autoRules: IEscalationRules
  manualRules: IEscalationRules
  rulesRequestCancelTokenSources: CancelTokenSource[]
}

const INITIAL_ESCALATION_STATE: IEscalationState = {
  counselors: Success([]),
  sensitiveUnderstandings: Success([]),
  usedUnderstandings: Loading(),
  loadingRules: WebDataStatus.Initial,
  autoRules: [],
  manualRules: [],
  rulesRequestCancelTokenSources: [],
}

const mapRuleResponse = (
  rule: IEscalationRuleResponseData
): IEscalationRule => {
  return {
    id: rule.id,
    name: rule.name,
    type: rule.type,
    counselors: rule.counselors,
    priority: rule.priority,
    contactFilterId: rule.contact_filter,
    conditions: rule.conditions.map(condition => {
      return {
        id: condition.id,
        type: condition.type,
        field: condition.field,
        value: condition.value,
        operator: condition.operator,
      }
    }),
  }
}

const mapResponseToCounselors = (response: ICounselorResponseData[]) =>
  response.map(x => ({
    id: x.id,
    collegeId: x.college_id,
    name: x.name,
    email: x.email,
    reason: x.reason,
  }))

const insertRule = (
  newRule: IEscalationRule,
  state: IEscalationState
): IEscalationState => {
  const ruleSet = newRule.type === 'AUTOMATIC' ? 'autoRules' : 'manualRules'
  const newRules = state[ruleSet].map(rule => {
    return rule.id === newRule.id ? newRule : rule
  })
  return {
    ...state,
    [ruleSet]: newRules,
  }
}

const sortRules = (rules: IEscalationRules): IEscalationRules =>
  rules.sort((a, b) => {
    if (a.priority && b.priority) {
      return b.priority - a.priority
    }
    return -1
  })

const understandingMapper = (
  understanding: IEscalationUnderstandingResponseData
): IEscalationUnderstandings[0] => {
  return {
    mongoId: understanding.mongo_id,
    sampleQuestion: understanding.sample_question,
    topic: understanding.topic,
  }
}

const reducer = (
  state: IEscalationState = INITIAL_ESCALATION_STATE,
  action: IEscalationActions
): IEscalationState => {
  switch (action.type) {
    case getType(getEscalationUnderstandings.request): {
      return {
        ...state,
        sensitiveUnderstandings: Loading(),
        usedUnderstandings: Loading(),
      }
    }
    case getType(getEscalationUnderstandings.success): {
      return {
        ...state,
        sensitiveUnderstandings: Success(
          action.payload.sensitive.map(understandingMapper)
        ),
        usedUnderstandings: Success(
          action.payload.used.map(understandingMapper)
        ),
      }
    }
    case getType(getEscalationUnderstandings.failure): {
      return {
        ...state,
        sensitiveUnderstandings: Failure('Failed to fetch data'),
        usedUnderstandings: Failure('Failed to fetch data'),
      }
    }
    // TODO (Manan) - Handle the state of search adding searchUnderstandings
    case getType(getCounselors.request): {
      return {
        ...state,
        counselors: Loading(),
      }
    }
    case getType(getCounselors.success): {
      return {
        ...state,
        counselors: Success(
          action.payload.map(counselor => {
            return {
              id: counselor.id,
              name: counselor.name,
              email: counselor.email,
              reason: counselor.reason,
              collegeId: counselor.college_id,
            }
          })
        ),
      }
    }
    case getType(getCounselors.failure): {
      return { ...state, counselors: Failure('Failed to fetch data') }
    }
    case getType(getRules.request): {
      return {
        ...state,
        autoRules: [],
        manualRules: [],
        loadingRules: WebDataStatus.Loading,
      }
    }
    case getType(getRules.success): {
      return {
        ...state,
        autoRules: action.payload.AUTOMATIC.map(mapRuleResponse),
        manualRules: action.payload.MANUAL.map(mapRuleResponse),
        loadingRules: WebDataStatus.Initial,
      }
    }
    case getType(getRules.failure): {
      return {
        ...state,
        autoRules: [],
        manualRules: [],
        loadingRules: WebDataStatus.Failure,
      }
    }
    case getType(deleteRule.request):
      return {
        ...state,
        loadingRules: WebDataStatus.Loading,
      }
    case getType(deleteRule.success):
      const deletedConditions =
        state.autoRules
          .find(rule => rule.id === action.payload.id)
          ?.conditions.map(condition => condition.value) || []

      return {
        ...state,
        loadingRules: WebDataStatus.Initial,
        manualRules: state.manualRules.filter(
          rule => rule.id !== action.payload.id
        ),
        autoRules: state.autoRules.filter(
          rule => rule.id !== action.payload.id
        ),
        ...(isSuccess(state.usedUnderstandings)
          ? {
              usedUnderstandings: Success(
                state.usedUnderstandings.data.filter(understanding => {
                  // For the deleted rule, remove any of its no-longer-in-use
                  // topic conditions from usedUnderstandinigs
                  return !deletedConditions.includes(understanding.topic)
                })
              ),
            }
          : {}),
      }
    case getType(deleteRule.failure):
      return {
        ...state,
        loadingRules: WebDataStatus.Failure,
      }
    case getType(deleteCounselor.request):
      return {
        ...state,
        counselors: isSuccessOrRefetching(state.counselors)
          ? Refetching(state.counselors.data)
          : Failure('Error deleting counselor'),
      }
    case getType(deleteCounselor.success):
      if (!isSuccessOrRefetching(state.counselors)) {
        return {
          ...state,
          counselors: Failure('Error deleting counselor'),
        }
      }
      return {
        ...state,
        counselors: Success(
          state.counselors.data.filter(x => x.id !== action.payload.id)
        ),
      }
    case getType(deleteCounselor.failure):
      if (!isSuccessOrRefetching(state.counselors)) {
        return {
          ...state,
          counselors: Failure('Error deleting counselor'),
        }
      }
      return {
        ...state,
        counselors: Success(state.counselors.data || []),
      }
    case getType(createCounselor.request):
      return {
        ...state,
        counselors: isSuccessOrRefetching(state.counselors)
          ? Refetching(state.counselors.data)
          : Loading(),
      }
    case getType(createCounselor.success):
      return {
        ...state,
        counselors: Success(mapResponseToCounselors(action.payload)),
      }
    case getType(createCounselor.failure):
      return {
        ...state,
        // For validation errors, we want to just keep the same set of counselor data available
        counselors:
          action.payload.response?.status === 400 &&
          isSuccessOrRefetching(state.counselors)
            ? Success(state.counselors.data)
            : Failure(),
      }
    case getType(updateCounselor.request):
      return {
        ...state,
        counselors: isSuccessOrRefetching(state.counselors)
          ? Refetching(state.counselors.data)
          : Loading(),
      }
    case getType(updateCounselor.success):
      return {
        ...state,
        counselors: Success(mapResponseToCounselors(action.payload)),
      }
    case getType(updateCounselor.failure):
      return {
        ...state,
        // For validation errors, we want to just keep the same set of counselor data available
        counselors:
          action.payload.response?.status === 400 &&
          isSuccessOrRefetching(state.counselors)
            ? Success(state.counselors.data)
            : Failure(),
      }
    case getType(saveRule.request):
      return insertRule(
        {
          ...action.payload,
          counselors: action.payload.counselors ?? [],
        },
        state
      )
    case getType(saveRule.success):
      return {
        ...state,
        autoRules: sortRules(action.payload.AUTOMATIC.map(mapRuleResponse)),
        manualRules: sortRules(action.payload.MANUAL.map(mapRuleResponse)),
        usedUnderstandings: Success(
          action.payload.used_understandings.map(understandingMapper)
        ),
      }
    case getType(saveRule.failure):
      return insertRule(
        {
          ...action.payload.rule,
          counselors: action.payload.rule.counselors ?? [],
        },
        state
      )
    case getType(updateRules):
      return {
        ...state,
        [action.payload.type]: action.payload.rules,
      }
    case getType(prioritizeRules.request):
      return {
        ...state,
        rulesRequestCancelTokenSources: state.rulesRequestCancelTokenSources.concat(
          action.payload
        ),
      }
    case getType(prioritizeRules.success):
      const ruleTypeKey =
        action.payload[0].type === ruleType.AUTOMATIC
          ? 'autoRules'
          : 'manualRules'
      return {
        ...state,
        [ruleTypeKey]: sortRules(action.payload.map(mapRuleResponse)),
        rulesRequestCancelTokenSources: [],
      }
  }
  return state
}

export default reducer
