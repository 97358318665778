export function FilterIcon() {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.875 1.0625H1.125L8.625 9.93125V16.0625L12.375 17.9375V9.93125L19.875 1.0625Z"
        stroke="#3a5882"
        strokeWidth="2.08333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
