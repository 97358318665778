import { HttpErrorKind } from 'store/webdata'
import { createAsyncAction, ActionType } from 'typesafe-actions'
import { ICounselorsResponseData } from 'api/response'

export const getTopicsPostgres = createAsyncAction(
  '@@MASCOT/GET_TOPICS_POSTGRES_START',
  '@@MASCOT/GET_TOPICS_POSTGRES_SUCCESS',
  '@@MASCOT/GET_TOPICS_POSTGRES_FAILURE'
)<void, ICounselorsResponseData, HttpErrorKind>()

export type ITopicActions = ActionType<typeof getTopicsPostgres>
