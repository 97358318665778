import classNames from 'classnames'
import { Button } from 'components/Button/Button'
import { ITrackingEvent } from 'components/EventTracker/EventTracker'
import { CloseButtonIcon } from 'components/Icons/CloseButtonIcon/CloseButtonIcon'
import Tooltip from 'components/Tooltip/Tooltip'

export interface ISimpleModalHeaderProps {
  onClose: () => void
  disable?: boolean
  text: string
  className?: string
  closeTooltipContent?: string
  cancelTrackingEvent?: ITrackingEvent
  hideX?: boolean
  hideDivider?: boolean
  noTextPadding?: boolean
}

export const SimpleModalHeader = ({
  onClose,
  disable,
  text,
  className,
  closeTooltipContent,
  cancelTrackingEvent,
  hideX,
  hideDivider,
  noTextPadding,
}: ISimpleModalHeaderProps) => (
  <div className={className}>
    <div
      className={classNames(
        'd-flex justify-content-between align-items-center px-4 py-3',
        noTextPadding ? 'px-0' : 'pl-4'
      )}>
      <h5 className="ms-modal-header mb-0">{text || ''}</h5>
      {!hideX && (
        <Tooltip
          content={closeTooltipContent || ''}
          isEnabled={!!closeTooltipContent}>
          <div tabIndex={-1}>
            <Button
              eventLocation={cancelTrackingEvent?.location}
              eventAction={cancelTrackingEvent?.action}
              eventObject={cancelTrackingEvent?.object}
              className="bg-white p-0"
              disabled={!!disable}
              style={{ marginTop: '-3px' }}
              onClick={onClose}>
              <CloseButtonIcon className="fill-mainstay-dark-blue-80" />
            </Button>
          </div>
        </Tooltip>
      )}
    </div>
    {!hideDivider && (
      <hr className="border-text-mainstay-dark-blue-05 mb-0 mt-0" />
    )}
  </div>
)
