export const FETCH_KNOWLEDGE_SEEDS_START =
  '@@MASCOT/KNOWLEDGE_SEEDER/FETCH_KNOWLEDGE_SEEDS_START'
export const FETCH_KNOWLEDGE_SEEDS_SUCCESS =
  '@@MASCOT/KNOWLEDGE_SEEDER/FETCH_KNOWLEDGE_SEEDS_SUCCESS'
export const FETCH_KNOWLEDGE_SEEDS_ERROR =
  '@@MASCOT/KNOWLEDGE_SEEDER/FETCH_KNOWLEDGE_SEEDS_ERROR'
export const REMOVE_KNOWLEDGE_SEED_START =
  '@@MASCOT/KNOWLEDGE_SEEDER/REMOVE_KNOWLEDGE_SEED_START'
export const REMOVE_KNOWLEDGE_SEED_SUCCESS =
  '@@MASCOT/KNOWLEDGE_SEEDER/REMOVE_KNOWLEDGE_SEED_SUCCESS'
export const REMOVE_KNOWLEDGE_SEED_ERROR =
  '@@MASCOT/KNOWLEDGE_SEEDER/REMOVE_KNOWLEDGE_SEED_ERROR'
export const UPDATE_KNOWLEDGE_SEED_APPROVAL =
  '@@MASCOT/KNOWLEDGE_SEEDER/UPDATE_KNOWLEDGE_SEED_APPROVAL'
export const ADD_KNOWLEDGE_SEED = '@@MASCOT/KNOWLEDGE_SEEDER/ADD_KNOWLEDGE_SEED'
