import React, { useContext, useState, useEffect, useCallback } from 'react'
import * as Api from 'api'
import { AxiosError } from 'axios'
import { toastOnHttpError500or400 } from 'api/http'
import { useIsLoggedIn } from 'util/hooks'

export type Permissions = string[]
export interface IPermissions {
  permissions: Permissions | undefined
  updatePermissions: (permissions: Permissions) => void
  hasPermission: (permission?: string) => boolean
  getPermissions: () => void
}

export const PermissionsContext = React.createContext<IPermissions>({
  permissions: [],
  hasPermission: () => true,
  updatePermissions: () => {
    return
  },
  getPermissions: () => {
    return
  },
})

export function usePermissionsContext() {
  return useContext(PermissionsContext)
}

export function PermissionsProvider({ children }: { children?: JSX.Element }) {
  const [permissions, setPermissions] = useState<Permissions>()
  const isAuthenticated = useIsLoggedIn()

  const updatePermissions = useCallback((permissions: Permissions): void => {
    setPermissions(permissions)
  }, [])

  const hasPermission = useCallback(
    (permission?: string): boolean => {
      if (!permission) {
        return true
      }

      return !!permissions && permissions.includes(permission)
    },
    [permissions]
  )

  const getPermissions = useCallback((): void => {
    if (!isAuthenticated) {
      return
    }

    Api.getCurrentUserProfile()
      .then(response => {
        setPermissions(response.data.permissions)
      })
      .catch((error: AxiosError) => {
        toastOnHttpError500or400(error)
      })
  }, [isAuthenticated])

  useEffect(() => {
    getPermissions()
  }, [getPermissions])

  return (
    <PermissionsContext.Provider
      value={{
        updatePermissions,
        getPermissions,
        hasPermission,
        permissions,
      }}>
      {children}
    </PermissionsContext.Provider>
  )
}
