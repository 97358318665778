import * as React from 'react'
import {
  FormControlLabel as MaterialFormControlLabel,
  RadioGroup as MaterialRadioGroup,
  FormControl as MaterialFormControl,
  FormLabel as MaterialFormLabel,
  Radio as MaterialRadio,
  FilledInput as MaterialFilledInput,
  InputAdornment as MaterialInputAdornment,
  Select as MaterialSelect,
  MenuItem as MaterialMenuItem,
  Drawer as MaterialDrawer,
  Tooltip as MaterialTooltip,
  withStyles,
} from '@material-ui/core'
import classnames from 'classnames'
import { FormControlLabelProps } from '@material-ui/core/FormControlLabel'
import { RadioGroupProps } from '@material-ui/core/RadioGroup'
import { FormControlProps } from '@material-ui/core/FormControl'
import { FormLabelProps } from '@material-ui/core/FormLabel'
import { RadioProps } from '@material-ui/core/Radio'
import { FilledInputProps } from '@material-ui/core/FilledInput'
import { InputAdornmentProps } from '@material-ui/core/InputAdornment'
import { SelectProps } from '@material-ui/core/Select'
import { MenuItemProps } from '@material-ui/core/MenuItem'
import { DrawerProps } from '@material-ui/core/Drawer'
import { TooltipProps } from '@material-ui/core/Tooltip'

export const FormControl: React.FunctionComponent<FormControlProps> = props => {
  return <MaterialFormControl {...props} />
}

export const FormLabel: React.FunctionComponent<FormLabelProps> = props => {
  return <MaterialFormLabel {...props} />
}

export const RadioGroup: React.FunctionComponent<RadioGroupProps> = props => {
  return <MaterialRadioGroup {...props} />
}

export const Radio: React.FunctionComponent<RadioProps> = props => {
  return <MaterialRadio {...props} />
}

export const Select: React.FunctionComponent<SelectProps> = props => {
  const { className, classes, error, ...rest } = props

  return (
    <MaterialSelect
      className={classnames(className, 'input-wrapper form-control', {
        'is-invalid': !!error,
      })}
      {...rest}
    />
  )
}

export const FilledInput: React.FunctionComponent<FilledInputProps> = props => {
  const { className, classes, ...rest } = props
  return (
    <MaterialFilledInput
      className={classnames(className, 'input-wrapper form-control')}
      classes={{ ...classes, error: 'is-invalid' }}
      {...rest}
    />
  )
}

export const InputAdornment: React.FunctionComponent<InputAdornmentProps> = props => {
  return <MaterialInputAdornment {...props} />
}

export const MenuItem: React.FunctionComponent<MenuItemProps> = props => {
  return <MaterialMenuItem {...props} />
}

export class FormControlLabel extends React.PureComponent<
  FormControlLabelProps
> {
  render() {
    const { className, ...rest } = this.props
    return (
      <div className={className}>
        <MaterialFormControlLabel {...rest} />
        {this.props.checked && this.props.children}
      </div>
    )
  }
}

// Unset `transform` property to fix an issue with react-beautiful-dnd where the draggable component would become invisible when being dragged
const StyledDrawer = withStyles({
  paper: {
    transform: 'unset !important',
  },
})(MaterialDrawer)

export const Drawer: React.FunctionComponent<DrawerProps> = props => {
  return <StyledDrawer {...props} />
}

export const Tooltip: React.FunctionComponent<TooltipProps> = props => {
  return <MaterialTooltip {...props} />
}
