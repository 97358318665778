import { RootState as IState } from 'store/store'
import { createSelector } from 'reselect'
import * as _ from 'lodash'
import { IMessageState } from 'store/triage/chat/messagesReducer'
import { FeaturesType } from 'components/Feature/Feature'
import { SCRIPT_LIBRARY_ORG_ID } from 'const/settings'
import { ChannelEnum } from 'store/transport'

export const getUserProfile = (state: IState) => {
  return state.triage.application.profile.currentUser
}
export const getUserId = (state: IState) => {
  return state.triage.application.profile.currentUser.id
}

export const getUserName = (state: IState) => {
  const user = state.triage.application.profile.currentUser
  if (user.name.full) {
    return user.name.full
  } else if (user.name.last && user.name.first) {
    return `${user.name.first} ${user.name.last}`
  } else if (user.name.first) {
    return user.name.first
  } else if (user.name.last) {
    return user.name.last
  } else {
    return 'Unknown User'
  }
}

export const getInstitutionId = (state: IState) => {
  return state.triage.application.profile.currentUser.institution.id
}

export const getInstitutionIdLegacy = (state: IState) => {
  const institution = state.triage.application.profile.currentUser.institution
  if (!institution) {
    return null
  }
  return institution.id
}

export const getInstitutionLegacy = (state: IState) => {
  return state.triage.application.profile.currentUser.institution
}

export const getCurrentMessagingService = (state: IState) => {
  const inst = state.triage.application.profile.currentUser.institution
  return inst && inst.messagingService
}

export const getUserProfileLoadingState = (state: IState) => {
  return state.triage.application.profile.loading
}

export const getIsLoadingUsers = (state: IState) => {
  return state.triage.application.profile.loadingFetchUsers
}

export const getUserIsLoggedIn = (state: IState) => {
  return state.triage.application.profile.isLoggedIn
}

export const getInstitutionWebhookSystemName = (state: IState) => {
  return (
    state.triage.application.profile.currentUser.institution
      .webhookSystemName || 'Webhook'
  )
}

export const getCurrentInstitutionDisplayName = (state: IState) => {
  const institution = state.triage.application.profile.currentUser.institution
  if (institution == null) {
    return null
  }
  return institution.displayName || institution.oliName
}

export const getLastModifiedUserName = (
  state: IState,
  ownProps: { message: IMessageState }
): string | undefined => {
  if (!ownProps.message.lastModifiedBy) {
    return
  }
  const user =
    state.triage.application.profile.byId[ownProps.message.lastModifiedBy]
  if (!user) {
    return ownProps.message.lastModifiedBy
  }
  if (user.name.first && user.name.last) {
    return `${user.name.first} ${user.name.last}`
  }
  return user.name.full
}

export function getIsStaff(state: IState): boolean {
  const user = state.triage.application.profile.currentUser
  return user && user.isStaff
}

export function getIsAdmitHubUser(state: IState): boolean {
  const user = state.triage.application.profile.currentUser
  return user && user.admitHubUser
}

export function getCanAuditUsers(state: IState): boolean {
  const user = state.triage.application.profile.currentUser
  if (!user) {
    return false
  }
  return (
    user.admitHubUser &&
    (user.enabledFeatures ?? []).includes(FeaturesType.ACCOUNT_ACCESS_TOOL)
  )
}

export function getIsEngineeringEmployee(state: IState): boolean {
  return (
    state.triage.application.profile?.currentUser?.engineeringEmployee ?? false
  )
}

export function getIsScriptyUser(state: IState): boolean {
  return !!(
    state.triage.application.profile &&
    state.triage.application.profile.currentUser &&
    state.triage.application.profile.currentUser.institution
      .messagingService === SCRIPT_LIBRARY_ORG_ID
  )
}

export const getEnabledFeatures = createSelector(
  getUserProfile,
  getInstitutionLegacy,
  (institution, user) => {
    const institutionFeatures = institution.enabledFeatures || []
    const userFeatures = user.enabledFeatures || []
    return institutionFeatures.concat(userFeatures)
  }
)

export const getAllUsers = (state: IState) => {
  return _.orderBy(
    state.triage.application.profile.allIds.map(
      x => state.triage.application.profile.byId[x]
    ),
    'name.full'
  )
}

export const getUserRegion = (state: IState) => {
  return state.triage.application.profile.currentUser.region
}

export const getAvailableRegions = (state: IState) => {
  return (
    state.triage.application.profile.currentUser.institution.availableRegions ||
    []
  )
}

export const getAreRegionsEnabled = (state: IState) => {
  const { institution } = state.triage.application.profile.currentUser
  return (
    institution.enabledFeatures &&
    institution.enabledFeatures.includes(FeaturesType.PREMS_REGIONS_ENABLED)
  )
}

export const getIsRegionalUser: (state: IState) => boolean = createSelector(
  getAreRegionsEnabled,
  getUserRegion,
  (regionsEnabled, userRegion) => {
    return !!regionsEnabled && !!userRegion
  }
)

export const getInstitutionLoading = (state: IState) =>
  state.triage.application.profile.loading

export const getInstitutionBotType = (state: IState) => {
  return state.triage.application.profile.currentUser.institution.botType
}
export const getInstitutionTimeZone = (state: IState) => {
  return state.triage.application.profile.currentUser.institution.timeZone
}
export const getInstitutionChannels = createSelector(
  getInstitutionLegacy,
  inst => (inst && inst.channels) || []
)

export const getInstitutionChannelsWithDefaults = createSelector(
  getInstitutionChannels,
  channels => [...channels, ChannelEnum.sms, ChannelEnum.web_bot]
)
