export const SET_SCHEDULED_CAMPAIGNS =
  '@@MASCOT/SCHEDULED_CAMPAIGNS/SET_SCHEDULED_CAMPAIGN'
export const SET_LOADING_SCHEDULED_CAMPAIGNS =
  '@@MASCOT/SCHEDULED_CAMPAIGNS/SET_LOADING_SCHEDULED_CAMPAIGNS'
export const SET_ERROR_LOADING_SCHEDULED_CAMPAIGNS =
  '@@MASCOT/SCHEDULED_CAMPAIGNS/SET_ERROR_LOADING_SCHEDULED_CAMPAIGNS'
export const DELETE_SCHEDULED_CAMPAIGN =
  '@@MASCOT/SCHEDULED_CAMPAIGNS/DELETE_SCHEDULED_CAMPAIGN'
export const DELETE_RECURRING_CAMPAIGN =
  '@@MASCOT/SCHEDULED_CAMPAIGNS/DELETE_RECURRING_CAMPAIGN'
export const DELETE_CAMPAIGN_TRIGGER =
  '@@MASCOT/SCHEDULED_CAMPAIGNS/DELETE_CAMPAIGN_TRIGGER'
