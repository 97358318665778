import {
  createAsyncAction,
  ActionType,
  createStandardAction,
} from 'typesafe-actions'
import {
  IAttributeMap,
  IGetAttributeMappingsResponseData,
  ICreateMappingAttributesResponseData,
  IMappingAttribute,
} from 'api/response'

export const getExistingMappings = createAsyncAction(
  '@@@mascot/attribute-mapper/FETCH_EXISTING_MAPPINGS_REQUEST',
  '@@@mascot/attribute-mapper/FETCH_EXISTING_MAPPINGS_SUCCESS',
  '@@@mascot/attribute-mapper/FETCH_EXISTING_MAPPINGS_FAILURE'
)<void, IGetAttributeMappingsResponseData, void>()

export const createNewAttributes = createAsyncAction(
  '@@@mascot/attribute-mapper/CREATE_NEW_ATTRIBUTES_REQUEST',
  '@@@mascot/attribute-mapper/CREATE_NEW_ATTRIBUTES_SUCCESS',
  '@@@mascot/attribute-mapper/CREATE_NEW_ATTRIBUTES_FAILURE'
)<void, ICreateMappingAttributesResponseData, void>()

export const updateMappingsAsync = createAsyncAction(
  '@@@mascot/attribute-mapper/UPDATE_MAPPINGS_REQUEST',
  '@@@mascot/attribute-mapper/UPDATE_MAPPINGS_SUCCESS',
  '@@@mascot/attribute-mapper/UPDATE_MAPPINGS_FAILURE'
)<void, IAttributeMap[], void>()

export const updateMappingsSync = createStandardAction(
  '@@mascot/attribute-mapper/UPDATE_MAPPINGS'
)<{ sourceAttrId: number; targetAttrId: number; unmap: boolean }>()

export const preMapOnName = createStandardAction(
  '@@mascot/attribute-mapper/PRE_MAP_ON_NAME'
)()
export const cancelMapping = createStandardAction(
  '@@mascot/attribute-mapper/CANCEL_MAPPING'
)()

export const addTargetAttribute = createStandardAction(
  '@@mascot/attribute-mapper/ADD_TARGET_ATTRIBUTE'
)<IMappingAttribute>()

export type IAttributeMapperActions =
  | ActionType<typeof getExistingMappings>
  | ActionType<typeof updateMappingsSync>
  | ActionType<typeof createNewAttributes>
  | ActionType<typeof updateMappingsAsync>
  | ActionType<typeof preMapOnName>
  | ActionType<typeof cancelMapping>
  | ActionType<typeof addTargetAttribute>
