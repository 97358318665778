// NOTE(neckenth - 2023-11-22)
// Source code: https://github.com/0xycvv/react-priority-navigation
// Made a fair amount of adjustments, and had some dependency issues, so
// instead of installing the npm package, I plucked the necessary code.
import * as React from 'react'
import classNames from 'classnames'
import 'components/PriorityNav/PriorityNav.scss'
import { debounce } from 'lodash'
import { ActionMenu } from 'components/ActionMenu/ActionMenu'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'

interface IPriorityNavProps extends Partial<IDefaultProps> {
  className?: string
  style?: React.CSSProperties
  children: Array<React.ReactNode | React.ReactElement>
}

interface IDefaultProps {
  itemPadding: string | number
  minWidth: string
  offset: number
  debounce: number
  isOpen: boolean
}

interface IPriorityNavState {
  children: Array<React.ReactNode | React.ReactElement>
  dropdownItems: Array<React.ReactElement | undefined>
  lastItemWidth: number[]
}

interface IAction {
  type: 'move' | 'return'
  payload?: {
    lastItem: HTMLDivElement | undefined
  }
}

function reducer(state: IPriorityNavState, action: IAction): IPriorityNavState {
  switch (action.type) {
    case 'move':
      const lastChild = state.children[state.children.length - 1]
      const children = state.children.slice(0, -1)
      return {
        ...state,
        children,
        dropdownItems: [
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          lastChild as React.ReactElement,
          ...state.dropdownItems,
        ],
        ...(action?.payload?.lastItem && {
          lastItemWidth: [
            ...state.lastItemWidth,
            action?.payload.lastItem.clientWidth,
          ],
        }),
      }
    case 'return':
      const [firstItemFromList, ...dropdownItems] = state.dropdownItems
      const [_, ...lastItemWidth] = state.lastItemWidth
      return {
        children: [...state.children, firstItemFromList],
        dropdownItems,
        lastItemWidth,
      }
    default:
      return state
  }
}

export const PriorityNav: React.FC<IPriorityNavProps> = props => {
  const outerNav = React.useRef<HTMLDivElement>(null)
  const nav = React.useRef<HTMLDivElement>(null)
  const items = React.useRef(new Map<number, HTMLDivElement>()).current

  const initialState: IPriorityNavState = {
    children: props.children,
    dropdownItems: [],
    lastItemWidth: [],
  }
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const outerNavWidth = useResizeObserve(outerNav)

  const doesItFit = debounce(() => {
    if (nav.current && outerNav.current) {
      const outerWidth = outerNav.current.offsetWidth - 36
      const totalWidth = nav.current.offsetWidth

      if (state.children.length > 0 && totalWidth > outerWidth) {
        moveItemToList()
      } else if (
        state.dropdownItems.length > 0 &&
        outerWidth >
          totalWidth +
            state.lastItemWidth[state.lastItemWidth.length - 1] +
            (props.offset || 0)
      ) {
        moveItemToNav()
      }
    }
  }, props.debounce)

  React.useEffect(() => {
    doesItFit()
  }, [
    state.children,
    state.dropdownItems,
    outerNavWidth,
    nav?.current?.offsetWidth,
    doesItFit,
  ])

  const moveItemToList = () => {
    dispatch({
      type: 'move',
      payload: {
        lastItem: items.get(state.children.length - 1),
      },
    })
  }

  const moveItemToNav = () => {
    dispatch({
      type: 'return',
    })
  }

  return (
    <div
      style={{
        minWidth: props.minWidth,
      }}
      ref={outerNav}
      className={classNames(
        'priority-nav-root d-flex align-items-center justify-content-start',
        props.className
      )}>
      <div ref={nav} className={classNames('priority-nav-main')}>
        {state.children.map((child, ix) => {
          if (React.isValidElement(child)) {
            return (
              <div
                ref={(s: HTMLDivElement) => {
                  if (s) {
                    items.set(ix, s)
                  }
                }}
                style={{
                  padding: props.itemPadding,
                }}
                className="priority-nav-item"
                key={child.key}>
                {child}
              </div>
            )
          }
        })}
      </div>
      {state.dropdownItems.length ? (
        <ActionMenu
          className="dropdown-icon-container"
          popoverPlacement="bottom-end"
          menuClassName="dropdown-container"
          buttonClassName="dropdown-item"
          stopPropagation={true}
          itemClassName="dropdown-item"
          menuItems={state.dropdownItems.map(item => {
            // tslint:disable no-unsafe-any
            return {
              label: item?.props?.children,
              onSelect: item?.props?.onClick,
              icon: undefined,
            }
            // tslint:enable no-unsafe-any
          })}
          triggerIcon={
            <div className="dropdown-icon pt-1">
              <AHIcon name="arrow_drop_down" />
            </div>
          }
          removePadding={true}
        />
      ) : null}
    </div>
  )
}

function useResizeObserve(ref: React.RefObject<HTMLElement>) {
  const [width, setWidth] = React.useState(
    ref.current ? ref.current.clientWidth : window ? window.innerWidth : 0
  )

  React.useLayoutEffect(() => {
    const currentRef = ref.current
    function handleWidth() {
      if (currentRef) {
        setWidth(currentRef.clientWidth)
      }
    }

    const resizeObserver = new ResizeObserver(handleWidth)
    if (currentRef) {
      resizeObserver.observe(currentRef)
    }
    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef)
      }
    }
  }, [ref])

  return width
}
