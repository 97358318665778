import { RootState, Dispatch } from 'store/store'
import { connect } from 'react-redux'
import UserMenu, { IUserMenuProps } from 'components/UserMenu/UserMenu'
import { LoadingStatus } from 'store/auth/reducer'
import { logoutAsync } from 'store/auth/thunks'
import { getAuthedUserFullName } from 'store/auth/selectors'

const mapStateToProps = (
  state: RootState
): Pick<IUserMenuProps, 'isLoggingOut' | 'name'> => ({
  isLoggingOut: state.auth.loggingOutStatus === LoadingStatus.Loading,
  name: getAuthedUserFullName(state),
})

const mapDispatchToProps = (
  dispatch: Dispatch
): Pick<IUserMenuProps, 'onLogout'> => ({
  onLogout: logoutAsync(dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
