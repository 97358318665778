import { ActionType, createAsyncAction } from 'typesafe-actions'
import { IDialogLabel } from 'store/dialog-labels/reducer'

export const fetchDialogLabels = createAsyncAction(
  '@MASCOT/DIALOG_LABELS/FETCH_LABELS_REQUEST',
  '@MASCOT/DIALOG_LABELS/FETCH_LABELS_END',
  '@MASCOT/DIALOG_LABELS/FETCH_LABELS_FAIL'
)<void, IDialogLabel[], void>()

export const createDialogLabel = createAsyncAction(
  '@MASCOT/DIALOG_LABELS/CREATE_LABEL_REQUEST',
  '@MASCOT/DIALOG_LABELS/CREATE_LABEL_END',
  '@MASCOT/DIALOG_LABELS/CREATE_LABEL_FAIL'
)<{ name: string }, { newLabel: IDialogLabel }, void>()

export const deleteDialogLabel = createAsyncAction(
  '@MASCOT/DIALOG_LABELS/DELETE_LABEL_REQUEST',
  '@MASCOT/DIALOG_LABELS/DELETE_LABEL_END',
  '@MASCOT/DIALOG_LABELS/DELETE_LABEL_FAIL'
)<{ labelId: number }, { labelId: number }, void>()

export const updateDialogLabel = createAsyncAction(
  '@MASCOT/DIALOG_LABELS/UPDATE_LABEL_REQUEST',
  '@MASCOT/DIALOG_LABELS/UPDATE_LABEL_END',
  '@MASCOT/DIALOG_LABELS/UPDATE_LABEL_FAIL'
)<{ labelId: number; newName: string }, { updatedLabel: IDialogLabel }, void>()

export const bulkAddDialogLabels = createAsyncAction(
  '@MASCOT/DIALOG_LABELS/BULK_ADD_REQUEST',
  '@MASCOT/DIALOG_LABELS/BULK_ADD_END',
  '@MASCOT/DIALOG_LABELS/BULK_ADD_FAIL'
)<
  { dialogIds: string[] },
  {
    dialogLabelMap: { [dialogId: string]: IDialogLabel[] }
    forTemplates?: boolean
  },
  void
>()

export const bulkRemoveDialogLabels = createAsyncAction(
  '@MASCOT/DIALOG_LABELS/BULK_REMOVE_REQUEST',
  '@MASCOT/DIALOG_LABELS/BULK_REMOVE_END',
  '@MASCOT/DIALOG_LABELS/BULK_REMOVE_FAIL'
)<
  { dialogIds: string[]; labelIds: number[] },
  { dialogIds: string[]; labelIds: number[] },
  void
>()

export const fetchAdmithubTags = createAsyncAction(
  '@@MASCOT/DIALOG_LABELS/FETCH_ADMITHUB_TAGS_START',
  '@@MASCOT/DIALOG_LABELS/FETCH_ADMITHUB_TAGS_SUCCESS',
  '@@MASCOT/DIALOG_LABELS/FETCH_ADMITHUB_TAGS_FAILURE'
)<void, IDialogLabel[], void>()

export type IDialogLabelActions =
  | ActionType<typeof fetchDialogLabels>
  | ActionType<typeof createDialogLabel>
  | ActionType<typeof deleteDialogLabel>
  | ActionType<typeof updateDialogLabel>
  | ActionType<typeof bulkAddDialogLabels>
  | ActionType<typeof bulkRemoveDialogLabels>
  | ActionType<typeof fetchAdmithubTags>
