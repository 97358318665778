import { Dispatch } from 'store/store'
import { fetchInstitutions } from 'store/institution/reducer'
import * as api from 'api'
import { HttpErrorKind } from 'store/webdata'

export const fetchingInstitutions = (dispatch: Dispatch) => {
  dispatch(fetchInstitutions.request())
  return api
    .getInstitutions()
    .then(res => {
      dispatch(fetchInstitutions.success(res.data))
    })
    .catch(() => dispatch(fetchInstitutions.failure(HttpErrorKind.other)))
}
