interface IInsightsHubIconProps {
  width?: string
  height?: string
}

export function InsightsHubIcon({
  width = '27',
  height = '28',
}: IInsightsHubIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27 28"
      fill="none">
      <path
        d="M22.4804 21.2325C22.9631 20.4646 23.2483 19.576 23.2483 18.6106C23.2483 15.8791 21.0433 13.6741 18.3118 13.6741C15.5802 13.6741 13.3752 15.8791 13.3752 18.6106C13.3752 21.3422 15.5802 23.5472 18.3008 23.5472C19.2662 23.5472 20.1657 23.2619 20.9226 22.7792L24.3453 26.2019L25.903 24.6442L22.4804 21.2325ZM18.3118 21.3531C17.5844 21.3531 16.8868 21.0642 16.3725 20.5499C15.8582 20.0356 15.5692 19.338 15.5692 18.6106C15.5692 17.8833 15.8582 17.1857 16.3725 16.6714C16.8868 16.1571 17.5844 15.8681 18.3118 15.8681C19.0391 15.8681 19.7367 16.1571 20.251 16.6714C20.7653 17.1857 21.0543 17.8833 21.0543 18.6106C21.0543 19.338 20.7653 20.0356 20.251 20.5499C19.7367 21.0642 19.0391 21.3531 18.3118 21.3531ZM17.9168 12.0286C17.1051 12.0505 16.3262 12.2261 15.6131 12.5222L15.0098 11.6117L10.8412 18.3912L7.53917 14.5298L3.55704 20.9034L1.76892 19.6199L7.25395 10.8438L10.545 14.6833L14.933 7.55282L17.9168 12.0286ZM20.7581 12.5771C20.056 12.2699 19.2991 12.0834 18.5092 12.0396L24.1259 3.16479L25.903 4.45926L20.7581 12.5771Z"
        fill="white"
      />
    </svg>
  )
}
