import { RootState as IState } from 'store/store'
import { getUserProfile } from 'store/triage/profile/selectors'

export const getAuthedUserLastName = (state: IState) => {
  return getUserProfile(state).name.last
}

export const getAuthedUserFirstName = (state: IState) => {
  return getUserProfile(state).name.first
}

export const getAuthedUserFullName = (state: IState): string | null => {
  return getUserProfile(state).name.full || null
}

export const getAuthedUserEmail = (state: IState): string | null => {
  return getUserProfile(state).email || null
}
