import React from 'react'

import classnames from 'classnames'
import { Styles } from 'react-select/lib/styles'
import { Props as AsyncSelectProps } from 'react-select/lib/Async'
import { Props as SelectProps } from 'react-select/lib/Select'
import Select from 'components/Select/SelectV2'
import { components, Async as ReactSelectAsync } from 'react-select'
import { ValueContainerProps } from 'react-select/lib/components/containers'
import { IndicatorProps } from 'react-select/lib/components/indicators'
import { isNil } from 'lodash'
import { IEventTrackerEventDetail } from 'components/EventTracker/EventTracker'
import scssVariables from 'scss/_variables.scss'
import 'components/SettingsEscalation/DropdownIndicator.scss'

const DropdownIndicatorArrow = ({
  className,
  isFocused,
}: {
  className?: string
  isFocused?: boolean
}) => (
  <svg
    className={className}
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill={isFocused ? '#5F779A' : 'hsl(0,0%,80%)'}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M0.984009 0.98407H11.015L5.99901 6.00007L0.984009 0.98407Z" />
  </svg>
)

export function DropdownIndicator<T>(props: IndicatorProps<T>) {
  return (
    <DropdownIndicatorArrow
      isFocused={props.isFocused}
      className="select-v2-dropdown-indicator"
    />
  )
}
export type CutstomValueContainerProps<T> = Omit<
  ValueContainerProps<T>,
  'selectProps'
> & {
  selectProps: SelectProps<T> & {
    floatingLabel: string
    hasError?: boolean
  }
}
export function ValueContainer<T>({ ...props }: CutstomValueContainerProps<T>) {
  return (
    <components.ValueContainer {...props}>
      <div className="react-select-labeled">
        <span
          className={classnames('label', {
            'label--active': props.selectProps.menuIsOpen || props.hasValue,
            'label--focused': props.selectProps.menuIsOpen,
            'label--error': props.selectProps.hasError,
          })}>
          {' '}
          {props.selectProps.floatingLabel}{' '}
        </span>
        {props.children}
      </div>
    </components.ValueContainer>
  )
}
function getErrorStyle(hasError: boolean): Pick<Styles, 'control'> {
  if (!hasError) {
    return {}
  }
  return {
    control: base => ({
      ...base,
      background: `${scssVariables.newUIDangerLight} !important`,
      '&:hover': {
        background: `${scssVariables.newUIDangerLight} !important`,
      },
    }),
  }
}

export function FloatingLabelSelectAsync<T>(
  props: AsyncSelectProps<T> & {
    floatingLabel?: string
    hasError?: boolean
  }
) {
  return (
    <ReactSelectAsync<T>
      {...props}
      components={{
        DropdownIndicator,
        ValueContainer,
        IndicatorSeparator: undefined,
      }}
      styles={{
        singleValue: base => ({ ...base, marginTop: '6px' }),
        input: base => ({ ...base, marginTop: '15px' }),
        control: base => ({
          ...base,
        }),
        ...getErrorStyle(!!props.hasError),
      }}
    />
  )
}

export function FloatingLabelSelect<T>(
  props: SelectProps<T> &
    IEventTrackerEventDetail & {
      floatingLabel?: string
      hasError?: boolean
      menuZIndex?: number
    } & { explicitPreventOverlap?: boolean }
) {
  const newComponents = props.floatingLabel
    ? { ...props.components, ValueContainer }
    : props.components
  const floatinglabelStyles = props.floatingLabel
    ? {
        singleValue: (base: React.CSSProperties) => ({
          ...base,
          marginTop: '6px',
          color: scssVariables.mainstayDarkBlue,
        }),
        input: (base: React.CSSProperties) => ({ ...base, marginTop: '15px' }),
        control: (base: React.CSSProperties) => ({ ...base, height: '50px' }),
        valueContainer: (base: React.CSSProperties) => ({
          ...base,
          top: '-2px',
        }),
        multiValueLabel: (base: React.CSSProperties) => ({
          ...base,
          background: 'transparent !important',
          borderColor: scssVariables.mainstayBlue80,
          color: scssVariables.mainstayDarkBlue,
          padding: '0px 4px',
          fontSize: '0.8rem !important',
        }),
      }
    : {}
  const menuStyles = isNil(props.menuZIndex)
    ? {}
    : {
        menuPortal: (base: React.CSSProperties) => ({
          ...base,
          zIndex: props.menuZIndex,
        }),
      }

  return (
    <Select<T>
      {...props}
      explicitPreventOverlap={!!props.explicitPreventOverlap}
      components={{
        DropdownIndicator,
        IndicatorSeparator: undefined,
        ...newComponents,
      }}
      styles={{
        ...floatinglabelStyles,
        ...menuStyles,
        ...getErrorStyle(!!props.hasError),
      }}
      menuPortalTarget={props.menuZIndex ? document.body : undefined}
      eventAction={props.eventAction}
      eventLocation={props.eventLocation}
      eventObject={props.eventObject}
      hideSelectedOptions={props.hideSelectedOptions}
    />
  )
}
