import { ActionType, createStandardAction } from 'typesafe-actions'
import * as Ably from 'ably/promises'

export const updateAblyConnectionState = createStandardAction(
  '@@MASCOT/ABLY/UPDATE_CONNECTION_STATE'
)<Ably.Types.ConnectionStateChange['current']>()

export const resetAblyConnectionState = createStandardAction(
  '@@MASCOT/ABLY/RESET_CONNECTION_STATE'
)()

export type IAblyConnectionActions =
  | ActionType<typeof updateAblyConnectionState>
  | ActionType<typeof resetAblyConnectionState>
